import React from 'react';
import { Route, Switch} from 'react-router-dom';
import asyncComponent from '../../itrust_common/components/AsyncComponent';

const Calendar = asyncComponent(() =>
  import('../../containers/authorised/appointment/Calendar')
);

const Appointment = asyncComponent(() =>
  import('../../containers/public/appointment/Appointment')
);

const AppointmentRoutes = (
  <Switch>
    <Route exact path="/appointment" component={Calendar} />
    <Route exact path="/appointment-preview" component={Appointment} />
  </Switch>
);

export default AppointmentRoutes;