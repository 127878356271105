import React, { useEffect, useRef } from "react";

const UserCam = () => {
  let videoRef = useRef(null);

  const getVideo = () => {
    navigator.mediaDevices
      .getUserMedia({
        video: { facingMode: "user" },
      })
      .then((stream) => {
        let video = videoRef.current;
        video.srcObject = stream;
        video.play();
      })
      .catch((err) => {
        console.error(err);
      });
  };
  useEffect(() => {
    getVideo();
  }, [videoRef]);
  return (
    <div>
      <video ref={videoRef} className="container video-cam" muted></video>
    </div>
  );
};

export default UserCam;
