import Toastr from "../../../itrust_common/components/Toastr"
const parent = 'PUBLIC_'

const DEFAULT_STATE = {
  slot: {},
  saving: false
}
let changes = null;
export default function appointmentReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  switch(action.type){
    case `${parent}APPOINTMENT_CREATE_PENDING`:
      return {...state, saving: true};

    case `${parent}APPOINTMENT_CREATE_REJECTED`:
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      return {...state, saving: false};

    case `${parent}APPOINTMENT_CREATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      changes = {
        ...response,
        saving: false
      };
      return {...state, ...changes};
    
    case `${parent}APPOINTMENT_SELECTED_SLOT`:
      changes = {
        slot: action.slot,
      };
      return {...state, ...changes};
    default:
      return state;
  }
}