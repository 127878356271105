import React from 'react'
import { useDispatch } from 'react-redux';
import { orderUpdate } from '../../../redux/actions/order/orderActions';
import Alert from '../../../itrust_common/components/Alert';
import { humanize } from '../../../itrust_common/components/HelperFunctions';

export default function DuplicateLensOrGlassButton(props) {
  const { orderId, children, lensOrGlassName, id } = props;
  const dispatch = useDispatch()

  const handleDuplicateLensOrGlassOrderCreate = () => {
    Alert.confirm(<div>Are you sure you want to create a duplicate of {humanize(lensOrGlassName).toLowerCase()} order. </div>,
      (res) => {
        if (res) {
          dispatch(orderUpdate({ id: orderId, [`copy_${lensOrGlassName}_id`]: id }, { include: '*' }))
        }
      }
    )
  }

  return (
    <span>
      <span
        className="pointer"
        onClick={() => handleDuplicateLensOrGlassOrderCreate()}
      >
        {children}
      </span>
    </span>
  )
}