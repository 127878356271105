import React, { Component } from 'react';
export default class Input extends Component {
  render() {
    const {
      props,
      props: { input, disabled, placeholder, autoComplete, wrapperClasses, label, min, max },
      props: { meta: { touched, error, warning } }
    } = this;
    
    return (
      <div className={`${disabled && 'disabled'} ${wrapperClasses}`}>
        {label !== undefined && <label>{props.label}</label>}
        <input
          {...input}
          autoComplete={autoComplete}
          placeholder={placeholder}
          type="range"
          min={min}
          max={max}
          list="num"
          disabled={props.disabled || false}
          className={`${props.className} ${touched && error && 'is-invalid'}`}
        />

        <datalist id="num" className="d-flex justify-content-between">
          <option value="1" label="1" />
          <option value="2" label="2" />
          <option value="3" label="3" />
          <option value="4" label="4" />
        </datalist>    
        
        { touched &&
          ((error && <span className="invalid-feedback text-danger">{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </div>
    );
  }
}
