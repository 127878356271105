import React, {Component, Fragment} from 'react';
import {buildDownloadUrl} from '../../utils/apiHelperUtils';

class ExportButton extends Component {
  render() {
    const { params, className, label, path, onClickHook, totalRecords, exportLimit } = this.props
    return(
    <Fragment>
      { totalRecords > exportLimit ?
        <a href="#" className={`btn ${className}`} onClick={()=> alert(`Export limit is ${exportLimit}, Please adjust your filters or contact support@itrust.io for more detail.`)}>{label}</a>
        :
        <a download className={`btn ${className}`} href={buildDownloadUrl(path, params)} onClick={onClickHook}>{label}</a>
      }
    </Fragment>
    )
  }
}
export default ExportButton;
