import _ from 'lodash'
import Toastr from "../../../components/Toastr"
const parent = 'SETTING_'

const DEFAULT_STATE = {
  social_histories: { eye_health: [] },
  social_history: {options: {type: "boolean", value: "yes"}},
  meta: {pagination: {}},
  saving: false
}
let changes = null;
export default function socialHistoryReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let socialHistoryData = [];
  let updatedRecord = {};
  let updatedCategory = "";
  let categoryData = "";
  let allCategories = []
  switch(action.type){
    case `${parent}SOCIAL_HISTORIES_LOAD_PENDING`:
    case `${parent}SOCIAL_HISTORY_LOAD_PENDING`:
      return {...state, saving: true};

    case `${parent}SOCIAL_HISTORY_CREATE_PENDING`:
    case `${parent}SOCIAL_HISTORY_UPDATE_PENDING`:
      return {...state, saving: true};

    case `${parent}SOCIAL_HISTORIES_LOAD_REJECTED`:
    case `${parent}SOCIAL_HISTORY_LOAD_REJECTED`:
    case `${parent}SOCIAL_HISTORY_CREATE_REJECTED`:
    case `${parent}SOCIAL_HISTORY_UPDATE_REJECTED`:
    case `${parent}SOCIAL_HISTORY_DELETE_REJECTED`:
      Toastr.handleShow(response.data.success, response.data.error, response.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case `${parent}SOCIAL_HISTORIES_LOAD_FULFILLED`:
      let data={}
      _.chain(response.social_histories).groupBy("social_history_section.name").map((value, key) => (data[key]=value)).value()
      changes = {
        social_histories: data,
        saving: false
      };
      return {...state, ...changes};
      
    case `${parent}SOCIAL_HISTORY_LOAD_FULFILLED`:
      changes = {
        social_history: response.social_history,
        saving: false
      }
      return {...state,...changes};
    
    case `${parent}SOCIAL_HISTORY_CREATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      let newRecord = response.social_history
      updatedCategory = newRecord.social_history_section.name
      categoryData = state.social_histories[updatedCategory] || []
      changes = {
        social_histories: {...state.social_histories, [updatedCategory]: [...categoryData, newRecord]},
        editing: false,
        saving: false
      }
      return {...state, ...changes};

    case `${parent}SOCIAL_HISTORY_UPDATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      updatedRecord = response.social_history;
      updatedCategory = updatedRecord.social_history_section.name
      categoryData = state.social_histories[updatedCategory] || []
      allCategories = Object.keys(state.social_histories)
      if(allCategories.includes(updatedCategory)){
        for (const [index, socialHistory] of categoryData.entries()) {
          if (socialHistory.id === updatedRecord.id) {
            socialHistoryData = [
              ...categoryData.slice(0, index),
              updatedRecord,
              ...categoryData.slice(index + 1)
            ];
          }
        }
      }
      else {
        for(const [ category] of allCategories.entries()) {
          for(const [index, categoryData] of state.social_histories[category].entries()) {
            if (categoryData.id === updatedRecord.id) {
              state.social_histories[category].splice(index, 1);
            }
          }
        }
        socialHistoryData = [ ...categoryData, updatedRecord]
      }

      changes = {
        social_histories: {...state.social_histories, [updatedCategory]: socialHistoryData},
        editing: false,
        saving: false
      };
      return { ...state, ...changes };

    case `${parent}SOCIAL_HISTORY_DELETE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      let deletedRecord = response.social_history;
      updatedCategory = deletedRecord.social_history_section.name
      categoryData = state.social_histories[updatedCategory] || []
      for (const [index, socialHistory] of categoryData.entries()) {
        if (socialHistory.id === deletedRecord.id) {
          categoryData.splice(index, 1);
        }
      }
      return {
        ...state,
        social_histories: {...state.social_histories, [updatedCategory]: categoryData},
        error: null
      };

    case `${parent}SOCIAL_HISTORY_REORDER`:
      changes = {
        social_histories: {...state.social_histories, [action.category]: action.socialHistories},
      };
      return { ...state, ...changes };

    case `${parent}SOCIAL_HISTORY_TYPE_CHANGE`:
      changes = {
        social_history: { ...action.data }
      };
      return { ...state, ...changes };


    case `${parent}SOCIAL_HISTORY_EDIT`:
      changes = {
        editing: {
          id: action.id,
        },
        social_history: {options: {type: "boolean", value: "true" }}
      };
      return { ...state, ...changes };

    case `${parent}SOCIAL_HISTORY_EDIT_CANCEL`:
      changes = {
        editing: false
      };
      return { ...state, ...changes };


    default:
      return state;
  }
}