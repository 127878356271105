import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { Checkbox } from '../../../itrust_common/components/form'
import { currentUserUpdate } from '../../../itrust_common/redux/actions/authActions'

function AddOnsForm(props) {
  const { saving, voipConfig, twoWayMessageConfig } = props

  const voipAddOns = [
    { name: 'voip.incoming_call_pop_up', label: 'Show incoming pop-up of calls.' },
    { name: 'voip.missed_call_notification', label: 'Receive notification of missed calls.' },
    { name: 'voip.voicemail_received_notification', label: 'Receive notification of voicemails.' }
  ]

  const twoWayMessageAddOns = [
    { name: 'two_way_message.incoming_message_notification', label: 'Receive notification for incoming messages.' }
  ]

  return (
    <div className='row m-4' >
      {(voipConfig?.visible && voipConfig?.status !== 'inactive') &&
        <>
          <h6 className='col-12 mb-3'>iTRUST Voice</h6>
          {voipAddOns.map((field) => <Field name={field.name} component={Checkbox} label={field.label} wrapperClasses='col-12 mb-1' disabled={saving} />)}
        </>
      }
      {(twoWayMessageConfig?.visible && twoWayMessageConfig?.status !== 'inactive') &&
        <>
          <h6 className='border-top my-4 pt-6 col-10'>Two way messages</h6>
          {twoWayMessageAddOns.map((field) => <Field name={field.name} component={Checkbox} label={field.label} wrapperClasses='col-12 mb-1' disabled={saving} />)}
        </>
      }
    </div>
  )
}

const mapStateToProps = (state) => {
  const { auth, auth: { user: { preferences } }, organisation: { organisation } } = state
  return {
    preferences,
    saving: auth.saving,
    voipConfig: organisation.add_ons_config?.type?.voip,
    twoWayMessageConfig: organisation?.add_ons_config?.type?.two_way_message,
  }
}

export default connect(mapStateToProps)(AddOnsForm);