import { handleAPI, API_VERSION_ONE } from '../../../../itrust_common/utils/apiUtils';

export function whatsAppAccountLoad(filters) {
  const url = `/${API_VERSION_ONE}/organisation/whatsapp_account`;
  const method = 'GET'

  return {
    type: 'WHATSAPP_ACCOUNT_LOAD',
    payload: handleAPI(url, filters, method)
  }
}

export function whatsAppProfileLoad(filters) {
  const url = `/${API_VERSION_ONE}/add_on`;
  const method = 'GET'

  return {
    type: 'WHATSAPP_PROFILE_LOAD',
    payload: handleAPI(url, filters, method)
  }
}

export function whatsAppProfileUpdate(data, filters) {
  const url = `/${API_VERSION_ONE}/add_on/${data.id}`;
  const method = 'PUT'

  return {
    type: 'WHATSAPP_PROFILE_UPDATE',
    payload: handleAPI(url, filters, method, data)
  }
}

export function whatsAppLogsLoad(filters) {
  const url = `/${API_VERSION_ONE}/add_ons/whatsapp/logs/all_logs`;
  const method = 'GET'

  return {
    type: 'WHATSAPP_LOGS_LOAD',
    payload: handleAPI(url, filters, method)
  }
}

export function patientWhatsAppLogCreate(data, filters) {
  const url = `/${API_VERSION_ONE}/add_ons/whatsapp/logs`;
  const method = 'POST'

  return {
    type: 'PATIENT_WHATSAPP_LOGS_CREATE',
    payload: handleAPI(url, filters, method, data)
  }
}

export function patientWhatsAppLogsLoad(filters) {
  const url = `/${API_VERSION_ONE}/add_ons/whatsapp/logs`;
  const method = 'GET'

  return {
    type: 'PATIENT_WHATSAPP_LOGS_LOAD',
    payload: handleAPI(url, filters, method)
  }
}

export function whatsAppChatInitiate(filters) {
  const url = `/${API_VERSION_ONE}/add_ons/whatsapp/logs/initiate_chat`;
  const method = 'GET'

  return {
    type: 'WHATSAPP_CHAT_INITIATE',
    payload: handleAPI(url, filters, method)
  }
}

export function whatsAppNotificationLoad(filters) {
  const url = `/${API_VERSION_ONE}/notifications`;
  const method = 'GET'

  return {
    type: 'WHATSAPP_NOTIFICATION_LOAD',
    payload: handleAPI(url, filters, method)
  }
}

export function whatsAppNotificationUpdate(data) {
  return {
    type: 'WHATSAPP_NOTIFICATION_UPDATE',
    data,
  };
}

export function whatsAppPatientLoad(id, filters) {
  const url = `/${API_VERSION_ONE}/patients/${id}`;
  const method = 'GET'

  return {
    type: 'WHATSAPP_PATIENT_LOAD',
    payload: handleAPI(url, filters, method)
  };
}

export function whatsAppEdit(data) {
  return {
    type: 'WHATSAPP_EDIT',
    data,
  };
}


export function whatsAppNotificationCreate(data) {
  return {
    type: 'WHATSAPP_NOTIFICATION_CREATE',
    data,
  };
}

export function whatsAppLogUpdate(data) {
  return {
    type: 'WHATSAPP_LOG_UPDATE',
    data,
  };
}