import React from 'react';

let FormTemplateSectionWrapper = props => {
  const {isTemplate, fieldValues: {visible}, children} = props;
  let isVisible = visible.toString()==="true"
  return (
    isTemplate ?
    <div className={`${!isVisible && 'opacity-5'}`}>
      {children}
    </div>
    :
    isVisible &&
    <div className="">
      {children}
    </div>
  )
}

export default FormTemplateSectionWrapper;