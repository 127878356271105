import { getInsuranceType } from "../../../../itrust_common/components/HelperFunctions";
import Toastr from "../../../../itrust_common/components/Toastr";

const DEFAULT_STATE = {
  saving: false,
  verify: false,
  detailViewPopup: { visible: false },
  eligibility: {},
  services: [],
  service: {},
  editing: {},
}

let changes = null

export const rpaReducer = (state = DEFAULT_STATE, action) => {
  const response = action.payload && (action.payload.data || action.payload.response)

  switch (action.type) {

    case 'RPA_SERVICES_LOAD_PENDING':
    case 'RPA_SERVICE_UPDATE_PENDING':
      return { ...state, saving: true };

    case 'RPA_SERVICES_LOAD_REJECTED':
    case 'RPA_SERVICE_UPDATE_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = { saving: false }
      return { ...state, ...changes };

    case 'RPA_SERVICE_UPDATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      let index = state.services.findIndex(item => item.id === response.add_on.id)
      // replace updated record
      state.services[index] = response.add_on
      changes = { saving: false }
      return { ...state, ...changes };

    case 'RPA_SERVICES_LOAD_FULFILLED':
      changes = { services: response.add_ons, rpa_addon_id: response.add_ons[0]?.id, saving: false }
      return { ...state, ...changes };

    case 'RPA_EDIT':
      changes = { editing: { ...state.editing, ...action.data } }
      return { ...state, ...changes };

    case 'RPA_EDIT_RESET':
      changes = { editing: {} }
      return { ...state, ...changes };


    // verify
    case 'RPA_ELIGIBILITY_VERIFY_PENDING':
      return { ...state, verify: true };

    case 'RPA_ELIGIBILITY_VERIFY_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      if (response.data.status_code !== 500) {
        changes = { verify: false }
      }
      else {
        changes = { verify: false, eligibility: { ...state.eligibility, [getInsuranceType(response.data.insurance_type)]: { ...response.data } } }
      }
      return { ...state, ...changes };

    case 'RPA_ELIGIBILITY_VERIFY_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      changes = { verify: false, eligibility: { ...state.eligibility, [getInsuranceType(response.rpa_log.insurance_type)]: { ...response.rpa_log, success: true } } }
      return { ...state, ...changes };

    case 'RPA_DETAIL_VIEW':
      changes = { detailViewPopup: { ...state.detailViewPopup, ...action.data } }
      return { ...state, ...changes };

    case 'RPA_ELIGIBILITY_EDIT':
      changes = { eligibility: { ...state.eligibility, ...action.data } }
      return { ...state, ...changes };

    case 'RPA_ELIGIBILITY_RESET':
      changes = { eligibility: {} }
      return { ...state, ...changes };

    default:
      return state
  }
}