import {handleAPI, API_VERSION_ONE} from '../../../itrust_common/utils/apiUtils';

export function examsLoad(filters){
  const url = `/${API_VERSION_ONE}/examinations/`;
  const method = 'GET'

  return {
    type: 'EXAMS_LOAD',
    payload: handleAPI(url, filters, method)
  };
}

export function examLoad(id, filters){
  const url = `/${API_VERSION_ONE}/examinations/${id}`;
  const method = 'GET'

  return {
    type: 'EXAM_LOAD',
    payload: handleAPI(url,filters,method)
  };
}

export function examCreate(data, filters){
  const url = `/${API_VERSION_ONE}/examinations/`;
  const method= 'POST'
  return {
    type: 'EXAM_CREATE',
    payload: handleAPI(url,filters,method, {exam: {...data}})
  }
}

export function examUpdate(data, filters){
  const url = `/${API_VERSION_ONE}/examinations/${data.id}`;
  const method = "PUT"
  return {
    type: 'EXAM_UPDATE',
    payload: handleAPI(url,filters,method, {exam: {...data}})
  }
}

export function examDelete(id, filters) {
  const url = `/${API_VERSION_ONE}/examinations/${id}/`;
  const method = 'DELETE';

  return {
    type: 'EXAM_DELETE',
    payload: new Promise((resolve, reject) => {
      handleAPI(url, filters, method)
        .then(response => {
          resolve({ id: id, ...response });
        })
        .catch(err => {
          reject(err);
        });
    })
  };
}

export function examTemplateSwitched(data) {
  return {
    type: 'EXAM_TEMPLATE_SWITCHED',
    data
  };
}

export function examProcedureImpressionReorder(data) {
  return {
    type: 'EXAM_PROCEDURE_IMPRESSION_REORDER',
    data
  };
}

export function examEdit(id, data) {
  return {
    type: 'EXAM_EDIT',
    id,
    data
  };
}

export function examEditCancel() {
  return {
    type: 'EXAM_EDIT_CANCEL'
  };
}

export function examSecureFax(examId, filters){
  const url = `/${API_VERSION_ONE}/examinations/${examId}/fax_prescription_report`;
  const method = 'GET'

  return {
    type: 'EXAM_SECURE_FAX',
    payload: handleAPI(url,filters,method)
  };
}

export function emailPrescriptionReportSend(examId, filters){
  const url = `/${API_VERSION_ONE}/examinations/${examId}/email_prescription_report`;
  const method = 'GET'

  return {
    type: 'EMAIL_PRESCRIPTION_REPORT',
    payload: handleAPI(url,filters,method)
  };
}

export function examReset() {
  return {
    type: 'EXAM_RESET'
  };
}

export function examPdfLink(pateintId, filters){
  const url = `/${API_VERSION_ONE}/patients/${pateintId}/reports/print/`;
  const method = 'GET'

  return {
    type: 'EXAM_PDF_LINK',
    payload: handleAPI(url,filters,method)
  };
}