import { Component } from 'react';
export default class Value extends Component {

  render() {
    const {
      props: { input },
    } = this;
    return (
      input.value
    );
  }
}
