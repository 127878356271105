import Toastr from "../../../itrust_common/components/Toastr"
const DEFAULT_STATE = {
  orders: [],
  payments: [],
  order: {},
  net_sale_payments: [],
  day_end_report_payments: {},
  meta: { pagination: {} },
  saving: false,
  editing: false,
}
let changes = null;
export default function otherPaymentReportReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  switch(action.type){
    case 'ALL_PAYMENTS_LOAD_PENDING':
    case 'INSURANCE_BALANCE_DUE_LOAD_PENDING':
    case 'CASH_BALANCE_DUE_PENDING':
    case 'CLAIM_DETAILS_LOAD_PENDING':
    case 'NET_SALE_BY_PAYERS_LOAD_PENDING':
    case 'AGED_CLAIMS_LOAD_PENDING':
    case 'ORDER_DETAILS_LOAD_PENDING':
    case 'DAY_END_REPORT_LOAD_PENDING':
    case 'SELLING_REPORT_LOAD_PENDING':
      return {...state, saving: true};

    case 'ALL_PAYMENTS_LOAD_REJECTED':
    case 'INSURANCE_BALANCE_DUE_RECORDS_LOAD_REJECTED':
    case 'CASH_BALANCE_DUE_LOAD_REJECTED':
    case 'CLAIM_DETAILS_LOAD_REJECTED':
    case 'NET_SALE_BY_PAYERS_LOAD_REJECTED':
    case 'AGED_CLAIMS_LOAD_REJECTED':
    case 'ORDER_DETAILS_LOAD_REJECTED':
    case 'DAY_END_REPORT_LOAD_REJECTED':
    case 'SELLING_REPORT_LOAD_REJECTED':
    case 'SELLING_REPORT_PREVIEW_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case 'ALL_PAYMENTS_LOAD_FULFILLED':
    case 'INSURANCE_BALANCE_DUE_RECORDS_LOAD_FULFILLED':
    case 'CASH_BALANCE_DUE_LOAD_FULFILLED':
    case 'CLAIM_DETAILS_LOAD_FULFILLED':
    case 'AGED_CLAIMS_LOAD_FULFILLED':
      changes = {
        ...response,
        saving: false
      };
      return {...state, ...changes};

    case 'ORDER_DETAILS_LOAD_FULFILLED':
      changes = {
        ...response,
        saving: false
      };
      return {...state, ...changes};

    case 'NET_SALE_BY_PAYERS_LOAD_FULFILLED':
      changes = {
        net_sale_payments: response.records,
        saving: false
      };
      return {...state, ...changes};

    case 'DAY_END_REPORT_LOAD_FULFILLED':
      changes = {
        day_end_report_payments: response.records,
        saving: false
      };
      return {...state, ...changes};

    case 'SELLING_REPORT_LOAD_FULFILLED':
      changes = {
        selling_report_payments: response.records,
        saving: false
      };
      return { ...state, ...changes };

    case 'RESET_OTHER_PAYMENT_REPORT':
      return DEFAULT_STATE;

    case 'PAYMENT_REPORT_EDIT':
      return {...state, editing:action.payload}

    case 'PAYMENT_REPORT_EDIT_CANCEL':
      return {...state, editing: false }

    default:
      return state;
  }
}
