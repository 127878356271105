import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Field } from 'redux-form';
import Alert from '../../../../itrust_common/components/Alert';
import { Input } from '../../../../itrust_common/components/form';
import { required, maxLength160 } from '../../../../itrust_common/components/form/FormValidations';

let MessageForm = props => {
  const { handleSubmit, isPhonePresent } = props;
  return (
    <form onSubmit={handleSubmit} className="d-flex align-items-start p-5">
      <Field name="body" type="text" placeholder="Type your message...." component={Input} className="form-control" wrapperClasses="input-group rounded" autoComplete="off" validate={[required, maxLength160]} />
      {
        isPhonePresent ?
          <button type="submit" className="btn btn-light rounded border">
            <i className="la la-paper-plane align-middle py-1 text-muted" />
          </button>
          :
          <button type="button" onClick={() => Alert.warning("Patient phone number is not present!")} className="btn btn-light rounded border">
            <i className="la la-paper-plane align-middle py-1 text-muted" />
          </button>
      }
    </form>
  )
}

MessageForm = reduxForm({
  form: 'messageForm',
  shouldValidate: () => true
})(MessageForm)

const mapStateToProps = state => {
  const { patient } = state;
  return {
    patient: patient.patient,
  }
}

export default connect(mapStateToProps)(MessageForm);
