import React, { useState } from "react";
import "cropperjs/dist/cropper.css";
import * as $ from 'jquery'
import FileCrop from './FileCrop'
import FilePreview from './FilePreview'
import { filterByKey } from '../../HelperFunctions';
import Alert from "../../Alert";

// var allFiles = [];

export const FilesUpload = (props) => {
  const {label, labelClasses, imageClasses, wrapperClasses, input} = props
  const [image, setImage] = useState("");
  const [allFiles, setAllFiles] = useState([]);
  var id = input.name.replace(/\[/g,'').replace(/\]/g,'').replace(/\_/g,'')

  const onChange = (e) => {
    e.preventDefault();

    // get the files
    let files = e.target.files;

    // Process each file
    for (var i = 0; i < files.length; i++) {
      let file = files[i];

      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        let fileInfo = {
          filename: file.name,
          content_type: file.type,
          byte_size: file.size,
          file: reader.result,
          name: props.input.name.split('][').slice(-1)[0].split('_')[0]
        };
        setImage(reader.result)
        // Push it to the state
        // allFiles.push(fileInfo);

        setAllFiles([...allFiles, fileInfo])
        if(fileInfo.content_type.includes("image/")) $(`#${id}`).modal('show')
        else handleSubmit(fileInfo)
      }; // reader.onload
    } // for
  };


  const handleSubmit = (file) => {
    const {
      onChangeHook, input
    } = props;

    input.onChange([...props.input.value, file])
    if(onChangeHook)
      onChangeHook([...props.input.value, file])
  }

  const handleDelete = (updatedRecord) => {
    const { onChangeHook, input } = props;

    var updatedRecords = []
    var images = props.input.value
    for (const [index, image] of images.entries()) {
      if (image.id === updatedRecord.id) {
        updatedRecords = [
          ...images.slice(0, index),
          updatedRecord,
          ...images.slice(index + 1)
        ];
      }
    }

    input.onChange(updatedRecords)
    if(onChangeHook)
      onChangeHook(updatedRecords)
  }
  const deleteReciords = filterByKey(props.input.value, '_destroy', true)
  return (
    <div className={`image-upload ${wrapperClasses}`}>
      <div style={{ width: "100%" }}>
        <label className={labelClasses}>
          {label && label}
        </label>
        { 
          props.input && props.input.value && props.input.value.map((image)=>
            !image._destroy &&
            <div className="border border-light text-center text-muted d-flex align-items-start">
              <label className="btn" data-toggle="modal" data-target={`#file-preview-${(image.id || image.byte_size)}`}>
                <embed key={image.file.url || image.file} className={`${imageClasses}`} src={image.file.url || image.file}  style={{objectFit: "fit"}} ></embed>
              </label>
                <button
                  className="btn shadow-none font-size-20 px-3 py-0 m-0"
                  type="button"
                  onClick={() => Alert.confirm("Are you sure ? You want to delete this image.",
                    (res) => res && handleDelete({ ...image, _destroy: true }))} >
                  <i className="las la-times"></i>
                </button>
              <FilePreview id={ (image.id || image.byte_size ) } imageUrl={image.file.url || image.file}/>
            </div>
          )
        }
        <label className="w-100">
          <input
            type="file"
            onChange={(e) => onChange(e)}
            {...props}
            className="d-none"
          />
          { props.input && (props.input.value.length === deleteReciords.length) &&
            <div className="border border-light bg-light py-7 px-5 text-center text-muted pointer">
              <i className="la la-cloud-upload text-muted"/>
              <p>Browse from computer to upload file.</p>
            </div>
          }
          <p className="mb-0 py-2 px-5 bg-light pointer text-center">
            <i className="la la-sm la-cloud-upload text-muted"/> Add New File
          </p>
        </label>
        <FileCrop id={id} allFiles={allFiles} setAllFiles={setAllFiles} image={image} label={label} handleSubmit={handleSubmit}/>
      </div>
    </div>
  );
};

export default FilesUpload;
