import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import * as serviceWorker from './serviceWorker';
import state from './redux/store.js';
import ErrorBoundary from './itrust_common/utils/ErrorBoundary';
import { ClearCacheProvider } from 'react-clear-cache';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ErrorBoundary>
    <Provider store={state}>
      <ClearCacheProvider duration={900000}>
        <App />
      </ClearCacheProvider>
    </Provider>
  </ErrorBoundary>);

window.addEventListener("online", () => window.location.reload())
window.addEventListener("offline", () => window.location.reload())
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: registration => {
    // alert('You are accessing old version of iTRUST software. An update is available close the browser tab and open website URL again to reflect changes.');
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
    window.location.reload();
  }
});