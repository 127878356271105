import {handleAPI, API_VERSION_ONE} from '../../../itrust_common/utils/apiUtils';

export function claimFormLoad(orderId, filters){
  const url = `/${API_VERSION_ONE}/orders/${orderId}/insurance_claim_form`;
  const method = 'GET'

  return {
    type: 'CLAIM_FORM_LOAD',
    payload: handleAPI(url,filters,method)
  };
}

export function claimFormCreate(orderId, filters){
  const url = `/${API_VERSION_ONE}/orders/${orderId}/insurance_claim_form`;
  const method = 'POST'

  return {
    type: 'CLAIM_FORM_CREATE',
    payload: handleAPI(url,filters,method)
  };
}

export function claimFormUpdate(orderId, data, filters){
  const url = `/${API_VERSION_ONE}/orders/${orderId}/insurance_claim_form`;
  const method = "PUT"
  return {
    type: 'CLAIM_FORM_UPDATE',
    payload: handleAPI(url,filters,method, {insurance_claim_form: {...data}})
  }
}

export function claimFormSync(orderId, filters){
  const url = `/${API_VERSION_ONE}/orders/${orderId}/insurance_claim_form/sync`;
  const method = "GET"
  return {
    type: 'CLAIM_FORM_SYNC',
    payload: handleAPI(url,filters,method)
  }
}

export function claimFormSecureFax(orderId, filters){
  const url = `/${API_VERSION_ONE}/orders/${orderId}/insurance_claim_form/fax_pdf`;
  const method = 'GET'

  return {
    type: 'CLAIM_FORM_SECURE_FAX',
    payload: handleAPI(url,filters,method)
  };
}

export function trizettoSend(orderId, filters){
  const url = `/${API_VERSION_ONE}/orders/${orderId}/insurance_claim_form/send_to_trizzeto`;
  const method = 'GET'

  return {
    type: 'SEND_TO_TRIZETTO',
    payload: handleAPI(url,filters,method)
  };
}

export function claimFormReset() {
  return {
    type: 'CLAIM_FORM_RESET',
  };
}