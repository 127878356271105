import {handleAPI, API_VERSION_ONE_ADMIN} from '../../../utils/apiUtils';
const parent = 'SETTING_'

export function socialHistoriesLoad(filters){
  const url = `/${API_VERSION_ONE_ADMIN}/settings/social_histories/`;
  const method = 'GET'

  return {
    type: `${parent}SOCIAL_HISTORIES_LOAD`,
    payload: handleAPI(url, filters, method)
  };
}

export function socialHistoryLoad(id, filters){
  const url = `/${API_VERSION_ONE_ADMIN}/settings/social_histories/${id}`;
  const method = 'GET'

  return {
    type: `${parent}SOCIAL_HISTORY_LOAD`,
    payload: handleAPI(url,filters,method)
  };
}

export function socialHistoryCreate(data, filters){
  const url = `/${API_VERSION_ONE_ADMIN}/settings/social_histories/`;
  const method= 'POST'
  return {
    type: `${parent}SOCIAL_HISTORY_CREATE`,
    payload: handleAPI(url,filters,method, {social_history: {...data}})
  }
}

export function socialHistoryUpdate(data, filters){
  const url = `/${API_VERSION_ONE_ADMIN}/settings/social_histories/${data.id}`;
  const method = "PUT"
  return {
    type: `${parent}SOCIAL_HISTORY_UPDATE`,
    payload: handleAPI(url,filters,method, {social_history: {...data}})
  }
}

export function socialHistoryDelete(id, filters) {
  const url = `/${API_VERSION_ONE_ADMIN}/settings/social_histories/${id}/`;
  const method = 'DELETE';

  return {
    type: `${parent}SOCIAL_HISTORY_DELETE`,
    payload: new Promise((resolve, reject) => {
      handleAPI(url, filters, method)
        .then(response => {
          resolve({ id: id, ...response });
        })
        .catch(err => {
          reject(err);
        });
    })
  };
}

export function socialHistoryReorder(category, socialHistories) {
  return {
    type: `${parent}SOCIAL_HISTORY_REORDER`,
    socialHistories,
    category
  };
}

export function socialHistoryTypeChange(data) {
  return {
    type: `${parent}SOCIAL_HISTORY_TYPE_CHANGE`,
    data,
  };
}

export function socialHistoryEdit(id) {
  return {
    type: `${parent}SOCIAL_HISTORY_EDIT`,
    id,
  };
}

export function socialHistoryEditCancel() {
  return {
    type: `${parent}SOCIAL_HISTORY_EDIT_CANCEL`
  };
}
