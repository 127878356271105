import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import ExternalInternalForm from '../../../../itrust_common/components/authorised/examFormPartial/ExternalInternalForm';
import { timeFormat } from '../../../../itrust_common/components/HelperFunctions';

class ExamExternalInternalForm extends Component {
  componentWillUnmount(){
    if(this.props.dirty){
      this.props.handleSubmit();
    }
  } 

  render(){
    const { handleSubmit, template, exam } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <ExternalInternalForm pprops={this.props} exam={exam} template={template} isTemplate={false}/>
      </form>
    )
  }
}

ExamExternalInternalForm = reduxForm({
  form: 'examExternalInternalForm',
  enableReinitialize: true
})(ExamExternalInternalForm)

ExamExternalInternalForm = connect(state => {
  const {exam: { exam }} = state
  return {
    exam: exam,
    initialValues: {
      id: exam.id,
      external_attributes: exam.external_attributes,
      internal_attributes: {
        ...exam.internal_attributes,
        instilled_at: exam.internal_attributes?.instilled_at && timeFormat(exam.internal_attributes?.instilled_at)
      },
      gat: exam.gat,
      nct: exam.nct,
      visual_field: exam.visual_field
    }
  }
})(ExamExternalInternalForm)

export default ExamExternalInternalForm;