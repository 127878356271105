import {handleAPI, API_VERSION_ONE_ADMIN} from '../../../utils/apiUtils';
const parent = 'SETTING_'
export function patientTemplateLoad(filters){
  const url = `/${API_VERSION_ONE_ADMIN}/settings/patient_templates`;
  const method = 'GET'
  return {
    type: `${parent}PATIENT_TEMPLATE_LOAD`,
    payload: handleAPI(url,filters,method)
  };
}

export function patientTemplateUpdate(data, filters){
  const url = `/${API_VERSION_ONE_ADMIN}/settings/patient_templates/${data.id}`;
  const method = "PUT"
  return {
    type: `${parent}PATIENT_TEMPLATE_UPDATE`,
    payload: handleAPI(url,filters,method, {patient_template: {...data}})
  }
}

