import React, { Component } from 'react';

export default class Radio extends Component {
  render() {
    const { props, 
            props: { meta:{form, touched, error, warning }, input, disabled, wrapperClasses, labelClasses }
        } = this;
    const id = `${input.name}-${input.value}-${form}`
    return (
      <div className={`radio-btn ${disabled && 'disabled'} ${wrapperClasses}`}>
        <div className="custom-control custom-radio">
          <input {...input} id={id}  className="custom-control-input" type="radio" />
          <label className={`custom-control-label pt-1 ${labelClasses}`} htmlFor={id}>{props.label}</label>
          { touched &&
          ((error && <span className="invalid-feedback text-danger d-inline la-sm">{error}</span>) ||
            (warning && <span>{warning}</span>))}
        </div>
      </div>
    );
  }
}


