import React, { Component } from 'react';
import { submit } from 'redux-form';
import { connect } from 'react-redux';
import * as $ from 'jquery';

import { orderEditCancel, orderUpdate } from '../../../redux/actions/order/orderActions';
import OrderForm from '../order/OrderForm';

class OrderModal extends Component {

  handleRemoteSubmit(formName){
    this.props.dispatch(submit(formName))
  }
  handleOrderSubmit(data) {
    this.props.dispatch(orderUpdate(data, {include: '*'}))
  }

  handleOrderEditCancel() {
    this.props.dispatch(orderEditCancel())
  }

  componentDidUpdate() {
    if(!this.props.orderEditing){
      $('#orderForm-modal').modal('hide');  
    }
  }

  render() {
    const { order } = this.props;
    return (
      <div className="exam-setting-modal exam-modal">
        <div className="modal fade" id="orderForm-modal" data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="modalTitle" aria-hidden="true">
          <div className="modal-dialog m-0" role="document">
            <div className="modal-content bg-light">
              {order.id &&
                <OrderForm 
                  onSubmit={this.handleOrderSubmit.bind(this)}
                  onRemoteSubmit={this.handleRemoteSubmit.bind(this)}
                  handleOrderEditCancel={this.handleOrderEditCancel.bind(this)}
                  />
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { order } = state;
  return {
    order: order.order,
    orderEditing: order.editing
  };
}

export default connect(mapStateToProps)(OrderModal);