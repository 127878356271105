import React, {Component} from 'react';
import {API_VERSION_ONE_ADMIN} from '../../utils/apiUtils';
import {buildDownloadUrl} from '../../utils/apiHelperUtils';
const path = `/${API_VERSION_ONE_ADMIN}/settings/sample_export`

class SampleExportButton extends Component {
  render() {
    const { params, className, label } = this.props
    return(
      <a download className={`btn ${className}`} href={buildDownloadUrl(path, params)}>{label}</a>
    )
  }
}
export default SampleExportButton;