import Toastr from "../../../components/Toastr"
const parent = 'SETTING_'

const DEFAULT_STATE = {
  eyeglass_lens_frames: [],
  eyeglass_lens_frame: {},
  meta: {pagination: {}},
  saving: false
}
let changes = null;
export default function eyeglassLensFrameReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let eyeglassLensFrames = [];
  let eyeglassLensFrameData = [];
  let updatedRecord = {};
  switch(action.type){
    case `${parent}EYEGLASS_LENS_FRAMES_LOAD_PENDING`:
    case `${parent}EYEGLASS_LENS_FRAME_LOAD_PENDING`:
      return {...state, saving: false};

    case `${parent}EYEGLASS_LENS_FRAME_CREATE_PENDING`:
    case `${parent}EYEGLASS_LENS_FRAME_UPDATE_PENDING`:
      return {...state, saving: true};

    case `${parent}EYEGLASS_LENS_FRAMES_LOAD_REJECTED`:
    case `${parent}EYEGLASS_LENS_FRAME_LOAD_REJECTED`:
    case `${parent}EYEGLASS_LENS_FRAME_CREATE_REJECTED`:
    case `${parent}EYEGLASS_LENS_FRAME_UPDATE_REJECTED`:
    case `${parent}EYEGLASS_LENS_FRAME_DELETE_REJECTED`:
      Toastr.handleShow(response.data.success, response.data.error, response.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case `${parent}EYEGLASS_LENS_FRAMES_LOAD_FULFILLED`:
      changes = {
        ...response,
        saving: false
      };
      return {...state, ...changes};
    
    case `${parent}EYEGLASS_LENS_FRAME_LOAD_FULFILLED`:
      changes = {
        eyeglass_lens_frame: response.eyeglass_lens_frame,
        saving: false
      }
      return {...state,...changes};
    
    case `${parent}EYEGLASS_LENS_FRAME_CREATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      eyeglassLensFrames = [...state.eyeglass_lens_frames]
      let newRecord = response.eyeglass_lens_frame
      changes = {
        eyeglass_lens_frames: [...eyeglassLensFrames, newRecord],
        editing: false,
        saving: false
      }
      return {...state, ...changes};

    case `${parent}EYEGLASS_LENS_FRAME_UPDATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      eyeglassLensFrames = [...state.eyeglass_lens_frames];
      updatedRecord = response.eyeglass_lens_frame;
      for (const [index, eyeglassLensFrame] of eyeglassLensFrames.entries()) {
        if (eyeglassLensFrame.id === updatedRecord.id) {
          eyeglassLensFrameData = [
            ...eyeglassLensFrames.slice(0, index),
            updatedRecord,
            ...eyeglassLensFrames.slice(index + 1)
          ];
        }
      }
      changes = {
        eyeglass_lens_frames: eyeglassLensFrameData,
        editing: false,
        saving: false
      };
      return { ...state, ...changes };

    case `${parent}EYEGLASS_LENS_FRAME_DELETE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      eyeglassLensFrames = [...state.eyeglass_lens_frames];
      for (const [index, eyeglassLensFrame] of eyeglassLensFrames.entries()) {
        if (eyeglassLensFrame.id === response.eyeglass_lens_frame.id) {
          eyeglassLensFrames.splice(index, 1);
        }
      }
      return {
        ...state,
        eyeglass_lens_frames: eyeglassLensFrames,
        error: null
      };
      
    case `${parent}EYEGLASS_LENS_FRAME_DUPLICATE`:
      changes = {
        editing: {
          id: 'New',
        },
        eyeglass_lens_frame: {...action.eyeglassLensFrame, id: null},
      };
      return { ...state, ...changes };

    case `${parent}EYEGLASS_LENS_FRAME_EDIT`:
      changes = {
        editing: {
          id: action.id,
          form_view: action.formView
        },
        eyeglass_lens_frame: {  }
      };
      return { ...state, ...changes };

    case `${parent}EYEGLASS_LENS_FRAME_EDIT_CANCEL`:
      changes = {
        editing: false
      };
      return { ...state, ...changes };

    default:
      return state;
  }
}