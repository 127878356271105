import { handleAPI, API_VERSION_ONE } from '../../../../itrust_common/utils/apiUtils';

export function rpaClaimFormCreate(data, filters) {
  const url = `/${API_VERSION_ONE}/add_ons/rpa/vision_insurances`;
  const method = 'POST'
  return {
    type: 'RPA_CLAIM_FORM_CREATE',
    payload: handleAPI(url, filters, method, data)
  }
}

export function rpaClaimFormLoad(orderId, filters) {
  const url = `/${API_VERSION_ONE}/add_ons/rpa/vision_insurances/${orderId}/insurance_claim_form`;
  const method = 'GET'
  return {
    type: 'RPA_CLAIM_FORM_LOAD',
    payload: handleAPI(url, filters, method)
  }
}

export function rpaClaimFormUpdate(data, filters) {
  const url = `/${API_VERSION_ONE}/add_ons/rpa/vision_insurances/${data.id}`;
  const method = 'PUT'
  return {
    type: 'RPA_CLAIM_FORM_UPDATE',
    payload: handleAPI(url, filters, method, { claim_info: { ...data } })
  }
}

export function rpaClaimFormSubmit(data, filters) {
  const url = `/${API_VERSION_ONE}/add_ons/rpa/vision_insurances`;
  const method = 'POST'
  return {
    type: 'RPA_CLAIM_FORM_SUBMIT',
    payload: handleAPI(url, filters, method, data)
  }
}

export function rpaClaimFormTemplateLoad(filters) {
  const url = `/${API_VERSION_ONE}/add_ons/rpa/templates`;
  const method = 'GET'
  return {
    type: 'RPA_CLAIM_FORM_TEMPLATE_LOAD',
    payload: handleAPI(url, filters, method)
  }
}

export function rpaClaimFormEdit(data) {
  return {
    type: 'RPA_CLAIM_FORM_EDIT',
    data
  };
}

export function rpaClaimFormEditReset() {
  return {
    type: 'RPA_CLAIM_FORM_EDIT_RESET',
  };
}

export function rpaClaimFormReset() {
  return {
    type: 'RPA_CLAIM_FORM_RESET',
  };
}
