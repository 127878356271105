import React from 'react';
import LensView from './LensView'

let ContactLensPrescription = props => {
  const { fieldsValues, template } = props;
  return (
    <div className="row">
      {fieldsValues.map((contactLens, index) =>
        <div className={`col-12 py-4`}>
          <div><span>Contact Lens #{index + 1}</span></div>
          <LensView
            fieldsValues={contactLens}
            template={template.contact_lenses[0]}
          />
        </div>
      )}
    </div>
  )
}


export default ContactLensPrescription;