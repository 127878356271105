import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import asyncComponent from '../../../components/AsyncComponent';

const CalendarSetting = asyncComponent(() =>
  import('../../../containers/authorised/setting/calendarSetting/CalendarSetting')
)

const AnonymousBooking = asyncComponent(() =>
  import('../../../containers/authorised/setting/anonymousBooking/AnonymousBooking')
)

// const AnonymousPreview = asyncComponent(() =>
//   import('../../../../containers/public/anonymousBooking/Appointment')
// )

const calendarRoutes = (
  <Switch>
    <Route exact path="/settings/calendar/calendar-setting" component={CalendarSetting} />
    <Route exact path="/settings/calendar/anonymous-booking" component={AnonymousBooking} />
    {/* <Route exact path="/anonymous-preview" component={AnonymousPreview} /> */}
    <Redirect exact from="/settings/calendar" to="/settings/calendar/calendar-setting" />
  </Switch>
);

export default calendarRoutes;