import Toastr from "../../../../itrust_common/components/Toastr"

const DEFAULT_STATE = {
  meta: { pagination: {} },
  account_info: {},
  profile_info: {},
  patient: {},
  patient_chat_logs: [],
  logs: [],
  is_chat_initiated: {},
  notifications: [],
  saving: false,
  minimizeView: false,
}
let changes = null
let notificationsData = [];
let allNotifications = [];
let updatedRecord = {}


export const whatsAppReducer = (state = DEFAULT_STATE, action) => {
  const response = action.payload && (action.payload.data || action.payload.response)
  switch (action.type) {

    case 'WHATSAPP_PROFILE_LOAD_PENDING':
    case 'WHATSAPP_ACCOUNT_LOAD_PENDING':
    case 'WHATSAPP_LOGS_LOAD_PENDING':
    case 'WHATSAPP_PROFILE_UPDATE_PENDING':
    case 'WHATSAPP_PATIENT_LOAD_PENDING':
    case 'WHATSAPP_NOTIFICATION_LOAD_PENDING':
      return { ...state, saving: true };

    case 'WHATSAPP_ACCOUNT_LOAD_REJECTED':
    case 'WHATSAPP_LOGS_LOAD_REJECTED':
    case 'WHATSAPP_PROFILE_LOAD_REJECTED':
    case 'WHATSAPP_PROFILE_UPDATE_REJECTED':
    case 'WHATSAPP_PATIENT_LOAD_REJECTED':
    case 'WHATSAPP_CHAT_INITIATE_REJECTED':
    case 'PATIENT_WHATSAPP_LOGS_CREATE_REJECTED':
    case 'PATIENT_WHATSAPP_LOGS_LOAD_REJECTED':
    case 'WHATSAPP_NOTIFICATION_LOAD_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = { saving: false }
      return { ...state, ...changes };

    case 'WHATSAPP_ACCOUNT_LOAD_FULFILLED':
      changes = { account_info: response['add_ons/whatsapp/account'], saving: false }
      return { ...state, ...changes };

    case 'WHATSAPP_LOGS_LOAD_FULFILLED':
      changes = { logs: response['add_ons/whatsapp/logs'], meta: { ...state.meta, ...response.meta }, saving: false }
      return { ...state, ...changes };

    case 'WHATSAPP_PROFILE_LOAD_FULFILLED':
      changes = { profile_info: { ...response.add_ons[0]?.config.phone_number, id: response.add_ons[0]?.id, store_id: response.add_ons[0]?.store_id, avatar_attributes: response.add_ons[0]?.attachments_attributes?.[0]?.media_file_attributes }, saving: false }
      return { ...state, ...changes };

    case 'WHATSAPP_PROFILE_UPDATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      changes = { profile_info: { ...response.add_on.config.phone_number, id: response.add_on.id, store_id: response.add_on.store_id, avatar_attributes: response.add_on?.attachments_attributes?.[0]?.media_file_attributes }, saving: false }
      return { ...state, ...changes };

    case 'WHATSAPP_PATIENT_LOAD_FULFILLED':
      changes = { ...response, meta: { ...state.meta, ...response.meta } }
      return { ...state, ...changes };

    case 'PATIENT_WHATSAPP_LOGS_CREATE_FULFILLED':
      changes = { ...response }
      return { ...state, ...changes };

    case 'PATIENT_WHATSAPP_LOGS_LOAD_FULFILLED':
      changes = { patient_chat_logs: response['add_ons/whatsapp/logs'], is_chat_initiated: response.meta.is_chat_initiated, saving: false }
      return { ...state, ...changes };

    case 'WHATSAPP_NOTIFICATION_LOAD_FULFILLED':
      allNotifications = response.meta.messages_read === "All Messages are read" ? [...response.notifications] : [...state.notifications, ...response.notifications]
      changes = { ...response, notifications: [...allNotifications], saving: false };
      return { ...state, ...changes };

    case 'WHATSAPP_NOTIFICATION_UPDATE':
      allNotifications = [...state.notifications];
      updatedRecord = { ...action.data }
      let unreadCount = updatedRecord.read_at ? state.meta.unread_count - 1 : state.meta.unread_count + 1
      for (const [index, notification] of allNotifications.entries()) {
        if (notification.id === updatedRecord.id) {
          notificationsData = [
            ...allNotifications.slice(0, index),
            updatedRecord,
            ...allNotifications.slice(index + 1)
          ];
        }
      }
      changes = {
        notifications: notificationsData,
        meta: { ...state.meta, unread_count: unreadCount },
        saving: false
      };
      return { ...state, ...changes };

    case 'WHATSAPP_EDIT':
      changes = { ...action.data }
      return { ...state, ...changes };

    case 'WHATSAPP_LOG_UPDATE':
      let index = state.patient_chat_logs.findIndex((item) => item.id == action.data.id)
      if (state.patientProfile && state.patient.id === action.data.patient_id) {
        state.patient_chat_logs[index] = action.data
        changes = { patient_chat_logs: [...state.patient_chat_logs] }
      }
      return { ...state, ...changes };

    case 'WHATSAPP_NOTIFICATION_CREATE':
      updatedRecord = action.data.notification.activity_attributes.parameters
      // fixes for BE > One notification trigger multiple times
      if (state.last_notification === undefined || state.last_notification.id !== action.data.notification.id) {
        if (state.patientProfile && state.patient.id === updatedRecord.patient_id) {
          changes = { patient_chat_logs: [...state.patient_chat_logs, updatedRecord], last_notification: action.data.notification }
        }
        else {
          changes = { notifications: [action.data.notification, ...state.notifications], meta: { ...state.meta, unread_count: state.meta.unread_count + 1 } }
        }
      }
      changes = { ...changes, last_notification: action.data.notification }
      return { ...state, ...changes };

    default:
      return state
  }
}