import React from 'react'
import { connect } from 'react-redux'
import WhatsAppInProgress from './WhatsAppInProgress'
import LoginWithFacebook from './facebookLogin/LoginWithFacebook'

function WhatsAppActivationScreens(props) {
  const { isPopupScreen, dispatch, whatsAppAccountInfo, whatsAppProfileInfo } = props

  return (
    <>
      {whatsAppAccountInfo?.account_id ?
        <>
          {(whatsAppProfileInfo?.phone_number_id && whatsAppAccountInfo?.account_status === 'not_verified' )?
            <WhatsAppInProgress isPopupScreen={isPopupScreen} dispatch={dispatch} /> :
            <LoginWithFacebook whatsAppAccountInfo={whatsAppAccountInfo} isPopupScreen={isPopupScreen} dispatch={dispatch} />
          }
        </> :
        <LoginWithFacebook isPopupScreen={isPopupScreen} dispatch={dispatch} />
      }
    </>
  )
}

const mapStateToProps = (state) => {
  const { whatsApp } = state

  return {
    whatsAppAccountInfo: whatsApp.account_info,
    whatsAppProfileInfo: whatsApp.profile_info,
  }
}

export default connect(mapStateToProps)(WhatsAppActivationScreens)