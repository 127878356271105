const parent = 'APP_SETTING_'
const DEFAULT_STATE = {
  results: [],
  background: {},
  filters: {query: 'sea', page: 1}
}
let changes = null;
export default function unsplashReducer(state = DEFAULT_STATE, action){
  let results =[];
  switch(action.type){
    case `${parent}UNSPLASH_PHOTOS_LOAD_PENDING`:
    case `${parent}BACKGROUND_LOAD_PENDING`:
    case `${parent}BACKGROUND_UPDATE_PENDING`:
    case `${parent}UNSPLASH_PHOTOS_LOAD_REJECTED`:
    case `${parent}BACKGROUND_LOAD_REJECTED`:
    case `${parent}BACKGROUND_UPDATE_REJECTED`:
      changes = {
      }
      return {...state,...changes};
      
    case `${parent}BACKGROUND_LOAD_FULFILLED`:
      changes = {
        background: action.payload.data.background,
      };
      return { ...state, ...changes };
    
    case `${parent}UNSPLASH_PHOTOS_LOAD_FULFILLED`:
      results = action.payload.filters.page===1 ? action.payload.data.results : [ ...state[action.payload.categoryName].results, ...action.payload.data.results ]
      changes = {
        [action.payload.categoryName]: 
          { ...action.payload.data,
            filters: action.payload.filters,
            results
          }
      };
      return { ...state, ...changes };

    case `${parent}BACKGROUND_UPDATE_FULFILLED`:
        let updatedRecord = action.payload.data.background
        changes = {
          background: updatedRecord
        };
        return { ...state, ...changes };

    default:
      return state;
  }
}