import React, { useEffect } from 'react';
import ActionCable from 'actioncable';
import { WS_ROOT } from '../../../../env';
import { connect, useDispatch } from 'react-redux';
import { callPopUpEdit, callingInformationUpdate, incomingCall } from '../../../../redux/actions/addOns/voip/voipActions';
import { contactLoad } from '../../../../redux/actions/addOns/contactListsAction';
import { bandwidthRingtoneRinging } from './voipPopUp/BandwidthCallingHelpers';
import { twilioDeviceInitialization } from './voipPopUp/TwilioCallingHelpers';

function VoipStatusNotification(props) {
  const { currentUser, telecomServiceType } = props
  const dispatch = useDispatch()

  useEffect(() => {
    let accessToken = localStorage.getItem('Access-Token');
    (accessToken && accessToken !== 'null' && accessToken.length > 15) && handleActionCableCreate()
  }, [])

  const handleIncomingPatientLoad = (data) => {
    dispatch(contactLoad(data.patient_id, { include: '*' }))
  }

  const handleIncomingCall = async (data) => {
    if (telecomServiceType === 'twilio') {
      twilioDeviceInitialization(data.token, dispatch)
    }
    else {
      dispatch(callPopUpEdit('patient'))
      dispatch(callingInformationUpdate({ callStatus: 'ringing' }))
      bandwidthRingtoneRinging(true)
    }
    await dispatch(incomingCall(data))
    await data.patient_id && handleIncomingPatientLoad(data)
  }

  const handleCallReceived = async (data) => {
    if (!data.staff_id !== currentUser.id) {
      dispatch(callPopUpEdit(false))
      telecomServiceType === 'bandwidth' && bandwidthRingtoneRinging(false)
    }
    else {
      dispatch(callingInformationUpdate({ callStatus: 'call_started', isCallActive: true }))
    }
  }

  const handleUnreceivedCall = async (data) => {
    bandwidthRingtoneRinging(false)
    dispatch(callingInformationUpdate({ callStatus: 'call_ended', isCallActive: false }))
    dispatch(callPopUpEdit(false))
  }

  const handleActionCableCreate = () => {
    setTimeout(function () {
      var cable = ActionCable.createConsumer(getWebSocketURL());
      cable.subscriptions.create(
        {
          channel: 'VoipChannel',
        },
        {
          connected: function () {
            console.log('Voip Socket Connected');
          },
          disconnected: function () {
            console.log('Voip Socket Disconnected');
          },
          received: function (data) {
            data = JSON.parse(data.data)
            if (data.type === "incoming_call") {
              handleIncomingCall(data)
            }
            if (data.type === "call_received") {
              handleCallReceived(data)
            }
            if (data.type === "call_failed" || data.type === "missed_call") {
              handleUnreceivedCall(data)
            }
            console.log('notification received');
          },
          speak: function (message) {
          }
        }
      );
    }, 4000)
  }

  const getWebSocketURL = () => {
    const token = localStorage.getItem('Access-Token')
    return `${WS_ROOT}/cable?token=${token}&org=org&ch=call`
  }

  return (
    <></>
  )
}

const mapStateToProps = (state) => {
  const { auth, organisation: { organisation } } = state
  return {
    telecomServiceType: organisation.telcom_service_type,
    currentUser: auth.user,
  }
}

export default connect(mapStateToProps)(VoipStatusNotification)