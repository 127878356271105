import { handleAPI, API_VERSION_ONE } from '../../../../itrust_common/utils/apiUtils';

export function twoWayReportLoad(filters) {
  const url = `/${API_VERSION_ONE}/add_ons/two_way_message/messaging/dashboard`;
  const method = 'GET'

  return {
    type: 'TWO_WAY_REPORT_LOAD',
    payload: handleAPI(url, filters, method)
  }
}

export function twoWaySMSLogsLoad(filters) {
  const url = `/${API_VERSION_ONE}/add_ons/two_way_message/messaging/sms_logs`;
  const method = 'GET'

  return {
    type: 'TWO_WAY_SMS_LOGS_LOAD',
    payload: handleAPI(url, filters, method)
  };
}
export function twoWayNotificationLoad(filters) {
  const url = `/${API_VERSION_ONE}/notifications`;
  const method = 'GET'

  return {
    type: 'TWO_WAY_NOTIFICATION_LOAD',
    payload: handleAPI(url, filters, method)
  };
}

export function twoWayNotificationUpdate(data) {
  return {
    type: 'TWO_WAY_NOTIFICATION_UPDATE',
    data,
  };
}

export function twoWayNotificationCreate(data) {
  return {
    type: 'TWO_WAY_NOTIFICATION_CREATE',
    data
  };
}
