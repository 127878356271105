import {handleAPI, API_VERSION_ONE_ADMIN} from '../../../utils/apiUtils';
const parent = 'SETTING_'

export function medicationsLoad(filters){
  const url = `/${API_VERSION_ONE_ADMIN}/settings/medications`;
  const method = 'GET'

  return {
    type: `${parent}MEDICATIONS_LOAD`,
    payload: handleAPI(url, filters, method)
  };
}

export function medicationLoad(id, filters){
  const url = `/${API_VERSION_ONE_ADMIN}/settings/medications/${id}`;
  const method = 'GET'

  return {
    type: `${parent}MEDICATION_LOAD`,
    payload: handleAPI(url,filters,method)
  };
}

export function medicationCreate(data, filters){
  const url = `/${API_VERSION_ONE_ADMIN}/settings/medications`;
  const method= 'POST'
  return {
    type: `${parent}MEDICATION_CREATE`,
    payload: handleAPI(url,filters,method, {medication: {...data}})
  }
}

export function medicationUpdate(data, filters){
  const url = `/${API_VERSION_ONE_ADMIN}/settings/medications/${data.id}`;
  const method = "PUT"
  return {
    type: `${parent}MEDICATION_UPDATE`,
    payload: handleAPI(url,filters,method, {medication: {...data}})
  }
}

export function medicationDelete(id, filters) {
  const url = `/${API_VERSION_ONE_ADMIN}/settings/medications/${id}`;
  const method = 'DELETE';

  return {
    type: `${parent}MEDICATION_DELETE`,
    payload: new Promise((resolve, reject) => {
      handleAPI(url, filters, method)
        .then(response => {
          resolve({ id: id, ...response });
        })
        .catch(err => {
          reject(err);
        });
    })
  };
}

export function medicationEdit(id) {
  return {
    type: `${parent}MEDICATION_EDIT`,
    id,
  };
}

export function medicationEditCancel() {
  return {
    type: `${parent}MEDICATION_EDIT_CANCEL`
  };
}
