import Toastr from "../../../components/Toastr"
const parent = 'SETTING_'

const DEFAULT_STATE = {
  patient_reports: [],
  patient_report: {},
  meta: {pagination: {}},
  saving: false
}
let changes = null;
export default function patientReportReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let patientReports = [];
  let patientReportData = [];
  let updatedRecord = {};
  switch(action.type){
    case `${parent}PATIENT_REPORTS_LOAD_PENDING`:
    case `${parent}PATIENT_REPORT_LOAD_PENDING`:
      return {...state, saving: false};

    case `${parent}PATIENT_REPORT_CREATE_PENDING`:
    case `${parent}PATIENT_REPORT_UPDATE_PENDING`:
      return {...state, saving: true};

    case `${parent}PATIENT_REPORTS_LOAD_REJECTED`:
    case `${parent}PATIENT_REPORT_LOAD_REJECTED`:
    case `${parent}PATIENT_REPORT_CREATE_REJECTED`:
    case `${parent}PATIENT_REPORT_UPDATE_REJECTED`:
    case `${parent}PATIENT_REPORT_DELETE_REJECTED`:
      Toastr.handleShow(response.data.success, response.data.error, response.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case `${parent}PATIENT_REPORTS_LOAD_FULFILLED`:
      changes = {
        ...response,
        saving: false
      };
      return {...state, ...changes};
    
    case `${parent}PATIENT_REPORT_LOAD_FULFILLED`:
      changes = {
        patient_report: response.patient_report,
        saving: false
      }
      return {...state,...changes};
    
    case `${parent}PATIENT_REPORT_UPDATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      patientReports = [...state.patient_reports];
      updatedRecord = response.patient_report;
      for (const [index, patientReport] of patientReports.entries()) {
        if (patientReport.id === updatedRecord.id) {
          patientReportData = [
            ...patientReports.slice(0, index),
            updatedRecord,
            ...patientReports.slice(index + 1)
          ];
        }
      }
      changes = {
        patient_reports: patientReportData,
        saving: false,
        editing: false
      };
      return { ...state, ...changes };

    case `${parent}PATIENT_REPORT_EDIT`:
      changes = {
        editing: {
          id: action.id,
        },
        patient_report: {}
      };
      return { ...state, ...changes };

    case `${parent}PATIENT_REPORT_EDIT_CANCEL`:
      changes = {
        editing: true
      };
      return { ...state, ...changes };

    default:
      return state;
  }
}