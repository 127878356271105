import React from 'react';
import DisplayField from '../../../../../itrust_common/components/others/DisplayField'

let Default = props => {
  const { fieldsValues, template } = props;
  return (
    <div className="row">
      <DisplayField
        isVisible={template.images1.visible}
        label={`${template.images1.custom_label}:`}
        value={
          fieldsValues.images1_attributes.map((image)=>
            <img className="img-fluid mb-6" src={image.file.url} alt=""/>
          )}
        className="col-6 py-4"
      />
      <DisplayField
        isVisible={template.images2.visible}
        label={`${template.images2.custom_label}:`}
        value={
          fieldsValues.images2_attributes.map((image) =>
            <img className="img-fluid mb-6" src={image.file.url} alt="" />
          )}
        className="col-6 py-4"
      />
      <DisplayField
        isVisible={template.od_order.visible}
        label={`${template.od_order.custom_label}:`}
        value={fieldsValues.fields && fieldsValues.fields.od_order}
        className="col-6 py-4"
      />
      <DisplayField
        isVisible={template.os_order.visible}
        label={`${template.os_order.custom_label}:`}
        value={fieldsValues.fields && fieldsValues.fields.os_order}
        className="col-6 py-4"
      />
      <DisplayField
        isVisible={template.od_interpretation.visible}
        label={`${template.od_interpretation.custom_label}:`}
        value={fieldsValues.fields && fieldsValues.fields.od_interpretation}
        className="col-6 py-4"
      />
      <DisplayField
        isVisible={template.os_interpretation.visible}
        label={`${template.os_interpretation.custom_label}:`}
        value={fieldsValues.fields && fieldsValues.fields.os_interpretation}
        className="col-6 py-4"
      />
    </div>
  )
}
export default Default;
