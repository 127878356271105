import React from 'react';
import { useClearCache } from 'react-clear-cache';
import { connect } from 'react-redux';

const NewVersionPopUp = () => {
  const { isLatestVersion, emptyCacheStorage } = useClearCache();

  function clearCache() {
    setTimeout(() => {
      emptyCacheStorage()
    }, 5000);
  }

  return (
    <>
      {!isLatestVersion && clearCache()}
    </>
  );
};

const mapStateToProps = state => {
  return {
    state: state
  }
}

export default connect(mapStateToProps)(NewVersionPopUp);

