import React, { Component } from 'react';
import { connect } from 'react-redux';

class OverviewFooter extends Component {
  render() {
    const { staff_attributes } = this.props;
    return (
      <>
        <footer>
          <h6>Authorized By:</h6>
          <div className="d-flex flex-column">
            {staff_attributes.signature_attributes ?
              <div className="py-2">
                <img className="img-fluid" style={{ height: '6rem' }} src={staff_attributes.signature_attributes.file.url} alt="" signature />
              </div> :
              <em className="py-6 px-5 signature font-size-30">{staff_attributes.name}</em>
            }
            <div className="d-flex align-items-baseline">
              <h6 className="mr-4">{staff_attributes.name}</h6>
              <h6 className="mr-4">Lic #: </h6>
              <span className="mr-4">{staff_attributes.license_number}</span>
              <h6 className="mr-4">NPI: </h6>
              <span className="mr-4">{staff_attributes.npi}</span>
            </div>
          </div>
        </footer>
      </>
    )
  }
}

OverviewFooter = connect(state => {
  const { exam } = state
  return {
    exam: exam.exam,
    staff_attributes: exam.exam.staff_attributes
  }
})(OverviewFooter)

export default OverviewFooter;
