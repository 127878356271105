import React, {Component} from 'react';
import {connect} from 'react-redux'
import { importLoad } from '../../redux/actions/importActions';
import Alert from '../Alert'
import { examImportDeviceData } from '../../redux/actions/exam/previousExamActions';

class ImportButton extends Component {

  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve({
      filename: file.name,
      content_type: file.type,
      byte_size: file.size,
      file: reader.result,
    });
    reader.onerror = error => reject(error);
  });
  
  async handleImport(fileEvent, params){
    var base64 = await this.toBase64(fileEvent)
    new Promise((resolve, reject) => {
      if (this.props.isDeviceData) {
        this.props.dispatch(examImportDeviceData({...params, file: base64}, {include: '*'}))
      } else {
      this.props.isScheduleJobImport == true ?  this.props.onImportHook({...params, file: base64}) : this.props.dispatch(importLoad({...params, file: base64}))
      .then(res => this.props.onImportHook())
      }
    })
  }

  handleChange(event){
    const { params } = this.props
    var fileEvent = event.target.files[0]
    Alert.confirm(
    <>
    { this.props.isDeviceData ?
      <div>
        <h6>You’re about to Import data that will change exam detail. Do you wish to proceed?</h6>
      </div>
      :
      <div>
        <h6>You’re about to import new data values. Do you wish to proceed?</h6>
        <span className="d-block">- Old data values will not be removed.</span>
        <span className="d-block">- New data values will be merged with old values.</span>
        <span className="d-block">- All duplicate values will be removed, keeping one unique record.</span>
        <span className="d-block">- Your report will be scheduled. Once the report has been successfully imported you will receive an email.</span>
      </div>
    }
    </>, (res) => res && this.handleImport(fileEvent, params))
    
  }

  render() {
    const { className, label } = this.props
    return(
      <div className="d-inline-block">
        <input 
          type="file" 
          className="d-none" 
          onChange={(event)=> this.handleChange(event)}
          onClick={(event)=> event.currentTarget.value = null}
        />
        <div className={`btn ${className}`}>
          {label}
        </div>
      </div>
    )
  }
}
export default connect()(ImportButton);