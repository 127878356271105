import Toastr from "../../../../itrust_common/components/Toastr";

const DEFAULT_STATE = {
  saving: false,
  editing: false,
}

let changes = null;

export const tabReducer = (state = DEFAULT_STATE, action) => {
  const response = action.payload && (action.payload.data || action.payload.response)
  switch (action.type) {

    default:
      return state
  }
}