import React, { useState } from 'react'

export default function ActiveCallButtons(props) {
  const { call, telecomServiceType } = props
  const [isMute, setIsMute] = useState(false)

  const handleMuteCall = async () => {
    telecomServiceType === 'bandwidth' ? call.muteAudio(true) : await call.mute(true)
    setIsMute(true)
  }

  const handleUnMuteCall = async () => {
    telecomServiceType === 'bandwidth' ? call.muteAudio(false) : await call.mute(false)
    setIsMute(false)
  }

  return (
    <>
      <button className='btn p-0 rounded-circle mr-5 font-size-20' onClick={isMute ? handleUnMuteCall : handleMuteCall}>
        {isMute ?
          <i className="las la-microphone-slash border border-dark rounded-circle text-dark pointer p-2"></i> :
          <i className="las la-microphone border border-dark rounded-circle text-dark pointer p-2"></i>
        }
      </button>
    </>
  )
}
