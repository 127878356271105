import React, { useRef, useEffect, useState } from "react";
import {
  addComment,
  BASEURL,
  getArticles,
  getTickets,
  reopenTicket,
  seenAllNotifications,
} from "../../utils/api/tickets";
import moment from "moment";
import { Field, reduxForm, reset } from "redux-form";
import { useDispatch } from "react-redux";
import ActionCable from "actioncable";
import { Textarea } from "../../../itrust_common/components/form";
import Attachments from "./Attachments";

let Articles = (props) => {
  const { ticketId, handleSubmit, view, setNotifications } = props;
  const [articles, setArticles] = useState([]);
  const [statusId, setStatusId] = useState([]);
  const [loading, setLoading] = useState(false);
  const [notActiveSince, setNotActiveSince] = useState();
  const dispatch = useDispatch();
  const bottomRef = useRef();
  useEffect(() => {
    if (ticketId !== undefined) {
      handleGetTicket(ticketId);
      handleGetArtciles(ticketId);
    }
  }, [ticketId]);

  useEffect(() => {
    bottomRef.current.scrollIntoView({ block: "end", behavior: "smooth" });
  }, [articles]);

  const handleGetArtciles = async (ticketId) => {
    setLoading(true);
    const response = await getArticles(ticketId);
    const lastUpdatedDate = response[response.length - 1].updated_at;
    setNotActiveSince(getDifferenceInDays(new Date(lastUpdatedDate)));
    setArticles(response);
    setLoading(false);
  };

  function getDifferenceInDays(date1) {
    const diffInMs = Math.abs(new Date() - date1);
    return diffInMs / (1000 * 60 * 60 * 24);
  }

  const storeSpecificTime = (time) => {
    if (localStorage.getItem("StoreTimezone")) {
      return moment(time).tz(localStorage.getItem("StoreTimezone")).format("L");
    } else {
      return moment(time).format("L");
    }
  };

  useEffect(() => {
    setTimeout(function () {
      var cable = ActionCable.createConsumer(
        `${BASEURL}cable?token=${localStorage.getItem("User-email")}`
      );
      cable.subscriptions.create(
        {
          channel: "OnlineNotificationsChannel",
        },
        {
          connected: function () {
            console.log("Support Socket connected");
          },
          disconnected: function () {
            console.log("Support Socket disconnected");
          },
          received: function (data) {
            if (view === "Tickets") {
              handleGetArtciles(ticketId);
              seenAllNotifications();
              setNotifications(false);
            }
            console.log(`notification received`);
          },
          speak: function (message) {
            return this.perform("speak", {
              message: message,
            });
          },
        }
      );
    }, 4000);
  }, []);

  const handleGetTicket = async (ticketId) => {
    const response = await getTickets(ticketId);
    setStatusId(response.state_id);
  };
  const handleReopenTicket = async (ticketId) => {
    const response = await reopenTicket(ticketId);
    if (response.ok) {
      handleGetArtciles(ticketId);
      handleGetTicket(ticketId);
    }
  };
  const submitComment = async (data) => {
    data.article.subject = "Comment from user";
    data.article.content_type = "text/html";
    data.article.internal = "false";
    data.all = "true";
    const response = await addComment(data, ticketId);
    if (response.ok) {
      handleGetArtciles(ticketId);
      dispatch(reset("createComment"));
    }
  };
  return (
    <>
      <div className="bg-white w-96 box-border summary position-absolute shadow-lg">
        <div className="divide-y divide-black">
          <p className=" text-sm py-2 px-2 text-center border-bottom">
            Ticket Comments
          </p>
          <div
            className="block overflow-auto h-71 hide-scroller"
            id="scroll-to-bottom"
          >
            {loading && (
              <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
            {articles &&
              articles.map((article, index) => (
                <div
                  className={` py-2 ${index % 2 === 0 ? "active-record" : ""}`}
                >
                  <p
                    className={`py-2 px-4 text-sm text-gray4 mb-0 ${
                      article.sender === "Customer" ? "text-right" : "text-left"
                    }`}
                    dangerouslySetInnerHTML={{ __html: article.body }}
                  ></p>
                  {article.attachments.length > 0 && (
                    <>
                    <div className={`${article.sender === "Customer" ? "text-right" : "text-left"}`}>
                      <Attachments article={article} />
                    </div>
                    </>
                  )}
                  <div
                    className={`d-flex items-center ${
                      article.sender === "Customer"
                        ? "justify-content-end pr-4 "
                        : "justify-content-start"
                    }`}
                  >
                    <p
                      className={`text-xxs pl-4 pr-1 py-1 mb-0 text-gray4 ${
                        article.sender === "Customer"
                          ? "text-right"
                          : "text-left"
                      }`}
                    >
                      {storeSpecificTime(article.created_at)}
                    </p>
                  </div>
                  <div
                    className={`d-flex items-center ${
                      article.sender === "Customer"
                        ? "justify-content-end pr-4"
                        : "justify-content-start pl-4"
                    }`}
                  >
                    <p className="text-sm text-gray4 pl-1 mb-0">
                      {article.from}
                    </p>
                  </div>
                </div>
              ))}
            <div ref={bottomRef} />
          </div>
        </div>
        {notActiveSince < 7 && (
          <>
            <hr
              className={`text-slate-500 ${statusId === 4 ? "mb-0" : "mb-2"}`}
            />
            {statusId === 4 ? (
              <>
                <p className="text-gray4 text-sm px-2 mb-0">
                  This ticket is closed as it was resolved. If you want to
                  reopen this ticket and add comment again. Please click this
                  button.
                </p>
                <div className="d-flex justify-content-end">
                  <button
                    className="bg-primary text-sm text-white px-3  my-1 border-0 mx-2 rounded-md mb-2"
                    onClick={() => handleReopenTicket(ticketId)}
                  >
                    Reopen
                  </button>
                </div>
              </>
            ) : (
              <form
                onSubmit={handleSubmit(submitComment)}
                className="d-flex align-items-center justify-content-between"
              >
                <Field
                  name="article.body"
                  component={Textarea}
                  className="comment-box-width pl-4 border-0 hide-scroller"
                  placeholder="Add comments..."
                />
                <button type="submit" className="px-2 border-0 bg-white">
                  <i className="las la-paper-plane text-primary"></i>
                </button>
              </form>
            )}
          </>
        )}
      </div>
    </>
  );
};

Articles = reduxForm({
  form: "createComment",
  enableReinitialize: false,
})(Articles);

export default Articles;
