const email = localStorage.getItem("User-email");
const firstname = localStorage.getItem("User-firstname");
const lastname = localStorage.getItem("User-lastname");
const organisationName = window.location.host.split(".")[0];
export const BASEURL = 'https://support.itrust.io/'

export async function getAllTickets() {
  const url = `${BASEURL}api/v1/tickets`;
  const res = await fetch(url, {
    method: "GET",
    headers: {
      "user-email": email,
      "user-firstname": firstname,
      "user-lastname": lastname,
      "user-type": "Customer",
      "user-org": organisationName,
    },
  });
  const data = await res.json();
  return data;
}
export async function getTickets(id) {
  const url = `${BASEURL}api/v1/tickets/${id}`;
  const res = await fetch(url, {
    method: "GET",
    headers: {
      "user-email": email,
      "user-firstname": firstname,
      "user-lastname": lastname,
      "user-type": "Customer",
      "user-org": organisationName,
    },
  });
  const data = await res.json();
  return data;
}

export async function getArticles(id) {
  const url = `${BASEURL}api/v1/ticket_articles/by_ticket/${id}`;
  const res = await fetch(url, {
    method: "GET",
    headers: {
      "user-email": email,
      "user-type": "Customer",
      "user-org": organisationName,
    },
  });
  const data = await res.json();
  return data;
}

export async function createTicket(data) {
  const url = `${BASEURL}api/v1/tickets`;
  const res = await fetch(url, {
    method: "POST",
    headers: {
      "user-email": email,
      "user-firstname": firstname,
      "user-lastname": lastname,
      "user-type": "Customer",
      "user-org": organisationName,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return res;
}

export async function addComment(data, id) {
  const url = `${BASEURL}api/v1/tickets/${id}`;
  const res = await fetch(url, {
    method: "PUT",
    headers: {
      "user-email": email,
      "user-firstname": firstname,
      "user-lastname": lastname,
      "user-type": "Customer",
      "user-org": organisationName,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return res;
}

export async function reopenTicket(id) {
  const url = `${BASEURL}api/v1/tickets/${id}`;
  const res = await fetch(url, {
    method: "PUT",
    headers: {
      "user-email": email,
      "user-firstname": firstname,
      "user-lastname": lastname,
      "user-type": "Customer",
      "user-org": organisationName,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      state: "open",
    }),
  });
  return res;
}

export async function getNotifications() {
  const url = `${BASEURL}api/v1/online_notifications`;
  const res = await fetch(url, {
    method: "GET",
    headers: {
      "user-email": email,
      "user-firstname": firstname,
      "user-lastname": lastname,
      "user-type": "Customer",
      "user-org": organisationName,
    },
  });
  const data = await res.json();
  return data;
}

export async function seenAllNotifications() {
  const url = `${BASEURL}api/v1/online_notifications/mark_all_as_read`;
  const res = await fetch(url, {
    method: "POST",
    headers: {
      "user-email": email,
      "user-firstname": firstname,
      "user-lastname": lastname,
      "user-type": "Customer",
      "user-org": organisationName,
    },
    body: "",
  });
  const data = await res.json();
  return data;
}
