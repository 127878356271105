import React, { Component } from 'react';
import { connect } from 'react-redux';
import { unsplashPhotosLoad } from '../../redux/actions/UnsplashActions';
import { currentUserUpdate } from '../../redux/actions/authActions';
import Search from './Search';
import * as $ from 'jquery'
class BackgroundSelector extends Component {

  handleUnsplashPhotosLoad(query, page, categoryName = 'user') {
    this.props.dispatch(unsplashPhotosLoad({ query: query, page: page }, categoryName));
  }

  handleCurrentUserUpdate(data) {
    const { dispatch, currentUser: { preferences } } = this.props
    if (process.env.REACT_APP_SUITE_NAME === "OWNER-SUITE")
      dispatch(currentUserUpdate(data))
    else
      dispatch(currentUserUpdate({ preferences: { ...preferences, background: { ...preferences.background, ...data } } }))
  }

  componentDidMount() {
    var _this = this;
    $('.unsplash-scroller').scroll(function () {
      var heightCheck =
        $('.unsplash-scroller').scrollTop() + $('.unsplash-scroller').height() >
        $('.unsplash-scroller')[0].scrollHeight - 150;
      if (
        heightCheck &&
        _this.props.unsplashWallpapers.results.length >= 10 &&
        _this.props.unsplashWallpapers.results.length < _this.props.unsplashWallpapers.total
      ) {
        _this.handleUnsplashPhotosLoad(_this.props.unsplashWallpapers.filters.query, _this.props.unsplashWallpapers.filters.page + 1)
      }
    });
  }
  render() {
    const { unsplashWallpapers, currentUser, currentUser: { preferences } } = this.props
    return (
      <div className="modal fade customise-background" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content bg-light">
            <div className="modal-header background-change-modal bg-light py-4 position-fixed">
              <h5 className="modal-title" id="exampleModalLabel">Change Background</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body pt-0 pb-3 px-0 overflow-auto modal-body-height">
              <div className="position-fixed backgound-search py-5 px-7 bg-light">
                <div className="mb-5">

                  {process.env.REACT_APP_SUITE_NAME === "OWNER-SUITE" ?
                    <>
                      {currentUser.background_url ?
                        <img className="img-fluid rounded w-100 selected-image-height" src={currentUser.background_url} alt="wallpaper" style={{ height: '14.375rem' }} /> :
                        <div className="bg-light text-center w-100 py-9" style={{ height: '14.375rem' }}>No wallpaper Selected</div>
                      }
                    </> :
                    <>
                      {preferences?.background?.background_url ?
                        <img className="img-fluid rounded w-100 selected-image-height" src={preferences?.background?.background_url} alt="wallpaper" style={{ height: '14.375rem' }} /> :
                        <div className="bg-light text-center w-100 py-9" style={{ height: '14.375rem' }}>No wallpaper Selected</div>}
                    </>
                  }
                </div>
                <Search placeholder="Type to Search..." onChangeHook={(query, page = 1) => this.handleUnsplashPhotosLoad(query, page)} />
              </div>
              <div className="row unsplash-scroller overflow-auto background-wallpaper-container py-4 px-7" style={{ height: '35rem' }}>
                {unsplashWallpapers && unsplashWallpapers.results.map(wallpaper =>
                  <div className="col-6 mt-5 px-3">
                    <div className="w-100">
                    </div>
                    {wallpaper.urls &&
                      <img className="unsplash-wallpaper img-fluid pointer" src={`${wallpaper.urls.small}`} alt="unsplash" onClick={this.handleCurrentUserUpdate.bind(this, { background_url: wallpaper.urls.full })} />
                    }
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

};

BackgroundSelector = connect(state => {
  const { unsplash: { user }, auth } = state
  return {
    unsplashWallpapers: user,
    currentUser: auth.user
  }
})(BackgroundSelector)

export default BackgroundSelector;
