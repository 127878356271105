import React from 'react';
import { Route, Switch } from 'react-router-dom';
import asyncComponent from '../../itrust_common/components/AsyncComponent';

const ReferralPdf = asyncComponent(() =>
  import('../../containers/pdf/ReferralPdf')
);

const ExamOverviewPdf = asyncComponent(() =>
  import('../../containers/pdf/ExamOverviewPdf')
);

const ExamRoutes = (
  <Switch>
    <Route exact path="/exams/:id/referrals/:referral_id" component={ReferralPdf} />
    <Route exact path="/exams/:id/overview" component={ExamOverviewPdf} />
  </Switch>
);

export default ExamRoutes;