import React, { Component } from 'react';
import { reduxForm, change, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import RefractionContactLensForm from '../../../../itrust_common/components/authorised/examFormPartial/RefractionContactLensForm';

class ExamRefractionContactLensForm extends Component {

  copyToCategory(source, destination){
    var sourceObj = eval(`this.props.refractionContactLensValues.${source}`);

    // OD OS Nodes copying
    ['od', 'os'].map(type=>{
      const sphereNode = sourceObj[`${type}_sphere`]
      const cylinderNode = sourceObj[`${type}_cylinder`]
      const axisNode = sourceObj[`${type}_axis`]
      const addNode = sourceObj[`${type}_add`]
      const prismNode = sourceObj[`${type}_prism`]
      const distanceNode = sourceObj['visual_acuity'] && sourceObj['visual_acuity'][`${type}_distance`]
      const nearNode = sourceObj['visual_acuity'] && sourceObj['visual_acuity'][`${type}_near`]
      return[
        this.props.dispatch(change('examRefractionContactLensForm', `${destination}[${type}_sphere]`, sphereNode)),
        this.props.dispatch(change('examRefractionContactLensForm', `${destination}[${type}_cylinder]`, cylinderNode)),
        this.props.dispatch(change('examRefractionContactLensForm', `${destination}[${type}_axis]`, axisNode)),
        this.props.dispatch(change('examRefractionContactLensForm', `${destination}[${type}_add]`, addNode)),
        this.props.dispatch(change('examRefractionContactLensForm', `${destination}[${type}_prism]`, prismNode)),
        this.props.dispatch(change('examRefractionContactLensForm', `${destination}[visual_acuity][${type}_distance]`, distanceNode)),
        this.props.dispatch(change('examRefractionContactLensForm', `${destination}[visual_acuity][${type}_near]`, nearNode)),
      ]
    })

    // OU Nodes copying
    const ouDistanceNode = sourceObj['visual_acuity'] && sourceObj['visual_acuity'][`ou_distance`]
    const ouNearNode = sourceObj['visual_acuity'] && sourceObj['visual_acuity'][`ou_near`]
    this.props.dispatch(change('examRefractionContactLensForm', `${destination}[visual_acuity][ou_distance]`, ouDistanceNode))
    this.props.dispatch(change('examRefractionContactLensForm', `${destination}[visual_acuity][ou_near]`, ouNearNode))

    //Glass type and Note node copying
    const glassTypeNode = sourceObj['glass_type']
    const noteNode = sourceObj[`notes`]
    this.props.dispatch(change('examRefractionContactLensForm', `${destination}[glass_type]`, glassTypeNode))
    this.props.dispatch(change('examRefractionContactLensForm', `${destination}[notes]`, noteNode))

    this.props.dispatch(change('examRefractionContactLensForm', `${destination}[is_marked_complete]`, true))
  }

  componentWillUnmount(){
    if(this.props.dirty){
      this.props.handleSubmit();
    }
  }

  render(){
    const { handleSubmit, template } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <RefractionContactLensForm pprops={this.props} copyToCategory={this.copyToCategory.bind(this)} template={template} isTemplate={false}/>
      </form>
    )
  }
}

ExamRefractionContactLensForm = reduxForm({
  form: 'examRefractionContactLensForm',
  enableReinitialize: true
})(ExamRefractionContactLensForm)

const selector = formValueSelector('examRefractionContactLensForm') 
ExamRefractionContactLensForm = connect(state => {
  const {exam: { exam }} = state
  const refractionContactLensValues = selector(state, 'current_glasses_attributes', 'objective_attributes', 'subjective_attributes', 'binocular_balance_attributes', 'cycloplegic_attributes')
  return {
    refractionContactLensValues,
    lensesAttributes: exam.current_lenses_attributes,
    finalizeLensCount: exam.finalized_lenses_attributes.length,
    finalizeGlassCount: exam.finalized_glasses_attributes.length,

    initialValues: {
      id: exam.id,
      visual_acuity_without_correction: exam.visual_acuity_without_correction,
      visual_acuity_with_correction: exam.visual_acuity_with_correction,
      current_glasses_attributes: exam.current_glasses_attributes,
      pupillary_distance: exam.pupillary_distance,
      intraoffice_notes: exam.intraoffice_notes,
      objective_attributes: exam.objective_attributes,
      subjective_attributes: exam.subjective_attributes,
      binocular_balance_attributes: exam.binocular_balance_attributes,
      cycloplegic_attributes: exam.cycloplegic_attributes,
      keratometry_attributes: exam.keratometry_attributes,
      current_lenses_attributes: exam.current_lenses_attributes
    }
  }
})(ExamRefractionContactLensForm)

export default ExamRefractionContactLensForm;
