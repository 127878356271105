import React, { Component } from 'react';
import Procedure from './procedureImpressionPlan/Procedure'
import ImpressionPlan from './procedureImpressionPlan/ImpressionPlan'

import { FormTemplateHeader, FormTemplateSectionWrapper } from '../../others/'

class PrcedureImpressionPlanForm extends Component {
  render() {
    const { isTemplate, template } = this.props
    return (
      <div className="row">
        {
          (isTemplate || template.procedures_and_impressions["procedure"]["visible"] === "true" || template.procedures_and_impressions["procedure"]["visible"] === true) &&
          <div className="col-12 mt-5">
            <FormTemplateSectionWrapper isTemplate={isTemplate} fieldValues={template.procedures_and_impressions}>
              <div className="card border-0 shadow-sm px-5">
                <FormTemplateHeader
                  isTemplate={isTemplate}
                  fieldValues={template.procedures_and_impressions.procedure}
                  baseName="procedures_and_impressions[procedure]"
                  overviewName="procedure"
                />
                {
                  !isTemplate &&
                  <Procedure pprops={this.props} {...this.props} />
                }
              </div>
            </FormTemplateSectionWrapper>
          </div>
        }
        {
          (isTemplate || template.procedures_and_impressions["impression"]["visible"] === "true" || template.procedures_and_impressions["impression"]["visible"] === true) &&
          <div className="col-12 mt-7">
            <FormTemplateSectionWrapper isTemplate={isTemplate} fieldValues={template.procedures_and_impressions}>
              <div className="card border-0 shadow-sm px-5">
                <FormTemplateHeader
                  isTemplate={isTemplate}
                  fieldValues={template.procedures_and_impressions.impression}
                  baseName="procedures_and_impressions[impression]"
                  overviewName="impression" />
                {
                  !isTemplate &&
                  <ImpressionPlan pprops={this.props} {...this.props} />
                }
              </div>
            </FormTemplateSectionWrapper>
          </div>
        }
      </div>
    );
  }
}

export default PrcedureImpressionPlanForm;
