import React, { Component } from 'react';
import {connect} from 'react-redux';
import { toggleId, getKeyValues, isPresentInArray, checkSubsetExistInArray, search, buildOptions} from '../HelperFunctions';
import { dataLoad } from '../../redux/actions/autoCompleteActions';

var delayTimer
let filteredKeys=[]
let newSelectedItemIds=[]

class MultiSelect extends Component {
  constructor(props){
    super(props)
    this.state ={
      selectedItemIds: props.defaultValues ? props.defaultValues : [],
      filteredData: buildOptions(props.data, props.textField, props.valueField),
      dataKeyValue: buildOptions(props.data, props.textField, props.valueField),
      visible: false,
      search: null
    }
    this.handlePatientRedirect()
    this.searchInput = React.createRef();
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  handlePatientRedirect() {
    const { klass, isPatientRedirect } = this.props
    if (klass && klass === "Patient" && isPatientRedirect) {
      this.props.dispatch(dataLoad({ klass: klass, rpp: 5, search: isPatientRedirect.name }))
        .then((res) => this.setState({ filteredData: res.value.data.data }))
    }
  }

  placeholder(isToolTip) {
    const { data, valueField, textField, placeholder, isPatientRedirect } = this.props;
    const { selectedItemIds, dataKeyValue, filteredData } = this.state;
    const dataKeys = getKeyValues(data && data.length > 0 ? data : filteredData, valueField)
    if (isPatientRedirect && selectedItemIds.length > 0) {
      const selectedLabel = filteredData?.map(item => item.id === selectedItemIds[0] ? item.name : null)
      return (<span>{selectedLabel}</span>)
    }
    else {
      if (selectedItemIds.length > 0) {
        if (selectedItemIds.length === dataKeys.length)
          return (<span>All Selected</span>)
        else {
          const selectedLabel = data && data.length > 0 ? dataKeyValue.find((o) => selectedItemIds[0] === o[valueField]) : filteredData.find((o) => selectedItemIds[0] === o[valueField])
          if (selectedLabel) {
            if (selectedItemIds.length === 1) {
              return (<span>{selectedLabel[textField]}</span>)
            }
            return (<span><span>{selectedLabel[textField]}</span><span className={`pl-3 ${!isToolTip && 'text-muted label-increase bg-white position-sticky'}`}>(+{selectedItemIds.length - 1})</span></span>)
          }
        }
      }
      else
        return (<span className="place-visible">{placeholder}</span>)
    }
  }

  handleChecked(itemId){
    newSelectedItemIds = toggleId(this.state.selectedItemIds, itemId)
    this.setState({selectedItemIds: newSelectedItemIds})
    this.props.onSelectHook(newSelectedItemIds)
  }

  handleAllChecked(){
    var {selectedItemIds, filteredData} = this.state
    filteredKeys = getKeyValues(filteredData, this.props.valueField)
    if(checkSubsetExistInArray(filteredKeys, selectedItemIds)){
      newSelectedItemIds = [];
    }
    else{
      if(this.props.defaultValues) {
        newSelectedItemIds = [...new Set([...selectedItemIds, ...filteredKeys,...this.props.defaultValues])];
      }else{
        newSelectedItemIds = [...new Set([...selectedItemIds, ...filteredKeys])];

      }
    }
    this.setState({selectedItemIds: newSelectedItemIds})
    this.props.onSelectHook(newSelectedItemIds)
  }

  handleUserSearch(event) {
    const val = event.target.value;
    const {data, textField, klass} = this.props
    // var matches = search(this.state.dataKeyValue, textField, val)
    // this.setState({ filteredData: matches });
    this.setState({ search: val });

    if(data && data.length > 0)
      this.setState({ filteredData: search(this.state.dataKeyValue, textField, val) });
    else {
      let filters = { search: val, klass: klass }
      if (klass) {
        clearTimeout(delayTimer);
        delayTimer = setTimeout(function () {
          new Promise((resolve, reject) => {
            this.props.dispatch(dataLoad(filters))
              .then(response => this.setState({ filteredData: response.value.data.data }))
          })
        }.bind(this), 500)
      }
    }
  }

  handleClick() {
    if (!this.state.visible) {
      // attach/remove event handler
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }

    this.setState(prevState => ({
       visible: !prevState.visible,
    }));
  }
  
  handleOutsideClick(e) {
    // ignore clicks on the component itself
    if (this.node.contains(e.target)) {
      return;
    }
    this.handleClick();
  }


  componentDidMount(){
    const { klass, rpp, valueField, showAllSelected } = this.props
    if(klass && klass !== "Patient") {
      new Promise((resolve, reject)=>{
        this.props.dispatch(dataLoad({klass:klass, rpp:rpp}))
          .then(response=> {
            this.setState({ filteredData: response.value.data.data });
            if (showAllSelected && response.value.data.data && response.value.data.data.length > 0) {
              this.setState({ selectedItemIds: getKeyValues(response.value.data.data, valueField) });
           }
          })
        }
      )
    }
  }

  render() {
    const {
      props,
      props: { disabled, wrapperClasses, label, valueField, textField, isSearchVisible, isAllSelectVisible, dropdownSearchPlaceholder },
    } = this;
    const {selectedItemIds, filteredData, visible } = this.state
    filteredKeys= getKeyValues(filteredData, valueField)

    return (
      <div className={`${disabled && 'disabled'} ${wrapperClasses}`} >
        {label !== undefined && <label>{props.label}</label>}
        <div className={`dropdown border position-relative rounded form-control ${props.disabled ? 'bg-light': ''}`} ref={node => { this.node = node; }}>
          <span
            className={`pointer selected overflow-hidden dropdown-toggle  d-block ${props.disabled ? 'disabled': ''}`}
            onClick={this.handleClick.bind(this)}
          >
            <span className="overflow-hidden mr-5 autocomplete-tooltip w-100">
              {this.placeholder()}
              {selectedItemIds.length !== 0 && <span className="tooltiptext">{this.placeholder(true)}</span> }
            </span>
            <span className="la la-angle-down pl-4 bg-white icon-position" />
          </span>
          <div className={`dropdown-menu multiSelectDropdown overflow-auto w-100 px-5 ${visible && "show"}`} >
            { isSearchVisible && <div className="input-group mb-5">
              <input ref={this.searchInput} value={this.state.search} onChange={this.handleUserSearch.bind(this)} type="text" className="form-control form-control-sm" placeholder={`${dropdownSearchPlaceholder ? dropdownSearchPlaceholder: "Type to search"}`}/>
              <div className="input-group-append">
                <span className="input-group-text search-button position-static bg-light py-0"><i className="la la-search text-muted" /></span>
              </div>
            </div> }

            { isAllSelectVisible && <div className="custom-control custom-checkbox mx-3" onClick={this.handleAllChecked.bind(this)}>
              <input type="checkbox" className="custom-control-input" value="checkedall"
                checked={checkSubsetExistInArray(filteredKeys,selectedItemIds)}
                />
              <label className="custom-control-label">Select All</label>
            </div> }  

            {filteredData.length > 0 ?
              filteredData.map(item => (
                <div className="custom-control custom-checkbox mx-3" onClick={ this.handleChecked.bind(this, eval(`item.${valueField}`))}>
                  <input type="checkbox" className="custom-control-input" 
                    checked={isPresentInArray(selectedItemIds, eval(`item.${valueField}`))}
                  />
                  <label className="custom-control-label">{eval(`item.${textField}`)}</label>
                </div>
              ))
              :
              this.state.search && <h6 className="w-100 text-center pb-5 pt-3">No Record found</h6>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(MultiSelect);
