import React, { Component } from 'react';
import { Field } from 'redux-form';
import {FormTemplateFields} from './index'
import {RadioGroup} from '../form/'

import {phoneRequired, required} from '../form/FormValidations'
import CopyToDestination from './CopyToDestination';
import { checkIsVisible } from '../HelperFunctions';
import PatientModeOfNotification from '../form/PatientModeOfNotification';


export default class FormTemplateField extends Component {

  renderTemplateFormField(isVisible){
    const {
      props,
      props: { type, baseName, wrapperClasses, fieldValues, hideIsRequired }
    } = this;
    switch(type){
      case "radioGroup":
        return(<RadioGroup 
          {...props}
          name={`${baseName}[default_value]`} 
          wrapperClasses={`label-icons ${wrapperClasses} ${!isVisible && 'opacity-5'}`}
          label={<FormTemplateFields isVisible={isVisible}
                  baseName={baseName} isRequired={fieldValues.required}/>
                }
          />)
      case 'mode_of_notification':
        return (<PatientModeOfNotification
          {...props}
          name={`${baseName}[default_value]`}
          wrapperClasses={`label-icons ${wrapperClasses} ${!isVisible && 'opacity-5'}`}
          label={<FormTemplateFields isVisible={isVisible} baseName={baseName} isRequired={fieldValues.required} hideIsRequired={hideIsRequired} isModeOfNotificationDefaultVal={true} defaultValueReadOnly={true} defaultValueDisabled={true}/>}
        />)
      default:
        return(<Field
          {...props}
          name={`${baseName}[default_value]`}
          wrapperClasses={`label-icons ${wrapperClasses} ${!isVisible && 'opacity-5'}`}
          label={<FormTemplateFields isVisible={isVisible}
            baseName={baseName} isRequired={fieldValues.required} hideIsRequired={hideIsRequired}/>
                }
        />)
    }
  }

  renderFormField(isVisible){
    const {
      props,
      props: { type, fieldValues, validate, isOpen, showCopy, isPhoneInput }
    } = this;
    var validateArr=validate || []
    const isRequired = fieldValues.required && checkIsVisible(fieldValues.required)

    switch(type){
      case "radioGroup":
        return(<RadioGroup 
          {...props}
          label={<span>{fieldValues.custom_label} <span className="text-danger">{isRequired ? " *" : ''}</span></span>}
          validate={isOpen && isRequired ? [...validateArr, required] : validate}
        />)
      case 'mode_of_notification':
        return (<PatientModeOfNotification
          {...props}
          label={<span>{fieldValues.custom_label} <span className="text-danger">{isRequired ? " *" : ''}</span></span>}
          validate={isOpen && isRequired ? [...validateArr, required] : validate}
        />)
      case "wysiwyg":
        return(
          <div className="col-12 px-7">
            <label>{fieldValues.custom_label}</label>
            <div
                dangerouslySetInnerHTML={{ __html: fieldValues.default_value }}
              />
          </div>
        )
      default:
        return(<Field
          {...props}
          label={
            <span>
              {fieldValues.custom_label} <span className="text-danger">{isRequired ? " *" : ''}</span>
              {showCopy?.destination && <CopyToDestination showCopy={showCopy} source={props.name} />}
            </span>
          }
          validate={isOpen && isRequired ? isPhoneInput ? [...validateArr, phoneRequired] : [...validateArr, required] : validate}
          labelClasses={!fieldValues.custom_label && 'mb-0 d-inline'}
        />)
    }
  }

  render() {
    const {
      props: { isTemplate, fieldValues }
    } = this;
    let isVisible = fieldValues && fieldValues.visible && fieldValues.visible.toString()==="true"
    return (
      isTemplate ?
      this.renderTemplateFormField(isVisible)
      :
        isVisible && this.renderFormField(isVisible)
    );
  }
}
