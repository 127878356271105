import Toastr from "../../../components/Toastr"
const parent = 'SETTING_'

const DEFAULT_STATE = {
  medications: [],
  medication: {},
  meta: {pagination: {}},
  saving: false
}
let changes = null;
export default function medicationReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let medications = [];
  let medicationData = [];
  let updatedRecord = {};
  switch(action.type){
    case `${parent}MEDICATIONS_LOAD_PENDING`:
    case `${parent}MEDICATION_LOAD_PENDING`:
      return {...state, saving: false};

    case `${parent}MEDICATION_CREATE_PENDING`:
    case `${parent}MEDICATION_UPDATE_PENDING`:
      return {...state, saving: true};

    case `${parent}MEDICATIONS_LOAD_REJECTED`:
    case `${parent}MEDICATION_LOAD_REJECTED`:
    case `${parent}MEDICATION_CREATE_REJECTED`:
    case `${parent}MEDICATION_UPDATE_REJECTED`:
    case `${parent}MEDICATION_DELETE_REJECTED`:
      Toastr.handleShow(response.data.success, response.data.error, response.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case `${parent}MEDICATIONS_LOAD_FULFILLED`:
      changes = {
        ...response,
        saving: false
      };
      return {...state, ...changes};
    
    case `${parent}MEDICATION_LOAD_FULFILLED`:
      changes = {
        medication: response.medication,
        saving: false
      }
      return {...state,...changes};
    
    case `${parent}MEDICATION_CREATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      medications = [...state.medications]
      let newRecord = response.medication
      changes = {
        medications: [...medications, newRecord],
        editing: false,
        saving: false
      }
      return {...state, ...changes};

    case `${parent}MEDICATION_UPDATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      medications = [...state.medications];
      updatedRecord = response.medication;
      for (const [index, medication] of medications.entries()) {
        if (medication.id === updatedRecord.id) {
          medicationData = [
            ...medications.slice(0, index),
            updatedRecord,
            ...medications.slice(index + 1)
          ];
        }
      }
      changes = {
        medications: medicationData,
        editing: false,
        saving: false
      };
      return { ...state, ...changes };

    case `${parent}MEDICATION_DELETE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      medications = [...state.medications];
      for (const [index, medication] of medications.entries()) {
        if (medication.id === response.medication.id) {
          medications.splice(index, 1);
        }
      }
      return {
        ...state,
        medications: medications,
        error: null
      };

    case `${parent}MEDICATION_EDIT`:
      changes = {
        editing: {
          id: action.id,
        },
        medication: {}
      };
      return { ...state, ...changes };

    case `${parent}MEDICATION_EDIT_CANCEL`:
      changes = {
        editing: false
      };
      return { ...state, ...changes };

    default:
      return state;
  }
}