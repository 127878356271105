import React from 'react';
import { Field, change } from 'redux-form';

import { InputWithIcon, AutoCompleteSelect } from '../../../../../../itrust_common/components/form';

import DiscountField from '../../DiscountField';

let Lens = props => {
  const { member, currentEyeglass, pprops} = props;
  return (
    <div className="row mx-0 bg-light">
      <div className="col-12 p-5">
        <h6>Lens</h6>
      </div>
      <Field 
        name={`${member}[lens_type_id]`} 
        displayName={`${member}[lens_type_name]`}
        component={AutoCompleteSelect} 
        onChangeHook={(data) => {pprops.dispatch(change('orderEyeglassForm', `${member}[lens_type_id]`, data.id)); setTimeout(() => pprops.submit(), 500) } }
        klass="Settings::EyeglassLensType"
        textField="lens_type" 
        valueField="id" 
        placeholder="Placeholder" 
        wrapperClasses="col-4 form-group" 
        className="form-control" 
        label="Type" 
        labelClasses="w-100" 
      />
      <Field name={`${member}[lens_type_unit_price]`} component={InputWithIcon} title="$" label="Unit Price" labelClasses="w-100" className="form-control disabled bg-light" wrapperClasses="col-2 px-2 form-group" />
      <Field name={`${member}[lens_type_copay]`} component={InputWithIcon} title="$" label="Copay" labelClasses="w-100" className="form-control" wrapperClasses="col-2 px-3 form-group" />
      <div className="col-3">
        <label>Discount</label>
        <DiscountField
          cashName={`${member}[lens_type_cash_discount]`}
          percentageName={`${member}[lens_type_percentage_discount]`}
          discountTypeName={`${member}[lens_type_discount_type]`}
          defaultType={currentEyeglass.lens_type_discount_type}
          prependClasses="py-2"
        />
      </div>

      <Field 
        name={`${member}[lens_material_id]`} 
        displayName={`${member}[lens_material_name]`}
        component={AutoCompleteSelect} 
        onChangeHook={(data) => {pprops.dispatch(change('orderEyeglassForm', `${member}[lens_material_id]`, data.id)); setTimeout(() => pprops.submit(), 500) } }
        klass="Settings::EyeglassLensMaterial"
        textField="material" 
        valueField="id" 
        placeholder="Placeholder" 
        wrapperClasses="col-4 form-group" 
        className="form-control" 
        label="Material" 
        labelClasses="w-100" 
      />
      <Field name={`${member}[lens_material_unit_price]`} component={InputWithIcon} title="$" label="Unit Price" labelClasses="w-100" className="form-control disabled bg-light" wrapperClasses="col-2 px-2 form-group" />
      <Field name={`${member}[lens_material_copay]`} component={InputWithIcon} title="$" label="Copay" labelClasses="w-100" className="form-control" wrapperClasses="col-2 px-3 form-group" />
      <div className="col-3">
        <label>Discount</label>
        <DiscountField
          cashName={`${member}[lens_material_cash_discount]`}
          percentageName={`${member}[lens_material_percentage_discount]`}
          discountTypeName={`${member}[lens_material_discount_type]`}
          defaultType={currentEyeglass.lens_material_discount_type}
          prependClasses="py-2"
        />
      </div>
    </div>
  )
}
export default Lens;