import React from "react";
import { Route, Switch } from 'react-router-dom';
import asyncComponent from '../../../itrust_common/components/AsyncComponent';

const Dashboard = asyncComponent(() =>
  import('../../../containers/authorised/addOns/twoWayMessage/dashboard/Dashboard')
)

const SMSLogsWrapper = asyncComponent(() =>
  import('../../../containers/authorised/addOns/twoWayMessage/SMSLogs/TwoWaySMSLogsWrapper')
)

const TwoWayRoutes = (
  <Switch>
    <Route exact path='/add-ons/two-way-message/dashboard' component={Dashboard} />
    <Route exact path='/add-ons/two-way-message/sms-logs' component={SMSLogsWrapper} />
  </Switch>
)

export default TwoWayRoutes;