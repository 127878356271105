import { handleAPI, API_VERSION_ONE } from "../../../itrust_common/utils/apiUtils";

export function patientPaymentMethodCreate(data,filters) {
  const url = `/${API_VERSION_ONE}/itrust_pay/customer_cards`
  const method = 'POST'
  return {
    type: 'PATIENT_PAYMENT_METHOD_CREATE',
    payload: handleAPI(url, filters, method, {...data})
  }
}

export function patientPaymentMethodDelete(filters) {
  const url = `/${API_VERSION_ONE}/itrust_pay/customer_cards/${filters.payment_method_id}`
  const method='DELETE'
  return{
    type: 'PATIENT_PAYMENT_METHOD_DELETE',
    payload: handleAPI(url,filters,method)
  }
}

export function patientPaymentMethodUpdate(data, filters) {
  const url = `/${API_VERSION_ONE}/itrust_pay/customer_cards/${data.payment_method_id}`
  const method = 'PUT'
  return {
    type: 'PATIENT_PAYMENT_METHOD_UPDATE',
    payload: handleAPI(url, filters, method, { card_details: { ...data } })
  }
}

export function patientPaymentInitialize(filters) {
  const url = `/${API_VERSION_ONE}/itrust_pay/customer_cards`
  const method = 'GET'
  return {
    type: 'PATIENT_PAYMENT_METHOD_INITIALIZE',
    payload: handleAPI(url, filters, method)
  }
}

export function patientPaymentMethodLoad(payment_method) {
  return {
    type: 'PATIENT_PAYMENT_METHOD_LOAD',
    payload: { data: payment_method }
  };
}

export function patientPaymentMethodReset() {
  return {
    type: 'PATIENT_PAYMENT_METHOD_RESET'
  };
}
