import React from 'react';
import DisplayField from '../../../../../itrust_common/components/others/DisplayField'

let Epilation = props => {
  const { fieldsValues, template } = props;
  return (
    <div className="row">
      <DisplayField
        isVisible={template.eye.visible}
        label={`${template.eye.custom_label}:`}
        value={fieldsValues.fields && fieldsValues.fields.eye}
        className="col-6 py-4"
      />
      <DisplayField
        isVisible={template.location.visible}
        label={`${template.location.custom_label}:`}
        value={fieldsValues.fields && fieldsValues.fields.location}
        className="col-6 py-4"
      />
      <DisplayField
        isVisible={template.number.visible}
        label={`${template.number.custom_label}:`}
        value={fieldsValues.fields && fieldsValues.fields.number}
        className="col-6 py-4"
      />
      <DisplayField
        isVisible={template.comments.visible}
        label={`${template.comments.custom_label}:`}
        value={fieldsValues.fields && fieldsValues.fields.comments}
        className="col-6 py-4"
      />
    </div>
  )
}
export default Epilation;