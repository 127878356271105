import {handleAPI, API_VERSION_ONE_ADMIN} from '../../../utils/apiUtils';
const parent = 'SETTING_'

export function proceduresLoad(filters){
  const url = `/${API_VERSION_ONE_ADMIN}/settings/procedures`;
  const method = 'GET'

  return {
    type: `${parent}PROCEDURES_LOAD`,
    payload: handleAPI(url, filters, method)
  };
}

export function procedureLoad(id, filters){
  const url = `/${API_VERSION_ONE_ADMIN}/settings/procedures/${id}`;
  const method = 'GET'

  return {
    type: `${parent}PROCEDURE_LOAD`,
    payload: handleAPI(url,filters,method)
  };
}

export function procedureCreate(data, filters){
  const url = `/${API_VERSION_ONE_ADMIN}/settings/procedures`;
  const method= 'POST'
  return {
    type: `${parent}PROCEDURE_CREATE`,
    payload: handleAPI(url,filters,method, {procedure: {...data}})
  }
}

export function procedureUpdate(data, filters){
  const url = `/${API_VERSION_ONE_ADMIN}/settings/procedures/${data.id}`;
  const method = "PUT"
  return {
    type: `${parent}PROCEDURE_UPDATE`,
    payload: handleAPI(url,filters,method, {procedure: {...data}})
  }
}

export function procedureDelete(id, filters) {
  const url = `/${API_VERSION_ONE_ADMIN}/settings/procedures/${id}`;
  const method = 'DELETE';

  return {
    type: `${parent}PROCEDURE_DELETE`,
    payload: new Promise((resolve, reject) => {
      handleAPI(url, filters, method)
        .then(response => {
          resolve({ id: id, ...response });
        })
        .catch(err => {
          reject(err);
        });
    })
  };
}

export function procedureReorder(procedures) {
  return {
    type: `${parent}PROCEDURE_REORDER`,
    procedures,
  };
}

export function procedureEdit(id) {
  return {
    type: `${parent}PROCEDURE_EDIT`,
    id,
  };
}

export function procedureEditCancel() {
  return {
    type: `${parent}PROCEDURE_EDIT_CANCEL`
  };
}
