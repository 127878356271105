import React from 'react';
import {FormTemplateField} from '../../../others/'
import {Textarea} from '../../../form'

let VisualSummaryField = props => {
  const { baseName, baseFieldValues, isTemplate, isOpen } = props;
  return (
    <div className="card-body">
      <div className="row">
        <FormTemplateField
          isTemplate={isTemplate}
          name={`${baseName}[fields][od_status]`}
          baseName={`${baseName}[fields][od_status]`}
          fieldValues={baseFieldValues['od_status']}
          type="radioGroup"
          wrapperClasses="col-6"
          options={[{label: 'Negative', value: "negative"}, {label: 'Positive', value: "positive"}]} 
         />
        <FormTemplateField
          isTemplate={isTemplate}
          name={`${baseName}[fields][os_status]`}
          baseName={`${baseName}[fields][os_status]`}
          fieldValues={baseFieldValues['os_status']}
          type="radioGroup"
          wrapperClasses="col-6"
          options={[{label: 'Negative', value: "negative"}, {label: 'Positive', value: "positive"}]} 
         />
        <FormTemplateField
          isTemplate={isTemplate}
          name={`${baseName}[fields][od_comments]`}
          baseName={`${baseName}[fields][od_comments]`}
          fieldValues={baseFieldValues['od_comments']}
          isOpen={isOpen}
          component={Textarea}
          rows={3}
          className="form-control"
          wrapperClasses="col-6 mt-5 label-icons form-group"
         />
        
        <FormTemplateField
          isTemplate={isTemplate}
          name={`${baseName}[fields][os_comments]`}
          baseName={`${baseName}[fields][os_comments]`}
          fieldValues={baseFieldValues['os_comments']}
          isOpen={isOpen}
          component={Textarea}
          rows={3}
          className="form-control"
          wrapperClasses="col-6 mt-5 label-icons form-group"
         />
        
      </div>
    </div>
  )
}
export default VisualSummaryField;