import Toastr from "../../../itrust_common/components/Toastr"
const DEFAULT_STATE = {
  patient_review_of_systems: [],
  patient_review_of_system: {},
  meta: { pagination: {} },
  saving: false
}
let changes = null;
export default function patientReviewOfSystemReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let patientReviewOfSystems = [];
  let patientReviewOfSystemData = [];
  let updatedRecord = {};
  switch(action.type){
    case 'PATIENT_REVIEW_OF_SYSTEMS_LOAD_PENDING':
    case 'PATIENT_REVIEW_OF_SYSTEM_LOAD_PENDING':
      return {...state, saving: true};

    case 'PATIENT_REVIEW_OF_SYSTEM_CREATE_PENDING':
      return {...state, saving: true};

    case 'PATIENT_REVIEW_OF_SYSTEMS_LOAD_REJECTED':
    case 'PATIENT_REVIEW_OF_SYSTEM_LOAD_REJECTED':
    case 'PATIENT_REVIEW_OF_SYSTEM_CREATE_REJECTED':
    case 'PATIENT_REVIEW_OF_SYSTEM_UPDATE_ALL_TO_NO_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case 'PATIENT_REVIEW_OF_SYSTEMS_LOAD_FULFILLED':
      changes = {
        ...response,
        saving: false
      };
      return {...state, ...changes};
    
    case 'PATIENT_REVIEW_OF_SYSTEM_LOAD_FULFILLED':
      changes = {
        patient_review_of_system: response.patient_review_of_system,
        saving: false
      }
      return {...state,...changes};
    
    case 'PATIENT_REVIEW_OF_SYSTEM_CREATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      patientReviewOfSystems = [...state.patient_review_of_systems]
      let newRecord = response.patient_review_of_system
      changes = {
        patient_review_of_systems: [...patientReviewOfSystems, newRecord],
        editing: false,
        saving: false
      }
      return {...state, ...changes};

    case 'PATIENT_REVIEW_OF_SYSTEM_UPDATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      patientReviewOfSystems = [...state.patient_review_of_systems];
      updatedRecord = response.patient_review_of_system;
      for (const [index, patientReviewOfSystem] of patientReviewOfSystems.entries()) {
        if (patientReviewOfSystem.id === updatedRecord.id) {
          patientReviewOfSystemData = [
            ...patientReviewOfSystems.slice(0, index),
            updatedRecord,
            ...patientReviewOfSystems.slice(index + 1)
          ];
        }
      }
      changes = {
        patient_review_of_systems: patientReviewOfSystemData,
        editing: false,
        saving: false
      };
      return { ...state, ...changes };

    case 'PATIENT_REVIEW_OF_SYSTEM_UPDATE_ALL_TO_NO_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      changes = {
        ...response,
        editing: false,
        saving: false
      };
      return { ...state, ...changes };

    case 'PATIENT_REVIEW_OF_SYSTEM_DELETE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      patientReviewOfSystems = [...state.patient_review_of_systems];
      for (const [index, patientReviewOfSystem] of patientReviewOfSystems.entries()) {
        if (patientReviewOfSystem.id === response.id) {
          patientReviewOfSystems.splice(index, 1);
        }
      }
      return {
        ...state,
        patient_review_of_systems: patientReviewOfSystems,
        error: null
      };

    case 'PATIENT_REVIEW_OF_SYSTEM_EDIT':
      changes = {
        editing: {
          id: action.id,
        },
        patient_review_of_system: { address_attributes: {country: "United States"} }
      };
      return { ...state, ...changes };

    case 'PATIENT_REVIEW_OF_SYSTEM_EDIT_CANCEL':
      changes = {
        editing: false
      };
      return { ...state, ...changes };

    default:
      return state;
  }
}