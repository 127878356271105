import React, { useState, useEffect } from 'react';

const ScrollToLastMessage = (props) => {
  const [showButton, setShowButton] = useState(false);
  const { chatContainerClass } = props

  useEffect(() => {
    const handleScroll = () => {
      const chatContainer = document.querySelector(`.${chatContainerClass}`); // Adjust this selector to your chat container class
      if (chatContainer) {
        const isAtBottom = chatContainer.scrollHeight - chatContainer.scrollTop <= chatContainer.clientHeight + 1;
        setShowButton(!isAtBottom);
      }
    };

    const chatContainer = document.querySelector(`.${chatContainerClass}`); // Adjust this selector to your chat container class
    if (chatContainer) {
      chatContainer.addEventListener('scroll', handleScroll);
      chatContainer.scrollTop = chatContainer.scrollHeight; // Automatically scroll to bottom on mount
      return () => chatContainer.removeEventListener('scroll', handleScroll);
    }
  }, []);

  const scrollToBottom = () => {
    const chatContainer = document.querySelector(`.${chatContainerClass}`); // Adjust this selector to your chat container class
    if (chatContainer) {
      const lastMessage = chatContainer.lastElementChild;
      if (lastMessage) {
        lastMessage.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    }
  };

  return (
    <>
      {showButton && (
        <button
          type='button'
          className='btn btn-light shadow-5 rounded-circle py-3 px-4'
          onClick={scrollToBottom}
        >
          <i className="las la-arrow-down font-size-24"></i>
        </button>
      )}
    </>
  );
};

export default ScrollToLastMessage;
