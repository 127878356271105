

let ScrollToTarget = target => {
  // const firstError = (Object.keys(errors))[0];
  // let el = document.querySelector(`[name="${firstError}"`)
  setTimeout(() => {
    let el;
    if (target)
      el = document.getElementById(target)
    else
      el = document.getElementsByClassName('error-msg')[0] || document.getElementsByClassName('is-invalid')[0] || document.getElementsByClassName('required-radio-btn')[0]
    if(el !== null && el !== undefined){
      el.scrollIntoView({
        smooth: true,
        block: target ? 'start' : 'center',
        offset: -200
      });
    }
  }, 100)
};
export default ScrollToTarget;
