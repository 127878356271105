import React from 'react'
import { whatsAppEdit } from '../../../../redux/actions/addOns/whatsApp/whatsAppActions'

export default function WhatsAppInProgress(props) {
  const { isPopupScreen, dispatch } = props

  return (
    <div className={`${!isPopupScreen && 'pt-7'}`}>
      {isPopupScreen && <div className='text-right bg-white'>
        <span onClick={() => dispatch(whatsAppEdit({ patientProfile: false }))}><i className="las la-times-circle font-size-20 text-primary pt-3 px-4 pointer"></i></span>
      </div>}
      <div className={`bg-white text-center d-flex flex-column justify-content-center align-items-center ${isPopupScreen ? '' : 'py-10'}`}>
        <i className={`la la-hourglass-half mb-4 text-blue ${isPopupScreen ? 'font-size-190' : 'font-size-360'}`}></i>
        <h1>Your Facebook Verification is in Progress</h1>
        <p className={`mt-4 ${isPopupScreen ? 'px-2' : 'w-50'}`}>
          Thank you for initiating the Facebook verification process. This typically takes 8-10 hours. We appreciate your patience and will notify you once the verification is complete.
        </p>
        <p>Thank you for your understanding.</p>
      </div>
    </div>
  )
}
