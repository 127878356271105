import React from 'react';
import DisplayField from '../../../../../itrust_common/components/others/DisplayField'

let VisualField = props => {
  const { fieldsValues, template} = props;
  return (
    <div className="row">
      <DisplayField
        isVisible={template.visible}
        // label={`${template.custom_label}:`}
        value={fieldsValues}
        className="col-12 py-4"
      />
    </div>
  )
}


export default VisualField;