import {handleAPI, API_VERSION_ONE_ADMIN} from '../../../utils/apiUtils';
const parent = 'SETTING_'

export function recommendationsLoad(filters){
  const url = `/${API_VERSION_ONE_ADMIN}/settings/recommendations`;
  const method = 'GET'

  return {
    type: `${parent}RECOMMENDATIONS_LOAD`,
    payload: handleAPI(url, filters, method)
  };
}

export function recommendationLoad(id, filters){
  const url = `/${API_VERSION_ONE_ADMIN}/settings/recommendations/${id}`;
  const method = 'GET'

  return {
    type: `${parent}RECOMMENDATION_LOAD`,
    payload: handleAPI(url,filters,method)
  };
}

export function recommendationCreate(data, filters){
  const url = `/${API_VERSION_ONE_ADMIN}/settings/recommendations`;
  const method= 'POST'
  return {
    type: `${parent}RECOMMENDATION_CREATE`,
    payload: handleAPI(url,filters,method, {recommendation: {...data}})
  }
}

export function recommendationUpdate(data, filters){
  const url = `/${API_VERSION_ONE_ADMIN}/settings/recommendations/${data.id}`;
  const method = "PUT"
  return {
    type: `${parent}RECOMMENDATION_UPDATE`,
    payload: handleAPI(url,filters,method, {recommendation: {...data}})
  }
}

export function recommendationDelete(id, filters) {
  const url = `/${API_VERSION_ONE_ADMIN}/settings/recommendations/${id}`;
  const method = 'DELETE';

  return {
    type: `${parent}RECOMMENDATION_DELETE`,
    payload: new Promise((resolve, reject) => {
      handleAPI(url, filters, method)
        .then(response => {
          resolve({ id: id, ...response });
        })
        .catch(err => {
          reject(err);
        });
    })
  };
}

export function recommendationReorder(recommendations) {
  return {
    type: `${parent}RECOMMENDATION_REORDER`,
    recommendations,
  };
}

export function recommendationEdit(id) {
  return {
    type: `${parent}RECOMMENDATION_EDIT`,
    id,
  };
}

export function recommendationEditCancel() {
  return {
    type: `${parent}RECOMMENDATION_EDIT_CANCEL`
  };
}
