import Toastr from "../../components/Toastr"
const DEFAULT_STATE = {
  saving: false
}
let changes = null;
export default function passwordReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  switch(action.type){
    case 'PASSWORD_UPDATE_PENDING':
      return {...state, saving: true};

    case 'PASSWORD_UPDATE_REJECTED':
    case 'PASSWORD_RESET_REJECTED':
    case 'PASSWORD_FORGOT_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};
    case 'PASSWORD_UPDATE_FULFILLED':
      Toastr.handleShow(response.success, response.message, response.status_code)
      changes = {
        saving: false
      };
      return { ...state, ...changes };
    case 'PASSWORD_FORGOT_FULFILLED':
        Toastr.handleShow(response.success, (response.error || response.message), response.status_code)
        changes = {
          saving: false
        };
        return { ...state, ...changes };

    case 'PASSWORD_RESET_FULFILLED':
      Toastr.handleShow(response.success, response.message, response.status_code)
      changes = {
        saving: false
      };
      return { ...state, ...changes };
    default: 
      return state;
  }
}