import React from 'react'
import { connect, useDispatch } from 'react-redux';
import Alert from '../../../../../../itrust_common/components/Alert';
import RPAVerifyButton from '../../eligibilityVerify/RPAVerifyButton';
import { rpaDetailView, rpaEligibilityEdit } from '../../../../../../redux/actions/addOns/rpa/rpaAction';

function TwentyTwentySource(props) {
  const { pprops, pprops: { selectedInsuranceName, selectedInsuranceMemberId, orderId, patient, insuranceType, handleInsuranceVerifySubmit, setShowVerifyBtn } } = props
  const dispatch = useDispatch()

  const handleVerifyInsurance = async () => {
    const insuranceRequiredInfo = (selectedInsuranceName && selectedInsuranceMemberId)
    const patientRequiredInfo = (patient.first_name && patient.last_name && patient.date_of_birth && patient.relation_to_insured)
    const relationInsurer = patient.relation_to_insured == 'self' ? true : (patient.primary_insurer.first_name && patient.primary_insurer.last_name && patient.primary_insurer.date_of_birth)

    if (insuranceRequiredInfo && patientRequiredInfo && relationInsurer) {
      let data = { member_id: selectedInsuranceMemberId }
      handleInsuranceVerifySubmit(data)
    }
    else {
      Alert.warning(<div>
        <span>Please make sure to Add and Save the following required Patient Information in Patient Profile to verify the Insurance:</span>
        <ul className='pl-6'>
          <li>First Name</li>
          <li>Last Name</li>
          <li>DOB</li>
          <li>{`${orderId ? 'Insurance Payer' : 'Insurance'}`}</li>
          <li>Insurance MemberID</li>
          <li>Patient Relationship to Insured</li>
          {!relationInsurer && <>
            <li>Insurer First Name</li>
            <li>Insurer Last Name</li>
            <li>Insurer DOB</li>
          </>}
        </ul>
      </div>
      )
    }
  }

  const handleReVerifyInsurance = () => {
    Alert.confirm('Are you sure you want to Re-Verify Insurance.', res => {
      if (res) {
        dispatch(rpaDetailView({ visible: false }))
        setShowVerifyBtn(true)
        dispatch(rpaEligibilityEdit({ [insuranceType]: null }))
        handleVerifyInsurance()
      }
    })
  }

  return (
    <>
      <RPAVerifyButton
        pprops={pprops}
        handleVerifyInsurance={handleVerifyInsurance}
        handleReVerifyInsurance={handleReVerifyInsurance}
      />
    </>
  )
}


export default connect()(TwentyTwentySource)