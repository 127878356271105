import {handleAPI, API_VERSION_ONE } from '../../utils/apiUtils';

export function login(data, filters) {
  const url = `/${API_VERSION_ONE}/sessions/`;
  const method = 'POST';
  return {
    type: 'LOGIN',
    payload: handleAPI(url, filters, method, data)
  };
} 

export function logout() {
  return {
    type: 'LOGOUT_FULFILLED',
    payload: true
  };
}

export function currentUserLoad(filters) {
  const url = `/${API_VERSION_ONE}/sessions/get_current_user/`;
  const method = 'get';

  return {
    type: 'GET_CURRENT_USER',
    payload: handleAPI(url, filters, method)
  };
}

export function currentUserUpdate(data, filters) {
  const url = `/${API_VERSION_ONE}/update_current_user/`;
  const method = 'PUT';
  return {
    type: 'CURRENT_USER_UPDATE',
    payload: handleAPI(url, filters, method, {current_user: data})
  };
}

export function setAuthToken(userType, accessToken) {
  accessToken && localStorage.setItem('Access-Token', accessToken);
  userType && localStorage.setItem('User-Type', userType);
}

export function removeAuthToken() {
  localStorage.removeItem('Access-Token');
  localStorage.removeItem('User-Type');
}

export function setAccountType(type) {
  return {
    type: 'SET_ACCOUNT_TYPE',
    payload: { data: type }
  }
}

export function currentUserEditing(data) {
  return {
    type: 'CURRENT_USER_EDITING',
    payload: { data: data }
  }
}
