import React from 'react';

//Tests import
import ContactLensPrescription from './ ContactLensPrescription'
import EyeGlassPrecription from './EyeGlassPrescription'

let FinalizePrescription = props => {
  const { fieldsValues: {finalized_glasses_attributes, finalized_lenses_attributes}, template} = props;
  return (
    <div className="row">
      { finalized_lenses_attributes.length > 0 && <div className="col-12">
        <div className="border-bottom">
          <h6 className="pb-3 pt-6">{template.contact_lens.custom_label}</h6>
        </div>
      </div> }
      <div className="col-12">
        <ContactLensPrescription fieldsValues={finalized_lenses_attributes} template={template.contact_lens}/>
      </div>
      
      { finalized_glasses_attributes.length > 0 && <div className="col-12">
        <div className="border-bottom">
          <h6 className="pb-3 pt-6">{template.eye_glass.custom_label}</h6>
        </div>
      </div> }
      <div className="col-12">
        <EyeGlassPrecription fieldsValues={finalized_glasses_attributes} template={template.eye_glass}/>
      </div>
    </div>
  )
}


export default FinalizePrescription;