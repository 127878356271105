import Toastr from "../../../itrust_common/components/Toastr"
const parent = 'STORE_SETTING_'

const DEFAULT_STATE = {
  available_numbers: [],
  meta: {pagination: {}},
  saving: false,
  loading: false,
  numbersRequested: false
}
let changes = null;
export default function messageTrackingReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  switch(action.type){
    case `${parent}MESSAGE_TRACKING_CREATE_PENDING`:
      return {...state, saving: true};
    case `${parent}AVAILABLE_NUMBERS_LOAD_PENDING`:
      return {...state, loading: true};

    case `${parent}MESSAGE_TRACKING_CREATE_REJECTED`:
    case `${parent}MESSAGE_TRACKING_DELETE_REJECTED`:
    case `${parent}AVAILABLE_NUMBERS_LOAD_REJECTED`:
      Toastr.handleShow(response.data.success, response.data.error, response.status_code)
      changes = {
        saving: false,
        loading: false,
        numbersRequested: true
      }
      return {...state,...changes};

    case `${parent}AVAILABLE_NUMBERS_LOAD_FULFILLED`:
      changes = {
        ...response,
        saving: false,
        loading: false,
        numbersRequested: true
      };
      return {...state, ...changes};
    
    case `${parent}MESSAGE_TRACKING_CREATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      changes = {
        saving: false,
        numbersRequested: false
      };
      return {...state, ...changes};

    case `${parent}MESSAGE_TRACKING_DELETE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      return state;

    case `${parent}MESSAGE_TRACKING_EDIT`:
      changes = {
        editing: {
          id: action.id,
        },
      };
      return { ...state, ...changes };

    case `${parent}MESSAGE_TRACKING_EDIT_CANCEL`:
      changes = {
        editing: false,
        numbersRequested: false,
        available_numbers: []
      };
      return { ...state, ...changes };

    default:
      return state;
  }
}