import React from 'react';
let DisplayField = props => {
  const { isVisible, label, value, className} = props;
  return (
    (isVisible==="true" || isVisible===true) &&
      <div className={className}>
        {label && <h6>{label}</h6> }
        {value && <span className="text-dark font-size-14">{value}</span> }
      </div>
  )
}


export default DisplayField;
