import Toastr from "../../../components/Toastr"
const DEFAULT_STATE = {
  previousExam: { data: { attributes: {} } },
  saving: false,
  editing: {id: false}
}
let changes = null;
export default function examReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  switch(action.type){
    case 'PREVIOUS_EXAM_LOAD_PENDING':
      return {...state, saving: true};

    case 'PREVIOUS_EXAM_LOAD_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case 'PREVIOUS_EXAM_LOAD_FULFILLED':
      changes = {
        previousExam: response.exam,
        saving: false
      }
      return {...state,...changes};

    case 'RESET_PREVIOUS_EXAM':
      return DEFAULT_STATE;
    
    case 'PREVIOUS_EXAM_EDIT':
      changes = {
        editing: {
          id: action.id,
        },
        exam: action.examData || DEFAULT_STATE['exam']
      };
      return { ...state, ...changes };

    case 'PREVIOUS_EXAM_EDIT_CANCEL':
      changes = {
        editing: false
      };
      return { ...state, ...changes };

    default:
      return state;
  }
}