import React, { Component } from 'react';
import { buildOptions } from '../HelperFunctions';

export default class Select extends Component {

  componentDidMount(){
    const {input: {value, onChange, onBlur}, data, textField, valueField, placeholder, reset} = this.props
    var formatData = buildOptions(data, textField, valueField)
    if((!value || reset) && !(placeholder || placeholder === "")){
      var val = formatData.length > 0 ? formatData[0][valueField] : "" 
      onChange(val)
      onBlur(val)
    }
  }

  render() {
    const {
      props, 
      props: { input, data, disabled, wrapperClasses, label, placeholder, textField, valueField }, 
      props: { meta: { touched, error, warning} },
    } = this;
    var formattedData = buildOptions(data, textField, valueField)
    
    return (
      <div className={`${wrapperClasses} ${disabled && 'disabled'}`}>
        { label && <label className={input.value && 'active'}>{label}</label> }
        <select {...input} className={`custom-select ${props.className} ${touched && error && 'is-invalid'}`}>
          {(placeholder || placeholder === "") && <option value="" selected className="text-muted">{placeholder}</option> }
          {formattedData.map(data => 
            <option key={data[valueField]} value={data[valueField]}>{data[textField]}</option>
          )}
        </select>

        { touched &&
          ((error && <span className="invalid-feedback text-danger d-inline la-sm">{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </div>
    );
  }
}