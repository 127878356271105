import React from 'react';
import { FormTemplateField } from '../../../others/'
import { Input, Textarea, FilesUpload } from '../../../form'

let Default = props => {
  const { baseName, baseFieldValues, isTemplate, isOpen } = props;
  return (
    <div className="card-body">
      <div className="row">
        <FormTemplateField
          isTemplate={isTemplate}
          name={`${baseName}[images1_attributes]`}
          baseName={`${baseName}[fields][images1]`}
          fieldValues={baseFieldValues['images1']}
          component={FilesUpload}
          wrapperClasses="col-6"
          imageClasses="py-3 px-5 uploaded-file-width"
        />
        <FormTemplateField
          isTemplate={isTemplate}
          name={`${baseName}[images2_attributes]`}
          baseName={`${baseName}[fields][images2]`}
          fieldValues={baseFieldValues['images2']}
          component={FilesUpload}
          wrapperClasses="col-6"
          imageClasses="py-3 px-5 uploaded-file-width"
        />
      </div>
      <div className="row">
        <div className="col-6 my-5 text-muted">OD</div>
        <div className="col-6 my-5 text-muted">OS</div>
        <FormTemplateField
          isTemplate={isTemplate}
          name={`${baseName}[fields][od_order]`}
          baseName={`${baseName}[fields][od_order]`}
          fieldValues={baseFieldValues['od_order']}
          isOpen={isOpen}
          component={Input}
          showCopy={{ destination: `${baseName}[fields][os_order]`, title: 'OS', formName: 'examAdditionalTestForm' }}
          className="form-control"
          wrapperClasses="col-6 form-group label-icons"
        />
        <FormTemplateField
          isTemplate={isTemplate}
          name={`${baseName}[fields][os_order]`}
          baseName={`${baseName}[fields][os_order]`}
          fieldValues={baseFieldValues['os_order']}
          isOpen={isOpen}
          component={Input}
          showCopy={{ destination: `${baseName}[fields][od_order]`, title: 'OD', formName: 'examAdditionalTestForm' }}
          className="form-control"
          wrapperClasses="col-6 form-group label-icons"
        />
        <FormTemplateField
          isTemplate={isTemplate}
          name={`${baseName}[fields][od_interpretation]`}
          baseName={`${baseName}[fields][od_interpretation]`}
          fieldValues={baseFieldValues['od_interpretation']}
          isOpen={isOpen}
          component={Textarea}
          showCopy={{ destination: `${baseName}[fields][os_interpretation]`, title: 'OS', formName: 'examAdditionalTestForm' }}
          rows={3}
          className="form-control"
          wrapperClasses="col-6 form-group label-icons"
        />
        <FormTemplateField
          isTemplate={isTemplate}
          name={`${baseName}[fields][os_interpretation]`}
          baseName={`${baseName}[fields][os_interpretation]`}
          fieldValues={baseFieldValues['os_interpretation']}
          isOpen={isOpen}
          component={Textarea}
          showCopy={{ destination: `${baseName}[fields][od_interpretation]`, title: 'OD', formName: 'examAdditionalTestForm' }}
          rows={3}
          className="form-control"
          wrapperClasses="col-6 form-group label-icons"
        />
      </div>
    </div>
  )
}
export default Default;
