const DEFAULT_STATE = {
  filters: {
        "sort": "created_at",
        "direction": "desc",
        "search": "",
        "filter": {
            
        },
        "page": 1,
        "rpp": 10
    },
    patientPaymentRedirect: false,
    dateRangePicker:false
}

export default function filterReducer(state = DEFAULT_STATE, action){
  switch(action.type){
    case 'FILTER_CHANGE':
      return { ...state, ...action.filters };
    case 'FILTER_RESET':
        return { ...state, ...DEFAULT_STATE };
    case 'PATIENT_PAYMENT_REDIRECT':
      return { ...state, patientPaymentRedirect: action.payload, };
    case 'DATE_RANGE_PCIKER':
      return { ...state, dateRangePicker: action.payload, };
    default:
      return state;
  }
}