import React, { Component } from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import DialerPad from "../../../assets/static/images/dialpad.svg";
import { callPopUpEdit, voipEdit } from '../../../redux/actions/addOns/voip/voipActions';
import { procedurePricingsLoad } from '../../../redux/actions/storeSettings/ProcedurePricingActions';
import { currentStoreLoad, storeStaffsLoad, currentStoreAll } from '../../../redux/actions/currentStoreActions';

class StoreSelection extends Component {
  constructor(props) {
    super(props)
    var storeId = localStorage.getItem('StoreID')
    localStorage.setItem('User-email', props.user.email)
    localStorage.setItem('User-firstname', props.user.first_name)
    localStorage.setItem('User-lastname', props.user.last_name)
    if (storeId)
      this.handleStoreSelection(storeId)
    else if (props.user.role == 'non_admin' || props.staffStores.length == 1) {
      if (props.staffStores[0]) {
        localStorage.setItem('StoreID', props.staffStores[0].store_id)
        this.handleStoreSelection(props.staffStores[0].store_id)
      }
    }
  }

  handleStoreSelection(id) {
    if (id === "") {
      localStorage.removeItem('StoreID');
      this.props.dispatch(currentStoreAll())
    } else {
      localStorage.setItem('StoreID', id);
      const { location, history, storeId } = this.props
      this.props.dispatch(currentStoreLoad(id))
      this.props.dispatch(storeStaffsLoad(id, { rpp: 1000, filter: { doctor: id } }))
      this.props.dispatch(procedurePricingsLoad({ rpp: 99999, filter: { for_store: id }, include: ['procedures'] }))

      if (location.pathname.includes('/organisation/stores/') && storeId !== id) {
        var route = location.pathname.split('/')
        history.push(`/organisation/stores/${id}/${route[4]}`)
      }
    }
  }

  timezoneDiff(storeTimezone, localTimezone) {
    var storeTimezoneOffest = moment.tz(storeTimezone)._offset;
    var localTimezoneOffest = moment.tz(localTimezone)._offset;

    if (storeTimezoneOffest > localTimezoneOffest) {
      return (<span className="badge bg-danger-light p-3">
        <i className="la la-sm la-clock-o" />
        Time Zone: {(Math.abs(storeTimezoneOffest - localTimezoneOffest)) / 60} hrs behind
      </span>)
    } else {
      return (<span className="badge bg-danger-light p-3">
        <i className="la la-sm la-clock-o" />
        Time Zone: {(Math.abs(storeTimezoneOffest - localTimezoneOffest)) / 60} hrs ahead
      </span>)
    }
  }

  handleOpenDialer(id) {
    this.props.dispatch(callPopUpEdit(id))
    this.props.dispatch(voipEdit({activePopupTab: 'dialer'}))
  }

  render() {
    const { storeId, staffStores, storeTimezone, user, add_ons_config } = this.props
    var localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    return (
      <div className="d-flex justify-content-end align-items-center mx-3 mx-md-4 mx-lg-5">
        {user.type !== 'Patient' &&
          <>
            {add_ons_config?.type?.voip?.visible &&
              <button className='btn bg-light-1 py-4 px-4 mr-4' onClick={() => this.handleOpenDialer('store_calling')}>
                <img src={DialerPad} alt='voip-call' />
              </button>
            }
            {storeId && storeTimezone !== localTimezone && this.timezoneDiff(storeTimezone, localTimezone)}
            <label className="text-muted px-5">Accessing</label>
            <select className="custom-select store-select" onChange={(e) => this.handleStoreSelection(e.target.value)} value={storeId}>
              {staffStores.length !== 1 && user.role !== 'non_admin' &&
                <option value="" selected>All</option>
              }
              {staffStores.map(store =>
                <option key={store.store_id} value={store.store_id}>{store.store_name}</option>
              )}
            </select>
          </>
        }
        {user.type !== "Patient" && user.role !== 'non_admin' && storeId &&
          <Link to={`/organisation/stores/${storeId}/settings`}>
            <i className="la la-cog pt-3 text-muted px-5" />
          </Link>
        }
      </div>
    );
  }
};

const mapStateToProps = state => {
  const { currentStore, auth, organisation: { organisation } } = state
  return {
    // staffStores: staffStore.staff_stores,
    storeId: currentStore.store.id,
    storeTimezone: currentStore.store.timezone,
    user: auth.user,
    staffStores: auth.user.accessible_stores,
    add_ons_config: organisation.add_ons_config,
  }
}
export default connect(mapStateToProps)(withRouter(StoreSelection));
