import Toastr from "../../../components/Toastr"
const parent = 'SETTING_'

const DEFAULT_STATE = {
  procedures: [],
  procedure: {},
  meta: {pagination: {}},
  saving: false
}
let changes = null;
export default function procedureReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let procedures = [];
  let procedureData = [];
  let updatedRecord = {};
  switch(action.type){
    case `${parent}PROCEDURES_LOAD_PENDING`:
    case `${parent}PROCEDURE_LOAD_PENDING`:
      return {...state, saving: false};

    case `${parent}PROCEDURE_CREATE_PENDING`:
    case `${parent}PROCEDURE_UPDATE_PENDING`:
      return {...state, saving: true};

    case `${parent}PROCEDURES_LOAD_REJECTED`:
    case `${parent}PROCEDURE_LOAD_REJECTED`:
    case `${parent}PROCEDURE_CREATE_REJECTED`:
    case `${parent}PROCEDURE_DELETE_REJECTED`:
    case `${parent}PROCEDURE_UPDATE_REJECTED`:
      Toastr.handleShow(response.data.success, response.data.error, response.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case `${parent}PROCEDURES_LOAD_FULFILLED`:
      changes = {
        ...response,
        saving: false
      };
      return {...state, ...changes};
    
    case `${parent}PROCEDURE_LOAD_FULFILLED`:
      changes = {
        procedure: response.procedure,
        saving: false
      }
      return {...state,...changes};
    
    case `${parent}PROCEDURE_CREATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      procedures = [...state.procedures]
      let newRecord = response.procedure
      changes = {
        procedures: [...procedures, newRecord],
        editing: false,
        saving: false
      }
      return {...state, ...changes};

    case `${parent}PROCEDURE_UPDATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      procedures = [...state.procedures];
      updatedRecord = response.procedure;
      for (const [index, procedure] of procedures.entries()) {
        if (procedure.id === updatedRecord.id) {
          procedureData = [
            ...procedures.slice(0, index),
            updatedRecord,
            ...procedures.slice(index + 1)
          ];
        }
      }
      changes = {
        procedures: procedureData,
        editing: false,
        saving: false
      };
      return { ...state, ...changes };

    case `${parent}PROCEDURE_DELETE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      procedures = [...state.procedures];
      for (const [index, procedure] of procedures.entries()) {
        if (procedure.id === response.procedure.id) {
          procedures.splice(index, 1);
        }
      }
      return {
        ...state,
        procedures: procedures,
        error: null
      };


    case `${parent}PROCEDURE_REORDER`:
      changes = {
        procedures: action.procedures
      };
      return { ...state, ...changes };

    case `${parent}PROCEDURE_EDIT`:
      changes = {
        editing: {
          id: action.id,
        },
        procedure: {}
      };
      return { ...state, ...changes };

    case `${parent}PROCEDURE_EDIT_CANCEL`:
      changes = {
        editing: false
      };
      return { ...state, ...changes };

    default:
      return state;
  }
}