import Toastr from "../../../components/Toastr"
const parent = 'SETTING_'

const DEFAULT_STATE = {
  exam_templates: [],
  exam_template: {},
  meta: {pagination: {}},
  saving: false
}
let changes = null;
export default function examTemplateReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let examTemplates = [];
  let examTemplateData = [];
  let updatedRecord = {};
  switch(action.type){
    case `${parent}EXAM_TEMPLATES_LOAD_PENDING`:
    case `${parent}EXAM_TEMPLATE_LOAD_PENDING`:
      return {...state, saving: true};

    case `${parent}EXAM_TEMPLATE_CREATE_PENDING`:
      return {...state, saving: true};

    case `${parent}EXAM_TEMPLATE_UPDATE_PENDING`:
      return {...state, saving: true};

    case `${parent}EXAM_TEMPLATES_LOAD_REJECTED`:
    case `${parent}EXAM_TEMPLATE_LOAD_REJECTED`:
    case `${parent}EXAM_TEMPLATE_CREATE_REJECTED`:
    case `${parent}EXAM_TEMPLATE_UPDATE_REJECTED`:
    case `${parent}EXAM_TEMPLATE_DELETE_REJECTED`:
      Toastr.handleShow(response.data.success, response.data.error, response.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case `${parent}EXAM_TEMPLATES_LOAD_FULFILLED`:
      changes = {
        ...response,
        saving: false
      };
      return {...state, ...changes};
    
    case `${parent}EXAM_TEMPLATE_LOAD_FULFILLED`:
      let data  = response.exam_template
      changes = {
        exam_template: {...data, exam_template_id: data.id},
        saving: false
      }
      return {...state,...changes};
    
    case `${parent}EXAM_TEMPLATE_CREATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      examTemplates = [...state.exam_templates]
      let newRecord = response.exam_template
      changes = {
        exam_templates: [...examTemplates, newRecord],
        exam_template: {...newRecord, exam_template_id: newRecord.id},
        editing: {id: newRecord.id, step: 2},
        saving: false
      }
      return {...state, ...changes};

    case `${parent}EXAM_TEMPLATE_UPDATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      examTemplates = [...state.exam_templates];
      updatedRecord = response.exam_template;
      for (const [index, examTemplate] of examTemplates.entries()) {
        if (examTemplate.id === updatedRecord.id) {
          examTemplateData = [
            ...examTemplates.slice(0, index),
            updatedRecord,
            ...examTemplates.slice(index + 1)
          ];
        }
      }
      changes = {
        exam_templates: examTemplateData,
        exam_template: {...updatedRecord, exam_template_id: updatedRecord.id},
        editing: state.editing.step===1 ? {id: state.editing.id, step: 2} : false,
        saving: false
      };
      return { ...state, ...changes };

    case `${parent}EXAM_TEMPLATE_DELETE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      examTemplates = [...state.exam_templates];
      for (const [index, examTemplate] of examTemplates.entries()) {
        if (examTemplate.id === response.exam_template.id) {
          examTemplates.splice(index, 1);
        }
      }
      return {
        ...state,
        exam_templates: examTemplates,
        error: null
      };

    case `${parent}EXAM_TEMPLATE_REORDER`:
      changes = {
        exam_templates: action.examTemplates
      };
      return { ...state, ...changes };
      
    case `${parent}EXAM_TEMPLATE_EDIT`:
      changes = {
        editing: {
          id: action.id,
          step: 1
        },
        exam_template: {}
      };
      return { ...state, ...changes };

    case `${parent}EXAM_TEMPLATE_EDIT_CANCEL`:
      changes = {
        editing: false
      };
      return { ...state, ...changes };


    default:
      return state;
  }
}