import React, {Component} from 'react';
import {connect} from 'react-redux';
import ExamRoutes from './examRoutes';

class PdfRoutes extends Component{
  render(){
    return(
      <div className="bg-light">
        { ExamRoutes }
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { other } = state;
  return {
    layout: other.layout,
  }
}

export default connect(mapStateToProps)(PdfRoutes);