import React, { Component } from 'react';

export default class Checkbox extends Component {
  render() {
    const { props, props: { id, input, disabled, wrapperClasses, className } } = this;
    const forId = `${input.name}-${input.value}-${id}`
    return (
      <div className={`${disabled && 'disabled'} ${wrapperClasses}`}>
        <div className="custom-control custom-checkbox">
            <input className={`custom-control-input ${className}`} type="checkbox" {...input} id={forId} checked={input.value}/>
            <label className="custom-control-label" htmlFor={forId}>
              {props.label}
            </label>
        </div>
      </div>
    );
  }
}