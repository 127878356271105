import Toastr from "../../itrust_common/components/Toastr"
const DEFAULT_STATE = {
  patient_surveys: [],
  patient_survey: {},
  meta: { pagination: {} },
  saving: false
}
let changes = null;

export default function patientSurveyReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let patientSurveys = [];
  let patientSurveyData = [];
  let updatedRecord = {};
  switch(action.type){
    case 'PATIENT_SURVEYS_LOAD_PENDING':
    case 'PATIENT_SURVEY_LOAD_PENDING':
      return {...state, saving: true};

    case 'PATIENT_SURVEY_CREATE_PENDING':
      return {...state, saving: true};

    case 'PATIENT_SURVEYS_LOAD_REJECTED':
    case 'PATIENT_SURVEY_LOAD_REJECTED':
    case 'PATIENT_SURVEY_CREATE_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case 'PATIENT_SURVEYS_LOAD_FULFILLED':
      changes = {
        ...response,
        saving: false
      };
      return {...state, ...changes};
    
    case 'PATIENT_SURVEY_LOAD_FULFILLED':
      changes = {
        patient_survey: response.patient_survey,
        saving: false
      }
      return {...state,...changes};
    
    case 'PATIENT_SURVEY_CREATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      patientSurveys = [...state.patient_surveys]
      let newRecord = response.patient_survey
      changes = {
        patient_surveys: [...patientSurveys, newRecord],
        editing: false,
        saving: false
      }
      return {...state, ...changes};

    case 'PATIENT_SURVEY_UPDATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      patientSurveys = [...state.patient_surveys];
      updatedRecord = response.patient_survey;
      for (const [index, patientSurvey] of patientSurveys.entries()) {
        if (patientSurvey.id === updatedRecord.id) {
          patientSurveyData = [
            ...patientSurveys.slice(0, index),
            updatedRecord,
            ...patientSurveys.slice(index + 1)
          ];
        }
      }
      changes = {
        patient_surveys: patientSurveyData,
        editing: false,
        saving: false
      };
      return { ...state, ...changes };

    case 'PATIENT_SURVEY_DELETE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      patientSurveys = [...state.patient_surveys];
      for (const [index, patientSurvey] of patientSurveys.entries()) {
        if (patientSurvey.id === response.patient_survey.id) {
          patientSurveys.splice(index, 1);
        }
      }
      return {
        ...state,
        patient_surveys: patientSurveys,
        error: null
      };

    case 'PATIENT_SURVEY_EDIT':
      changes = {
        editing: {
          id: action.id,
        },
        patient_survey: {}
      };
      return { ...state, ...changes };

    case 'PATIENT_SURVEY_EDIT_CANCEL':
      changes = {
        editing: false,
      };
      return { ...state, ...changes };
    
    default:
      return state;
  }
}