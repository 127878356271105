import React, { Component } from 'react';
import { reduxForm, Field, FieldArray, change } from 'redux-form';
import { connect } from 'react-redux';

import { AutoComplete } from '../../../../itrust_common/components/others';
import { InputWithIcon, Input } from '../../../../itrust_common/components/form';
import { onlyLetters } from '../../../../itrust_common/components/form/FormValidations';
import DiscountField from './DiscountField';
import ImportWrapper from './import/ImportWrapper';
import { numberToCurrency } from '../../../../itrust_common/components/HelperFunctions';
import { orderCloseModal } from '../../../../redux/actions/order/orderActions';

class RenderProcedures extends React.Component {
  render() {
    const { fields, order, pprops } = this.props
    return (
     <tbody>
      {fields.map((member, index, members) => (
        <tr key={`procedure-${index}`} className="text-dark bg-light border-bottom border-white border-bottom-2">
          <td>{index + 1}</td>
          <td>{members.get(index).description}</td>
          <td>{members.get(index).exam_code}</td>
          <td>
            <Field name={`${member}[pointer]`} component={Input} title="" className="form-control" wrapperClasses="d-flex w-75" validate={[onlyLetters]} />
          </td>
          <td>{numberToCurrency(members.get(index).unit_price)}</td>
          <td>
            <Field name={`${member}[copay]`} type="number" component={InputWithIcon} title="$" className="form-control" wrapperClasses="d-flex w-75" />
          </td>
          {
            pprops.order.patient_insurance_id ?
            <td>
              <Field name={`${member}[allowance]`} disabled={members.get(index).allowance==null} type="text" component={InputWithIcon} title="$" className="form-control" wrapperClasses="d-flex w-75" />
            </td>
            :
            <td>
              <div className="d-flex w-75">
                <DiscountField
                  cashName={`${member}[cash_discount]`}
                  percentageName={`${member}[percentage_discount]`}
                  discountTypeName={`${member}[discount_type]`}
                  defaultType={members.get(index).discount_type}
                />
              </div>
            </td>
          }
          <td>{numberToCurrency(members.get(index).subtotal)}</td>
          <td>
            <a className="badge badge-pill badge-primary" onClick={() => { pprops.dispatch(change('orderProcedureForm', `${member}[_destroy]`, true)); setTimeout(() => pprops.submit(), 500) }}>
              <i className="la la-trash"></i>
            </a>
          </td>
        </tr>
      ))}
      {(fields.length > 0 && !pprops.order.patient_insurance_id) && <tr className="text-dark bg-light border-bottom border-white border-bottom-2">
        <td >
        </td>

        <td colSpan="3" className="text-right">
          <h6>Overall Discount</h6>
        </td>
        <td>
          <div className="d-flex w-75">
            <DiscountField
              cashName="cash_procedures_discount"
              percentageName="percentage_procedures_discount"
              discountTypeName="procedures_discount_type"
              defaultType={order.procedures_discount_type}
            />
          </div>
        </td>
        <td></td>
        <td></td>
      </tr> }
    </tbody>
    );
  }
}


class ProcedureForm extends Component {

  componentWillUnmount(){
    if(this.props.dirty){
      this.props.handleSubmit();
      this.props.dispatch(orderCloseModal(false))
    }
  }

  render(){
    const { handleSubmit, order, onSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <div className="col-12 mb-7">
          <div className={`d-flex justify-content-between align-items-center ${order.status !== "finalized" && 'mt-6'}`}>
            <h5>Procedures</h5>
            <ImportWrapper order={order} importName="procedure"/>
          </div>
        </div>
        <div className="card border-0 shadow-sm px-5">
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                <label>Add Procedure</label>
                <AutoComplete onSelect={(settings_procedure_id) => { onSubmit({ id: order.id, procedures_attributes: [{ settings_procedure_id: settings_procedure_id }] }) }} klass="ProcedurePricing" otherFilters={{ filter: { for_store: order.store_id } }} textField="name" valueField="settings_procedure_id" placeholder="Type in to search by procedure name or code" wrapperClasses="col-4 px-0"/>
              </div>
              <div className="col-12 mt-7">
                <div className="overview-table px-2">
                  <table className="table table-hover shadow-none">
                    <thead className="text-dark border-bottom bg-light border-white border-bottom-2">
                      <tr>
                        <th scope="col">No.</th>
                        <th scope="col">Description</th>
                        <th scope="col">Code</th>
                        <th scope="col">Impression pointer</th>
                        <th scope="col">Unit Price (in $)</th>
                        <th scope="col">Copay</th>
                        {
                          order.patient_insurance_id ?
                          <th scope="col">Allowance</th>
                          :
                          <th scope="col">Discount</th>
                        }
                        <th scope="col">Subtotal</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <FieldArray name="procedures_attributes"
                        props = {{
                          order: order,
                          onSubmit: onSubmit,
                          pprops: this.props
                        }}
                        component={RenderProcedures} />
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  }
}

ProcedureForm = reduxForm({
  form: 'orderProcedureForm',
  enableReinitialize: true,
  onChange: (values, dispatch, props) => {
    if(props.dirty) {
      dispatch(orderCloseModal(true))
    }
  }
})(ProcedureForm)

ProcedureForm = connect(state=> {
  const { order } = state.order
  return {
    order: order,
    initialValues: {
      id: order.id,
      percentage_procedures_discount: order.percentage_procedures_discount,
      cash_procedures_discount: order.cash_procedures_discount,
      procedures_discount_type: order.procedures_discount_type,
      procedures_attributes: order.procedures_attributes
    }
  }
})(ProcedureForm)

export default ProcedureForm;
