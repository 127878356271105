import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Hpi, PreliminaryBinocular, MedicalHistory, RefractionContactLens, ExternalInternalTests, AdditionalTest, ProcedureImpressionPlan, FinalizePrescription } from '../../../../components/authorised/exam/overview/index';
import StoreInfo from '../../../../components/authorised/exam/overview/StoreInformation';
import PatientInfo from '../../../../components/authorised/exam/overview/PatientInformation';
import OverviewFooter from '../../../../components/authorised/exam/overview/OverviewFooter';

import ReferralInfo from './ReferralInfo';
import { dateFormat } from "../../../../itrust_common/components/HelperFunctions"

class Show extends Component {
  render() {
    const { isPdfView, store, medicalHistories, referral, referral: { check_list }, exam,
      exam: { patient_attributes, template: { preliminary_binocular_testing, refraction_contact_lens_exam, external_internal_exam, procedures_and_impressions, finalize_prescription, additional_tests, hpi } } } = this.props;
    return (
      <div id="referralPrint" className="modal-body p-0 pdf-fax-font-change">
        <div className="border-bottom p-6">
          <StoreInfo store={store} />
        </div>
        <div className="text-right py-4 px-6">
          <span className="font-weight-bold"> Exam Date: </span><span>{dateFormat(exam.exam_date)}</span>
        </div>
        <div className="px-6 pt-6 pb-4">
          <ReferralInfo referral={referral} />
        </div>
        <div className="px-6 pt-6 pb-4">
          <PatientInfo isPdfView={isPdfView} patient={patient_attributes} />
        </div>

        {check_list && check_list.hpi &&
          <div className="px-6 pt-6 pb-4">
            <Hpi fieldsValues={exam} template={hpi} />
          </div>
        }

        {check_list && check_list.medical_history &&
          <div className="px-6 pt-6 pb-4">
            <MedicalHistory fieldsValues={medicalHistories} neurologicalStatus={patient_attributes.neurological_psychological_status}/>
          </div>
        }

        {check_list && check_list.preliminary_binocular_testing &&
          <div className="px-6 py-4">
            <PreliminaryBinocular
              fieldsValues={exam}
              template={preliminary_binocular_testing}
            />
          </div>
        }

        {check_list && check_list.refraction_contact_lens_exam &&
          <div className="px-6 py-4">
            <RefractionContactLens
              fieldsValues={exam}
              template={refraction_contact_lens_exam}
              isPdfView={isPdfView}
              isReferralPdf={true}
            />
          </div>
        }

        {check_list && check_list.external_internal_exam &&
          <div className="px-6 py-4">
            <ExternalInternalTests
              fieldsValues={exam}
              template={external_internal_exam}
              isPdfView={isPdfView}
              store={store}
            />
          </div>
        }

        {check_list && check_list.additional_tests &&
          <div className="px-6 py-4">
            <AdditionalTest
              fieldsValues={exam}
              template={additional_tests}
            />
          </div>
        }

        {check_list && check_list.procedures_and_impressions &&
          <div className="px-6 py-4">
            <ProcedureImpressionPlan
              fieldsValues={exam}
              template={procedures_and_impressions}
            />
          </div>
        }

        {check_list && check_list.finalize_prescription &&
          <div className="px-6 py-4">
            <FinalizePrescription
              fieldsValues={exam}
              template={finalize_prescription}
            />
          </div>
        }

        <div className="px-6 py-4">
          <OverviewFooter />
        </div>

      </div>
    );
  }
}

const mapStateToProps = state => {
  const { referral, exam, patientMedicalHistory, patient } = state;
  return {
    referral: referral.referral,
    exam: exam.exam,
    medicalHistories: patientMedicalHistory.patient_medical_histories,
    store: exam.exam.store_attributes,
    patient: patient.patient
  }
}

export default connect(mapStateToProps)(Show);