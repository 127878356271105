import Toastr from "../../../components/Toastr"
const parent = 'SETTING_'

const DEFAULT_STATE = {
  eyeglass_lens_types: [],
  eyeglass_lens_type: {},
  meta: {pagination: {}},
  saving: false
}
let changes = null;
export default function eyeglassLensTypeReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let eyeglassLensTypes = [];
  let eyeglassLensTypeData = [];
  let updatedRecord = {};
  switch(action.type){
    case `${parent}EYEGLASS_LENS_TYPES_LOAD_PENDING`:
    case `${parent}EYEGLASS_LENS_TYPE_LOAD_PENDING`:
      return {...state, saving: false};

    case `${parent}EYEGLASS_LENS_TYPE_CREATE_PENDING`:
    case `${parent}EYEGLASS_LENS_TYPE_UPDATE_PENDING`:
      return {...state, saving: true};

    case `${parent}EYEGLASS_LENS_TYPES_LOAD_REJECTED`:
    case `${parent}EYEGLASS_LENS_TYPE_LOAD_REJECTED`:
    case `${parent}EYEGLASS_LENS_TYPE_CREATE_REJECTED`:
    case `${parent}EYEGLASS_LENS_TYPE_UPDATE_REJECTED`:
    case `${parent}EYEGLASS_LENS_TYPE_DELETE_REJECTED`:
      Toastr.handleShow(response.data.success, response.data.error, response.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case `${parent}EYEGLASS_LENS_TYPES_LOAD_FULFILLED`:
      changes = {
        ...response,
        saving: false
      };
      return {...state, ...changes};
    
    case `${parent}EYEGLASS_LENS_TYPE_LOAD_FULFILLED`:
      changes = {
        eyeglass_lens_type: response.eyeglass_lens_type,
        saving: false
      }
      return {...state,...changes};
    
    case `${parent}EYEGLASS_LENS_TYPE_CREATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      eyeglassLensTypes = [...state.eyeglass_lens_types]

      let newRecord = response.eyeglass_lens_type
      changes = {
        eyeglass_lens_types: [...eyeglassLensTypes, newRecord],
        editing: false,
        saving: false
      }
      return {...state, ...changes};

    case `${parent}EYEGLASS_LENS_TYPE_UPDATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      eyeglassLensTypes = [...state.eyeglass_lens_types];
      updatedRecord = response.eyeglass_lens_type;
      for (const [index, eyeglassLensType] of eyeglassLensTypes.entries()) {
        if (eyeglassLensType.id === updatedRecord.id) {
          eyeglassLensTypeData = [
            ...eyeglassLensTypes.slice(0, index),
            updatedRecord,
            ...eyeglassLensTypes.slice(index + 1)
          ];
        }
      }
      changes = {
        eyeglass_lens_types: eyeglassLensTypeData,
        editing: false,
        saving: false
      };
      return { ...state, ...changes };

    case `${parent}EYEGLASS_LENS_TYPE_DELETE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      eyeglassLensTypes = [...state.eyeglass_lens_types];
      for (const [index, eyeglassLensType] of eyeglassLensTypes.entries()) {
        if (eyeglassLensType.id === response.eyeglass_lens_type.id) {
          eyeglassLensTypes.splice(index, 1);
        }
      }
      return {
        ...state,
        eyeglass_lens_types: eyeglassLensTypes,
        error: null
      };

    case `${parent}EYEGLASS_LENS_TYPE_DUPLICATE`:
      changes = {
        editing: {
          id: 'New',
        },
        eyeglass_lens_type: {...action.eyeglassLensType, id: null},
      };
      return { ...state, ...changes };

    case `${parent}EYEGLASS_LENS_TYPE_EDIT`:
      changes = {
        editing: {
          id: action.id,
        },
        eyeglass_lens_type: {}
      };
      return { ...state, ...changes };

    case `${parent}EYEGLASS_LENS_TYPE_EDIT_CANCEL`:
      changes = {
        editing: false
      };
      return { ...state, ...changes };

    default:
      return state;
  }
}