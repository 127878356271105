import React from 'react';
import { FieldArray, change } from 'redux-form';
import { Input, Textarea, Select } from '../../form'
import { FormTemplateField } from '../../others'

class RenderChiefComplaint extends React.Component {

  render() {
    const { template, isTemplate, fields, pprops } = this.props
    return (
      <div className="row">
        <div className="col-12 mb-7">
          <div className="d-flex justify-content-between align-items-center exam-form-header-btn">
            <h5>History Of Present Illness</h5>
            {
              !isTemplate &&
              <button type="button" className="btn btn-primary" onClick={() => { fields.push({}); setTimeout(() => pprops.submit(), 500) }}>
                Add CC
              </button>
            }
          </div>
        </div>
        {fields.map((member, index) => (
          <div key={`chief-complaint-${index}`} className="col-lg-3 col-md-4 col-xs-6 mb-7">
            <div className="bg-white shadow-sm rounded py-6 pl-6 pr-5">
              {
                !isTemplate && index !== 0 &&
                <button type="button" className="close float-right" aria-label="Close" onClick={() => { pprops.dispatch(change('examHpiForm', `${member}[_destroy]`, true)); setTimeout(() => pprops.submit(), 500) }}>
                  <span aria-hidden="true">&times;</span>
                </button>
              }
              <div className="row">
                <FormTemplateField
                  isTemplate={isTemplate}
                  name={`${member}[chief_complaint]`}
                  baseName={`${member}[fields][chief_complaint]`}
                  fieldValues={template['hpi']['hpis'][0]['fields']['chief_complaint']}
                  className="form-control" wrapperClasses="form-group col-12"
                  component={Textarea} rows={6}
                />
                <FormTemplateField
                  isTemplate={isTemplate}
                  name={`${member}[location]`}
                  baseName={`${member}[fields][location]`}
                  fieldValues={template['hpi']['hpis'][0]['fields']['location']}
                  component={Input}
                  className="form-control"
                  wrapperClasses="col-12 form-group label-icons"
                />
                <FormTemplateField
                  isTemplate={isTemplate}
                  name={`${member}[quality]`}
                  baseName={`${member}[fields][quality]`}
                  fieldValues={template['hpi']['hpis'][0]['fields']['quality']}
                  component={Input}
                  className="form-control"
                  wrapperClasses="col-12 form-group label-icons"
                />
                <FormTemplateField
                  isTemplate={isTemplate}
                  name={`${member}[severity]`}
                  baseName={`${member}[fields][severity]`}
                  fieldValues={template['hpi']['hpis'][0]['fields']['severity']}
                  component={Select}
                  data={[{}, { key: 'high' }, { key: 'low' }, { key: 'medium' }]}
                  textField="key"
                  valueField="key"
                  className="form-control"
                  wrapperClasses="col-12 form-group label-icons"
                />
                <FormTemplateField
                  isTemplate={isTemplate}
                  name={`${member}[duration]`}
                  baseName={`${member}[fields][duration]`}
                  fieldValues={template['hpi']['hpis'][0]['fields']['duration']}
                  component={Input}
                  className="form-control"
                  wrapperClasses="col-12 form-group label-icons"
                />
                <FormTemplateField
                  isTemplate={isTemplate}
                  name={`${member}[timing]`}
                  baseName={`${member}[fields][timing]`}
                  fieldValues={template['hpi']['hpis'][0]['fields']['timing']}
                  component={Input}
                  className="form-control"
                  wrapperClasses="col-12 form-group label-icons"
                />
                <FormTemplateField
                  isTemplate={isTemplate}
                  name={`${member}[context]`}
                  baseName={`${member}[fields][context]`}
                  fieldValues={template['hpi']['hpis'][0]['fields']['context']}
                  component={Input}
                  className="form-control"
                  wrapperClasses="col-12 form-group label-icons"
                />
                <FormTemplateField
                  isTemplate={isTemplate}
                  name={`${member}[modifying_factors]`}
                  baseName={`${member}[fields][modifying_factors]`}
                  fieldValues={template['hpi']['hpis'][0]['fields']['modifying_factors']}
                  component={Input}
                  className="form-control"
                  wrapperClasses="col-12 form-group label-icons"
                />
                <FormTemplateField
                  isTemplate={isTemplate}
                  name={`${member}[associated_signs_and_symptoms]`}
                  baseName={`${member}[fields][associated_signs_and_symptoms]`}
                  fieldValues={template['hpi']['hpis'][0]['fields']['associated_signs_and_symptoms']}
                  component={Input}
                  className="form-control"
                  wrapperClasses="col-12 form-group label-icons"
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

const HistoryOfPresentIllnessForm = props => {
  const { template, isTemplate, pprops } = props
  const name = isTemplate ? "hpi[hpis]" : "hpis_attributes"
  return (
    <FieldArray name={name}
      props={{
        template: template,
        isTemplate: isTemplate,
        pprops: pprops
      }}
      component={RenderChiefComplaint} />
  );
}

export default HistoryOfPresentIllnessForm;