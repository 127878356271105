import Toastr from "../../components/Toastr"

const DEFAULT_STATE = {
  activities: [],
  meta: {pagination: {}},
  filters: {
        "sort": "created_at",
        "direction": "desc",
        "page": 1,
        "rpp": 10
    },
  saving: false
}
let changes = null;
export default function activityReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  switch(action.type){
    case 'ACTIVITIES_LOAD_PENDING':
      changes = {
        saving: false
      }
      return {...state,...changes};
    case 'ACTIVITIES_LOAD_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case 'ACTIVITIES_LOAD_FULFILLED':
      changes = {
        ...response,
        saving: false
      };
      return { ...state, ...changes };

    case 'FILTER_CHANGE':
      return { ...state, ...action.filters };
    
    default:
      return state;
  }
}