import React, {Component} from 'react';
import {FormTemplateHeader, FormTemplateSectionWrapper} from '../../../../others/'

import Correction from './Correction'
import CurrentGlass from './CurrentGlass'

export default class VisualAcuity extends Component{
  render(){
    const {isTemplate, template, copyToCategory, pprops} = this.props
    return (
      <FormTemplateSectionWrapper isTemplate={isTemplate} fieldValues={template.refraction_contact_lens_exam.visual_acuity}>
        <div className="px-5">
          <FormTemplateHeader 
            isTemplate={isTemplate} 
            fieldValues={template.refraction_contact_lens_exam.visual_acuity} 
            baseName="refraction_contact_lens_exam[visual_acuity]"
            />
        </div>
        <div className="row">
          <div className="col-xl-3 col-md-6">
            <FormTemplateSectionWrapper isTemplate={isTemplate} fieldValues={template.refraction_contact_lens_exam.visual_acuity.fields.without_correction}>
              <div className="card border-0 shadow-sm px-5">
                <FormTemplateHeader 
                  isTemplate={isTemplate}
                  name="visual_acuity_without_correction[is_marked_complete]"
                  isToggle={pprops.initialValues && pprops.initialValues.visual_acuity_without_correction.is_marked_complete}
                  fieldValues={template.refraction_contact_lens_exam.visual_acuity.fields.without_correction} 
                  baseName="refraction_contact_lens_exam[visual_acuity][fields][without_correction]"
                  overviewName="visual_acuity_without_correction"
                  pprops={pprops}
                />
                <div className={`${(pprops.initialValues && pprops.initialValues.visual_acuity_without_correction.is_marked_complete) || isTemplate ? '': 'd-none'}`}>
                  <Correction 
                    {...this.props}
                    type="without_correction"
                    />
                </div>
              </div>
            </FormTemplateSectionWrapper>
          </div>
          <div className="col-xl-3 col-md-6">
            <FormTemplateSectionWrapper isTemplate={isTemplate} fieldValues={template.refraction_contact_lens_exam.visual_acuity.fields.with_correction}>
              <div className="card border-0 shadow-sm px-5">
                <FormTemplateHeader 
                  isTemplate={isTemplate}
                  name="visual_acuity_with_correction[is_marked_complete]"
                  isToggle={pprops.initialValues && pprops.initialValues.visual_acuity_with_correction.is_marked_complete}
                  fieldValues={template.refraction_contact_lens_exam.visual_acuity.fields.with_correction} 
                  baseName="refraction_contact_lens_exam[visual_acuity][fields][with_correction]"
                  overviewName="visual_acuity_with_correction"
                  pprops={pprops}
                />
                <div className={`${(pprops.initialValues && pprops.initialValues.visual_acuity_with_correction.is_marked_complete) || isTemplate ? '': 'd-none'}`}>
                  <Correction 
                    {...this.props}
                    type="with_correction"
                    />
                </div>
              </div>
            </FormTemplateSectionWrapper>
          </div>
          <div className="col-xl-6 col-md-12 mt-md-7 mt-xl-0">
            <FormTemplateSectionWrapper isTemplate={isTemplate} fieldValues={template.refraction_contact_lens_exam.visual_acuity.fields.current_glass}>
              <div className="card border-0 shadow-sm px-5">
                <FormTemplateHeader 
                  isTemplate={isTemplate} 
                  fieldValues={template.refraction_contact_lens_exam.visual_acuity.fields.current_glass} 
                  baseName="refraction_contact_lens_exam[visual_acuity][fields][current_glass]"
                  overviewName="current_glasses"
                />
                <CurrentGlass
                  copyToCategory={copyToCategory}
                  {...this.props} 
                />
              </div>
            </FormTemplateSectionWrapper>
          </div>
        </div>
      </FormTemplateSectionWrapper>
    );
  }
}