import React from 'react';
import { Route, Switch, Redirect} from 'react-router-dom';
import asyncComponent from '../../itrust_common/components/AsyncComponent';

const Patient = asyncComponent(() =>
  import('../../containers/authorised/patient/Patients.js')
);
const Overview = asyncComponent(() =>
  import('../../containers/authorised/patient/Overview.js')
);

const IntraofficeNotes = asyncComponent(() =>
  import('../../containers/authorised/patient/IntraofficeNotes.js')
);
const FamilyMember = asyncComponent(() =>
  import('../../containers/authorised/patient/FamilyMember.js')
);
const Report = asyncComponent(() =>
  import('../../containers/authorised/patient/Report.js')
);
const AccountInformation = asyncComponent(() =>
  import('../../containers/authorised/patient/AccountInformation')
);
const SocialHistory = asyncComponent(() =>
  import('../../containers/authorised/patient/SocialHistory')
);
const MedicalHistory = asyncComponent(() =>
  import('../../containers/authorised/patient/MedicalHistory')
);
const ReviewOfSystem = asyncComponent(() =>
  import('../../containers/authorised/patient/ReviewOfSystem')
);
const Documents = asyncComponent(() =>
  import('../../containers/authorised/patient/Documents')
);

const PatientSurvey = asyncComponent(() =>
  import('../../containers/public/patientSurvey/PatientSurvey')
);

const DashboardRoutes = (
  <Switch>
    <Route exact path="/patient_surveys" component={PatientSurvey} />
    <Route exact path="/patients" component={Patient} />
    <Route path="/patients/:id/overview" component={Overview} />
    <Route path="/patients/:id/intraoffice-note" component={IntraofficeNotes} />
    <Route path="/patients/:id/family-member" component={FamilyMember} />
    <Route path="/patients/:id/reports" component={Report} />
    <Route path="/patients/:id/account_information" component={AccountInformation} />
    <Route path="/patients/:id/social_history" component={SocialHistory} />
    <Route path="/patients/:id/medical_history" component={MedicalHistory} />
    <Route path="/patients/:id/review_of_system" component={ReviewOfSystem} />
    <Route path="/patients/:id/documents" component={Documents} />
    <Redirect exact from="/patients/:id" to="/patients/:id/overview" />
  </Switch>
);

export default DashboardRoutes;