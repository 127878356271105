import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as $ from 'jquery';

import { eyeglassLabOrderEdit, eyeglassLabOrderLoad } from '../../../../../../redux/actions/order/eyeglassLabOrderActions';


class LabOrderButton extends Component {

  handleExamEdit(eyeglassId, labOrderId){
    this.props.dispatch(eyeglassLabOrderEdit( eyeglassId ));
    if(labOrderId)
      this.props.dispatch(eyeglassLabOrderLoad( eyeglassId, labOrderId ))
    $(`#eyeglassLabOrderForm-${eyeglassId}-modal`).modal();
  }

  render() {
    const { eyeglassId, labOrderId } = this.props;
    return (
      <span
        className="pointer"
        onClick={this.handleExamEdit.bind(this, eyeglassId, labOrderId)}
      >
        {this.props.children}
      </span>
    );
  }
}

export default connect()(LabOrderButton);
