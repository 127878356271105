import React from 'react';
import { Field } from 'redux-form';

import {InputWithIcon} from '../../../../../../itrust_common/components/form';

let PupillaryDistance = props => {
  const { member } = props;
  return (
    <div className="row mx-0 bg-light">
      <div className="col-12 p-5">
        <h6>Pupillary Distance</h6>
      </div>
      <div className="col-4 mb-5">
        <span>Distance</span>
      </div>
      <div className="col-4">
        <span>Intermediate</span>
      </div>
      <div className="col-4">
        <span>Near</span>
      </div>
      <Field name={`${member}[pupillary_distance][od_distance]`} component={InputWithIcon} title="OD" className="form-control" wrapperClasses="col-4 form-group" />
      <Field name={`${member}[pupillary_distance][od_intermediate]`} component={InputWithIcon} title="OD" className="form-control" wrapperClasses="col-4 form-group" />
      <Field name={`${member}[pupillary_distance][od_near]`} component={InputWithIcon} title="OD" className="form-control" wrapperClasses="col-4 form-group" />
      <Field name={`${member}[pupillary_distance][os_distance]`} component={InputWithIcon} title="OS" className="form-control" wrapperClasses="col-4 form-group" />
      <Field name={`${member}[pupillary_distance][os_intermediate]`} component={InputWithIcon} title="OS" className="form-control" wrapperClasses="col-4 form-group" />
      <Field name={`${member}[pupillary_distance][os_near]`} component={InputWithIcon} title="OS" className="form-control" wrapperClasses="col-4 form-group" />
      <Field name={`${member}[pupillary_distance][ou_distance]`} component={InputWithIcon} title="OU" className="form-control" wrapperClasses="col-4 form-group" />
      <Field name={`${member}[pupillary_distance][ou_intermediate]`} component={InputWithIcon} title="OU" className="form-control" wrapperClasses="col-4 form-group" />
      <Field name={`${member}[pupillary_distance][ou_near]`} component={InputWithIcon} title="OU" className="form-control" wrapperClasses="col-4 form-group" />
    </div>
  )
}
export default PupillaryDistance;