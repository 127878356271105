import Toastr from "../../itrust_common/components/Toastr"
const DEFAULT_STATE = {
  referrals: [],
  referral: { contacts: [] },
  meta: { pagination: {} },
  saving: false
}
let changes = null;
export default function referralReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let referrals = [];
  let referralData = [];
  let updatedRecord = {};
  switch(action.type){
    case 'REFERRALS_LOAD_PENDING':
    case 'REFERRAL_LOAD_PENDING':
      return {...state, saving: true};

    case 'REFERRAL_CREATE_PENDING':
    case 'REFERRAL_UPDATE_PENDING':
      return {...state, saving: true};

    case 'REFERRALS_LOAD_REJECTED':
    case 'REFERRAL_LOAD_REJECTED':
    case 'REFERRAL_CREATE_REJECTED':
    case 'REFERRAL_UPDATE_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case 'REFERRALS_LOAD_FULFILLED':
      changes = {
        ...response,
        saving: false
      };
      return {...state, ...changes};
    
    case 'REFERRAL_LOAD_FULFILLED':
      changes = {
        referral: response.referral,
        saving: false
      }
      return {...state,...changes};
    
    case 'REFERRAL_CREATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      referrals = [...state.referrals]
      let newRecord = response.referral
      changes = {
        referrals: [...referrals, newRecord],
        editing: false,
        saving: false
      }
      return {...state, ...changes};

    case 'REFERRAL_UPDATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      referrals = [...state.referrals];
      updatedRecord = response.referral;
      for (const [index, referral] of referrals.entries()) {
        if (referral.id === updatedRecord.id) {
          referralData = [
            ...referrals.slice(0, index),
            updatedRecord,
            ...referrals.slice(index + 1)
          ];
        }
      }
      changes = {
        referrals: referralData,
        editing: false,
        saving: false
      };
      return { ...state, ...changes };

    case 'REFERRAL_DELETE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      referrals = [...state.referrals];
      for (const [index, referral] of referrals.entries()) {
        if (referral.id === response.referral.id) {
          referrals.splice(index, 1);
        }
      }
      return {
        ...state,
        referrals: referrals,
        error: null
      };

    case 'REFERRAL_EDIT':
      changes = {
        editing: {
          id: action.id,
        },
      };
      return { ...state, ...changes };

    case 'REFERRAL_EDIT_CANCEL':
      changes = {
        editing: false,
        referral: { contacts: [] }
      };
      return { ...state, ...changes };
    
    default:
      return state;
  }
}