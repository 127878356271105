import React from 'react'
import { connect } from 'react-redux'
import OutGoingWrapper from './OutGoingWrapper'
import PatientInfoHeader from '../../other/patient/PatientInfoHeader'
import PatientProfileAddOns from '../../other/patient/PatientProfileAddOns'
import VoipAdvertisement from '../VoipAdvertisement'

function CallingPopUpWrapper(props) {
  const { callPopUpStatus, voipConfig, currentStoreTelecomConfig, isCallActive, contact, callInfo, telecomServiceType, isCallRecordingActive, voipMinimizeView, layout, editing } = props

  return (
    <>
      {voipConfig?.status && voipConfig?.status !== "inactive" ?
        <>
          {(callPopUpStatus === 'patient' || callPopUpStatus === 'store_calling') &&
            <>
              {(callInfo.isIncomingCall && callInfo?.callStatus === 'ringing') ? "" :
                <>
                  <PatientProfileAddOns
                    btnClasses="text-primary bg-transparent border-0 font-size-20 py-0"
                    wrapperClasses="justify-content-between p-3"
                    isPopUp={true}
                    type='voip'
                    hideMinimizeBtn={callPopUpStatus === 'store_calling' ? true : false}
                    hideAddOnsBtn={callPopUpStatus === 'store_calling' ? true : false}
                    patient={contact}
                  />
                </>
              }
              {voipMinimizeView ?
                <PatientInfoHeader patient={contact} layout={layout} /> :
                <OutGoingWrapper
                  callPopUpStatus={callPopUpStatus}
                  currentStoreTelecomConfig={currentStoreTelecomConfig}
                  isCallRecordingActive={isCallRecordingActive}
                  telecomServiceType={telecomServiceType}
                  isCallActive={isCallActive}
                  contact={contact}
                  callInfo={callInfo}
                  editing={editing}
                />}
            </>
          }
        </> :
        <>
          <PatientProfileAddOns
            btnClasses="text-primary bg-transparent border-0 font-size-20 py-0"
            wrapperClasses="justify-content-between p-3"
            isPopUp={true}
            type='voip'
            hideMinimizeBtn={true}
            hideAddOnsBtn={true}
          />
          <VoipAdvertisement isPopupScreen={true} />
        </>
      }
    </>
  )
}

const mapStateToProps = state => {
  const { voip, organisation: { organisation }, currentStore, contact, auth: { user }, addOn, other } = state;

  return {
    isCallRecordingActive: addOn.add_on.config?.voip_settings?.call_recording?.active,
    currentStoreTelecomConfig: currentStore.store?.telcom_config,
    telecomServiceType: organisation.telcom_service_type,
    isIncomingCall: voip?.isIncomingCall,
    callPopUpStatus: voip?.call_pop_up_status,
    isCallActive: voip?.calling_info.isCallActive,
    voipConfig: organisation.add_ons_config?.type?.voip,
    contact: contact.contact_list,
    callInfo: voip?.calling_info,
    userType: user?.type,
    voipMinimizeView: voip.minimizeView,
    layout: other.layout,
    editing: voip.editing
  }
}

export default connect(mapStateToProps)(CallingPopUpWrapper);