import React from 'react'
import { whatsAppEdit } from '../../../../../redux/actions/addOns/whatsApp/whatsAppActions'

export default function FacebookLoginAdv(props) {
  const { isPopupScreen, dispatch } = props

  return (
    <div className={`${!isPopupScreen && 'pt-7'}`}>
      {isPopupScreen && <div className='text-right bg-white'>
        <span onClick={() => dispatch(whatsAppEdit({ patientProfile: false }))}><i className="las la-times-circle font-size-20 text-primary pt-3 px-4 pointer"></i></span>
      </div>}
      <div className='bg-white text-center d-flex flex-column justify-content-center align-items-center py-10'>
        <i className={`lab la-whatsapp whatsapp-icon-color text-white ${isPopupScreen ? 'font-size-190' : 'font-size-360'}`}></i>
        <h1 className='mt-7'>Activate WhatsApp</h1>
        <p className='mt-5 font-size-18'>
          Please contact the Super Admin to get Whatsapp activated.
        </p>
      </div>
    </div>
  )
}
