import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as $ from 'jquery';

import { orderPaymentsLoad, orderPaymentEdit } from '../../../../redux/actions/order/orderPaymentActions';

class RefundButtonForm extends Component {

  handlePaymentEdit(data) {
    this.props.dispatch(orderPaymentEdit('New'))
    this.props.dispatch(orderPaymentsLoad(this.props.orderId, {filter: {payment_type: 'refund'}, include: ['staff']} ))
    $("#orderRefundForm-modal").modal()
    $('#Refund-tab').tab('show')
  }

  render() {
    return (
      <span
        className="pointer"
        onClick={this.handlePaymentEdit.bind(this)}
      >
        {this.props.children}
      </span>
    );
  }
}

RefundButtonForm = connect(state=> {
  return {
  }
})(RefundButtonForm)

export default RefundButtonForm;
