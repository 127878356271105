import storeReducer from './StoreReducer';
import staffReducer from './StaffReducer';
import appointmentReducer from './AppointmentReducer';
import anonymousSettingReducer from './AnonymousSettingReducer';
import procedureReducer from './ProcedureReducer';
import patientSurveyReducer from './PatientSurveyReducer'
import organisationReducer from './OrganisationReducer'

const publicReducers ={
  publicOrganisation: organisationReducer,
  publicStore: storeReducer,
  publicStaff: staffReducer,
  publicAppointment: appointmentReducer,
  publicAnonymousSetting: anonymousSettingReducer,
  publicProcedure: procedureReducer,
  publicPatientSurvey: patientSurveyReducer,
};

export default publicReducers;