import React, {Component} from 'react';
var delayTimer

class Search extends Component {

  handleSearch(query){
    clearTimeout(delayTimer);
    delayTimer = setTimeout(function() {
      this.props.onChangeHook(query);
    }.bind(this), 500)
  }

  render() {
    const { inputClasses, iconClasses, placeholder } = this.props
    return(
      <div className="input-group">
        <input onChange={(search) => this.handleSearch(search.target.value)} type="text" className={`form-control ${inputClasses}`} placeholder={placeholder}/>
        <div className="input-group-append">
          <span className={`input-group-text search-button position-static bg-light ${iconClasses}`}><i className="la la-search text-muted" /></span>
        </div>
      </div>
    )
  }
}
export default Search;