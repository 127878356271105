import React, { Component } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const convertHtmlToEditorState = html => {
  const contentBlock = htmlToDraft(html);
  const contentState = ContentState.createFromBlockArray(
    contentBlock.contentBlocks
  );
  const editorState = EditorState.createWithContent(contentState);
  return editorState;
};

export default class Wysiwyg extends Component{

  render() {
    const {
      props,
      props: { input, disabled, wrapperClasses, label, toolbarClassName, wrapperClassName, editorClassName},
      props: { meta: { touched, error } }
    } = this;
    return (
      <div className={`${disabled && 'disabled'} ${wrapperClasses}`}>
        {label !== undefined && <label>{props.label}</label>}
        <Editor 
          defaultEditorState={convertHtmlToEditorState(input.value)}
          //editorState={convertHtmlToEditorState(input.value)}
          placeholder={'Start writing here'}
          toolbarClassName={toolbarClassName}
          wrapperClassName={wrapperClassName}
          editorClassName={`${editorClassName} ${touched && error && 'is-invalid'}`}
          onEditorStateChange={editorState => input.onChange( draftToHtml(convertToRaw(editorState.getCurrentContent())) )}
          toolbar={{
            image: {
              urlEnabled: true,
              uploadEnabled: true,
              uploadCallback: ()=> {}
            },
            fontFamily: {
              options: ['Roboto', 'Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
            },
            emoji: {
              className: "emoji"
            }
          }}
        />
      </div>
    );
  }
} 