import React from 'react';
import { dateFormat, isPresentInArray } from '../../../../../itrust_common/components/HelperFunctions';
import LensView from './LensView'

let ContactLens = props => {
  const { fieldsValues, template, handleIdsSelect, selectedIds, overviewName } = props;
  return (
    <div className="row">
      {fieldsValues.map((currentGlass, index) =>
        currentGlass.is_marked_complete && <div className={`col-12 ${index !== 0 ? "py-4" : "pb-4"}`}>
          <div className='d-flex justify-content-between'>
            <span>
              {
                `${index !== 0 ? 'Contact Lens Trial #' : 'Current Contact Lens'}${index !== 0 ? index : ''}`} Issue Date {dateFormat(currentGlass.issue_date)
              }
            </span>
            {overviewName && <div className='d-flex'>
              <label className='mr-3'>Select</label>
              <div className="custom-control custom-checkbox">
                <input
                  onChange={() => handleIdsSelect(selectedIds, fieldsValues[index].id)}
                  type="checkbox"
                  checked={isPresentInArray(selectedIds, fieldsValues[index].id)}
                  className="custom-control-input" id={`impression-select-${fieldsValues[index].id}`} />
                <label className="custom-control-label" htmlFor={`impression-select-${fieldsValues[index].id}`}></label>
              </div>
            </div>}
          </div>
          <LensView
            fieldsValues={fieldsValues[index]}
            template={template}
          />
        </div>
      )}
    </div>
  )
}


export default ContactLens;