import React, { Component } from 'react'
import { connect } from 'react-redux'
import Search from './Search';

class TableSortSearch extends Component {
  onFilterChange(filter){
    this.props.filterChangeHook({...this.props.filters, ...filter})
  }

  render() {
    const { filters, sortByOptions, searchRemove, searchPlaceholder } = this.props;
    return(
      <div className="d-flex flex-row align-items-center justify-content-between radio-btn">
        <div className="d-flex flex-row align-items-center">
          <span className="text-nowrap">Sort by</span>
          <select className="custom-select bg-white mx-5 custom-select-sm" value={filters.sort} onChange={(a)=> this.onFilterChange({sort: a.target.value})}>
            {
              sortByOptions.map((option)=> 
                <option value={option.value}>{option.name}</option>
              )
            }
          </select>
          <div className="radio-sm">
            <div className="custom-control custom-radio">
              <input name="order" id='asc' className="custom-control-input" type="radio" value="" checked={filters.direction==='asc'} onChange={(a)=> this.onFilterChange({direction: 'asc'})}/>
              <label className="custom-control-label pt-1" htmlFor='asc'>Ascending</label>
            </div>
          </div>
          <div className="radio-sm">
            <div className="custom-control custom-radio mx-5" onChange={(a)=> this.onFilterChange({direction: 'desc'})}>
              <input name="order" id='desc' className="custom-control-input" type="radio" value="" checked={filters.direction==='desc'}/>
              <label className="custom-control-label pt-1" htmlFor='desc'>Descending</label>
            </div>
          </div>
        </div>
        { 
          !searchRemove &&
          <div className="search-bar w-50">
              <Search inputClasses="form-control-sm" iconClasses="py-0" placeholder={searchPlaceholder ?? "Type to search..."} onChangeHook={(query) => this.onFilterChange({ search: query, page: 1 })} />
          </div> 
        }
      </div>
    )
  }
}

export default connect()(TableSortSearch)