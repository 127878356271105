import React from "react";
import { Route, Switch } from 'react-router-dom';
import asyncComponent from '../../../itrust_common/components/AsyncComponent';

const DashboardWrapper = asyncComponent(() =>
  import('../../../containers/authorised/addOns/whatsApp/dashboard/DashboardWrapper')
)

const FAQsWrapper = asyncComponent(() =>
  import('../../../containers/authorised/addOns/whatsApp/faqs/FAQsWrapper')
)

const LogsWrapper = asyncComponent(() =>
  import('../../../containers/authorised/addOns/whatsApp/logs/LogsWrapper')
)

const BusinessWrap = asyncComponent(() =>
  import('../../../containers/authorised/addOns/whatsApp/settings/business/BusinessWrap')
)

const ProfileWrap = asyncComponent(() =>
  import('../../../containers/authorised/addOns/whatsApp/settings/profile/ProfileWrap')
)

const WhatsAppRoutes = (
  <Switch>
    <Route exact path='/add-ons/whatsapp/dashboard' component={DashboardWrapper} />
    <Route exact path='/add-ons/whatsapp/logs' component={LogsWrapper} />
    <Route exact path='/add-ons/whatsapp/setting/business' component={BusinessWrap} />
    <Route exact path='/add-ons/whatsapp/setting/profile' component={ProfileWrap} />
    <Route exact path='/add-ons/whatsapp/faqs' component={FAQsWrapper} />
  </Switch>
)

export default WhatsAppRoutes;