import React from 'react';
import { Route, Switch } from 'react-router-dom';
import asyncComponent from '../../itrust_common/components/AsyncComponent';

const Campaign = asyncComponent(() =>
  import('../../containers/authorised/campaign/Campaign')
);

const CampaignRoutes = (
  <Switch>
    <Route exact path="/campaign" component={Campaign} />
  </Switch>
);

export default CampaignRoutes;