import Toastr from "../../../../itrust_common/components/Toastr"

const DEFAULT_STATE = {
  graph_data:{},
  kpi_data:{},
  saving:false
}
let changes = null

export const whatsAppReportReducer = (state = DEFAULT_STATE, action) => {
  const response = action.payload && (action.payload.data || action.payload.response)
  switch (action.type) {

    case 'WHATSAPP_REPORT_LOAD_PENDING':
      return { ...state, saving: true };

    case 'WHATSAPP_REPORT_LOAD_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = { saving: false }
      return { ...state, ...changes };

    case 'WHATSAPP_REPORT_LOAD_FULFILLED':
      changes = { ...response, saving: false }
      return { ...state, ...changes };

    default:
      return state
  }
}