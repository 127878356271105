import React, { Component } from 'react';
import { change, reset, submit } from 'redux-form'
import { connect } from 'react-redux'
import * as $ from 'jquery';

import { eyeglassLabOrderCreate, eyeglassLabOrderUpdate, eyeglassLabOrderEditCancel } from '../../../../../../redux/actions/order/eyeglassLabOrderActions';
import { ModalFormWrapper } from '../../../../../../itrust_common/components/others';
import LabOrderForm from './LabOrderForm'
import { orderLoad } from '../../../../../../redux/actions/order/orderActions';
import Alert from '../../../../../../itrust_common/components/Alert';

class LabOrderModal extends Component {

  handleLabOrderEditCancel() {
    this.props.dispatch(eyeglassLabOrderEditCancel());
    $(`#eyeglassLabOrderForm-${this.props.eyeglassId}-modal`).modal('hide');
  }

  async handleLabOrderSend() {
    const { eyeglassId } = this.props
    await this.props.dispatch(change(`eyeglassLabOrderForm-${eyeglassId}`, 'send_fax', true))
    this.props.dispatch(submit(`eyeglassLabOrderForm-${eyeglassId}`));
  }

  handleLabOrderSubmit(data) {
    const { eyegalssLabOrderEditing, eyeglassId } = this.props;
    if (data.send_fax && !data.contact_id) {
      Alert.warning('To send Lab Order Secure Fax need to be selected.')
      this.props.dispatch(change(`eyeglassLabOrderForm-${eyeglassId}`, 'send_fax', false))
    }
    else {
      if (data.id !== undefined) {
        this.props.dispatch(eyeglassLabOrderUpdate(eyegalssLabOrderEditing.id, data))
          .then((response) => this.handleAfterLabOrderSubmit(response))
          .catch(() => this.props.dispatch(change(`eyeglassLabOrderForm-${eyeglassId}`, 'send_fax', false)))
      }
      else {
        this.props.dispatch(eyeglassLabOrderCreate(eyegalssLabOrderEditing.id, data))
          .then((response) => this.handleAfterLabOrderSubmit(response))
          .catch(() => this.props.dispatch(change(`eyeglassLabOrderForm-${eyeglassId}`, 'send_fax', false)))
      }
    }
  }

  handleAfterLabOrderSubmit(response, data) {
    const { orderId } = this.props;
    this.props.dispatch(orderLoad(orderId, { include: '*' }))
    let url = response.value.data.eyeglass_lab_order.pdf_attributes.file.url
    window.open(url, '_blank');
    data.send_fax && this.handleEyeglassLabOrderFormClose()
  }

  handleEyeglassLabOrderFormClose() {
    $(`#eyeglassLabOrderForm-${this.props.eyeglassId}-modal`).modal('hide');
    this.props.dispatch(reset(`eyeglassLabOrderForm-${this.props.eyeglassId}`))
    this.props.dispatch(eyeglassLabOrderEditCancel());
  }

  render() {
    const { eyeglassId } = this.props;
    return (
      <div className="inner-modal">
        <ModalFormWrapper
          formName={`eyeglassLabOrderForm-${eyeglassId}`}
          formTitle="Lab Order"
          modalSize="modal-md"
          modalContentClasses="border-0 rounded shadow-lg"
          label='Save and Create'
          button2Submit={() => this.handleLabOrderSend()}
          handleEditCancel={this.handleLabOrderEditCancel.bind(this)}
        >
          <LabOrderForm
            form={`eyeglassLabOrderForm-${eyeglassId}`}
            onSubmit={this.handleLabOrderSubmit.bind(this)}
          />
        </ModalFormWrapper>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { eyeglassLabOrder } = state;
  return {
    saving: eyeglassLabOrder.saving,
    eyegalssLabOrderEditing: eyeglassLabOrder.editing,
  }
}

export default connect(mapStateToProps)(LabOrderModal);
