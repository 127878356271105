import React, { useEffect, useState } from 'react'
import * as $ from 'jquery';
import { connect, useDispatch } from 'react-redux';
import { currentUserEditing, currentUserUpdate } from '../../../itrust_common/redux/actions/authActions';
import PreferencesForm from './PreferencesForm';
import { getCurrentZoomLevel } from '../../../itrust_common/components/HelperFunctions';

function PreferencesModal(props) {
  const { voipConfig, twoWayMessageConfig, preferencesEdit, saving } = props
  const appZoomLevel = getCurrentZoomLevel() ? getCurrentZoomLevel() : '100%'
  const [isReloadRequire, setIsReloadRequire] = useState(false)
  const [currentTheme, setCurrentTheme] = useState(localStorage.getItem('theme'))
  const [currentZoomLevel, setCurrentZoomLevel] = useState(appZoomLevel)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!localStorage.getItem('theme') || localStorage.getItem('theme') == 'null') {
      localStorage.setItem('theme', 'theme1')
    }
  }, [])

  const handleCurrentUserUpdate = (data) => {
    dispatch(currentUserUpdate(data)).then(() => {
      localStorage.setItem('theme', currentTheme)
      localStorage.setItem('zoom_level', currentZoomLevel)
      handlePreferenceModalCancel()
      isReloadRequire && window.location.reload()
    })
  }

  const handlePreferenceModalCancel = () => {
    $('#preferences-modal').modal('hide')
    dispatch(currentUserEditing({ preferencesEdit: false }))
  }


  return (
    <div className="modal " id='preferences-modal'>
      <div className='modal-dialog modal-dialog-centered modal-xl mx-auto'>
        <div className='modal-content'>
          <div className='modal-header py-6'>
            <h5>Preferences</h5>
            <i className="las la-times font-size-24" onClick={() => handlePreferenceModalCancel()}></i>
          </div>
          <div className={`modal-body d-flex flex-row height-35 ${saving && 'disabled'}`}>
            <div className="nav flex-column nav-pills w-25 " id="preferences-tab" role="tablist" aria-orientation="vertical">
              <a className="nav-link active" id="theme-tab" data-toggle="pill" role="tab" href="#theme" aria-controls="theme" aria-selected="true">
                <div className='d-flex flex-row align-items-center'>
                  <i className="las la-pen mr-2"></i> <span>Themes</span>
                </div>
              </a>
              <a className="nav-link" id="accessibility-tab" data-toggle="pill" role="tab" href="#accessibility" aria-controls="accessibility" aria-selected="false" >
                <div className='d-flex flex-row align-items-center '>
                  <i className="las la-sliders-h rotate-90 mr-2"></i> <span>Accessibility</span>
                </div>
              </a>
              {((voipConfig?.visible && voipConfig?.status !== 'inactive') || (twoWayMessageConfig?.visible && twoWayMessageConfig?.status !== 'inactive')) &&
                <a className="nav-link" id="addOns-tab" data-toggle="pill" href="#addOns" role="tab" aria-controls="addOns" aria-selected="false">
                  <div className='d-flex flex-row align-items-center '>
                    <i className="las la-crown mr-2"></i> <span>Add-Ons</span>
                  </div>
                </a>
              }
            </div>
            {preferencesEdit &&
              <PreferencesForm
                onSubmit={handleCurrentUserUpdate}
                setIsReloadRequire={setIsReloadRequire}
                isReloadRequire={isReloadRequire}
                setCurrentTheme={setCurrentTheme}
                currentTheme={currentTheme}
                setCurrentZoomLevel={setCurrentZoomLevel}
              />
            }
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  const { organisation: { organisation }, auth: { editing, saving } } = state

  return {
    voipConfig: organisation.add_ons_config?.type?.voip,
    twoWayMessageConfig: organisation?.add_ons_config?.type?.two_way_message,
    preferencesEdit: editing.preferencesEdit,
    saving,
  }
}
export default connect(mapStateToProps)(PreferencesModal)