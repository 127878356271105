import {handleAPI, API_VERSION_ONE} from '../../itrust_common/utils/apiUtils';

export function referralsLoad(exam_id, filters){
  const url = `/${API_VERSION_ONE}/examinations/${exam_id}/referrals`;
  const method = 'GET'

  return {
    type: 'REFERRALS_LOAD',
    payload: handleAPI(url, filters, method)
  };
}

export function referralLoad(exam_id, id, filters){
  const url = `/${API_VERSION_ONE}/examinations/${exam_id}/referrals/${id}`;
  const method = 'GET'

  return {
    type: 'REFERRAL_LOAD',
    payload: handleAPI(url,filters,method)
  };
}

export function referralCreate(exam_id, data, filters){
  const url = `/${API_VERSION_ONE}/examinations/${exam_id}/referrals`;
  const method= 'POST'
  return {
    type: 'REFERRAL_CREATE',
    payload: handleAPI(url,filters,method, {referral: {...data}})
  }
}

export function referralUpdate(exam_id, data, filters){
  const url = `/${API_VERSION_ONE}/examinations/${exam_id}/referrals/${data.id}`;
  const method = "PUT"
  return {
    type: 'REFERRAL_UPDATE',
    payload: handleAPI(url,filters,method, {referral: {...data}})
  }
}

export function referralDelete(exam_id, id, filters) {
  const url = `/${API_VERSION_ONE}/examinations/${exam_id}/referrals/${id}`;
  const method = 'DELETE';

  return {
    type: 'REFERRAL_DELETE',
    payload: new Promise((resolve, reject) => {
      handleAPI(url, filters, method)
        .then(response => {
          resolve({ id: id, ...response });
        })
        .catch(err => {
          reject(err);
        });
    })
  };
}

export function referralEdit(id) {
  return {
    type: 'REFERRAL_EDIT',
    id,
  };
}

export function referralEditCancel() {
  return {
    type: 'REFERRAL_EDIT_CANCEL'
  };
}