import React, { useEffect, useRef } from 'react'
import NotificationsListing from './NotificationsListing';
import { notificationUpdate } from '../../../itrust_common/redux/actions/notificationActions';
import { useDispatch } from 'react-redux';
import * as $ from 'jquery';

export default function NotificationsListingWrapper(props) {
  const { id, unread_count, header, handleNotificationLoad, saving, notifications, logsRoute, totalRecords, handleMessageView, iconClasses, isAddonActive, NotificationAdvertisement, handleNotificationUpdate } = props;

  const filtersRef = useRef({
    direction: "desc",
    page: 1,
    rpp: 10,
    sort: "created_at"
  })
  const prevYRef = useRef(0);
  const totalRecordsRef = useRef(0)
  const notificationCountRef = useRef(10);
  const dispatch = useDispatch()

  const handleObserver = async (entities, observer) => {
    const y = entities[0].boundingClientRect.y;
    if ((prevYRef.current > y) && (notificationCountRef.current.length < totalRecordsRef.current)) {
      let newFilters = { ...filtersRef.current, page: filtersRef.current.page + 1 }
      await handleNotificationLoad(newFilters)
      filtersRef.current = { ...newFilters }
      notificationCountRef.current = notificationCountRef.current + 10
    }
    prevYRef.current = y > 900 ? y : 900;
  }

  useEffect(() => {
    isAddonActive && handleNotificationLoad({ ...filtersRef.current })
  }, [])

  useEffect(() => {
    if (isAddonActive) {
      notificationCountRef.current = notifications
      totalRecordsRef.current = totalRecords
      return () => {
        notificationCountRef.current = 0
        totalRecordsRef.current = 0
      }
    }
  }, [notifications, totalRecords])

  useEffect(() => {
    if (isAddonActive) {
      const options = {
        root: document.querySelector(`#infinite-${id}-notification-container`),
        rootMargin: '0px',
        threshold: 0.1,
        delay: 10
      };
      const observer = new IntersectionObserver(
        (entities, observer) => handleObserver(entities, observer),
        options
      );
      const target = document.querySelector(`#infinite-${id}-notification-target`);
      observer.observe(target);
    }
  }, []);

  const makeNotificationRead = async (notificationId, isRead) => {
    let updatedStatus = isRead ? 'unread' : 'read'
    let res = await dispatch(notificationUpdate({ id: notificationId, read_at: updatedStatus, include: ['activity'] }))
    let updatedNotification = res.value.data.notification
    dispatch(handleNotificationUpdate(updatedNotification))
  }

  $(document).on('click', '.notification-dropdown-menu', function (e) {
    e.stopPropagation();
  });

  return (
    <div className="dropdown mr-5 notification">
      <span className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
        <i className={`font-size-24 ${iconClasses}`}></i>
        {unread_count > 0 && (
          <small className="circle red text-white">
            {unread_count}
          </small>
        )}
      </span>
      <div className="dropdown-menu shadow border-0 pt-0 rounded notification-dropdown-menu">
        <h6 className='text-center py-4 border-bottom notification-header bg-white'>{header} Notification</h6>
        <div className='overflow-auto notification-container' id={`infinite-${id}-notification-container`}>
          {unread_count > 0 &&
            <li className="border-bottom border-bottom-2 border-light px-5 py-4">
              <div className="d-flex flex-row align-items-center justify-content-between">
                <span className="">You have {unread_count} new notifications.</span>
                <button className="btn btn-sm btn-link" onClick={() => handleNotificationLoad({ read: 'all', ...filtersRef.current, page: 1, rpp: 10 })}>Mark all as read</button>
              </div>
            </li>
          }
          {isAddonActive ?
            <NotificationsListing
              logsRoute={logsRoute}
              notifications={notifications}
              handleMessageView={handleMessageView}
              handleNotificationRead={makeNotificationRead}
            /> : <NotificationAdvertisement isPopupScreen={true} />
          }
          <span id={`infinite-${id}-notification-target`} className="d-flex flex-column justify-content-center align-items-center py-3">
            {saving && <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>}
          </span>
        </div>
        <div className='text-center py-4 border-top'>
          <span>Scroll down to view more</span>
        </div>
      </div>
    </div>
  )
}