import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import Alert from '../../../../../itrust_common/components/Alert';
import { whatsAppEdit } from '../../../../../redux/actions/addOns/whatsApp/whatsAppActions';

export default function FacebookLogin(props) {
  const { fbInfo, whatsAppAccountInfo, isPopupScreen, dispatch } = props;
  const history = useHistory();

  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: '1035506964460234',
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v18.0',
      });
    };

    // Load the SDK asynchronously
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }, []);

  function launchWhatsAppSignup() {
    if (localStorage.getItem('StoreID') == null) {
      Alert.warning('Please select store from top dropdown.');
    }
    else {
      console.log('Launching WhatsApp Signup...');
      window.FB.login(
        function (response) {
          console.log('Login response:', response);
          if (response.authResponse) {
            const accessToken = response.authResponse.accessToken;
            console.log('Access Token:', accessToken);
            // Use this token to call the debug_token API and get the shared WABA's ID
          } else {
            console.log('User cancelled login or did not fully authorize.');
          }
        },
        {
          config_id: '341277562077578',
          response_type: 'code',
          override_default_response_type: true,
          extras: {
            setup: fbInfo,
          },
        }
      );
    }
  }

  return (
    <>
      <Helmet>
        <script async defer crossorigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js"></script>
      </Helmet>
      <div className={`${!isPopupScreen && 'pt-7'}`}>
        {isPopupScreen && <div className='text-right bg-white'>
          <span onClick={() => dispatch(whatsAppEdit({ patientProfile: false }))}><i className="las la-times-circle font-size-20 text-primary pt-3 px-4 pointer"></i></span>
        </div>}
        <div className={`d-flex flex-column align-items-center justify-content-center text-center py-10 bg-white`}>
          <i className={`lab la-whatsapp whatsapp-icon-color text-white ${isPopupScreen ? 'font-size-190' : 'font-size-360'}`}></i>
          {whatsAppAccountInfo?.account_id ?
            <p className='my-7 font-size-20'>
              You are done with Account Creation Phase.<br /> Please Continue with Create Phone Number Phase to activate WhatsApp.
            </p> :
            <p className='my-7 font-size-20'>
              To activate the WhatsApp you have to login with Facebook first.
            </p>
          }
          <button type='button' className='btn btn-primary' onClick={launchWhatsAppSignup}>
            Login with Facebook
          </button>
        </div>
      </div>
    </>
  );
}