import React, {Component} from 'react';
import {connect} from 'react-redux';
import { dataLoad } from '../../redux/actions/autoCompleteActions';
var delayTimer;

class AutoComplete extends Component {
  constructor(props){
    super(props);
    this.state={data: [], search: "", isVisible: false};
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  handleSearch(value){
    const { klass, otherFilters, input } = this.props
    this.setState({search: value})
    this.setState({isVisible: true})
    input.onChange(value)
    var filters = {search: value, klass: klass, ...otherFilters}
    if (klass) {
      clearTimeout(delayTimer);
      delayTimer = setTimeout(function () {
        new Promise((resolve, reject) => {
          this.props.dispatch(dataLoad(filters))
            .then(response => this.setState({ data: response.value.data.data }))
        })
      }.bind(this), 500)
    }
  }

  handleSelect(val){
    this.setState({search: null})
    this.setState({isVisible: false})
    this.props.input.onChange(val)
    this.props.onSelect && this.props.onSelect(val)
  }

  handleClick() {
    if (!this.state.isVisible) {
      // attach/remove event handler
      document.addEventListener('click', this.handleOutsideClick, false);
    }

    this.setState({
       isVisible: !this.state.isVisible
    });
  }
  
  handleOutsideClick(e) {
    // ignore clicks on the component itself
    if (this.node.contains(e.target)) {
      return;
    }
    this.setState({ isVisible: false })
    document.removeEventListener('click', this.handleOutsideClick, false);
  }
  
  render(){
    const { placeholder, wrapperClasses, dropdownClasses, textField, valueField, label, labelClasses } = this.props
    const { 
      props: { input }
    } = this;
    const {data} = this.state
    return(
      <div className={`autocomplete-input ${wrapperClasses}`} ref={node => { this.node = node; }}>
        { label && <label className={labelClasses}>{label}</label> }
        <div className="input-group" onClick={this.handleClick.bind(this)}>
          <input value={this.state.search || input.value} onChange={(search) => this.handleSearch(search.target.value)} type="text" className="form-control" placeholder={placeholder}/>
          <div className="input-group-append">
            <span className="input-group-text search-button position-static bg-light"><i className="la la-search text-muted" /></span>
          </div>
        </div>
        <ul className={`dropdown-menu list-unstyled px-5 bg-white w-100 ${dropdownClasses} ${data && data.length > 0 && this.state.isVisible && 'show'}`}>
          {data && data.map(obj => (
            <li key={`auto-complete ${valueField ? obj[valueField] : obj.id}`} className="pointer" onClick={this.handleSelect.bind(this, valueField ? obj[valueField] : obj)}>
              {obj[textField]}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default connect()(AutoComplete);