import Toastr from "../../../itrust_common/components/Toastr"
import _ from 'lodash'

const DEFAULT_STATE = {
  patient_medical_histories: [],
  patient_medical_history: {},
  meta: { pagination: {} },
  saving: false
}
let changes = null;
export default function patientMedicalHistoryReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let patientMedicalHistoryData = [];
  let updatedRecord = {};
  let updatedMedicalHistoryCategory = "";
  let medicalHistoryCategoryData = "";
  switch(action.type){
    case 'PATIENT_MEDICAL_HISTORIES_LOAD_PENDING':
    case 'PATIENT_MEDICAL_HISTORY_LOAD_PENDING':
      return {...state, saving: true};

    case 'PATIENT_MEDICAL_HISTORY_CREATE_PENDING':
      return {...state, saving: true};

    case 'PATIENT_MEDICAL_HISTORIES_LOAD_REJECTED':
    case 'PATIENT_MEDICAL_HISTORY_LOAD_REJECTED':
    case 'PATIENT_MEDICAL_HISTORY_CREATE_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case `PATIENT_MEDICAL_HISTORIES_LOAD_FULFILLED`:
      let data={}
      _.chain(response.patient_medical_histories).groupBy("medical_history_category").map((value, key) => (data[key]=value)).value()
      changes = {
        patient_medical_histories: data,
        meta: response.meta,
        saving: false
      };
      return {...state, ...changes};
    
    case `PATIENT_MEDICAL_HISTORY_CREATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      let newRecord = response.patient_medical_history
      updatedMedicalHistoryCategory = newRecord.medical_history_category
      medicalHistoryCategoryData = state.patient_medical_histories[updatedMedicalHistoryCategory] || []
      changes = {
        patient_medical_histories: {...state.patient_medical_histories, [updatedMedicalHistoryCategory]: [...medicalHistoryCategoryData, newRecord]},
        editing: false,
        saving: false
      }
      return {...state, ...changes};

    case `PATIENT_MEDICAL_HISTORY_UPDATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      updatedRecord = response.patient_medical_history;
      updatedMedicalHistoryCategory = updatedRecord.medical_history_category
      medicalHistoryCategoryData = state.patient_medical_histories[updatedMedicalHistoryCategory]
      for (const [index, patientMedicalHistory] of medicalHistoryCategoryData.entries()) {
        if (patientMedicalHistory.id === updatedRecord.id) {
          patientMedicalHistoryData = [
            ...medicalHistoryCategoryData.slice(0, index),
            updatedRecord,
            ...medicalHistoryCategoryData.slice(index + 1)
          ];
        }
      }
      changes = {
        patient_medical_histories: {...state.patient_medical_histories, [updatedMedicalHistoryCategory]: patientMedicalHistoryData},
        editing: false,
        saving: false
      };
      return { ...state, ...changes };

    case `PATIENT_MEDICAL_HISTORY_DELETE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      let deletedRecord = response.patient_medical_history;
      updatedMedicalHistoryCategory = deletedRecord.medical_history_category
      medicalHistoryCategoryData = state.patient_medical_histories[updatedMedicalHistoryCategory] || []
      for (const [index, patientMedicalHistory] of medicalHistoryCategoryData.entries()) {
        if (patientMedicalHistory.id === deletedRecord.id) {
          medicalHistoryCategoryData.splice(index, 1);
        }
      }
      return {
        ...state,
        patient_medical_histories: {...state.patient_medical_histories, [updatedMedicalHistoryCategory]: medicalHistoryCategoryData},
        error: null
      };

    case `PATIENT_MEDICAL_HISTORY_DELETE_ALL_FULFILLED`:
      Toastr.handleShow(true, response.message, 201)
      updatedMedicalHistoryCategory = response.medical_history_category
      return {
        ...state,
        patient_medical_histories: {...state.patient_medical_histories, [updatedMedicalHistoryCategory]: []},
        error: null
      };

    default:
      return state;
  }
}