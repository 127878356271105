import React from 'react';
import { FieldArray } from 'redux-form';
import { FormTemplateHeader, FormTemplateSectionWrapper} from '../../others'
import Default from './additionalTests/Default'
import AmslerGrid from './additionalTests/AmslerGrid'
import PunctalPlug from './additionalTests/PunctalPlug'
import Epilation from './additionalTests/Epilation'
import ForeignBodyRemoval from './additionalTests/ForeignBodyRemoval'

import SkinTagRemovel from './additionalTests/SkinTagRemovel'
import AmnioticMembrane from './additionalTests/AmnioticMembrane'
import Pachymetry from './additionalTests/Pachymetry'
import InflammaDry from './additionalTests/InflammaDry'
import TearLab from './additionalTests/TearLab'

class RenderTest extends React.Component {

  renderForm(isTemplate, baseName, baseFieldValues, defaultLabel, isOpen){
    switch(defaultLabel){
    case 'Amsler grid':
      return <AmslerGrid isTemplate={isTemplate} baseName={baseName} baseFieldValues={baseFieldValues} isOpen={isOpen}/>
    case 'Punctal plug':
      return <PunctalPlug isTemplate={isTemplate} baseName={baseName} baseFieldValues={baseFieldValues} isOpen={isOpen}/>
    case 'Epilation':
      return <Epilation isTemplate={isTemplate} baseName={baseName} baseFieldValues={baseFieldValues} isOpen={isOpen}/>
    case 'Foreign body removal':
      return <ForeignBodyRemoval isTemplate={isTemplate} baseName={baseName} baseFieldValues={baseFieldValues} isOpen={isOpen}/>
    case 'Skin tag removal':
      return <SkinTagRemovel isTemplate={isTemplate} baseName={baseName} baseFieldValues={baseFieldValues} isOpen={isOpen}/>
    case 'Amniotic membrane':
      return <AmnioticMembrane isTemplate={isTemplate} baseName={baseName} baseFieldValues={baseFieldValues} isOpen={isOpen}/>
    case 'Pachymetry':
      return <Pachymetry isTemplate={isTemplate} baseName={baseName} baseFieldValues={baseFieldValues} isOpen={isOpen}/>
    case 'Inflamma dry':
      return <InflammaDry isTemplate={isTemplate} baseName={baseName} baseFieldValues={baseFieldValues} isOpen={isOpen}/>
    case 'Tear lab':
      return <TearLab isTemplate={isTemplate} baseName={baseName} baseFieldValues={baseFieldValues} isOpen={isOpen}/>
    default:
      return <Default isTemplate={isTemplate} baseName={baseName} baseFieldValues={baseFieldValues} isOpen={isOpen}/>
    }
  }

  render() {
    const {template, isTemplate, fields, pprops} = this.props
    return (
      <div className="row">
        {fields.map((member, index, members) => (
          <div key={`additional-test${index}`} className="col-xl-4 col-md-6 mt-7">
            <FormTemplateSectionWrapper isTemplate={isTemplate} fieldValues={template.additional_tests.tests[index]}>
              <div className="card border-0 shadow-sm px-5">
                <FormTemplateHeader 
                  isTemplate={isTemplate}
                  name={`${member}[is_marked_complete]`}
                  isToggle={members.get(index).is_marked_complete}
                  fieldValues={template.additional_tests.tests[index]} 
                  baseName={`${member}`}
                  overviewName={template.additional_tests.tests[index].default_label.split(" ").join('_').toLowerCase()}
                  pprops={pprops}
                />
                <div className={`${members.get(index).is_marked_complete || isTemplate ? '': 'd-none'}`}>
                  {template.additional_tests.tests[index].default_label !== undefined &&
                    this.renderForm(isTemplate, member, template.additional_tests.tests[index].fields, template.additional_tests.tests[index].default_label, members.get(index).is_marked_complete)}
                </div>
              </div>
            </FormTemplateSectionWrapper>
          </div>
        ))}
      </div>
    );
  }
}

const AdditionalTestForm = props => {
  const {template, isTemplate, pprops} = props
  const name = isTemplate ?  "additional_tests[tests]" : "additional_tests_attributes"
  return (
    <FieldArray name={name}
      props = {{
        template: template,
        isTemplate: isTemplate,
        pprops: pprops
      }} 
      component={RenderTest} />
  );
}

export default AdditionalTestForm;