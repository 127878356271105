import React, { Component, Fragment } from 'react';
import Header from '../../itrust_common/components/authorised/layouts/Topbar.js';
import Sidebar from '../../components/authorised/layouts/Sidebar';
import DashboardRoutes from './dashboardRoutes.js';
import AppointmentRoutes from './appointmentRoutes.js';
import PatientRoutes from './patientRoutes.js';
import ExamRoutes from './examRoutes';
import OrderRoutes from './orderRoutes.js';
import PaymentReportRoutes from './paymentReportRoutes';
import CampaignRoutes from './campaignRoutes';
import LogsRoutes from './logsRoutes';
import ActivityRoutes from '../../itrust_common/routes/authorised/activityRoutes';
// import {StoreSettingsOfficeRoutes, StoreSettingsMessageTrackingRoutes} from '../../routes/authorised/storeSettings/index';
import { SettingExamRoutes, SettingMessageTemplateRoutes, SettingOrderRoutes, SettingPatientRoutes, SettingContactRoutes, SettingCalendarRoutes, SettingInsuranceRoutes } from '../../itrust_common/routes/authorised/setting/index';
import OrganisationRoutes from './organisationRoutes.js';
import OrganisationPaymentRoutes from './organisationPaymentRoutes.js';
import { connect } from 'react-redux';
import AnalyticsRoutes from './analyticsRoutes';
import StoreSelection from '../../components/authorised/layouts/StoreSelection'

import EditExamModal from '../../components/authorised/buttons/EditExamModal'
import OrderModal from '../../components/authorised/buttons/OrderModal'
import CreateExamModal from '../../components/authorised/buttons/CreateExamModal'

// import { browserHistory } from 'react-router-dom';
import Alert from '../../itrust_common/components/Alert'
import AddOnsRoutes from './addOns/index.js';

class Authorised extends Component {

  getHeaderMenu(role) {
    const { organisation } = this.props
    if (role === "super_admin" || role === "itrust_admin") {
      return organisation.subscription_plan !== "enterprise" ? [{ title: 'Manage Organization', icon: 'la-hospital-o', path: '/organisation/highlights' }, { title: 'Preferences', icon: 'las la-cog' },
      { title: 'Your Billing', icon: 'la-dollar-sign', path: '/payment' },
      { title: 'Contact', icon: 'la-phone-square', path: '/settings/contact' }] : [{ title: 'Manage Organization', icon: 'la-hospital-o', path: '/organisation/highlights' }, { title: 'Preferences', icon: 'las la-cog' },
      { title: 'Contact', icon: 'la-phone-square', path: '/settings/contact' }]
    }
    else return [{ title: 'Contact', icon: 'la-phone-square', path: '/settings/contact' }, { title: 'Preferences', icon: 'las la-cog' }]
  }

  componentDidMount() {
    if (this.props.currentUser.role == 'non_admin' && !localStorage.getItem('StoreID')) {
      Alert.warning("Please select Store from header in order to access moduleNames")
    }
  }

  render() {
    const { layout, userType, currentUser, organisation, totalAmount, isTourActive } = this.props
    return (
      <Fragment>
        {
          ["super_admin", "itrust_admin"].includes(currentUser.role) &&
          <div className={`${layout !== 'payment' && 'd-none'}`}>
            {OrganisationPaymentRoutes}
          </div>
        }
        <div className={`${layout !== 'default' && 'd-none'}`}>
          <Header
            userType={userType}
            headerMenu={currentUser && this.getHeaderMenu(currentUser.role)}
            handleLogout={this.props.handleLogout}
            storeSelectionComponent={<StoreSelection currentUser={currentUser} />}
          />
          <div className="main-div">
            <Sidebar organisation={organisation} totalAmount={totalAmount} key={organisation.add_ons_config?.type} isTourActive={isTourActive} />
            <div className="main-content position-relative px-5 bg-light">
              {DashboardRoutes}
              {AppointmentRoutes}
              {ActivityRoutes}
              {PatientRoutes}
              {ExamRoutes}
              {OrderRoutes}
              {CampaignRoutes}
              {LogsRoutes}
              {AnalyticsRoutes}
              {PaymentReportRoutes}
              <AddOnsRoutes />
              {
                ["super_admin", "admin", "itrust_admin"].includes(currentUser.role) &&
                [OrganisationRoutes,
                  SettingExamRoutes,
                  SettingMessageTemplateRoutes,
                  SettingOrderRoutes,
                  SettingPatientRoutes,
                  SettingCalendarRoutes,
                  SettingContactRoutes,
                  SettingInsuranceRoutes]
              }

              <EditExamModal />
              <OrderModal />
              <CreateExamModal />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { other, auth, organisation, itrustPay, onBoarding: { isTourActive } } = state;
  return {
    layout: other.layout,
    currentUser: auth.user,
    organisation: organisation.organisation,
    totalAmount: itrustPay.wallet_details.organisation_wallet_amount,
    isTourActive,
  }
}

export default connect(mapStateToProps)(Authorised);
