import React from 'react'
import { Radio } from '../../../../../itrust_common/components/form'
import { Field } from 'redux-form'
import { getCardBrandImg } from '../../../../../itrust_common/components/HelperFunctions'

function PaymentMethodListing(props) {
  const { payment_methods, handlePaymentMethodDelete } = props

  return (
    <>
      {payment_methods?.map((paymentMethod) => {
        return <label className={`mt-4 d-flex`} key={paymentMethod.id}>
          <Field type="radio" component={Radio} name="payment_method_id" value={paymentMethod.card_id} />
          {getCardBrandImg(paymentMethod.brand)}
          <span className="mx-2 ">{`XXXX XXXX XXXX ${paymentMethod.last4digit}`}</span>
          <i className="las la-trash-alt mt-1 ml-3 pointer font-size-18 text-danger" onClick={() => handlePaymentMethodDelete(paymentMethod.id)}></i>
        </label>
      })}
    </>
  )
}

export default PaymentMethodListing;