import React, { Component } from 'react';
import { connect } from 'react-redux';

import InvoiceReportTemplate from '../../../../itrust_common/containers/authorised/setting/patient/report/reportTypes/ReportTemplate';

import Procedure from './invoice/Procedure'
import MiscItem from './invoice/MiscItem';
import ContactLens from './invoice/ContactLens';
import Eyeglass from './invoice/Eyeglass';
import { numberToCurrency } from '../../../../itrust_common/components/HelperFunctions';

class Invoice extends Component{
  getProcedureTotal(procedures){
    let total = 0
    procedures.forEach(procedure => {
      return total = total + procedure.subtotal
    })
    return total
  }
  render(){
    const { exam, order, order: {procedures_attributes, misc_items_attributes, contact_lenses_attributes, eyeglasses_attributes} } = this.props
    const { patientReport: {display_fields, display_values} } = this.props
    return (
      <div className="card border-0 shadow-sm px-5">
        <div className="card-body" id="orderInvoicePdf">
          <div className="row">
            <div className="col-12 px-0">
              <InvoiceReportTemplate 
                displayFields={display_fields} 
                displayValues={display_values} 
                orderDetails={{
                  date: order.order_date,
                  invoice_id: order.id,
                  payment_status: order.payment_status,
                  payments: order.payments_attributes
                  // cash_payment: order.cash_payment,
                  // check_payment: order.check_payment,
                  // card_payment: order.card_payment,
                  // other_payment: order.other_payment,
                }} 
                category="invoice">
                <div className="pt-7">
                  {order.exam_examination_id && exam.impressions_attributes && (exam.impressions_attributes.length > 0) && <div className="overview-table px-7 mb-7">
                    <table className="table table-hover shadow-none">
                      <thead className="text-dark border-bottom border-white border-bottom-2">
                        <tr>
                          <th scope="col"><h5>Impression/Plan</h5></th>
                          <th scope="col" className="text-right pr-9"><h5>Code</h5></th>
                        </tr>
                      </thead>
                      <tbody>
                        { exam.impressions_attributes.map(impression =>
                            <tr className="text-dark bg-light border-bottom border-white border-bottom-2">
                              <td>{impression.impression.name}</td>
                              <td className="text-right pr-9">{impression.impression.code}</td>
                            </tr>
                          )
                        }
                      </tbody>
                    </table>
                  </div>
                  }
                  <div className="overview-table px-7">
                    <table className="table table-hover shadow-none">
                      <thead className="text-dark border-bottom border-white border-bottom-2">
                        <tr>
                          <th scope="col"><h5>Item Description</h5></th>
                          <th scope="col"></th>
                          <th scope="col" className="pl-8"><h5>Unit Cost</h5></th>
                          <th scope="col"><h5>Copay</h5></th>
                          <th scope="col"><h5>Discount</h5></th>
                          <th scope="col"><h5>Item Subtotal</h5></th>
                          <th scope="col"><h5>Overall Discount</h5></th>
                          <th scope="col"><h5>Total</h5></th>
                        </tr>
                      </thead>
                      <tbody>
                        <Procedure procedures={procedures_attributes} order={order}/>
                        <MiscItem miscItems={misc_items_attributes} order={order} />
                        <ContactLens contactLens={contact_lenses_attributes} />
                        <Eyeglass eyeglasses={eyeglasses_attributes} />
                      </tbody>
                    </table>
                  </div>
                  
                  <div className="overview-table px-7 w-25 py-4 ml-auto">
                    <table className="table shadow-none">
                      <tbody>
                        <tr className="border-bottom">
                          <td className="text-center pr-4">Sub Total</td>
                          <td className="text-center">{numberToCurrency(order.total)}</td>
                        </tr>
                        {/* <tr className="border-bottom">
                          <td className="text-center pr-4">Tax</td>
                          <td className="text-center">{numberToCurrency(0)}</td>
                        </tr> */}
                        <tr className="border-bottom">
                          <td className="text-center pr-4 font-weight-bold">Grand Total</td>
                          <td className="text-center font-weight-bold">{numberToCurrency(order.total)}</td>
                        </tr>
                        <tr>
                          <td className="text-center pr-4">Amount Paid</td>
                          <td className="text-center">{numberToCurrency(order.amount_paid)}</td>
                        </tr>
                        <tr>
                          <td className="text-primary text-center pr-4">Balance Due</td>
                          <td className="text-primary text-center">{numberToCurrency(order.balance_due)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </InvoiceReportTemplate>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {order: {order}, exam: {exam}, setting: {patientReport}} = state;
  return {
    patientReport: patientReport.patient_reports[0],
    order: order,
    exam: exam
  }
}

export default connect(mapStateToProps)(Invoice);