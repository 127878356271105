import Toastr from "../../../components/Toastr"
const parent = 'SETTING_'

const DEFAULT_STATE = {
  insurance_plans: [],
  insurance_plan: {},
  meta: {pagination: {}},
  saving: false
}
let changes = null;
export default function insurancePlanReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let insurance_plans = [];
  let insurancePlanData = [];
  let updatedRecord = {};
  switch(action.type){
    case `${parent}INSURANCE_PLANS_LOAD_PENDING`:
    case `${parent}INSURANCE_PLAN_LOAD_PENDING`:
      return {...state, saving: false};

    case `${parent}INSURANCE_PLAN_CREATE_PENDING`:
    case `${parent}INSURANCE_PLAN_UPDATE_PENDING`:
      return {...state, saving: true};

    case `${parent}INSURANCE_PLANS_LOAD_REJECTED`:
    case `${parent}INSURANCE_PLAN_LOAD_REJECTED`:
    case `${parent}INSURANCE_PLAN_CREATE_REJECTED`:
    case `${parent}INSURANCE_PLAN_UPDATE_REJECTED`:
    case `${parent}INSURANCE_PLAN_DELETE_REJECTED`:
      Toastr.handleShow(response.data.success, response.data.error, response.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case `${parent}INSURANCE_PLANS_LOAD_FULFILLED`:
      changes = {
        ...response,
        saving: false
      };
      return {...state, ...changes};
    
    case `${parent}INSURANCE_PLAN_LOAD_FULFILLED`:
      changes = {
        insurance_plan: response.insurance_plan,
        saving: false
      }
      return {...state,...changes};
    
    case `${parent}INSURANCE_PLAN_CREATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      insurance_plans = [...state.insurance_plans]
      let newRecord = response.insurance_plan
      changes = {
        insurance_plans: [...insurance_plans, newRecord],
        editing: false,
        saving: false
      }
      return {...state, ...changes};

    case `${parent}INSURANCE_PLAN_UPDATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      insurance_plans = [...state.insurance_plans];
      updatedRecord = response.insurance_plan;
      for (const [index, insurance] of insurance_plans.entries()) {
        if (insurance.id === updatedRecord.id) {
          insurancePlanData = [
            ...insurance_plans.slice(0, index),
            updatedRecord,
            ...insurance_plans.slice(index + 1)
          ];
        }
      }
      changes = {
        insurance_plans: insurancePlanData,
        saving: false,
        editing: false,
      };
      return { ...state, ...changes };

    case `${parent}INSURANCE_PLAN_DELETE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      insurance_plans = [...state.insurance_plans];
      for (const [index, insurance] of insurance_plans.entries()) {
        if (insurance.id === response.insurance_plan.id) {
          insurance_plans.splice(index, 1);
        }
      }
      return {
        ...state,
        insurance_plans: insurance_plans,
        error: null
      };

    case `${parent}INSURANCE_PLAN_EDIT`:
      changes = {
        editing: {
          id: action.id,
        },
      };
      return { ...state, ...changes };

    case `${parent}INSURANCE_PLAN_EDIT_CANCEL`:
      changes = {
        editing: false
      };
      return { ...state, ...changes };

    case `${parent}INSURANCE_PLANS_LIST_RESET`:
      changes = {
        insurance_plans: []
      };
      return {...state, ...changes};

    default:
      return state;
  }
}