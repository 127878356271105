import moment from "moment-timezone"
import { startRecording, stopRecoding } from "../../../support/utils/recorder"
import * as $ from 'jquery'

export async function handleStartExamRecording() {
  const res = await startRecording()
  !res && $('#editExamForm-modal').modal('hide')
  return res
}

export async function handleStopExamRecording(exam) {
  var res = await stopRecoding()
  const fileName = `${moment(new Date()).format("MM/DD/YYYY_hh:mma")}`
  res = {
    file: res.attachmentBase,
    filename: fileName,
    content_type: 'video/webm',
    byte_size: res.byte_size
  }
  return res
}
