import React, {Component} from 'react';
import CanvasDraw from "react-canvas-draw";

class CanvasDrawing extends Component{
  constructor(props){
    super(props)
    this.state={
      brushColor: "#000",
      brushRadius: "1",
    }
  }

  handleDrawingAction(action){
    if(action === 'clear')
      this[this.props.canvasRef].clear()
    if(action === 'undo')
      this[this.props.canvasRef].undo()
    
    this.props.onChangeHook && this.props.onChangeHook(JSON.parse(this[this.props.canvasRef].getSaveData()))
  }

  componentDidMount(){
    setTimeout(function(){
      if(this[this.props.canvasRef]){
        this[this.props.canvasRef].drawImage();
      }
    }.bind(this), 2000)
  }

  componentDidUpdate(){
    setTimeout(function(){
      if(this[this.props.canvasRef]){
        this[this.props.canvasRef].drawImage();
      }
    }.bind(this), 2000)
  }
  

  render(){
    const {imgSrc, defaultData, canvasRef, canvasHeight, canvasWidth, wrapperClasses, isTemplate, className, isMarkCompleted, disabled} = this.props
    return (
      <div className={`canvas-drawing ${wrapperClasses}`}>
        { !isTemplate && <div className="d-flex flex-row justify-content-between px-7 mb-3 align-items-center">
          <input type="color" value={this.state.brushColor} style={{width:'4rem'}} onChange={e => this.setState({brushColor: e.target.value})}/>
          <input type="number" className="form-control form-control-sm" style={{width:'4rem'}} value={this.state.brushRadius} onChange={(e)=> this.setState({brushRadius: parseInt(e.target.value, 10)})}/>
          <div className="">
            <button type="button" className="btn btn-sm btn-outline-primary mr-5" onClick={() => this.handleDrawingAction("clear")}>Clear</button>
            <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => this.handleDrawingAction("undo")}>Undo</button>
            {/* <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => this.handleDrawingAction("save")}>Done</button> */}
          </div>
        </div> }
        <div className="img-fluid py-5">
          { isMarkCompleted && <CanvasDraw
            ref={canvasDraw => (this[canvasRef] = canvasDraw)}
            className={className}
            brushColor={this.state.brushColor}
            imgSrc={imgSrc}
            saveData={(defaultData === null || defaultData === undefined) ? JSON.stringify({lines: []}) : JSON.stringify(defaultData) }
            brushRadius={this.state.brushRadius}
            lazyRadius={0}
            loadTimeOffset={0}
            canvasWidth={canvasWidth}
            canvasHeight={canvasHeight}
            disabled={disabled}
            onChange={() => this.handleDrawingAction("save")}
          /> }
        </div>
      </div>
    );
  }
}

export default CanvasDrawing;