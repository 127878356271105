const DEFAULT_STATE = {
  tab: 1
}

export default function wizardReducer(state = DEFAULT_STATE, action){
  switch(action.type){
    case 'NEXT_TAB':
      return { ...state, tempTab: state.tab+1 };
    case 'PREVIOUS_TAB':
      return { ...state, tempTab: state.tab-1 };
    case 'JUMP_TO_TAB':
      return { ...state, tempTab: action.tabNumber };
    case 'SET_CURRENT_TAB':
      return { ...state, tab: state.tempTab };
    case 'RESET_TAB':
      return { ...state, tab: 1 };

    default:
      return state;
  }
}