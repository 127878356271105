import Toastr from "../../../itrust_common/components/Toastr"
const DEFAULT_STATE = {
  patient_social_histories: [],
  patient_social_history: {},
  meta: { pagination: {} },
  saving: false
}
let changes = null;
export default function patientSocialHistoryReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let patientSocialHistories = [];
  let patientSocialHistoryData = [];
  let updatedRecord = {};
  switch(action.type){
    case 'PATIENT_SOCIAL_HISTORIES_LOAD_PENDING':
      return {...state, saving: true};

    case 'PATIENT_SOCIAL_HISTORY_CREATE_PENDING':
      return {...state, saving: true};

    case 'PATIENT_SOCIAL_HISTORIES_LOAD_REJECTED':
    case 'PATIENT_SOCIAL_HISTORY_CREATE_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case 'PATIENT_SOCIAL_HISTORIES_LOAD_FULFILLED':
      changes = {
        ...response,
        saving: false
      };
      return {...state, ...changes}
    
    case 'PATIENT_SOCIAL_HISTORY_CREATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      patientSocialHistories = [...state.patient_social_histories]
      let newRecord = response.patient_social_history
      changes = {
        patient_social_histories: [...patientSocialHistories, newRecord],
        saving: false
      }
      return {...state, ...changes};

    case 'PATIENT_SOCIAL_HISTORY_UPDATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      patientSocialHistories = [...state.patient_social_histories];
      updatedRecord = response.patient_social_history;
      for (const [index, patientSocialHistory] of patientSocialHistories.entries()) {
        if (patientSocialHistory.id === updatedRecord.id) {
          patientSocialHistoryData = [
            ...patientSocialHistories.slice(0, index),
            updatedRecord,
            ...patientSocialHistories.slice(index + 1)
          ];
        }
      }
      changes = {
        patient_social_histories: patientSocialHistoryData,
        editing: false,
        saving: false
      };
      return { ...state, ...changes };

    default:
      return state;
  }
}