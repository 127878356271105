import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import asyncComponent from '../../../components/AsyncComponent';

const VisionInsurance = asyncComponent(() =>
  import('../../../containers/authorised/setting/insuranceSetting/vision/VisionInsurance')
)

const MedicalInsurance = asyncComponent(() =>
  import('../../../containers/authorised/setting/insuranceSetting/medical/MedicalInsurance')
)

const VisionInsurancePlans = asyncComponent(() =>
  import('../../../containers/authorised/setting/insuranceSetting/vision/VisionInsurancePlans')
)

const MedicalInsurancePlans = asyncComponent(() =>
  import('../../../containers/authorised/setting/insuranceSetting/medical/MedicalInsurancePlans')
)

const insuranceRoutes = (
  <Switch>
    <Route exact path="/settings/insurance/vision-insurance" component={VisionInsurance} />
    <Route exact path="/settings/insurance/medical-insurance" component={MedicalInsurance} />
    <Route exact path="/settings/insurance/vision-insurance/plans" component={VisionInsurancePlans} />
    <Route exact path="/settings/insurance/medical-insurance/plans" component={MedicalInsurancePlans} />
    <Redirect exact from="/settings/insurance" to="/settings/insurance/vision-insurance" />
  </Switch>
);

export default insuranceRoutes;