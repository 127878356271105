import React, { useEffect } from 'react'
import LogsListing from '../../../../../../../../itrust_common/components/others/listings/LogsListing'
import { Field, FieldArray, change } from 'redux-form'
import { Checkbox, InputWithIcon } from '../../../../../../../../itrust_common/components/form'
import EyeMedFormButton from './EyeMedFormButton'
import { connect, useDispatch } from 'react-redux'

function EyeMedFinalStep(props) {
  const { rpaClaimForm, isOverview } = props
  const dispatch = useDispatch()

  const LogsHead = [
    { title: 'S.No', className: 'text-nowrap' },
    { title: 'Vision Care Service or Material', className: 'text-nowrap' },
    { title: 'Usual Charge', className: 'text-nowrap' },
  ]

  const RenderEyeMedClaimService = (props) => {
    const { fields } = props

    useEffect(() => {
      if (!isOverview) {
        rpaClaimForm.claim_data?.charges?.map((item, index) => {
          handleChangeCharge(item.charge, `claim_data.charges[${index}]`)
          handleChangePermitZeroCharge(item.permit_0_charge, `claim_data.charges[${index}]`, item.charge)
        })
      }
    }, [rpaClaimForm.claim_data?.charges])

    const handleChangePermitZeroCharge = (checked, field, charge) => {
      dispatch(change('EyeMedClaimForm', `${field}[charge]`, (checked === true || checked === 'yes') ? '0' : charge ?? ''))
    }

    const handleChangeCharge = (value, field) => {
      dispatch(change('EyeMedClaimForm', `${field}[permit_0_charge]`, (value === '0' || !value) ? 'yes' : 'no'))
    }

    return (
      <>
        {fields.map((member, index, members) => <tr>
          <td>{index + 1}</td>
          <td>{members.get(index).service}</td>
          <td>
            <Field name={`${member}[charge]`} component={InputWithIcon} title="$" className="form-control" wrapperClasses="pl-4" readOnly={isOverview} onChange={(e) => handleChangeCharge(e.target.value, member)} />
          </td>
          {!isOverview && <td>
            <Field name={`${member}[permit_0_charge]`} component={Checkbox} wrapperClasses="pl-4" format={(value) => value === 'yes'} normalize={(value) => (value ? 'yes' : 'no')} onChange={(e) => handleChangePermitZeroCharge(e.target.checked, member)} />
          </td>}
        </tr>)}
      </>
    )
  }

  return (
    <LogsListing logs={rpaClaimForm.claim_data?.charges} TableHead={isOverview ? LogsHead : [...LogsHead, { title: 'Permit $0 Charge?', className: 'text-nowrap' }]}>
      <FieldArray name="claim_data.charges" props={{ rpaClaimForm }} component={RenderEyeMedClaimService} />
      {!isOverview && <tr>
        <td colSpan={10}>
          <EyeMedFormButton buttonClasses="py-2" isFinalScreen={true} />
        </td>
      </tr>}
    </LogsListing>
  )
}

const mapStateToProps = (state) => {
  return {
  }
}

export default connect(mapStateToProps)(EyeMedFinalStep);