import Toastr from "../../itrust_common/components/Toastr"
const DEFAULT_STATE = {
  staff_stores: [],
  staff_store: {},
  meta: { pagination: {} },
  saving: false
}
let changes = null;
export default function staffStoreReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let staffStores = [];
  let staffStoreData = [];
  let updatedRecord = {};
  switch(action.type){
    case 'STAFF_STORES_LOAD_PENDING':
      return {...state, saving: true};

    case 'STAFF_STORE_CREATE_PENDING':
      return {...state, saving: true};

    case 'STAFF_STORES_LOAD_REJECTED':
    case 'STAFF_STORE_CREATE_REJECTED':
    case 'STAFF_STORE_UPDATE_REJECTED':
    case 'STAFF_STORE_DELETE_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case 'STAFF_STORES_LOAD_FULFILLED':
      changes = {
        ...response,
        saving: false
      };
      return {...state, ...changes};
    
    case 'STAFF_STORE_CREATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      staffStores = [...state.staff_stores]
      let newRecord = response.staff_store
      changes = {
        staff_stores: [...staffStores, newRecord],
        editing: false,
        saving: false
      }
      return {...state, ...changes};

    case 'STAFF_STORE_UPDATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      staffStores = [...state.staff_stores];
      updatedRecord = response.staff_store;
      for (const [index, staffStore] of staffStores.entries()) {
        if (staffStore.id === updatedRecord.id) {
          staffStoreData = [
            ...staffStores.slice(0, index),
            updatedRecord,
            ...staffStores.slice(index + 1)
          ];
        }
      }
      changes = {
        staff_stores: staffStoreData,
        editing: false,
        saving: false
      };
      return { ...state, ...changes };

    case 'STAFF_STORE_DELETE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      staffStores = [...state.staff_stores];
      for (const [index1, staffStore] of staffStores.entries()) {
        if (staffStore.id === action.payload.id) {
          staffStores.splice(index1, 1);
        }
      }
      return {
        ...state,
        staff_stores: staffStores,
        error: null
      };

    case 'STAFF_STORES_DELETE_FULFILLED':
      Toastr.handleShow(true, response.message, 201)
      changes = {
        ...DEFAULT_STATE,
      }
      return {...state,...changes};
    
    case 'PERMISSION_ALL_REVOKED_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      changes = {
        ...response,
      };
      return { ...state, ...changes };
    
    case 'STAFF_STORE_EDIT':
      changes = {
        editing: {
          id: action.id,
        },
        staff_store: {}
      };
      return { ...state, ...changes };
  
    case 'STAFF_STORE_EDIT_CANCEL':
      changes = {
        editing: false
      };
      return { ...state, ...changes };

    default:
      return state;
  }
}