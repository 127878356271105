import Toastr from "../../../itrust_common/components/Toastr";
const DEFAULT_STATE = {
  payment_methods: [],
  payment_method: {},
  meta: { pagination: {} },
  saving: false,
  initializing: false
}

let changes = null;

export default function patientPaymentMethodReducer(state = DEFAULT_STATE, action) {
  const response = action.payload && (action.payload.data || action.payload.response)
  let payment_methods = []
  let payment_method = {}
  let updatedRecord = {}
  switch (action.type) {
    case 'PATIENT_PAYMENT_METHOD_INITIALIZE_PENDING':
      changes = {
        initializing: true,
        saving: true
      }
      return { ...state, ...changes }

    case 'PATIENT_PAYMENT_METHOD_CREATE_PENDING':
    case 'PATIENT_PAYMENT_METHOD_DELETE_PENDING':
    case 'PATIENT_PAYMENT_METHOD_UPDATE_PENDING':
      changes = {
        saving: true
      }
      return { ...state, ...changes }

    case 'PATIENT_PAYMENT_METHOD_INITIALIZE_REJECTED':
      changes = {
        initializing: false,
        saving: false
      }
      return { ...state, ...changes }

    case 'PATIENT_PAYMENT_METHOD_CREATE_REJECTED':
    case 'PATIENT_PAYMENT_METHOD_DELETE_REJECTED':
    case 'PATIENT_PAYMENT_METHOD_UPDATE_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.status_code)
      changes = {
        saving: false
      }
      return { ...state, ...changes };

    case 'PATIENT_PAYMENT_METHOD_INITIALIZE_FULFILLED':
      changes = {
        initializing: false,
        payment_methods: response["add_ons/itrust_pay/customer_cards"],
        saving: false,
        ...response.payment_data
      }
      return { ...state, ...changes }

    case 'PATIENT_PAYMENT_METHOD_LOAD':
      changes = { payment_method: { ...response } }
      return { ...state, ...changes }

    case 'PATIENT_PAYMENT_METHOD_CREATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      payment_method = response["add_ons/itrust_pay/customer_card"]
      payment_methods = [...state.payment_methods];
      changes = {
        payment_methods: [...payment_methods, payment_method],
        saving: false
      }
      return { ...state, ...changes }

    case 'PATIENT_PAYMENT_METHOD_DELETE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      payment_methods = [...state.payment_methods];
      let deletedPaymentMethod = { ...response["add_ons/itrust_pay/customer_card"] }
      for (const [index, payment_method] of payment_methods.entries()) {
        if (payment_method.id === deletedPaymentMethod.id) {
          payment_methods.splice(index, 1);
        }
      }
      return {
        ...state,
        payment_methods,
        error: null,
        saving: false
      };

    case 'PATIENT_PAYMENT_METHOD_UPDATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      payment_methods = [...state.payment_methods];
      updatedRecord = { ...response["add_ons/itrust_pay/customer_card"] };
      for (const [index, payment_method] of payment_methods.entries()) {
        if (payment_method.id === updatedRecord.id) {
          payment_methods = [
            ...payment_methods.slice(0, index),
            updatedRecord,
            ...payment_methods.slice(index + 1)
          ];
        }
      }
      changes = {
        payment_methods: payment_methods,
        editing: false,
        saving: false
      };
      return { ...state, ...changes };

    case 'PATIENT_PAYMENT_METHOD_RESET':
      changes = {
        ...DEFAULT_STATE
      };
      return { ...state, ...changes };

    default:
      return state;
  }
}