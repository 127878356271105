import React, { Component } from 'react';
import _ from 'lodash';

export default class Dropdown extends Component {

  placeholder(state, props) {
    const { props: { data, input, valueField, textField, placeholder } } = this;

    const selectedLabel = _.find(data, function(o) { return eval(`o.${valueField}`).toString() === input.value.toString(); });
    if(selectedLabel)
      return(eval(`selectedLabel.${textField}`))
    else
      return(<span className="place-visible">{placeholder}</span>)
  }

  handleOnClick(val){
    const { 
      props: { input, onChangeHook }
    } = this;
    input.onChange(val)
    onChangeHook && onChangeHook(val)
  }

  resetValue() {
    const { input } = this.props
    input.onChange(null)
  }

  render() {
    const { 
      props, 
      props: { input, disabled, valueField, textField, wrapperClasses }, 
      props: { meta: { touched, error, warning} },
    } = this;
    return (
      <div className={`${wrapperClasses} ${props.className} ${disabled && 'disabled'}`}>
        <input {...input} className={`invisible d-none ${touched && error && 'danger'}`} />
        <label className={input.value && 'active'}>
          {props.label}
        </label>
        <div className={`dropdown border rounded form-control${props.disabled ? 'bg-light': ''}`}>
          <a 
            className={`selected dropdown-toggle d-block ${props.disabled ? 'disabled': ''}`}
            data-toggle="dropdown" 
            aria-haspopup="true" 
            aria-expanded="false"
          > 
            {this.placeholder()}
            <span className="la la-angle-down icon-position text-muted" />
          </a>
          {input.value && <span className="la la-times-circle text-right text-muted reset-close pointer" onClick={() => this.resetValue()}/> }

          <div className="dropdown-menu w-100 ml-n3 mt-4">
            {props.data.map(data => (
              <div
                key={eval(`data.${valueField}`).toString()}
                className={
                  'dropdown-item ' + (input.value.toString() === eval(`data.${valueField}`).toString() ? 'active' : '')
                }
                onClick={this.handleOnClick.bind(this, eval(`data.${valueField}`))}
              >
                {eval(`data.${textField}`)}
              </div>
            ))}
          </div>
        </div>
        
        { touched && 
          ((error && <span className="error-msg text-danger">{error}</span>) ||
            (warning && <span>{warning}</span>))}
        {/* { touched && (error && <i className="la la-warning error-icon"></i>)} */}
      </div>
    );
  }
}