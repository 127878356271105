import React, { useEffect } from 'react';
import ActionCable from 'actioncable';
import { connect, useDispatch } from 'react-redux';
import { WS_ROOT } from '../../../../env';
import { whatsAppLogUpdate } from '../../../../redux/actions/addOns/whatsApp/whatsAppActions';

function WhatsAppNotification(props) {
  const dispatch = useDispatch()

  useEffect(() => {
    let accessToken = localStorage.getItem('Access-Token');
    (accessToken && accessToken !== 'null' && accessToken.length > 15) && handleActionCableCreate()
  }, [])

  const handleActionCableCreate = () => {
    setTimeout(function () {
      var cable = ActionCable.createConsumer(getWebSocketURL());
      cable.subscriptions.create(
        {
          channel: 'WhatsappChannel',
        },
        {
          connected: function () {
            console.log('WhatsApp Socket Connected');
          },
          disconnected: function () {
            console.log('WhatsApp Socket Disconnected');
          },
          received: function (data) {
            data = JSON.parse(data.data)
            dispatch(whatsAppLogUpdate(data))
            console.log('notification received');
          },
          speak: function (message) {
          }
        }
      );
    }, 4000)
  }

  const getWebSocketURL = () => {
    const token = localStorage.getItem('Access-Token')
    return `${WS_ROOT}/cable?token=${token}&org=org&ch=app`
  }

  return (
    <></>
  )
}

export default connect()(WhatsAppNotification)