import React, { useState, useEffect } from 'react'
import AddPaymentMethodForm from './AddPaymentMethodForm'
import PaymentMethodListing from './PaymentMethodListing'
import { patientPaymentInitialize, patientPaymentMethodCreate, patientPaymentMethodDelete } from '../../../../../redux/actions/patient/patientPaymentMethodActions'
import { connect, useDispatch } from 'react-redux'
import Alert from '../../../../../itrust_common/components/Alert'

function OnlinePaymentWrapper(props) {
  const [showAddCardForm, setshowAddCardForm] = useState(false)
  const { order, patient_attributes, patientPaymentMethod: { payment_methods, initializing, saving } } = props
  const dispatch = useDispatch()
  const paymentMethodFormInitials = {
    card_holder_name: patient_attributes?.name,
    address_line1: patient_attributes?.home_address_attributes?.address1 ? patient_attributes?.home_address_attributes?.address1 : '',
    city: patient_attributes?.home_address_attributes?.city ? patient_attributes?.home_address_attributes?.city : '',
    state: patient_attributes?.home_address_attributes?.state ? patient_attributes?.home_address_attributes?.state : '',
    zip: patient_attributes?.home_address_attributes?.zip ? patient_attributes?.home_address_attributes?.zip : '',
    country :'USA'
  }

  useEffect(() => {
    dispatch(patientPaymentInitialize({ patient_id: order.patient_id }))
  }, [order.patient_id])

  const handlePaymentMethodSubmit = async (data) => {
    await dispatch(patientPaymentMethodCreate({ card_details: { ...data, last4digit: data.card_number?.slice(-4) }, patient_id: order.patient_id }))
    setshowAddCardForm(false)
  }

  const handlePaymentMethodDelete = (id) => {
    Alert.deleteConfirm("Are you sure? You want to permanently delete this payment method .",
      (response) => {
        if (response) {
          dispatch(patientPaymentMethodDelete({ patient_id: order.patient_id, payment_method_id: id }))
        }
      })
  }

  return (
    <>
      {initializing ?
        <div className="spinner-border my-4 ml-9 text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div> :
        <>
          <div className='ml-8'>
            {showAddCardForm || !payment_methods?.length ?
              <AddPaymentMethodForm onSubmit={handlePaymentMethodSubmit} setshowAddCardForm={setshowAddCardForm} saving={saving} paymentMethodsCount={payment_methods?.length} initialValues={{ ...paymentMethodFormInitials }} /> :
              <>
                <PaymentMethodListing payment_methods={payment_methods} handlePaymentMethodDelete={handlePaymentMethodDelete} />
                <button className='btn btn-primary my-4' onClick={() => setshowAddCardForm(true)}>Add Card</button>
              </>
            }
          </div>
        </>
      }
    </>
  )
}

const mapStateToProps = (state) => {
  const { order, patientPaymentMethod } = state

  return {
    order: order.order,
    patientPaymentMethod,
    patient_attributes: order.order.patient_attributes,
  }
}
export default connect(mapStateToProps)(OnlinePaymentWrapper)
