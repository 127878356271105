import React from "react";
import { Route, Switch } from 'react-router-dom';
import asyncComponent from '../../../itrust_common/components/AsyncComponent';

const PhoneSettingsWrap = asyncComponent(() =>
  import('../../../containers/authorised/addOns/voip/voipSettings/PhoneSettingsWrap')
)

const TableDataWrapper = asyncComponent(() =>
  import('../../../containers/authorised/addOns/voip/voipReport/tableData/TableDataWrapper')
)

const GraphWrapper = asyncComponent(() =>
  import('../../../containers/authorised/addOns/voip/voipReport/graphs/GraphWrapper')
)

const CallLogsWrapper = asyncComponent(() =>
  import('../../../containers/authorised/addOns/voip/callLogs/CallLogsWrapper')
)

const VoipRoutes = (
  <Switch>
    <Route exact path='/add-ons/voip/dashboard' component={GraphWrapper} />
    <Route exact path='/add-ons/voip/tabular-report' component={TableDataWrapper} />
    <Route exact path='/add-ons/voip/settings' component={PhoneSettingsWrap} />
    <Route exact path='/add-ons/voip/call-logs' component={CallLogsWrapper} />
  </Switch>
)

export default VoipRoutes;