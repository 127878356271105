import claimFormReducer from './claimFormReducer';
import orderPaymentReducer from './orderPaymentReducer';
import orderReducer from './orderReducer';
import eyeglassLabOrderReducer from './eyeglassLabOrderReducer';
import trialOrderReducer from './trialOrderReducer';
import revenueOrderReducer from './revenueOrderReducer';

const orderReducers ={
  claimForm: claimFormReducer,
  orderPayment: orderPaymentReducer,
  eyeglassLabOrder: eyeglassLabOrderReducer,
  trialOrder: trialOrderReducer,
  revenueOrder: revenueOrderReducer,
  order: orderReducer,
};

export default orderReducers;