import Toastr from "../../../itrust_common/components/Toastr"
const parent = 'STORE_SETTING'

const DEFAULT_STATE = {
  available_numbers: [],
  store_setting: {},
  saving: false,
  loading: false,
}
let changes = null;
export default function messageTrackingReducer(state = DEFAULT_STATE, action) {
  const response = action.payload && (action.payload.data || action.payload.response)
  switch (action.type) {
    case `${parent}_UPDATE_PENDING`:
      return { ...state, saving: true };
    case `${parent}_LOAD_PENDING`:
    case `${parent}_DEVICES_LOAD_PENDING`:
    case `${parent}_DEVICE_UPDATE_PENDING`:
      return { ...state, loading: true };

    case `${parent}_UPDATE_REJECTED`:
    case `${parent}_DEVICES_LOAD_REJECTED`:
    case `${parent}_DEVICE_LOAD_REJECTED`:
    case `${parent}_DEVICE_UPDATE_REJECTED`:
      Toastr.handleShow(response.data.success, response.data.error, response.status_code)
      changes = {
        saving: false,
      }
      return { ...state, ...changes };

    case `${parent}_LOAD_FULFILLED`:
      changes = {
        ...response,
        saving: false,
        loading: false,
      };
      return { ...state, ...changes };

    case `${parent}_DEVICES_LOAD_FULFILLED`:
      changes = {
        store_setting: { device_integrations: response.device_integrations },
        saving: false,
        loading: false,
      };
      return { ...state, ...changes };

    case `${parent}_DEVICE_LOAD_FULFILLED`:
      changes = {
        store_setting: { ...state.store_setting, device_integration: response.device_integration },
        saving: false,
        loading: false,
      };
      return { ...state, ...changes };

    case `${parent}_DEVICE_UPDATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      changes = {
        store_setting: { ...state.store_setting, device_integration: response.device_integration },
        saving: false,
        loading: false,
      };
      return { ...state, ...changes };

    case `${parent}_UPDATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      changes = {
        ...response,
        saving: false,
      };
      return { ...state, ...changes };

    default:
      return state;
  }
}
