import React, { useState, useEffect } from 'react';
import { useRef } from 'react';
import _ from 'lodash';
import { isDesktopScreen } from '../HelperFunctions';

export default function InputDropdown(props) {
  const { input, disabled, placeholder, wrapperClasses, className, label, readOnly, nextFocusRef, data, textField, valueField, meta: { touched, error, active }, dropdownClasses, show, additionalValues, leftPosition, onChangeHook } = props;
  const [isActive, setIsActive] = useState(false);
  const [isNegativeVal, setIsNegativeVal] = useState(false)
  const myRef = useRef(null);
  const isDesktopScreenView = isDesktopScreen()

  function formatDataToDisplay(data) {
    let negatives = [], positives = [], nonNumeric = [];
    data.forEach(item => {
      const value = parseFloat(item.key);
      if (isNaN(value)) {
        nonNumeric.push(item);
      } else if (value < 0) {
        negatives.push(item);
      } else {
        positives.push(item);
      }
    });
    negatives.sort((a, b) => parseFloat(b.key) - parseFloat(a.key));
    positives.sort((a, b) => parseFloat(a.key) - parseFloat(b.key));
    let finalValue = []
    if (additionalValues) {
      finalValue = [[...additionalValues?.start, ...negatives], [...nonNumeric, ...positives, ...additionalValues?.last]].filter(arr => arr.length);
    } else {
      const originalArray = [...nonNumeric, ...positives];
      if (!negatives.length) {
        // Split the array into 3 chunks if it has more than 60 elements
        if (originalArray.length > 60) {
          const chunkAbc = isDesktopScreenView ? 2 : 2
          const chunkSize = Math.ceil(originalArray.length / chunkAbc);
          finalValue = _.chunk(originalArray, chunkSize);
        } else {
          finalValue = [originalArray];
        }
      } else {
        finalValue = [negatives, originalArray].filter(arr => arr.length);
      }
    }
    return finalValue;
  }

  const dataArr = formatDataToDisplay(data)

  useEffect(() => {
    setIsActive(active)
  }, [active])

  useEffect(() => {
    if (isActive) {
      document.addEventListener('click', handleOutsideClick);
    } else {
      document.removeEventListener('click', handleOutsideClick);
    }
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isActive]);

  const handleOutsideClick = (e) => {
    if (myRef.current && !myRef.current.contains(e.target)) {
      setIsActive(false);
    }
  };

  const handleFocus = () => {
    setIsActive(true);
  };

  const handleBlur = (e) => {
    if (!myRef.current.contains(e.relatedTarget)) {
      setIsActive(false);
    }
  };

  const handleValueSelect = (value) => {
    input.onChange(value);
    onChangeHook && onChangeHook(value)
    setIsActive(false);
    nextFocusRef?.ref.current.handleFocus()
  };

  const handleOnChange = (e) => {
    onChangeHook && onChangeHook(e.target.value)
    input.onChange(e.target.value);
  }

  return (
    <div className={`${disabled && 'disabled'} ${wrapperClasses} position-relative d-flex flex-column`} ref={myRef}>
      {label && <label>{label}</label>}
      <input
        {...input}
        value={input.value}
        autoComplete="off"
        placeholder={placeholder}
        readOnly={readOnly}
        disabled={disabled}
        onChange={handleOnChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className={`${className} ${touched && error && 'is-invalid'} form-control pl-4`}
      />
      {isActive &&
        <>
          <div className={`${dropdownClasses} width-1-half height-1-half border border-bottom-0 border-right-0 rotate-45 position-absolute z-index-3 bg-white ${label ? 'input-dropdown' : 'mt-9'}`}></div>
          <RenderDropdown
            handleValueSelect={handleValueSelect}
            dataArr={dataArr}
            valueField={valueField}
            textField={textField}
            inputValue={input.value}
            error={error}
            touched={touched}
            leftPosition={leftPosition}
            inputName={input.name}
            isNegativeVal={isNegativeVal}
            isDesktopScreenView={isDesktopScreenView}
          />
        </>
      }
    </div>
  );
}

const RenderDropdown = (props) => {
  const { handleValueSelect, dataArr, textField, valueField, inputValue, error, touched, leftPosition, inputName, isNegativeVal, isDesktopScreenView } = props

  const getLeftAlignVal = () => {
    const [mainField, subField, lasField] = inputName.split('[');
    const isLeftAlignedField = ['subjective_attributes', 'cycloplegic_attributes', 'current_glasses_attributes'].includes(mainField);
    if (isLeftAlignedField) {
      const lasFieldSuffix = lasField?.split('_')?.[1];
      const subFieldSuffix = subField?.split('_')?.[1];
      if (mainField === 'current_glasses_attributes' && lasFieldSuffix !== "add]") {
        if (lasFieldSuffix === 'axis]') {
          return '-72rem';
        }
        return '-58rem';
      }
      if ((['subjective_attributes', 'cycloplegic_attributes'].includes(mainField) && subFieldSuffix !== "add]") || subFieldSuffix === 'axis]') {
        if (subFieldSuffix === 'axis]') {
          return '-71.5rem';
        }
        return '-58.5rem';
      }
    }
    return leftPosition ?? (subField.includes('axis') ? '-12.8rem' : '-0.8rem');
  };

  const getLeftAlignValTabView = () => {
    const isKetromateryVerticalVal = inputName.search('vertical')
    if (isKetromateryVerticalVal && isKetromateryVerticalVal !== -1) {
      return '-37rem'
    }
    return '-0.8rem'
  }

  const getDesktopCSS = (arrLength) => {
    if (isDesktopScreenView) {
      if (arrLength > 40) {
        return { width: '54.7rem' }
      }
      else {
        return { width: '30rem' }
      }
    }
    else {
      return {}
    }
  }

  return (
    <div className="dropdown-menu show position-absolute border d-flex z-index-2 p-3 overflow-auto" style={{ left: isDesktopScreenView ? getLeftAlignVal() : getLeftAlignValTabView() }}>
      <div className='d-flex flex-column'>
        {touched && error && <div className='text-danger p-2'>* {error}</div>}
        <div className='d-flex flex-row overflow-auto'>
          {dataArr.map((arr, arrIdx) => {
            return <span className={`p-2 my-2 overflow-auto ${!isDesktopScreenView && 'width-22'} ${arrIdx === 1 ? 'bg-light mx-4' : 'bg-primary-light'}`} style={{ ...getDesktopCSS(arr.length) }}>
              {arr.map((item, index) => <button tabindex="-1" className={`border border-1 input-dropdown-style pb-6 width-4 height-1-half ${arrIdx !== 0 && 'bg-primary-light'} ${item[valueField] === inputValue ? 'bg-primary text-white' : `${arrIdx === 1 ? 'bg-white' : 'bg-light'}`}`} key={`${item[valueField]}-${index}`} onClick={() => handleValueSelect(item[valueField])}>{(item[textField] == ' ' || !item[textField]) ? <span className='invisible'>hide</span> : item[textField]}</button>)}
            </span>
          })}
        </div>
      </div>
    </div>
  )
};