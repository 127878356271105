import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import Alert from '../../../../../itrust_common/components/Alert'
import { initiateDevicePayment } from '../../../../../redux/actions/order/orderPaymentActions'
import DeviceLogsListing from './DeviceLogsListing'
import { itrustPayPaymentLogsLoad } from '../../../../../redux/actions/addOns/itrustPay/itrustPayPaymentLogsActions'
import Moment from 'moment';
import { dateBeforeDays, unixTimeFormat } from '../../../../../itrust_common/components/HelperFunctions'

function DevicePayment(props) {
  const { orderId, storeDeviceId, amount, orderBalanceDue, yesterdayPaymentLogs, orderStaffId } = props
  const dispatch = useDispatch()

  useEffect(() => {
    handlePaymentLogsLoad()
  }, [])

  const handlePaymentLogsLoad = () => {
    let lastFourteenDays = new Date(unixTimeFormat(dateBeforeDays(14)));
    lastFourteenDays = lastFourteenDays.toLocaleDateString('en-US', { weekday: 'short', day: '2-digit', month: 'short', year: 'numeric' });
    dispatch(itrustPayPaymentLogsLoad({ sort: 'created_at', direction: 'desc', filter: { last_one_day_logs: lastFourteenDays } }))
  }
  const handleInitiateDevicePayment = () => {
    if (storeDeviceId) {
      if (amount && orderBalanceDue >= amount) {
        dispatch(initiateDevicePayment({ requested_amount: amount, record_id: orderId, status: 'initiated', terminal_id: storeDeviceId, mode_of_payment: 'device', payment_type: 'sale', payment_date: Moment(new Date()).format("YYYY/MM/DD"), store_id: localStorage.getItem('StoreID'), staff_id: orderStaffId }))
      }
      else {
        Alert.warning("Amount can't be nil or greater than Balance due.")
      }
    }
    else {
      Alert.warning(<div>There is no terminal set up for your location. Please contact <strong>support@itrust.io</strong> to have it set up.</div>)
    }
  }

  return (
    <div className='col-12 text-center'>
      <div className='pb-4'>
        <button type='button' className='btn btn-primary my-5 px-8' onClick={handleInitiateDevicePayment}>Initiate Terminal Payment</button>
      </div>
      <DeviceLogsListing yesterdayPaymentLogs={yesterdayPaymentLogs} dispatch={dispatch} orderId={orderId} amount={amount} />
    </div>
  )
}

const mapStateToProps = (state) => {
  const { itrustPayPaymentLog } = state
  return {
    yesterdayPaymentLogs: itrustPayPaymentLog.yesterday_payment_logs
  }
}

export default connect(mapStateToProps)(DevicePayment)