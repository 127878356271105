import React from 'react';
import GlassView from './GlassView'

let CurrentGlass = props => {
  const { fieldsValues, template } = props;
  return (
    <div className="row">
      {fieldsValues.map((eyeGlass, index) =>
        <div className={`col-12 py-4`}>
          <div><span>Eyeglass #{index + 1}</span></div>
          <GlassView
            fieldsValues={eyeGlass}
            template={template.eye_glasses[0]}
          />
        </div>
      )}
    </div>
  )
}


export default CurrentGlass;