import Toastr from "../../../itrust_common/components/Toastr"
const DEFAULT_STATE = {
  intraoffice_notes: [],
  intraoffice_note: {},
  meta: { pagination: {} },
  editing: false,
  saving: false,
}
let changes = null;
export default function intraofficeNoteReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let intraofficeNotes = [];
  let intraofficeNoteData = [];
  let updatedRecord = {};
  switch(action.type){
    case 'INTRAOFFICE_NOTES_LOAD_PENDING':
    case 'INTRAOFFICE_NOTE_LOAD_PENDING':
      return {...state, saving: true};

    case 'INTRAOFFICE_NOTE_CREATE_PENDING':
    case 'INTRAOFFICE_NOTE_UPDATE_PENDING':
      return {...state, saving: true};

    case 'INTRAOFFICE_NOTES_LOAD_REJECTED':
    case 'INTRAOFFICE_NOTE_LOAD_REJECTED':
    case 'INTRAOFFICE_NOTE_CREATE_REJECTED':
    case 'INTRAOFFICE_NOTE_UPDATE_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case 'INTRAOFFICE_NOTES_LOAD_FULFILLED':
      changes = {
        ...response,
        saving: false
      };
      return {...state, ...changes};
    
    case 'INTRAOFFICE_NOTE_LOAD_FULFILLED':
      changes = {
        intraoffice_note: response.intraoffice_note,
        saving: false,
      }
      return {...state,...changes};
    
    case 'INTRAOFFICE_NOTE_CREATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      intraofficeNotes = [...state.intraoffice_notes]
      let newRecord = response.intraoffice_note
      changes = {
        intraoffice_notes: [newRecord, ...intraofficeNotes],
        editing: false,
        saving: false
      }
      return {...state, ...changes};

    case 'INTRAOFFICE_NOTE_UPDATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      intraofficeNotes = [...state.intraoffice_notes];
      updatedRecord = response.intraoffice_note;
      for (const [index, intraofficeNote] of intraofficeNotes.entries()) {
        if (intraofficeNote.id === updatedRecord.id) {
          intraofficeNoteData = [
            ...intraofficeNotes.slice(0, index),
            updatedRecord,
            ...intraofficeNotes.slice(index + 1)
          ];
        }
      }
      changes = {
        intraoffice_notes: intraofficeNoteData,
        intraoffice_note: {},
        editing: false,
        saving: false
      };
      return { ...state, ...changes };

    case 'INTRAOFFICE_NOTE_DELETE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      intraofficeNotes = [...state.intraoffice_notes];
      for (const [index, intraofficeNote] of intraofficeNotes.entries()) {
        if (intraofficeNote.id === action.payload.id) {
          intraofficeNotes.splice(index, 1);
        }
      }
      return {
        ...state,
        intraoffice_notes: intraofficeNotes,
        error: null
      };

    case 'INTRAOFFICE_NOTE_EDIT':
      changes = {
        editing: {
          id: action.id,
        },
        intraoffice_note: {}
      };
      return { ...state, ...changes };

    case 'INTRAOFFICE_NOTE_EDIT_CANCEL':
      changes = {
        editing: false
      };
      return { ...state, ...changes };

    default:
      return state;
  }
}