import {handleAPI, API_VERSION_ONE_ADMIN} from '../../../utils/apiUtils';
const parent = 'SETTING_'

export function diseasesLoad(filters){
  const url = `/${API_VERSION_ONE_ADMIN}/settings/diseases`;
  const method = 'GET'

  return {
    type: `${parent}DISEASES_LOAD`,
    payload: handleAPI(url, filters, method)
  };
}

export function diseaseLoad(id, filters){
  const url = `/${API_VERSION_ONE_ADMIN}/settings/diseases/${id}`;
  const method = 'GET'

  return {
    type: `${parent}DISEASE_LOAD`,
    payload: handleAPI(url,filters,method)
  };
}

export function diseaseCreate(data, filters){
  const url = `/${API_VERSION_ONE_ADMIN}/settings/diseases`;
  const method= 'POST'
  return {
    type: `${parent}DISEASE_CREATE`,
    payload: handleAPI(url,filters,method, {disease: {...data}})
  }
}

export function diseaseUpdate(data, filters){
  const url = `/${API_VERSION_ONE_ADMIN}/settings/diseases/${data.id}`;
  const method = "PUT"
  return {
    type: `${parent}DISEASE_UPDATE`,
    payload: handleAPI(url,filters,method, {disease: {...data}})
  }
}

export function diseaseDelete(id, filters) {
  const url = `/${API_VERSION_ONE_ADMIN}/settings/diseases/${id}`;
  const method = 'DELETE';

  return {
    type: `${parent}DISEASE_DELETE`,
    payload: new Promise((resolve, reject) => {
      handleAPI(url, filters, method)
        .then(response => {
          resolve({ id: id, ...response });
        })
        .catch(err => {
          reject(err);
        });
    })
  };
}

export function diseaseEdit(id, diseaseType) {
  return {
    type: `${parent}DISEASE_EDIT`,
    id,
    diseaseType
  };
}

export function diseaseEditCancel() {
  return {
    type: `${parent}DISEASE_EDIT_CANCEL`
  };
}
