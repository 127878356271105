import React, {Component} from 'react';
import { connect } from 'react-redux';
var _this;
class Toastr extends Component{
  constructor(props){
    super(props)
    this.state={
      visible: 'hide'
    }
    _this = this
  }

  static handleShow = (isSuccess, message, code) => {
    const { user } = _this.props
    _this.setState({isSuccess: isSuccess, visible: 'show', message: message})
    setTimeout( function(){
      _this.setState({visible: 'hide'})
    }, 3500)
    if ((isSuccess === false && code == 403 && message == "Signature has expired") || (isSuccess === false && code === 403 && message === "Something went wrong. Please sign in again.")) {
      // if JWT token expired > reload the screen
      window.location.reload()
    }
    if (isSuccess === false && code == 403 && message == "Store Access Denied") {
      localStorage.setItem('StoreID', user.accessible_stores[0].store_id)
    }
  }

  render(){
    const { userType } = this.props
    return(
      // <div aria-live="polite" aria-atomic="true" className="toast-conatiner">
      //   <div className="toast-position">
      //     <div className={`toast ${this.state.visible}`} role="alert" aria-live="assertive" aria-atomic="true">
      //       <div className="toast-header">
      //         <strong className="mr-auto">Bootstrap</strong>
      //         <button type="button" className="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
      //           <span aria-hidden="true">&times;</span>
      //         </button>
      //       </div>
      //       <div className="toast-body">
      //         <span className={this.state.isSuccess ? 'text-success' : 'text-danger'}>{this.state.message}</span>
      //       </div>
      //     </div>
      //   </div>
      // </div>
    
      <div className={` ${this.state.visible === 'hide' && 'd-none'} w-100 ${userType === 'Patient' ? 'wizard-alert-position' : 'alert-position'} position-fixed text-center toastr-alert`}>
        <div className={`alert w-25 alert-width-mobile ${this.state.isSuccess ? 'alert-success' : 'alert-danger'} alert-dismissible fade ${this.state.visible}`} role="alert">
        <span>{this.state.message}</span>
        <button type="button" className="close" onClick={() => this.setState({visible: 'hide'})}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>

    );
  }
}

const mapStateToProps = state => {
  const { auth: { user } } = state
  return {
    user, 
    userType:user?.type
  }
}
export default connect(mapStateToProps)(Toastr);