import Toastr from "../../../itrust_common/components/Toastr"
const parent = 'ORGANISATION_'
const DEFAULT_STATE = {
  subscriptions: [],
  plans: [],
  subscription: { plan: { nickname: '' } },
  saving: false
}
let changes = null;
export default function subscriptionReducer(state = DEFAULT_STATE, action) {
  const response = action.payload && (action.payload.data || action.payload.response)
  var responseId = action.payload && action.payload.id
  let subscriptions = [];
  let subscriptionData = {};
  let updatedRecord = {};
  switch (action.type) {
    case `${parent}SUBSCRIPTION_LOAD_PENDING`:
    case `${parent}SUBSCRIPTION_COUPON_APPLY_UPDATE_PENDING`:
    case `${parent}SUBSCRIPTION_APPLIED_COUPON_REMOVE_PENDING`:
      return { ...state, saving: true };

    case `${parent}SUBSCRIPTION_LOAD_REJECTED`:
    case `${parent}SUBSCRIPTION_COUPON_APPLY_UPDATE_REJECTED`:
    case `${parent}SUBSCRIPTION_APPLIED_COUPON_REMOVE_REJECTED`:
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = {
        saving: false
      }
      return { ...state, ...changes };

    case `${parent}SUBSCRIPTION_LOAD_FULFILLED`:
      changes = {
        subscription: response.subscription,
        saving: false
      }
      return { ...state, ...changes };

    case `${parent}SUBSCRIPTION_COUPON_APPLY_UPDATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      return { ...state, saving: false };

    case `${parent}SUBSCRIPTION_APPLIED_COUPON_REMOVE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      return { ...state, saving: false };

    case `${parent}SUBSCRIPTION_DELETE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      subscriptions = [...state.subscriptions];
      for (const [index, subscription] of subscriptions.entries()) {
        if (subscription.id === responseId) {
          subscriptions.splice(index, 1);
        }
      }
      return {
        ...state,
        subscriptions: subscriptions,
        saving: false,
        error: null
      };
      
    default:
      return state;
  }
}