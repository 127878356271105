import React, { Component } from 'react';
import { Field } from 'redux-form';
import { Input } from '../../form/'
import { FormTemplateHeader, FormTemplateSectionWrapper } from '../../others/'
import External from './internalExtenalTests/External'
import Internal from './internalExtenalTests/Internal'
import Nct from './internalExtenalTests/Nct';
import Gat from './internalExtenalTests/Gat';

class ExternalInternalForm extends Component {
  render() {
    const { template, isTemplate, exam } = this.props
    return (
      <div className="row">

        <External {...this.props} />

        <div className="col-12">
          <div className="row">
            <Gat {...this.props} />
            <Nct {...this.props} />
            <div className="col-xl-4 col-md-12 mt-7">
              <FormTemplateSectionWrapper isTemplate={isTemplate} fieldValues={template.external_internal_exam.visual_field}>
                <div className="card border-0 shadow-sm px-5">
                  <FormTemplateHeader
                    isTemplate={isTemplate}
                    fieldValues={template.external_internal_exam.visual_field}
                    baseName="external_internal_exam[visual_field]"
                    overviewName="visual_field"
                  />

                  <div className="card-body">
                    <div className="row">
                      <Field name="visual_field" component={Input} className="form-control" wrapperClasses="col-12 form-group" />
                    </div>
                  </div>
                </div>
              </FormTemplateSectionWrapper>
            </div>
          </div>
        </div>

        <Internal  {...this.props} exam={exam} />

      </div>
    );
  }
}

export default ExternalInternalForm;