import React, { useState, useEffect } from 'react';

const CountdownTimer = (props) => {
  const { initialTime, onCountDownFinish, timerClasses } = props
  const [time, setTime] = useState(initialTime);

  useEffect(() => {
    if (time > 0) {
      const timerId = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timerId);
    }
    else {
      onCountDownFinish && onCountDownFinish()
    }
  }, [time]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  };

  return (
    <span className={`${timerClasses}`}>{formatTime(time)}</span>
  );
};

export default CountdownTimer;
