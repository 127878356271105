import Toastr from "../../itrust_common/components/Toastr"
const DEFAULT_STATE = {
  appointments: [],
  appointment: {},
  meta: { pagination: {} },
  handlerData: {formType: 'default', isFullMode: false},
  saving: false,
}
let changes = null;
export default function appointmentReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let appointments = [];
  let appointmentData = [];
  let updatedRecord = {};
  switch(action.type){
    case 'APPOINTMENTS_LOAD_PENDING':
    case 'APPOINTMENT_LOAD_PENDING':
      return {...state, saving: true};

    case 'APPOINTMENT_CREATE_PENDING':
    case 'APPOINTMENT_ONE_YEAR_CREATE_PENDING':
    case 'APPOINTMENT_UPDATE_PENDING':
      return {...state, saving: true};

    case 'APPOINTMENTS_LOAD_REJECTED':
    case 'APPOINTMENT_LOAD_REJECTED':
    case 'APPOINTMENT_CREATE_REJECTED':
    case 'APPOINTMENT_ONE_YEAR_CREATE_REJECTED':
    case 'APPOINTMENT_UPDATE_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case 'APPOINTMENTS_LOAD_FULFILLED':
      changes = {
        ...response,
        saving: false
      };
      return {...state, ...changes};
    
    case 'APPOINTMENT_LOAD_FULFILLED':
      appointments = [...state.appointments];
      updatedRecord = response.appointment;
      for (const [index, appointment] of appointments.entries()) {
        if (appointment.id === updatedRecord.id) {
          appointmentData = [
            ...appointments.slice(0, index),
            updatedRecord,
            ...appointments.slice(index + 1)
          ];
        }
      }
      changes = {
        appointments: appointmentData,
        appointment: response.appointment,
        saving: false
      }
      return {...state,...changes};
    
    case 'APPOINTMENT_CREATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201 )
      appointments = [...state.appointments]
      let newRecord = response.appointment
      changes = {
        appointments: [...appointments, newRecord],
        newAppointment: {},
        editing: false,
        saving: false,
        formType: 'default'
      }
      return {...state, ...changes};
    
    case 'APPOINTMENT_ONE_YEAR_CREATE_FULFILLED':
      Toastr.handleShow(true, "One Year Pre-Booking Appointment Created Successfully", 201)
      return state;
    
    case 'APPOINTMENT_RECONFIRM_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      return state;

    case 'APPOINTMENT_UPDATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      appointments = [...state.appointments];
      updatedRecord = response.appointment;
      for (const [index, appointment] of appointments.entries()) {
        if (appointment.id === updatedRecord.id) {
          appointmentData = [...appointments.slice(0, index), updatedRecord, ...appointments.slice(index + 1)];
        }
      }
      changes = {
        appointments: appointmentData,
        editing: false,
        saving: false
      };
      return { ...state, ...changes };

    case 'APPOINTMENT_DELETE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      appointments = [...state.appointments];
      for (const [index, appointment] of appointments.entries()) {
        if (appointment.id === response.appointment.id) {
          appointments.splice(index, 1);
        }
      }
      return {
        ...state,
        appointments: appointments,
        error: null,
        editing: false
      };

    case 'APPOINTMENT_EDIT':
      changes = {
        editing: {
          id: action.id,
          data: action.data,
          handlerData: {...state.handlerData, isFullMode: false, formType: 'default'}
        },
        appointment: {...action.data}
      };
      return { ...state, ...changes };

    case 'APPOINTMENT_EDIT_CANCEL':
      changes = {
        editing: false,
        handlerData: {formType: 'default', isFullMode: false}
      };
      return { ...state, ...changes };
    
    case 'APPOINTMENT_EDIT_HANDLER':
      changes = {
        handlerData: {...state.handlerData, ...action.data}
      };
      return { ...state, ...changes };

    case 'APPOINTMENT_RESET':
      return { ...state, ...DEFAULT_STATE };
      
    default:
      return state;
  }
}