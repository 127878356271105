import React, { useEffect, useState } from 'react';

//Tests import
import Correction from './Correction';
import PupillaryDistance from './PupillaryDistance';
import CurrentGlass from './CurrentGlass';
import GlassView from './GlassView';
import ContactLens from './ContactLens';
import Keratometry from './Keratometry';

let RefractionContactLens = props => {
  const { isPdfView, fieldsValues: { visual_acuity_without_correction, visual_acuity_with_correction, current_glasses_attributes, pupillary_distance, objective_attributes, subjective_attributes, cycloplegic_attributes, binocular_balance_attributes, keratometry_attributes, current_lenses_attributes }, template, isReferralPdf} = props;
  let [lensShowLabel, lensSetstate] = useState()
  let [glassShowLabel, glassSetstate] = useState()
  
  useEffect(() => {
    lensShowLabel = current_lenses_attributes.some((test)=> test.is_marked_complete === true)
    glassShowLabel = current_glasses_attributes.some((test)=> test.is_marked_complete === true)
    lensSetstate(lensShowLabel)
    glassSetstate(glassShowLabel)
  }, [])
  
  return (
    <div className={`row ${isPdfView && 'referral-print-table'}`}>
      {/* Visual Acuity */}
      <div className="col-12">
        <div className="border-bottom">
          <h6 className="pb-3 pt-6">{template.visual_acuity.custom_label}</h6>
        </div>
      </div>
      { visual_acuity_without_correction && visual_acuity_without_correction.is_marked_complete && 
        <div className={`${ isPdfView ? 'col-3' : 'col-3'}`}>
          <h6 className="py-4">{template.visual_acuity.fields.without_correction.custom_label}</h6>
          <Correction fieldsValues={visual_acuity_without_correction} template={template.visual_acuity.fields.without_correction}/>
        </div>
      }
      { visual_acuity_with_correction && visual_acuity_with_correction.is_marked_complete &&
      <div className={`${ isPdfView ? 'col-3' : 'col-3'}`}>
        <h6 className="py-4">{template.visual_acuity.fields.with_correction.custom_label}</h6>
        <Correction fieldsValues={visual_acuity_with_correction} template={template.visual_acuity.fields.with_correction}/>
      </div>
      }
      { glassShowLabel && <div className={`${ isPdfView ? 'col-4' : 'col-6'}`}>
        <div className="py-4">
          <h6>{template.visual_acuity.fields.current_glass.custom_label}</h6>
        </div>
        <CurrentGlass fieldsValues={current_glasses_attributes} template={template.visual_acuity.fields.current_glass}/>
      </div> }

      {/* Refraction */}
      <div className="col-12">
        <div className="border-bottom">
          <h6 className="pb-3 pt-6">{template.refraction.custom_label}</h6>
        </div>
      </div>
      {pupillary_distance && pupillary_distance.is_marked_complete &&
        <div className={`py-4 ${ isPdfView ? `${isReferralPdf ? 'col-6' : 'col-4'}` : 'col-6'}`}>
          <h6 className="py-4">{template.refraction.fields.pupillary_distance.custom_label}</h6>
          <PupillaryDistance fieldsValues={pupillary_distance} template={template.refraction.fields.pupillary_distance}/>
        </div> }
      {objective_attributes && objective_attributes.is_marked_complete &&
        <div className={`py-4 ${ isPdfView ? `${isReferralPdf ? 'col-6' : 'col-4'}` : 'col-6'}`}>
          <h6 className="py-4">{template.refraction.fields.objective.custom_label}</h6>
          <GlassView fieldsValues={objective_attributes} template={template.refraction.fields.objective}/>
        </div>
      }
      {subjective_attributes && subjective_attributes.is_marked_complete &&
        <div className={`py-4 ${ isPdfView ? `${isReferralPdf ? 'col-6' : 'col-4'}` : 'col-6'}`}>
          <h6 className="py-4">{template.refraction.fields.subjective.custom_label}</h6>
          <GlassView fieldsValues={subjective_attributes} template={template.refraction.fields.subjective}/>
        </div>
      }
      {cycloplegic_attributes && cycloplegic_attributes.is_marked_complete &&
        <div className={`py-4 ${ isPdfView ? `${isReferralPdf ? 'col-6' : 'col-4'}` : 'col-6'}`}>
          <h6 className="py-4">{template.refraction.fields.cycloplegic.custom_label}</h6>
          <GlassView fieldsValues={cycloplegic_attributes} template={template.refraction.fields.cycloplegic}/>
        </div>
      }
      {binocular_balance_attributes && binocular_balance_attributes.is_marked_complete &&
        <div className={`py-4 ${ isPdfView ? `${isReferralPdf ? 'col-6' : 'col-4'}` : 'col-6'}`}>
          <h6 className="py-4">{template.refraction.fields.binocular_balance.custom_label}</h6>
          <GlassView fieldsValues={binocular_balance_attributes} template={template.refraction.fields.binocular_balance}/>
        </div>
      }

      {/* Contact Lens */}
      { (lensShowLabel || (keratometry_attributes && keratometry_attributes.is_marked_complete)) && <div className="col-12">
        <div className="border-bottom">
          <h6 className="pb-3 pt-6">{template.contact_lens.custom_label}</h6>
        </div>
      </div> }
      {keratometry_attributes && keratometry_attributes.is_marked_complete &&
        <div className="col-12">
          <Keratometry name="keratometry_attributes" fieldsValues={keratometry_attributes} template={template.contact_lens.fields.keratometry} />
        </div>
      }
      { lensShowLabel && <div className="col-12 py-4">
        <h6>{template.contact_lens.fields.current_contact_lens.custom_label}</h6>
      </div> }
      <div className="col-12">
        <ContactLens fieldsValues={current_lenses_attributes} template={template.contact_lens.fields.current_contact_lens.tests[0]} />
      </div>
    </div>
  )
}


export default RefractionContactLens;