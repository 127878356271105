import React from 'react';
import DisplayField from '../../../../../itrust_common/components/others/DisplayField'

let Binocular = props => {
  const { fieldsValues, template} = props;
  return (
    <div className="row">
      
      <DisplayField
        isVisible={template.fields.cover_test.fields.distance.visible}
        label={`${template.fields.cover_test.custom_label+' ('+template.fields.cover_test.fields.distance.custom_label+')'}:`}
        value={fieldsValues.cover_test && fieldsValues.cover_test.distance}
        className="col-2 py-4"
      />
      <DisplayField
        isVisible={template.fields.cover_test.fields.near.visible}
        label={`${template.fields.cover_test.custom_label+' ('+template.fields.cover_test.fields.near.custom_label+')'}:`}
        value={fieldsValues.cover_test && fieldsValues.cover_test.near}
        className="col-2 py-4"
      />
      <DisplayField
        isVisible={template.fields.cover_test.fields.nra.visible}
        label={`${template.fields.cover_test.fields.nra.custom_label}:`}
        value={fieldsValues.cover_test && fieldsValues.cover_test.nra}
        className="col-2 py-4"
      />
      <DisplayField
        isVisible={template.fields.cover_test.fields.pra.visible}
        label={`${template.fields.cover_test.fields.pra.custom_label}:`}
        value={fieldsValues.cover_test && fieldsValues.cover_test.pra}
        className="col-2 py-4"
      />
      <DisplayField
        isVisible={template.fields.cover_test.fields.npc.visible}
        label={`${template.fields.cover_test.fields.npc.custom_label}:`}
        value={fieldsValues.cover_test && fieldsValues.cover_test.npc}
        className="col-2 py-4"
      />
      <DisplayField
        isVisible={template.fields.cover_test.fields.fcc.visible}
        label={`${template.fields.cover_test.fields.fcc.custom_label}:`}
        value={fieldsValues.cover_test && fieldsValues.cover_test.fcc}
        className="col-2 py-4"
      />
      <DisplayField
        isVisible={template.fields.cover_test.fields.accomodation_facility.visible}
        label={`${template.fields.cover_test.fields.accomodation_facility.custom_label}:`}
        value={fieldsValues.cover_test && fieldsValues.cover_test.accomodation_facility}
        className="col-2 py-4"
      />
      <DisplayField
        isVisible={template.fields.cover_test.fields.dem.visible}
        label={`${template.fields.cover_test.fields.dem.custom_label}:`}
        value={fieldsValues.cover_test && fieldsValues.cover_test.dem}
        className="col-2 py-4"
      />
      <DisplayField
        isVisible={template.fields.cover_test.fields.amplitude_of_accomodation.visible}
        label={`${template.fields.cover_test.fields.amplitude_of_accomodation.custom_label}:`}
        value={fieldsValues.cover_test && fieldsValues.cover_test.amplitude_of_accomodation}
        className="col-2 py-4"
      />
      <DisplayField
        isVisible={template.fields.phorias.fields.horizontal_distance.visible}
        label={`${template.fields.phorias.custom_label+' ('+template.fields.phorias.fields.horizontal_distance.custom_label+')'}:`}
        value={fieldsValues.phorias && fieldsValues.phorias.horizontal_distance}
        className="col-2 py-4"
      />
      <DisplayField
        isVisible={template.fields.phorias.fields.vergences_distance.visible}
        label={`${template.fields.phorias.custom_label+' ('+template.fields.phorias.fields.vergences_distance.custom_label+')'}:`}
        value={fieldsValues.phorias && fieldsValues.phorias.vergences_distance}
        className="col-2 py-4"
      />
      <DisplayField
        isVisible={template.fields.phorias.fields.vertical_distance.visible}
        label={`${template.fields.phorias.custom_label+' ('+template.fields.phorias.fields.vertical_distance.custom_label+')'}:`}
        value={fieldsValues.phorias && fieldsValues.phorias.vertical_distance}
        className="col-2 py-4"
      />
      <DisplayField
        isVisible={template.fields.phorias.fields.horizontal_near.visible}
        label={`${template.fields.phorias.custom_label+' ('+template.fields.phorias.fields.horizontal_near.custom_label+')'}:`}
        value={fieldsValues.phorias && fieldsValues.phorias.horizontal_near}
        className="col-2 py-4"
      />
      <DisplayField
        isVisible={template.fields.phorias.fields.vergences_near.visible}
        label={`${template.fields.phorias.custom_label+' ('+template.fields.phorias.fields.vergences_near.custom_label+')'}:`}
        value={fieldsValues.phorias && fieldsValues.phorias.vergences_near}
        className="col-2 py-4"
      />
      <DisplayField
        isVisible={template.fields.phorias.fields.vertical_near.visible}
        label={`${template.fields.phorias.custom_label+' ('+template.fields.phorias.fields.vertical_near.custom_label+')'}:`}
        value={fieldsValues.phorias && fieldsValues.phorias.vertical_near}
        className="col-2 py-4"
      />
      { fieldsValues.note && <DisplayField
        isVisible={template.fields.notes.visible}
        label={`${template.fields.notes.custom_label}:`}
        value={fieldsValues.note}
        className="col-2 py-4"
      /> }

    </div>
  )
}


export default Binocular;