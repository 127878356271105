import React from 'react'
import ExamClaimStep1 from './ExamClaimStep1'
import ExamClaimStep2 from './ExamClaimStep2'
import { FormSection } from 'redux-form'

export default function ExamClaimForm(props) {
  const { activeTab, claimFormTemplate, rpaClaimForm } = props

  return (
    <div className='row'>
      <div className='col-10'>
        <div className='bg-white p-6 border rounded'>
          {activeTab === 2 && <FormSection name="exam">
            <ExamClaimStep1 claimFormTemplate={claimFormTemplate} rpaClaimForm={rpaClaimForm} />
          </FormSection>}
          {activeTab === 3 && <FormSection name='impression'>
            <ExamClaimStep2 claimFormTemplate={claimFormTemplate} rpaClaimForm={rpaClaimForm} />
          </FormSection>}
        </div>
      </div>
      <div className='col-2'>
        <div className='bg-white border rounded-lg'>
          <div className='d-flex flex-column align-items-center'>
            <span className='py-5 px-6 w-100 text-primary'>Exam</span>
          </div>
        </div>
      </div>
    </div>
  )
}
