import React, { Component } from 'react';
import { change } from 'redux-form';
import { connect } from 'react-redux';
import CanvasDrawing from '../../../others/CanvasDrawing';
import { Input, Textarea, InputWithIcon, RadioGroup, CheckboxGroup, MultiSelect } from '../../../form/'
import internalExam from '../../../../assets/static/images/internal-exam.png';
import hunteyecareInternalExam from '../../../../assets/static/images/hunteyecare-internal-exam.png';
import { FormTemplateHeader, FormTemplateSectionWrapper, FormTemplateField } from '../../../others/'
import moment from "moment";

// const dropUsed = [{label: 'Tropicomide', value: 'tropicomide'},{label: 'Phenylephrine', value: 'phenylephrine'},{label: 'Ophthalmoscopy', value: 'ophthalmoscopy'},{label: 'Cyclo', value: 'cyclo'}]

class InternalForm extends Component {

  getDropUsedOptions() {
    const { template: { external_internal_exam: { internal_exam } } } = this.props
    var options = [];
    ['drop1', 'drop2', 'drop3', 'drop4'].map(drop => {
      if (internal_exam['fields'][drop] && internal_exam['fields'][drop].visible && internal_exam['fields'][drop]['default_value'] !== "")
        options.push({ label: internal_exam['fields'][drop]['default_value'], value: internal_exam['fields'][drop]['default_value'] })
    })
    return options
  }

  getTestUsedOptions() {
    const { template: { external_internal_exam: { internal_exam } } } = this.props
    var options = [];
    ['test1', 'test2'].map(test => {
      if (internal_exam['fields'][test] && internal_exam['fields'][test].visible && internal_exam['fields'][test]['default_value'] !== "")
        options.push({ label: internal_exam['fields'][test]['default_value'], value: internal_exam['fields'][test]['default_value'] })
    })
    return options
  }


  handleDropUsedShow(drops_used, value) {
    if (value) {
      // value is an array | need to convert in obj | from [0: Cyclo 1.0%]  to {Cyclo 1.0% : true}
      const objVal = {};
      for (let i = 0; i < value.length; i++) {
        const item = value[i];
        objVal[item] = true;
      }
      this.props.dispatch(change('examExternalInternalForm', 'import_dialation_test', objVal))
    }
    else {
      // drops_used is obj | need array for listing options | textField and ValueField
      return Object.entries(drops_used).map(([label, value]) => ({ label }))
    }
  }

  render() {
    const { template, isTemplate, exam, pprops = null } = this.props
    const isMarkCompleted = pprops && pprops.initialValues.internal_attributes && pprops.initialValues.internal_attributes.is_marked_complete
    const internalExamTemplate = template.external_internal_exam.internal_exam.fields

    return (
      <div className="col-12 mt-7">
        <FormTemplateSectionWrapper isTemplate={isTemplate} fieldValues={template.external_internal_exam.internal_exam}>
          <div className="card border-0 shadow-sm px-5">
            <FormTemplateHeader
              isTemplate={isTemplate}
              name="internal_attributes[is_marked_complete]"
              isToggle={isMarkCompleted}
              fieldValues={template.external_internal_exam.internal_exam}
              baseName="external_internal_exam[internal_exam]"
              overviewName="internal_exam"
              pprops={pprops}
            />

            <div className={`card-body ${isMarkCompleted || isTemplate ? '' : 'd-none'}`}>
              <div className="row">
                <div className="col-8">
                  <div className="offset-2 col-8">
                    {/* <span>Drawing</span> */}
                    {
                      (isTemplate || internalExamTemplate.drawing.visible === "true") &&
                      <div className="row mb-7">
                        <div className="col-12">
                          <FormTemplateField
                            isTemplate={isTemplate}
                            name="external_attributes[drawing]"
                            baseName="external_internal_exam[internal_exam][fields][drawing]"
                            fieldValues={template.external_internal_exam.internal_exam.fields.drawing}
                            component={Input}
                            className="d-none"
                          />
                          <div className={`${!internalExamTemplate.drawing.visible && "opacity-5"}`}>
                            <CanvasDrawing
                              isTemplate={isTemplate}
                              wrapperClasses="bg-light rounded text-center p-5"
                              className="mx-auto"
                              canvasRef="internalCanvasRef"
                              canvasWidth={495}
                              canvasHeight={150}
                              isMarkCompleted={isTemplate ? true : isMarkCompleted}
                              imgSrc={window.location.host.split('.')[0] === 'hunteyecare' ? hunteyecareInternalExam : internalExam}
                              defaultData={exam.internal_attributes && exam.internal_attributes.draw_data}
                              onChangeHook={(val) => { pprops.dispatch(change('examExternalInternalForm', 'internal_attributes[draw_data]', val)) }}
                            />
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="row mb-7">
                        <FormTemplateField
                          isTemplate={isTemplate}
                          name="internal_attributes[od_cd]"
                          baseName="external_internal_exam[internal_exam][fields][od_cd]"
                          fieldValues={template.external_internal_exam.internal_exam.fields.od_cd}
                          component={Input}
                          isOpen={isMarkCompleted}
                          showCopy={{ destination: 'internal_attributes[os_cd]', title: 'OS' }}
                          className="form-control"
                          wrapperClasses="form-group label-icons col-12"
                        />
                        <FormTemplateField
                          isTemplate={isTemplate}
                          name="internal_attributes[os_cd]"
                          baseName="external_internal_exam[internal_exam][fields][os_cd]"
                          fieldValues={template.external_internal_exam.internal_exam.fields.os_cd}
                          component={Input}
                          isOpen={isMarkCompleted}
                          showCopy={{ destination: 'internal_attributes[od_cd]', title: 'OD' }}
                          className="form-control"
                          wrapperClasses="form-group col-12 label-icons"
                        />
                      </div>
                      <div className="row mb-7">
                        <FormTemplateField
                          isTemplate={isTemplate}
                          name="internal_attributes[macula][od]"
                          baseName="external_internal_exam[internal_exam][fields][od_macula]"
                          fieldValues={template.external_internal_exam.internal_exam.fields.od_macula}
                          component={InputWithIcon}
                          isOpen={isMarkCompleted}
                          showCopy={{ destination: 'internal_attributes[macula][os]', title: 'OS' }}
                          title="OD"
                          className="form-control"
                          wrapperClasses="form-group label-icons col-12"
                        />
                        <FormTemplateField
                          isTemplate={isTemplate}
                          name="internal_attributes[macula][os]"
                          baseName="external_internal_exam[internal_exam][fields][os_macula]"
                          fieldValues={template.external_internal_exam.internal_exam.fields.os_macula}
                          component={InputWithIcon}
                          isOpen={isMarkCompleted}
                          showCopy={{ destination: 'internal_attributes[macula][od]', title: 'OD' }}
                          title="OS"
                          className="form-control"
                          wrapperClasses="form-group col-12 label-icons"
                        />
                        <FormTemplateField
                          isTemplate={isTemplate}
                          name="internal_attributes[macula][ou]"
                          baseName="external_internal_exam[internal_exam][fields][ou_macula]"
                          fieldValues={template.external_internal_exam.internal_exam.fields.ou_macula}
                          component={InputWithIcon}
                          isOpen={isMarkCompleted}
                          title="OU"
                          className="form-control"
                          wrapperClasses="form-group col-12 label-icons"
                        />
                      </div>
                      <div className="row mb-7">
                        <FormTemplateField
                          isTemplate={isTemplate}
                          name="internal_attributes[vessels][od]"
                          baseName="external_internal_exam[internal_exam][fields][od_vessels]"
                          fieldValues={template.external_internal_exam.internal_exam.fields.od_vessels}
                          component={InputWithIcon}
                          isOpen={isMarkCompleted}
                          showCopy={{ destination: 'internal_attributes[vessels][os]', title: 'OS' }}
                          title="OD"
                          className="form-control"
                          wrapperClasses="form-group col-12 label-icons"
                        />
                        <FormTemplateField
                          isTemplate={isTemplate}
                          name="internal_attributes[vessels][os]"
                          baseName="external_internal_exam[internal_exam][fields][os_vessels]"
                          fieldValues={template.external_internal_exam.internal_exam.fields.os_vessels}
                          component={InputWithIcon}
                          isOpen={isMarkCompleted}
                          showCopy={{ destination: 'internal_attributes[vessels][od]', title: 'OD' }}
                          title="OS"
                          className="form-control"
                          wrapperClasses="form-group col-12 label-icons"
                        />
                        <FormTemplateField
                          isTemplate={isTemplate}
                          name="internal_attributes[vessels][ou]"
                          baseName="external_internal_exam[internal_exam][fields][ou_vessels]"
                          fieldValues={template.external_internal_exam.internal_exam.fields.ou_vessels}
                          component={InputWithIcon}
                          isOpen={isMarkCompleted}
                          title="OU"
                          className="form-control"
                          wrapperClasses="form-group col-12 label-icons"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="row mb-7">
                        <FormTemplateField
                          isTemplate={isTemplate}
                          name="internal_attributes[post_pole][od]"
                          baseName="external_internal_exam[internal_exam][fields][od_post_pole]"
                          fieldValues={template.external_internal_exam.internal_exam.fields.od_post_pole}
                          component={InputWithIcon}
                          isOpen={isMarkCompleted}
                          showCopy={{ destination: 'internal_attributes[post_pole][os]', title: 'OS' }}
                          title="OD"
                          className="form-control"
                          wrapperClasses="form-group label-icons col-12"
                        />
                        <FormTemplateField
                          isTemplate={isTemplate}
                          name="internal_attributes[post_pole][os]"
                          baseName="external_internal_exam[internal_exam][fields][os_post_pole]"
                          fieldValues={template.external_internal_exam.internal_exam.fields.os_post_pole}
                          component={InputWithIcon}
                          isOpen={isMarkCompleted}
                          showCopy={{ destination: 'internal_attributes[post_pole][od]', title: 'OD' }}
                          title="OS"
                          className="form-control"
                          wrapperClasses="form-group col-12 label-icons"
                        />
                        <FormTemplateField
                          isTemplate={isTemplate}
                          name="internal_attributes[post_pole][ou]"
                          baseName="external_internal_exam[internal_exam][fields][ou_post_pole]"
                          fieldValues={template.external_internal_exam.internal_exam.fields.ou_post_pole}
                          component={InputWithIcon}
                          isOpen={isMarkCompleted}
                          title="OU"
                          className="form-control"
                          wrapperClasses="form-group col-12 label-icons"
                        />
                      </div>
                      <div className="row mb-7">
                        <FormTemplateField
                          isTemplate={isTemplate}
                          name="internal_attributes[vitreous][od]"
                          baseName="external_internal_exam[internal_exam][fields][od_vitreous]"
                          fieldValues={template.external_internal_exam.internal_exam.fields.od_vitreous}
                          component={InputWithIcon}
                          isOpen={isMarkCompleted}
                          showCopy={{ destination: 'internal_attributes[vitreous][os]', title: 'OS' }}
                          title="OD"
                          className="form-control"
                          wrapperClasses="form-group col-12 label-icons"
                        />
                        <FormTemplateField
                          isTemplate={isTemplate}
                          name="internal_attributes[vitreous][os]"
                          baseName="external_internal_exam[internal_exam][fields][os_vitreous]"
                          fieldValues={template.external_internal_exam.internal_exam.fields.os_vitreous}
                          component={InputWithIcon}
                          isOpen={isMarkCompleted}
                          showCopy={{ destination: 'internal_attributes[vitreous][od]', title: 'OD' }}
                          title="OS"
                          className="form-control"
                          wrapperClasses="form-group col-12 label-icons"
                        />
                        <FormTemplateField
                          isTemplate={isTemplate}
                          name="internal_attributes[vitreous][ou]"
                          baseName="external_internal_exam[internal_exam][fields][ou_vitreous]"
                          fieldValues={template.external_internal_exam.internal_exam.fields.ou_vitreous}
                          component={InputWithIcon}
                          isOpen={isMarkCompleted}
                          title="OU"
                          className="form-control"
                          wrapperClasses="form-group col-12 label-icons"
                        />
                      </div>
                      <div className="row mb-7">
                        <FormTemplateField
                          isTemplate={isTemplate}
                          name="internal_attributes[periphery][od]"
                          baseName="external_internal_exam[internal_exam][fields][od_periphery]"
                          fieldValues={template.external_internal_exam.internal_exam.fields.od_periphery}
                          component={InputWithIcon}
                          isOpen={isMarkCompleted}
                          showCopy={{ destination: 'internal_attributes[periphery][os]', title: 'OS' }}
                          title="OD"
                          className="form-control"
                          wrapperClasses="form-group col-12 label-icons"
                        />
                        <FormTemplateField
                          isTemplate={isTemplate}
                          name="internal_attributes[periphery][os]"
                          baseName="external_internal_exam[internal_exam][fields][os_periphery]"
                          fieldValues={template.external_internal_exam.internal_exam.fields.os_periphery}
                          component={InputWithIcon}
                          isOpen={isMarkCompleted}
                          showCopy={{ destination: 'internal_attributes[periphery][od]', title: 'OD' }}
                          title="OS"
                          className="form-control"
                          wrapperClasses="form-group col-12 label-icons"
                        />
                        <FormTemplateField
                          isTemplate={isTemplate}
                          name="internal_attributes[periphery][ou]"
                          baseName="external_internal_exam[internal_exam][fields][ou_periphery]"
                          fieldValues={template.external_internal_exam.internal_exam.fields.ou_periphery}
                          component={InputWithIcon}
                          isOpen={isMarkCompleted}
                          title="OU"
                          className="form-control"
                          wrapperClasses="form-group col-12 label-icons"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  {
                    (isTemplate || internalExamTemplate.dialation_test.visible === "true") &&
                    <div className={`${!internalExamTemplate.dialation_test.visible && "opacity-5"}`}>
                      <div className="row mb-6">
                        <div className="col-12">
                          <div className="d-flex align-items-end justify-content-between h-100">

                            <RadioGroup name="internal_attributes[is_dialation_required]"
                              label={
                                <FormTemplateField
                                  isTemplate={isTemplate}
                                  baseName="external_internal_exam[internal_exam][fields][dialation_test]"
                                  fieldValues={template.external_internal_exam.internal_exam.fields.dialation_test}
                                  component={Input}
                                  className="d-none"
                                />
                              }
                              options={[{ value: true, label: 'Yes' }, { value: false, label: 'No' }]}
                              className="form-control"
                              wrapperClasses="label-icons form-group"
                              onChangeHook={(val) => !isTemplate && setTimeout(() => pprops.submit(), 500)}
                            />

                            <FormTemplateField
                              isTemplate={isTemplate}
                              name="internal_attributes[dialation_inactive_reason]"
                              baseName="external_internal_exam[internal_exam][fields][reason]"
                              fieldValues={template.external_internal_exam.internal_exam.fields.reason}
                              component={Input}
                              isOpen={isMarkCompleted}
                              className="form-control"
                              wrapperClasses="label-icons ml-5 flex-fill form-group"
                            />
                          </div>
                        </div>
                      </div>
                      {exam.internal_attributes && exam.internal_attributes.is_dialation_required && <div className="row">
                        {!isTemplate ?
                          [<>
                            {exam.internal_attributes?.drops_used &&
                              <MultiSelect
                                name="import_dialation_test"
                                placeholder="Show in Overview"
                                data={this.handleDropUsedShow(exam.internal_attributes?.drops_used)}
                                textField="label"
                                valueField="label"
                                wrapperClasses="col-12 form-group px-0"
                                onSelectHook={(value) => this.handleDropUsedShow(null, value)}
                              />
                            }
                          </>,
                          <CheckboxGroup
                            label="Drop(s) Used"
                            name="internal_attributes[drops_used]"
                            // options={dropUsed}
                            options={this.getDropUsedOptions()}
                            wrapperClasses="col-12 form-group"
                            className="m-2"
                          />,
                          <CheckboxGroup
                            label="Test(s) Used"
                            name="internal_attributes[tests_performed]"
                            options={this.getTestUsedOptions()}
                            wrapperClasses="col-12 form-group"
                            className="m-2"
                          />]
                          :
                          <div className="row mx-0 mb-5">
                            <h6 className="col-12 py-5">Test(s) Used</h6>
                            <FormTemplateField
                              isTemplate={isTemplate}
                              name={`internal_attributes[test1]`}
                              baseName={`external_internal_exam[internal_exam][fields][test1]`}
                              fieldValues={template.external_internal_exam.internal_exam.fields.test1}
                              component={Input}
                              className="form-control"
                              wrapperClasses="col-6 form-group"
                            />
                            <FormTemplateField
                              isTemplate={isTemplate}
                              name={`internal_attributes[test2]`}
                              baseName={`external_internal_exam[internal_exam][fields][test2]`}
                              fieldValues={template.external_internal_exam.internal_exam.fields.test2}
                              component={Input}
                              className="form-control"
                              wrapperClasses="col-6 form-group"
                            />

                            <h6 className="col-12 py-5">Drop(s) Used</h6>
                            <FormTemplateField
                              isTemplate={isTemplate}
                              name={`internal_attributes[drop1]`}
                              baseName={`external_internal_exam[internal_exam][fields][drop1]`}
                              fieldValues={template.external_internal_exam.internal_exam.fields.drop1}
                              component={Input}
                              className="form-control"
                              wrapperClasses="col-6 form-group"
                            />
                            <FormTemplateField
                              isTemplate={isTemplate}
                              name={`internal_attributes[drop2]`}
                              baseName={`external_internal_exam[internal_exam][fields][drop2]`}
                              fieldValues={template.external_internal_exam.internal_exam.fields.drop2}
                              component={Input}
                              className="form-control"
                              wrapperClasses="col-6 form-group"
                            />
                            <FormTemplateField
                              isTemplate={isTemplate}
                              name={`internal_attributes[drop3]`}
                              baseName={`external_internal_exam[internal_exam][fields][drop3]`}
                              fieldValues={template.external_internal_exam.internal_exam.fields.drop3}
                              component={Input}
                              className="form-control"
                              wrapperClasses="col-6 form-group"
                            />
                            <FormTemplateField
                              isTemplate={isTemplate}
                              name={`internal_attributes[drop4]`}
                              baseName={`external_internal_exam[internal_exam][fields][drop4]`}
                              fieldValues={template.external_internal_exam.internal_exam.fields.drop4}
                              component={Input}
                              className="form-control"
                              wrapperClasses="col-6 form-group"
                            />
                          </div>
                        }

                        <div className="col-5 label-icons form-group">
                          <FormTemplateField
                            isTemplate={isTemplate}
                            name="internal_attributes[instilled_at]"
                            baseName="external_internal_exam[internal_exam][fields][instilled_at]"
                            fieldValues={template.external_internal_exam.internal_exam.fields.instilled_at}
                            component={InputWithIcon}
                            title="Time"
                            className="form-control"
                          // wrapperClasses="col-5 label-icons form-group"
                          />
                          <small>Format: hh:mm A</small>
                        </div>

                        {!isTemplate &&
                          <div className="col-7">
                            <button type="button" className="btn btn-primary mt-6"
                              onClick={() => pprops.dispatch(change('examExternalInternalForm', 'internal_attributes[instilled_at]', moment().format("hh:mm A")))}>
                              Time
                            </button>
                          </div>
                        }

                        <FormTemplateField
                          isTemplate={isTemplate}
                          name="internal_attributes[dialation_od]"
                          baseName="external_internal_exam[internal_exam][fields][od]"
                          fieldValues={template.external_internal_exam.internal_exam.fields.od}
                          component={InputWithIcon}
                          isOpen={isMarkCompleted}
                          title="OD"
                          className="form-control"
                          wrapperClasses="col-12 label-icons form-group"
                        />

                        <FormTemplateField
                          isTemplate={isTemplate}
                          name="internal_attributes[dialation_os]"
                          baseName="external_internal_exam[internal_exam][fields][os]"
                          fieldValues={template.external_internal_exam.internal_exam.fields.os}
                          component={InputWithIcon}
                          isOpen={isMarkCompleted}
                          title="OS"
                          className="form-control"
                          wrapperClasses="col-12 label-icons form-group"
                        />

                        <FormTemplateField
                          isTemplate={isTemplate}
                          name="internal_attributes[comments]"
                          baseName="external_internal_exam[internal_exam][fields][comments]"
                          fieldValues={template.external_internal_exam.internal_exam.fields.comments}
                          component={Textarea}
                          isOpen={isMarkCompleted}
                          rows={4}
                          className="form-control"
                          wrapperClasses="col-12 label-icons form-group"
                        />
                      </div>}
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </FormTemplateSectionWrapper>
      </div>
    );
  }
}

export default connect()(InternalForm);
