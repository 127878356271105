import React from 'react';
import { Field, change } from 'redux-form';
import { FormTemplateField } from '../../../others'
import { Textarea,  AutoCompleteSelect, DatePicker } from '../../../form/'
import { findDOMNode } from 'react-dom'
import { handleContactLensFieldsCopy, humanize, isPresentInArray } from '../../../HelperFunctions';
import { contactLensTypeFields } from '../../../Constants';
import { connect } from 'react-redux';

class RenderLens extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOdLensTypeLoading: false,
      isOsLensTypeLoading: false,
      odReset: false,
      osReset: false,
      odLensTypeFields: [],
      osLensTypeFields: [],
    }
  }

  copyTo(source, destination) {
    const { name, pprops, currentContactLens } = this.props
    const lensTypeNode = findDOMNode(this.refs[`${name}[${source}_contact_lens_id]`]).lastChild.firstChild.value
    const sourceContactLensType = currentContactLens[`${source}_contact_lens_attributes`]['lens_type']

    pprops.dispatch(change('examFinalizePrescriptionForm', `${name}[${destination}_contact_lens_id]`, lensTypeNode))
    pprops.dispatch(change('examFinalizePrescriptionForm', `${name}[${destination}_contact_lens_attributes]`, currentContactLens[source + '_contact_lens_attributes']))

    handleContactLensFieldsCopy(this, pprops, sourceContactLensType, name, destination, source, 'examFinalizePrescriptionForm')
    setTimeout(() => pprops.submit(), 500)
    setTimeout(() => this.handleContactLensFieldUpdate(destination), 1500);
  }

  onContactLensSelect(type) {
    const { isTemplate, pprops } = this.props
    this.setState({ [`is${humanize(type)}LensTypeLoading`]: true, [`${type}Reset`]: true })
    !isTemplate && setTimeout(() => pprops.submit(), 500)
    setTimeout(() => this.handleContactLensFieldUpdate(type), 1500);
  }

  handleContactLensFieldUpdate(type) {
    const { currentContactLens: { os_contact_lens_attributes, od_contact_lens_attributes } } = this.props
    const lensType = type === 'od' ? od_contact_lens_attributes.lens_type : os_contact_lens_attributes.lens_type
    this.setState({ [`${type}LensTypeFields`]: contactLensTypeFields[lensType] })
  }

  renderOdLabel() {
    return (
      <span>OD<small className="float-right btn-link" onClick={() => !this.props.isTemplate && this.copyTo('od', 'os')}><i className="la la-sm la-copy" />OS</small></span>
    )
  }

  renderOsLabel() {
    return (
      <span>OS<small className="float-right btn-link" onClick={() => !this.props.isTemplate && this.copyTo('os', 'od')}><i className="la la-sm la-copy" />OD</small></span>
    )
  }

  buildLensTypeData(arr) {
    var data = []
    arr && arr.map((val) => {
      data = [...data, { key: val, value: val }]
    })
    return data
  }

  componentDidMount() {
    const { currentContactLens: { os_contact_lens_attributes, od_contact_lens_attributes }, currentContactLens } = this.props
    this.setState({
      odReset: false, osReset: false,
      odLensTypeFields: od_contact_lens_attributes ? contactLensTypeFields[od_contact_lens_attributes?.lens_type] : contactLensTypeFields[currentContactLens?.od_lens_type],
      osLensTypeFields: os_contact_lens_attributes ? contactLensTypeFields[os_contact_lens_attributes?.lens_type] : contactLensTypeFields[currentContactLens?.os_lens_type],
    })
  }

  handleAddSearchValSubmit(val, contact_lens_id, field_name, type) {
    const { name, pprops } = this.props
    const addToSettings = {type: type, val: val, field_name: field_name, setting_contact_lens_id: contact_lens_id, }
    this.props.dispatch(change('examFinalizePrescriptionForm', `${name}[add_lens_values]`, addToSettings))
    setTimeout(() => pprops.submit(), 500)
  }

  render() {
    const { name, baseName, fieldValues, isTemplate, pprops, pprops: { contactLensCleaningSolutions }, currentContactLens: { od_contact_lens_attributes, os_contact_lens_attributes } } = this.props
    return (
      [<div className="row">
        <div className='col-2 form-group'>
          <Field
            key={od_contact_lens_attributes && od_contact_lens_attributes.id}
            ref={`${name}[od_contact_lens_id]`}
            name={`${name}[od_contact_lens_id]`}
            displayName={`${name}[od_contact_lens_brand_name]`}
            component={AutoCompleteSelect}
            isNilInputValue={true}
            onChangeHook={() => this.onContactLensSelect('od')}
            klass="Settings::ContactLens"
            textField="brand_name"
            valueField="id"
            placeholder="Placeholder"
            className="form-control"
            label={this.renderOdLabel()}
            labelClasses="w-100"
          />
          <small>{od_contact_lens_attributes?.manufacturer_name}</small>
        </div>

        {this.state.isOdLensTypeLoading ?
          this.loadingSpinner("isOdLensTypeLoading") :
          <div className="col-6 px-0">
            <div className="row mx-0">
              {this.state.odLensTypeFields?.map((fieldName) => {
                return isPresentInArray(this.state.odLensTypeFields, `${fieldName}`) && this.renderLensFields('od', fieldName, name, od_contact_lens_attributes)
              })}
            </div>
          </div>
        }
        <FormTemplateField
          isTemplate={isTemplate}
          name={`${name}[issue_date]`}
          baseName={`${baseName}[fields][issue_date]`}
          fieldValues={fieldValues['fields']['issue_date']}
          component={DatePicker}
          placeholder="dd-mm-yy"
          wrapperClasses="col-2 form-group"
        />
        <FormTemplateField
          isTemplate={isTemplate}
          name={`${name}[cleaning_solution]`}
          baseName={`${baseName}[fields][cleaning_solution]`}
          fieldValues={fieldValues['fields']['cleaning_solution']}
          component={AutoCompleteSelect}
          data={contactLensCleaningSolutions}
          textField="name"
          valueField="name"
          wrapperClasses="col-2 form-group"
        />
      </div>,
      <div className="row">
        <div className='col-2 form-group'>
          <Field
            key={os_contact_lens_attributes && os_contact_lens_attributes.id}
            ref={`${name}[os_contact_lens_id]`}
            name={`${name}[os_contact_lens_id]`}
            displayName={`${name}[os_contact_lens_brand_name]`}
            component={AutoCompleteSelect}
            isNilInputValue={true}
            onChangeHook={() => this.onContactLensSelect('os')}
            klass="Settings::ContactLens"
            textField="brand_name"
            valueField="id"
            placeholder="Placeholder"
            className="form-control"
            label={this.renderOsLabel()}
            labelClasses="w-100"
          />
          <small>{os_contact_lens_attributes?.manufacturer_name}</small>
        </div>
        {this.state.isOsLensTypeLoading ?
          this.loadingSpinner("isOsLensTypeLoading") :
          <div className="col-6 px-0">
            <div className="row mx-0">
              {this.state.osLensTypeFields?.map((fieldName) => {
                return isPresentInArray(this.state.osLensTypeFields, `${fieldName}`) && this.renderLensFields('os', fieldName, name, os_contact_lens_attributes)
              })}
            </div>
          </div>
        }
        <FormTemplateField
          isTemplate={isTemplate}
          name={`${name}[expiry_date]`}
          baseName={`${baseName}[fields][expiry_date]`}
          fieldValues={fieldValues['fields']['expiry_date']}
          component={DatePicker}
          placeholder="dd-mm-yy"
          wrapperClasses="col-2 form-group"
        />
        <FormTemplateField
          isTemplate={isTemplate}
          name={`${name}[notes]`}
          baseName={`${baseName}[fields][notes]`}
          fieldValues={fieldValues['fields']['notes']}
          component={Textarea}
          className="form-control" wrapperClasses="col-2 form-group"
        />
      </div>]
    );
  }

  renderLensFields(lens_type, fieldName, name, contact_lens_attributes) {
    const contactLensFields = { base_curves: 'curve', diameters: 'diameter', spheres: 'sphere' };
    const contactLensExamField = contactLensFields[fieldName] || fieldName;

    return (
      <>
        <Field
          ref={`${name}[${lens_type}_${contactLensExamField}]`}
          name={`${name}[${lens_type}_${contactLensExamField}]`}
          label={`${humanize(contactLensExamField)}`}
          component={AutoCompleteSelect}
          reset={lens_type === 'od' ? this.state.odReset : this.state.osReset}
          data={this.buildLensTypeData(contact_lens_attributes && contact_lens_attributes[fieldName])}
          textField="value"
          valueField="key"
          wrapperClasses="col-2 mb-4"
          key={`${name}[${lens_type}_${contactLensExamField}]-${contact_lens_attributes?.id}`}
          displayName={`${name}[${lens_type}_${contactLensExamField}]`}
          hideResetButton={true}
          className="form-control"
          labelClasses="w-100"
          showAddOption={true}
          addSearchValSubmit={(val) => this.handleAddSearchValSubmit(val, contact_lens_attributes?.id, contactLensExamField, lens_type)}
        />
      </>
    )
  }

  loadingSpinner(fieldType) {
    return (
      <div className="col-6 d-flex align-items-center">
        <span className="spinner-border text-primary" style={{ width: '1.25rem', height: '1.25rem' }} role="status" />
        <h6 className="d-inline-block pl-3">Loading Lens Type...</h6>
        <span className="d-none">{setTimeout(() => { this.setState({ [fieldType]: false }) }, 3000)}</span>
      </div>
    )
  }
}

export default connect()(RenderLens);