import React, { Component } from 'react';
import { connect } from 'react-redux';
import { submit } from 'redux-form'
import { medicalRxEditCancel, medicalRxCreate } from '../../../redux/actions/medicalRxActions'
import MedicalRxForm from '../../../components/authorised/medicalRx/MedicalRxForm';
import * as $ from 'jquery' 
import { tabChange } from '../../../itrust_common/redux/actions/otherActions';
import Alert from '../../../itrust_common/components/Alert';

class MedicalRx extends Component{

  constructor(props) {
    super(props);
  }

  handleMedicalRxEditCancel(){
    this.props.dispatch(medicalRxEditCancel());
    $('#medicalRxModal').modal('hide')
  }

  handleRemoteSubmit(){
    this.props.dispatch(submit('medicalRxForm'))
  }

  handleMedicalRxCreated(res){
    const { onSubmitHook } = this.props
    onSubmitHook && onSubmitHook()
    $('#medicalRxModal').modal('hide')
    this.handleMedicalRxRedirection(res.action.payload.data.medical_rx.id)
  }

  getDuplicateImpressionStr(ImpressionArr){
   let duplicateImpressionStr=''
    ImpressionArr.map((impression)=>{
      duplicateImpressionStr = `${(duplicateImpressionStr && duplicateImpressionStr + ", " )+ impression}`
    })
    return duplicateImpressionStr
  }

  async handleMedicalRxSubmit(data) {
    const { patientId, dispatch, onSubmitHook } = this.props
    try {
      var res = await dispatch(medicalRxCreate(patientId, data))
      this.handleMedicalRxCreated(res)
    } catch (err) {
      let a= err;
      if (err.response.data.status_code === 422) {
        Alert.confirm(<div> <strong>{`${this.getDuplicateImpressionStr(err.response.data.impression_names)}`}</strong> already exists on the exam, do you want to proceed and replace with the Medical RX plan? </div>,
          async (res) => {
            if (res) {
              let response = await dispatch(medicalRxCreate(patientId, { ...data, continue_with_duplicate_impression: true }))
              this.handleMedicalRxCreated(response)
            }}
        )}}
  }
  async handleMedicalRxRedirection(rxId) {
    this.props.dispatch(tabChange({ exam: 11 }))
    setTimeout(() => {
      let el = document.getElementById(`MedicalRx-${rxId}`)
      el.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  }

  render(){
    const { medicalRxEditing } = this.props
    return (
      <div className="medicalRx-modal">
        <div className="modal" id="medicalRxModal" data-backdrop="static">
          <div className="modal-dialog modal-lg">
            <div className="modal-content border-0 shadow-lg rounded">
              <div className="modal-header p-6 align-items-center border-bottom border-bottom-2 border-light">
                <h5 className="text-dark" >Medical Rx's</h5>
                <div className="d-flex flex-row">
                  <button type="button" className="btn btn-outline-primary mr-7" onClick={this.handleMedicalRxEditCancel.bind(this)}>Cancel</button>
                  <button type="button" className="btn btn-primary" onClick={this.handleRemoteSubmit.bind(this)}>Submit</button>
                </div>
              </div>
              <div className="modal-body">
                {
                  medicalRxEditing &&
                  <MedicalRxForm onSubmit={this.handleMedicalRxSubmit.bind(this)}/>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { medicalRx } = state;
  return {
    medicalRxEditing: medicalRx.editing,
  }
}

export default connect(mapStateToProps)(MedicalRx);