import Toastr from "../../../components/Toastr"
const parent = 'SETTING_'

const DEFAULT_STATE = {
  contact_lenses: [],
  contact_lens: {cylinder: [""], add: [""], diameters: [""], spheres: [""], base_curves: [""], axis: [""]},
  meta: {pagination: {}},
  saving: false
}
let changes = null;
export default function contactLensReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let contactLenses = [];
  let contactLensData = [];
  let updatedRecord = {};
  switch(action.type){
    case `${parent}CONTACT_LENSES_LOAD_PENDING`:
    case `${parent}CONTACT_LENS_LOAD_PENDING`:
      return {...state, saving: false};

    case `${parent}CONTACT_LENS_CREATE_PENDING`:
    case `${parent}CONTACT_LENS_UPDATE_PENDING`:
      return {...state, saving: true};

    case `${parent}CONTACT_LENSES_LOAD_REJECTED`:
    case `${parent}CONTACT_LENS_LOAD_REJECTED`:
    case `${parent}CONTACT_LENS_CREATE_REJECTED`:
    case `${parent}CONTACT_LENS_UPDATE_REJECTED`:
    case `${parent}CONTACT_LENS_DELETE_REJECTED`:
      Toastr.handleShow(response.data.success, response.data.error, response.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case `${parent}CONTACT_LENSES_LOAD_FULFILLED`:
      changes = {
        contact_lenses: response.contact_lens,
        meta: response.meta,
        saving: false
      };
      return {...state, ...changes};
    
    case `${parent}CONTACT_LENS_LOAD_FULFILLED`:
      changes = {
        contact_lens: response.contact_lens,
        saving: false
      }
      return {...state,...changes};
    
    case `${parent}CONTACT_LENS_CREATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      contactLenses = [...state.contact_lenses]
      let newRecord = response.contact_lens
      changes = {
        contact_lenses: [...contactLenses, newRecord],
        editing: false,
        saving: false
      }
      return {...state, ...changes};

    case `${parent}CONTACT_LENS_UPDATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      contactLenses = [...state.contact_lenses];
      updatedRecord = response.contact_lens;
      for (const [index, contactLens] of contactLenses.entries()) {
        if (contactLens.id === updatedRecord.id) {
          contactLensData = [
            ...contactLenses.slice(0, index),
            updatedRecord,
            ...contactLenses.slice(index + 1)
          ];
        }
      }
      changes = {
        contact_lenses: contactLensData,
        editing: false,
        saving: false
      };
      return { ...state, ...changes };

    case `${parent}CONTACT_LENS_DELETE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      contactLenses = [...state.contact_lenses];
      for (const [index, contactLens] of contactLenses.entries()) {
        if (contactLens.id === response.contact_lens.id) {
          contactLenses.splice(index, 1);
        }
      }
      return {
        ...state,
        contact_lenses: contactLenses,
        error: null
      };
    
    case `${parent}CONTACT_LENS_DUPLICATE`:
      changes = {
        editing: {
          id: 'New',
        },
        contact_lens: {...action.contactLens, id: null},
      };
      return { ...state, ...changes };

    case `${parent}CONTACT_LENS_EDIT`:
      changes = {
        editing: {
          id: action.id,
        },
        contact_lens: DEFAULT_STATE["contact_lens"]
      };
      return { ...state, ...changes };

    case `${parent}CONTACT_LENS_EDIT_CANCEL`:
      changes = {
        editing: false
      };
      return { ...state, ...changes };

    default:
      return state;
  }
}