import React from 'react'
import whatsAppWallpaper from '../../../../../assets/static/images/whatsapp-wallpaper.svg'
import { dateTimeFormat } from '../../../../../itrust_common/components/HelperFunctions'
import ScrollToLastMessage from '../../other/ScrollToLastMessage'

export default function WhatsAppMessageListing(props) {
  const { patientChatLogs } = props
  return (
    <div className='position-relative'>
      <div className='p-5 whatsapp-chat-listing overflow-auto' style={{ backgroundImage: `url(${whatsAppWallpaper})` }}>
        <div className='d-flex flex-column'>
          {[...patientChatLogs, ...patientChatLogs, ...patientChatLogs, ...patientChatLogs, ...patientChatLogs, ...patientChatLogs, ...patientChatLogs, ...patientChatLogs, ...patientChatLogs, ...patientChatLogs, ...patientChatLogs, ...patientChatLogs].map(log => <div className={`${log.message_type == 'patient' ? 'whatsapp-incoming-msg' : 'whatsapp-outgoing-msg'} whatsapp-msg border-radius-6 py-2 px-4 mb-4 position-relative font-size-14`}>
            <p className='mb-0'>{log.body}</p>

            <p className='text-right mb-0 font-size-12'>
              {dateTimeFormat(log.created_at)}
              {log.message_type == 'business' && <span className='ml-3 font-size-18 whatsapp-error-msg'>
                {log.other_details?.details ?
                  <span className='text-danger'><i className="las la-exclamation-circle font-size-20 custom-tooltip" data-title={log.other_details?.details}></i></span> :
                  <>
                    {(log.status == 'initiate' || log.status == 'sent') ?
                      <i className="las la-check"></i> :
                      <i className={`las la-check-double ${log.status == 'read' && 'text-info'}`}></i>
                    }
                  </>
                }
              </span>}
            </p>
            <div className={`message-corner position-absolute ${log.message_type == 'patient' ? 'incoming-message-corner' : 'outgoing-message-corner'}`}></div>
          </div>)}
        </div>
      </div>
      <div className='position-absolute scroll-bottom-btn'>
        <ScrollToLastMessage key={patientChatLogs.length} chatContainerClass={"whatsapp-chat-listing"} />
      </div>
    </div>
  )
}
