import { blobToBase64 } from "./base64Convertor";

let mediaRecorder;
let videoBase64;
let recordedChunks = [];
let stream;
let audioStream;
export async function startRecording() {
  try {
    stream = await recordScreen();
    audioStream = await recordAudio();
    let combine = new MediaStream([
      ...stream.getTracks(),
      ...audioStream.getTracks(),
    ]);
    let mimeType = { mimeType: "video/webm;codecs=vp8" };
    mediaRecorder = createRecorder(combine, mimeType);
    return stream;
  } catch (err) {
    console.log("Unable to acquire screen capture: " + err);
  }
}

export async function stopRecoding() {
  const blob = new Blob(recordedChunks, {
    type: "video/webm;codecs=vp8",
  });
  var tracks = [...stream.getTracks()];
  for (var i = 0; i < tracks.length; i++) tracks[i].stop();
  var audioTracks = [...audioStream.getTracks()];
  for (var j = 0; j < audioTracks.length; j++) audioTracks[j].stop();
  var byte_size = blob.size;
  videoBase64 = await blobToBase64(blob);
  recordedChunks = [];
  const name = localStorage.getItem("User-firstname") + Date.now();
  return {
    filename: name,
    attachmentBase: videoBase64,
    type: "video",
    byte_size: byte_size,
  };
}

async function recordScreen() {
  return await navigator.mediaDevices.getDisplayMedia({
    video: {
      mediaSource: "screen",
    },
    audio: true,
  });
}

async function recordAudio() {
  return await navigator.mediaDevices.getUserMedia({
    audio: true,
    video: false,
  });
}

function createRecorder(stream, mimeType) {
  const mediaRecorder = new MediaRecorder(stream, mimeType);
  mediaRecorder.ondataavailable = function (e) {
    if (e.data.size > 0) {
      recordedChunks.push(e.data);
    }
  };
  mediaRecorder.start(200);
  return mediaRecorder;
}

