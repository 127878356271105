import Toastr from "../../../itrust_common/components/Toastr"

const parent = 'PUBLIC_'

const DEFAULT_STATE = {
  slots: {},
  unavailable_dates: [],
  saving: false,
  unavailable_saving: false
}
let changes = null;

export default function staffReducer(state = DEFAULT_STATE, action) {
  const response = action.payload && (action.payload.data || action.payload.response)
  switch (action.type) {
    case `${parent}STAFFS_AVAILABLE_SLOTS_LOAD_PENDING`:
      changes = { saving: true }
      return { ...state, ...changes };

    case `${parent}STAFFS_UNAVAILABLE_DATES_LOAD_PENDING`:
      changes = { unavailable_saving: true }
      return { ...state, ...changes };

    case `${parent}STAFFS_AVAILABLE_SLOTS_LOAD_REJECTED`:
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = { saving: false }
      return { ...state, ...changes };

    case `${parent}STAFFS_UNAVAILABLE_DATES_LOAD_REJECTED`:
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = { unavailable_saving: false }
      return { ...state, ...changes };

    case `${parent}STAFFS_AVAILABLE_SLOTS_LOAD_FULFILLED`:
      changes = {
        ...response,
        saving: false
      };
      return { ...state, ...changes };

    case `${parent}STAFFS_UNAVAILABLE_DATES_LOAD_FULFILLED`:
      changes = {
        unavailable_dates: [...response.slots],
        unavailable_saving: false,
      };
      return { ...state, ...changes };

    case `${parent}STAFFS_AVAILABLE_SLOTS_DUMP`:
      changes = {
        slots: {},
      };
      return { ...state, ...changes };

    case `${parent}STAFFS_UNAVAILABLE_DATES_DUMP`:
      changes = {
        unavailable_dates: [],
      };
      return { ...state, ...changes };

    case `${parent}STAFF_ID`:
      changes = {
        staffId: action.id
      };
      return { ...state, ...changes };

    default:
      return state;
  }
}