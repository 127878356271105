import Toastr from "../../itrust_common/components/Toastr"
const DEFAULT_STATE = {
  anonymous_calendar_recurring_schedules: [],
  anonymous_calendar_recurring_schedule: {},
  meta: { pagination: {} },
  saving: false
}
let changes = null;
export default function recurringScheduleReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let recurringSchedules = [];
  let recurringScheduleData = [];
  let updatedRecord = {};
  switch(action.type){
    case 'ANONYMOUS_CALENDAR_RECURRING_SCHEDULES_LOAD_PENDING':
      return {...state, saving: true};

    case 'ANONYMOUS_CALENDAR_RECURRING_SCHEDULE_CREATE_PENDING':
      return {...state, saving: true};

    case 'ANONYMOUS_CALENDAR_RECURRING_SCHEDULES_LOAD_REJECTED':
    case 'ANONYMOUS_CALENDAR_RECURRING_SCHEDULE_CREATE_REJECTED':
    case 'ANONYMOUS_CALENDAR_RECURRING_SCHEDULE_UPDATE_REJECTED':
    case 'ANONYMOUS_CALENDAR_RECURRING_SCHEDULE_DELETE_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case 'ANONYMOUS_CALENDAR_RECURRING_SCHEDULES_LOAD_FULFILLED':
      changes = {
        ...response,
        saving: false
      };
      return {...state, ...changes};

    case 'ANONYMOUS_CALENDAR_RECURRING_SCHEDULE_UPDATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      recurringSchedules = [...state.anonymous_calendar_recurring_schedules];
      updatedRecord = response.anonymous_calendar_recurring_schedule;
      for (const [index, recurringSchedule] of recurringSchedules.entries()) {
        if (recurringSchedule.id === updatedRecord.id) {
          recurringScheduleData = [
            ...recurringSchedules.slice(0, index),
            updatedRecord,
            ...recurringSchedules.slice(index + 1)
          ];
        }
      }
      changes = {
        anonymous_calendar_recurring_schedules: recurringScheduleData,
        editing: false,
        saving: false
      };
      return { ...state, ...changes };

    default:
      return state;
  }
}