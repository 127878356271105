import React, { Component } from 'react';
import { AutoComplete } from '../others'
import { toggleId, isPresentInArray, removeObject, defaultImage } from '../HelperFunctions';

export default class AutoCompleteList extends Component {
  constructor(props){
    super(props)
    this.state = {
      data: this.props.defaultData ? this.props.defaultData : []
    }
  }
  handleFormValue(val, isRemoved){
    const { input, valueField } = this.props
    const { data } = this.state
    var selectedIds = [...input.value]
    if(isPresentInArray(input.value, val[valueField])){
      if(isRemoved){
        this.setState({data: removeObject(data, val.id)})
        selectedIds = toggleId(input.value, val[valueField])
      }
    }
    else {
      this.setState({data: [...new Set([...data, val])]})
      selectedIds = toggleId(input.value, val[valueField])
    }
    input.onChange(selectedIds)
  }
  
  render() {
    const {
      props,
      props: { disabled, placeholder, wrapperClasses, label, klass, autoCompleteValueField, autoCompleteTextField },
      props: { meta: { touched, error, warning } }
    } = this;
    return (
      <div className={`${disabled && 'disabled'} ${wrapperClasses}`}>
        {label !== undefined && <label>{props.label}</label>}
        <AutoComplete onSelect={(val)=> this.handleFormValue(val, false)} 
          textField={autoCompleteTextField}
          valueField={autoCompleteValueField}
          klass={klass}
          placeholder={placeholder}
          wrapperClasses="col-12 px-0"
         />
        <div className="row">
          { this.state.data && this.state.data.map(obj => this.renderItem(obj))}
        </div>
        { touched &&
          ((error && <span className="error-msg text-danger">{error}</span>) ||
            (warning && <span>{warning}</span>))}
        { touched && (error && <i className="la la-close text-danger error-icon-label"></i>)}
      </div>
    );
  }

  renderItem(obj){
    const { view: {image, h5, span}, className } = this.props
    var img = obj[image] || obj[image+"_attributes"]
    return (
      <div key={`selected-item-${obj.id}`} className={className}>
        <div className="d-flex align-items-center justify-content-between badge badge-light badge-pill py-3 px-5 border border-secondary">
          {image && <img src={defaultImage(img)} alt="logo" className="avatar"/>}
          <div className={`${image && 'border-left pl-4'} pr-4 text-left`}>
            {h5 && <h5 className="pb-3 text-wrap">{obj[h5]}</h5>}
            {span && <span>{obj[span]}</span>}
          </div>
          <span onClick={this.handleFormValue.bind(this, obj, true)}><i className="la la-times"/></span>
        </div>
      </div>
    )
  }
}
