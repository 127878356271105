import React from 'react';
import { humanize } from '../../../../../itrust_common/components/HelperFunctions';
import DisplayField from '../../../../../itrust_common/components/others/DisplayField'

let LensView = props => {
  const { fieldsValues, template} = props;
  return (
    <div className="row">
      <div className="col-12">
      <div className="overview-table mb-3">
          <table className="table table-bordered bg-light table-hover shadow-none border border-muted">
            <thead className="text-dark border border-muted">
              <tr className="text-center">
                <th scope="col"></th>
                <th scope="col">
                  <DisplayField
                    isVisible={true}
                    label={"Name"}
                  />
                </th>
                <th scope="col">
                  <DisplayField
                    isVisible={template.fields.od_sphere.visible}
                    label={"SPH"}
                  />
                </th>
                <th scope="col">
                  <DisplayField
                    isVisible={template.fields.od_cylinder.visible}
                    label={"CYL"}
                  />
                </th>
                <th scope="col">
                  <DisplayField
                    isVisible={template.fields.od_axis.visible}
                    label={"AXIS"}
                  />
                </th>
                <th scope="col">
                  <DisplayField
                    isVisible={template.fields.od_add.visible}
                    label={"ADD"}
                  />
                </th>
                <th scope="col">
                  <DisplayField
                    isVisible={template.fields.od_curve.visible}
                    label={"BC"}
                  />
                </th>
                <th scope="col">
                  <DisplayField
                    isVisible={template.fields.od_diameter.visible}
                    label={"DIA"}
                  />
                </th>
                <th scope="col">
                  <DisplayField
                    isVisible={true}
                    label={"Over Refraction"}
                  />
                </th>
                <th scope="col">
                  <DisplayField
                    isVisible={true}
                    label={'Distance'}
                  />
                </th>
                <th scope="col">
                  <DisplayField
                    isVisible={true}
                    label={"Near"}
                  />
                </th>
                <th scope="col">
                  <DisplayField
                    isVisible={true}
                    label={"Note"}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
                {["od", "os", "ou"].map(type =>
                  <tr className="text-center">
                    <td>
                      <h6>{type.toUpperCase()}</h6>
                    </td>
                    <td>
                      { <DisplayField
                        isVisible={true}
                        // label={template.fields.distance_od.custom_label}
                        value={humanize(fieldsValues[`${type}_contact_lens_brand_name`])}
                      /> }
                    </td>
                    <td>
                      <DisplayField
                        isVisible={template.fields.od_sphere.visible}
                        // label={template.fields.distance_od.custom_label}
                        value={fieldsValues[`${type}_sphere`]}
                      />
                    </td>
                    <td>
                      <DisplayField
                        isVisible={template.fields.od_cylinder.visible}
                        // label={template.fields.near_od.custom_label}
                        value={fieldsValues[`${type}_cylinder`]}
                      />
                    </td>
                    <td>
                      <DisplayField
                        isVisible={template.fields.od_axis.visible}
                        // label={template.fields.near_od.custom_label}
                        value={fieldsValues[`${type}_axis`]}
                      />
                    </td>
                    <td>
                      <DisplayField
                        isVisible={template.fields.od_add.visible}
                        // label={template.fields.near_od.custom_label}
                        value={fieldsValues[`${type}_add`]}
                      />
                    </td>
                    <td>
                      <DisplayField
                        isVisible={template.fields.od_curve.visible}
                        // label={template.fields.near_od.custom_label}
                        value={fieldsValues[`${type}_curve`]}
                      />
                    </td>
                    <td>
                      <DisplayField
                        isVisible={template.fields.od_diameter.visible}
                        // label={template.fields.near_od.custom_label}
                        value={fieldsValues[`${type}_diameter`]}
                      />
                    </td>
                    <td>
                      <DisplayField
                        isVisible={true}
                        // label={template.fields.near_od.custom_label}
                        value={fieldsValues[`${type}_over_refraction`]}
                      />
                    </td>
                    <td>
                      <DisplayField
                        isVisible={true}
                        // label={template.fields.near_od.custom_label}
                        value={fieldsValues.visual_acuity && fieldsValues.visual_acuity[`${type}_distance`]}
                      />
                    </td>
                    <td>
                      <DisplayField
                        isVisible={true}
                        // label={template.fields.near_od.custom_label}
                        value={fieldsValues.visual_acuity && fieldsValues.visual_acuity[`${type}_near`]}
                      />
                    </td>
                    <td>
                      <DisplayField
                        isVisible={template.fields.od_notes.visible}
                        // label={template.fields.near_od.custom_label}
                        value={fieldsValues[`${type}_notes`]}
                      />
                    </td>
                  </tr>
                  )
              }
            </tbody>
          </table>
        </div>
        { fieldsValues.notes && <DisplayField
          isVisible={template.fields.notes.visible}
          label={`${template.fields.notes.custom_label}:`}
          value={fieldsValues.notes}
          className=""
        /> }
      </div>
    </div>
  )
}


export default LensView;