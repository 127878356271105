import React from 'react';

//Tests import
import Default from '../overview/additionalTests/Default'
import AmslerGrid from '../overview/additionalTests/AmslerGrid'
import PunctalPlug from '../overview/additionalTests/PunctalPlug'
import Epilation from '../overview/additionalTests/Epilation'
import ForeignBodyRemoval from '../overview/additionalTests/ForeignBodyRemoval'
import SkinTagRemovel from '../overview/additionalTests/SkinTagRemovel'
import AmnioticMembrane from '../overview/additionalTests/AmnioticMembrane'
import Pachymetry from '../overview/additionalTests/Pachymetry'
import InflammaDry from '../overview/additionalTests/InflammaDry'
import TearLab from '../overview/additionalTests/TearLab'
import { humanize } from '../../../../itrust_common/components/HelperFunctions';

let renderTest = (fieldsValue, template, defaultLabel) => {
  switch(defaultLabel){
    case 'Amsler grid':
      return <AmslerGrid fieldsValues={fieldsValue} template={template}/>
    case 'Punctal plug':
      return <PunctalPlug fieldsValues={fieldsValue} template={template}/>
    case 'Epilation':
      return <Epilation fieldsValues={fieldsValue} template={template}/>
    case 'Foreign body removal':
      return <ForeignBodyRemoval fieldsValues={fieldsValue} template={template}/>
    case 'Skin tag removal':
      return <SkinTagRemovel fieldsValues={fieldsValue} template={template}/>
    case 'Amniotic membrane':
      return <AmnioticMembrane fieldsValues={fieldsValue} template={template}/>
    case 'Pachymetry':
      return <Pachymetry fieldsValues={fieldsValue} template={template}/>
    case 'Inflamma dry':
      return <InflammaDry fieldsValues={fieldsValue} template={template}/>
    case 'Tear lab':
      return <TearLab fieldsValues={fieldsValue} template={template}/>
    default:
      return <Default fieldsValues={fieldsValue} template={template}/>
  }
}

let AdditionalTests = props => {
  const { fieldsValues: {additional_tests_attributes}, template, overviewName} = props;
  return (
    <div className="row">
      {additional_tests_attributes.map((member, index) =>
        member.is_marked_complete && template.tests[index].default_label === humanize(overviewName) && <div className="col-12 py-4">
          {renderTest(member, template.tests[index].fields, template.tests[index].default_label)}
        </div> 
      )}
    </div>
  )
}


export default AdditionalTests;