import React, {Component} from 'react';
import { Field } from 'redux-form';
import {reduxForm} from 'redux-form';
import { Input } from '../../../../itrust_common/components/form';
import { connect } from 'react-redux';
var timeout=null

class LastExamForm extends Component{
  render(){
    return (
      <div className="col-lg-6 col-md-12 mt-7">
        <div className="card border-0 shadow-sm">
          <div className="p-5 border-bottom border-light">
            <h6>Last Exams</h6>
          </div>
          <div className="card-body px-7">
            <div className="row">
              <div className="col-6">
                <div className="row">
                  <div className="col-12 mb-5">
                    <span className="text-muted">Eye Exam</span>
                  </div>
                  <Field name="last_eye_exam[exam_name]" label="Exam Name" component={Input} className="form-control" wrapperClasses="col-12 form-group" />
                  <Field name="last_eye_exam[exam_date]" label="Date of Exam" component={Input} className="form-control" wrapperClasses="form-group col-12" />
                  <Field name="last_eye_exam[exam_city]" label="Eye Exam City" component={Input} className="form-control" wrapperClasses="col-12 form-group" />
                </div>
              </div>
              <div className="col-6">
                <div className="row">
                  <div className="col-12 mb-5">
                    <span className="text-muted">Medical Exam</span>
                  </div>
                  <Field name="last_medical_exam[exam_name]" label="Exam Name" component={Input} className="form-control" wrapperClasses="col-12 form-group" />
                  <Field name="last_medical_exam[exam_date]" label="Date of Exam" component={Input} className="form-control" wrapperClasses="form-group col-12" />
                  <Field name="last_medical_exam[exam_city]" label="Eye Exam City" component={Input} className="form-control" wrapperClasses="col-12 form-group" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

LastExamForm = reduxForm({
  form: "lastExamForm",
  enableReinitialize: true,
  onChange: (values, dispatch, props) => {
    if(props.dirty){
      clearTimeout(timeout);
      timeout = setTimeout(() => props.submit(), 1500);
    }
  },
})(LastExamForm)

LastExamForm = connect(state => {
  const { patient: {patient}, exam: {exam} } = state
  const patientDetails = exam && exam.id ? exam.patient_attributes : patient
  return {
    initialValues: {
      id: patientDetails.id,
      last_eye_exam: patientDetails.last_eye_exam,
      last_medical_exam: patientDetails.last_medical_exam
    }
  }
})(LastExamForm)

export default LastExamForm;