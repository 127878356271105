import Toastr from "../../itrust_common/components/Toastr"
const DEFAULT_STATE = {
  medical_rxes: [],
  medical_rx: {},
  meta: { pagination: {} },
  saving: false
}
let changes = null;

export default function medicalRxReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let medicalRxes = [];
  let medicalRxData = [];
  let updatedRecord = {};
  switch(action.type){
    case 'MEDICALRXS_LOAD_PENDING':
    case 'MEDICALRX_LOAD_PENDING':
      return {...state, saving: true};

    case 'MEDICALRX_CREATE_PENDING':
    case 'MEDICALRX_UPDATE_PENDING':
      return {...state, saving: true};

    case 'MEDICALRXS_LOAD_REJECTED':
    case 'MEDICALRX_LOAD_REJECTED':
    case 'MEDICALRX_CREATE_REJECTED':
    case 'MEDICALRX_UPDATE_REJECTED':
    case 'MEDICALRX_SECURE_FAX_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case 'MEDICALRXS_LOAD_FULFILLED':
      changes = {
        ...response,
        saving: false
      };
      return {...state, ...changes};
    
    case 'MEDICALRX_LOAD_FULFILLED':
      changes = {
        medical_rx: response.medical_rx,
        saving: false
      }
      return {...state,...changes};
    
    case 'MEDICALRX_CREATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      medicalRxes = [...state.medical_rxes]
      let newRecord = response.medical_rx
      changes = {
        medical_rxes: [...medicalRxes, newRecord],
        editing: false,
        saving: false
      }
      return {...state, ...changes};

    case 'MEDICALRX_UPDATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      medicalRxes = [...state.medical_rxes];
      updatedRecord = response.medical_rx;
      for (const [index, medicalRx] of medicalRxes.entries()) {
        if (medicalRx.id === updatedRecord.id) {
          medicalRxData = [
            ...medicalRxes.slice(0, index),
            updatedRecord,
            ...medicalRxes.slice(index + 1)
          ];
        }
      }
      changes = {
        medical_rxes: medicalRxData,
        editing: false,
        saving: false
      };
      return { ...state, ...changes };

    case 'MEDICALRX_DELETE_FULFILLED':
      medicalRxes = [...state.medical_rxes];
      for (const [index, medicalRx] of medicalRxes.entries()) {
        if (medicalRx.id === response.medical_rx.id) {
          medicalRxes.splice(index, 1);
        }
      }
      return {
        ...state,
        medical_rxes: medicalRxes,
        error: null
      };

    case 'MEDICALRX_EDIT':
      changes = {
        editing: {
          id: action.id,
        },
      };
      return { ...state, ...changes };

    case 'MEDICALRX_EDIT_CANCEL':
      changes = {
        editing: false,
        medical_rx: {}
      };
      return { ...state, ...changes };
    
    case 'MEDICALRX_SECURE_FAX_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      return state;

    default:
      return state;
  }
}