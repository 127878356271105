import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import asyncComponent from '../../itrust_common/components/AsyncComponent';

const PlanPricing = asyncComponent(() =>
  import('../../containers/authorised/organisationPayment/planPricing/PlanPricing')
);

const ContactSupport = asyncComponent(() =>
  import('../../containers/authorised/organisationPayment/contactSupport/ContactSupport')
);

const PrivacyPolicyTermsToUse = asyncComponent(() =>
  import('../../containers/authorised/organisationPayment/PrivacyPolicyTermsToUse')
);

const CurrentPlan = asyncComponent(() =>
  import('../../containers/authorised/organisationPayment/currentPlan/CurrentPlan')
);

const ManageLicense = asyncComponent(() =>
  import('../../containers/authorised/organisationPayment/manageLicense/ManageLicense')
);

// const Payouts = asyncComponent(() =>
//   import('../../containers/authorised/itrust_pay/Payouts')
// )

const AddOnsPlan = asyncComponent(() =>
  import('../../containers/authorised/organisationPayment/addOns/AddOnsPlan')
)

const PaymentRoutes = (
  <Switch>
    <Route exact path="/payment/current-plan/current-plan" component={CurrentPlan} />
    <Route exact path="/payment/current-plan/manage-license" component={ManageLicense} />
    <Route exact path="/privacy-policy-terms-of-use-connect" component={PrivacyPolicyTermsToUse} />
    {/* <Route exact path="/payment/itrust-pay" component={Payouts} /> */}
    <Route exact path="/payment/add-ons" component={AddOnsPlan} />
    <Redirect exact from="/payment/current-plan" to="/payment/current-plan/current-plan" />
    <Route exact path="/payment/contact-support" component={ContactSupport} />
    <Route exact path="/payment/plan-pricing" component={PlanPricing} />
    <Redirect exact from="/payment" to="/payment/current-plan" />
  </Switch>
);


export default PaymentRoutes;