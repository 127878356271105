import React, {Component} from 'react';
import { Field } from 'redux-form';
import {reduxForm} from 'redux-form';
import { Textarea, Select } from '../../../../itrust_common/components/form';
import { FAMILY_RELATIONS } from '../../../../itrust_common/components/Constants';

let timeout = null;
class MedicalHistoryForm extends Component{
  render(){
    const {handleSubmit, handleDelete, isFamily, isDatesVisible, initialValues} = this.props
    return (
      <form onSubmit={handleSubmit}>
        <div className="row mt-5 py-5 bg-light">
          {/* <div className={isDatesVisible ? 'col-12' : 'col-6'}> */}
          <div className="col-12">
            <div className="row align-items-center">
              <div className="col">
                <span className="badge badge-dark">{initialValues.name}</span>
              </div>
              {
                isFamily &&
                <Field  name="relation"  label="Relationship" component={Select} data={FAMILY_RELATIONS} valueField="value" textField="label" className="ml-3 bg-white" wrapperClasses="d-flex align-items-center col" />
              }
              <button type="button" className="btn btn-link py-0" onClick={()=>handleDelete(initialValues.id)}><i className="la la-close text-muted"/></button>
            </div>
            <div className="row">
              {/* {
                isFamily &&
                <Field  name="relation"  label="Relationship" component={Dropdown} data={FAMILY_RELATIONS} valueField="value" textField="label" wrapperClasses="pt-4 col" />
              } */}
              {/* {
                isDatesVisible &&
                [<Field  name="begin_date"  label="Begin Date" component={DatePicker} placeholder="dd-mm-yy" wrapperClasses="pt-4 col" />,
                <Field  name="end_date"  label="End Date" component={DatePicker} placeholder="dd-mm-yy" wrapperClasses="pt-4 col" />]
              } */}
            </div>
          </div>
          <div className="col-12 mt-5 d-flex flex-row justify-content-between align-items-start">
            <Field  name="details" placeholder="History of Illness" component={Textarea} rows={1} className="form-control w-100" wrapperClasses="form-group w-100 " />
          </div>
        </div>
      </form>
    );
  }
}

MedicalHistoryForm = reduxForm({
  enableReinitialize: true,
  onChange: (values, dispatch, props) => {
    if(props.dirty){
      clearTimeout(timeout);
      timeout = setTimeout(() => props.submit(), 1500);
    }
  },
})(MedicalHistoryForm)

export default MedicalHistoryForm;
