import {handleAPI, API_VERSION_ONE} from '../../../itrust_common/utils/apiUtils';
const parent = 'STORE_SETTING_'

export function procedurePricingsLoad(filters){
  const url = `/${API_VERSION_ONE}/procedure_pricings`;
  const method = 'GET'

  return {
    type: `${parent}PROCEDURE_PRICINGS_LOAD`,
    payload: handleAPI(url, filters, method)
  };
}

export function procedurePricingLoad(id, filters){
  const url = `/${API_VERSION_ONE}/procedure_pricings/${id}`;
  const method = 'GET'

  return {
    type: `${parent}PROCEDURE_PRICING_LOAD`,
    payload: handleAPI(url,filters,method)
  };
}

export function procedurePricingCreate(data, filters){
  const url = `/${API_VERSION_ONE}/procedure_pricings`;
  const method= 'POST'
  return {
    type: `${parent}PROCEDURE_PRICING_CREATE`,
    payload: handleAPI(url,filters,method, data)
  }
}

export function procedurePricingUpdate(data, filters){
  const url = `/${API_VERSION_ONE}/procedure_pricings/${data.id}`;
  const method = "PUT"
  return {
    type: `${parent}PROCEDURE_PRICING_UPDATE`,
    payload: handleAPI(url,filters,method, {procedure_pricing: {...data}})
  }
}

export function procedurePricingDelete(id, filters) {
  const url = `/${API_VERSION_ONE}/procedure_pricings/${id}`;
  const method = 'DELETE';

  return {
    type: `${parent}PROCEDURE_PRICING_DELETE`,
    payload: new Promise((resolve, reject) => {
      handleAPI(url, filters, method)
        .then(response => {
          resolve({ id: id, ...response });
        })
        .catch(err => {
          reject(err);
        });
    })
  };
}

export function procedurePricingEdit(id) {
  return {
    type: `${parent}PROCEDURE_PRICING_EDIT`,
    id,
  };
}

export function procedurePricingEditCancel() {
  return {
    type: `${parent}PROCEDURE_PRICING_EDIT_CANCEL`
  };
}
