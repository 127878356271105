import React, {useEffect, useState} from 'react';

//Tests import
import Default from './Default'
import AmslerGrid from './AmslerGrid'
import PunctalPlug from './PunctalPlug'
import Epilation from './Epilation'
import DisplayField from '../../../../../itrust_common/components/others/DisplayField';
import ForeignBodyRemoval from './ForeignBodyRemoval'
import SkinTagRemovel from './SkinTagRemovel'
import AmnioticMembrane from './AmnioticMembrane'
import Pachymetry from './Pachymetry'
import InflammaDry from './InflammaDry'
import TearLab from './TearLab'

let renderTest = (fieldsValue, template, defaultLabel) => {
  switch(defaultLabel){
    case 'Amsler grid':
      return <AmslerGrid fieldsValues={fieldsValue} template={template}/>
    case 'Punctal plug':
      return <PunctalPlug fieldsValues={fieldsValue} template={template}/>
    case 'Epilation':
      return <Epilation fieldsValues={fieldsValue} template={template}/>
    case 'Foreign body removal':
      return <ForeignBodyRemoval fieldsValues={fieldsValue} template={template}/>
    case 'Skin tag removal':
      return <SkinTagRemovel fieldsValues={fieldsValue} template={template}/>
    case 'Amniotic membrane':
      return <AmnioticMembrane fieldsValues={fieldsValue} template={template}/>
    case 'Pachymetry':
      return <Pachymetry fieldsValues={fieldsValue} template={template}/>
    case 'Inflamma dry':
      return <InflammaDry fieldsValues={fieldsValue} template={template}/>
    case 'Tear lab':
      return <TearLab fieldsValues={fieldsValue} template={template}/>
    default:
      return <Default fieldsValues={fieldsValue} template={template}/>
  }
}


let AdditionalTests = props => {
  const { fieldsValues: {additional_tests_attributes}, template} = props;
  let [showLabel, setstate] = useState()
  
  useEffect(() => {
    showLabel = additional_tests_attributes.some((test)=> test.is_marked_complete === true)
    setstate(showLabel)
  }, [])

  return (
    <div className="row">
      {showLabel && <div className="col-12">
        <div className=" border-bottom">
          <h6 className="pb-3 pt-6">{template.custom_label}</h6>
        </div>
      </div> }

      {additional_tests_attributes.map((member, index) =>
        member.is_marked_complete && <div className="col-4 py-4">
          <DisplayField
            isVisible={template.tests[index].visible}
            label={template.tests[index].default_label}
            className="py-4"
          />
          {renderTest(member, template.tests[index].fields, template.tests[index].default_label)}
        </div> 
      )}
    </div>
  )
}


export default AdditionalTests;