import Toastr from "../../../../itrust_common/components/Toastr"

const DEFAULT_STATE = {
  call_logs: [],
  dashboard_logs: [],
  phone_report: {},
  saving: false,
}
let logs = [];
let changes = null
let updatedLogs = [];

export const voipReportReducer = (state = DEFAULT_STATE, action) => {
  const response = action.payload && (action.payload.data || action.payload.response)
  switch (action.type) {

    case 'VOIP_CALL_LOGS_LOAD_PENDING':
    case 'VOIP_PHONE_REPORT_LOAD_PENDING':
      return { ...state, saving: true };

    case 'VOIP_CALL_LOGS_LOAD_REJECTED':
    case 'VOIP_PHONE_REPORT_LOAD_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = { saving: false }
      return { ...state, ...changes };

    case 'VOIP_CALL_LOGS_LOAD_FULFILLED':
      // handle popup & dashboard  logs load
      logs = [...state.call_logs]
      let dataField = response?.meta?.pagination?.items === 60 ? 'call_logs' : 'dashboard_logs';
      updatedLogs = response?.meta?.pagination?.items === 60 ? [...logs, ...response['add_ons/voip/call_logs']] : [...response['add_ons/voip/call_logs']]

      changes = {
        [dataField]: [...updatedLogs],
        meta: response.meta,
        saving: false,
      }
      return { ...state, ...changes };

    case 'VOIP_PHONE_REPORT_LOAD_FULFILLED':
      changes = { phone_report: { ...response.records }, saving: false }
      return { ...state, ...changes };

    case 'VOIP_CALL_LOGS_CLEAR':
      changes = {
        call_logs: []
      };
      return { ...state, ...changes };

    default:
      return state
  }
}