import React, {Component} from 'react';
import { Field, change } from 'redux-form';
import { connect } from 'react-redux';
import {Textarea, InputWithIcon, CheckboxGroup, InputWithRange, Select, Input} from '../../../form/'
import sle from '../../../../assets/static/images/sle-tablet-img.png'
import cornea from '../../../../assets/static/images/cornea-tablet-img.png';
import {FormTemplateHeader, FormTemplateSectionWrapper, FormTemplateField} from '../../../others/'
import GonioscopyImage from '../../../../assets/static/images/gonioscopy.png'
import CanvasDrawing from '../../../others/CanvasDrawing';

const cornealDiseases = [{label:'SPK', value:"spk"}, {label:'SEI', value:"sei"}, {label:'Scar', value:"scar"}, {label:'Arcus', value:"arcus"}, {label:'Map Dot', value:"map_dot"}, {label:'Ulcer', value:"ulcer"}, {label:'Filaments', value:"filaments"}, {label:'Guttata', value:"guttata"}, ]

class ExternalForm extends Component{

  render(){
    const { template, isTemplate, exam, pprops=null } = this.props
    const isMarkCompleted = pprops && pprops.initialValues.external_attributes && pprops.initialValues.external_attributes.is_marked_complete
    const externalExamTemplate = template.external_internal_exam.external_exam.fields
    return (
      <div className="col-12">
        <FormTemplateSectionWrapper isTemplate={isTemplate} fieldValues={template.external_internal_exam.external_exam}>
          <div className="card border-0 shadow-sm px-5">
            <FormTemplateHeader
              isTemplate={isTemplate}
              name="external_attributes[is_marked_complete]"
              isToggle={isMarkCompleted}
              fieldValues={template.external_internal_exam.external_exam}
              baseName="external_internal_exam[external_exam]"
              overviewName="external_exam"
              pprops={pprops}
            />

            <div className={`card-body ${(isMarkCompleted) || isTemplate ? '' : 'd-none'}`}>
              <div className="row border-bottom border-bottom-2 border-light pb-7">
                {
                  (isTemplate || externalExamTemplate.sle.visible === "true") &&
                  <div className="col-lg-4">
                    {/* <span>SLE</span> */}
                    <FormTemplateField
                      isTemplate={isTemplate}
                      name="external_attributes[sle]"
                      baseName="external_internal_exam[external_exam][fields][sle]"
                      fieldValues={template.external_internal_exam.external_exam.fields.sle}
                      component={Input}
                      className="d-none"
                      />

                    <div className={`${!externalExamTemplate.sle.visible && "opacity-5"}`}>
                      <CanvasDrawing
                        isTemplate={isTemplate}
                        wrapperClasses="bg-light rounded text-center pt-3 pb-5 mt-5"
                        className="mx-auto"
                        canvasRef="sleCanvasRef"
                        canvasWidth={410}
                        canvasHeight={145}
                        imgSrc={sle}
                        isMarkCompleted={isTemplate ? true : isMarkCompleted}
                        defaultData={exam.external_attributes && exam.external_attributes.eye_draw_data}
                        onChangeHook = {(val)=> {pprops.dispatch(change('examExternalInternalForm', 'external_attributes[eye_draw_data]', val)) }}
                       />
                    </div>
                  </div>
                }
                {
                  (isTemplate || externalExamTemplate.cornea.visible === "true") &&
                  <div className="col-lg-4">
                    {/* <span>Cornea</span> */}
                    <FormTemplateField
                      isTemplate={isTemplate}
                      name="external_attributes[cornea]"
                      baseName="external_internal_exam[external_exam][fields][cornea]"
                      fieldValues={template.external_internal_exam.external_exam.fields.cornea}
                      component={Input}
                      className="d-none"
                      />

                    <div className={`bg-light p-6 rounded mt-5 ${!externalExamTemplate.cornea.visible && "opacity-5"}`}>
                      <CanvasDrawing
                        isTemplate={isTemplate}
                        wrapperClasses="bg-white rounded text-center py-5 h-100"
                        className="mx-auto"
                        canvasRef="corneaCanvasRef"
                        canvasWidth={335}
                        canvasHeight={108}
                        imgSrc={cornea}
                        isMarkCompleted={isTemplate ? true : isMarkCompleted}
                        defaultData={exam.external_attributes && exam.external_attributes.cornea.corneal_exam_draw_data}
                        onChangeHook = {(val)=> {pprops.dispatch(change('examExternalInternalForm', 'external_attributes[cornea][corneal_exam_draw_data]', val)) }}
                      />
                      <CheckboxGroup name="external_attributes[cornea][diseases]" options={cornealDiseases} wrapperClasses="col-12 form-group" className="m-2"/>
                      <Field name="external_attributes[cornea][note]" label="Notes" component={Textarea} rows={3} className="form-control"/>
                    </div>
                  </div>
                }
                {
                  (isTemplate || externalExamTemplate.gonioscopy.visible === "true") &&
                  <div className="col-lg-4">
                    {/* <span>Gonioscopy</span> */}
                    <FormTemplateField
                      isTemplate={isTemplate}
                      name="external_attributes[gonioscopy]"
                      baseName="external_internal_exam[external_exam][fields][gonioscopy]"
                      fieldValues={template.external_internal_exam.external_exam.fields.gonioscopy}
                      component={Input}
                      className="d-none"
                      />
                    <div className={`bg-light p-6 rounded mt-5 ${!externalExamTemplate.gonioscopy.visible && "opacity-5"}`}>
                      <div className="d-flex justify-content-between">
                        <div className="text-center">
                          <h5 className="pb-5">OD</h5>
                          <div className="external-gonioscopy-test position-relative d-inline-block">
                            <img src={GonioscopyImage} className="img-fluid pr-3" alt=""/>
                            <Field component={InputWithRange} name="external_attributes[gonioscopy_exam][od_inferior]" type="range" min="1" max="4" wrapperClasses="d-inline-block position-absolute top-range" className="input-range-width"/>
                            <Field component={InputWithRange} name="external_attributes[gonioscopy_exam][od_nasal]" type="range" min="1" max="4" wrapperClasses="d-inline-block position-absolute left-range" className="input-range-width"/>
                            <Field component={InputWithRange} name="external_attributes[gonioscopy_exam][od_temporal]" type="range" min="1" max="4" wrapperClasses="d-inline-block position-absolute right-range" className="input-range-width"/>
                            <Field component={InputWithRange} name="external_attributes[gonioscopy_exam][od_superior]" type="range" min="1" max="4" wrapperClasses="d-inline-block position-absolute bottom-range" className="input-range-width"/>
                          </div>
                          <Field component={Select} label="OD Pigmentation" name="external_attributes[gonioscopy_exam][od_pigmentation]" textField="label" valueField="value" data={[{label: 'Pigmentation 1', value: '1'}, {label: 'Pigmentation 2', value: '2'}, {label: 'Pigmentation 3', value: '3'}, {label: 'Pigmentation 4', value: '4'}]} wrapperClasses="form-group text-left my-3 pr-3" className=""/>
                        </div>
                        <div className="text-center">
                          <h5 className="pb-5">OS</h5>
                          <div className="external-gonioscopy-test position-relative d-inline-block">
                            <img src={GonioscopyImage} className="img-fluid pl-3" alt=""/>
                            <Field component={InputWithRange} name="external_attributes[gonioscopy_exam][os_inferior]" type="range" min="1" max="4" wrapperClasses="d-inline-block position-absolute top-range" className="input-range-width"/>
                            <Field component={InputWithRange} name="external_attributes[gonioscopy_exam][os_nasal]" type="range" min="1" max="4" wrapperClasses="d-inline-block position-absolute left-range" className="input-range-width"/>
                            <Field component={InputWithRange} name="external_attributes[gonioscopy_exam][os_temporal]" type="range" min="1" max="4" wrapperClasses="d-inline-block position-absolute right-range" className="input-range-width"/>
                            <Field component={InputWithRange} name="external_attributes[gonioscopy_exam][os_superior]" type="range" min="1" max="4" wrapperClasses="d-inline-block position-absolute bottom-range" className="input-range-width"/>
                          </div>
                          <Field component={Select} label="OS Pigmentation" name="external_attributes[gonioscopy_exam][os_pigmentation]" textField="label" valueField="value" data={[{label: 'Pigmentation 1', value: '1'}, {label: 'Pigmentation 2', value: '2'}, {label: 'Pigmentation 3', value: '3'}, {label: 'Pigmentation 4', value: '4'}]} wrapperClasses="form-group text-left my-3 pl-3" className=""/>
                        </div>
                      </div>
                      <div className="w-100">
                        <Field label="Notes" name="external_attributes[gonioscopy_exam][note]" component={Textarea} rows="2" className="form-control" />
                      </div>
                    </div>
                  </div>
                }
              </div>
              <div className="row pt-7">
                <div className="col-lg-4">
                  <div className="row mb-7">
                    <FormTemplateField
                      isTemplate={isTemplate}
                      name="external_attributes[tear_film][od]"
                      baseName="external_internal_exam[external_exam][fields][od_tear_film]"
                      fieldValues={template.external_internal_exam.external_exam.fields.od_tear_film}
                      component={InputWithIcon}
                      isOpen={isMarkCompleted}
                      showCopy={{ destination: 'external_attributes[tear_film][os]', title: 'OS' }}
                      title="OD"
                      className="form-control"
                      wrapperClasses="form-group label-icons col-12"
                      />
                    <FormTemplateField
                      isTemplate={isTemplate}
                      name="external_attributes[tear_film][os]"
                      baseName="external_internal_exam[external_exam][fields][os_tear_film]"
                      fieldValues={template.external_internal_exam.external_exam.fields.os_tear_film}
                      component={InputWithIcon}
                      isOpen={isMarkCompleted}
                      showCopy={{ destination: 'external_attributes[tear_film][od]', title: 'OD' }}
                      title="OS"
                      className="form-control"
                      wrapperClasses="form-group col-12 label-icons"
                      />
                    <FormTemplateField
                      isTemplate={isTemplate}
                      name="external_attributes[tear_film][ou]"
                      baseName="external_internal_exam[external_exam][fields][ou_tear_film]"
                      fieldValues={template.external_internal_exam.external_exam.fields.ou_tear_film}
                      component={InputWithIcon}
                      isOpen={isMarkCompleted}
                      title="OU"
                      className="form-control"
                      wrapperClasses="form-group label-icons col-12"
                      />
                  </div>
                  <div className="row mb-7">
                    <FormTemplateField
                      isTemplate={isTemplate}
                      name="external_attributes[cornea][od]"
                      baseName="external_internal_exam[external_exam][fields][od_cornea]"
                      fieldValues={template.external_internal_exam.external_exam.fields.od_cornea}
                      component={InputWithIcon}
                      isOpen={isMarkCompleted}
                      showCopy={{ destination: 'external_attributes[cornea][os]', title: 'OS' }}
                      title="OD"
                      className="form-control"
                      wrapperClasses="form-group col-12 label-icons"
                      />
                    <FormTemplateField
                      isTemplate={isTemplate}
                      name="external_attributes[cornea][os]"
                      baseName="external_internal_exam[external_exam][fields][os_cornea]"
                      fieldValues={template.external_internal_exam.external_exam.fields.os_cornea}
                      component={InputWithIcon}
                      isOpen={isMarkCompleted}
                      showCopy={{ destination: 'external_attributes[cornea][od]', title: 'OD' }}
                      title="OS"
                      className="form-control"
                      wrapperClasses="form-group col-12 label-icons"
                      />
                    <FormTemplateField
                      isTemplate={isTemplate}
                      name="external_attributes[cornea][ou]"
                      baseName="external_internal_exam[external_exam][fields][ou_cornea]"
                      fieldValues={template.external_internal_exam.external_exam.fields.ou_cornea}
                      component={InputWithIcon}
                      isOpen={isMarkCompleted}
                      title="OU"
                      className="form-control"
                      wrapperClasses="form-group label-icons col-12"
                      />
                  </div>
                  <div className="row mb-7">
                    <FormTemplateField
                      isTemplate={isTemplate}
                      name="external_attributes[sclera][od]"
                      baseName="external_internal_exam[external_exam][fields][od_sclera]"
                      fieldValues={template.external_internal_exam.external_exam.fields.od_sclera}
                      component={InputWithIcon}
                      isOpen={isMarkCompleted}
                      showCopy={{ destination: 'external_attributes[sclera][os]', title: 'OS' }}
                      title="OD"
                      className="form-control"
                      wrapperClasses="form-group col-12 label-icons"
                      />
                    <FormTemplateField
                      isTemplate={isTemplate}
                      name="external_attributes[sclera][os]"
                      baseName="external_internal_exam[external_exam][fields][os_sclera]"
                      fieldValues={template.external_internal_exam.external_exam.fields.os_sclera}
                      component={InputWithIcon}
                      isOpen={isMarkCompleted}
                      showCopy={{ destination: 'external_attributes[sclera][od]', title: 'OD' }}
                      title="OS"
                      className="form-control"
                      wrapperClasses="form-group col-12 label-icons"
                      />
                    <FormTemplateField
                      isTemplate={isTemplate}
                      name="external_attributes[sclera][ou]"
                      baseName="external_internal_exam[external_exam][fields][ou_sclera]"
                      fieldValues={template.external_internal_exam.external_exam.fields.ou_sclera}
                      component={InputWithIcon}
                      isOpen={isMarkCompleted}
                      title="OU"
                      className="form-control"
                      wrapperClasses="form-group label-icons col-12"
                      />
                  </div>
                  <div className="row mb-7">
                    <FormTemplateField
                      isTemplate={isTemplate}
                      name="external_attributes[angle][od]"
                      baseName="external_internal_exam[external_exam][fields][od_angle]"
                      fieldValues={template.external_internal_exam.external_exam.fields.od_angle}
                      component={InputWithIcon}
                      isOpen={isMarkCompleted}
                      showCopy={{ destination: 'external_attributes[angle][os]', title: 'OS' }}
                      title="OD"
                      className="form-control"
                      wrapperClasses="label-icons form-group col-12"
                      />
                    <FormTemplateField
                      isTemplate={isTemplate}
                      name="external_attributes[angle][os]"
                      baseName="external_internal_exam[external_exam][fields][os_angle]"
                      fieldValues={template.external_internal_exam.external_exam.fields.os_angle}
                      component={InputWithIcon}
                      isOpen={isMarkCompleted}
                      showCopy={{ destination: 'external_attributes[angle][od]', title: 'OD' }}
                      title="OS"
                      className="form-control"
                      wrapperClasses="label-icons form-group col-12"
                      />
                    <FormTemplateField
                      isTemplate={isTemplate}
                      name="external_attributes[angle][ou]"
                      baseName="external_internal_exam[external_exam][fields][ou_angle]"
                      fieldValues={template.external_internal_exam.external_exam.fields.ou_angle}
                      component={InputWithIcon}
                      isOpen={isMarkCompleted}
                      title="OU"
                      className="form-control"
                      wrapperClasses="label-icons form-group col-12"
                      />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="row mb-7">
                    <FormTemplateField
                      isTemplate={isTemplate}
                      name="external_attributes[iris][od]"
                      baseName="external_internal_exam[external_exam][fields][od_iris]"
                      fieldValues={template.external_internal_exam.external_exam.fields.od_iris}
                      component={InputWithIcon}
                      isOpen={isMarkCompleted}
                      showCopy={{ destination: 'external_attributes[iris][os]', title: 'OS' }}
                      title="OD"
                      className="form-control"
                      wrapperClasses="form-group col-12 label-icons"
                      />
                    <FormTemplateField
                      isTemplate={isTemplate}
                      name="external_attributes[iris][os]"
                      baseName="external_internal_exam[external_exam][fields][os_iris]"
                      fieldValues={template.external_internal_exam.external_exam.fields.os_iris}
                      component={InputWithIcon}
                      isOpen={isMarkCompleted}
                      showCopy={{ destination: 'external_attributes[iris][od]', title: 'OD' }}
                      title="OS"
                      className="form-control"
                      wrapperClasses="form-group col-12 label-icons"
                      />
                    <FormTemplateField
                      isTemplate={isTemplate}
                      name="external_attributes[iris][ou]"
                      baseName="external_internal_exam[external_exam][fields][ou_iris]"
                      fieldValues={template.external_internal_exam.external_exam.fields.ou_iris}
                      component={InputWithIcon}
                      isOpen={isMarkCompleted}
                      title="OU"
                      className="form-control"
                      wrapperClasses="form-group label-icons col-12"
                      />
                  </div>
                  <div className="row mb-7">
                    <FormTemplateField
                      isTemplate={isTemplate}
                      name="external_attributes[bulbar_conj][od]"
                      baseName="external_internal_exam[external_exam][fields][od_bulbar_conj]"
                      fieldValues={template.external_internal_exam.external_exam.fields.od_bulbar_conj}
                      component={InputWithIcon}
                      isOpen={isMarkCompleted}
                      showCopy={{ destination: 'external_attributes[bulbar_conj][os]', title: 'OS' }}
                      title="OD"
                      className="form-control"
                      wrapperClasses="form-group label-icons col-12"
                      />
                    <FormTemplateField
                      isTemplate={isTemplate}
                      name="external_attributes[bulbar_conj][os]"
                      baseName="external_internal_exam[external_exam][fields][os_bulbar_conj]"
                      fieldValues={template.external_internal_exam.external_exam.fields.os_bulbar_conj}
                      component={InputWithIcon}
                      isOpen={isMarkCompleted}
                      showCopy={{ destination: 'external_attributes[bulbar_conj][od]', title: 'OD' }}
                      title="OS"
                      className="form-control"
                      wrapperClasses="form-group col-12 label-icons"
                      />
                    <FormTemplateField
                      isTemplate={isTemplate}
                      name="external_attributes[bulbar_conj][ou]"
                      baseName="external_internal_exam[external_exam][fields][ou_bulbar_conj]"
                      fieldValues={template.external_internal_exam.external_exam.fields.ou_bulbar_conj}
                      component={InputWithIcon}
                      isOpen={isMarkCompleted}
                      title="OU"
                      className="form-control"
                      wrapperClasses="form-group label-icons col-12"
                      />
                  </div>
                  <div className="row mb-7">
                    <FormTemplateField
                      isTemplate={isTemplate}
                      name="external_attributes[lens][od]"
                      baseName="external_internal_exam[external_exam][fields][od_lens]"
                      fieldValues={template.external_internal_exam.external_exam.fields.od_lens}
                      component={InputWithIcon}
                      isOpen={isMarkCompleted}
                      showCopy={{ destination: 'external_attributes[lens][os]', title: 'OS' }}
                      title="OD"
                      className="form-control"
                      wrapperClasses="form-group label-icons col-12"
                      />
                    <FormTemplateField
                      isTemplate={isTemplate}
                      name="external_attributes[lens][os]"
                      baseName="external_internal_exam[external_exam][fields][os_lens]"
                      fieldValues={template.external_internal_exam.external_exam.fields.os_lens}
                      component={InputWithIcon}
                      isOpen={isMarkCompleted}
                      showCopy={{ destination: 'external_attributes[lens][od]', title: 'OD' }}
                      title="OS"
                      className="form-control"
                      wrapperClasses="form-group col-12 label-icons"
                      />
                    <FormTemplateField
                      isTemplate={isTemplate}
                      name="external_attributes[lens][ou]"
                      baseName="external_internal_exam[external_exam][fields][ou_lens]"
                      fieldValues={template.external_internal_exam.external_exam.fields.ou_lens}
                      component={InputWithIcon}
                      isOpen={isMarkCompleted}
                      title="OU"
                      className="form-control"
                      wrapperClasses="form-group label-icons col-12"
                      />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="row mb-7">
                    <FormTemplateField
                      isTemplate={isTemplate}
                      name="external_attributes[palpebral_conj][od]"
                      baseName="external_internal_exam[external_exam][fields][od_palpebral_conj]"
                      fieldValues={template.external_internal_exam.external_exam.fields.od_palpebral_conj}
                      component={InputWithIcon}
                      isOpen={isMarkCompleted}
                      showCopy={{ destination: 'external_attributes[palpebral_conj][os]', title: 'OS' }}
                      title="OD"
                      className="form-control"
                      wrapperClasses="form-group label-icons col-12"
                      />
                    <FormTemplateField
                      isTemplate={isTemplate}
                      name="external_attributes[palpebral_conj][os]"
                      baseName="external_internal_exam[external_exam][fields][os_palpebral_conj]"
                      fieldValues={template.external_internal_exam.external_exam.fields.os_palpebral_conj}
                      component={InputWithIcon}
                      isOpen={isMarkCompleted}
                      showCopy={{ destination: 'external_attributes[palpebral_conj][od]', title: 'OD' }}
                      title="OS"
                      className="form-control"
                      wrapperClasses="form-group col-12 label-icons"
                      />
                    <FormTemplateField
                      isTemplate={isTemplate}
                      name="external_attributes[palpebral_conj][ou]"
                      baseName="external_internal_exam[external_exam][fields][ou_palpebral_conj]"
                      fieldValues={template.external_internal_exam.external_exam.fields.ou_palpebral_conj}
                      component={InputWithIcon}
                      isOpen={isMarkCompleted}
                      title="OU"
                      className="form-control"
                      wrapperClasses="form-group label-icons col-12"
                      />
                  </div>
                  <div className="row mb-7">
                    <FormTemplateField
                      isTemplate={isTemplate}
                      name="external_attributes[ac][od]"
                      baseName="external_internal_exam[external_exam][fields][od_ac]"
                      fieldValues={template.external_internal_exam.external_exam.fields.od_ac}
                      component={InputWithIcon}
                      isOpen={isMarkCompleted}
                      showCopy={{ destination: 'external_attributes[ac][os]', title: 'OS' }}
                      title="OD"
                      className="form-control"
                      wrapperClasses="form-group label-icons col-12"
                      />
                    <FormTemplateField
                      isTemplate={isTemplate}
                      name="external_attributes[ac][os]"
                      baseName="external_internal_exam[external_exam][fields][os_ac]"
                      fieldValues={template.external_internal_exam.external_exam.fields.os_ac}
                      component={InputWithIcon}
                      isOpen={isMarkCompleted}
                      showCopy={{ destination: 'external_attributes[ac][od]', title: 'OD' }}
                      title="OS"
                      className="form-control"
                      wrapperClasses="form-group col-12 label-icons"
                      />
                    <FormTemplateField
                      isTemplate={isTemplate}
                      name="external_attributes[ac][ou]"
                      baseName="external_internal_exam[external_exam][fields][ou_ac]"
                      fieldValues={template.external_internal_exam.external_exam.fields.ou_ac}
                      component={InputWithIcon}
                      isOpen={isMarkCompleted}
                      title="OU"
                      className="form-control"
                      wrapperClasses="form-group label-icons col-12"
                      />
                  </div>
                  <div className="row mb-7">
                    <FormTemplateField
                      isTemplate={isTemplate}
                      name="external_attributes[lids_lashes][od]"
                      baseName="external_internal_exam[external_exam][fields][od_lids_lashes]"
                      fieldValues={template.external_internal_exam.external_exam.fields.od_lids_lashes}
                      component={InputWithIcon}
                      isOpen={isMarkCompleted}
                      showCopy={{ destination: 'external_attributes[lids_lashes][os]', title: 'OS' }}
                      title="OD"
                      className="form-control"
                      wrapperClasses="form-group label-icons col-12"
                      />
                    <FormTemplateField
                      isTemplate={isTemplate}
                      name="external_attributes[lids_lashes][os]"
                      baseName="external_internal_exam[external_exam][fields][os_lids_lashes]"
                      fieldValues={template.external_internal_exam.external_exam.fields.os_lids_lashes}
                      component={InputWithIcon}
                      isOpen={isMarkCompleted}
                      showCopy={{ destination: 'external_attributes[lids_lashes][od]', title: 'OD' }}
                      title="OS"
                      className="form-control"
                      wrapperClasses="form-group label-icons col-12"
                      />
                    <FormTemplateField
                      isTemplate={isTemplate}
                      name="external_attributes[lids_lashes][ou]"
                      baseName="external_internal_exam[external_exam][fields][ou_lids_lashes]"
                      fieldValues={template.external_internal_exam.external_exam.fields.ou_lids_lashes}
                      component={InputWithIcon}
                      isOpen={isMarkCompleted}
                      title="OU"
                      className="form-control"
                      wrapperClasses="form-group label-icons col-12"
                      />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </FormTemplateSectionWrapper>
      </div>
    );
  }
}

export default connect()(ExternalForm);
