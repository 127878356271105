import {handleAPI, API_VERSION_ONE} from '../../itrust_common/utils/apiUtils';

export function staffsLoad(filters){
  const url = `/${API_VERSION_ONE}/staffs`;
  const method = 'GET'
  return {
    type: 'STAFFS_LOAD',
    payload: handleAPI(url, filters, method)
  };
}

export function staffLoad(id, filters){
  const url = `/${API_VERSION_ONE}/staffs/${id}`;
  const method = 'GET'

  return {
    type: 'STAFF_LOAD',
    payload: handleAPI(url,filters,method)
  };
}

export function staffCreate(data, filters){
  const url = `/${API_VERSION_ONE}/staffs`;
  const method= 'POST'
  return {
    type: 'STAFF_CREATE',
    payload: handleAPI(url,filters,method, {staff: {...data}})
  }
}

export function staffUpdate(data, filters){
  const url = `/${API_VERSION_ONE}/staffs/${data.id}`;
  const method = "PUT"
  return {
    type: 'STAFF_UPDATE',
    payload: handleAPI(url,filters,method, {staff: {...data}})
  }
}

export function staffDelete(id, filters) {
  const url = `/${API_VERSION_ONE}/staffs/${id}`;
  const method = 'DELETE';

  return {
    type: 'STAFF_DELETE',
    payload: new Promise((resolve, reject) => {
      handleAPI(url, filters, method)
        .then(response => {
          resolve({ id: id, ...response });
        })
        .catch(err => {
          reject(err);
        });
    })
  };
}

export function staffEdit(id) {
  return {
    type: 'STAFF_EDIT',
    id,
  };
}

export function staffEditCancel() {
  return {
    type: 'STAFF_EDIT_CANCEL'
  };
}