import React from 'react';
import { change, Field } from 'redux-form';

import { Input, Select } from '../../../../../../itrust_common/components/form';
import { rangeBuilder } from '../../../../../../itrust_common/components/HelperFunctions'

let Glass = props => {
  const { member, pprops: { dispatch, currentEyeglassAttributes } } = props;

  const updateCorrespondingAxisValue = (e, member, glassType) => {
    if (["DS", " "].includes(e?.target?.value)) {
      dispatch(change('orderEyeglassForm', `${member}[${glassType}_glass_details][axis]`, null))
    }
  }

  const copyTo = (source, destination) => {
    let eyeglasses_attributes = currentEyeglassAttributes
    let sourceObj = eval(`${member}.${source}_glass_details`);
    dispatch(change('orderEyeglassForm', `${member}[${destination}_glass_details][sphere]`, sourceObj.sphere))
    dispatch(change('orderEyeglassForm', `${member}[${destination}_glass_details][cylinder]`, sourceObj.cylinder))
    dispatch(change('orderEyeglassForm', `${member}[${destination}_glass_details][axis]`, sourceObj.axis))
    dispatch(change('orderEyeglassForm', `${member}[${destination}_glass_details][add]`, sourceObj.add))
    dispatch(change('orderEyeglassForm', `${member}[${destination}_glass_details][prism]`, sourceObj.prism))
    dispatch(change('orderEyeglassForm', `${member}[${destination}_glass_details][segment_height]`, sourceObj.segment_height))
    dispatch(change('orderEyeglassForm', `${member}[${destination}_glass_details][base_curve]`, sourceObj.base_curve))
  }

  const renderEyeFieldLabel = (fieldType) => {
    let destination = fieldType === 'od' ? 'os' : 'od'
    return <span>
      Eye
      <small className='text-primary btn-link pointer ml-8' onClick={() => copyTo(fieldType, destination)}>{destination.toUpperCase()}<i className="las la-copy btn-link" /></small>
    </span>
  }

  return (
    <div className="col-12 p-6">
      <div className="row">
        <Field 
          label={renderEyeFieldLabel('od')}
          component={Input} 
          placeholder="OD" 
          className="form-control disabled bg-light" 
          wrapperClasses="col-1 form-group" 
        />
        <Field 
          name={`${member}[od_glass_details][sphere]`}
          label="SPH" 
          component={Select} 
          data={[{ key: '' }, { key: 'Bal' }, ...rangeBuilder(20, -25.25, -0.25, '+', 'pl')]}
          textField="key" 
          valueField="key" 
          wrapperClasses="col-1" />
        <Field 
          name={`${member}[od_glass_details][cylinder]`} 
          label="CYL" 
          component={Select} 
          data={[...rangeBuilder(6.0, -10.25, -0.25, '+', ' ', 2, 'DS')]}
          textField="key" valueField="key" wrapperClasses="col-1"
          onChange={(e) => updateCorrespondingAxisValue(e, member, 'od')}
          />
        <Field 
          name={`${member}[od_glass_details][axis]`} 
          label="Axis"
          component={Select} 
          data={[{ key: null }, ...rangeBuilder(1, 181, 1, '', '', 0)]} 
          textField="key" valueField="key" wrapperClasses="col-1" />
        <Field 
          name={`${member}[od_glass_details][add]`}
          label="Add"
          component={Select} 
            data={[{ key: null }, ...rangeBuilder(0.25, 6.25, 0.25, '+', '0.00')]}
          textField="key" valueField="key" wrapperClasses="col-1" />
        <Field 
          name={`${member}[od_glass_details][prism]`} 
          label="Prism"
          component={Input}
          labelClasses="w-100"
          className="form-control"
          wrapperClasses="col-2" />

        <Field 
          label="Segment Height" 
          name={`${member}[od_glass_details][segment_height]`} 
          component={Input} 
          className="form-control"
          wrapperClasses="col-2 form-group"
        />

        <Field 
          label="Base Curve" 
          name={`${member}[od_glass_details][base_curve]`} 
          component={Input} 
          title="$"
          labelClasses="w-100"
          className="form-control"
          wrapperClasses="col-2"
        />
      </div>
      <div className="row">
        <Field 
          label={renderEyeFieldLabel('os')}
          component={Input} 
          placeholder="OS" 
          className="form-control disabled bg-light" 
          wrapperClasses="col-1 form-group" 
        />
        <Field 
          name={`${member}[os_glass_details][sphere]`}
          label="SPH" 
          component={Select} 
          data={[{ key: '' }, {key: 'Bal'}, ...rangeBuilder(20, -25.25, -0.25, '+', 'pl')]}
          textField="key" 
          valueField="key" 
          wrapperClasses="col-1" />
        <Field 
          name={`${member}[os_glass_details][cylinder]`} 
          label="CYL" 
          component={Select} 
          data={[...rangeBuilder(6.0, -10.25, -0.25, '+', ' ', 2, 'DS')]}
          textField="key" valueField="key" wrapperClasses="col-1"
          onChange={(e) => updateCorrespondingAxisValue(e, member, 'os')}
          />
        <Field 
          name={`${member}[os_glass_details][axis]`} 
          label="Axis"
          component={Select} 
          data={[{ key: null }, ...rangeBuilder(1, 181, 1, '', '', 0)]} 
          textField="key" valueField="key" wrapperClasses="col-1" />
        <Field 
          name={`${member}[os_glass_details][add]`}
          label="Add"
          component={Select} 
          data={[{ key: null }, {key: 'ADD'}, ...rangeBuilder(0, 6.25, 0.25, '+', '0.00')]} 
          textField="key" valueField="key" wrapperClasses="col-1" />
        <Field 
          name={`${member}[os_glass_details][prism]`} 
          label="Prism"
          component={Input}
          labelClasses="w-100"
          className="form-control"
          wrapperClasses="col-2" />

        <Field 
          label="Segment Height" 
          name={`${member}[os_glass_details][segment_height]`} 
          component={Input} 
          className="form-control"
          wrapperClasses="col-2 form-group"
        />

        <Field 
          label="Base Curve" 
          name={`${member}[os_glass_details][base_curve]`} 
          component={Input} 
          title="$"
          labelClasses="w-100"
          className="form-control"
          wrapperClasses="col-2"
        />
      </div>
    </div>
  )
}
export default Glass;