import React from 'react'
import { Radio, RadioGroup } from '../../../itrust_common/components/form'
import { Field } from 'redux-form'

export default function Accessibility(props) {
  const { setIsReloadRequire, setCurrentZoomLevel } = props

  const zoomLevelFields = [
    { value: '80%', label: 'Extra Small' },
    { value: '90%', label: 'Small' },
    { value: '100%', label: 'Default' },
    { value: '105%', label: 'Large' },
    { value: '110%', label: 'Extra Large' },
    { value: '115%', label: 'Extra Extra Large' },
  ]

  const handleZoomLevelChange = (level) => {
    setCurrentZoomLevel(level)
    setIsReloadRequire(true)
  }

  return (
    <div>
      <h6 className='col-12 mb-4'>Zoom Level</h6>
      <div className='ml-5'>
        {zoomLevelFields.map((field) => <Field name="zoom_level" type="radio" component={Radio} value={`${field.value}`} label={`${field.label}`} wrapperClasses="radio-sm" onChange={() => handleZoomLevelChange(field.value)} />)}
      </div>
      <h6 className='col-10 border-top pt-6 pb-2 mt-6'>Font Appearance</h6>
      <RadioGroup name="preferences.accessibility.font_appearance" btnWrapperClasses="mt-3" options={[{ label: 'Large', value: 'large' }, { label: 'Default', value: 'default' }]} wrapperClasses="col-6 form-group" isButtonsNormal={true} />
    </div>
  )
}