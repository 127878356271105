import React from 'react'
import { onBoardingTourInfoReset, onBoardingTourInfoUpdate } from '../../../redux/actions/onBoardingActions'
import { useOnboardingTourData } from './OnBoardingCustomHooks'

function CustomToolTip(props) {
  const { wrapperClasses, title, description, step, index, size, isLastStep, backProps, primaryProps, tooltipProps, isRenderCustom, customData, handleTourBackStep } = props
  const onBoardingTourData = useOnboardingTourData()
  // isRenderCustom > to handle open modal cases
  const { userRole, dispatch, onBoarding: { activeTourType } } = onBoardingTourData

  const handleTourClose = (isDone) => {
    dispatch(onBoardingTourInfoUpdate({ isTourActive: false, nextModule: false, nextModuleToPlay: '' }))
    dispatch(onBoardingTourInfoReset(isDone == true ? activeTourType : false))
  }

  return (
    <div {...tooltipProps} className={`${wrapperClasses} onboarding-tooltip`}>
      <div className={`bg-white border-radius-6`}>
        <div>
          <div className='p-5'>
            <div className='d-flex justify-content-between'>
              <h5>{isRenderCustom ? title : step?.title}</h5>
              <span onClick={handleTourClose}><i className="las la-times font-size-24"></i></span>
            </div>
            <p className='mt-5'>{isRenderCustom ? description : step?.content}</p>
          </div>
          <div className='bg-light p-5 border-radius-6-bottom d-flex justify-content-between align-items-center'>
            <span>
              Step {isRenderCustom ? customData.index : index + 1} of {isRenderCustom ? customData.size : size}
            </span>
            <div>
              {index !== 0 && <>
                {isRenderCustom ?
                  <button type="button" className='btn btn-outline-primary mr-5 px-6' onClick={handleTourBackStep}>Back</button> :
                  <button type="button" {...backProps} className='btn btn-outline-primary mr-5 px-6'>Back</button>
                }
              </>}
              {isLastStep && <button type="button" {...primaryProps} className='btn btn-primary px-6' onClick={() => handleTourClose(true)}>{(step?.target === '.activate-account-step-2' || ((!["super_admin", "itrust_admin"].includes(userRole)) && step?.target === '.order-step-1')) ? 'Finish' : 'Done'}</button>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomToolTip;