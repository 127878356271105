import Toastr from "../../../components/Toastr"
const parent = 'SETTING_'

const DEFAULT_STATE = {
  impressions: [],
  impression: {},
  meta: {pagination: {}},
  saving: false
}
let changes = null;
export default function impressionReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let impressions = [];
  let impressionData = [];
  let updatedRecord = {};
  switch(action.type){
    case `${parent}IMPRESSIONS_LOAD_PENDING`:
    case `${parent}IMPRESSION_LOAD_PENDING`:
      return {...state, saving: false};

    case `${parent}IMPRESSION_CREATE_PENDING`:
    case `${parent}IMPRESSION_UPDATE_PENDING`:
      return {...state, saving: true};

    case `${parent}IMPRESSIONS_LOAD_REJECTED`:
    case `${parent}IMPRESSION_LOAD_REJECTED`:
    case `${parent}IMPRESSION_CREATE_REJECTED`:
    case `${parent}IMPRESSION_UPDATE_REJECTED`:
    case `${parent}IMPRESSION_DELETE_REJECTED`:
      Toastr.handleShow(response.data.success, response.data.error, response.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case `${parent}IMPRESSIONS_LOAD_FULFILLED`:
      changes = {
        ...response,
        saving: false
      };
      return {...state, ...changes};
    
    case `${parent}IMPRESSION_LOAD_FULFILLED`:
      changes = {
        impression: response.impression,
        saving: false
      }
      return {...state,...changes};
    
    case `${parent}IMPRESSION_CREATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      impressions = [...state.impressions]
      let newRecord = response.impression
      changes = {
        impressions: [...impressions, newRecord],
        editing: false,
        saving: false
      }
      return {...state, ...changes};

    case `${parent}IMPRESSION_UPDATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      impressions = [...state.impressions];
      updatedRecord = response.impression;
      for (const [index, impression] of impressions.entries()) {
        if (impression.id === updatedRecord.id) {
          impressionData = [
            ...impressions.slice(0, index),
            updatedRecord,
            ...impressions.slice(index + 1)
          ];
        }
      }
      changes = {
        impressions: impressionData,
        editing: false,
        saving: false
      };
      return { ...state, ...changes };

    case `${parent}IMPRESSION_DELETE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      impressions = [...state.impressions];
      for (const [index, impression] of impressions.entries()) {
        if (impression.id === response.impression.id) {
          impressions.splice(index, 1);
        }
      }
      return {
        ...state,
        impressions: impressions,
        error: null
      };

    case `${parent}IMPRESSION_REORDER`:
      changes = {
        impressions: action.impressions
      };
      return { ...state, ...changes };

    case `${parent}IMPRESSION_EDIT`:
      changes = {
        editing: {
          id: action.id,
        },
        impression: {}
      };
      return { ...state, ...changes };

    case `${parent}IMPRESSION_EDIT_CANCEL`:
      changes = {
        editing: false
      };
      return { ...state, ...changes };

    default:
      return state;
  }
}