import React, { useEffect, useState } from 'react';

//Tests import
import External from './External'
import Internal from './Internal'
import Gat from './Gat'
import Nct from './Nct'
import VisualField from './VisualField'

let ExternalInternalTests = props => {
  const { fieldsValues: {external_attributes, internal_attributes, nct, gat, visual_field}, template, isPdfView, store} = props;
  let [gatShowLabel, gatSetstate] = useState()
  let [nctShowLabel, nctSetstate] = useState()

  useEffect(() => {
    gatShowLabel = gat.some((test)=> test.is_marked_complete === true)
    nctShowLabel = nct.some((test)=> test.is_marked_complete === true)
    gatSetstate(gatShowLabel)
    nctSetstate(nctShowLabel)
  }, [])

  return (
    <div className="row">
      {external_attributes && external_attributes.is_marked_complete &&
        <div className="col-12">
          <div className="border-bottom">
            <h6 className="pb-3 pt-6">{template.external_exam.custom_label}</h6>
          </div>
          <External isPdfView={isPdfView} fieldsValues={external_attributes} template={template.external_exam}/>
        </div>
      }
      { template.gat.visible === 'true' && gat.length > 0 && gatShowLabel &&
        <div className="col-3">
          <h6 className="pb-3 pt-6">{template.gat.custom_label}</h6>
          <Gat fieldsValues={gat} template={template.gat}/>
        </div>
      }

      { template.nct.visible === 'true' && nct.length > 0 && nctShowLabel &&
        <div className="col-3">
          <h6 className="pb-3 pt-6">{template.nct.custom_label}</h6>
          <Nct fieldsValues={nct} template={template.nct}/>
        </div>
      }

      { visual_field && <div className="col-3">
        <h6 className="pb-3 pt-6">{template.visual_field.custom_label}</h6>
        <VisualField fieldsValues={visual_field} template={template.visual_field}/>
      </div> }

      {internal_attributes && internal_attributes.is_marked_complete &&
        <div className="col-12">
          <div className="border-bottom">
            <h6 className="pb-3 pt-6">{template.internal_exam.custom_label}</h6>
          </div>
          <Internal isPdfView={isPdfView} fieldsValues={internal_attributes} template={template.internal_exam} store={store}/>
        </div>
      }
    </div>
  )
}


export default ExternalInternalTests;
