import React from 'react';
import { Field } from 'redux-form';
import {Input, Checkbox, Value} from '../form/'
import * as $ from 'jquery'

let FormTemplateFields = props => {
  const { baseName, isVisible, isRequired, hideIsRequired, isModeOfNotificationDefaultVal, defaultValueReadOnly, defaultValueDisabled } = props;
  $(".dropdown-menu .custom-checkbox").click(function(e){
    e.stopPropagation();
  })

  return (
    <span className="FormTemplateFields label-customisation d-flex justify-content-between">
      <div className="d-flex justify-content-start">
        <Field name={baseName+'[custom_label]'} label="" component={Value}/>
        {isRequired && <span className="text-danger pl-2"> *</span> }
      </div>
      <div className="d-flex justify-content-end">
        <span>
          <i className={`la text-muted mr-3 ${isVisible ? 'la-eye' : 'la-eye-slash'}`}/>
          <Field name={baseName+'[visible]'} label="" component={Checkbox} className="d-none" wrapperClasses="d-none"/>
        </span>
        <span className={`btn p-0 ${isVisible ? '' : 'd-none'}`} id={baseName} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i className="la la-pencil text-muted"/>
        </span>
        <div id="label-customisation" className="dropdown-menu dropdown-menu-right border-0 shadow mt-7 ml-3" aria-labelledby={baseName}>
          <div className="row px-7 pb-5">
            <button type="button" className="close m-0 w-100 text-right" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <Field name={baseName+'[default_label]'} label="Default Label" component={Input} disabled={true} className="form-control" wrapperClasses="col-12 form-group"/>
            <Field name={baseName+'[custom_label]'} label="New Label" component={Input} className="form-control" wrapperClasses="col-12 form-group"/>
            <Field name={baseName + '[default_value]'} label="Default Value" component={Input} className="form-control" wrapperClasses="col-12 form-group" isModeOfNotificationDefaultVal={isModeOfNotificationDefaultVal} readOnly={defaultValueReadOnly} disabled={defaultValueDisabled }/>
            { !hideIsRequired &&  <Field name={baseName+'[required]'} label="Is required?" component={Checkbox} className="form-control" wrapperClasses="col-12 form-group ch"/>}
          </div>
        </div>
      </div>
    </span>
  )
}


export default FormTemplateFields;