import Toastr from "../../../components/Toastr"
const parent = 'SETTING_'

const DEFAULT_STATE = {
  recommendations: [],
  recommendation: {},
  meta: {pagination: {}},
  saving: false
}
let changes = null;
export default function recommendationReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let recommendations = [];
  let recommendationData = [];
  let updatedRecord = {};
  switch(action.type){
    case `${parent}RECOMMENDATIONS_LOAD_PENDING`:
    case `${parent}RECOMMENDATION_LOAD_PENDING`:
      return {...state, saving: false};

    case `${parent}RECOMMENDATION_CREATE_PENDING`:
    case `${parent}RECOMMENDATION_UPDATE_PENDING`:
      return {...state, saving: true};

    case `${parent}RECOMMENDATIONS_LOAD_REJECTED`:
    case `${parent}RECOMMENDATION_LOAD_REJECTED`:
    case `${parent}RECOMMENDATION_CREATE_REJECTED`:
    case `${parent}RECOMMENDATION_UPDATE_REJECTED`:
    case `${parent}RECOMMENDATION_DELETE_REJECTED`:
      Toastr.handleShow(response.data.success, response.data.error, response.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case `${parent}RECOMMENDATIONS_LOAD_FULFILLED`:
      changes = {
        ...response,
        saving: false
      };
      return {...state, ...changes};
    
    case `${parent}RECOMMENDATION_LOAD_FULFILLED`:
      changes = {
        recommendation: response.recommendation,
        saving: false
      }
      return {...state,...changes};
    
    case `${parent}RECOMMENDATION_CREATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      recommendations = [...state.recommendations]
      let newRecord = response.recommendation
      changes = {
        recommendations: [...recommendations, newRecord],
        editing: false,
        saving: false
      }
      return {...state, ...changes};

    case `${parent}RECOMMENDATION_UPDATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      recommendations = [...state.recommendations];
      updatedRecord = response.recommendation;
      for (const [index, recommendation] of recommendations.entries()) {
        if (recommendation.id === updatedRecord.id) {
          recommendationData = [
            ...recommendations.slice(0, index),
            updatedRecord,
            ...recommendations.slice(index + 1)
          ];
        }
      }
      changes = {
        recommendations: recommendationData,
        editing: false,
        saving: false
      };
      return { ...state, ...changes };

    case `${parent}RECOMMENDATION_DELETE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      recommendations = [...state.recommendations];
      for (const [index, recommendation] of recommendations.entries()) {
        if (recommendation.id === response.recommendation.id) {
          recommendations.splice(index, 1);
        }
      }
      return {
        ...state,
        recommendations: recommendations,
        error: null
      };
    case `${parent}RECOMMENDATION_REORDER`:
      changes = {
        recommendations: action.recommendations
      };
      return { ...state, ...changes };

    case `${parent}RECOMMENDATION_EDIT`:
      changes = {
        editing: {
          id: action.id,
        },
        recommendation: {}
      };
      return { ...state, ...changes };

    case `${parent}RECOMMENDATION_EDIT_CANCEL`:
      changes = {
        editing: false
      };
      return { ...state, ...changes };

    default:
      return state;
  }
}