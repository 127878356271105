import Toastr from "../../components/Toastr"

const DEFAULT_STATE = {
  notifications: [],
  meta: {pagination: {}},
  filters: {
        "sort": "created_at",
        "direction": "desc",
        "page": 1,
        "rpp": 10
    },
  saving: false
}
let changes = null;
export default function notificationReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let notifications = [];
  switch(action.type){
    case 'NOTIFICATIONS_LOAD_PENDING':
    case 'ORGANISARION_CREATE_PENDING':
      return {...state, saving: true };

    case 'NOTIFICATIONS_LOAD_REJECTED':
    case 'NOTIFICATION_CREATE_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case 'NOTIFICATIONS_LOAD_FULFILLED':
      if(response.meta.pagination.from===1){
        notifications = response.notifications
      }
      else {
        notifications = [...state.notifications, ...response.notifications]
      }
      changes = {
        ...response,
        notifications: notifications,
        saving: false
      };
      return { ...state, ...changes };

    case 'NOTIFICATION_CREATE_SUCCESS':
      changes = {
        notifications: [JSON.parse(response).notification, ...state.notifications],
        meta: { ...state.meta, unread_count: state.meta.unread_count + 1 },
        saving: false
      };
      return { ...state, ...changes }

    case 'NOTIFICATION_FILTER_CHANGE':
      return { ...state, ...action.filters };
    
    default:
      return state;
  }
}