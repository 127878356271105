import React, {Component} from 'react';
import { Field, FieldArray, change } from 'redux-form';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Input } from '../../../form/'
import AutoComplete from '../../../others/AutoComplete';
import grid_icon from '../../../../assets/static/images/group.png';

class ImpressionRecords extends Component {
  render() {
    const {fields, pprops, pprops:{impressions_attributes}, onDragEnd} = this.props
    return (
      <div className="card-body">
        <div className="row">
          <div className="col-12">
            <label>Search Impression</label>
            <AutoComplete onSelect={(val) => { pprops.onSubmit({ id: pprops.examId, impressions_attributes: [{ settings_impression_id: val }] }) }} klass="Settings::Impression" textField="name" valueField="id" placeholder="Type in to search by Impression Name or Code" wrapperClasses="col-6 px-0"/>
          </div>
          <div className="col-12 mt-7">
            <div className="overview-table px-2">
              <DragDropContext onDragEnd={(dnd)=> onDragEnd(dnd, "Exam::Impression", "impressions")}>
                <table className="table table-hover shadow-none">
                  <thead className="text-dark border-bottom bg-light border-white border-bottom-2">
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">No.</th>
                      <th scope="col">Impression</th>
                      <th scope="col">Code</th>
                      <th scope="col">Plan</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <Droppable droppableId="droppable">
                    {(provided) =>
                      <tbody ref={provided.innerRef}>
                        {fields.map((member, index) => (
                          <Draggable key={impressions_attributes[index].id} draggableId={`${impressions_attributes[index].id}`} index={index}>
                          {(provided, snapshot) => (
                            <tr ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps} style={{...provided.draggableProps.style}} key={`impression-${impressions_attributes[index].id}`} className="text-dark bg-light border-bottom border-white border-bottom-2">
                              <td><img src={grid_icon} alt="grid"/></td>
                                <td>{String.fromCharCode(index + 97).toUpperCase()}</td>
                              <td>{impressions_attributes[index].impression && impressions_attributes[index].impression.name}</td>
                              <td>{impressions_attributes[index].impression && impressions_attributes[index].impression.code}</td>
                              <td className="impression-field-width">
                                <Field name={`${member}[plan]`} onBlur={() => { setTimeout(() => pprops.submit(), 500) }} component={Input} wrapperClasses="form-group" className="form-control"/>
                              </td>
                              <td><a className="badge badge-pill badge-primary" onClick={() => { pprops.dispatch(change('procedureImpressionPlanForm',`${member}[_destroy]`,true)); setTimeout(() => pprops.submit(), 500) }}><i className="la la-trash"></i></a></td>
                            </tr>
                          )}
                      </Draggable>
                        ))}
                  </tbody> }
                    </Droppable>
                  </table>
                </DragDropContext>
                </div>
              </div>
            </div>
          </div>
    );
  }
}

const Impressions = props => {
  const {template, isTemplate, pprops, onDragEnd} = props
  return (
    <FieldArray name="impressions_attributes"
      props = {{
        template: template,
        isTemplate: isTemplate,
        pprops: pprops,
        onDragEnd: onDragEnd,
      }}
      component={ImpressionRecords} />
  );
}

export default Impressions;
