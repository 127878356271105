import React from 'react';
import { isPresentInArray, buildHashString, dateFormat } from '../../../../../itrust_common/components/HelperFunctions'

let EyeGlasses = props => {
  const { selectedExam, selectedIds, handleIdsSelect } = props;
  return (
    <div className="row">
      <div className="col-12 mt-7">
        { selectedExam && selectedExam.finalized_glasses_attributes && 
          <div className="overview-table px-2">
            { selectedExam.finalized_glasses_attributes.map((finalizedGlass, index)=>
              <table className="table table-hover shadow-none mb-6">
                <tbody>
                  <tr key={`finalizedGlass-${index}`} className="text-dark bg-light border-bottom border-white border-bottom-2">
                    <td><h6>{index+1}</h6></td>
                    <td colSpan="5"><h6>Issue Date: {dateFormat(finalizedGlass.issue_date)}</h6></td>
                    <td>
                      <div className="custom-control custom-checkbox">
                        <input 
                          onChange={()=> handleIdsSelect(selectedIds, finalizedGlass.id)}
                          type="checkbox"
                          checked={isPresentInArray(selectedIds, finalizedGlass.id)}
                          className="custom-control-input" id={`contact-lens-select-${finalizedGlass.id}`} />
                        <label className="custom-control-label" htmlFor={`contact-lens-select-${finalizedGlass.id}`}></label>
                      </div>
                    </td>
                  </tr>
                  <tr className="text-dark bg-light border-bottom border-white border-bottom-2">
                    <td></td>
                    <td><h6>EYE</h6></td>
                    <td><h6>SPH</h6></td>
                    <td><h6>CYL</h6></td>
                    <td><h6>AXIS</h6></td>
                    <td><h6>ADD</h6></td>
                    <td><h6>Prism</h6></td>
                  </tr>
                  <tr className="text-dark bg-light border-bottom border-white border-bottom-2">
                    <td></td>
                    <td>OD</td>
                    <td>{finalizedGlass.od_sphere}</td>
                    <td>{finalizedGlass.od_cylinder}</td>
                    <td>{finalizedGlass.od_axis}</td>
                    <td>{finalizedGlass.od_add}</td>
                    <td>{finalizedGlass.od_prism}</td>
                  </tr>
                  <tr className="text-dark bg-light border-bottom border-white border-bottom-2">
                    <td></td>
                    <td>OS</td>
                    <td>{finalizedGlass.os_sphere}</td>
                    <td>{finalizedGlass.os_cylinder}</td>
                    <td>{finalizedGlass.os_axis}</td>
                    <td>{finalizedGlass.os_add}</td>
                    <td>{finalizedGlass.os_prism}</td>
                  </tr>
                  <tr className="text-dark bg-light border-bottom border-white border-bottom-2">
                    <td></td>
                    <td><h6>Recommendations</h6></td>
                    <td colSpan="5">{finalizedGlass.recommendations && buildHashString(finalizedGlass.recommendations)}</td>
                  </tr>
                  <tr className="text-dark bg-light border-bottom border-white border-bottom-2">
                    <td></td>
                    <td><h6>Comments</h6></td>
                    <td colSpan="5">{finalizedGlass.notes}</td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        }
      </div>
    </div>
  )
}

export default EyeGlasses;