import React from 'react'
import { useDispatch } from 'react-redux'
import IncomingButtons from '../callingButtons/IncomingButtons'
import OutgoingButtons from '../callingButtons/OutgoingButtons'
import ActiveCallButtons from '../callingButtons/ActiveCallButtons'
import CallTimer from '../CallTimer'
import { humanize } from '../../../../../../itrust_common/components/HelperFunctions'

export default function PatientCalling(props) {
  const { contact, isIncomingCall, staffId, callInfo, makeOutGoingCall, call, isCallActive, isAnonymous, callInfo: { anonymous_call_logs }, telecomServiceType } = props
  const dispatch = useDispatch()

  const handleMakeOutGoingCall = (phoneNumber) => {
    makeOutGoingCall(phoneNumber)
  }

  return (
    <>
      <div className='d-flex flex-column align-items-center justify-content-center'>
        {/* <p className='text-secondary font-size-14 mb-0'>{call?.status()}</p> */}
        <p className='text-secondary font-size-14 mb-0'>{humanize(callInfo?.callStatus)}</p>
        {(callInfo.callStatus === 'call_started' || callInfo.callStatus === 'call_ended') &&
          <CallTimer callStatus={callInfo.callStatus} />
        }
        <div className='font-size-20 m-4 d-flex justify-content-center align-items-center'>
          {isIncomingCall ?
            <IncomingButtons call={call} callInfo={callInfo} dispatch={dispatch} handleMakeOutGoingCall={() => handleMakeOutGoingCall()} isCallActive={isCallActive} staffId={staffId} isAnonymous={isAnonymous} telecomServiceType={telecomServiceType} /> :
            <OutgoingButtons call={call} dispatch={dispatch} handleMakeOutGoingCall={(phoneNumber) => handleMakeOutGoingCall(phoneNumber)} callInfo={callInfo} contact={contact} isCallActive={isCallActive} isAnonymous={isAnonymous} telecomServiceType={telecomServiceType} />
          }
          {/* {(call?.status() === 'open' || call?.status() === 'reconnecting') && <ActiveCallButtons call={call}/>} */}
          {(callInfo.callStatus === 'call_started' || callInfo.callStatus === 'ringing') && <ActiveCallButtons call={call} telecomServiceType={telecomServiceType} />}
        </div>
      </div>
      <div className='font-size-16 font-weight-500 text-primary d-flex justify-content-around mt-2 px-8'>
        <span className='mr-4'><i className="las la-arrow-up rotate-45 font-size-18 text-primary"></i>
          {isAnonymous ? anonymous_call_logs?.outgoing_calls : contact.outgoing_calls}
        </span>
        <span className='mr-4'><i className="las la-arrow-up rotate-220 font-size-18 text-success"></i>
          {isAnonymous ? anonymous_call_logs?.incoming_calls : contact.incoming_calls}
        </span>
        <span className='mr-4'><i className="las la-level-up-alt rotate-45 font-size-18 text-danger"></i>
          {isAnonymous ? anonymous_call_logs?.missed_calls : contact.missed_calls}
        </span>
      </div>
    </>
  )
}