import React, { Component } from 'react'
import { Field } from 'redux-form';
import _ from 'lodash';
import {AutoCompleteSelect} from '../form'

import {CountriesStates} from '../CountriesStates';

class CountryStateFields extends Component {
  constructor(props) {
    super(props);
    this.countrySelect = React.createRef();
    this.state = {
      country: props.selectedCountry
    };
  }
  
  render() {
    const countryData  = _.find(CountriesStates, { countryName: this.state.country })
    const regions = countryData ? countryData.regions : []
    const {
      countryFieldName, stateFieldName, countryFieldLabel, stateFieldLabel, countryFieldWrapperClasses, stateFieldWrapperClasses
    } = this.props;
    return(
      [<Field
        name={countryFieldName} 
        component={AutoCompleteSelect} 
        data={CountriesStates}  
        textField="countryName" 
        valueField= "countryName" 
        placeholder="Placeholder" 
        wrapperClasses={`error autocomplete ${countryFieldWrapperClasses}`}
        ref={this.countrySelect}
        label={countryFieldLabel}/>,
      <Field
        name={stateFieldName}
        component={AutoCompleteSelect} 
        data={regions} 
        textField="name" 
        valueField= "name" 
        placeholder="Placeholder" 
        wrapperClasses={`error autocomplete ${stateFieldWrapperClasses}`}
        label={stateFieldLabel}/>]
    )
  }
}

export default CountryStateFields


