import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { contactLoad } from '../../../../../redux/actions/addOns/contactListsAction'
import { patientMessagesLoad } from '../../../../../redux/actions/patient/patientMessageActions'
import { callPopUpEdit, voipEdit } from '../../../../../redux/actions/addOns/voip/voipActions'
import itrustCrownImg from '../../../../../assets/static/images/itrust_crown.svg'
import { patientWhatsAppLogsLoad, whatsAppAccountLoad, whatsAppEdit, whatsAppPatientLoad, whatsAppProfileLoad } from '../../../../../redux/actions/addOns/whatsApp/whatsAppActions'
import PatientAddOnsHeader from './PatientAddOnsHeader'

function PatientProfileAddOns(props) {
  const { filters, voipConfig, twoWayMessageConfig, whatsAppConfig, userType, patient, wrapperClasses, storeWhatsAppNumber, patientProfile, callPopUpStatus, organisationId, btnClasses, isPopUp, type, whatsAppMinimizeView, voipMinimizeView, hideMinimizeBtn, hideAddOnsBtn, prevTab, isListingIcons } = props
  const dispatch = useDispatch()

  async function handleCallMessagePatientLoad(patientId, editType) {
    patientProfile && dispatch(whatsAppEdit({ patientProfile: false }))
    if (editType === 'patient_message') {
      let filter = { ...filters, filter: {}, sort: "send_at", direction: 'asc', rpp: 999999999, include: ['patient', 'store', 'staff','message_template'] }
      await dispatch(contactLoad(patientId, { include: '*' }))
      await dispatch(patientMessagesLoad({ ...filter, patient_id: patientId }))
      dispatch(callPopUpEdit(editType))
    }
    else {
      await dispatch(contactLoad(patientId, { include: '*' }))
      dispatch(callPopUpEdit(editType))
    }
  }

  const handleWhatsAppLoad = async () => {
    callPopUpStatus && dispatch(callPopUpEdit(false))
    await dispatch(whatsAppPatientLoad(patient.id))
    let whatsAppFilter = { sort: 'created_at', rpp: 99999999999999, page: 1, filter: {}, search: '', direction: 'asc', patient_id: patient.id }
    await dispatch(patientWhatsAppLogsLoad(whatsAppFilter))
    if (!storeWhatsAppNumber) {
      await dispatch(whatsAppProfileLoad({ store_id: localStorage.getItem('StoreID'), category: "whatsapp", include: '*' }))
      await dispatch(whatsAppAccountLoad({ organisation_id: organisationId }))
    }
    dispatch(whatsAppEdit({ patientProfile: true }))
  }

  const handleBackMove=()=>{
    dispatch(callPopUpEdit('store_calling'))
    dispatch(voipEdit({ activePopupTab: prevTab }))
  }

  return (
    <div className={`d-flex align-items-center ${wrapperClasses}`}>
      <div>
        {(isPopUp && prevTab) &&
          <button className={`${btnClasses} ${!isPopUp && 'ml-4'}`} onClick={() => handleBackMove()}>
            <i className='las la-arrow-left' />
          </button>
        }
        {(!hideAddOnsBtn) && <>
          {(userType !== 'Patient' && voipConfig?.visible && type !== 'voip') &&
            <button className={`${btnClasses} ${!isPopUp && 'position-relative'}`} onClick={() => handleCallMessagePatientLoad(patient.id, 'patient')}>
              <i className='las la-phone' />
              {!(isPopUp || isListingIcons) && <img src={itrustCrownImg} alt='Crown' className='font-size-18 position-absolute crown-align' />}
            </button>
          }
          {(twoWayMessageConfig?.visible && type !== 'two_way_message') &&
            <button className={`${btnClasses} ${!(isPopUp || isListingIcons) && 'ml-4'}`} onClick={() => handleCallMessagePatientLoad(patient.id, 'patient_message')}>
              <i className='las la-comment-dots' />
            </button>
          }
          {(whatsAppConfig?.visible && userType !== 'Patient' && type !== 'whatsapp') && <>
            <button className={`${btnClasses} ${!(isPopUp || isListingIcons) && 'ml-4'}`} onClick={() => handleWhatsAppLoad()}>
              <i className='lab la-whatsapp' />
            </button>
          </>}
        </>}
      </div>
      <div>
        {isPopUp && <PatientAddOnsHeader type={type} minimizeView={whatsAppMinimizeView || voipMinimizeView} callPopUpStatus={callPopUpStatus} hideMinimizeBtn={hideMinimizeBtn} />}
      </div>
    </div>
  )
}


const mapStateToProps = state => {
  const { patient, auth, organisation: { organisation }, filter, whatsApp, voip } = state;
  return {
    filters: filter.filters,
    userType: auth.user?.type,
    voipConfig: organisation?.add_ons_config?.type?.voip,
    twoWayMessageConfig: organisation?.add_ons_config?.type?.two_way_message,
    whatsAppConfig: organisation.add_ons_config?.type?.whatsapp,
    storeWhatsAppNumber: whatsApp.profile_info?.phone_number,
    patientProfile: whatsApp.patientProfile,
    callPopUpStatus: voip?.call_pop_up_status,
    organisationId: organisation.id,
    whatsAppMinimizeView: whatsApp.minimizeView,
    voipMinimizeView: voip.minimizeView,
    prevTab: voip?.editing?.prevTab
  }
}
export default connect(mapStateToProps)(PatientProfileAddOns);