import React from 'react'
import EyeMedFormButton from '../EyeMedFormButton'
import { Field } from 'redux-form'
import { humanize } from '../../../../../../../../../itrust_common/components/HelperFunctions'
import { AutoCompleteSelect, Textarea } from '../../../../../../../../../itrust_common/components/form'

export default function ContactLensClaim(props) {
  const { activeTab, claimFormTemplate } = props

  const RenderContactLensFields = (props) => {
    const { field } = props
    switch (field.type) {
      case "text_area":
        return <Field name={`${field.label}`} label={`${humanize(field.label)}`} component={Textarea} className="form-control" wrapperClasses="form-group col-12" />

      default:
        return <Field
          name={`${field.label}.value`}
          label={`${humanize(field.label)}`}
          component={AutoCompleteSelect}
          data={field.options}
          textField="label"
          valueField="value"
          hideSearch={true}
          wrapperClasses={`form-group mb-8 col-6`}
        />
    }

  }

  return (
    <div className='row'>
      <div className='col-10'>
        <div className='bg-white p-6 border rounded'>
          <h6>Contact Lens Fir and Follow-Up</h6>
          <p>Complete the information about the patient’s eye exam below. Remember, disease diagnosis codes are required.</p>
          <div className='row'>
            {claimFormTemplate?.config?.contact_lens_fit_and_follow_up.map((field) => <RenderContactLensFields field={field} />)}
          </div>
          <EyeMedFormButton isContactLens={true} />
        </div>
      </div>
      <div className='col-2'>
        <div className='bg-white border rounded-lg'>
          <div className='d-flex flex-column align-items-center'>
            <span className='py-5 px-6 w-100 text-primary'>Contact Lens</span>
          </div>
        </div>
      </div>
    </div>
  )
}
