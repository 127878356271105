import React from 'react'
import { connect } from 'react-redux'
import TwoWayFeaturesImg from '../../../../assets/static/images/two_way_feature.svg'
import TwoWayMessageImg from '../../../../assets/static/images/two_way_message.svg'
import AdvertisementWrap from '../other/AdvertisementWrap'

function TwoWayAdvertisement(props) {
  const { isPopupScreen, organisationName } = props

  const twoWayFeature = [
    'Never miss a patient‘s phone call with our voip phone system.',
    "Our Phone POP feature keeps your office up to speed on the patient with real time patient.",
    'Flexibility for your business to use a virtual phone, physical phone or both.',
    'Have your own dedicated business phone line using your current or new number.',
  ]

  const twoWayAdvDetail = {
    title: 'Two Way Message',
    redirectTo: 'https://www.itrust.io',
    imageAddOn: TwoWayMessageImg,
    imageFeatures: TwoWayFeaturesImg,
    isPopupScreenAddOnImg: TwoWayMessageImg,
    features: twoWayFeature
  }

  return (
    <AdvertisementWrap addOnAdvDetail={twoWayAdvDetail} isPopupScreen={isPopupScreen} organisationName={organisationName} />
  )
}

const mapStateToProps = (state) => {
  const { organisation: { organisation } } = state
  return {
    organisationName: organisation.name
  }
}

export default connect(mapStateToProps)(TwoWayAdvertisement)

