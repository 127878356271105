import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { callPopUpEdit } from '../../../../redux/actions/addOns/voip/voipActions'
import { contactLoad } from '../../../../redux/actions/addOns/contactListsAction'
import NotificationsListingWrapper from '../../notification/NotificationsListingWrapper'
import { patientMessagesLoad } from '../../../../redux/actions/patient/patientMessageActions'
import { twoWayNotificationLoad, twoWayNotificationUpdate } from '../../../../redux/actions/addOns/twoWayMessaging/twoWayReportAction'
import TwoWayAdvertisement from './TwoWayAdvertisement'
import { notificationUpdate } from '../../../../itrust_common/redux/actions/notificationActions'

function TwoWayNotificationList(props) {
  const { twoWayNotifications, saving, totalRecords, unread_count, twoWayMessageConfig } = props
  const dispatch = useDispatch()

  const handleTwoWayNotificationLoad = (filters) => {
    dispatch(twoWayNotificationLoad({ activity_type: 'patient_sms_received', ...filters, include: ['activity'] }))
  }

  const handleViewPatientMessage = async (patientId) => {
    let filter = { sort: "send_at", direction: 'asc', rpp: 999999999, search: "", page: 1, filter: {}, include: ['patient', 'store', 'staff','message_template'] }
    await dispatch(contactLoad(patientId, { include: '*' }))
    await dispatch(patientMessagesLoad({ ...filter, patient_id: patientId }))
    dispatch(callPopUpEdit('patient_message'))
  }

  return (
    <>
      <NotificationsListingWrapper
        saving={saving}
        id='twoWayMessage'
        header={"Two Way Message"}
        totalRecords={totalRecords}
        unread_count={unread_count}
        notifications={twoWayNotifications}
        iconClasses={'las la-comment-dots'}
        handleMessageView={handleViewPatientMessage}
        NotificationAdvertisement={TwoWayAdvertisement}
        logsRoute={'/add-ons/two-way-message/sms-logs'}
        handleNotificationLoad={handleTwoWayNotificationLoad}
        isAddonActive={twoWayMessageConfig?.status !== 'inactive'}
        handleNotificationUpdate={twoWayNotificationUpdate}
      />
    </>
  )
}

const mapStateToProps = (state) => {
  const { twoWayReport, organisation: { organisation } } = state
  return {
    twoWayMessageConfig: organisation?.add_ons_config?.type?.two_way_message,
    twoWayNotifications: twoWayReport.notifications,
    totalRecords: twoWayReport.meta.pagination.count,
    unread_count: twoWayReport.meta.unread_count,
    saving: twoWayReport.saving,
  }
}

export default connect(mapStateToProps)(TwoWayNotificationList)