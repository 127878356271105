import React from 'react'
import { useDispatch } from 'react-redux';
import { duplicateOrderCreate } from '../../../redux/actions/order/orderActions';
import Alert from '../../../itrust_common/components/Alert';

export default function DuplicateOrderButton(props) {
  const { orderId, children } = props;
  const dispatch = useDispatch()

  const handleDuplicateOrderCreate = orderId => {
    Alert.confirm(<div>Are you sure you want to create a duplicate order. </div>,
      (res) => {
        if (res) {
          dispatch(duplicateOrderCreate({ id: orderId, include: '*' }))
        }
      }
    )
  }

  return (
    <span>
      <span
        className="pointer"
        onClick={() => handleDuplicateOrderCreate(orderId)}
      >
        {children}
      </span>
    </span>
  )
}