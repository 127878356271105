import Toastr from "../../../itrust_common/components/Toastr"
const DEFAULT_STATE = {
  patient_messages: [],
  patient_message: {},
  meta: { pagination: {} },
  saving: false
}
let changes = null;
export default function patientMessageReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let patientMessages = [];
  switch(action.type){
    case 'PATIENT_MESSAGES_LOAD_PENDING':
    case 'PATIENT_MESSAGE_LOAD_PENDING':
      return {...state, saving: true};

    case 'PATIENT_MESSAGE_CREATE_PENDING':
      return {...state, saving: true};

    case 'PATIENT_MESSAGES_LOAD_REJECTED':
    case 'PATIENT_MESSAGE_LOAD_REJECTED':
    case 'PATIENT_MESSAGE_CREATE_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case 'PATIENT_MESSAGES_LOAD_FULFILLED':
      changes = {
        patient_messages: response.sms_logs,
        saving: false
      };
      return {...state, ...changes};
    
    case 'PATIENT_MESSAGE_LOAD_FULFILLED':
      changes = {
        patient_message: response.sms_log,
        saving: false
      }
      return {...state,...changes};
    
    case 'PATIENT_MESSAGE_CREATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      patientMessages = [...state.patient_messages]
      let newRecord = response.sms_log
      changes = {
        patient_messages: [...patientMessages, newRecord],
        patient_message: newRecord,
        editing: false,
        saving: false
      }
      return {...state, ...changes};
    
    default:
      return state;
  }
}