import React, {Component} from 'react';
import {connect} from 'react-redux';
import { reset } from 'redux-form'
import MedicalHistoryFormWrapper from '../../../components/authorised/patient/medicalHistoryForms/MedicalHistoryFormWrapper.js';
import LastExamForm from '../../../components/authorised/patient/medicalHistoryForms/LastExamForm.js';
import NeurologicalForm from '../../../components/authorised/patient/medicalHistoryForms/NeurologicalForm.js';
import {patientMedicalHistoriesLoad, patientMedicalHistoryCreate, patientMedicalHistoryUpdate, patientMedicalHistoryDelete, patientMedicalHistoryDeleteAll} from '../../../redux/actions/patient/patientMedicalHistoryActions';
import {examLoad, examUpdate} from '../../../redux/actions/exam/examActions';
import {patientUpdate} from '../../../redux/actions/patient/patientActions';
import {filterReset} from '../../../itrust_common/redux/actions/filterActions';
import Alert from '../../../itrust_common/components/Alert.js';

class PatientMedicalHistory extends Component {
  constructor(props){
    super(props);
    var filters = { sort: 'created_at', direction: 'desc', page: 1, rpp: 100}
    this.handlePatientMedicalHistoriesLoad({...filters, exam_id: props.examId});
  }

  handlePatientMedicalHistoriesLoad(filters){
    this.props.dispatch(patientMedicalHistoriesLoad(this.props.patientId, filters));
  }

  handlePatientMedicalHistoryDelete(id){
    Alert.deleteConfirm("Are you sure? You want to delete this record.",
    (response)=>{
      if(response)
        this.props.dispatch(patientMedicalHistoryDelete(this.props.patientId, id))
    })
  }

  handlePatientMedicalHistoryDeleteAll(medicalHistoryCategory){
    Alert.deleteConfirm("Are you sure? You want to delete all record.",
    (response)=>{
      if(response)
        this.props.dispatch(patientMedicalHistoryDeleteAll(this.props.patientId, {exam_id: this.props.examId, medical_history_category: medicalHistoryCategory}));
    })
  }

  handlePatientMedicalHistorySubmit(data) {
    const id = data.id || '';
    var patientId = this.props.patientId
    if (id === '') {
      this.props.dispatch(patientMedicalHistoryCreate(patientId, {exam_examination_id: this.props.examId, ...data}));
    } else {
      this.props.dispatch(patientMedicalHistoryUpdate(patientId, {exam_examination_id: this.props.examId, ...data}));
    }
  }

  handleNeurologicalFormSubmit(data){
    if(this.props.examId)
      this.props.dispatch(examUpdate(data, {include: '*'}))
    else
      this.handlePatientSubmit(data)
  }

  async handlePatientSubmit(data) {
    const { examId } = this.props
    await this.props.dispatch(patientUpdate(data))
    examId && this.props.dispatch(examLoad(examId, { include: "*" }))
  }

  componentDidUpdate() {
    if (!this.props.patientMedicalHistoryEditing){
      this.props.dispatch(reset('patientMedicalHistoryForm'))
    }
  }
  componentWillUnmount(){
    this.props.dispatch(filterReset());
  }
  render() {
    const {examId, patientMedicalHistories, patientMedicalHistoriesMeta, examEditing } = this.props
    return(
        <div>
          { !examEditing && !examEditing.id && patientMedicalHistoriesMeta.has_pending_exam &&
            <div className="d-flex align-items-center pb-5">
              <i className="la la-warning text-danger mr-2"></i>
              <h6>This patient has an active unfinished exam. Syncing to patient profile changes must be made on the exam, and then finalized.</h6>
            </div>
          }
        <div className={!examEditing && !examEditing.id && patientMedicalHistoriesMeta.has_pending_exam ? "row disabled" : "row"}>
            <MedicalHistoryFormWrapper
              medicalHistoryCategory="medical_history"
              diseaseType="medical_history"
              belongsToId="settings_disease_id"
              klass="Settings::Disease"
              records={patientMedicalHistories.medical_history}
              handleSubmit={this.handlePatientMedicalHistorySubmit.bind(this)}
              handleDelete={this.handlePatientMedicalHistoryDelete.bind(this)}
              handleDeleteAll={this.handlePatientMedicalHistoryDeleteAll.bind(this)}
              name="Patient Medical History"
              isFamily={false}
              isDatesVisible={true} />
            <MedicalHistoryFormWrapper
              medicalHistoryCategory="family_medical_history"
              diseaseType="medical_history"
              belongsToId="settings_disease_id"
              klass="Settings::Disease"
              records={patientMedicalHistories.family_medical_history}
              handleSubmit={this.handlePatientMedicalHistorySubmit.bind(this)}
              handleDelete={this.handlePatientMedicalHistoryDelete.bind(this)}
              handleDeleteAll={this.handlePatientMedicalHistoryDeleteAll.bind(this)}
              name="Family Medical History"
              isFamily={true}
              isDatesVisible={true} />
            <MedicalHistoryFormWrapper
              medicalHistoryCategory="ocular_history"
              diseaseType="ocular_history"
              belongsToId="settings_disease_id"
              klass="Settings::Disease"
              records={patientMedicalHistories.ocular_history}
              handleSubmit={this.handlePatientMedicalHistorySubmit.bind(this)}
              handleDelete={this.handlePatientMedicalHistoryDelete.bind(this)}
              handleDeleteAll={this.handlePatientMedicalHistoryDeleteAll.bind(this)}
              name="Patient Ocular History"
              isFamily={false}
              isDatesVisible={true} />
            <MedicalHistoryFormWrapper
              medicalHistoryCategory="family_ocular_history"
              diseaseType="ocular_history"
              belongsToId="settings_disease_id"
              klass="Settings::Disease"
              records={patientMedicalHistories.family_ocular_history}
              handleSubmit={this.handlePatientMedicalHistorySubmit.bind(this)}
              handleDelete={this.handlePatientMedicalHistoryDelete.bind(this)}
              handleDeleteAll={this.handlePatientMedicalHistoryDeleteAll.bind(this)}
              name="Family Ocular History"
              isFamily={true}
              isDatesVisible={true} />

            <MedicalHistoryFormWrapper
              medicalHistoryCategory="allergy"
              diseaseType="allergy"
              belongsToId="settings_disease_id"
              klass="Settings::Disease"
              records={patientMedicalHistories.allergy}
              handleSubmit={this.handlePatientMedicalHistorySubmit.bind(this)}
              handleDelete={this.handlePatientMedicalHistoryDelete.bind(this)}
              handleDeleteAll={this.handlePatientMedicalHistoryDeleteAll.bind(this)}
              name="Patient Allergies"
              isFamily={false}
              isDatesVisible={false} />
            <MedicalHistoryFormWrapper
              medicalHistoryCategory="surgery"
              diseaseType="surgery"
              belongsToId="settings_disease_id"
              klass="Settings::Disease"
              records={patientMedicalHistories.surgery}
              handleSubmit={this.handlePatientMedicalHistorySubmit.bind(this)}
              handleDelete={this.handlePatientMedicalHistoryDelete.bind(this)}
              handleDeleteAll={this.handlePatientMedicalHistoryDeleteAll.bind(this)}
              name="Patient Surgical History"
              isFamily={false}
              isDatesVisible={false} />

            <MedicalHistoryFormWrapper
              medicalHistoryCategory="medication"
              diseaseType=""
              belongsToId="settings_medication_id"
              klass="Settings::Medication"
              records={patientMedicalHistories.medication}
              handleSubmit={this.handlePatientMedicalHistorySubmit.bind(this)}
              handleDelete={this.handlePatientMedicalHistoryDelete.bind(this)}
              handleDeleteAll={this.handlePatientMedicalHistoryDeleteAll.bind(this)}
              name="Patient Medications"
              isFamily={false}
              isDatesVisible={false} />

            <NeurologicalForm examId={examId} onSubmit={this.handleNeurologicalFormSubmit.bind(this)} />
            <LastExamForm onSubmit={this.handlePatientSubmit.bind(this)} />
          </div>
        </div>
    );
  }
}

const mapStateToProps = state => {
  const {patientMedicalHistory, filter, exam} = state;
  return {
    patientMedicalHistories: patientMedicalHistory.patient_medical_histories,
    patientMedicalHistoriesMeta: patientMedicalHistory.meta,
    saving: patientMedicalHistory.saving,
    patientMedicalHistoryEditing: patientMedicalHistory.editing,
    examEditing: exam.editing,
    filters: filter.filters
  }
}

export default connect(mapStateToProps)(PatientMedicalHistory);
