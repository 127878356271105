import Toastr from "../../../components/Toastr"
const parent = 'SETTING_'

const DEFAULT_STATE = {
  dosages: [],
  dosage: {},
  meta: {pagination: {}},
  saving: false
}
let changes = null;
export default function dosageReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let dosages = [];
  let dosageData = [];
  let updatedRecord = {};
  switch(action.type){
    case `${parent}DOSAGES_LOAD_PENDING`:
    case `${parent}DOSAGE_LOAD_PENDING`:
      return {...state, saving: false};

    case `${parent}DOSAGE_CREATE_PENDING`:
    case `${parent}DOSAGE_UPDATE_PENDING`:
      return {...state, saving: true};

    case `${parent}DOSAGES_LOAD_REJECTED`:
    case `${parent}DOSAGE_LOAD_REJECTED`:
    case `${parent}DOSAGE_CREATE_REJECTED`:
    case `${parent}DOSAGE_UPDATE_REJECTED`:
    case `${parent}DOSAGE_DELETE_REJECTED`:
      Toastr.handleShow(response.data.success, response.data.error, response.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case `${parent}DOSAGES_LOAD_FULFILLED`:
      changes = {
        ...response,
        saving: false
      };
      return {...state, ...changes};
    
    case `${parent}DOSAGE_LOAD_FULFILLED`:
      changes = {
        dosage: response.dosage,
        saving: false
      }
      return {...state,...changes};
    
    case `${parent}DOSAGE_CREATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      dosages = [...state.dosages]
      let newRecord = response.dosage
      changes = {
        dosages: [...dosages, newRecord],
        editing: false,
        saving: false
      }
      return {...state, ...changes};

    case `${parent}DOSAGE_UPDATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      dosages = [...state.dosages];
      updatedRecord = response.dosage;
      for (const [index, dosage] of dosages.entries()) {
        if (dosage.id === updatedRecord.id) {
          dosageData = [
            ...dosages.slice(0, index),
            updatedRecord,
            ...dosages.slice(index + 1)
          ];
        }
      }
      changes = {
        dosages: dosageData,
        editing: false,
        saving: false
      };
      return { ...state, ...changes };

    case `${parent}DOSAGE_DELETE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      dosages = [...state.dosages];
      for (const [index, dosage] of dosages.entries()) {
        if (dosage.id === response.dosage.id) {
          dosages.splice(index, 1);
        }
      }
      return {
        ...state,
        dosages: dosages,
        error: null
      };

    case `${parent}DOSAGE_EDIT`:
      changes = {
        editing: {
          id: action.id,
        },
        dosage: {  }
      };
      return { ...state, ...changes };

    case `${parent}DOSAGE_EDIT_CANCEL`:
      changes = {
        editing: false
      };
      return { ...state, ...changes };

    default:
      return state;
  }
}