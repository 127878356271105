import React, { useEffect, useState } from 'react'
import { Field, change, formValueSelector } from 'redux-form'
import { connect, useDispatch } from 'react-redux'
import { MODE_OF_NOTIFICATIONS } from '../Constants'
import { required } from './FormValidations'

function PatientModeOfNotification(props) {
  const { orgWhatsAppConfig, publicOrgWhatsAppConfig, form, wrapperClasses, label, isTemplate, patientModeOfNotification, appointmentModeOfNotification, isRequired, templateModeOfNotification, patientTemplateModeOfNotification } = props
  const formName = props.formName ? props.formName : isTemplate ? 'patientTemplateForm' : 'patientForm'
  const fieldName = isTemplate ? "personal_information[basic_details][fields][mode_of_notification_type][default_value].all_notifications" : 'mode_of_notification_type.all_notifications';
  const noneFieldName = isTemplate ? "personal_information[basic_details][fields][mode_of_notification_type][default_value].no_notifications" : 'mode_of_notification_type.no_notifications';
  const whatsAppConfig = orgWhatsAppConfig ?? publicOrgWhatsAppConfig
  const [isFieldChecked, setIsFieldChecked] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    handleUpdateAllNotification()
  }, [patientModeOfNotification, appointmentModeOfNotification, patientTemplateModeOfNotification, templateModeOfNotification])

  useEffect(() => {
    if (!isTemplate) {
      let objName = formName == 'appointmentForm' ? appointmentModeOfNotification : patientModeOfNotification;
      handleCheckIsFieldChecked(objName)
    }
  }, [patientModeOfNotification, appointmentModeOfNotification])

  const handleCheckIsFieldChecked = (objName) => {
    const isWhatsAppActive = (whatsAppConfig?.status && whatsAppConfig?.status !== 'inactive');
    let isFieldAnyFieldChecked;
    if (!isWhatsAppActive) {
      isFieldAnyFieldChecked = objName && Object.keys(objName).some(key => objName[key] === true && key !== 'whatsapp');
    }
    else {
      isFieldAnyFieldChecked = objName && Object.keys(objName).some(key => objName[key] === true);
    }
    setIsFieldChecked(isFieldAnyFieldChecked)
  }

  const handleUpdateAllNotification = () => {
    const objName = isTemplate ? patientTemplateModeOfNotification : (formName === 'appointmentForm' ? appointmentModeOfNotification : patientModeOfNotification);
    const isWhatsAppActive = (whatsAppConfig?.status && whatsAppConfig?.status !== 'inactive');
    if (objName) {
      if (Object.keys(objName).length == 2) {
        objName.all_notifications !== false && dispatch(change(formName, fieldName, false));
      }
      else {
        let keys = Object.keys(objName).filter(key => key !== 'all_notifications' && key !== 'no_notifications');

        if (!isWhatsAppActive) {
          keys = keys.filter(key => key !== 'whatsapp');
        }
        const isAnyFalse = keys.some(key => objName[key] === false);
        const isEveryFalse = keys.every(key => objName[key] === false);
        const currentValue = !isAnyFalse;
        // Only dispatch if the current state is different
        if (currentValue !== objName['all_notifications']) {
          dispatch(change(formName, fieldName, currentValue));
        }
        if (isEveryFalse !== objName['no_notifications']) {
          dispatch(change(formName, noneFieldName, isEveryFalse));
        }
      }
    }
  };

  const handleModOfNotificationChange = (e) => {
    if (e.target.name == fieldName) {
      handleAllModOfNotificationChange(e.target.checked)
    }
    else if (e.target.name == noneFieldName && e.target.checked) {
      handleAllModOfNotificationChange(!e.target.checked)
    }
    else {
      const isWhatsAppActive = whatsAppConfig?.status && whatsAppConfig?.status !== 'inactive';
      const arr = isWhatsAppActive ? ['whatsapp', 'email', 'sms'] : ['email', 'sms'];
      const targetMode = e.target.name.split('.')[1];
      const index = arr.indexOf(targetMode);
      if (index !== -1) arr.splice(index, 1);
      let formVal = form[formName].values
      formVal = getValueFromPath(formVal, props.name);
      const allNotificationsStatus = arr.every(mode => formVal[mode]) && e.target.checked;
      dispatch(change(formName, `${props.name}.all_notifications`, allNotificationsStatus));
    }
  }

  const getValueFromPath = (obj, path) => {
    const keys = path.replace(/\[|\]/g, '.').split('.').filter(key => key);
    return keys.reduce((acc, key) => acc && acc[key], obj);
  }


  const handleAllModOfNotificationChange = (val) => {
    dispatch(change(formName, `${props.name}.email`, val));
    dispatch(change(formName, `${props.name}.sms`, val));
    whatsAppConfig?.status && whatsAppConfig?.status !== 'inactive' && dispatch(change(formName, `${props.name}.whatsapp`, val));
  }

  const getRequiredValidation = () => {
    if (!isTemplate && isRequired && !isFieldChecked) {
      return [required]
    }
    else {
      return ''
    }
  }

  return (
    <div className={`${wrapperClasses} patient-form-field-target`} id='mode-of-notifications-field'>
      {label !== undefined && <label className="mb-2 d-block">{label}</label>}
      <div className={`btn-group btn-group-toggle btn-group mode-of-notification-wrap`} >
        {MODE_OF_NOTIFICATIONS.map(item => <>
          {((item.value == 'whatsapp' && whatsAppConfig?.status && whatsAppConfig?.status !== 'inactive') || item.value != 'whatsapp') &&
            <Field component={Checkbox} name={`${props.name}.${item.value}`} label={item.label} onChange={handleModOfNotificationChange} validate={getRequiredValidation()} wrapperClasses="btn btn-sm" />}
        </>)}
      </div>
      {getRequiredValidation() && <span className="error-msg text-danger"><br />Required <i className="las la-info-circle ml-2 font-size-20 mt-3"></i></span>}
    </div>
  )
}

const Checkbox = (props) => {
  const { id, input, disabled, wrapperClasses, className } = props
  const forId = `${input.name}-${input.value}-${id}`

  return (
    <div className={`${disabled && 'disabled'} ${wrapperClasses} ${input.value ? 'btn-primary ' : 'btn-outline-primary'}`}>
      <div className={`custom-control custom-checkbox mode-of-notification pl-3`}>
        <input className={`custom-control-input ${className}`} type="checkbox" {...input} id={forId} checked={input.value} />
        <label className="custom-control-label pr-3" htmlFor={forId}>
          {props.label}
        </label>
      </div>
    </div>
  );
}

const patientForm = formValueSelector('patientForm')
const appointmentForm = formValueSelector('appointmentForm')
const patientTemplateForm = formValueSelector('patientTemplateForm')

const mapStateToProps = (state) => {
  const { organisation: { organisation }, publicOrganisation, form, setting } = state
  const patientModeOfNotification = patientForm(state, 'mode_of_notification_type')
  const appointmentModeOfNotification = appointmentForm(state, 'mode_of_notification_type')
  const patientTemplateModeOfNotification = patientTemplateForm(state, 'personal_information[basic_details][fields][mode_of_notification_type][default_value]')

  return {
    form,
    patientModeOfNotification,
    appointmentModeOfNotification,
    patientTemplateModeOfNotification,
    orgWhatsAppConfig: organisation.add_ons_config?.type?.whatsapp,
    publicOrgWhatsAppConfig: publicOrganisation?.current_organisation.whatsapp_config,
    isRequired: setting.patientTemplate.patient_template?.personal_information?.basic_details?.fields?.mode_of_notification_type?.required,
    templateModeOfNotification: setting.patientTemplate.patient_template?.personal_information?.basic_details?.fields?.mode_of_notification_type.default_value,
  }
}

export default connect(mapStateToProps)(PatientModeOfNotification)