import React from 'react';

let SaveButton = props => {
  const { saving, buttonClasses, wrapperClasses, handleOnClick, label, buttonType } = props;
  return (
    <div className={wrapperClasses}>
      {saving ?
        <button className={`btn btn-primary d-flex align-items-center ${buttonClasses}`} type="submit">
          <span className="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>
          <span>Saving...</span>
        </button>
        :
        <button type={buttonType ? buttonType : 'submit'} className={`btn btn-primary ${buttonClasses}`} onClick={handleOnClick} aria-disabled="true">{label ? label : "Save"}</button>
      }
    </div>
  )
}

export default SaveButton;