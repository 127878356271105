import React from 'react';
import { Notifier } from '@airbrake/browser';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    if(process.env.REACT_APP_ENV === "PRODUCTION"){
      this.airbrake = new Notifier({
        projectId: process.env.REACT_APP_AIRBRAKE_PROJECT_ID,
        projectKey: process.env.REACT_APP_AIRBRAKE_PROJECT_KEY
      });
    }
  }
  
  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // Send error to Airbrake
    if(process.env.REACT_APP_ENV === "PRODUCTION"){
      this.airbrake.notify({
        error: error,
        params: {info: info}
      });
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
