import React from 'react';
import DisplayField from '../../../../../itrust_common/components/others/DisplayField'

let Keratometry = props => {
  const { fieldsValues, template} = props;
  return (
    <div className="row">
      <div className="col-12 py-4">
        <h6>{template.custom_label}</h6>
      </div>

      {["od", "os"].map(type =>
        <div className="col-4">
          <div className="overview-table mb-3">
            <table className="table table-bordered bg-light table-hover shadow-none border border-muted">
              <thead className="text-dark border border-muted">
                <tr className="text-center">
                  <th scope="col">
                    <h6>{type.toUpperCase()}</h6>
                  </th>
                  <th scope="col">
                    <DisplayField
                      isVisible={true}
                      label={"Diopter"}
                    />
                  </th>
                  <th scope="col">
                    <DisplayField
                      isVisible={true}
                      label={"MM"}
                    />
                  </th>
                  <th scope="col">
                    <DisplayField
                      isVisible={true}
                      label={"Axis"}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="text-center">
                  <td>
                    <h6>Horizontal</h6>
                  </td>
                  <td>
                    <DisplayField
                      isVisible={true}
                      value={fieldsValues[`${type}_horizontal_diopter`]}
                    />
                  </td>
                  <td>
                    <DisplayField
                      isVisible={true}
                      value={fieldsValues[`${type}_horizontal_mm`]}
                    />
                  </td>
                  <td>
                    <DisplayField
                      isVisible={true}
                      value={fieldsValues[`${type}_horizontal_axis`]}
                    />
                  </td>
                </tr>
                <tr className="text-center">
                  <td>
                    <h6>Vertical</h6>
                  </td>
                  <td>
                    <DisplayField
                      isVisible={true}
                      value={fieldsValues[`${type}_vertical_diopter`]}
                    />
                  </td>
                  <td>
                    <DisplayField
                      isVisible={true}
                      value={fieldsValues[`${type}_vertical_mm`]}
                    />
                  </td>
                  <td>
                    <DisplayField
                      isVisible={true}
                      value={fieldsValues[`${type}_vertical_axis`]}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <DisplayField
            isVisible={template.fields[`${type}_notes`]['visible']}
            label={template.fields[`${type}_notes`]['custom_label']}
            value={fieldsValues[`${type}_notes`]}
          />
        </div>
      )}
      <div className="col-2">
        <DisplayField
          isVisible={true}
          label={"Topography (OD)"}
          // value={fieldsValues.topography_fields.order_od}
        />

        <DisplayField
          isVisible={template.fields.images1.visible}
          label={`${template.fields.images1.custom_label}:`}
          value={
            fieldsValues.od_topography_images_attributes?.map((image) =>
              <img className="img-fluid mb-6" src={image.file.url} alt="" />
            )}
          className="py-4"
        />

        <DisplayField
          isVisible={template.fields.order_od.visible}
          label={`${template.fields.order_od.custom_label}:`}
          value={fieldsValues.topography_fields && fieldsValues.topography_fields.order_od}
          className="py-2"
        />
        <DisplayField
          isVisible={template.fields.interpretation_od.visible}
          label={`${template.fields.interpretation_od.custom_label}:`}
          value={fieldsValues.topography_fields && fieldsValues.topography_fields.interpretation_od}
          className="py-2"
        />
      </div>
      <div className="col-2">
        <DisplayField
          isVisible={true}
          label={"Topography (OS)"}
          // value={fieldsValues.topography_fields.order_od}
        />

        <DisplayField
          isVisible={template.fields.images2.visible}
          label={`${template.fields.images2.custom_label}:`}
          value={
            fieldsValues.os_topography_images_attributes?.map((image) =>
              <img className="img-fluid mb-6" src={image.file.url} alt="" />
            )}
          className="py-4"
        />

        <DisplayField
          isVisible={template.fields.order_os.visible}
          label={`${template.fields.order_os.custom_label}:`}
          value={fieldsValues.topography_fields && fieldsValues.topography_fields.order_os}
          className="py-2"
        />
        <DisplayField
          isVisible={template.fields.interpretation_os.visible}
          label={`${template.fields.interpretation_os.custom_label}:`}
          value={fieldsValues.topography_fields && fieldsValues.topography_fields.interpretation_os}
          className="py-2"
        />
      </div>


      <DisplayField
        isVisible={true}
        label={"Dominant Eye:"}
        value={fieldsValues.dominant_eye}
        className="col-4 mt-4"
      />

    </div>
  )
}


export default Keratometry;