import React, { Component } from 'react';
import EyeGlassPrescription from './finalizePrescription/EyeGlassPrescription'
import ContactLensPrescription from './finalizePrescription/ContactLensPrescription'

import { FormTemplateHeader, FormTemplateSectionWrapper } from '../../others/'

class RefractionContactLensForm extends Component {
  render() {
    const { isTemplate, template } = this.props
    return (
      <div className="row finalize-prescription-form">
        <div className="col-12 mb-7">
          <FormTemplateSectionWrapper isTemplate={isTemplate} fieldValues={template.finalize_prescription.eye_glass}>
            <div className="card border-0 p-6">
              <FormTemplateHeader
                isTemplate={isTemplate}
                fieldValues={template.finalize_prescription.eye_glass}
                baseName="finalize_prescription[eye_glass]"
                overviewName="eye_glass_prescription"
              />
              {
                !isTemplate &&
                <EyeGlassPrescription pprops={this.props} {...this.props} />
              }
            </div>
          </FormTemplateSectionWrapper>
        </div>

        <div className="col-12 mb-7">
          <FormTemplateSectionWrapper isTemplate={isTemplate} fieldValues={template.finalize_prescription.contact_lens}>
            <div className="card border-0 p-6">
              <FormTemplateHeader
                isTemplate={isTemplate}
                fieldValues={template.finalize_prescription.contact_lens}
                baseName="finalize_prescription[contact_lens]"
                overviewName="contact_lens_prescription"
              />
              {
                !isTemplate &&
                <ContactLensPrescription pprops={this.props} {...this.props} />
              }
            </div>
          </FormTemplateSectionWrapper>
        </div>

      </div>
    );
  }
}

export default RefractionContactLensForm;