import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Moment from 'moment';

import ReferralCheckListForm from './ReferralCheckListForm'
import ReferralDetailsForm from './ReferralDetailsForm'
import { dateFormat } from '../../../itrust_common/components/HelperFunctions'

class ReferralForm extends Component {
  render() {
    const { handleSubmit, contacts, defaultContacts, referralId, referralEditing, examTemplate } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <ul className="nav nav-tabs border-bottom border-bottom-2 border-light" id="myTab" role="tablist">
          <li className="nav-item">
            <a className="px-6 py-5 tab-link active" id="ReferralCheckList-tab" data-toggle="tab" href="#ReferralCheckList" role="tab" aria-controls="ReferralCheckList" aria-selected="true">
              Referral Check List
            </a>
          </li>
          <li className="nav-item">
            <a className="px-6 py-5 tab-link" id="ReferralDetail-tab" data-toggle="tab" href="#ReferralDetail" role="tab" aria-controls="ReferralDetail" aria-selected="false">
              Referral Detail
            </a>
          </li>
        </ul>
        <div className="tab-content modal-body mx-auto">
          <div className="tab-pane active" id="ReferralCheckList" role="tabpanel" aria-labelledby="ReferralCheckList-tab">
            <ReferralCheckListForm examTemplate={examTemplate} />
          </div>
          <div className="tab-pane" id="ReferralDetail" role="tabpanel" aria-labelledby="ReferralDetail-tab">
            {(referralEditing && (referralEditing.id === referralId || referralEditing.id === 'New')) && <ReferralDetailsForm contacts={contacts} defaultContacts={defaultContacts} />}
          </div>
        </div>
      </form>
    );
  }
}

function getDefaultTestsChecked(template) {
  const { hpi, medical_history, social_history, preliminary_binocular_testing, attached_documents, finalize_prescription, external_internal_exam, refraction_contact_lens_exam, additional_tests, procedures_and_impressions } = template
  return {
    medical_history: medical_history.visible,
    social_history: social_history.visible,
    hpi: hpi.visible,
    preliminary_binocular_testing: preliminary_binocular_testing.visible,
    external_internal_exam: external_internal_exam.visible,
    refraction_contact_lens_exam: refraction_contact_lens_exam.visible,
    additional_tests: additional_tests.visible,
    procedures_and_impressions: procedures_and_impressions.visible,
    finalize_prescription: finalize_prescription.visible,
    attached_documents: attached_documents.visible
  }
}

ReferralForm = reduxForm({
  form: 'referralForm',
  enableReinitialize: true
})(ReferralForm)

ReferralForm = connect(state => {
  const { referral, exam: { exam, exam: { patient_attributes, template } } } = state;
  return {
    defaultContacts: referral.referral.contacts,
    referralEditing: referral.editing,
    referralId: referral.referral.id,
    examTemplate: template,
    initialValues: {
      patient_id: exam.patient_id,
      name: patient_attributes.name,
      age: patient_attributes.age,
      referral_date: Moment(new Date()).format("YYYY/MM/DD"),
      date_of_birth: dateFormat(patient_attributes.date_of_birth),
      cell_phone: patient_attributes.cell_phone,
      email: patient_attributes.email,
      check_list: getDefaultTestsChecked(template),
      ...referral.referral
    }
  };
})(ReferralForm);

export default ReferralForm;