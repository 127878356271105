import React, { Component } from 'react';
import Moment from 'moment';
import moment from 'moment-timezone';
import momentLocalizer from 'react-widgets-moment';
import { DateTimePicker } from 'react-widgets';
import 'react-widgets/dist/css/react-widgets.css';

Moment.locale('en');
momentLocalizer();
const localTimeZone=Intl.DateTimeFormat().resolvedOptions().timeZone
let timeFormatter = date => moment.tz(date, localTimeZone).format('hh:mm A');

export default class TimePicker extends Component {
  render() {
    const {
      props,
      props: { label, wrapperClasses, input: { onChange, value, open }, onChangeAction },
    } = this;
    return (
      <div className={`${wrapperClasses}`}>
        {label && <span>{label}</span>}
        <DateTimePicker
          {...props}
          onChange={val => {
            onChange(val);
            if (onChangeAction) {
              onChangeAction(val);
            }
          }}
          onFocus={open}
          date={false}
          value={!value ? null : new Date(value)}
          timeFormat={timeFormatter}
        />
      </div>
    );
  }
}
