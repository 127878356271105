import React, { Component} from 'react';
import {connect} from 'react-redux';
// import AppointmentRoutes from './appointmentRoutes';
import AuthRoutes from './authRoutes';

class Public extends Component{
  render(){
    // const { layout } =this.props
    return(
        <div className="h-100">
          {/* {AppointmentRoutes} */}
          <AuthRoutes/>
        </div>
    );
  }
}

const mapStateToProps = state => {
  const { other } = state;
  return {
    layout: other.layout
  }
}

export default connect(mapStateToProps)(Public);