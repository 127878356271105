import Toastr from "../../../itrust_common/components/Toastr"
const DEFAULT_STATE = {
  patient_documents: [],
  patient_document: {},
  meta: { pagination: {} },
  editing: false,
  saving: false,
}
let changes = null;
export default function patientDocumentReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let patientDocuments = [];
  let patientDocumentData = [];
  let updatedRecord = {};
  switch(action.type){
    case 'PATIENT_DOCUMENTS_LOAD_PENDING':
    case 'PATIENT_DOCUMENT_LOAD_PENDING':
      return {...state, saving: true};

    case 'PATIENT_DOCUMENT_CREATE_PENDING':
    case 'PATIENT_DOCUMENT_UPDATE_PENDING':
      return {...state, saving: true};

    case 'PATIENT_DOCUMENTS_LOAD_REJECTED':
    case 'PATIENT_DOCUMENT_LOAD_REJECTED':
    case 'PATIENT_DOCUMENT_CREATE_REJECTED':
    case 'PATIENT_DOCUMENT_UPDATE_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case 'PATIENT_DOCUMENTS_LOAD_FULFILLED':
      changes = {
        ...response,
        saving: false
      };
      return {...state, ...changes};
    
    case 'PATIENT_DOCUMENT_LOAD_FULFILLED':
      changes = {
        patient_document: response.patient_document,
        saving: false,
      }
      return {...state,...changes};
    
    case 'PATIENT_DOCUMENT_CREATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      patientDocuments = [...state.patient_documents]
      let newRecord = response.patient_document
      changes = {
        patient_documents: [...patientDocuments, newRecord],
        editing: false,
        saving: false
      }
      return {...state, ...changes};

    case 'PATIENT_DOCUMENT_UPDATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      patientDocuments = [...state.patient_documents];
      updatedRecord = response.patient_document;
      for (const [index, patientDocument] of patientDocuments.entries()) {
        if (patientDocument.id === updatedRecord.id) {
          patientDocumentData = [
            ...patientDocuments.slice(0, index),
            updatedRecord,
            ...patientDocuments.slice(index + 1)
          ];
        }
      }
      changes = {
        patient_documents: patientDocumentData,
        editing: false,
        saving: false
      };
      return { ...state, ...changes };

    case 'PATIENT_DOCUMENT_DELETE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      patientDocuments = [...state.patient_documents];
      for (const [index, patientDocument] of patientDocuments.entries()) {
        if (patientDocument.id === action.payload.id) {
          patientDocuments.splice(index, 1);
        }
      }
      return {
        ...state,
        patient_documents: patientDocuments,
        error: null
      };

    case 'PATIENT_DOCUMENT_EDIT':
      changes = {
        editing: {
          id: action.id,
        },
        patient_document: {}
      };
      return { ...state, ...changes };

    case 'PATIENT_DOCUMENT_EDIT_CANCEL':
      changes = {
        editing: false
      };
      return { ...state, ...changes };

    default:
      return state;
  }
}