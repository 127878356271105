import {handleAPI, API_VERSION_ONE_ADMIN} from '../../../utils/apiUtils';
const parent = 'SETTING_'

export function examTemplatesLoad(filters){
  const url = `/${API_VERSION_ONE_ADMIN}/settings/exam_templates/`;
  const method = 'GET'

  return {
    type: `${parent}EXAM_TEMPLATES_LOAD`,
    payload: handleAPI(url, filters, method)
  };
}

export function examTemplateLoad(id, filters){
  const url = `/${API_VERSION_ONE_ADMIN}/settings/exam_templates/${id}`;
  const method = 'GET'

  return {
    type: `${parent}EXAM_TEMPLATE_LOAD`,
    payload: handleAPI(url,filters,method)
  };
}

export function examTemplateCreate(data, filters){
  const url = `/${API_VERSION_ONE_ADMIN}/settings/exam_templates/`;
  const method= 'POST'
  return {
    type: `${parent}EXAM_TEMPLATE_CREATE`,
    payload: handleAPI(url,filters,method, {exam_template: {...data}})
  }
}

export function examTemplateUpdate(data, filters){
  const url = `/${API_VERSION_ONE_ADMIN}/settings/exam_templates/${data.id}`;
  const method = "PUT"
  return {
    type: `${parent}EXAM_TEMPLATE_UPDATE`,
    payload: handleAPI(url,filters,method, {exam_template: {...data}})
  }
}

export function examTemplateDelete(id, filters) {
  const url = `/${API_VERSION_ONE_ADMIN}/settings/exam_templates/${id}/`;
  const method = 'DELETE';

  return {
    type: `${parent}EXAM_TEMPLATE_DELETE`,
    payload: new Promise((resolve, reject) => {
      handleAPI(url, filters, method)
        .then(response => {
          resolve({ id: id, ...response });
        })
        .catch(err => {
          reject(err);
        });
    })
  };
}

export function examTemplateEdit(id) {
  return {
    type: `${parent}EXAM_TEMPLATE_EDIT`,
    id,
  };
}

export function examTemplateEditCancel() {
  return {
    type: `${parent}EXAM_TEMPLATE_EDIT_CANCEL`
  };
}

export function examTemplateReorder(examTemplates) {
  return {
    type: `${parent}EXAM_TEMPLATE_REORDER`,
    examTemplates,
  };
}
