import React from 'react'
import { ExportButton } from '../../../../itrust_common/components/others'
import { connect } from 'react-redux'

function ClaimFormDownload(props) {
  const { rpaClaimFormId, orderId } = props

  return (
    <>
      <ExportButton label="Download" className="btn btn-outline-primary ml-lg-7 ml-md-5 mx-md-4 my-md-2 mx-lg-0 my-lg-0" path={`/v1/orders/${orderId}/insurance_claim_form/download_form`} params={{ ['Store-Id']: localStorage.getItem('StoreID'), format: 'pdf', with_background: true, rpa_claim_id: rpaClaimFormId ?? null }} />
      <ExportButton label="Download Text PDF" className="btn btn-outline-primary ml-lg-7 ml-md-5 mx-md-4 my-md-2 mx-lg-0 my-lg-0" path={`/v1/orders/${orderId}/insurance_claim_form/download_form`} params={{ ['Store-Id']: localStorage.getItem('StoreID'), format: 'pdf', with_background: false, rpa_claim_id: rpaClaimFormId ?? null }} />
      <ExportButton label="Download .txt" className="btn btn-outline-primary ml-lg-7 ml-md-5 mx-md-4 my-md-2 mx-lg-0 my-lg-0" path={`/v1/orders/${orderId}/insurance_claim_form/download_form`} params={{ ['Store-Id']: localStorage.getItem('StoreID'), format: 'text', rpa_claim_id: rpaClaimFormId ?? null }} />
    </>
  )
}

const mapStateToProps = (state) => {
  const { rpaClaimForm: { rpaClaimForm }, order: { order } } = state
  return {
    rpaClaimFormId: rpaClaimForm.id,
    orderId: order.id
  }
}

export default connect(mapStateToProps)(ClaimFormDownload)