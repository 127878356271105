import React from 'react';
import { Field } from 'redux-form';
import { Checkbox } from '../../../itrust_common/components/form';

let ReferralCheckListForm = props => {
  const { examTemplate: {hpi, medical_history, social_history, preliminary_binocular_testing, attached_documents, finalize_prescription, external_internal_exam, refraction_contact_lens_exam, additional_tests, procedures_and_impressions} } = props
  return (
    <div>
      <span className="font-weight-bold">Referral Check List</span>
      <div className="row mt-3">
        { hpi.visible && 
          <div className="col-6 mt-5">
            <Field name="check_list[hpi]" component={Checkbox} label="HPI" wrapperClasses="bg-light py-3 px-3" />
          </div>
        }
        { medical_history.visible && 
          <div className="col-6 mt-5">
            <Field name="check_list[medical_history]" component={Checkbox} label="Medical History" wrapperClasses="bg-light py-3 px-3" />
          </div>
        }
        { social_history.visible && 
          <div className="col-6 mt-5">
            <Field name="check_list[social_history]" component={Checkbox} label="Social History" wrapperClasses="bg-light py-3 px-3" />
          </div>
        }
        { preliminary_binocular_testing.visible &&  
          <div className="col-6 mt-5">
            <Field name="check_list[preliminary_binocular_testing]" component={Checkbox} label="Preliminary &amp; Binocular Testing" wrapperClasses="bg-light py-3 px-3" />
          </div>
        }
        { refraction_contact_lens_exam.visible &&
          <div className="col-6 mt-5">
            <Field name="check_list[refraction_contact_lens_exam]" component={Checkbox} label="Refraction &amp; Contact Lens Exam" wrapperClasses="bg-light py-3 px-3" />
          </div>
        }
        { external_internal_exam.visible &&
          <div className="col-6 mt-5">
            <Field name="check_list[external_internal_exam]" component={Checkbox} label="External &amp; Internal Exam" wrapperClasses="bg-light py-3 px-3" />
          </div>
        }
        { additional_tests.visible &&
          <div className="col-6 mt-5">
            <Field name="check_list[additional_tests]" component={Checkbox} label="Additional Tests" wrapperClasses="bg-light py-3 px-3" />
          </div>
        }
        { procedures_and_impressions.visible &&
          <div className="col-6 mt-5">
            <Field name="check_list[procedures_and_impressions]" component={Checkbox} label="Procedures &amp; Impression Plan" wrapperClasses="bg-light py-3 px-3" />
          </div>
        }
        { finalize_prescription.visible &&
          <div className="col-6 mt-5">
            <Field name="check_list[finalize_prescription]" component={Checkbox} label="Finalize Prescription" wrapperClasses="bg-light py-3 px-3" />
          </div>
        }
        { attached_documents.visible &&
          <div className="col-6 mt-5">
            <Field name="check_list[attached_documents]" component={Checkbox} label="Attached Documents" wrapperClasses="bg-light py-3 px-3" />
          </div>
        }
      </div>
    </div>
  );
}

export default ReferralCheckListForm;