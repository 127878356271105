import Toastr from "../../../components/Toastr"
const parent = 'SETTING'

const DEFAULT_STATE = {
  org_config: {},
  saving: false
}
let changes;
export default function orgConfigReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  switch(action.type){
    case `${parent}_ORG_CONFIG_LOAD_PENDING`:
      return {...state, saving: false};

    case `${parent}_ORG_CONFIG_UPDATE_PENDING`:
      return {...state, saving: true};

    case `${parent}_ORG_CONFIG_LOAD_REJECTED`:
    case `${parent}_ORG_CONFIG_UPDATE_REJECTED`:
      Toastr.handleShow(response.data.success, response.data.error, response.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case `${parent}_ORG_CONFIG_LOAD_FULFILLED`:
      changes = {
        org_config: response.org_config,
        saving: false
      }
      return {...state,...changes};

    case `${parent}_ORG_CONFIG_UPDATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      changes = {
        org_config: response.org_config,
        editing: false,
        saving: false
      };
      return { ...state, ...changes };

    default:
      return state;
  }
}
