import React, { Component } from 'react';
import { Field, change } from 'redux-form';
import { connect } from 'react-redux';

import { Textarea, InputWithIcon } from '../../../../form/'
import { FormTemplateField } from '../../../../others/'
import SetDistanceNear from '../SetDistanceNear';


class Correction extends Component {

  setVisualAcuity(visual, value) {
    const { type } = this.props
    this.props.dispatch(change('examRefractionContactLensForm', `visual_acuity_${type}[od_${visual}]`, value))
    this.props.dispatch(change('examRefractionContactLensForm', `visual_acuity_${type}[os_${visual}]`, value))
    this.props.dispatch(change('examRefractionContactLensForm', `visual_acuity_${type}[ou_${visual}]`, value))
  }

  render() {
    const { isTemplate, template, type } = this.props
    return (
      <div className="card-body">
        <div className="row">
          <div className="col-6">
            <p>Distance</p>
          </div>
          <div className="col-6">
            <p>Near</p>
          </div>
          <Field ref={`visual_acuity_${type}[od_distance]`} name={`visual_acuity_${type}[od_distance]`} component={InputWithIcon} title="OD 20/" className="form-control" wrapperClasses="col-6 form-group" />
          <Field ref={`visual_acuity_${type}[od_near]`} name={`visual_acuity_${type}[od_near]`} component={InputWithIcon} title="OD 20/" className="form-control" wrapperClasses="col-6 form-group" />
          <Field ref={`visual_acuity_${type}[os_distance]`} name={`visual_acuity_${type}[os_distance]`} component={InputWithIcon} title="OS 20/" className="form-control" wrapperClasses="col-6 form-group" />
          <Field ref={`visual_acuity_${type}[os_near]`} name={`visual_acuity_${type}[os_near]`} component={InputWithIcon} title="OS 20/" className="form-control" wrapperClasses="col-6 form-group" />
          <Field ref={`visual_acuity_${type}[ou_distance]`} name={`visual_acuity_${type}[ou_distance]`} component={InputWithIcon} title="OU 20/" className="form-control" wrapperClasses="col-6 form-group" />
          <Field ref={`visual_acuity_${type}[ou_near]`} name={`visual_acuity_${type}[ou_near]`} component={InputWithIcon} title="OU 20/" className="form-control" wrapperClasses="col-6 form-group" />
          {
            !isTemplate &&
            <>
              <SetDistanceNear setVisualAcuity={this.setVisualAcuity.bind(this)} visual='distance' />
              <SetDistanceNear setVisualAcuity={this.setVisualAcuity.bind(this)} visual='near' />
            </>
          }
          <FormTemplateField
            isTemplate={isTemplate}
            name={`visual_acuity_${type}[notes]`}
            baseName={`refraction_contact_lens_exam[visual_acuity][fields][${type}][fields][notes]`}
            fieldValues={template.refraction_contact_lens_exam.visual_acuity.fields[type]['fields']['notes']}
            component={Textarea}
            rows={3}
            className="form-control"
            wrapperClasses="col-12 form-group label-icons"
          />
        </div>
      </div>
    );
  }
}

export default connect()(Correction);

