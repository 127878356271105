import { handleAPI, API_VERSION_ONE } from '../../../../itrust_common/utils/apiUtils';

export function rpaServicesLoad(filters) {
  const url = `/${API_VERSION_ONE}/add_on`;

  const method = 'GET'
  return {
    type: 'RPA_SERVICES_LOAD',
    payload: handleAPI(url, filters, method)
  }
}

export function rpaServiceUpdate(data, filters) {
  const url = `/${API_VERSION_ONE}/add_on/${data.id}`;
  const method = 'PUT'
  return {
    type: 'RPA_SERVICE_UPDATE',
    payload: handleAPI(url, filters, method, { add_on: data })
  }
}

export function rpaEligibilityVerify(data, filters) {
  const url = `/${API_VERSION_ONE}/add_ons/rpa/vision_insurances`;
  const method = 'POST'
  return {
    type: 'RPA_ELIGIBILITY_VERIFY',
    payload: handleAPI(url, filters, method, data)
  }
}

export function rpaDetailView(data) {
  return {
    type: 'RPA_DETAIL_VIEW',
    data
  };
}

export function rpaEdit(data) {
  return {
    type: 'RPA_EDIT',
    data
  };
}

export function rpaEditReset() {
  return {
    type: 'RPA_EDIT_RESET',
  };
}

export function rpaEligibilityEdit(data) {
  return {
    type: 'RPA_ELIGIBILITY_EDIT',
    data
  };
}

export function rpaEligibilityReset() {
  return {
    type: 'RPA_ELIGIBILITY_RESET',
  };
}
