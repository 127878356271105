import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import * as $ from 'jquery';

import Overview from './Overview';

import ExamHeaderForm from './edit/ExamHeaderForm'
import PreliminaryBinocularForm from './edit/PreliminaryBinocularForm'
import HpiForm from './edit/HpiForm'
import AdditionalTestForm from './edit/AdditionalTestForm'
import AttachedDocForm from './edit/AttachedDocForm'
import ExternalInternalForm from './edit/ExternalInternalForm'
import ProcedureImpressionPlanForm from './edit/ProcedureImpressionPlanForm';
import RefractionContactLensForm from './edit/RefractionContactLensForm';
import FinalizePrescriptionForm from './edit/FinalizePrescriptionForm';

import { tabChange } from '../../../itrust_common/redux/actions/otherActions';

import SocialHistoryForm from '../../../containers/authorised/patientPartials/SocialHistoryPartial'
import MedicalHistoryForm from '../../../containers/authorised/patientPartials/MedicalHistoryPartial'
import ReviewOfSystemForm from '../../../containers/authorised/patientPartials/ReviewOfSystemPartial'
import DeleteExamButton from '../../../components/authorised/buttons/DeleteExamButton';

import Referral from '../../../containers/authorised/referral/Referral'
import MedicalRx from '../../../containers/authorised/medicalRx/MedicalRx';
import OldValuesWrapper from './oldValues/OldValuesWrapper';
import EditOrderButton from '../buttons/EditOrderButton';
import Alert from '../../../itrust_common/components/Alert';
import { dateFormat, checkIsVisible } from '../../../itrust_common/components/HelperFunctions';
import { patientReportPreview } from '../../../redux/actions/patient/patientReportActions'

import { medicalRxEdit, medicalRxsLoad } from '../../../redux/actions/medicalRxActions'
import { isSubmitting, submit } from 'redux-form';
import { appointmentLoad } from '../../../redux/actions/appointmentActions';
import { examUpdate } from '../../../redux/actions/exam/examActions';
import { handleStopExamRecording } from './CommonFunctions';
import UserCam from '../../../support/utils/UserCam';
import { ImportButton } from '../../../itrust_common/components/others';

class ExamForm extends Component {
  constructor(props) {
    super(props)
    this.handleTabChange(1)
    this.state = {
      accordionToggle: true,
      lastScrollPos: 0
    }
    this.handleMedicalRxsLoad({ examination_id: this.props.exam.id })
  }

  handleMedicalRxsLoad(filters) {
    this.props.dispatch(medicalRxsLoad(this.props.exam.patient_id, filters))
  }

  handleTabChange(data) {
    this.props.dispatch(tabChange({ exam: data }))
  }

  handleScroll(event) {
    if (event.currentTarget.scrollTop === 0) {
      this.setState({
        lastScrollPos: event.currentTarget.scrollTop,
        accordionToggle: true
      });
    } else if (this.state.lastScrollPos < event.currentTarget.scrollTop) {
      this.setState({
        lastScrollPos: event.currentTarget.scrollTop,
        accordionToggle: false
      });
    }
  }

  handleMedicalRxEdit() {
    this.props.dispatch(medicalRxEdit('New'));
    $('#medicalRxModal').modal('show')
  }

  async handlePrintPrescription() {
    const { exam } = this.props
    await this.props.dispatch(submit("examFinalizePrescriptionForm"));
    var res = await this.props.dispatch(patientReportPreview(exam.patient_id, { report_type: "prescriptions", object_id: exam.id }))
    window.open(res.value.data.filepath, '_blank')
  }


  handleFinalizedUpdate(onSubmit, exam) {
    onSubmit({ id: exam.id, status: "finalized" })
    if (exam.appointment_id) setTimeout(() => {
      this.props.dispatch(appointmentLoad(exam.appointment_id, { include: ['patient', 'staff', 'procedures', 'examination', 'order'] }))
    }, 1000)
  }

  async handleStopSaveRecording(exam) {
    const res = await handleStopExamRecording()
    res && this.props.dispatch(examUpdate({ id: exam.id, telemed_visits_attributes: [res] }, { include: '*' }))
  }

  handleExamFromClose(exam) {
    exam.is_telemed ? this.handleStopSaveRecording(exam).then(() => $('#editExamForm-modal').modal('hide')) :
      $('#editExamForm-modal').modal('hide')
  }

  render() {
    const { store, activeTab, onSubmit, handleExamEditCancel, handleExamPdfPrint, onSecurFax, emailPrescriptionReportSend, exam, exam: { order_attributes, patient_attributes, template, patient_id, status, template: { hpi, social_history, medical_history, ros, preliminary_binocular_testing, refraction_contact_lens_exam, external_internal_exam, additional_tests, procedures_and_impressions, finalize_prescription, attached_documents } }, medicalRxes, currentUser, overviewName } = this.props;
    return (
      <div>
        <Helmet>
          <title>{`${exam.patient_attributes.name} ${dateFormat(exam.exam_date)} ${store.name}`}</title>
        </Helmet>
        <div className="fixed-container-modal px-5 pt-5 bg-light patient-exam-form-fixed">

          <div className="d-flex flex-row justify-content-between align-items-center">
            <div className="d-flex align-items-start">
              <div>
                <h5 className="mr-2">Patient Exam Form</h5>
                <span className="ml-1"><strong>ID:</strong> {exam.id}</span>
              </div>
              <a className="collapsed border border-primary rounded-circle ml-2" onClick={() => this.setState({ accordionToggle: !this.state.accordionToggle })} role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                <i className="la la-sm la-angle-down text-primary px-2 pt-2" />
              </a>
            </div>
            <div className="d-flex">
              {exam.is_telemed &&
                <button
                  type="button"
                  className="btn btn-danger danger-pulse mx-3">
                  Recording
                </button>
              }
              {
                <label><ImportButton label="Import from Device" className="btn btn-outline-primary mx-3" {...this.props} isDeviceData={true} params={{ id: exam.id }} /></label>
              }
              {

                order_attributes ?
                  <EditOrderButton isTelemedExam={exam.is_telemed} storeId={order_attributes && order_attributes.store_id} orderId={order_attributes && order_attributes.id}>
                    <button className={`btn btn-outline-primary mx-3`} aria-disabled="true">Open Order</button>
                  </EditOrderButton>
                  :
                  <button
                    type="button"
                    className="btn btn-outline-primary mx-3"
                    onClick={() => Alert.confirm(<div>You're creating order for <strong>{patient_attributes.name}</strong>.</div>, (res) => res && onSubmit({ id: exam.id, create_order_from_exam: true }))}>
                    Create Order
                  </button>
              }
              {/* <button className="btn btn-outline-primary mx-3">Save Exam</button> */}
              {
                status === "finalized" ?
                  <button
                    type="button"
                    className="btn btn-success mx-3">
                    Finalized
                  </button>
                  :
                  <button
                    type="button"
                    className="btn btn-outline-primary mx-3"
                    onClick={() => Alert.confirm(<div>You're finalizing exams for <strong>{patient_attributes.name}</strong>. Exam will become read-only.</div>,
                      (res) => res && this.handleFinalizedUpdate(onSubmit, exam))
                    }>
                    Finalize Exam
                  </button>
              }

              <div className="dropdown d-inline-block mx-3 bg-light">
                <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  More Actions
                  <i className="ml-3 la la-angle-down la-sm" />
                </button>
                <div className="dropdown-menu border-0 shadow rounded my-3" aria-labelledby="dropdownMenuButton">
                  <a className="dropdown-item d-flex align-item-center border-bottom border-bottom-2 border-light py-3" data-toggle="modal" href="#referralModal">
                    <i className="la la-sm mr-3 la-edit" />
                    Referral
                  </a>
                  <a onClick={() => this.handleMedicalRxEdit()} className="dropdown-item d-flex align-item-center border-bottom border-bottom-2 border-light py-3 pointer" >
                    <i className="la la-sm mr-3 la-prescription" />
                    e-SCRIBE
                  </a>
                  <a onClick={handleExamPdfPrint} className="dropdown-item d-flex align-item-center border-bottom border-bottom-2 border-light py-3 pointer">
                    <i className="la la-sm mr-3 la-print" />
                    Print
                  </a>
                  {/*<a className="dropdown-item d-flex align-item-center border-bottom border-bottom-2 border-light py-3" data-toggle="modal" href="#"><i className="la la-sm mr-3 la-user"/>View Profile</a>*/}
                  <DeleteExamButton exam={exam} onDeleteHook={handleExamEditCancel}>
                    <a className="dropdown-item d-flex align-items-center text-danger py-3"><i className="la la-trash la-sm mr-3" />Delete</a>
                  </DeleteExamButton>
                </div>
              </div>
              {/* <button className="btn btn-outline-primary mx-3" data-dismiss="modal" aria-label="Close">Close</button> */}

              <button className="btn btn-outline-primary mx-3" onClick={() => this.handleExamFromClose(exam)}>Close</button>
            </div>
          </div>

          <ExamHeaderForm currentUserRole={currentUser.role} onSubmit={onSubmit} patient={patient_attributes} defaultVisible={this.state.accordionToggle} isHpiTabVisible={hpi.visible} />

          <div className="text-nowrap overflow-auto nav-tabs-view ml-0 border border-radius-6 px-3 mt-4 bg-white">
            <ul className="nav nav-tabs exam-tabs text-nowrap overflow-auto flex-nowrap" id="myTab" role="tablist">
              <li className="nav-item" onClick={this.handleTabChange.bind(this, 1)}>
                <a className="p-3 mr-3 tab-link active" id="overview-tab" data-toggle="tab" href="#overview" role="tab" aria-controls="overview" aria-selected="true">
                  Overview
                </a>
              </li>
              {(!(currentUser.role === 'non_admin' && exam.status === "finalized") || (currentUser.role === "non_admin" && currentUser.id === exam.staff_attributes.id)) &&
                <>
                  {
                    hpi && checkIsVisible(hpi.visible) &&
                    <li className="nav-item" onClick={this.handleTabChange.bind(this, 2)}>
                      <a className="py-3 px-6 mx-0 tab-link" id="history-present-illness-tab" data-toggle="tab" href="#history-present-illness" role="tab" aria-controls="history-present-illness" aria-selected="false">
                        HPI
                      </a>
                    </li>
                  }
                  {
                    social_history && checkIsVisible(social_history.visible) &&
                    <li className="nav-item" onClick={this.handleTabChange.bind(this, 3)}>
                      <a className="p-3 mr-3 tab-link" id="social-history-tab" data-toggle="tab" href="#social-history" role="tab" aria-controls="social-history" aria-selected="false">
                        Social History
                      </a>
                    </li>
                  }

                  {
                    medical_history && checkIsVisible(medical_history.visible) &&
                    <li className="nav-item" onClick={this.handleTabChange.bind(this, 4)}>
                      <a className="p-3 mr-3 tab-link" id="medical-history-tab" data-toggle="tab" href="#medical-history" role="tab" aria-controls="medical-history" aria-selected="false">
                        Medical History
                      </a>
                    </li>
                  }

                  {
                    ros && checkIsVisible(ros.visible) &&
                    <li className="nav-item" onClick={this.handleTabChange.bind(this, 5)}>
                      <a className="py-3 px-6 mx-0 tab-link" id="ros-tab" data-toggle="tab" href="#ros" role="tab" aria-controls="ros" aria-selected="false">
                        ROS
                      </a>
                    </li>
                  }

                  {
                    preliminary_binocular_testing && checkIsVisible(preliminary_binocular_testing.visible) &&
                    <li className="nav-item" onClick={this.handleTabChange.bind(this, 6)}>
                      <a className="p-3 mr-3 tab-link" id="preliminary-binocular-tab" data-toggle="tab" href="#preliminary-binocular" role="tab" aria-controls="preliminary-binocular" aria-selected="false">
                        Preliminary/ Binocular
                      </a>
                    </li>
                  }
                  {
                    refraction_contact_lens_exam && checkIsVisible(refraction_contact_lens_exam.visible) &&
                    <li className="nav-item" onClick={this.handleTabChange.bind(this, 7)}>
                      <a className={`p-3 mr-3 tab-link`} id="refraction-contact-lens-tab" data-toggle="tab" href="#refraction-contact-lens" role="tab" aria-controls="refraction-contact-lens" aria-selected="false">
                        Refraction/ Contact Lens
                      </a>
                    </li>
                  }
                  {
                    external_internal_exam && checkIsVisible(external_internal_exam.visible) &&
                    <li className="nav-item" onClick={this.handleTabChange.bind(this, 8)}>
                      <a className="p-3 mr-3 tab-link" id="external-internal-tab" data-toggle="tab" href="#external-internal" role="tab" aria-controls="external-internal" aria-selected="false">
                        External/ Internal
                      </a>
                    </li>
                  }
                  {
                    additional_tests && checkIsVisible(additional_tests.visible) &&
                    <li className="nav-item" onClick={this.handleTabChange.bind(this, 9)}>
                      <a className="p-3 mr-3 tab-link" id="additional-tests-tab" data-toggle="tab" href="#additional-tests" role="tab" aria-controls="additional-tests" aria-selected="false">
                        Additional Tests
                      </a>
                    </li>
                  }
                  {
                    procedures_and_impressions && checkIsVisible(procedures_and_impressions.visible) &&
                    <li className="nav-item" onClick={this.handleTabChange.bind(this, 10)}>
                      <a className="p-3 mr-3 tab-link" id="impression-plan-tab" data-toggle="tab" href="#impression-plan" role="tab" aria-controls="impression-plan" aria-selected="false">
                        Procedure Impression/Plan
                      </a>
                    </li>
                  }
                  {
                    finalize_prescription && checkIsVisible(finalize_prescription.visible) &&
                    <li className="nav-item" onClick={this.handleTabChange.bind(this, 11)}>
                      <a className={`p-3 mr-3 tab-link`} id="finalize-prescription-tab" data-toggle="tab" href="#finalize-prescription" role="tab" aria-controls="finalize-prescription" aria-selected="false">
                        <span className="mr-3">Finalize Prescription</span>
                        {exam.finalized_glasses_attributes.length > 0 &&
                          <span className="badge badge-info badge-pill mr-3"> {exam.finalized_glasses_attributes.length} </span>
                        }
                        {medicalRxes.length > 0 &&
                          <span className="badge bg-gray-600 light-badge text-light badge-pill mr-3"> {medicalRxes.length} </span>
                        }
                        {exam.finalized_lenses_attributes.length > 0 &&
                          <span className="badge badge-warning badge-pill"> {exam.finalized_lenses_attributes.length} </span>
                        }
                      </a>
                    </li>
                  }

                  {
                    attached_documents && checkIsVisible(attached_documents.visible) &&
                    <li className="nav-item" onClick={this.handleTabChange.bind(this, 12)}>
                      <a className="p-3 mr-3 tab-link" id="attached-docs-tab" data-toggle="tab" href="#attached-docs" role="tab" aria-controls="attached-docs" aria-selected="false">
                        Attached Docs
                        {exam.patient_documents_attributes.length > 0 &&
                          <span className="badge badge-info badge-pill ml-4 mr-2"> {exam.patient_documents_attributes.length} </span>
                        }
                      </a>
                    </li>
                  }
                </>}
            </ul>
          </div>
        </div>

        <div id="examForm-scroll" className={`${this.state.accordionToggle ? 'middle-container-modal-2 mt-5' : 'middle-container-modal'} px-5 mb-9`}>
          <div className="tab-content overflow-auto" onScroll={this.handleScroll.bind(this)} style={{ height: '800px' }}>
            {
              activeTab === 1 &&
              <div className="tab-pane active" id="overview" role="tabpanel" aria-labelledby="overview-tab">
                <Overview exam={exam} store={store} patient={patient_attributes} />
              </div>
            }
            {
              activeTab === 2 &&
              <div className="tab-pane active" id="history-present-illness" role="tabpanel" aria-labelledby="history-present-illness-tab">
                <HpiForm onSubmit={onSubmit} template={template} isTemplate={false} />
              </div>
            }
            {
              activeTab === 3 &&
              <div className="tab-pane active" id="social-history" role="tabpanel" aria-labelledby="social-history-tab">
                <SocialHistoryForm patientId={patient_id} examId={exam.id} />
              </div>
            }
            {
              activeTab === 4 &&
              <div className="tab-pane active" id="medical-history" role="tabpanel" aria-labelledby="medical-history-tab">
                <MedicalHistoryForm patientId={patient_id} examId={exam.id} />
              </div>
            }
            {
              activeTab === 5 &&
              <div className="tab-pane active" id="ros" role="tabpanel" aria-labelledby="ros-tab">
                <ReviewOfSystemForm patientId={patient_id} examId={exam.id} />
              </div>
            }
            {
              activeTab === 6 &&
              <div className="tab-pane active" id="preliminary-binocular" role="tabpanel" aria-labelledby="preliminary-binocular-tab">
                <PreliminaryBinocularForm onSubmit={onSubmit} template={template} isTemplate={false} />
              </div>
            }

            {
              activeTab === 7 &&
              <div className="tab-pane active" id="refraction-contact-lens" role="tabpanel" aria-labelledby="refraction-contact-lens-tab">
                <RefractionContactLensForm onSubmit={onSubmit} template={template} isTemplate={false} />
              </div>
            }

            {
              activeTab === 8 &&
              <div className="tab-pane active" id="external-internal" role="tabpanel" aria-labelledby="external-internal-tab">
                <ExternalInternalForm onSubmit={onSubmit} template={template} isTemplate={false} />
              </div>
            }

            {
              activeTab === 9 &&
              <div className="tab-pane active" id="additional-tests" role="tabpanel" aria-labelledby="additional-tests-tab">
                <AdditionalTestForm onSubmit={onSubmit} template={template} isTemplate={false} />
              </div>
            }

            {
              activeTab === 10 &&
              <div className="tab-pane active" id="impression-plan" role="tabpanel" aria-labelledby="impression-plan-tab">
                <ProcedureImpressionPlanForm onSubmit={onSubmit} template={template} isTemplate={false} exam={exam} />
              </div>
            }

            {
              activeTab === 11 &&
              <div className="tab-pane active" id="finalize-prescription" role="tabpanel" aria-labelledby="finalize-prescription-tab">
                <FinalizePrescriptionForm onSubmit={onSubmit} exam={exam} onSecurFax={onSecurFax} emailPrescriptionReportSend={emailPrescriptionReportSend} handlePrintPrescription={this.handlePrintPrescription.bind(this)} template={template} isTemplate={false} />
              </div>
            }

            {
              activeTab === 12 &&
              <div className="tab-pane active" id="attached-docs" role="tabpanel" aria-labelledby="attached-docs-tab">
                <AttachedDocForm onSubmit={onSubmit} template={template} isTemplate={false} />
              </div>
            }
          </div>

          <Referral exam={exam} store={store} />
          <MedicalRx onSubmitHook={() => onSubmit({ id: exam.id })} patientId={exam.patient_id} />
          {overviewName?.id && <OldValuesWrapper examId={exam.id} activeTab={activeTab} />}
          {exam.is_telemed &&
            <div className='position-fixed user-cam'>
              <UserCam />
            </div>}
        </div>
      </div>

    );
  }
}



ExamForm = connect(state => {
  const { other, auth, exam: { exam }, organisation, medicalRx, previousExam } = state;
  return {
    overviewName: previousExam.editing,
    submitting: isSubmitting('examHeaderForm')(state),
    exam: exam,
    activeTab: other.activeTab.exam,
    organisation: organisation.organisation,
    store: exam.store_attributes,
    medicalRxes: medicalRx.medical_rxes,
    currentUser: auth.user,
  }
})(ExamForm)


export default ExamForm;
