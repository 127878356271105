import Toastr from "../../../components/Toastr"
const parent = 'SETTING_'

const DEFAULT_STATE = {
  insurance_plan_procedures: [],
  insurance_plan_procedure: {},
  meta: {pagination: {}},
  saving: false,
  editing: false
}
let changes = null;
export default function planProcedureReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let insurance_plan_procedures = [];
  let insurancePlanData = [];
  let updatedRecord = {};
  switch(action.type){
    case `${parent}PLAN_PROCEDURES_LOAD_PENDING`:
    case `${parent}PLAN_PROCEDURE_LOAD_PENDING`:
      return {...state, saving: false};

    case `${parent}PLAN_PROCEDURE_CREATE_PENDING`:
    case `${parent}PLAN_PROCEDURE_UPDATE_PENDING`:
      return {...state, saving: true};

    case `${parent}PLAN_PROCEDURES_LOAD_REJECTED`:
    case `${parent}PLAN_PROCEDURE_LOAD_REJECTED`:
    case `${parent}PLAN_PROCEDURE_CREATE_REJECTED`:
    case `${parent}PLAN_PROCEDURE_UPDATE_REJECTED`:
      Toastr.handleShow(response.data.success, response.data.error, response.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case `${parent}PLAN_PROCEDURES_LOAD_FULFILLED`:
      changes = {
        ...response,
        saving: false
      };
      return {...state, ...changes};
    
    case `${parent}PLAN_PROCEDURE_LOAD_FULFILLED`:
      changes = {
        insurance_plan_procedure: response.insurance_plan_procedure,
        saving: false
      }
      return {...state,...changes};
    
    case `${parent}PLAN_PROCEDURE_CREATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      // insurance_plan_procedures = [...state.insurance_plan_procedures]
      let newRecord = response.insurance_plan_procedures
      changes = {
        // insurance_plan_procedures: [...insurance_plan_procedures, newRecord],
        insurance_plan_procedures: newRecord,
        editing: false,
        saving: false
      }
      return {...state, ...changes};

    case `${parent}PLAN_PROCEDURE_UPDATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      insurance_plan_procedures = [...state.insurance_plan_procedures];
      updatedRecord = response.insurance_plan_procedure;
      for (const [index, insurance] of insurance_plan_procedures.entries()) {
        if (insurance.id === updatedRecord.id) {
          insurancePlanData = [
            ...insurance_plan_procedures.slice(0, index),
            updatedRecord,
            ...insurance_plan_procedures.slice(index + 1)
          ];
        }
      }
      changes = {
        insurance_plan_procedures: insurancePlanData,
        saving: false,
        editing: false,
      };
      return { ...state, ...changes };

    case `${parent}PLAN_PROCEDURE_DELETE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      insurance_plan_procedures = [...state.insurance_plan_procedures];
      for (const [index, insurance] of insurance_plan_procedures.entries()) {
        if (insurance.id === response.insurance_plan_procedure.id) {
          insurance_plan_procedures.splice(index, 1);
        }
      }
      return {
        ...state,
        insurance_plan_procedures: insurance_plan_procedures,
        error: null
      };

    case `${parent}PLAN_PROCEDURE_EDIT`:
      changes = {
        editing: {
          id: action.id,
        },
      };
      return { ...state, ...changes };

    case `${parent}PLAN_PROCEDURE_EDIT_CANCEL`:
      changes = {
        editing: false
      };
      return { ...state, ...changes };

    case `${parent}PLAN_PROCEDURES_LIST_RESET`:
      changes = {
        insurance_plan_procedures: []
      };
      return {...state, ...changes};

    default:
      return state;
  }
}