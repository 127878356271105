import Toastr from "../../../../itrust_common/components/Toastr";

const DEFAULT_STATE = {
  saving: false,
  graph_data: {},
  kpi_data: {},
  rpa_logs: [],
}

let changes = null
export const rpaReportReducer = (state = DEFAULT_STATE, action) => {
  const response = action.payload && (action.payload.data || action.payload.response)

  switch (action.type) {

    case 'RPA_DASHBOARD_LOAD_PENDING':
    case 'RPA_CLAIM_SUBMIT_LOGS_LOAD_PENDING':
      return { ...state, saving: true };

    case 'RPA_DASHBOARD_LOAD_REJECTED':
    case 'RPA_CLAIM_SUBMIT_LOGS_LOAD_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = { saving: false }
      return { ...state, ...changes };

    case 'RPA_DASHBOARD_LOAD_FULFILLED':
      changes = { [`${response.meta.params}_data`]: { ...response.records }, meta: { ...response.meta }, saving: false }
      return { ...state, ...changes };

    case 'RPA_CLAIM_SUBMIT_LOGS_LOAD_FULFILLED':
      changes = { ...response, saving: false }
      return { ...state, ...changes };

    default:
      return state
  }
}