import React, { Component } from 'react';
import { connect } from 'react-redux';
import { numberToCurrency } from '../../../../../itrust_common/components/HelperFunctions';

class Eyeglass extends Component{

  renderFrameDetails(frame) {
    return <>
      {frame &&
        <>
          {frame.brand} <br />
          <b>{`${(frame.model && frame.color) ? "Model Name-" : frame.model ? 'Model Name' : ''}${frame.color ? "Color" : ''}:`}</b><br />
          {frame.model}{(frame.model && frame.color) ? `-${frame.color}` : frame.color}<br />
          <b>{`${((frame.eye_size && frame.bridge) || (frame.eye_size && frame.temple)) ? "Eye Size-" : frame.eye_size ? 'Eye Size' : ''}${(frame.bridge && frame.temple) ? "Bridge-" : frame.bridge ? 'Bridge' : ""}${frame.temple ? "Temple" : ""}:`}</b><br />
          {frame.eye_size}{(frame.eye_size && frame.bridge) ? `-${frame.bridge}` : frame.bridge}{((frame.bridge || frame.eye_size) && frame.temple) ? `-${frame.temple}` : frame.temple}<br />
        </>
      }
    </>
  }

  render(){
    const { eyeglasses } = this.props
    return (
      eyeglasses.map((glass, index) => [
        <tr className="text-dark bg-light border-bottom border-white border-bottom-2">
          <td><h6>Eyeglass Order #{index+1}</h6></td>
          <td colspan="5"><h6>Details</h6></td>
          <td><h6 className="text-danger">{glass.overall_cash_discount && numberToCurrency(glass.overall_cash_discount)}</h6></td>
          <td><h6>{glass.total ? numberToCurrency(glass.total) : ''}</h6></td>
        </tr>,
        <tr className="text-dark bg-light border-bottom border-white border-bottom-2">
          <td>Frame</td>
          <td>{this.renderFrameDetails(glass.frame_details)}</td>
          <td className="pl-8">{glass.frame_unit_price ? numberToCurrency(glass.frame_unit_price) : ''}</td>
          <td>{glass.frame_copay ? numberToCurrency(glass.frame_copay) : ''}</td>
          <td>{(glass.frame_cash_discount || glass.frame_percentage_discount) ? numberToCurrency(glass.frame_discount_type === "cash" ? glass.frame_cash_discount : glass.frame_percentage_discount) : ''}</td>
          <td colSpan="3">Not A</td>
        </tr>,
        <tr className="text-dark bg-light border-bottom border-white border-bottom-2">
          <td>Glass Lens Type</td>
          <td>{glass.lens_type_name}</td>
          <td className="pl-8">{glass.lens_type_unit_price ? numberToCurrency(glass.lens_type_unit_price) : ''}</td>
          <td>{glass.lens_type_copay ? numberToCurrency(glass.lens_type_copay) : ''}</td>
          <td>{(glass.lens_type_cash_discount || glass.lens_type_percentage_discount) ? numberToCurrency(glass.lens_type_discount_type === "cash" ? glass.lens_type_cash_discount : glass.lens_type_percentage_discount) : ''}</td>
          <td colSpan="3">{glass.lens_type_subtotal ? numberToCurrency(glass.lens_type_subtotal) : ''}</td>
        </tr>,
        <tr className="text-dark bg-light border-bottom border-white border-bottom-2">
          <td>Material</td>
          <td>{glass.lens_material_name}</td>
          <td className="pl-8">{glass.lens_material_unit_price ? numberToCurrency(glass.lens_material_unit_price) : ''}</td>
          <td>{glass.lens_material_copay ? numberToCurrency(glass.lens_material_copay) : ''}</td>
          <td>{(glass.lens_material_cash_discount || glass.lens_material_percentage_discount) ? numberToCurrency(glass.lens_material_discount_type === "cash" ? glass.lens_material_cash_discount : glass.lens_material_percentage_discount) : ''}</td>
          <td colSpan="3">{glass.lens_material_subtotal ? numberToCurrency(glass.lens_material_subtotal) : ''}</td>
        </tr>,
        glass.eyeglass_addons_attributes && glass.eyeglass_addons_attributes.map((addon, index) => (
          <tr className="text-dark bg-light border-bottom border-white border-bottom-2">
            <td>Add-ons #{index + 1}</td>
            <td>{addon.addon_name}</td>
            <td className="pl-8">{addon.unit_price ? numberToCurrency(addon.unit_price) : ''}</td>
            <td>{addon.copay ? numberToCurrency(addon.copay) : ''}</td>
            <td>{(addon.cash_discount || addon.percentage_discount) ? numberToCurrency(addon.discount_type === "cash" ? addon.cash_discount : addon.percentage_discount) : ''}</td>
            <td colSpan="3">{addon.subtotal ? numberToCurrency(addon.subtotal) : ''}</td>
          </tr>
        )),
        <tr className="text-dark bg-light border-bottom border-white border-bottom-2">
          <td colSpan="3">Material Copay</td>
          <td colSpan="2">{glass.material_copay ? numberToCurrency(glass.material_copay) : ''}</td>
          <td colSpan="3">{glass.material_copay ? numberToCurrency(glass.material_copay) : ''}</td>
        </tr>,
        <tr className="text-dark bg-light border-bottom border-white border-bottom-2">
          <td colSpan="3">Frame Allowance</td>
          <td colSpan="2">{glass.frame_allowance ? numberToCurrency(glass.frame_allowance) : ''}</td>
          <td colSpan="3">{glass.frame_allowance ? numberToCurrency(glass.frame_allowance) : ''}</td>
        </tr>,
        <tr className="text-dark bg-light border-bottom border-white border-bottom-2">
          <td colSpan="3">Frame Only Discount</td>
          <td colSpan="2">{glass.frame_only_percentage_discount ? numberToCurrency(glass.frame_only_percentage_discount) : ''}</td>
          <td colSpan="3">{glass.frame_only_percentage_discount ? numberToCurrency(glass.frame_only_percentage_discount) : ''}</td>
        </tr>
      ])
    )
  }
}

export default connect()(Eyeglass);