import {handleAPI, API_VERSION_ONE} from '../../../utils/apiUtils';

export function socialHistorySectionsLoad(filters){
  const url = `/${API_VERSION_ONE}/settings/social_history_sections`;
  const method = 'GET'
  return {
    type: 'SOCIAL_HISTORY_SECTIONS_LOAD',
    payload: handleAPI(url, filters, method)
  };
}

export function socialHistorySectionLoad(id, filters){
  const url = `/${API_VERSION_ONE}/settings/social_history_sections/${id}`;
  const method = 'GET'

  return {
    type: 'SOCIAL_HISTORY_SECTION_LOAD',
    payload: handleAPI(url,filters,method)
  };
}

export function socialHistorySectionCreate(data, filters){
  const url = `/${API_VERSION_ONE}/settings/social_history_sections`;
  const method= 'POST'
  return {
    type: 'SOCIAL_HISTORY_SECTION_CREATE',
    payload: handleAPI(url,filters,method, {social_history_section: {...data}})
  }
}

export function socialHistorySectionUpdate(data, filters){
  const url = `/${API_VERSION_ONE}/settings/social_history_sections/${data.id}`;
  const method = "PUT"
  return {
    type: 'SOCIAL_HISTORY_SECTION_UPDATE',
    payload: handleAPI(url,filters,method, {social_history_section: {...data}})
  }
}

export function socialHistorySectionsReorder(data) {
  return {
    type: 'SOCIAL_HISTORY_SECTIONS_REORDER',
    data,
  };
}

export function socialHistorySectionEdit(id) {
  return {
    type: 'SOCIAL_HISTORY_SECTION_EDIT',
    id,
  };
}

export function socialHistorySectionEditCancel() {
  return {
    type: 'SOCIAL_HISTORY_SECTION_EDIT_CANCEL'
  };
}
