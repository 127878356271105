import React from 'react';
import { Route, Switch, Redirect} from 'react-router-dom';
import asyncComponent from '../../itrust_common/components/AsyncComponent';

// const Order = asyncComponent(() =>
//   import('../../containers/authorised/order/Order.js')
// );

const AllOrders = asyncComponent(() =>
  import('../../containers/authorised/order/AllOrders.js')
);
const RecentOrders = asyncComponent(() =>
  import('../../containers/authorised/order/RecentOrder.js')
);
const UnfinishedOrders = asyncComponent(() =>
  import('../../containers/authorised/order/UnfinishedOrders.js')
);
const FinalizedOrders = asyncComponent(() =>
  import('../../containers/authorised/order/FinalizedOrders.js')
);
const MaterialOrders = asyncComponent(() =>
  import('../../containers/authorised/order/MaterialOrder.js')
);
const ToBeOrdered = asyncComponent(() =>
  import('../../containers/authorised/order/ToBeOrdered.js')
);
const Ordered = asyncComponent(() =>
  import('../../containers/authorised/order/Ordered.js')
);
const ToBeDispensed = asyncComponent(() =>
  import('../../containers/authorised/order/ToBeDispensed')
);
const DispensedOrders = asyncComponent(() =>
  import('../../containers/authorised/order/DispensedOrder.js')
);
const ContactLens = asyncComponent(() =>
  import('../../containers/authorised/order/ContactLens')
);

const OrderRoutes = (
  <Switch>
    {/* <Route path="/order" component={Order} /> */}
    <Route exact path="/order/all-orders" component={AllOrders} />
    <Route exact path="/order/recent-orders" component={RecentOrders} />
    <Route exact path="/order/unfinished-orders" component={UnfinishedOrders} />
    <Route exact path="/order/finalized-orders" component={FinalizedOrders} />
    <Route exact path="/order/material-orders" component={MaterialOrders} />
    <Route exact path="/order/to-be-ordered" component={ToBeOrdered} />
    <Route exact path="/order/ordered" component={Ordered} />
    <Route exact path="/order/to-be-dispensed" component={ToBeDispensed} />
    <Route exact path="/order/dispensed-orders" component={DispensedOrders} />
    <Route exact path="/order/contact-lens" component={ContactLens} />
    <Redirect exact from="/order" to="/order/all-orders" />
  </Switch>
);

export default OrderRoutes;