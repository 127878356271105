import React, {Component} from 'react';
import { Field, FieldArray, change } from 'redux-form';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {Input} from '../../../form/'
import AutoComplete from '../../../others/AutoComplete';
import grid_icon from '../../../../assets/static/images/group.png';
import { onlyLetters} from '../../../form/FormValidations';

class ProcedureRecords extends Component {
  render() {
    const { fields, pprops, pprops: { procedures_attributes }, onDragEnd, pprops: {exam}} = this.props
    return (
      <div className="card-body">
        <div className="row">
          <div className="col-12">
            <label>Search Procedure</label>
            <AutoComplete 
              onSelect={(val) => { pprops.onSubmit({ id: pprops.examId, procedures_attributes: [{ settings_procedure_id: val }] }) }} 
              klass="ProcedurePricing" 
              otherFilters={{ filter: { for_store: exam.store_id } }} 
              textField="name" valueField="settings_procedure_id" 
              placeholder="Type in to search by Procedure Name or Code" 
              wrapperClasses="col-6 px-0"/>
          </div>
          <div className="col-12 mt-7">
            <div className="overview-table px-2">
              <DragDropContext onDragEnd={(dnd)=> onDragEnd(dnd, "Exam::Procedure", "procedures")}>
                <table className="table table-hover shadow-none">
                  <thead className="text-dark border-bottom bg-light border-white border-bottom-2">
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">No.</th>
                      <th scope="col">Procedure</th>
                      <th scope="col">Procedure Code</th>
                      <th scope="col">Impression pointer</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <Droppable droppableId="droppable">
                    {(provided) =>
                      <tbody ref={provided.innerRef}>
                        {fields.map((member, index) => (
                          <Draggable key={procedures_attributes[index].id} draggableId={`${procedures_attributes[index].id}`} index={index}>
                          {(provided, snapshot) => (
                            <tr ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps} style={{...provided.draggableProps.style}} key={`procedure-${procedures_attributes[index].id}`} className="text-dark bg-light border-bottom border-white border-bottom-2">
                              <td><img src={grid_icon} alt="grid"/></td>
                              <td>{index + 1}</td>
                              <td>{procedures_attributes[index].name}</td>
                              <td>{procedures_attributes[index].exam_code}</td>
                              <td>
                                <Field name={`${member}[pointer]`} onBlur={() => { setTimeout(() => pprops.submit(), 500) }} component={Input} wrapperClasses="form-group w-50" className="form-control" validate={[onlyLetters]} />
                              </td>
                              <td><a href="#"className="badge badge-pill badge-primary" onClick={() => { pprops.dispatch(change('procedureImpressionPlanForm', `procedures_attributes[${index}][_destroy]`, true)); setTimeout(() => pprops.submit(), 500) }}><i className="la la-trash"></i></a></td>
                            </tr>
                          )}
                      </Draggable>
                        ))}
                  </tbody> }
                    </Droppable>
                  </table>
                </DragDropContext>
                </div>
              </div>
            </div>
          </div>
    );
  }
}

const Procedures = props => {
  const {template, isTemplate, pprops, onDragEnd} = props
  return (
    <FieldArray name="procedures_attributes"
      props = {{
        template: template,
        isTemplate: isTemplate,
        pprops: pprops,
        onDragEnd: onDragEnd,
      }}
      component={ProcedureRecords} />
  );
}

export default Procedures;







