import Toastr from "../../components/Toastr"

let changes = null;
export default function importReducer(state = {}, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  switch(action.type){
    case 'IMPORT_LOAD_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case 'IMPORT_LOAD_FULFILLED':
      Toastr.handleShow(response.success, response.message, response.status_code)
      changes = {
        saving: false
      }
      return { ...state, ...changes };

    default:
      return state;
  }
}