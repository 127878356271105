import { combineReducers} from 'redux';

import messageTemplateReducer from './settingReducers/messageTemplateReducer';
import examTemplateReducer from './settingReducers/examTemplateReducer';
import contactLensReducer from './settingReducers/contactLensReducer';
import impressionReducer from './settingReducers/impressionReducer';
import procedureReducer from './settingReducers/procedureReducer';
import diseaseReducer from './settingReducers/diseaseReducer';
import dosageReducer from './settingReducers/dosageReducer';
import prescribingMedicationReducer from './settingReducers/prescribingMedicationReducer';
import eyeglassLensTypeReducer from './settingReducers/eyeglassLensTypeReducer';
import eyeglassLensMaterialReducer from './settingReducers/eyeglassLensMaterialReducer';
import eyeglassLensFrameReducer from './settingReducers/eyeglassLensFrameReducer';
import eyeglassAddOnReducer from './settingReducers/eyeglassAddOnReducer';
import miscItemReducer from './settingReducers/miscItemReducer';
import recommendationReducer from './settingReducers/recommendationReducer';
import medicationReducer from './settingReducers/medicationReducer';
import patientTemplateReducer from './settingReducers/patientTemplateReducer';
import insuranceReducer from './settingReducers/insuranceReducer';
import socialHistoryReducer from './settingReducers/socialHistoryReducer';
import socialHistorySectionReducer from './settingReducers/socialHistorySectionReducer';
import contactLensCleaningSolutionReducer from './settingReducers/contactLensCleaningSolutionReducer';
import contactReducer from './settingReducers/contactReducer'
import reviewOfSystemReducer from './settingReducers/reviewOfSystemReducer'
import calendarSettingReducer from './settingReducers/calendarSettingReducer'
import anonymousBookingReducer from './settingReducers/anonymousBookingReducer'
import surveyParameterReducer from './settingReducers/surveyParameterReducer'
import patientReportReducer from './settingReducers/patientReportReducer';
import orgConfigReducer from './settingReducers/orgConfigReducer';
import insurancePlanReducer from './settingReducers/insurancePlanReducer';
import planProcedureReducer from './settingReducers/planProcedureReducer';

const settingReducer = combineReducers({
  messageTemplate: messageTemplateReducer,
  examTemplate: examTemplateReducer,
  contactLens: contactLensReducer,
  impression: impressionReducer,
  procedure: procedureReducer,
  disease: diseaseReducer,
  dosage: dosageReducer,
  prescribingMedication: prescribingMedicationReducer,
  eyeglassLensType: eyeglassLensTypeReducer,
  eyeglassLensMaterial: eyeglassLensMaterialReducer,
  eyeglassLensFrame: eyeglassLensFrameReducer,
  eyeglassAddOn: eyeglassAddOnReducer,
  miscItem: miscItemReducer,
  recommendation: recommendationReducer,
  medication: medicationReducer,
  patientTemplate: patientTemplateReducer,
  insurance: insuranceReducer,
  insurancePlan: insurancePlanReducer,
  planProcedure: planProcedureReducer,
  socialHistory: socialHistoryReducer,
  socialHistorySection: socialHistorySectionReducer,
  contactLensCleaningSolution: contactLensCleaningSolutionReducer,
  contact: contactReducer,
  reviewOfSystem: reviewOfSystemReducer,
  calendarSetting: calendarSettingReducer,
  anonymousBooking: anonymousBookingReducer,
  surveyParameter: surveyParameterReducer,
  patientReport: patientReportReducer,
  orgConfig: orgConfigReducer,
});

export default settingReducer;
