import React, { Component } from 'react';
import { reduxForm, FieldArray, change } from 'redux-form';
import { connect } from 'react-redux';
import { AutoComplete } from '../../../../itrust_common/components/others';
import ImportWrapper from './import/ImportWrapper';
import { orderCloseModal } from '../../../../redux/actions/order/orderActions';

class RenderImpression extends React.Component {
  render() {
    const { fields, pprops } = this.props
    return (
      <tbody>
        {fields.map((member, index, members) => (
          <tr key={`impression-${index}`} className="text-dark bg-light border-bottom border-white border-bottom-2">
            <td>{String.fromCharCode(index + 97).toUpperCase()}</td>
            <td>{members.get(index).description}</td>
            <td>{members.get(index).code}</td>
            <td>
              <a className="badge badge-pill badge-primary" onClick={() => { pprops.dispatch(change('orderImpressionForm', `${member}[_destroy]`, true)); setTimeout(() => pprops.submit(), 500) }}>
                <i className="la la-trash"></i>
              </a>
            </td>
          </tr>
        ))}
      </tbody>
    );
  }
}

class ImpressionForm extends Component {

  componentWillUnmount() {
    if (this.props.dirty) {
      this.props.handleSubmit();
      this.props.dispatch(orderCloseModal(false))
    }
  }

  render() {
    const { handleSubmit, order, onSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <div className="col-12 mb-7 mt-8">
          <div className="d-flex justify-content-between align-items-center">
            <h5>Impression</h5>
            <ImportWrapper order={order} importName="impression" />
          </div>
        </div>
        <div className="card border-0 shadow-sm px-5">
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                <label>Add Impression</label>
                <AutoComplete onSelect={(id) => { onSubmit({ id: order.id, impressions_attributes: [{ settings_impression_id: id }] }) }} klass="Settings::Impression" textField="name" valueField="id" placeholder="Type in to search by impression name or code" wrapperClasses="col-4 px-0" />
              </div>
              <div className="col-12 mt-7">
                <div className="overview-table px-2">
                  <table className="table table-hover shadow-none">
                    <thead className="text-dark border-bottom bg-light border-white border-bottom-2">
                      <tr>
                        <th scope="col">No.</th>
                        <th scope="col">Impression</th>
                        <th scope="col">Code</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <FieldArray name="impressions_attributes"
                      props={{
                        order: order,
                        onSubmit: onSubmit,
                        pprops: this.props
                      }}
                      component={RenderImpression} />
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  }
}

ImpressionForm = reduxForm({
  form: 'orderImpressionForm',
  enableReinitialize: true,
  onChange: (values, dispatch, props) => {
    if (props.dirty) {
      dispatch(orderCloseModal(true))
    }
  }
})(ImpressionForm)

ImpressionForm = connect(state => {
  const { order } = state.order
  return {
    order: order,
    initialValues: {
      id: order.id,
      impressions_attributes: order.impressions_attributes,
    }
  }
})(ImpressionForm)

export default ImpressionForm;
