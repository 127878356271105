import Toastr from "../../../itrust_common/components/Toastr"
const DEFAULT_STATE = {
  exams: [],
  exam: {},
  meta: { pagination: {} },
  saving: false,
  editing: false
}
let changes = null;
export default function examReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let exams = [];
  let examData = [];
  let updatedRecord = {};
  switch(action.type){
    case 'EXAMS_LOAD_PENDING':
    case 'EXAM_LOAD_PENDING':
    case 'EXAM_NEW_LOAD_PENDING':
    case 'EXAM_UPDATE_PENDING':
      return {...state, saving: true};

    case 'EXAM_CREATE_PENDING':
      return {...state, saving: true};

    case 'EXAMS_LOAD_REJECTED':
    case 'EXAM_LOAD_REJECTED':
    case 'EXAM_CREATE_REJECTED':
    case 'EXAM_DELETE_REJECTED':
    case 'EXAM_SECURE_FAX_REJECTED':
    case 'EXAM_UPDATE_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = {
        saving: false,
        templateSwitchSaving: false,
      }
      return {...state,...changes};

    case 'EXAMS_LOAD_FULFILLED':
      changes = {
        ...response,
        saving: false
      };
      return {...state, ...changes};
    
    case 'EXAM_LOAD_FULFILLED':
      changes = {
        exam: response.exam,
        saving: false
      }
      return {...state,...changes};

    case 'EXAM_CREATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      exams = [...state.exams]
      let newRecord = response.exam
      changes = {
        exams: [...exams, newRecord],
        exam: newRecord,
        editing: false,
        saving: false
      }
      return {...state, ...changes};

    case 'EXAM_UPDATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      exams = [...state.exams];
      updatedRecord = response.exam;
      for (const [index, exam] of exams.entries()) {
        if (exam.id === updatedRecord.id) {
          examData = [
            ...exams.slice(0, index),
            updatedRecord,
            ...exams.slice(index + 1)
          ];
        }
      }
      changes = {
        exams: examData,
        exam: updatedRecord,
        saving: false,
        templateSwitchSaving: false,
      };
      return { ...state, ...changes };

    case 'EXAM_DELETE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      exams = [...state.exams];
      for (const [index, exam] of exams.entries()) {
        if (exam.id === response.exam.id) {
          exams.splice(index, 1);
        }
      }
      return {
        ...state,
        exams: exams,
        error: null
      };

    case 'EXAM_PROCEDURE_IMPRESSION_REORDER':
      examData = state.exam;
      changes = {
          exam: { ...examData, ...action.data },
        };
      return { ...state, ...changes };

    case 'EXAM_TEMPLATE_SWITCHED':
      changes = {
        templateSwitchSaving: { ...action.data },
      };
      return { ...state, ...changes };

    case 'EXAM_EDIT':
      changes = {
        editing: {
          id: action.id,
          data: action.data
        },
        exam: DEFAULT_STATE['exam']
      };
      return { ...state, ...changes };

    case 'EXAM_EDIT_CANCEL':
      changes = {
        editing: false
      };
      return { ...state, ...changes };

    case 'EXAM_SECURE_FAX_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      return state;

    case 'EMAIL_PRESCRIPTION_REPORT_FULFILLED':
      Toastr.handleShow(response.success, response.message, 201)
      return state;

    case 'EXAM_RESET':
      return { ...state, ...DEFAULT_STATE };
      
    default:
      return state;
  }
}