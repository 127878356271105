import {handleAPI, API_VERSION_ONE} from '../../../utils/apiUtils';
const parent = 'SETTING_'

export function calendarSettingLoad(filters){
  const url = `/${API_VERSION_ONE}/settings/appointment_setting`;
  const method = 'GET'

  return {
    type: `${parent}CALENDAR_SETTING_LOAD`,
    payload: handleAPI(url,filters,method)
  };
}

export function calendarSettingUpdate(data, filters){
  const url = `/${API_VERSION_ONE}/settings/appointment_setting`;
  const method = "PUT"
  return {
    type: `${parent}CALENDAR_SETTING_UPDATE`,
    payload: handleAPI(url,filters,method, {appointment_setting: {...data}})
  }
}