import React, {Component} from 'react';
import AutoComplete from '../../../../itrust_common/components/others/AutoComplete';
 import MedicalHistoryForm from './MedicalHistoryForm'
class MedicalHistoryFormWrapper extends Component{
  render(){
    const {handleSubmit, belongsToId, handleDeleteAll, name, records, klass, diseaseType, medicalHistoryCategory} = this.props
    return (
      <div className="col-lg-6 col-md-12 mt-7">
        <div className="card border-0 shadow-sm">
          <div className="p-5 border-bottom border-light d-flex flex-row align-items-center justify-content-between">
            <h6>{name}</h6>
            <div>
              <div className="btn-group btn-group-toggle" data-toggle="buttons">
                <label onClick={()=> records && handleDeleteAll(medicalHistoryCategory)} className={`btn btn-outline-primary ${records && records.length > 0 ? '' : 'active'}`}>
                  <input type="radio" name="options" id="option1" autocomplete="off" checked=""/> Negative
                </label>
                <label className={`btn btn-outline-primary ${records && records.length > 0 ? 'active' : ''}`}>
                  <input type="radio" name="options" id="option2" autocomplete="off"/> Positive
                </label>
              </div>
            </div>
          </div>
          <div className="card-body px-7">
            <div className=" border-bottom border-light pb-5">
              <AutoComplete key={medicalHistoryCategory} addNewSetting = {medicalHistoryCategory}  textField="name" valueField="id" fieldName={medicalHistoryCategory} placeholder={`Search to add ${name}`} onSelect={(id) => handleSubmit({ [belongsToId]: id, medical_history_category: medicalHistoryCategory })} klass={klass} otherFilters={{filter: {disease_type: diseaseType}}} wrapperClasses="w-100 position-relative" />
            </div>
            {records && records.map((record, index) =>
              <MedicalHistoryForm key={`medicalHistoryCategory${record.id}`} {...this.props} onSubmit={handleSubmit} form={`medicalHistoryCategory${record.id}`} initialValues={{...record}}/>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default MedicalHistoryFormWrapper;
