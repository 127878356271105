import React from 'react';
import MedicalHistoryWrapper from './MedicalHistoryView'
import NeurologicalPsychologicalStatus from './NeurologicalPsychologicalStatus'

let MedicalHistory = props => {
  const { fieldsValues, neurologicalStatus, isPdfView } = props;
  return (
    <div className="row">
      <div className={`${isPdfView ? 'col-4' : 'col-6'}`}>
        <MedicalHistoryWrapper 
          records={fieldsValues.medical_history} 
          name="Patient Medical History" 
          isFamily={false} 
          isDatesVisible={true} />
      </div>
      <div className={`${isPdfView ? 'col-4' : 'col-6'}`}>
        <MedicalHistoryWrapper 
          records={fieldsValues.family_medical_history} 
          name="Family Medical History" 
          isFamily={true} 
          isDatesVisible={true} />
      </div>
      <div className={`${isPdfView ? 'col-4' : 'col-6'}`}>
        <MedicalHistoryWrapper 
          records={fieldsValues.ocular_history} 
          name="Patient Ocular History" 
          isFamily={false} 
          isDatesVisible={true} />
      </div>
      <div className={`${isPdfView ? 'col-4' : 'col-6'}`}>
        <MedicalHistoryWrapper 
          records={fieldsValues.family_ocular_history} 
          name="Family Ocular History" 
          isFamily={true}
          isDatesVisible={true} />
      </div>
      <div className={`${isPdfView ? 'col-4' : 'col-6'}`}>
        <MedicalHistoryWrapper 
          records={fieldsValues.allergy} 
          name="Patient Allergies" 
          isFamily={false}
          isDatesVisible={false} />
      </div>
      <div className={`${isPdfView ? 'col-4' : 'col-6'}`}>
        <MedicalHistoryWrapper 
          records={fieldsValues.surgery} 
          name="Patient Surgical History" 
          isFamily={false}
          isDatesVisible={false} />
      </div>
      <div className={`${isPdfView ? 'col-4' : 'col-6'}`}>
        <MedicalHistoryWrapper 
          records={fieldsValues.medication} 
          name="Patient Medications" 
          isFamily={false}
          isDatesVisible={false} />
      </div>
      <div className={`${isPdfView ? 'col-4' : 'col-6'}`}>
        {/* <LastExamForm /> */}
        <NeurologicalPsychologicalStatus neurologicalStatus={neurologicalStatus}/>
      </div>

    </div>
  )
}


export default MedicalHistory;