const DEFAULT_STATE = {
  isTourActive: false,
  nextModule: false,
  activeTourType: '',
  nextModuleToPlay: '',
  stepIndex: 0,
  moduleTourCompleted: false,
  progressPopUpInfo: {
    checkListShow: false,
    demoPatientAppointmentId: null,
    demoPatientAppointments: [],
    moduleProgressHide: false,
  },
  onBoardingGuide: {
    login: true,
    create_appointment: false,
    create_patient: false,
    create_exam: false,
    create_order: false,
    activate_account: false,
  }
}

export default function onBoardingReducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case 'ONBOARDING_TOUR_INFO_UPDATE':
      return { ...state, ...action.data, progressPopUpInfo: { ...state.progressPopUpInfo }, onBoardingGuide: { ...state.onBoardingGuide } };

    case 'ONBOARDING_TOUR_INFO_RESET':
      return { ...DEFAULT_STATE, moduleTourCompleted: action.moduleTourCompleted, progressPopUpInfo: { ...state.progressPopUpInfo, }, onBoardingGuide: { ...state.onBoardingGuide } };

    case 'ONBOARDING_TOUR_PROGRESS_INFO_UPDATE':
      return { ...state, progressPopUpInfo: { ...state.progressPopUpInfo, ...action.data } };

    case 'ONBOARDING_TOUR_PROGRESS_INFO_RESET':
      return { ...state, progressPopUpInfo: { ...DEFAULT_STATE.progressPopUpInfo } };

    case 'ONBOARDING_TOUR_GUIDE_INFO_UPDATE':
      return { ...state, onBoardingGuide: { ...state.onBoardingGuide, ...action.data } };

    case 'ONBOARDING_TOUR_GUIDE_INFO_RESET':
      return { ...state, onBoardingGuide: { ...DEFAULT_STATE.onBoardingGuide } };

    case 'ONBOARDING_TOUR_RESET':
      return { ...DEFAULT_STATE };

    default:
      return state;
  }
}
