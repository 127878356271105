import {handleAPI, API_VERSION_ONE} from '../../../itrust_common/utils/apiUtils';

export function patientReviewOfSystemsLoad(patient_id, filters){
  const url = `/${API_VERSION_ONE}/patients/${patient_id}/patient_review_of_systems`;
  const method = 'GET'
  return {
    type: 'PATIENT_REVIEW_OF_SYSTEMS_LOAD',
    payload: handleAPI(url, filters, method)
  };
}

export function patientReviewOfSystemLoad(patient_id, id, filters){
  const url = `/${API_VERSION_ONE}/patients/${patient_id}/patient_review_of_systems/${id}`;
  const method = 'GET'

  return {
    type: 'PATIENT_REVIEW_OF_SYSTEM_LOAD',
    payload: handleAPI(url,filters,method)
  };
}

export function patientReviewOfSystemCreate(patient_id, data, filters){
  const url = `/${API_VERSION_ONE}/patients/${patient_id}/patient_review_of_systems`;
  const method= 'POST'
  return {
    type: 'PATIENT_REVIEW_OF_SYSTEM_CREATE',
    payload: handleAPI(url,filters,method, {patient_review_of_system: {...data}})
  }
}

export function patientReviewOfSystemUpdate(patient_id, data, filters){
  const url = `/${API_VERSION_ONE}/patients/${patient_id}/patient_review_of_systems/${data.id}`;
  const method = "PUT"
  return {
    type: 'PATIENT_REVIEW_OF_SYSTEM_UPDATE',
    payload: handleAPI(url,filters,method, {patient_review_of_system: {...data}})
  }
}

export function patientReviewOfSystemUpdateAllToNo(patient_id, filters){
  const url = `/${API_VERSION_ONE}/patients/${patient_id}/patient_review_of_systems/update_all_to_no`;
  const method = "PUT"
  return {
    type: 'PATIENT_REVIEW_OF_SYSTEM_UPDATE_ALL_TO_NO',
    payload: handleAPI(url,filters,method)
  }
}

export function patientReviewOfSystemDelete(patient_id, id, filters) {
  const url = `/${API_VERSION_ONE}/patients/${patient_id}/patient_review_of_systems/${id}`;
  const method = 'DELETE';

  return {
    type: 'PATIENT_REVIEW_OF_SYSTEM_DELETE',
    payload: new Promise((resolve, reject) => {
      handleAPI(url, filters, method)
        .then(response => {
          resolve({ id: id, ...response });
        })
        .catch(err => {
          reject(err);
        });
    })
  };
}

export function patientReviewOfSystemEdit(id) {
  return {
    type: 'PATIENT_REVIEW_OF_SYSTEM_EDIT',
    id,
  };
}

export function patientReviewOfSystemEditCancel() {
  return {
    type: 'PATIENT_REVIEW_OF_SYSTEM_EDIT_CANCEL'
  };
}
