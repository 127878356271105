import Toastr from "../../../itrust_common/components/Toastr"

const parent = 'PUBLIC_'

const DEFAULT_STATE = {
  survey_parameters: [],
  patient_survey: {}
}
let changes = null;
export default function patientSurveyReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  switch(action.type){
    case `${parent}SURVEY_PARAMETERS_LOAD_PENDING`:
    case `${parent}SURVEY_PARAMETERS_CREATE_PENDING`:
      return {...state};

    case `${parent}SURVEY_PARAMETERS_LOAD_REJECTED`:
    case `${parent}SURVEY_PARAMETERS_CREATE_REJECTED`:
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      return {...state};

    case `${parent}SURVEY_PARAMETERS_LOAD_FULFILLED`:
      changes = {
        ...response,
      };
      return {...state, ...changes};
    
    case `${parent}SURVEY_PARAMETERS_CREATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      changes = {
        ...response,
        saving: false
      };
      return {...state, ...changes};
    
    default:
      return state;
  }
}