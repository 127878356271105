import React from 'react';
import DisplayField from '../../../../../itrust_common/components/others/DisplayField'
import { dateFormat } from '../../../../../itrust_common/components/HelperFunctions';

let LensView = props => {
  const { fieldsValues, template } = props;
  return (
    <div className="row">
      <div className="col-12">
        <div className="overview-table mb-3">
          <table className="table table-bordered bg-light table-hover shadow-none border border-muted">
            <thead className="text-dark border border-muted">
              <tr className="text-center">
                <th scope="col"></th>
                <th scope="col">
                  <DisplayField
                    isVisible={true}
                    label={"Name"}
                  />
                </th>
                <th scope="col">
                  <DisplayField
                    isVisible={template.fields.od_sphere.visible}
                    label={"SPH"}
                  />
                </th>
                <th scope="col">
                  <DisplayField
                    isVisible={template.fields.od_cylinder.visible}
                    label={"CYL"}
                  />
                </th>
                <th scope="col">
                  <DisplayField
                    isVisible={template.fields.od_axis.visible}
                    label={"AXIS"}
                  />
                </th>
                <th scope="col">
                  <DisplayField
                    isVisible={template.fields.od_add.visible}
                    label={"ADD"}
                  />
                </th>
                <th scope="col">
                  <DisplayField
                    isVisible={template.fields.od_curve.visible}
                    label={"BC"}
                  />
                </th>
                <th scope="col">
                  <DisplayField
                    isVisible={template.fields.od_diameter.visible}
                    label={"DIA"}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {["od", "os", "ou"].map(type =>
                <tr className="text-center">
                  <td>
                    <h6>{type.toUpperCase()}</h6>
                  </td>
                  <td>
                    <DisplayField
                      isVisible={true}
                      value={fieldsValues[`${type}_contact_lens_brand_name`]}
                    />
                  </td>
                  <td>
                    <DisplayField
                      isVisible={true}
                      value={fieldsValues[`${type}_sphere`]}
                    />
                  </td>
                  <td>
                    <DisplayField
                      isVisible={true}
                      value={fieldsValues[`${type}_cylinder`]}
                    />
                  </td>
                  <td>
                    <DisplayField
                      isVisible={true}
                      value={fieldsValues[`${type}_axis`]}
                    />
                  </td>
                  <td>
                    <DisplayField
                      isVisible={true}
                      value={fieldsValues[`${type}_add`]}
                    />
                  </td>
                  <td>
                    <DisplayField
                      isVisible={true}
                      value={fieldsValues[`${type}_curve`]}
                    />
                  </td>
                  <td>
                    <DisplayField
                      isVisible={true}
                      value={fieldsValues[`${type}_diameter`]}
                    />
                  </td>
                </tr>
              )
              }
            </tbody>
          </table>
        </div>
      </div>
      <DisplayField
        isVisible={template.fields.issue_date.visible}
        label={`${template.fields.issue_date.custom_label}:`}
        value={dateFormat(fieldsValues.issue_date)}
        className="col-2 py-4"
      />
      <DisplayField
        isVisible={template.fields.expiry_date.visible}
        label={`${template.fields.expiry_date.custom_label}:`}
        value={dateFormat(fieldsValues.expiry_date)}
        className="col-2 py-4"
      />
      { fieldsValues.cleaning_solution && <DisplayField
        isVisible={template.fields.cleaning_solution.visible}
        label={`${template.fields.cleaning_solution.custom_label}:`}
        value={fieldsValues.cleaning_solution}
        className="col-2 py-4"
      /> }
      
      { fieldsValues.notes && <DisplayField
        isVisible={template.fields.notes.visible}
        label={`${template.fields.notes.custom_label}:`}
        value={fieldsValues.notes}
        className="col-2 py-4"
      /> }
    </div>
  )
}


export default LensView;