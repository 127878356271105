import Toastr from "../../../itrust_common/components/Toastr"
const DEFAULT_STATE = {
  orders: [],
  order: {},
  meta: { pagination: {} },
  saving: false,
  editing: false,
  isFormChanged: false
}
let changes = null;
export default function orderReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let orders = [];
  let orderData = [];
  let updatedRecord = {};
  switch(action.type){
    case 'ORDERS_LOAD_PENDING':
    case 'ORDER_LOAD_PENDING':
    case 'ORDER_NEW_LOAD_PENDING':
      return {...state, saving: true};

    case 'ORDER_CREATE_PENDING':
      return {...state, saving: true};
  
    case 'ORDERS_LOAD_REJECTED':
    case 'ORDER_LOAD_REJECTED':
    case 'ORDER_CREATE_REJECTED':
    case 'ORDER_DELETE_REJECTED':
    case 'ORDER_UPDATE_REJECTED':
    case 'DUPLICATE_ORDER_CREATE_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case 'ORDERS_LOAD_FULFILLED':
      changes = {
        ...response,
        saving: false
      };
      return {...state, ...changes};
    
    case 'ORDER_LOAD_FULFILLED':
      changes = {
        order: response.order,
        saving: false
      }
      return {...state,...changes};

    case 'ORDER_CREATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      orders = [...state.orders]
      let newRecord = response.order
      changes = {
        orders: [...orders, newRecord],
        order: newRecord,
        saving: false
      }
      return {...state, ...changes};

    case 'DUPLICATE_ORDER_CREATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      orders = [...state.orders]
      updatedRecord = response.order
      changes = {
        orders: [...orders, updatedRecord],
        saving: false
      }
      return { ...state, ...changes };

    case 'ORDER_UPDATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      orders = [...state.orders];
      updatedRecord = response.order;
      for (const [index, order] of orders.entries()) {
        if (order.id === updatedRecord.id) {
          orderData = [
            ...orders.slice(0, index),
            updatedRecord,
            ...orders.slice(index + 1)
          ];
        }
      }
      changes = {
        orders: orderData,
        order: updatedRecord,
        meta: {...state.meta, ...response.meta},
        saving: false
      };
      return { ...state, ...changes };

    case 'ORDER_DELETE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      orders = [...state.orders];
      for (const [index, order] of orders.entries()) {
        if (order.id === response.order.id) {
          orders.splice(index, 1);
        }
      }
      return {
        ...state,
        orders: orders,
        error: null
      };

    case 'ORDER_PROCEDURE_IMPRESSION_REORDER':
      orderData = state.order;
      changes = {
          order: { ...orderData, ...action.data },
        };
      return { ...state, ...changes };

    case 'ORDER_EDIT':
      changes = {
        editing: {
          id: action.id,
          data: action.data
        },
      };
      return { ...state, ...changes };

    case 'ORDER_EDIT_CANCEL':
      changes = {
        editing: false
      };
      return { ...state, ...changes };

    case 'ORDER_RESET':
      return { ...state, ...DEFAULT_STATE };

    case 'ORDER_CLOSE_MODAL':
      return { ...state, isFormChanged: action.payload };

    default:
      return state;
  }
}