import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import asyncComponent from '../../itrust_common/components/AsyncComponent';

const Overview = asyncComponent(() =>
  import('../../containers/authorised/dashboard/Overview.js')
);

const RecentOrders = asyncComponent(() =>
  import('../../containers/authorised/dashboard/order/RecentOrder.js')
);

const RecentExams = asyncComponent(() =>
  import('../../containers/authorised/dashboard/exam/RecentExams.js')
);

const UnfinishedOrders = asyncComponent(() =>
  import('../../containers/authorised/dashboard/order/UnfinishedOrders.js')
);

const UnfinishedExams = asyncComponent(() =>
  import('../../containers/authorised/dashboard/exam/UnfinishedExams.js')
);


const ToBeDispensed = asyncComponent(() =>
  import('../../containers/authorised/dashboard/order/ToBeDispensed')
);

const DashboardRoutes = (
  <Switch>
    {/* <Route exact path="/dashboard" component={Dashboard} /> */}
    <Route exact path="/dashboard/recent-orders" component={RecentOrders} />
    <Route exact path="/dashboard/recent-exams" component={RecentExams} />
    <Route exact path="/dashboard/unfinished-orders" component={UnfinishedOrders} />
    <Route exact path="/dashboard/unfinished-exams" component={UnfinishedExams} />
    <Route exact path="/dashboard/to-be-dispensed" component={ToBeDispensed} />
    <Route exact path="/dashboard/overview" component={Overview} />
    <Redirect exact from="/" to="/dashboard/overview" />
    <Redirect exact from="/dashboard" to="/dashboard/overview" />
    <Redirect exact from="/doctor/sign-in" to="/dashboard/overview" />
  </Switch>
);

export default DashboardRoutes;