import React from 'react';
import DisplayField from '../../../../../itrust_common/components/others/DisplayField'

let NeurologicalPsychologicalStatus = props => {
  const { neurologicalStatus } = props;
  return (
    <div className="row">
      <div className="col-12">
        <div className="border-bottom">
          <h6 className="pb-3 pt-6">Neurological Psychological Status</h6>
        </div>
      </div>
        <div className="col-12 pt-4">
          <div className="overview-table mb-3">
            <table className="table table-bordered bg-light table-hover shadow-none border border-muted">
              <tbody>
                <tr>
                  <td>
                    <DisplayField
                      isVisible={true}
                      label={'Agitated'}
                    />
                  </td>
                  <td>
                    <DisplayField
                      isVisible={true}
                      value={neurologicalStatus && neurologicalStatus.agitated ? "Yes" : "No"}
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    <DisplayField
                      isVisible={true}
                      label={'Anxious'}
                    />
                  </td>
                  <td>
                    <DisplayField
                      isVisible={true}
                      value={neurologicalStatus && neurologicalStatus.anxious ? "Yes" : "No"}
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    <DisplayField
                      isVisible={true}
                      label={'Depressed'}
                    />
                  </td>
                  <td>
                    <DisplayField
                      isVisible={true}
                      value={neurologicalStatus && neurologicalStatus.depressed ? "Yes" : "No"}
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    <DisplayField
                      isVisible={true}
                      label={'Oriented x3'}
                    />
                  </td>
                  <td>
                    <DisplayField
                      isVisible={true}
                      value={neurologicalStatus && neurologicalStatus.oriented_x3 ? "Yes" : "No"}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
    </div>
  )
}


export default NeurologicalPsychologicalStatus;
