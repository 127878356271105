import React from 'react';
import { Route, Switch, Redirect} from 'react-router-dom';
import asyncComponent from '../../itrust_common/components/AsyncComponent';

const RecentExams = asyncComponent(() =>
  import('../../containers/authorised/exam/RecentExams.js')
);

const UnfinishedExams = asyncComponent(() =>
  import('../../containers/authorised/exam/UnfinishedExams.js')
);

const FinalizedExams = asyncComponent(() =>
  import('../../containers/authorised/exam/FinalizedExams')
);

const ExamRoutes = (
  <Switch>
    <Route exact path="/exam/recent-exams" component={RecentExams} />
    <Route exact path="/exam/unfinished-exams" component={UnfinishedExams} />
    <Route exact path="/exam/finalized-exams" component={FinalizedExams} />
    <Redirect exact from="/exam" to="/exam/recent-exams" />
  </Switch>
);

export default ExamRoutes;