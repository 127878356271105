import Toastr from "../../../components/Toastr"
const parent = 'SETTING_'

const DEFAULT_STATE = {
  insurances: [],
  insurance: {},
  meta: {pagination: {}},
  saving: false
}
let changes = null;
export default function insuranceReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let insurances = [];
  let insuranceData = [];
  let updatedRecord = {};
  switch(action.type){
    case `${parent}INSURANCES_LOAD_PENDING`:
    case `${parent}INSURANCE_LOAD_PENDING`:
      return {...state, saving: false};

    case `${parent}INSURANCE_CREATE_PENDING`:
    case `${parent}INSURANCE_UPDATE_PENDING`:
      return {...state, saving: true};

    case `${parent}INSURANCES_LOAD_REJECTED`:
    case `${parent}INSURANCE_LOAD_REJECTED`:
    case `${parent}INSURANCE_CREATE_REJECTED`:
    case `${parent}INSURANCE_DELETE_REJECTED`:
    case `${parent}INSURANCE_UPDATE_REJECTED`:
      Toastr.handleShow(response.data.success, response.data.error, response.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case `${parent}INSURANCES_LOAD_FULFILLED`:
      changes = {
        ...response,
        saving: false
      };
      return {...state, ...changes};
    
    case `${parent}INSURANCE_LOAD_FULFILLED`:
      changes = {
        insurance: response.insurance,
        saving: false
      }
      return {...state,...changes};
    
    case `${parent}INSURANCE_CREATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      insurances = [...state.insurances]
      let newRecord = response.insurance
      changes = {
        insurances: [...insurances, newRecord],
        editing: false,
        saving: false
      }
      return {...state, ...changes};

    case `${parent}INSURANCE_UPDATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      insurances = [...state.insurances];
      updatedRecord = response.insurance;
      for (const [index, insurance] of insurances.entries()) {
        if (insurance.id === updatedRecord.id) {
          insuranceData = [
            ...insurances.slice(0, index),
            updatedRecord,
            ...insurances.slice(index + 1)
          ];
        }
      }
      changes = {
        insurances: insuranceData,
        saving: false,
        editing: false,
      };
      return { ...state, ...changes };

    case `${parent}INSURANCE_DELETE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      insurances = [...state.insurances];
      for (const [index, insurance] of insurances.entries()) {
        if (insurance.id === response.insurance.id) {
          insurances.splice(index, 1);
        }
      }
      return {
        ...state,
        insurances: insurances,
        error: null
      };

    case `${parent}INSURANCE_EDIT`:
      changes = {
        editing: {
          id: action.id,
        },
        insurance: {insurance_type: action.insuranceType}
      };
      return { ...state, ...changes };

    case `${parent}INSURANCE_EDIT_CANCEL`:
      changes = {
        editing: false
      };
      return { ...state, ...changes };

    default:
      return state;
  }
}