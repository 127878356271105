import React from 'react'
import { connect, useDispatch } from 'react-redux';
import { VoipNotificationLoad, voipNotificationUpdate } from '../../../../redux/actions/addOns/voip/voipActions';
import NotificationsListingWrapper from '../../notification/NotificationsListingWrapper';
import VoipAdvertisement from './VoipAdvertisement';
import { notificationUpdate } from '../../../../itrust_common/redux/actions/notificationActions';

function VoipNotificationList(props) {
  const { voipNotifications, saving, unread_count, totalRecords, voipConfig } = props
  const dispatch = useDispatch()

  const handleVoipNotificationLoad = (filters) => {
    dispatch(VoipNotificationLoad({ activity_type: ['missed_call', 'voicemail_received'], ...filters, include: ['activity'] }))
  }

  return (
    <NotificationsListingWrapper
      id='voip'
      saving={saving}
      totalRecords={totalRecords}
      unread_count={unread_count}
      notifications={voipNotifications}
      iconClasses={'las la-phone'}
      header={"iTRUST Voice"}
      NotificationAdvertisement={VoipAdvertisement}
      logsRoute={'/add-ons/voip/call-logs'}
      isAddonActive={voipConfig?.status !== 'inactive'}
      handleNotificationLoad={handleVoipNotificationLoad}
      handleNotificationUpdate={voipNotificationUpdate}
    />
  )
}

const mapStateToProps = (state) => {
  const { voip, organisation: { organisation } } = state
  return {
    voipConfig: organisation.add_ons_config?.type?.voip,
    totalRecords: voip.meta?.pagination.count,
    voipNotifications: voip.notifications,
    unread_count: voip.meta?.unread_count,
    saving: voip.saving,
  }
}

export default connect(mapStateToProps)(VoipNotificationList)