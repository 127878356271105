import { handleAPI, API_VERSION_ONE } from '../../../itrust_common/utils/apiUtils';

export function orderPaymentsLoad(orderId, filters) {
  const url = `/${API_VERSION_ONE}/orders/${orderId}/payments`;
  const method = 'GET'

  return {
    type: 'ORDER_PAYMENTS_LOAD',
    payload: handleAPI(url, filters, method)
  };
}

export function orderPaymentCreate(orderId, data, filters) {
  const url = `/${API_VERSION_ONE}/orders/${orderId}/payments`;
  const method = 'POST'

  return {
    type: 'ORDER_PAYMENT_CREATE',
    payload: handleAPI(url, filters, method, { payment: { ...data } })
  };
}

export function orderPaymentDelete(orderId, data, filters) {
  const url = `/${API_VERSION_ONE}/orders/${orderId}/payments/${data.payments_attributes.id}`;
  const method = 'DELETE'

  return {
    type: 'ORDER_PAYMENT_DELETE',
    payload: handleAPI(url, filters, method, { payment: { ...data } })
  };
}

export function orderPaymentUpdate(orderId, data, filters) {
  const id = data.id
  const url = `/${API_VERSION_ONE}/orders/${orderId}/payments/${id}`;
  const method = "PUT"
  return {
    type: 'ORDER_PAYMENT_UPDATE',
    payload: handleAPI(url, filters, method, { payment: { ...data } })
  }
}

export function orderPaymentEdit(id) {
  return {
    type: 'ORDER_PAYMENT_EDIT',
    id,
  };
}

export function orderPaymentEditCancel() {
  return {
    type: 'ORDER_PAYMENT_EDIT_CANCEL'
  };
}

// Device Payment
export function initiateDevicePayment(data, filters) {
  const url = `/${API_VERSION_ONE}/add_ons/itrust_pay/logs`;
  const method = 'POST'

  return {
    type: `INITIATE_DEVICE_PAYMENT`,
    payload: handleAPI(url, filters, method, data)
  };
}

export function devicePaymentLogDelete(data, filters) {
  const url = `/${API_VERSION_ONE}/add_ons/itrust_pay/logs/${data.id}`;
  const method = 'DELETE'

  return {
    type: `DEVICE_PAYMENT_LOG_DELETE`,
    payload: handleAPI(url, filters, method, data)
  };
}

export function devicePaymentStatusUpdate(data) {
  return {
    type: `DEVICE_PAYMENT_STATUS_UPDATE`,
    payload: data
  };
}

export function devicePaymentLogLoad(deviceLogId, filters) {
  const url = `/${API_VERSION_ONE}/add_ons/itrust_pay/logs/${deviceLogId}`;
  const method = 'GET'
  return {
    type: 'DEVICE_PAYMENT_LOG_LOAD',
    payload: handleAPI(url, filters, method)
  };
}

// Pay via Link
export function sendPaymentLink(data, filters) {
  const url = `/${API_VERSION_ONE}/orders/${data.id}/payments/create_payment_link`;
  const method = 'POST'

  return {
    type: 'SEND_PAYMENT_URL',
    payload: handleAPI(url, filters, method, { ...data })
  };
}

export function submitPaymentByLink(data, filters) {
  const url = `/${API_VERSION_ONE}/orders/${data.id}/payments/submit_payment_link`;
  const method = 'POST'

  return {
    type: 'SUBMIT_PAYMENT_BY_LINK',
    payload: handleAPI(url, filters, method, { ...data })
  };
}

export function validatePaymentLink(filters) {
  const url = `/validate_payment_link`;
  const method = 'GET'

  return {
    type: 'VALIDATE_PAYMENT_LINK',
    payload: handleAPI(url, filters, method)
  };
}
