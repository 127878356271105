import React, { Component } from 'react';
import { connect } from 'react-redux';
import Alert from '../../../../itrust_common/components/Alert';

import { orderUpdate } from '../../../../redux/actions/order/orderActions';
import { appointmentLoad } from '../../../../redux/actions/appointmentActions';

class UnFinalizeButton extends Component {

  handleOrderUpdate(data) {
    Alert.confirm("Are you sure you want to unfinalize this order?",
      (response) => {
        if (response)
          this.props.dispatch(orderUpdate({ id: this.props.orderId, status: 'unfinished' }, { include: '*' }));
        if (data.appointmentId) setTimeout(() => {
          this.props.dispatch(appointmentLoad(data.appointmentId, { include: ['patient', 'staff', 'procedures', 'examination', 'order'] }))
        }, 1000)
      })
  }

  render() {
    const { appointmentId } = this.props;
    return (
      <span
        className="pointer"
        onClick={() => this.handleOrderUpdate({ appointmentId })}
      >
        {this.props.children}
      </span>
    );
  }
}



export default connect()(UnFinalizeButton);
