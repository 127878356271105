import React from 'react'
import Alert from '../../../itrust_common/components/Alert'
import { claimFormCreate } from '../../../redux/actions/order/claimFormActions'
import { orderLoad } from '../../../redux/actions/order/orderActions'
import { connect } from 'react-redux';
import { getInsuranceFromRPAServices, isRpaVerifiedInsurance } from '../../../containers/authorised/addOns/other/AddOnsHelperFunctions'
import RpaClaimFormGenerate from '../../../containers/authorised/addOns/rpa/claimForm/generateClaim/RpaClaimFormGenerate';
import { rpaClaimFormCreate, rpaClaimFormLoad } from '../../../redux/actions/addOns/rpa/rpaClaimFormAction';
import { getInsuranceType } from '../../../itrust_common/components/HelperFunctions';

function GenerateClaimFormButton(props) {
  const { dispatch, order, handleTabChange, rpaConfig, services } = props
  const isRpaSupported = isRpaVerifiedInsurance(rpaConfig, services, order.patient_insurance_attributes?.insurance_name)

  const handleGenerateClaimForm = () => {
    if (order.insurance_receivable <= 0.0) {
      Alert.confirm("This order has a receivable less than or equal to $0 and creating a 1500 claim form could lead to a claim rejection.", (res) => {
        if (res) {
          dispatch(handleClaimFormCreate)
        }
      })
    }
    else {
      handleClaimFormCreate()
    }
  }

  const handleClaimFormCreate = async () => {
    if (!order.patient_insurance_id)
      Alert.warning("Patient insurance is not selected yet, You can't generate 1500 form without patient insurance")
    else {
      await claimFormGenerate()
      await dispatch(orderLoad(order.id, { include: '*' }));
      handleTabChange(6)
    }
  }

  const claimFormGenerate = async () => {
    if (isRpaSupported) {
      const insurance = getInsuranceFromRPAServices(services, order.patient_insurance_attributes?.insurance_name)
      let rpaClaimGenerateData = { add_ons_id: insurance.id, orders_order_id: order.id, patient_id: order.patient_id, insurance_type: order.patient_insurance_attributes?.insurance_type, store_id: order.store_id, insurance_payer: order.patient_insurance_attributes?.insurance_name, service: insurance.config.service, other_details: { resource: "generate_claim" } }
      await dispatch(rpaClaimFormCreate(rpaClaimGenerateData))
      await dispatch(rpaClaimFormLoad(order.id))
      return;
    }
    else {
      await dispatch(claimFormCreate(order.id))
      return;
    }
  }

  return (
    <>
      {isRpaSupported ?
        <RpaClaimFormGenerate
          selectedInsuranceName={order.patient_insurance_attributes?.insurance_name}
          selectedInsuranceMemberId={order.patient_insurance_attributes?.member_id}
          insuranceType={getInsuranceType(order.patient_insurance_attributes?.insurance_type)}
          orderId={order.id}
          patientId={order.patient_id}
          order={order}
          services={services}
          handleGenerateClaimForm={handleGenerateClaimForm}
          dispatch={dispatch}
        /> :
        <button className="btn btn-danger mx-lg-3" onClick={handleGenerateClaimForm}>Generate Claim Form</button>
      }
    </>
  )
}

const mapStateToProps = (state) => {
  const { organisation: { organisation }, rpa: { services } } = state
  return {
    rpaConfig: organisation.add_ons_config?.type?.rpa,
    services,
  }
}

export default connect(mapStateToProps)(GenerateClaimFormButton)