import React, {Component} from 'react';
import {connect} from 'react-redux';

import { humanize, findByKey, filterByKey } from '../../../itrust_common/components/HelperFunctions'
import SocialHistoryForm from '../../../components/authorised/patient/SocialHistoryForm.js';
import { patientSocialHistoriesLoad, patientSocialHistoryCreate, patientSocialHistoryUpdate } from '../../../redux/actions/patient/patientSocialHistoryActions';
import {socialHistoriesLoad as settingSocialHistoriesLoad } from '../../../itrust_common/redux/actions/settingActions/socialHistoryActions';
import { socialHistorySectionsLoad } from '../../../itrust_common/redux/actions/settingActions/socialHistorySectionActions';

class SocialHistory extends Component {
  constructor(props){
    super(props);
    this.props.dispatch(settingSocialHistoriesLoad({sort: 'category, position', rpp: 100}));
    this.props.dispatch(patientSocialHistoriesLoad(props.patientId, {rpp: 100, exam_id: props.examId}));
    this.props.dispatch(socialHistorySectionsLoad({sort:'position', direction: 'asc'}))
  }

  handleSocialHistorySubmit(data) {
    const id = data.id || '';
    var patientId = this.props.patientId
    var examId = this.props.examId
    if (id === '') {
      this.props.dispatch(patientSocialHistoryCreate(patientId, {exam_examination_id: examId, ...data}));
    } else {
      this.props.dispatch(patientSocialHistoryUpdate(patientId, {exam_examination_id: examId, ...data}));
    }
  }

  renderSettingSocialHistory(category, settingSocialHistory, index) {
    const {patientSocialHistories} = this.props
    const patientSocialHistory = findByKey(patientSocialHistories, 'settings_social_history_id', parseInt(settingSocialHistory.id))
    var initialValues = {settings_social_history_id: settingSocialHistory.id, answer: settingSocialHistory.options}
    if(patientSocialHistory && patientSocialHistory.id){
      initialValues = {...initialValues, id: patientSocialHistory.id, answer: patientSocialHistory.answer}
    }
    return(
      settingSocialHistory.is_visible &&
        <li
          className={`border-bottom border-bottom-2 border-light px-7 py-5`}>
          <div className="d-flex flex-row justify-content-between">
            <p className="mb-0">{settingSocialHistory.question}</p>
            <small className="mb-0 text-muted">{patientSocialHistory ? `Last update: ${patientSocialHistory.updated_at}` : "Not filled yet"}</small>
          </div>
          <SocialHistoryForm settingSocialHistory={settingSocialHistory} form={`patientSocialHistoryForm${settingSocialHistory.id}`} onSubmit={this.handleSocialHistorySubmit.bind(this)} initialValues={initialValues}/>
        </li>
    );
  }

  isCategoryVisible(category) {
    const { settingSocialHistories } = this.props
    return filterByKey(settingSocialHistories?.[category], 'is_visible', true).length ? true : false
  }

  render() {
    const { settingSocialHistories, patientSocialHistoriesMeta, examEditing, socialHistorySections } = this.props
    return(
      <div>
        {!examEditing && !examEditing.id && patientSocialHistoriesMeta.has_pending_exam &&
          <div className="d-flex align-items-center pb-5">
            <i className="la la-warning text-danger mr-2"></i>
            <h6>This patient has an active unfinished exam. Syncing to patient profile changes must be made on the exam, and then finalized.</h6>
          </div>
        }
        <div className={!examEditing && !examEditing.id && patientSocialHistoriesMeta.has_pending_exam ? "card-columns mt-7 social-history disabled" : "card-columns mt-7 social-history"}>
          {/* Sort section as per socialHistorySections Positioning */}
          {socialHistorySections.map((item) =>
            <>
              {this.isCategoryVisible(item.name) ?
                <div className="card border-0 shadow-sm">
                  <div className="p-5 border-bottom border-light">
                    <h6>{humanize(item.name)}</h6>
                  </div>
                  <div className="card-body p-0">
                    <ul className="list-unstyled mb-0">
                      {settingSocialHistories[item.name].map((question, index) =>
                        this.renderSettingSocialHistory(item.name, question, index)
                      )}
                    </ul>
                  </div>
                </div> : null}
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {patientSocialHistory, setting, filter, exam} = state;
  return {
    settingSocialHistories: setting.socialHistory.social_histories,
    patientSocialHistories: patientSocialHistory.patient_social_histories,
    patientSocialHistoriesMeta: patientSocialHistory.meta,
    examEditing: exam.editing,
    filters: filter.filters,
    socialHistorySections: setting.socialHistorySection?.social_history_sections
  }
}

export default connect(mapStateToProps)(SocialHistory);
