import React from 'react'
import { connect } from 'react-redux';
import CallingPopUpWrapper from './CallingPopUpWrapper';
import PatientMessagePopUp from './patient/message/PatientMessagePopUp';

function CallingWrapper(props) {
  const { callPopUpStatus, userType } = props

  return (
    <div className='calling-pop-up'>
      <div className='dailer-box calling-pop-up-wrap'>
        {callPopUpStatus === 'patient_message' ?
          <PatientMessagePopUp /> :
          <>{userType !== 'Patient' && <CallingPopUpWrapper />}</>
        }
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  const { voip, auth: { user } } = state;

  return {
    callPopUpStatus: voip?.call_pop_up_status,
    userType: user?.type,
  }
}

export default connect(mapStateToProps)(CallingWrapper);
