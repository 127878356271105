const DEFAULT_STATE = {
  layout: 'default',
  activeTab: {exam: 1, overview: 1, anonymous: 1, referral: 1}
}
let changes = null;
export default function otherReducer(state = DEFAULT_STATE, action){
  switch(action.type){
    case 'LAYOUT_CHANGE':
      changes = {
        layout: action.payload
      };
      return { ...state, ...changes };
    case 'TAB_CHANGE':
      changes = {
        activeTab: { ...state.activeTab, ...action.payload }
      };
      return { ...state, ...changes };

    default:
      return state;
  }
}