import React, { Component } from 'react';
import { connect } from 'react-redux';
import { numberToCurrency } from '../../../../../itrust_common/components/HelperFunctions';

class MiscItem extends Component{
  getMiscItemTotal(miscItems){
    let total = 0
    miscItems.forEach(item => {
      return total = total + item.subtotal
    })
    return total
  }

  render(){
    const { miscItems, order } = this.props
    return [
      <tr className="text-dark bg-light border-bottom border-white border-bottom-2">
        <td colspan="6"><h6>Misc. Items</h6></td>
        <td><h6 className="text-danger">{order.cash_misc_items_discount ? numberToCurrency(order.cash_misc_items_discount) : ''}</h6></td>
        <td><h6>{this.getMiscItemTotal(miscItems)?numberToCurrency(this.getMiscItemTotal(miscItems)):''}</h6></td>
      </tr>,
      miscItems.map((miscItem, index) => (
        <tr key={`miscItem-${index}`} className="text-dark bg-light border-bottom border-white border-bottom-2">
          <td colSpan="2">{index + 1}. {miscItem.description}</td>
          <td className="pl-8">{miscItem.unit_price ? numberToCurrency(miscItem.unit_price) : ''}</td>
          <td>{miscItem.copay ? numberToCurrency(miscItem.copay) : ''}</td>
          <td>{(miscItem.cash_discount || miscItem.percentage_discount) ? numberToCurrency(miscItem.discount_type === "cash" ? miscItem.cash_discount : miscItem.percentage_discount) : ''}</td>
          <td colspan="3">{miscItem.subtotal ? numberToCurrency(miscItem.subtotal) : ''}</td>
        </tr>
      ))
    ]
  }
}

export default connect()(MiscItem);