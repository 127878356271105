import { API_VERSION_ONE, handleAPI } from "../../itrust_common/utils/apiUtils";

export function onBoardingTourInfoUpdate(data) {
  return {
    type: 'ONBOARDING_TOUR_INFO_UPDATE',
    data
  };
}

export function onBoardingTourProgressInfoUpdate(data) {
  return {
    type: 'ONBOARDING_TOUR_PROGRESS_INFO_UPDATE',
    data
  };
}

export function onBoardingTourGuideInfoUpdate(data) {
  return {
    type: 'ONBOARDING_TOUR_GUIDE_INFO_UPDATE',
    data
  };
}

export function onBoardingTourInfoReset(moduleTourCompleted) {
  return {
    type: 'ONBOARDING_TOUR_INFO_RESET',
    moduleTourCompleted,
  };
}

export function onBoardingTourProgressInfoReset() {
  return {
    type: 'ONBOARDING_TOUR_PROGRESS_INFO_RESET',
  };
}

export function onBoardingTourGuideInfoReset() {
  return {
    type: 'ONBOARDING_TOUR_GUIDE_INFO_RESET',
  };
}

export function onBoardingTourReset() {
  return {
    type: 'ONBOARDING_TOUR_RESET',
  };
}

export function onBoardingPatientsLoad(filters) {
  // trail Org > patientLoad on creating Demo Patient and its Appointment
  const url = `/${API_VERSION_ONE}/patients/`;
  const method = 'GET'

  return {
    type: 'ONBOARDING_PATIENTS_LOAD',
    payload: handleAPI(url, filters, method)
  };
}

export function onBoardingAppointmentsLoad(filters) {
  const url = `/${API_VERSION_ONE}/appointments/`;
  const method = 'GET'
  return {
    type: 'ONBOARDING_APPOINTMENTS_LOAD',
    payload: handleAPI(url, filters, method)
  };
}
