import Toastr from "../../itrust_common/components/Toastr"
const DEFAULT_STATE = {
  all_day_notes: [],
  all_day_note: {},
  meta: { pagination: {} },
  saving: false,
}
let changes = null;
export default function allDayNoteReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let allDayNotes = [];
  let allDayNoteData = [];
  let updatedRecord = {};
  switch(action.type){
    case 'ALL_DAY_NOTES_LOAD_PENDING':
    case 'ALL_DAY_NOTE_LOAD_PENDING':
      return {...state, saving: true};

    case 'ALL_DAY_NOTE_CREATE_PENDING':
      return {...state, saving: true};

    case 'ALL_DAY_NOTES_LOAD_REJECTED':
    case 'ALL_DAY_NOTE_LOAD_REJECTED':
    case 'ALL_DAY_NOTE_CREATE_REJECTED':
    case 'ALL_DAY_NOTE_UPDATE_REJECTED':
    case 'ALL_DAY_NOTE_DELETE_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case 'ALL_DAY_NOTES_LOAD_FULFILLED':
      changes = {
        ...response,
        saving: false
      };
      return {...state, ...changes};
    
    case 'ALL_DAY_NOTE_LOAD_FULFILLED':
      changes = {
        all_day_note: response.all_day_note,
        saving: false
      }
      return {...state,...changes};
    
    case 'ALL_DAY_NOTE_CREATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      allDayNotes = [...state.all_day_notes]
      let newRecord = response.all_day_note
      changes = {
        all_day_notes: [...allDayNotes, newRecord],
        all_day_note: {},
        editing: false,
        saving: false,
      }
      return {...state, ...changes};

    case 'ALL_DAY_NOTE_UPDATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      allDayNotes = [...state.all_day_notes];
      updatedRecord = response.all_day_note;
      for (const [index, allDayNote] of allDayNotes.entries()) {
        if (allDayNote.id === updatedRecord.id) {
          allDayNoteData = [
            ...allDayNotes.slice(0, index),
            updatedRecord,
            ...allDayNotes.slice(index + 1)
          ];
        }
      }
      changes = {
        all_day_notes: allDayNoteData,
        editing: false,
        saving: false
      };
      return { ...state, ...changes };

    case 'ALL_DAY_NOTE_DELETE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      allDayNotes = [...state.all_day_notes];
      for (const [index, allDayNote] of allDayNotes.entries()) {
        if (allDayNote.id === response.all_day_note.id) {
          allDayNotes.splice(index, 1);
        }
      }
      return {
        ...state,
        all_day_notes: allDayNotes,
        error: null,
        editing: false
      };

    case 'ALL_DAY_NOTE_EDIT':
      changes = {
        editing: {
          id: action.id,
          data: action.data,
        },
        all_day_note: {...action.data}
      };
      return { ...state, ...changes };

    case 'ALL_DAY_NOTE_EDIT_CANCEL':
      changes = {
        editing: false,
      };
      return { ...state, ...changes };
    

    default:
      return state;
  }
}