import React, { Component } from 'react';
import OtpInput from 'react-otp-input';
export default class Input extends Component {
  render() {
    const {
      props,
      props: { input: { onChange }, disabled, onChangeHook, wrapperClasses, label },
      props: { meta: { touched, error, warning } }
    } = this;
    return (
      <div className={`${disabled && 'disabled'} ${wrapperClasses}`}>
        {label !== undefined && <label>{props.label}</label>}
        <OtpInput
          value={props.input.value}
          otpType='number'
          onChange={otp => {
            onChange(otp);
            console.log(otp)
            if (onChangeHook) {
              onChangeHook(otp);
            }
          }}
          autoFocus
          numInputs={6}
          separator={<span>-</span>}
        />
        { touched &&
          ((error && <span className="invalid-feedback text-danger d-inline la-sm">{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </div>
    );
  }
}
