import "./Support.css";
import { useState } from "react";
import MainPopup from "./component/MainPopup";
import { stopRecoding } from "./utils/recorder";
import React, { useEffect } from "react";
import recodingIcon from "../support/images/recodingIcon.svg";
import captureIcon from "../support/images/CapturingIcon.svg";
import { BASEURL, getNotifications } from "./utils/api/tickets";
import ActionCable from "actioncable";
import UserCam from "./utils/UserCam";
import { useOnboardingTourData } from "../containers/authorised/onboarding/OnBoardingCustomHooks";
import { handleOnboardingTourStart } from "../containers/authorised/onboarding/onBoardingHelpers";

function Support() {
  //view=> false, Menu, Tickets, Ticket, Recording, Capture, Text
  const [view, setView] = useState(false);
  const [notifications, setNotifications] = useState(false);
  const [attachmentData, setAttachmentData] = useState();
  const onBoardingTourData = useOnboardingTourData()

  useEffect(() => {
    setTimeout(function () {
      var cable = ActionCable.createConsumer(
        `${BASEURL}cable?token=${localStorage.getItem(
          "User-email"
        )}`
      );
      cable.subscriptions.create(
        {
          channel: "OnlineNotificationsChannel",
        },
        {
          connected: function () {
            console.log("Support Socket connected");
          },
          disconnected: function () {
            console.log("Support Socket disconnected");
          },
          received: function (data) {
            setNotifications(true);
            console.log(`notification received`);
          },
          speak: function (message) {
            return this.perform("speak", {
              message: message,
            });
          },
        }
      );
    }, 4000);
  }, []);

  useEffect(() => {
    handleGetNotifiction();
  }, []);

  const handleStopRecoding = async () => {
    const response = await stopRecoding();
    setAttachmentData(response);
    setView("RecordingForm");
  };

  const handleGetNotifiction = async () => {
    const response = await getNotifications();
    for (let i = 0; i < response.length; i++) {
      if (response[i].seen === false) {
        setNotifications(true);
        break;
      } else {
        setNotifications(false);
      }
    }
  };

  const handleOnboardingTourReplay = () => {
    setView(false)
    handleOnboardingTourStart(onBoardingTourData)
  }

  return (
    <>
      {view === "Recording" &&
        <div className='position-fixed user-cam'>
          <UserCam />
        </div>
      }
      {view === "Recording" || view === "Capture" ? (
        <a
          className="absolute right-8 bottom-4"
          onClick={() => view === "Recording" && handleStopRecoding()}
        >
          <img
            src={view === "Recording" ? recodingIcon : captureIcon}
            alt="recoding-icon"
            className="blink_me"
          />
        </a>
      ) : (
        <>
          {view !== false && (
            <>
              {localStorage.getItem('User-Type') === 'non_admin' ?
                <div className="">
                  <ul className="position-absolute bg-white main-popup  text-center text-gray2 text-sm rounded-lg shadow-lg z-50 list-unstyled bg-clip-padding  mt-1 border border-1 border-gray m-0">
                    <li>
                      <span className="dropdown-item text-sm py-5 px-3 text-gray-700 hover-effect pointer" onClick={handleOnboardingTourReplay}>
                        <i className="las la-hand-pointer text-primary"></i><br />
                        App Tour
                      </span>
                    </li>
                  </ul>
                </div> :
                <MainPopup
                  view={view}
                  setView={setView}
                  setAttachmentData={setAttachmentData}
                  attachmentData={attachmentData}
                  setNotifications={setNotifications}
                  handleOnboardingTourReplay={handleOnboardingTourReplay}
                />
              }
            </>
          )}



          <button
            className={`bg-primary position-relative main-btn rounded-circle border-0 `}
            onClick={() => (view ? setView(false) : setView("Menu"))}
          >
            <svg
              width="12"
              height="7"
              viewBox="0 0 35 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={`${view && "rotate-90"}`}
            >
              <circle cx="3.5" cy="3.5" r="3.5" fill="white" />
              <circle cx="17.5" cy="3.5" r="3.5" fill="white" />
              <circle cx="31.5" cy="3.5" r="3.5" fill="white" />
            </svg>
            {notifications && (
              <span className="position-absolute notification-icon translate-middle p-2 bg-danger border border-light rounded-circle"></span>
            )}
          </button>
        </>
      )}
    </>
  );
}

export default Support;
