import Toastr from "../../../itrust_common/components/Toastr";

const DEFAULT_STATE = {
  saving: false,
  add_ons: [],
  add_on: {},
}
let changes = null

export const addOnReducer = (state = DEFAULT_STATE, action) => {
  const response = action.payload && (action.payload.data || action.payload.response)
  switch (action.type) {

    case 'ADD_ON_UPDATE_PENDING':
    case 'MANAGE_ADD_ON_UPDATE_PENDING':
      return { ...state, saving: true };

    case 'ADD_ONS_LOAD_REJECTED':
    case 'ADD_ON_UPDATE_REJECTED':
    case 'ADD_ON_LOAD_REJECTED':
    case 'MANAGE_ADD_ON_UPDATE_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = { saving: false }
      return { ...state, ...changes };

    case 'ADD_ONS_LOAD_FULFILLED':
      changes = { ...response, saving: false }
      return { ...state, ...changes };

    case 'ADD_ON_LOAD_FULFILLED':
      changes = { ...response, saving: false }
      return { ...state, ...changes };

    case 'ADD_ON_UPDATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      changes = { ...response, saving: false }
      return { ...state, ...changes };

    case 'MANAGE_ADD_ON_UPDATE_FULFILLED':
      changes = { ...response, saving: false }
      return { ...state, ...changes };

    default:
      return state
  }
}