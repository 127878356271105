import Toastr from "../../components/Toastr"
import {
  setAuthToken,
  removeAuthToken,
} from '../actions/authActions';

const DEFAULT_STATE = {
  saving: false,
  currentDayHour: new Date().getHours(),
  user: {},
  accountType: "doctor",
  editing: {}
};

let changes = null;
export default function authReducer(state = DEFAULT_STATE, action) {
  const response = action.payload && (action.payload.data || action.payload.response)
  switch (action.type) {
    case 'LOGIN_PENDING':
    case 'GET_CURRENT_USER_PENDING':
    case 'CURRENT_USER_UPDATE_PENDING':
      return { ...state, saving: true };

    case 'LOGIN_REJECTED':
    case 'CURRENT_USER_UPDATE_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = {
        saving: false
      };
      return { ...state, ...changes };

    case 'GET_CURRENT_USER_REJECTED':
      removeAuthToken();
      changes = {
        user: null,
        saving: false
      };
      return { ...state, ...changes };

    case 'LOGIN_FULFILLED':
      changes = {
        ...response,
        meta: response.meta,
        saving: false,
      };
      response.user && setAuthToken(response.user.role, response.meta.auth_token);
      return { ...state, ...changes };

    case 'GET_CURRENT_USER_FULFILLED':
      changes = {
        ...response,
        meta: response.meta,
        saving: false
      };
      return { ...state, ...changes };

    case 'CURRENT_USER_UPDATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      changes = {
        ...response,
        saving: false
      };
      return { ...state, ...changes };

    case 'LOGOUT_FULFILLED':
      changes = {
        user: null,
        saving: false,
      };
      removeAuthToken();
      Toastr.handleShow(true, "You are logged out successfully.", 600)
      return { ...state, ...changes };

    case 'SET_ACCOUNT_TYPE':
      changes = {
        accountType: response,
      };
      return { ...state, ...changes };

    case 'CURRENT_USER_EDITING':
      changes = {
        editing: response,
      };
      return { ...state, ...changes };

    default:
      var currentDayHour = new Date().getHours()
      changes = {
        currentDayHour
      };
      return { ...state, ...changes };
  }
}
