import { API_VERSION_ONE, handleAPI } from "../../../../itrust_common/utils/apiUtils";

export function makeOutgoingCall(filters) {
  const url = `/${API_VERSION_ONE}/add_ons/voip/outgoing_call`;
  const method = 'GET'

  return {
    type: 'MAKE_OUTGOING_CALL',
    payload: handleAPI(url, filters, method)
  };
}

export function receiveIncomingCall(filters) {
  const url = `/${API_VERSION_ONE}/add_ons/voip/incoming_call`;
  const method = 'GET'

  return {
    type: 'RECEIVE_INCOMING_CALL',
    payload: handleAPI(url, filters, method)
  };
}

export function anonymousCallLogsLoad(filters) {
  const url = `/${API_VERSION_ONE}/add_ons/voip/anonymous_call_logs`;
  const method = 'GET'

  return {
    type: 'ANONYMOUS_CALL_LOGS_LOAD',
    payload: handleAPI(url, filters, method)
  };
}

export function VoipNotificationLoad(filters) {
  const url = `/${API_VERSION_ONE}/notifications`;
  const method = 'GET'

  return {
    type: 'VOIP_NOTIFICATION_LOAD',
    payload: handleAPI(url, filters, method)
  };
}

export function voipNotificationUpdate(data) {
  return {
    type: 'VOIP_NOTIFICATION_UPDATE',
    data,
  };
}

export function VoipNotificationCreate(data) {
  return {
    type: 'VOIP_NOTIFICATION_CREATE',
    data
  }
}

export function incomingCall(data) {
  return {
    type: 'INCOMING_CALL',
    payload: { data },
  };
}

export function callPopUpEdit(id) {
  return {
    type: 'CALL_POP_UP_EDIT',
    id,
  };
}

export function callingInformationUpdate(data) {
  return {
    type: 'CALL_INFORMATION_UPDATE',
    data,
  };
}

export function voipInfoUpdate(data) {
  return {
    type: 'VOIP_INFO_UPDATE',
    data,
  };
}

export function voipEdit(data) {
  return {
    type: 'VOIP_EDIT',
    data
  };
}

export function voipEditCancel() {
  return {
    type: 'VOIP_EDIT_CANCEL'
  };
}

// bandwidth
export function bandwidthIncomingCallReject(filters) {
  const url = `/${API_VERSION_ONE}/add_ons/voip/hang_up_incoming_call`;
  const method = 'GET'

  return {
    type: 'BANDWIDTH_INCOMING_CALL_REJECT',
    payload: handleAPI(url, filters, method)
  };
}

export function bandwidthCallRecordingAndStatusUpdate(data, filters) {
  const url = `/${API_VERSION_ONE}/add_ons/voip/update_call_status_and_recording`;
  const method = 'POST'

  return {
    type: 'BANDWIDTH_CALL_RECORDING_AND_STATUS_UPDATE',
    payload: handleAPI(url, filters, method, { ...data })
  };
}
