import { rpaClaimFormReducer } from './rpaClaimFormReducer';
import { rpaReducer } from './rpaReducer';
import { rpaReportReducer } from './rpaReportReducer';

const rpaReducers = {
  rpa: rpaReducer,
  rpaReport: rpaReportReducer,
  rpaClaimForm: rpaClaimFormReducer,
};

export default rpaReducers;