import {handleAPI, API_VERSION_ONE} from '../../../itrust_common/utils/apiUtils';

export function patientSocialHistoriesLoad(patientId, filters){
  const url = `/${API_VERSION_ONE}/patients/${patientId}/patient_social_histories/`;
  const method = 'GET'

  return {
    type: 'PATIENT_SOCIAL_HISTORIES_LOAD',
    payload: handleAPI(url, filters, method)
  };
}

export function patientSocialHistoryCreate(patientId, data, filters){
  const url = `/${API_VERSION_ONE}/patients/${patientId}/patient_social_histories/`;
  const method= 'POST'
  return {
    type: 'PATIENT_SOCIAL_HISTORY_CREATE',
    payload: handleAPI(url,filters,method, {patient_social_history: {...data}})
  }
}

export function patientSocialHistoryUpdate(patientId, data, filters){
  const url = `/${API_VERSION_ONE}/patients/${patientId}/patient_social_histories/${data.id}`;
  const method = "PUT"
  return {
    type: 'PATIENT_SOCIAL_HISTORY_UPDATE',
    payload: handleAPI(url,filters,method, {patient_social_history: {...data}})
  }
}
