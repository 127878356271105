export function layoutChange(data) {
  return {
    type: 'LAYOUT_CHANGE',
    payload: data
  };
}

export function tabChange(data) {
  return {
    type: 'TAB_CHANGE',
    payload: data
  };
}