import React from 'react';
import { isPresentInArray } from '../../../../../itrust_common/components/HelperFunctions';
import DisplayField from '../../../../../itrust_common/components/others/DisplayField'

let Procedure = props => {
  const { fieldsValues, selectedIds, handleIdsSelect, overviewName } = props;
  return (
    <div className="row">
      <div className="col-12 py-4">
        <div className="overview-table mb-3">
          <table className="table bg-light table-hover shadow-none border border-muted">
            <thead className="text-dark border border-muted">
              <tr className="text-center">
                <th scope="col">
                  <DisplayField
                    isVisible={true}
                    label={"No."}
                  />
                </th>
                <th scope="col">
                  <DisplayField
                    isVisible={true}
                    label={"Procedure"}
                  />
                </th>
                <th scope="col">
                  <DisplayField
                    isVisible={true}
                    label={"Procedure Code"}
                  />
                </th>
                <th scope="col">
                  <DisplayField
                    isVisible={true}
                    label={"Impression pointer"}
                  />
                </th>
                {overviewName && <th scope="col">
                  <DisplayField
                    isVisible={true}
                    label={"Select"}
                  />
                </th>}
              </tr>
            </thead>
            <tbody>
              {fieldsValues && fieldsValues.map((procedure, index) =>
                <tr className="text-center">
                  <td>
                    <DisplayField
                      isVisible={true}
                      value={index + 1}
                    />
                  </td>
                  <td>
                    <DisplayField
                      isVisible={true}
                      value={procedure.name}
                    />
                  </td>
                  <td>
                    <DisplayField
                      isVisible={true}
                      value={procedure.exam_code}
                    />
                  </td>
                  <td>
                    <DisplayField
                      isVisible={true}
                      value={procedure.pointer}
                    />
                  </td>
                  {overviewName && <td>
                    <div className="custom-control custom-checkbox">
                      <input
                        onChange={() => handleIdsSelect(selectedIds, procedure.id)}
                        type="checkbox"
                        checked={isPresentInArray(selectedIds, procedure.id)}
                        className="custom-control-input" id={`procedure-select-${procedure.id}`} />
                      <label className="custom-control-label" htmlFor={`procedure-select-${procedure.id}`}></label>
                    </div>
                  </td>}
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}


export default Procedure;
