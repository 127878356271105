import React from 'react';
import {FormTemplateField} from '../../../others/'
import {Input, Textarea} from '../../../form'

let TearLab = props => {
  const { baseName, baseFieldValues, isTemplate, isOpen } = props;
  return (
    <div className="card-body">
      <div className="row">
        
        <FormTemplateField
          isTemplate={isTemplate}
          name={`${baseName}[fields][od]`}
          baseName={`${baseName}[fields][od]`}
          fieldValues={baseFieldValues['od']}
          component={Input}
          isOpen={isOpen}
          className="form-control"
          wrapperClasses="col-6 form-group label-icons"
         />
        
        <FormTemplateField
          isTemplate={isTemplate}
          name={`${baseName}[fields][os]`}
          baseName={`${baseName}[fields][os]`}
          fieldValues={baseFieldValues['os']}
          component={Input}
          isOpen={isOpen}
          className="form-control"
          wrapperClasses="col-6 form-group label-icons"
         />

        <FormTemplateField
          isTemplate={isTemplate}
          name={`${baseName}[fields][comments]`}
          baseName={`${baseName}[fields][comments]`}
          fieldValues={baseFieldValues['comments']}
          component={Textarea}
          isOpen={isOpen}
          rows={3}
          className="form-control"
          wrapperClasses="col-12 form-group label-icons"
         />
        
      </div>
    </div>
  )
}
export default TearLab;