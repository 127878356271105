import Toastr from "../../../../itrust_common/components/Toastr";

const DEFAULT_STATE = {
  saving: false,
  logs: [],
  kpi_data: {},
  graph_data: {},
  table_data: {}
}

let changes = null;
let logs = []
let logsData = []
let updatedRecord = {}

export const EDIReportReducer = (state = DEFAULT_STATE, action) => {
  const response = action.payload && (action.payload.data || action.payload.response)
  switch (action.type) {

    case 'EDI_LOGS_LOAD_PENDING':
    case 'EDI_REPORT_LOAD_PENDING':
    case 'EDI_LOG_UPDATE_PENDING':
      return { ...state, saving: true }

    case 'EDI_LOGS_LOAD_REJECTED':
    case 'EDI_REPORT_LOAD_REJECTED':
    case 'EDI_LOG_UPDATE_REJECTED':
      Toastr.handleShow(false, response.data.error, response.data.status)
      return { ...state, saving: false }

    case 'EDI_REPORT_LOAD_FULFILLED':
      let dashboardType = response.meta.params
      let dataField = dashboardType === 'graph' ? 'graph_data' : dashboardType === 'kpi' ? 'kpi_data' : 'table_data'
      changes = {
        [dataField]: response.records,
        meta: response.meta,
        saving: false
      }
      return { ...state, ...changes }

    case 'EDI_LOGS_LOAD_FULFILLED':
      changes = {
        logs: response["add_ons/edi_clearing_house/logs"],
        meta: response.meta,
        saving: false
      }
      return { ...state, ...changes }

    case 'EDI_LOG_UPDATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      logs = [...state.logs]
      updatedRecord = response["add_ons/edi_clearing_house/log"]
      for (const [index, log] of logs.entries()) {
        if (log.id === updatedRecord.id) {
          logsData = [
            ...logs.slice(0, index),
            updatedRecord,
            ...logs.slice(index + 1)
          ];
        }
      }
      changes = {
        logs: logsData,
        saving: false
      }
      return { ...state, ...changes }

    default:
      return state;
  }
}