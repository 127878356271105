import React, { Component } from 'react';
import { connect } from 'react-redux';
import Moment from 'moment';
import * as $ from 'jquery';
import { DatePicker } from 'react-widgets';

import { orderPaymentUpdate } from '../../../../redux/actions/order/orderPaymentActions';

import { filterByKey, humanize, numberToCurrency } from '../../../../itrust_common/components/HelperFunctions';
import { Link } from 'react-router-dom';

class PaymentButtonForm extends Component {

  handlePaymentSubmit(data) {
    const { order } = this.props
    this.props.dispatch(orderPaymentUpdate(order.id, data))
  }

  handlePaymentEditCancel() {
    $("#orderPaymentsForm-modal").modal('hide')
  }

  render() {
    const { payments, organisation, currentUser, itrustPayConfig } = this.props;
    const salePayments = filterByKey(payments, 'payment_type', 'sale')
    const voidPayments = filterByKey(payments, 'payment_type', 'void')
    // Extract payments as per payment type to avoid multiple rendering.
    const cashPayments = filterByKey(salePayments, 'mode_of_payment', 'cash')
    const checkPayments = filterByKey(salePayments, 'mode_of_payment', 'check')
    const cardPayments = filterByKey(salePayments, 'mode_of_payment', 'card')
    const onlinePayments = filterByKey(salePayments, 'mode_of_payment', 'online')
    const devicePayments = filterByKey(salePayments, 'mode_of_payment', 'device')
    const payByTextPayments = filterByKey(salePayments, 'mode_of_payment', 'link')
    return (
      <div className="inner-modal ">
        <div className="modal refund-modal" id="orderPaymentsForm-modal">
          <div className="modal-dialog">
            <div className="modal-content border-0 shadow">
              <div className="modal-header p-5">
                <h6 className="modal-title">
                  Payment Details
                </h6>
                <button type="button" className="btn btn-outline-primary mr-3" onClick={this.handlePaymentEditCancel.bind(this)}>
                  Cancel
                </button>
              </div>
              <div className="modal-body py-5 px-0">
                <ul className="nav nav-tabs border-bottom flex-nowrap overflow-auto" id="myTab" role="tablist">
                  <li className="nav-item">
                    <a className="px-6 py-5 tab-link text-nowrap active" id="CashPayments-tab" data-toggle="tab" href="#CashPayments" role="tab" aria-controls="CashPayments" aria-selected="true">
                      Cash Payments
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="px-6 py-5 tab-link text-nowrap" id="CheckPayments-tab" data-toggle="tab" href="#CheckPayments" role="tab" aria-controls="CheckPayments" aria-selected="false">
                      Check Payments
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="px-6 py-5 tab-link text-nowrap" id="CardPayments-tab" data-toggle="tab" href="#CardPayments" role="tab" aria-controls="CardPayments" aria-selected="false">
                      Offline Payments
                    </a>
                  </li>
                  {organisation.subscription_plan !== "enterprise" && itrustPayConfig?.status !== 'inactive' && itrustPayConfig?.visible &&
                    <>
                      <li className="nav-item">
                        <a className="px-6 py-5 tab-link text-nowrap" id="OnlinePayments-tab" data-toggle="tab" href="#OnlinePayments" role="tab" aria-controls="OnlinePayments" aria-selected="false">
                          Online Payments
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="px-6 py-5 tab-link text-nowrap" id="DevicePayments-tab" data-toggle="tab" href="#DevicePayments" role="tab" aria-controls="DevicePayments" aria-selected="false">
                          Device Payments
                        </a>
                      </li>
                    <li className="nav-item">
                      <a className="px-6 py-5 tab-link text-nowrap" id="PayByTextPayments-tab" data-toggle="tab" href="#PayByTextPayments" role="tab" aria-controls="PayByTextPayments" aria-selected="false">
                        Pay By Text Payments
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="px-6 py-5 tab-link text-nowrap" id="VoidPayments-tab" data-toggle="tab" href="#VoidPayments" role="tab" aria-controls="VoidPayments" aria-selected="false">
                        Void Payments
                      </a>
                    </li>
                    </>
                  }
                </ul>
                <div className="tab-content mx-auto">
                  <div className="tab-pane active" id="CashPayments" role="tabpanel" aria-labelledby="CashPayments-tab">
                    {this.renderPayments(cashPayments, 'cash')}
                  </div>
                  <div className="tab-pane" id="CheckPayments" role="tabpanel" aria-labelledby="CheckPayments-tab">
                    {this.renderPayments(checkPayments, 'check')}
                  </div>
                  <div className="tab-pane" id="CardPayments" role="tabpanel" aria-labelledby="CardPayments-tab">
                    {this.renderPayments(cardPayments, 'card')}
                  </div>
                  <div className="tab-pane" id="OnlinePayments" role="tabpanel" aria-labelledby="OnlinePayments-tab">
                    {this.renderPayments(onlinePayments, 'online')}
                  </div>
                  <div className="tab-pane" id="DevicePayments" role="tabpanel" aria-labelledby="DevicePayments-tab">
                    {this.renderPayments(devicePayments, 'device')}
                  </div>
                  <div className="tab-pane" id="PayByTextPayments" role="tabpanel" aria-labelledby="PayByTextPayments-tab">
                    {this.renderPayments(payByTextPayments, 'pay_by_text')}
                  </div>
                  <div className="tab-pane" id="VoidPayments" role="tabpanel" aria-labelledby="VoidPayments-tab">
                    {this.renderPayments(voidPayments, 'void')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderPayments(payments, type) {
    return (
      <div className="overview-table py-7">
        <table className="table table-hover shadow-none">
          <thead className="text-dark border-bottom border-bottom-2 border-light">
            <tr>
              <th scope="col" className="text-center">ID</th>
              <th scope="col">Amount</th>
              {
                type === 'check' &&
                <th scope="col">Check #</th>
              }
              {
                type === 'card' &&
                <th scope="col">Card type</th>
              }
              <th scope="col">Collected By</th>
              <th scope="col">Charged On</th>
              {!["device", "pay_by_text", "void"].includes(type) && <th scope="col">Comment</th>}
            </tr>
          </thead>
          <tbody>
            {payments.map((payment) =>
              <tr className="text-dark border-bottom border-light border-bottom-2">
                <td className="text-center">#{payment.id}</td>
                <td>{numberToCurrency(payment.amount)}</td>
                {
                  type === 'check' &&
                  <td>{payment.check_details && payment.check_details.check_number}</td>
                }
                {
                  type === 'card' &&
                  <td>{payment.card_details && payment.card_details.card_type}</td>
                }
                <td>{payment.staff_attributes && humanize(payment.staff_attributes.name)}</td>
                <td className="w-25">
                  <DatePicker value={payment.payment_date ? new Date(payment.payment_date.replace(/-/g, '\/')) : null} format="MM/DD/YYYY" placeholder="MM/DD/YYYY" editFormat="MM/DD/YYYY" onChange={(date) => { this.handlePaymentSubmit({ id: payment.id, payment_date: Moment(date).format("YYYY/MM/DD") }) }} />
                </td>
                {!["device", "pay_by_text", "void"].includes(type) && <td>{payment.notes}</td>}
              </tr>
            )}
          </tbody>
        </table>
      </div>
    )

  }
}

PaymentButtonForm = connect(state => {
  const { orderPayment, order, organisation: { organisation }, auth: { user } } = state
  return {
    currentUser: user,
    organisation,
    paymentEditing: orderPayment.editing,
    payments: orderPayment.payments,
    order: order.order,
    itrustPayConfig: organisation.add_ons_config?.type?.itrust_pay
  }
})(PaymentButtonForm)

export default PaymentButtonForm;
