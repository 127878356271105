import React, { Component } from 'react';
import { FormTemplateHeader, FormTemplateSectionWrapper } from '../../../../others/'
import Keratometry from './Keratometry'
import CurrentContactLens from './CurrentContactLens'
import {tabChange} from '../../../../../redux/actions/otherActions';

export default class ContactLens extends Component {
  render() {
    const { isTemplate, template, pprops } = this.props
    return (
      <FormTemplateSectionWrapper isTemplate={isTemplate} fieldValues={template.refraction_contact_lens_exam.contact_lens}>
        <div className="mt-7 px-5">
          <FormTemplateHeader
            isTemplate={isTemplate}
            fieldValues={template.refraction_contact_lens_exam.contact_lens}
            baseName="refraction_contact_lens_exam[contact_lens]"
          />
        </div>
        <div className="row">
          <div className="col-md-12 ">
            <FormTemplateSectionWrapper isTemplate={isTemplate} fieldValues={template.refraction_contact_lens_exam.contact_lens.fields.keratometry}>
              <div className="card border-0 shadow-sm px-5">
                <FormTemplateHeader
                  isTemplate={isTemplate}
                  name="keratometry_attributes[is_marked_complete]"
                  isToggle={pprops.initialValues && pprops.initialValues.keratometry_attributes && pprops.initialValues.keratometry_attributes.is_marked_complete}
                  fieldValues={template.refraction_contact_lens_exam.contact_lens.fields.keratometry}
                  baseName="refraction_contact_lens_exam[contact_lens][fields][keratometry]"
                  overviewName="keratometry"
                  pprops={pprops}
                />
                <div className={`${(pprops.initialValues && pprops.initialValues.keratometry_attributes && pprops.initialValues.keratometry_attributes.is_marked_complete) || isTemplate ? '': 'd-none'}`}>
                  <Keratometry name="keratometry_attributes" {...this.props} />
                </div>
              </div>
            </FormTemplateSectionWrapper>
          </div>
          <div className="col-12 mt-7">
            <FormTemplateSectionWrapper isTemplate={isTemplate} fieldValues={template.refraction_contact_lens_exam.contact_lens.fields.current_contact_lens}>
              <div className="card border-0 shadow-sm px-5">
                <div className="d-flex justify-content-between align-items-center">
                  <FormTemplateHeader
                    isTemplate={isTemplate}
                    fieldValues={template.refraction_contact_lens_exam.contact_lens.fields.current_contact_lens}
                    baseName="refraction_contact_lens_exam[contact_lens][fields][current_contact_lens]"
                    overviewName="current_contact_lens"
                  />

                  {!isTemplate && (pprops.finalizeLensCount > 0) && <button type="button" className={`btn btn-sm px-3 py-2 btn-success text-nowrap`} onClick={() => pprops.dispatch(tabChange({exam: 11}))}>Go To Final Rx</button>}
                </div>
                
                <CurrentContactLens {...this.props} />
              </div>
            </FormTemplateSectionWrapper>
          </div>
        </div>
      </FormTemplateSectionWrapper>
    );
  }
}