import Toastr from "../../../itrust_common/components/Toastr"
const parent = 'PUBLIC_'

const DEFAULT_STATE = {
  anonymous_calendar_setting: {}
}
let changes = null;
export default function anonymousCalendarReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  switch(action.type){
    case `${parent}ANONYMOUS_CALENDAR_SETTING_LOAD_PENDING`:
      return {...state};

    case `${parent}ANONYMOUS_CALENDAR_SETTING_LOAD_REJECTED`:
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      return {...state};

    case `${parent}ANONYMOUS_CALENDAR_SETTING_LOAD_FULFILLED`:
      changes = {
        ...response,
      };
      return {...state, ...changes};
    
    default:
      return state;
  }
}