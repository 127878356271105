import Toastr from "../../../itrust_common/components/Toastr";
const parent = 'STORE_SETTING_'
const DEFAULT_STATE = {
  contacts: [],
  contact: {},
  meta: {pagination: {}},
  saving: false
}
let changes = null;
export default function StoreContactReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let contacts = [];
  let contactData = [];
  let updatedRecord = {};
  switch(action.type){
    case `${parent}CONTACTS_LOAD_PENDING`:
    case `${parent}CONTACT_LOAD_PENDING`:
      return {...state, saving: false};

    case `${parent}CONTACT_CREATE_PENDING`:
    case `${parent}CONTACT_UPDATE_PENDING`:
      return {...state, saving: true};

    case `${parent}CONTACTS_LOAD_REJECTED`:
    case `${parent}CONTACT_LOAD_REJECTED`:
    case `${parent}CONTACT_CREATE_REJECTED`:
    case `${parent}CONTACT_UPDATE_REJECTED`:
    case `${parent}CONTACT_DELETE_REJECTED`:
      Toastr.handleShow(response.data.success, response.data.error, response.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case `${parent}CONTACTS_LOAD_FULFILLED`:
      changes = {
        ...response,
        saving: false
      };
      return {...state, ...changes};
    
    case `${parent}CONTACT_LOAD_FULFILLED`:
      changes = {
        contact: response.contact,
        saving: false
      }
      return {...state,...changes};
    
    case `${parent}CONTACT_CREATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      contacts = [...state.contacts]
      let newRecord = response.contact
      changes = {
        contacts: [...contacts, newRecord],
        editing: false,
        saving: false
      }
      return {...state, ...changes};

    case `${parent}CONTACT_UPDATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      contacts = [...state.contacts];
      updatedRecord = response.contact;
      for (const [index, contact] of contacts.entries()) {
        if (contact.id === updatedRecord.id) {
          contactData = [
            ...contacts.slice(0, index),
            updatedRecord,
            ...contacts.slice(index + 1)
          ];
        }
      }
      changes = {
        contacts: contactData,
        editing: false,
        saving: false
      };
      return { ...state, ...changes };

    case `${parent}CONTACT_DELETE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      contacts = [...state.contacts];
      for (const [index, contact] of contacts.entries()) {
        if (contact.id === response.contact.id) {
          contacts.splice(index, 1);
        }
      }
      return {
        ...state,
        contacts: contacts,
      };

    case `${parent}CONTACT_EDIT`:
      changes = {
        editing: {
          id: action.id,
        },
        contact: { address_attributes: {country: "United States"} }
      };
      return { ...state, ...changes };

    case `${parent}CONTACT_EDIT_CANCEL`:
      changes = {
        editing: false,
        contact: DEFAULT_STATE.contact
      };
      return { ...state, ...changes };

    default:
      return state;
  }
}