import React from 'react'
import DiamondImg from '../../../../assets/static/images/diamond.svg';
import AlarmImg from '../../../../assets/static/images/alarm.svg';
import TouchAppImg from '../../../../assets/static/images/touch_app.svg';
import EasyCommunicationImg from '../../../../assets/static/images/easy_communication.svg';

export default function GuideStep3() {
  return (
    <div className='row mx-0 my-9'>
      <div className='col-6 d-flex flex-column justify-content-between py-5'>
        <div className='d-flex align-items-center'>
          <img className='img-fluid' src={DiamondImg} />
          <div className='ml-5'>
            <h5>Quick Set Up</h5>
            <p className='mb-0 mt-3'>We will active your account in hours not weeks or months.</p>
          </div>
        </div>
        <div className='d-flex align-items-center'>
          <img className='img-fluid' src={AlarmImg} />
          <div className='ml-5'>
            <h5>24/7 Support </h5>
            <p className='mb-0 mt-3'>Our team will always be there for you and ensure you are having the best experience.</p>
          </div>
        </div>
        <div className='d-flex align-items-center'>
          <img className='img-fluid' src={TouchAppImg} />
          <div className='ml-5'>
            <h5>Easy to Learn</h5>
            <p className='mb-0 mt-3'>We have designed our system to be user friendly even for a non technical user.</p>
          </div>
        </div>
      </div>
      <div className='col-6'>
        <img className='img-fluid' src={EasyCommunicationImg} />
      </div>
    </div>
  )
}
