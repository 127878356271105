import {handleAPI, API_VERSION_ONE} from '../../../itrust_common/utils/apiUtils';

export function patientMedicalHistoriesLoad(patientId, filters){
  const url = `/${API_VERSION_ONE}/patients/${patientId}/patient_medical_histories/`;
  const method = 'GET'

  return {
    type: 'PATIENT_MEDICAL_HISTORIES_LOAD',
    payload: handleAPI(url, filters, method)
  };
}

export function patientMedicalHistoryLoad(patientId, id, filters){
  const url = `/${API_VERSION_ONE}/patients/${patientId}/patient_medical_histories/${id}`;
  const method = 'GET'

  return {
    type: 'PATIENT_MEDICAL_HISTORY_LOAD',
    payload: handleAPI(url,filters,method)
  };
}

export function patientMedicalHistoryCreate(patientId, data, filters){
  const url = `/${API_VERSION_ONE}/patients/${patientId}/patient_medical_histories/`;
  const method= 'POST'
  return {
    type: 'PATIENT_MEDICAL_HISTORY_CREATE',
    payload: handleAPI(url,filters,method, {patient_medical_history: {...data}})
  }
}

export function patientMedicalHistoryUpdate(patientId, data, filters){
  const url = `/${API_VERSION_ONE}/patients/${patientId}/patient_medical_histories/${data.id}`;
  const method = "PUT"
  return {
    type: 'PATIENT_MEDICAL_HISTORY_UPDATE',
    payload: handleAPI(url,filters,method, {patient_medical_history: {...data}})
  }
}

export function patientMedicalHistoryDelete(patientId, id, filters) {
  const url = `/${API_VERSION_ONE}/patients/${patientId}/patient_medical_histories/${id}/`;
  const method = 'DELETE';

  return {
    type: 'PATIENT_MEDICAL_HISTORY_DELETE',
    payload: new Promise((resolve, reject) => {
      handleAPI(url, filters, method)
        .then(response => {
          resolve({ id: id, ...response });
        })
        .catch(err => {
          reject(err);
        });
    })
  };
}

export function patientMedicalHistoryDeleteAll(patientId, data, filters){
  const url = `/${API_VERSION_ONE}/patients/${patientId}/patient_medical_histories/destroy_all`;
  const method = "DELETE"
  return {
    type: 'PATIENT_MEDICAL_HISTORY_DELETE_ALL',
    payload: handleAPI(url,filters,method, data)
  }
}
