import React, { useState } from "react";
import "cropperjs/dist/cropper.css";
import * as $ from 'jquery'
import FileCrop from './FileCrop'

// var allFiles = [];

export const FileUpload = (props) => {
  const { label, labelClasses, imageClasses, wrapperClasses, input, uploadClasses } = props
  const [image, setImage] = useState("");
  const [allFiles, setAllFiles] = useState([]);
  var id = input.name.replace(/\[/g, '').replace(/\]/g, '').replace(/\_/g, '')

  const onChange = (e) => {
    e.preventDefault();

    // get the files
    let files = e.target.files;

    // Process each file
    for (var i = 0; i < files.length; i++) {
      let file = files[i];

      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        let fileInfo = {
          filename: file.name,
          content_type: file.type,
          byte_size: file.size,
          file: reader.result,
          name: props.input.name.split('][').slice(-1)[0].split('_')[0]
        };
        setImage(reader.result)
        // Push it to the state
        // allFiles.push(fileInfo);

        setAllFiles([...allFiles, fileInfo])

        if (fileInfo.content_type.includes("image/")) {
          setAllFiles([fileInfo])
          $(`#${id}`).modal('show')
        }
        else handleSubmit(fileInfo)
      }; // reader.onload
    } // for
  };


  const handleSubmit = (file) => {
    const { onChangeHook, input } = props;
    input.onChange(file)
    if (onChangeHook)
      onChangeHook(file)
  }

  // const handleDeleteSubmit = (data) => {
  //   const {
  //     onChangeHook, input
  //   } = props;

  //   if(data.id){
  //     input.onChange({...data, _destroy: true})
  //     if(onChangeHook)
  //       onChangeHook({...data, _destroy: true})
  //   }else{
  //     input.onChange('')
  //   }
  // }
  return (
    <div className={`image-upload ${wrapperClasses}`}>
      <div style={{ width: "100%" }}>
        <label className={labelClasses}>
          {label && label}
          <input
            type="file"
            onChange={(e) => onChange(e)}
            {...props}
            className="d-none"
          />

          {props.input && props.input.value && props.input.value.file ?
            <div className="border border-light text-center text-muted">
              <embed key={props.input.value.file.url || props.input.value.file} className={`${imageClasses}`} src={props.input.value.file.url || props.input.value.file} style={{ objectFit: "fit" }} ></embed>
              <p className="mb-0 py-2 px-5 bg-light pointer"><i className="la la-sm la-cloud-upload text-muted" /> Change file</p>
            </div>
            :
            <div className={`border border-light bg-light py-7 px-5 text-center text-muted pointer ${uploadClasses}`}>
              <i className="la la-cloud-upload text-muted" />
              <p>Browse from computer to upload file.</p>
            </div>
          }
        </label>
        <FileCrop id={id} allFiles={allFiles} setAllFiles={setAllFiles} image={image} label={label} handleSubmit={handleSubmit} />
      </div>
    </div>
  );
};

export default FileUpload;
