import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PreliminaryBinocularForm from '../../../../itrust_common/components/authorised/examFormPartial/PreliminaryBinocularForm';

class ExamPreliminaryBinocularForm extends Component {

  componentWillUnmount(){
    if(this.props.dirty){
      this.props.handleSubmit();
    }
  }

  render(){
    const { handleSubmit, template } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <PreliminaryBinocularForm pprops={this.props} template={template} isTemplate={false}/>
      </form>
    )
  }
}

ExamPreliminaryBinocularForm = reduxForm({
  form: 'examPreliminaryBinocularForm',
  enableReinitialize: true,
})(ExamPreliminaryBinocularForm)

ExamPreliminaryBinocularForm = connect(state => {
  const {exam: { exam } } = state
  return {
    initialValues: {
      id: exam.id,
      priliminaries: exam.priliminaries,
      binocular: exam.binocular
    }
  }
})(ExamPreliminaryBinocularForm)

export default ExamPreliminaryBinocularForm;