import React, { useEffect, useState } from 'react'
import PatientContact from './PatientContact'
import CallLogs from './logs/CallLogs'
import Dialer from './Dialer'
import DialerPad from "../../../../../../../src/assets/static/images/dialpad.svg";
import { filterReset } from '../../../../../../itrust_common/redux/actions/filterActions'
import { voipEditCancel } from '../../../../../../redux/actions/addOns/voip/voipActions'

export default function StoreCallingWrapper(props) {
  const { makeOutGoingCall, isCallActive, editing, dispatch } = props
  const [activeDialerTab, setActiveDialerTab] = useState(1)

  const handleActiveTab = (activeTab)=>{
    switch (activeTab) {
      case 'dialer':
        setActiveDialerTab(1)
        break;
      case 'patients':
        setActiveDialerTab(2)
        break;
      case 'logs':
        setActiveDialerTab(3)
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    handleActiveTab(editing?.activePopupTab)
  }, [editing])

  const handleTabChange=(tab)=>{
    setActiveDialerTab(tab)
    dispatch(voipEditCancel())
  }
  return (
    <div>
      <div className='bg-white'>
        {activeDialerTab === 1 &&
          <Dialer
            makeOutGoingCall={(phoneNumber) => makeOutGoingCall(phoneNumber)}
            isCallActive={isCallActive}
          />
        }
        {activeDialerTab === 2 && <PatientContact />}
        {activeDialerTab === 3 && <CallLogs />}
      </div>
      <div className='d-flex justify-content-around font-size-12 py-3'>
        <button className={`border-0 bg-transparent d-flex flex-column align-items-center ${activeDialerTab === 2 && 'text-primary'}`} onClick={() => { handleTabChange(2); dispatch(filterReset()) }}>
          <i className="las la-user-plus font-size-20"></i>
          Patients
        </button>
        <button className={`border-0 bg-transparent d-flex flex-column align-items-center ${activeDialerTab === 1 && 'text-primary'}`} onClick={() => handleTabChange(1)}>
          {/* <i className="las la-user-plus font-size-20"></i> */}
          <img src={DialerPad} alt='voip-call' />
          Keypad
        </button>
        <button className={`border-0 bg-transparent d-flex flex-column align-items-center ${activeDialerTab === 3 && 'text-primary'}`} onClick={() => handleTabChange(3)}>
          <i className="lar la-clock font-size-20"></i>
          Logs
        </button>
      </div>
    </div>
  )
}
