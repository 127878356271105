import React from 'react';
import { Route, Switch} from 'react-router-dom';
import asyncComponent from '../../itrust_common/components/AsyncComponent';

const Analytics = asyncComponent(() =>
  import('../../containers/authorised/analytics/Analytics')
);

const AppointmentRoutes = (
  <Switch>
    <Route exact path="/analytics" component={Analytics} />
  </Switch>
);

export default AppointmentRoutes;