import {handleAPI, API_VERSION_ONE} from '../../utils/apiUtils';

export function dataLoad(filters){
  const url = `/${API_VERSION_ONE}/autocompletes/`
  const method = "GET"
  return {
    type: 'AUTO_COMPLETE_LOAD',
    //payload: handleAPI(url, filters, method)

    payload: new Promise((resolve, reject) => {
      handleAPI(url, filters, method)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    })
  };
}
