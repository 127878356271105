import Toastr from "../../../components/Toastr"
import _ from 'lodash'
const DEFAULT_STATE = {
  review_of_systems: [],
  review_of_system: {},
  meta: {pagination: {}},
  saving: false
}
let changes = null;
export default function reviewOfSystemReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let reviewOfSystems = [];
  let reviewOfSystemData = [];
  let updatedRecord = {};
  let updatedCategory = '';
  let categoryData = [];
  switch(action.type){
    case 'REVIEW_OF_SYSTEMS_LOAD_PENDING':
    case 'REVIEW_OF_SYSTEM_LOAD_PENDING':
    case 'REVIEW_OF_SYSTEM_UPDATE_ALL_TO_HIDE_PENDING':
      return {...state, saving: true};

    case 'REVIEW_OF_SYSTEM_CREATE_PENDING':
      return {...state, saving: true};

    case 'REVIEW_OF_SYSTEMS_LOAD_REJECTED':
    case 'REVIEW_OF_SYSTEM_LOAD_REJECTED':
    case 'REVIEW_OF_SYSTEM_CREATE_REJECTED':
    case 'REVIEW_OF_SYSTEM_UPDATE_ALL_TO_HIDE_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case 'REVIEW_OF_SYSTEMS_LOAD_FULFILLED':
      let data = {}
      _.chain(response.review_of_systems).groupBy('category').map((value,key)=> data[key] = value).value()
      changes = {
        review_of_systems: data,
        saving: false
      };
      return {...state, ...changes};
    
    case 'REVIEW_OF_SYSTEM_LOAD_FULFILLED':
      changes = {
        review_of_system: response.review_of_system,
        saving: false
      }
      return {...state,...changes};
    
    case 'REVIEW_OF_SYSTEM_CREATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      reviewOfSystems = [...state.review_of_systems]
      let newRecord = response.review_of_system
      changes = {
        review_of_systems: [...reviewOfSystems, newRecord],
        editing: false,
        saving: false
      }
      return {...state, ...changes};

    case 'REVIEW_OF_SYSTEM_UPDATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      updatedRecord = response.review_of_system;
      updatedCategory = updatedRecord.category;
      categoryData = state.review_of_systems[updatedCategory]
      for (const [index, reviewOfSystem] of categoryData.entries()) {
        if (reviewOfSystem.id === updatedRecord.id) {
          reviewOfSystemData = [
            ...categoryData.slice(0, index),
            updatedRecord,
            ...categoryData.slice(index + 1)
          ];
        }
      }
      changes = {
        review_of_systems: {...state.review_of_systems, [updatedCategory]: reviewOfSystemData},
        editing: false,
        saving: false
      };
      return { ...state, ...changes };

    case 'REVIEW_OF_SYSTEM_DELETE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      reviewOfSystems = [...state.review_of_systems];
      for (const [index, reviewOfSystem] of reviewOfSystems.entries()) {
        if (reviewOfSystem.id === response.id) {
          reviewOfSystems.splice(index, 1);
        }
      }
      return {
        ...state,
        review_of_systems: reviewOfSystems,
        error: null
      };

    case 'REVIEW_OF_SYSTEM_EDIT':
      changes = {
        editing: {
          id: action.id,
        },
        review_of_system: { address_attributes: {country: "United States"}}
      };
      return { ...state, ...changes };

    case 'REVIEW_OF_SYSTEM_EDIT_CANCEL':
      changes = {
        editing: false
      };
      return { ...state, ...changes };

    case 'REVIEW_OF_SYSTEM_UPDATE_ALL_TO_HIDE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      reviewOfSystemData = response.review_of_systems
      updatedCategory = response.review_of_systems[0].category
      changes = {
        review_of_systems: { ...state.review_of_systems, [updatedCategory]: reviewOfSystemData },
        saving: false
      }
      return { ...state, ...changes}

    default:
      return state;
  }
}