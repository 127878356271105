import React, { useState } from 'react'
import { Field } from 'redux-form'
import { PhoneInput } from '../../../../../../itrust_common/components/form'
import { useDispatch } from 'react-redux'
import { sendPaymentLink } from '../../../../../../redux/actions/order/orderPaymentActions'
import Alert from '../../../../../../itrust_common/components/Alert'
import CountdownTimer from '../../../../../others/CountdownTimer'

export default function ItrustPayByLink(props) {
  const { orderId, storeId, staffId, phoneNumberForLink, amount, orderBalanceDue } = props
  const [isLinkSentOnce, setIsLinkSentOnce] = useState(false)
  const [disableSendButton, setDisableSendButton] = useState(false)

  const dispatch = useDispatch()

  const handleSendPaymentLink = () => {
    if (amount && orderBalanceDue >= amount) {
      dispatch(sendPaymentLink({ id: orderId, store_id: storeId, staff_id: staffId, amount: amount, phone_number: phoneNumberForLink })).then(() => {
        setDisableSendButton(true)
        setIsLinkSentOnce(true)
      })
    }
    else {
      Alert.warning("Amount can't be nil or greater than Balance due.")
    }
  }

  return (
    <div className='col-12'>
      <div className='row align-items-center'>
        <div className='col-5 ml-6'>
          <Field name="phone_number" component={PhoneInput} label="Phone Number to Send Payment Text" />
        </div>
        <div className='col-5'>
          <button type='button' className='btn btn-primary' onClick={handleSendPaymentLink} disabled={disableSendButton}>
            {isLinkSentOnce ? disableSendButton ? <>
              Resend Payment Text in
              <CountdownTimer initialTime={15} onCountDownFinish={() => setDisableSendButton(false)} timerClasses="ml-3" />
            </> : 'Resend Payment Text' : 'Send Payment Text'}
          </button>
        </div>
      </div>
    </div>
  )
}
