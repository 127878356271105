import React from 'react'
import { SaveButton } from '../../../../../../../../itrust_common/components/others'
import { connect, useDispatch } from 'react-redux'
import { rpaClaimFormEdit } from '../../../../../../../../redux/actions/addOns/rpa/rpaClaimFormAction'

function EyeMedFormButton(props) {
  const { saving, buttonClasses, backStep, rpaClaimForm, isFinalScreen, isContactLens, backModule, editing: { activeTab } } = props
  const dispatch = useDispatch()
  const activeModulesArr = rpaClaimForm.eligibility.data.map(item => item.active && item.service_type ? item.service_type : '')

  const handleBackClick = () => {
    let activeTab, activeModule;
    if (isContactLens) {
      if (activeModulesArr.includes('Exam')) {
        activeTab = rpaClaimForm.exams.exam.is_cpt2_selected == 'no' ? 2 : 3;
        activeModule = 'Exam';
      } else {
        activeTab = 'first';
        activeModule = '';
      }
    } else if (isFinalScreen) {
      if (activeModulesArr.includes('Contact Lens Fit and Follow-up')) {
        activeTab = 2;
        activeModule = 'Contact Lens Fit and Follow-up';
      } else if (activeModulesArr.includes('Exam')) {
        activeTab = rpaClaimForm.exams.exam.is_cpt2_selected == 'no' ? 2 : 3;
        activeModule = 'Exam';
      }
    } else {
      activeTab = backStep;
      activeModule = backModule ?? '';
    }
    dispatch(rpaClaimFormEdit({ activeTab, activeModule }));
  };


  return (
    <div className='d-flex justify-content-end mt-6'>
      {activeTab !== 'first' && <button type="button" className='btn btn-outline-primary mr-5 px-7' onClick={handleBackClick}>Back</button>}
      <SaveButton saving={saving} buttonClasses={`px-7 ${buttonClasses}`} label="Next" />
    </div>
  )
}

const mapStateToProps = (state) => {
  const { rpaClaimForm: { rpaClaimForm, saving, editing } } = state

  return {
    rpaClaimForm,
    saving,
    editing
  }
}

export default connect(mapStateToProps)(EyeMedFormButton)