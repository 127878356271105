import moment from "moment-timezone";
import React from "react";
import { useEffect, useState } from "react";
import {
  BASEURL,
  getAllTickets,
  seenAllNotifications,
} from "../../utils/api/tickets";
import Articles from "./Articles";
import ActionCable from "actioncable";

const AllTickets = (props) => {
  const { view, setNotifications } = props;
  const [ticketId, setTicketId] = useState(false);
  const [allTickets, setAllTickets] = useState([]);

  const getTicketDetails = (id) => {
    setTicketId(id);
  };

  useEffect(() => {
    handleGetTickets();
  }, []);

  useEffect(() => {
    setTimeout(function () {
      var cable = ActionCable.createConsumer(
        `${BASEURL}cable?token=${localStorage.getItem("User-email")}`
      );
      cable.subscriptions.create(
        {
          channel: "OnlineNotificationsChannel",
        },
        {
          connected: function () {
            console.log("Support Socket connected");
          },
          disconnected: function () {
            console.log("Support Socket disconnected");
          },
          received: function (data) {
            if (view === "Tickets") {
              handleGetTickets();
              seenAllNotifications();
              setNotifications(false);
            }
            console.log(`notification received`);
          },
          speak: function (message) {
            return this.perform("speak", {
              message: message,
            });
          },
        }
      );
    }, 4000);
  }, []);

  const handleGetTickets = async () => {
    const response = await getAllTickets();
    setAllTickets(response);
  };
  const ticketStatus = {
    1: "New",
    2: "Open",
    3: "Pending Reminder",
    4: "Closed",
    5: "Merged",
    6: "Removed",
    7: "Pending Close",
  };

  const storeSpecificTime = (time) => {
    if (localStorage.getItem("StoreTimezone")) {
      return moment(time).tz(localStorage.getItem("StoreTimezone")).format("L");
    } else {
      return moment(time).format("L");
    }
  };
  return (
    <>
      {ticketId && (
        <Articles
          ticketId={ticketId}
          view={view}
          setNotifications={setNotifications}
        />
      )}
      <div className="position-absolute allticktes box-border bg-white shadow-lg">
        <table className="text-xs d-block table mb-0 ">
          <thead className="font-weight-normal text-gray-700 uppercase ">
            <tr className="d-flex">
              <th scope="col" className="w-32 px-4  text-center py-3">
                Ticket ID
              </th>
              <th scope="col" className="w-48 px-2 text-center py-3">
                Ticket Title
              </th>
              <th scope="col" className="w-32 px-4 text-center py-3">
                Created At
              </th>
              <th scope="col" className="w-32 px-4 text-center py-3">
                Updated At
              </th>
              <th scope="col" className="w-32 px-4 text-center py-3">
                Status
              </th>
            </tr>
          </thead>
          <tbody className="text-gray4 d-block overflow-auto h-80 hide-scroller">
            {allTickets
              .map((ticket) => (
                <tr
                  onClick={() => getTicketDetails(ticket.id)}
                  className={`${
                    ticketId !== ticket.id && "hover-effect"
                  } cursor-pointer d-flex ${
                    ticketId === ticket.id && "active-record"
                  }`}
                >
                  <td className="w-32 px-4 text-center py-2">
                    #{ticket.number}
                  </td>
                  <td className="w-48 px-2 text-center py-2 ">
                    {ticket.title}
                  </td>
                  <td className="w-32 px-4 text-center py-2">
                    {storeSpecificTime(ticket.created_at)}
                  </td>
                  <td className="w-32 px-4 text-center py-2">
                    {storeSpecificTime(ticket.updated_at)}
                  </td>
                  <td className="w-32 px-4 text-center py-2">
                    {" "}
                    {ticketStatus[ticket.state_id]}
                  </td>
                </tr>
              ))
              .reverse()}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AllTickets;
