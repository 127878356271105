import Toastr from "../../../itrust_common/components/Toastr"
const DEFAULT_STATE = {
  orders: [],
  order: {},
  meta: { pagination: {} },
  saving: false,
  editing: false
}
let changes = null;
export default function paymentReportReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let orders = [];
  let orderData = [];
  let updatedRecord = {};
  switch(action.type){
    case 'PAYMENT_REPORTS_LOAD_PENDING':
      return {...state, saving: true};

    case 'PAYMENT_REPORTS_LOAD_REJECTED':
    case 'PAYMENT_BULK_SENT_TO_TRIZZETO_REJECTED':
    case 'PAYMENT_REPORT_UPDATE_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case 'PAYMENT_REPORTS_LOAD_FULFILLED':
      changes = {
        ...response,
        saving: false
      };
      return {...state, ...changes};

    case 'PAYMENT_REPORT_LOAD_FULFILLED':
      changes = {
        order: response.order,
        saving: false
      };
      return {...state, ...changes};

    case `PAYMENT_REPORT_UPDATE_REJECTED`:
      Toastr.handleShow(response.data.success, response.data.error, response.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case 'PAYMENT_REPORT_UPDATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      orders = [...state.orders];
      updatedRecord = response.order;
      for (const [index, order] of orders.entries()) {
        if (order.id === updatedRecord.id) {
          orderData = [
            ...orders.slice(0, index),
            updatedRecord,
            ...orders.slice(index + 1)
          ];
        }
      }
      changes = {
        orders: orderData,
        editing: false,
        saving: false
      };
      return { ...state, ...changes };

    case 'PAYMENT_REPORT_ORDER_LOAD':
      orders = [...state.orders];
      updatedRecord = response.order;
      for (const [index, order] of orders.entries()) {
        if (order.id === updatedRecord.id) {
          orderData = [...orders.slice(0, index), updatedRecord, ...orders.slice(index + 1)];
        }
      }
      changes = { orders: orderData, editing: false, saving: false, };
      return { ...state, ...changes };

    case 'PAYMENT_REPORT_EDIT':
      changes = {
        editing: {
          ...action.payload
        },
        order: { }
      };
      return { ...state, ...changes };

    case 'PAYMENT_BULK_SENT_TO_TRIZZETO_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      return state;

    case 'PAYMENT_REPORT_EXPORT_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201 )
      changes = {
        editing: false,
        saving: false,
      }
      return {...state, ...changes};


    // case 'PAYMENT_REPORT_EDIT_CANCEL':
    //   changes = {
    //     editing: false
    //   };
    //   return { ...state, ...changes };

    default:
      return state;
  }
}
