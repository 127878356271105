import React from 'react';
import {InputWithIcon, Checkbox} from '../../../form/'
import { change, FieldArray, Field } from 'redux-form';
import {connect} from 'react-redux';
import moment from 'moment'
import {FormTemplateField, FormTemplateHeader, FormTemplateSectionWrapper} from '../../../others'

const renderNCT = (props) => {
  const { fields, isTemplate, template, pprops } = props
  return (
    <div>
      {fields.map((member, index, members) => (
        <div className="row border-top border-top-2 border-light pt-5">
          <div className="col-12 d-flex flex-row justify-content-between align-items-center py-3 border-top-2 border-bottom-2 border-light">
            <div>{`${template.external_internal_exam.nct.custom_label} #${index+1}`}</div>
            <div>
              {
                !isTemplate && (index === fields.length-1) && 
                <button type="button" className="btn btn-primary mx-7" onClick={() => { fields.push({})}}>
                  Add New
                </button>
              }

              { !isTemplate && 
                <Field 
                  name={`${member}[is_marked_complete]`} 
                  component={Checkbox} label={members.get(index).is_marked_complete ? <i className="la la-angle-up"/> : <i className="la la-angle-down"/>} 
                  className="d-none" 
                  wrapperClasses="mr-6 d-inline-block checkbox-remove"
                  onChange={()=> setTimeout(() => pprops.submit(), 500) }
                />
              }
            </div>
          </div>
            
          <div className={members.get(index).is_marked_complete || isTemplate ? 'pt-6': 'd-none'}>
            <div className="row">
          {/* { !isTemplate && <Field component={Checkbox} name={`${member}.is_marked_complete`} label="Test Performed" wrapperClasses="col-12 text-right mb-5"/> } */}
              <FormTemplateField
                isTemplate={isTemplate}
                name={`${member}.od`}
                baseName="external_internal_exam[nct][fields][od]"
                fieldValues={template.external_internal_exam['nct']['ncts'][0]['fields']['od']}
                component={InputWithIcon}
                title="OD" 
                titleAppend="mmHG"
                className="form-control" 
                wrapperClasses="col-6 mb-7 label-icons form-group"
              />

              <FormTemplateField
                isTemplate={isTemplate}
                name={`${member}.os`}
                baseName="external_internal_exam[nct][fields][os]"
                fieldValues={template.external_internal_exam['nct']['ncts'][0]['fields']['os']}
                component={InputWithIcon}
                title="OS" 
                titleAppend="mmHG"
                className="form-control" 
                wrapperClasses="col-6 mb-7 label-icons form-group"
              />

              <div className="col-5">
                <FormTemplateField
                  isTemplate={isTemplate}
                  name={`${member}.nct_time`}
                  baseName={`external_internal_exam[nct][fields][time]`}
                  fieldValues={template.external_internal_exam['nct']['ncts'][0]['fields']['time']}
                  component={InputWithIcon}
                  title="Time"
                  className="form-control" 
                  wrapperClasses="label-icons"
                />
                <small>Format: hh:mm A</small>
              </div>

              { !isTemplate && <div className="col-3 mb-7 pt-2">
                <button type="button" className="btn btn-primary" 
                  onClick={()=> pprops.dispatch(change('examExternalInternalForm', `${member}.nct_time`, moment().format("hh:mm A")))}>
                  Time
                </button>
              </div> }
              {index !== 0 && <div className="col-4 pt-3 text-right">
                <span className="badge badge-danger pointer" onClick={()=> fields.remove(index)}><i className="la la-trash"/></span>
              </div> }
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

const NctForm = props => {
  const {template, isTemplate, pprops} = props
  const name = isTemplate ?  "external_internal_exam[nct][ncts]" : "nct"
  return (
    <div className="col-xl-4 col-md-12 mt-7">
      <FormTemplateSectionWrapper isTemplate={isTemplate} fieldValues={template.external_internal_exam.nct}>
        <div className="card border-0 shadow-sm px-5">
          <FormTemplateHeader 
            isTemplate={isTemplate} 
            fieldValues={template.external_internal_exam.nct}
            baseName="external_internal_exam[nct]"
            overviewName="nct"
          />

          <div className="card-body pt-0">
            <FieldArray name={name} component={renderNCT}  
              props = {{
                template: template,
                isTemplate: isTemplate,
                pprops: pprops
              }}/>
          </div>
        </div>
      </FormTemplateSectionWrapper>
    </div>
  )
}
export default connect()(NctForm)