import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { Textarea, Checkbox, Radio } from '../../../itrust_common/components/form';

let timeout = null;
let PatientSocialHistoryForm = (props)=> {
  const { handleSubmit, settingSocialHistory } = props
  const renderOption=()=> {
    switch(settingSocialHistory.options.type){
    case "comment":
      return(
        <Field name="answer[value]" component={Textarea} placeholder={settingSocialHistory.options.label} className="form-control" wrapperClasses="form-group"/>
      );
    case "boolean":
      return(
        <div className="d-flex flex-row radio-btn">
          <Field name="answer[value]" type="radio" component={Radio} value="yes" label="Yes" wrapperClasses="mr-7 radio-sm"/>
          <Field name="answer[value]" type="radio" component={Radio} value="no" label="No" wrapperClasses="mr-7 radio-sm"/>
        </div>
      );
    case "checkbox":
      return(
        <div className="d-flex flex-row flex-wrap">{settingSocialHistory.options.data.map((option, index)=>
          <Field name={`answer[data][${index}][value]`} type="checkbox" id={option.label} component={Checkbox} label={option.label} wrapperClasses="mr-6" />
          )}
        </div>
      );
    default:
      return(
        <div className="d-flex flex-row radio-btn flex-wrap">{settingSocialHistory.options.data.map((option, index)=>
          <Field name={`answer[value]`} type="radio" component={Radio} value={`option-${index}`} label={option.label} wrapperClasses="radio-sm mr-7" />
          )}
        </div>
      );
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      {renderOption()}
    </form>
  );
}

PatientSocialHistoryForm = reduxForm({
  enableReinitialize: true,
  onChange: (values, dispatch, props) => {
    if (props.dirty) {
      if (values.answer.type === "comment") {
        clearTimeout(timeout);
        timeout = setTimeout(() => props.submit(), 1500);
      }
      else
        setTimeout(() => props.submit(), 100);
    }
  },
})(PatientSocialHistoryForm)



export default PatientSocialHistoryForm;
