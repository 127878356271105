import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Field, change, formValueSelector, reduxForm } from 'redux-form'
import { Checkbox, Textarea } from '../../../../itrust_common/components/form'
import { SaveButton } from '../../../../itrust_common/components/others'
import { required } from '../../../../itrust_common/components/form/FormValidations'

function PurposeForUseItrustForm(props) {
  const { handleSubmit, otherField, dispatch, userName } = props

  const handleAllOfTheAboveFieldChange = (val) => {
    val = val == 'false' ? true : false
    const fields = ['patient_communications', 'electronic_health_record', 'optical_point_of_sale', 'practice_management']
    fields.map((field) => dispatch(change('purposeForUseItrustForm', `utilization_purpose[${field}]`, val)))
  }

  return (
    <form onSubmit={handleSubmit}>
      <h4 className='mb-3'>Hello {userName}!</h4>
      <p>My name is Iris, I am your trusted tour guide that is always learning. I will help you navigate iTRUST to become a pro quickly.</p>
      <h6 className='mb-6'>Tell me what you are looking for to customize your experience</h6>
      <Field name="utilization_purpose.patient_communications" component={Checkbox} wrapperClasses="form-group my-4" className="form-control" label="Patient communications" />
      <Field name="utilization_purpose.electronic_health_record" component={Checkbox} wrapperClasses="form-group my-4" className="form-control" label="Electronic health record" />
      <Field name="utilization_purpose.optical_point_of_sale" component={Checkbox} wrapperClasses="form-group my-4" className="form-control" label="Optical point of sale" />
      <Field name="utilization_purpose.practice_management" component={Checkbox} wrapperClasses="form-group my-4" className="form-control" label="Practice management" />
      <Field name="utilization_purpose.all_of_the_above" onChange={e => handleAllOfTheAboveFieldChange(e.target.value)} component={Checkbox} wrapperClasses="form-group my-4" className="form-control" label="All of the above" />
      <Field name="others" component={Checkbox} wrapperClasses="form-group my-4" className="form-control" label="Other" />
      {otherField &&
        <Field name="utilization_purpose.other" component={Textarea} wrapperClasses="form-group mt-6 mb-4" placeholder='Enter Your Reason' className="form-control" validate={[required]} />
      }
      <SaveButton wrapperClasses="mt-8 d-flex justify-content-end" label='Next' />
    </form>
  )
}

PurposeForUseItrustForm = reduxForm({
  form: 'purposeForUseItrustForm',
})(PurposeForUseItrustForm)

const selector = formValueSelector('purposeForUseItrustForm');

const mapStateToProps = (state) => {
  const { organisation: { organisation }, auth: { user } } = state
  const otherField = selector(state, 'others')

  return {
    otherField,
    userName: user.name,
    initialValues: {
      id: organisation.id,
      others: organisation.utilization_purpose?.other ? true : false,
      utilization_purpose: { ...organisation.utilization_purpose }
    }
  }
}

export default connect(mapStateToProps)(PurposeForUseItrustForm)