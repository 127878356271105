import { handleAPI, API_VERSION_ONE } from '../../../../itrust_common/utils/apiUtils';

export function itrustPayPaymentLogsLoad(filters) {
  const url = `/${API_VERSION_ONE}/add_ons/itrust_pay/logs`;
  const method = 'GET'

  return {
    type: `ITRUST_PAY_PAYMENT_LOGS_LOAD`,
    payload: handleAPI(url, filters, method)
  };
}

export function itrustPayPaymentLogUpdate(data, filters) {
  const url = `/${API_VERSION_ONE}/add_ons/itrust_pay/logs/${data.id}`;
  const method = 'PATCH'

  return {
    type: `ITRUST_PAY_PAYMENT_LOG_UPDATE`,
    payload: handleAPI(url, filters, method, data)
  };
}

export function itrustPayPaymentSettle(data, filters) {
  const url = `/${API_VERSION_ONE}/add_ons/itrust_pay/logs/${data.id}/settle_payment`;
  const method = 'PATCH'

  return {
    type: `ITRUST_PAY_PAYMENT_SETTLE`,
    payload: handleAPI(url, filters, method, data)
  };
}

export function itrustPayPaymentLogEdit(data) {
  return {
    type: 'ITRUST_PAY_PAYMENT_LOG_EDIT',
    data
  };
}

export function itrustPayPaymentLogEditCancel() {
  return {
    type: 'ITRUST_PAY_PAYMENT_LOG_EDIT_CANCEL',
  };
}