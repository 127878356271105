import React from 'react'
import FacebookLogin from './FacebookLogin'
import FacebookLoginAdv from './FacebookLoginAdv'
import { connect } from 'react-redux'
import { CountriesStates } from '../../../../../itrust_common/components/CountriesStates'
import _ from 'lodash';

function LoginWithFacebook(props) {
  const { userRole, organisation, currentStore, whatsAppAccountInfo, isPopupScreen, dispatch } = props
  const countryData = _.find(CountriesStates, { countryName: organisation.address_attributes?.country })
  const stateData = _.find(countryData.regions, { shortCode: organisation.address_attributes?.state })

  const fbInfo = {
    // business: {
    //   name: `${_.capitalize(currentStore.name)}`,
    //   email: `${currentStore.email}`,
    //   phone: {
    //     code: 1,
    //     number: `${currentStore.phone}`,
    //   },
    //   website: `${organisation.website ?? 'https://www.itrust.io'}`,
    //   address: {
    //     streetAddress1: `${organisation.address_attributes.address1}`,
    //     city: `${organisation.address_attributes.city}`,
    //     state: stateData?.name,
    //     zipPostal: `${organisation.address_attributes.zip}`,
    //     country: countryData?.countryShortCode,
    //   },
    //   timezone: `${organisation.timezone}`
    // },
    phone: {
      displayName: `${currentStore.name}`,
      category: 'HEALTH',
      description: `${organisation.subdomain},${currentStore.id}`,
      about: `${organisation.subdomain},${currentStore.id}`,
    },
  }

  return (
    <>
      {["super_admin", "itrust_admin"].includes(userRole) ? <FacebookLogin fbInfo={fbInfo} whatsAppAccountInfo={whatsAppAccountInfo} isPopupScreen={isPopupScreen} dispatch={dispatch} /> : <FacebookLoginAdv isPopupScreen={isPopupScreen} dispatch={dispatch} />}
    </>
  )
}

const mapStateToProps = (state) => {
  const { auth, organisation: { organisation }, currentStore } = state
  return {
    userRole: auth?.user?.role,
    organisation,
    currentStore: currentStore.store
  }
}

export default connect(mapStateToProps)(LoginWithFacebook)