import Toastr from "../../../itrust_common/components/Toastr"
const DEFAULT_STATE = {
  eyeglass_lab_order: {},
  meta: { pagination: {} },
  saving: false
}
let changes = null;
export default function eyeglassLabOrderReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let updatedRecord = {};
  switch(action.type){
    case 'EYEGLASS_LAB_ORDER_LOAD_PENDING':
      return {...state, saving: true};

    case 'EYEGLASS_LAB_ORDER_UPDATE_PENDING':
      return {...state, saving: true};

    case 'EYEGLASS_LAB_ORDER_LOAD_REJECTED':
    case 'EYEGLASS_LAB_ORDER_UPDATE_REJECTED':
    case 'EYEGLASS_LAB_ORDER_CREATE_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case 'EYEGLASS_LAB_ORDER_LOAD_FULFILLED':
      changes = {
        ...response,
        saving: false
      }
      return {...state,...changes};
    
    case 'EYEGLASS_LAB_ORDER_UPDATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      updatedRecord = response.eyeglass_lab_order;
      changes = {
        eyeglass_lab_order: updatedRecord,
        saving: false,
      };
      return { ...state, ...changes };

    case 'EYEGLASS_LAB_ORDER_CREATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      var createdRecord = response.eyeglass_lab_order;
      changes = {
        eyeglass_lab_order: createdRecord,
        saving: false,
      };
      return { ...state, ...changes };

    case 'EYEGLASS_LAB_ORDER_EDIT':
      changes = {
        editing: {
          id: action.id,
        },
      };
      return { ...state, ...changes };

    case 'EYEGLASS_LAB_ORDER_EDIT_CANCEL':
      changes = {
        editing: false,
        eyeglass_lab_order: {}
      };
      return { ...state, ...changes };

    default:
      return state;
  }
}