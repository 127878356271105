import { blobToBase64 } from "./base64Convertor";
function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
export const capture = async () => {
  try {
    let mediaStream = await navigator.mediaDevices.getDisplayMedia({
      video: { mediaSource: "screen" },
    });
    let track = mediaStream.getVideoTracks()[0];
    const name = localStorage.getItem("User-firstname") + Date.now()
    await sleep(500);
    const capture = await new ImageCapture(track);
    const bitmap = await capture.grabFrame();
    const canvas = document.createElement("canvas");
    canvas.getContext("bitmaprenderer").transferFromImageBitmap(bitmap);
    const blob = await new Promise((res) => canvas.toBlob(res));
    const baseImg = await blobToBase64(blob);
    return {
      filename: name,
      attachmentBase: baseImg,
      type: "image",
    };
  } catch (err) {
    return{
      error:true
    }
    console.log("Unable to acquire screen capture: " + err);
  }
};
