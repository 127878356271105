import React from 'react';
import { Route, Switch, Redirect} from 'react-router-dom';
import asyncComponent from '../../itrust_common/components/AsyncComponent';

const EmailLogs = asyncComponent(() =>
  import('../../containers/authorised/logs/EmailLogs.js')
);

const SmsLogs = asyncComponent(() =>
  import('../../containers/authorised/logs/SmsLogs.js')
);

const FaxLogs = asyncComponent(() =>
  import('../../containers/authorised/logs/FaxLogs')
);

const ScheduleJobLogs = asyncComponent(() =>
  import('../../containers/authorised/logs/ScheduleJobLogs')
);

const ExamRoutes = (
  <Switch>
    <Route exact path="/logs/email-logs" component={EmailLogs} />
    <Route exact path="/logs/sms-logs" component={SmsLogs} />
    <Route exact path="/logs/fax-logs" component={FaxLogs} />
    <Route exact path="/logs/schedule-job-logs" component={ScheduleJobLogs} />
    <Redirect exact from="/logs" to="/logs/email-logs" />
  </Switch>
);

export default ExamRoutes;
