import { handleAPI, API_VERSION_ONE } from '../../../itrust_common/utils/apiUtils';

export function addOnsLoad(filters) {
  const url = `/${API_VERSION_ONE}/add_on`;
  const method = 'GET'
  return {
    type: 'ADD_ONS_LOAD',
    payload: handleAPI(url, filters, method)
  }
}

export function addOnLoad(id, filters) {
  const url = `/${API_VERSION_ONE}/add_on/${id}`;
  const method = 'GET'
  return {
    type: 'ADD_ON_LOAD',
    payload: handleAPI(url, filters, method)
  }
}

export function addOnUpdate(data, filters) {
  const url = `/${API_VERSION_ONE}/add_on/${data.id}`;
  const method = 'PUT'
  return {
    type: 'ADD_ON_UPDATE',
    payload: handleAPI(url, filters, method, data)
  }
}

export function manageAddOnUpdate(filters) {
  const url = `/${API_VERSION_ONE}/add_on/manage`;
  const method = 'GET'
  return {
    type: 'MANAGE_ADD_ON_UPDATE',
    payload: handleAPI(url, filters, method)
  }
}
