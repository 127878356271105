import qs from 'qs';
import { API_ROOT } from '../../env';

export function buildDownloadUrl(path, params) {
  const url = API_ROOT + path;
  params = { ...params, ["Store-Id"]: localStorage.getItem('StoreID'), Authorization: localStorage.getItem('Access-Token'), HTTP_SUITE_NAME: process.env.REACT_APP_SUITE_NAME, };
  return url +'?'+ qs.stringify(params, { arrayFormat: 'brackets' })
}

export function buildFileUrl(path) {
  return API_ROOT + path;
}