import React from 'react';
import { isPresentInArray } from '../../../../../itrust_common/components/HelperFunctions'

let Impressions = props => {
  const { selectedExam, selectedIds, handleIdsSelect } = props;
  return (
    <div className="row">
      <div className="col-12 mt-7">
        {selectedExam && selectedExam.procedures_attributes &&
          <div className="overview-table px-2">
            <table className="table table-hover shadow-none">
              <thead className="text-dark border-bottom bg-light border-white border-bottom-2">
                <tr>
                  <th scope="col">Impression</th>
                  <th scope="col">Code</th>
                  <th scope="col">Select</th>
                </tr>
              </thead>
              <tbody>
                {selectedExam.impressions_attributes.map((impression_attribute, index) =>
                  <tr key={`impression-${index}`} className="text-dark bg-light border-bottom border-white border-bottom-2">
                    <td>{impression_attribute.impression.name}</td>
                    <td>{impression_attribute.code}</td>
                    <td>
                      <div className="custom-control custom-checkbox">
                        <input
                          onChange={() => handleIdsSelect(selectedIds, impression_attribute.id)}
                          type="checkbox"
                          checked={isPresentInArray(selectedIds, impression_attribute.id)}
                          className="custom-control-input" id={`impression-select-${impression_attribute.id}`}
                        />
                        <label className="custom-control-label" htmlFor={`impression-select-${impression_attribute.id}`}></label>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        }
      </div>
    </div>
  )
}

export default Impressions;
