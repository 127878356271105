import {handleAPI, API_VERSION_ONE} from '../../itrust_common/utils/apiUtils';

export function appointmentsLoad(filters){
  const url = `/${API_VERSION_ONE}/appointments/`;
  const method = 'GET'
  return {
    type: 'APPOINTMENTS_LOAD',
    payload: handleAPI(url, filters, method)
  };
}

export function appointmentLoad(id, filters){
  const url = `/${API_VERSION_ONE}/appointments/${id}`;
  const method = 'GET'

  return {
    type: 'APPOINTMENT_LOAD',
    payload: handleAPI(url,filters,method)
  };
}

export function appointmentCreate(data, filters){
  const url = `/${API_VERSION_ONE}/appointments/`;
  const method= 'POST'
  return {
    type: 'APPOINTMENT_CREATE',
    payload: handleAPI(url,filters,method, {appointment: {...data}})
  }
}

export function appointmentOneYearCreate(data, filters){
  const url = `/${API_VERSION_ONE}/appointments/`;
  const method= 'POST'
  return {
    type: 'APPOINTMENT_ONE_YEAR_CREATE',
    payload: handleAPI(url,filters,method, {appointment: {...data}})
  }
}

export function appointmentUpdate(data, filters){
  const url = `/${API_VERSION_ONE}/appointments/${data.id}`;
  const method = "PUT"
  return {
    type: 'APPOINTMENT_UPDATE',
    payload: handleAPI(url,filters,method, {appointment: {...data}})
  }
}

//We are not doing hard delete, we just do soft delete
export function appointmentDelete(id, filters) {
  const url = `/${API_VERSION_ONE}/appointments/${id}`;
  const method = 'DELETE';

  return {
    type: 'APPOINTMENT_DELETE',
    payload: new Promise((resolve, reject) => {
      handleAPI(url, filters, method)
        .then(response => {
          resolve({ id: id, ...response });
        })
        .catch(err => {
          reject(err);
        });
    })
  };
}

export function appointmentEdit(id, data) {
  return {
    type: 'APPOINTMENT_EDIT',
    id,
    data
  };
}

export function appointmentEditCancel() {
  return {
    type: 'APPOINTMENT_EDIT_CANCEL'
  };
}

export function appointmentEditHandler(data) {
  return {
    type: 'APPOINTMENT_EDIT_HANDLER',
    data
  };
}

export function appointmentReset() {
  return {
    type: 'APPOINTMENT_RESET'
  };
}

export function appointmentReconfirm(id, filters) {
  const url = `/${API_VERSION_ONE}/appointments/${id}/reconfirm`;
  const method = 'GET'

  return {
    type: 'APPOINTMENT_RECONFIRM',
    payload: handleAPI(url,filters,method)
  };
}