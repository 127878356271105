import licenseReducer from './licenseReducer';
import subscriptionReducer from './subscriptionPlanReducer';
import paymentMethodReducer from './paymentMethodReducer';

const paymentReducer ={
  organisationLicense: licenseReducer,
  organisationSubscription: subscriptionReducer,
  organisationPaymentMethod: paymentMethodReducer,

};

export default paymentReducer;