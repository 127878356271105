import React from 'react';
import DisplayField from '../../../../../itrust_common/components/others/DisplayField'

let TearLab = props => {
  const { fieldsValues, template } = props;
  return (
    <div className="row">
      <DisplayField
        isVisible={template.od.visible}
        label={`${template.od.custom_label}:`}
        value={fieldsValues.fields && fieldsValues.fields.od}
        className="col-6 py-4"
      />
      <DisplayField
        isVisible={template.os.visible}
        label={`${template.os.custom_label}:`}
        value={fieldsValues.fields && fieldsValues.fields.os}
        className="col-6 py-4"
      />
      <DisplayField
        isVisible={template.comments.visible}
        label={`${template.comments.custom_label}:`}
        value={fieldsValues.fields && fieldsValues.fields.comments}
        className="col-6 py-4"
      />      
    </div>
  )
}
export default TearLab;