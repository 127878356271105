import {handleAPI, API_VERSION_ONE_ADMIN} from '../../utils/apiUtils';
export function positionsUpdate(data){
  const url = `/${API_VERSION_ONE_ADMIN}/positionable/reorder`;
  const method = "POST"
  return {
    type: 'POSITIONS_UPDATE',
    payload: handleAPI(url,{},method, {positionable: data})
  }
}


