import Toastr from "../../itrust_common/components/Toastr"
const DEFAULT_STATE = {
  organisation: {},
  saving: false
}
let changes = null;
export default function organisationReducer(state = DEFAULT_STATE, action) {
  const response = action.payload && (action.payload.data || action.payload.response)
  switch (action.type) {
    case 'ORGANISATION_LOAD_PENDING':
    case 'ORGANISATION_UPDATE_PENDING':
    // Connect Account
    // case 'ORGANISATION_SETUP_CONNECT_ACCOUNT_PENDING':
    // case 'ORGANISATION_CONNECT_ACCOUNT_PERSON_BANK_CREATE_PENDING':
    // case 'ORGANISATION_CONNECT_ACCOUNT_PERSON_BANK_UPDATE_PENDING':
      // case 'ORGANISATION_CONNECT_ACCOUNT_BANK_UPDATE_PENDING':
      // case 'ORGANISATION_CONNECT_ACCOUNT_PERSON_UPDATE_PENDING':
      return { ...state, saving: true };

    case 'ORGANISATION_LOAD_REJECTED':
    case 'ORGANISATION_UPDATE_REJECTED':
    // Connect Account
    // case 'ORGANISATION_SETUP_CONNECT_ACCOUNT_REJECTED':
    // case 'ORGANISATION_CONNECT_ACCOUNT_PERSON_BANK_CREATE_REJECTED':
    // case 'ORGANISATION_CONNECT_ACCOUNT_PERSON_LOAD_REJECTED':
    // case 'ORGANISATION_CONNECT_ACCOUNT_BANK_LOAD_REJECTED':
    // case 'ORGANISATION_CONNECT_ACCOUNT_BANK_UPDATE_REJECTED':
    // case 'ORGANISATION_CONNECT_ACCOUNT_PERSON_UPDATE_REJECTED':
    // case 'ORGANISATION_CONNECT_ACCOUNT_LOAD_REJECTED':
    // case 'ORGANISATION_CONNECT_ACCOUNT_PAYOUTS_INFO_LOAD_REJECTED':
    // case 'ORGANISATION_CONNECT_ACCOUNT_PERSON_BANK_UPDATE_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = {
        saving: false
      }
      return { ...state, ...changes };

    case 'ORGANISATION_LOAD_FULFILLED':
      changes = {
        organisation: response.organisation,
        saving: false
      }
      return { ...state, ...changes };

    case 'ORGANISATION_UPDATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      changes = {
        organisation: response.organisation,
        editing: false,
        saving: false
      };
      return { ...state, ...changes };

    case 'ORGANISATION_EDIT':
      changes = {
        editing: {
          id: action.id,
        },
      };
      return { ...state, ...changes };

    case 'ORGANISATION_EDIT_CANCEL':
      changes = {
        editing: false
      };
      return { ...state, ...changes };

    // Connect Account
    // case 'ORGANISATION_SETUP_CONNECT_ACCOUNT_FULFILLED':
    //   changes = {
    //     saving: false
    //   }
    //   return { ...state, ...changes };

    // case 'ORGANISATION_CONNECT_ACCOUNT_PERSON_BANK_CREATE_FULFILLED':
    //   Toastr.handleShow(true, response.meta.message, 201)
    //   changes = {
    //     saving: false
    //   }
    //   return { ...state, ...changes };

    // case 'ORGANISATION_CONNECT_ACCOUNT_LOAD_FULFILLED':
    //   response.connect_account.representative.dob = `${response.connect_account.representative.dob.year}/${response.connect_account.representative.dob.month}/${response.connect_account.representative.dob.day}`

    //   changes = {
    //     organisation: {
    //       ...state.organisation,
    //       connect_account: {
    //         ...state.organisation.connect_account,
    //         ...response.connect_account,
    //         external_account: { ...response.connect_account.external_accounts.data[0] },
    //         representative: { ...response.connect_account.representative }
    //       }
    //     },
    //     saving: false
    //   }

    //   return { ...state, ...changes };

    // case 'ORGANISATION_CONNECT_ACCOUNT_PAYOUTS_INFO_LOAD_FULFILLED':
    //   changes = {
    //     organisation: {
    //       ...state.organisation,
    //       connect_account: { ...state.organisation.connect_account, ...response },
    //     },
    //     saving: false
    //   }
    //   return { ...state, ...changes };

    // case 'ORGANISATION_CONNECT_ACCOUNT_PAYOUTS_WALLET_INFO_LOAD_FULFILLED':
    //   changes = {
    //     organisation: {
    //       ...state.organisation,
    //       organisation_wallet_amount: response.wallet_details.organisation_wallet_amount,
    //       organisation_payout_amount: response.wallet_details.organisation_payout_amount,
    //       stripe_available_balance: response.wallet_details.stripe_available_balance,
    //     },
    //     saving: false
    //   }
    //   return { ...state, ...changes };

    // case 'ORGANISATION_CONNECT_ACCOUNT_PAYOUT_LOGS_LOAD_FULFILLED':
    //   changes = {
    //     organisation: {
    //       ...state.organisation,
    //       connect_account: { ...state.organisation.connect_account, payout_log: response.payout_log }
    //     },
    //     saving: false
    //   }
    //   return { ...state, ...changes };

    default:
      return state;
  }
}