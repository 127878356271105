import React from 'react'
import { connect, useDispatch } from 'react-redux'
import DefaultMessageImg from '../../../../../../../../src/assets/static/images/message_default.svg'
import PatientMessage from './PatientMessage'
import { patientMessageCreate } from '../../../../../../../redux/actions/patient/patientMessageActions'
import { Link } from 'react-router-dom'

import Alert from '../../../../../../../itrust_common/components/Alert'
import MessageForm from '../../../../../../../components/authorised/patient/message/MessageForm'
import { dateFormat, getShortName } from '../../../../../../../itrust_common/components/HelperFunctions'
import { patientLoad } from '../../../../../../../redux/actions/patient/patientActions'
import { layoutChange } from '../../../../../../../itrust_common/redux/actions/otherActions'

function PatientMessageWrapper(props) {
  const { patientMessages, contact, currentUser, layout } = props
  const dispatch = useDispatch()

  const handlePatientMessageSubmit = (data) => {
    if (localStorage.getItem('StoreID') == null)
      Alert.warning("Please select store from top dropdown.")
    else
      dispatch(patientMessageCreate({ ...data, staff_id: currentUser?.id }, { patient_id: contact.id, include: ['patient', 'store', 'staff'] }))
  }

  const handlePatientRedirect = () => {
    if (layout !== "default") {
      dispatch(layoutChange('default'))
    }
    dispatch(patientLoad(contact.id, { include: '*' }))
  }

  return (
    <>
      <div className='d-flex align-items-center p-4 border-1 border-bottom bg-white'>
        {contact && getShortName(contact, 'mr-3 width-2-half height-2-half', 'mr-3 width-2-half height-2-half font-size-18 ')}
        <div className='d-flex flex-column'>
          <div className='text-primary'>
            <Link className="text-primary" to={`/patients/${contact.id}/overview`} onClick={() => handlePatientRedirect()}>
              <span className='font-size-20 m-1 text-primary'>{contact.name}</span>
              <i className="las la-external-link-alt font-size-20"></i>
            </Link>
          </div>
          <div>
            <span className='text-secondary'>DOB:</span>
            <span className='ml-2'>{contact.date_of_birth && `${dateFormat(contact.date_of_birth)} | ${contact.age}`}</span>
          </div>
        </div>
      </div>
      {patientMessages?.length !== 0 ?
        <PatientMessage patientMessages={patientMessages} /> :
        <div className='calling-inner-height bg-white d-flex justify-content-center align-items-center'>
          <img src={DefaultMessageImg} alt='chat-image' className='bg-transparent' />
        </div>
      }
      {currentUser?.type !== "Patient" && <MessageForm
        key={patientMessages.length}
        isPhonePresent={contact.cell_phone && contact.cell_phone !== undefined && contact.cell_phone.length > 8}
        onSubmit={handlePatientMessageSubmit}
      />}
    </>
  )
}
const mapStateToProps = state => {
  const { patientMessage, contact, auth, other } = state;

  return {
    contact: contact.contact_list,
    patientMessages: patientMessage.patient_messages,
    currentUser: auth.user,
    layout: other.layout,
  }
}

export default connect(mapStateToProps)(PatientMessageWrapper);