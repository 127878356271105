import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import asyncComponent from '../../itrust_common/components/AsyncComponent';

const AccountType = asyncComponent(() => 
  import ('../../containers/public/auth/AccountType')
);

const DoctorLogin = asyncComponent(() => 
  import ('../../containers/public/auth/DoctorLogin')
);

const PatientLogin = asyncComponent(() => 
  import ('../../containers/public/auth/PatientLogin')
);

const DoctorPrivacyPolicy = asyncComponent(() => 
  import ('../../containers/public/auth/DoctorPrivacyPolicy')
);

const PatientPrivacyPolicy = asyncComponent(() => 
  import ('../../containers/public/auth/PatientPrivacyPolicy')
);

const ResetPassword = asyncComponent(() =>
  import('../../containers/public/auth/ResetPassword')
);

const ItrustPayByLinkWrap = asyncComponent(() =>
  import('../../containers/public/auth/itrustPayByLink/ItrustPayByLinkWrap')
);

const Appointment = asyncComponent(() =>
  import('../../containers/public/appointment/Appointment')
);

const PatientSurvey = asyncComponent(() =>
  import('../../containers/public/patientSurvey/PatientSurvey')
);

const authRoute = () => {
  return (
    <Switch>
      <Route exact path="/" component={AccountType} />
      <Route exact path="/doctor/sign-in" component={DoctorLogin} />
      <Route exact path="/patient/sign-in" component={PatientLogin} />
      <Route exact path="/doctor/privacy-policy-terms-of-use-connect" component={DoctorPrivacyPolicy} />
      <Route exact path="/patient/privacy-policy" component={PatientPrivacyPolicy} />
      <Route exact path="/passwords/reset" component={ResetPassword} />
      <Route exact path="/itrust-pay/payment" component={ItrustPayByLinkWrap} />
      <Route exact path="/appointment" component={Appointment} />
      <Route exact path="/appointment/:id" component={Appointment} />
      <Route exact path="/patient_surveys" component={PatientSurvey} />
      <Redirect from="*" to="/"/>
    </Switch>
  );
}

export default authRoute;