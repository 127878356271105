import Toastr from "../../itrust_common/components/Toastr"
const DEFAULT_STATE = {
  permissions: [],
  permission: {},
  meta: { pagination: {} },
  saving: false
}
let changes = null;
export default function permissionReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let permissions = [];
  let permissionData = [];
  let updatedRecord = {};
  switch(action.type){
    case 'PERMISSIONS_LOAD_PENDING':
      return {...state, saving: true};
    case 'PERMISSIONS_LOAD_REJECTED':
    case 'PERMISSION_CREATE_REJECTED':
    case 'PERMISSION_UPDATE_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case 'PERMISSIONS_LOAD_FULFILLED':
      changes = {
        ...response,
        saving: false
      };
      return {...state, ...changes};
   
    case 'PERMISSION_UPDATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      permissions = [...state.permissions];
      updatedRecord = response.permission;
      for (const [index, permission] of permissions.entries()) {
        if (permission.id === updatedRecord.id) {
          permissionData = [
            ...permissions.slice(0, index),
            updatedRecord,
            ...permissions.slice(index + 1)
          ];
        }
      }
      changes = {
        permissions: permissionData,
        editing: false,
        saving: false
      };
      return { ...state, ...changes };

    default:
      return state;
  }
}