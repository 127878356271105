import {handleAPI, API_VERSION_ONE} from '../../../itrust_common/utils/apiUtils';

export function patientReportsLoad(patient_id, filters){
  const url = `/${API_VERSION_ONE}/patients/${patient_id}/reports`;
  const method = 'GET'

  return {
    type: 'PATIENT_REPORTS_LOAD',
    payload: handleAPI(url, filters, method)
  };
}

export function patientReportPreview(patient_id, filters){
  const url = `/${API_VERSION_ONE}/patients/${patient_id}/reports/preview`;
  const method = 'GET'

  return {
    type: 'PATIENT_REPORT_PREVIEW',
    payload: handleAPI(url,filters,method)
  };
}

export function patientReportEmail(patient_id, filters){
  const url = `/${API_VERSION_ONE}/patients/${patient_id}/reports/email`;
  const method = 'GET'

  return {
    type: 'PATIENT_REPORT_EMAIL',
    payload: handleAPI(url,filters,method)
  };
}

export function patientReportEdit(id) {
  return {
    type: 'PATIENT_REPORT_EDIT',
    id,
  };
}

export function patientReportEditCancel() {
  return {
    type: 'PATIENT_REPORT_EDIT_CANCEL'
  };
}
