import React from 'react';
import DisplayField from '../../../../../itrust_common/components/others/DisplayField'

let AmnioticMembrane = props => {
  const { fieldsValues, template } = props;
  return (
    <div className="row">
      <DisplayField
        isVisible={template.eye.visible}
        label={`${template.eye.custom_label}:`}
        value={fieldsValues.fields && fieldsValues.fields.eye}
        className="col-6 py-4"
      />
      <DisplayField
        isVisible={template.lid_taped.visible}
        label={`${template.lid_taped.custom_label}:`}
        value={fieldsValues.fields && fieldsValues.fields.lid_taped}
        className="col-6 py-4"
      />
      <DisplayField
        isVisible={template.insertion_method.visible}
        label={`${template.insertion_method.custom_label}:`}
        value={fieldsValues.fields && fieldsValues.fields.insertion_method}
        className="col-6 py-4"
      />
      <DisplayField
        isVisible={template.am_type.visible}
        label={`${template.am_type.custom_label}:`}
        value={fieldsValues.fields && fieldsValues.fields.am_type}
        className="col-6 py-4"
      />
      <DisplayField
        isVisible={template.post_op_medication.visible}
        label={`${template.post_op_medication.custom_label}:`}
        value={fieldsValues.fields && fieldsValues.fields.post_op_medication}
        className="col-6 py-4"
      />
      <DisplayField
        isVisible={template.comments.visible}
        label={`${template.comments.custom_label}:`}
        value={fieldsValues.fields && fieldsValues.fields.comments}
        className="col-6 py-4"
      />
    </div>
)
    
}
export default AmnioticMembrane;