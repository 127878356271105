import React from "react";
import { Route, Switch } from 'react-router-dom';
import asyncComponent from '../../../itrust_common/components/AsyncComponent';

const TabWrapper = asyncComponent(() =>
  import('../../../containers/authorised/addOns/tab/TabWrapper')
)

const TabRoutes = (
  <Switch>
    <Route exact path='/add-ons/tab/dashboard' component={TabWrapper} />
  </Switch>
)

export default TabRoutes;