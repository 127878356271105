import React from 'react'
import RPASubmitHistoryListing from './RPASubmitHistoryListing'

export default function RPASubmitHistory(props) {
  const { expired_vision_insurance_logs, dispatch } = props

  return (
    <div className="dropdown bg-transparent ml-4 mt-2">
      <span className="dropdown-toggle font-size-24 text-primary" role="button" data-toggle="dropdown" aria-expanded="false">
        <i className="las la-history"></i>
      </span>
      <div className="dropdown-menu width-20 height-18">
        <h6 className='mx-4 border-bottom py-3 text-primary'>History</h6>
        <RPASubmitHistoryListing dispatch={dispatch} expired_vision_insurance_logs={expired_vision_insurance_logs} wrapperClasses="table-hover" />
      </div>
    </div>
  )
}
