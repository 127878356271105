import axios from 'axios';
import {handleAPI, API_VERSION_ONE_ADMIN} from '../../utils/apiUtils';
const parent = 'APP_SETTING_'

export function unsplashPhotosLoad(filters, categoryName){
  return {
    type: `${parent}UNSPLASH_PHOTOS_LOAD`,
    payload:  new Promise((resolve, reject) => {
                axios.get('https://api.unsplash.com/search/photos?', {
                  params: { query: filters["query"], page: filters["page"], client_id: "0588a67eeb66525643266a29581cd1d2e5ec81775349822d1322538726ff4ff4", fit: 'scale'} 
                })
                .then(response => {
                  resolve({ filters: filters, ...response, categoryName });
                })
                .catch(err => {
                  reject(err);
                });
              })
  };
}

export function backgroundLoad(){
  const url = `/${API_VERSION_ONE_ADMIN}/app_settings/background/`
  const method = "GET"
  return {
    type: `${parent}BACKGROUND_LOAD`,
    payload: handleAPI(url, {}, method)
  }
}

export function backgroundUpdate(data){
  const url = `/${API_VERSION_ONE_ADMIN}/app_settings/background`
  const method = "PUT"
  return {
    type: `${parent}BACKGROUND_UPDATE`,
    payload: handleAPI(url, {}, method, {background: {...data}})
  }
}

