import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as $ from 'jquery'

import { dateFormat, humanize, toggleId } from '../../../../../itrust_common/components/HelperFunctions'
import { previousExamLoad } from '../../../../../itrust_common/redux/actions/exam/previousExamActions'
import { examReset, examsLoad } from '../../../../../redux/actions/exam/examActions'
import Procedures from './Procedures'
import ContactLenses from './ContactLenses'
import EyeGlasses from './EyeGlasses'
import Alert from '../../../../../itrust_common/components/Alert';
import { orderUpdate } from '../../../../../redux/actions/order/orderActions';
import Impressions from './Impressions';

class ImportWrapper extends Component {
  constructor(props) {
    super(props)
    this.state={
      activeExam: null,
      selectedIds: []
    }
  }

  async handlePreviousExamLoad(id) {
    const { importName } = this.props;
    let tabName = ""
    switch(importName){
      case "contact_lens": tabName = "finalized_lenses_attributes"; break
      case "eyeglass": tabName = "finalized_glasses_attributes"; break
      case "procedure": tabName = "procedures_attributes"; break
      case "impression": tabName = "impressions_attributes"; break
    }

    let ids = [];
    var exam = await this.props.dispatch(previousExamLoad(id, {include: '*'}))
    this.setState({ selectedIds: [] })
    exam.value.data.exam[tabName].map((item => ids.push(item.id)))
    this.setState({ activeExam: id, selectedIds: ids })
  }
  
  handleIdsSelect(ids, id){
    var ids = toggleId(ids, id)
    this.setState({selectedIds: ids})
  }

  getDefaultSelection(exams){
    var id = null
    if(exams.length > 0) {
      id = exams[0].id
      this.handlePreviousExamLoad(id)
      this.setState({activeExam: id})
    }
  }

  async openModel(importName){
    var exams = await this.props.dispatch(examsLoad( {direction: 'desc', sort: 'exam_date', rpp: 200, filter: {patient: this.props.order.patient_id} } ) )
    this.getDefaultSelection(exams.value.data.exams)
    $(`#${importName}`).modal()
  }

  closeModel(importName){
    this.props.dispatch(examReset())
    this.setState({selectedIds: []})
    $(`#${importName}-modal`).modal('hide')
  }

  import() {
    const { importName } = this.props;
    if (this.state.selectedIds.length === 0) {
      Alert.warning("No record selected!, Please select some values and than import it.")
    } else {
      new Promise((resolve, reject) => {
        let importData = this.state.selectedIds;
        if (importName === 'procedure' || importName === 'impression') {
          importData = this.getSelectedItemIdsAndPosition()
        }
        this.props.dispatch(orderUpdate({ id: this.props.order.id, [`import_${importName}_ids`]: importData }, { include: '*' }))
          .then(() => {
            this.setState({ selectedIds: [] })
            $(`#${importName}-modal`).modal('toggle')
          })
      }
      )
    }
  }

  getSelectedItemIdsAndPosition() {
    const data = {}
    const { importName, previousExam } = this.props;
    previousExam[`${importName}s_attributes`].filter(obj => this.state.selectedIds.includes(obj.id)).map(obj => (data[obj.position] = obj.id));
    return data
  }

  render() {
    const { importName, exams, previousExam } = this.props;  
    return (
      <div className="inner-modal">
        <button type="button" className="btn btn-outline-primary" onClick={()=> this.openModel(`${importName}-modal`)}>
          Add New from Exam
        </button>
        <div className="modal fade" data-backdrop="static" id={`${importName}-modal`} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className={`modal-dialog modal-lg`} role="document">
            <div className="modal-content shadow-lg border-0">
              <div className="modal-header align-items-center">
                <h5 className="modal-title" id="exampleModalLabel">{humanize(importName)}</h5>
                <button type="button" className="btn btn-outline-primary" onClick={this.closeModel.bind(this, importName)}>
                  Close
                </button>
              </div>
              <div className="modal-body">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-end">
                    <div className="d-flex flex-column">
                      <h6 className="mr-3 d-inline-block"> Exam Selection </h6>
                      <select className="custom-select" style={{ width: "15rem"}} value={this.state.activeExam} onChange={(e) => this.handlePreviousExamLoad(e.target.value)}>
                        <option disabled value={null}></option>
                        {exams.map(exam =>
                          <option value={exam.id}>{dateFormat(exam.exam_date)}</option>
                        )}
                      </select>
                    </div>
                    { this.state.activeExam && 
                      <div className="d-flex flex-column ml-4">
                        <h6 className="mr-3 d-inline-block"> Status </h6>
                        <span className="badge badge-primary rounded-sm">{humanize(previousExam.status)}</span>
                      </div>
                    }
                  </div>
                  
                  <button type="button" className="btn btn-outline-primary" onClick={()=> this.import()}>
                    Import Selected
                  </button>
                </div>
                { this.renderComponent(importName) }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderComponent(importName) {
    const { previousExam } = this.props
    switch (importName) {
      case "procedure":
        return <Procedures handleIdsSelect={this.handleIdsSelect.bind(this)} selectedExam={previousExam} selectedIds={this.state.selectedIds}/>
      case "contact_lens":
        return <ContactLenses handleIdsSelect={this.handleIdsSelect.bind(this)} selectedExam={previousExam} selectedIds={this.state.selectedIds}/>
      case "eyeglass":
        return <EyeGlasses handleIdsSelect={this.handleIdsSelect.bind(this)} selectedExam={previousExam} selectedIds={this.state.selectedIds}/>
      case "impression":
        return <Impressions handleIdsSelect={this.handleIdsSelect.bind(this)} selectedExam={previousExam} selectedIds={this.state.selectedIds}/>
      default:
    }
  }
}

const mapStateToProps = state => {
  const { exam, previousExam } = state
  return {
    exams: exam.exams,
    previousExam: previousExam.previousExam
  }
}
export default connect(mapStateToProps)(ImportWrapper);