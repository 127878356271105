import {handleAPI, API_VERSION_ONE} from '../../itrust_common/utils/apiUtils';

export function organisationLoad(filters){
  const url = `/${API_VERSION_ONE}/organisation`;
  const method = 'GET'

  return {
    type: 'ORGANISATION_LOAD',
    payload: handleAPI(url, filters ,method)
  };
}

export function organisationUpdate(data, filters){
  const url = `/${API_VERSION_ONE}/organisation`;
  const method = "PUT"
  return {
    type: 'ORGANISATION_UPDATE',
    payload: handleAPI(url, filters, method, { organisation: { ...data } })
    // payload: new Promise((resolve, reject) => {
    //   handleAPI(url,filters,method, {organisation: {...data}})
    //     .then(response => {
    //       !data.messageType ?
    //         resolve({ ...response })
    //         :
    //         resolve({ data: { ...response.data, meta: {message: data.messageType} } });
    //     })
    // })
  }
}

export function organisationEdit(id) {
  return {
    type: 'ORGANISATION_EDIT',
    id,
  };
}

export function organisationEditCancel() {
  return {
    type: 'ORGANISATION_EDIT_CANCEL'
  };
}
