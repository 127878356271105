import React, { Component } from 'react';
import { reduxForm, Field, submit } from 'redux-form';
import { connect } from 'react-redux';
import { Input, Radio } from '../../../../itrust_common/components/form';
import clam_qr from '../../../../assets/static/images/1500-form-qr.png';
import { required } from '../../../../itrust_common/components/form/FormValidations';
import ScrollToTarget from '../../../../itrust_common/components/others/ScrollToTarget';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import ClaimFormHeader from './ClaimFormHeader';

class ClamForm extends Component {

  render(){
    const { handleSubmit, order, dispatch, onTrizettoSend, trizettoSending, ediClearingHouseConfig, userRole } = this.props;
    return (
      <div className="clam-form-modal clam-form bg-light">
        <div className="container">
          <ClaimFormHeader dispatch={dispatch} order={order} trizettoSending={trizettoSending} ediClearingHouseConfig={ediClearingHouseConfig} onTrizettoSend={onTrizettoSend} userRole={userRole} />
          <form onSubmit={handleSubmit}>
            <div className="bg-white">
              <div className="p-7">
                <div className="row">
                  <div className="col-7 px-0">
                    <img src={clam_qr} alt="clam-qr" className="mb-3"/>
                    <h3 className="mb-3">HEALTH INSURANCE CLAIM FORM</h3>
                    <h6 className="mb-6">APPROVED BY NATIONAL UNIFORM CLAIM COMMITTEE 02/12</h6>
                  </div>
                  <div className="col-5 px-0">
                  <Field name="insurance_details[line1]" component={Input} className="form-control" wrapperClasses="mb-1"/>
                  <Field name="insurance_details[line2]" component={Input} className="form-control" wrapperClasses="mb-1"/>
                  <Field name="insurance_details[line3]" component={Input} className="form-control" wrapperClasses="mb-1"/>
                  <Field name="insurance_details[line4]" component={Input} className="form-control" wrapperClasses=""/>

                  </div>
                  <div className="col-12 border border-danger">
                    <div className="row">
                      <div className="col-8 p-3 border-right border-danger">
                        <div className="row">
                          <div className="col-12 d-flex flex-row justify-content-between radio-btn">
                            <div>
                              <p className="mb-1">1. MEDICARE</p>
                              <Field name="section1[insurance_type]" value="medicare" type="radio" component={Radio} label="(Medicare#)" wrapperClasses="radio-sm"/>
                            </div>
                            <div>
                              <p className="pl-6 mb-1">MEDICAID</p>
                              <Field name="section1[insurance_type]" value="medicade" type="radio" component={Radio} label="(Medicaid#)" wrapperClasses="radio-sm"/>
                            </div>
                            <div>
                              <p className="pl-6 mb-1">TRICARE</p>
                              <Field name="section1[insurance_type]" value="tricare" type="radio" component={Radio} label="(Sponsor’s SSN)" wrapperClasses="radio-sm"/>
                            </div>
                            <div>
                              <p className="pl-6 mb-1">CHAMPVA</p>
                              <Field name="section1[insurance_type]" value="champva" type="radio" component={Radio} label="(Member ID#)" wrapperClasses="radio-sm"/>
                            </div>
                            <div>
                              <p className="pl-6 mb-1">GROUP HEALTH PLAN</p>
                              <Field name="section1[insurance_type]" value="ghp" type="radio" component={Radio} label="(SSN or ID)" wrapperClasses="radio-sm"/>
                            </div>
                            <div>
                              <p className="pl-6 mb-1">FECA BLK LUNG</p>
                              <Field name="section1[insurance_type]" value="feca" type="radio" component={Radio} label="(SSN)" wrapperClasses="radio-sm"/>
                            </div>
                            <div>
                              <p className="pl-6 mb-1">OTHER</p>
                              <Field name="section1[insurance_type]" value="other" type="radio" component={Radio} label="(ID)" wrapperClasses="radio-sm"/>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-4 p-3">
                        <label className="w-100 d-flex flex-row justify-content-between"><span>1a. INSURED'S I.D. NUMBER</span><span>(For Program in Item 1) *</span></label>
                        <Field name="section1a[insured_id_number]" component={Input} className="form-control" wrapperClasses="pl-6" validate={[required]}/>
                      </div>
                    </div>

                    <div className="row border-top border-danger">
                      <div className="col-4 p-3 border-right border-danger">
                        <label>2. PATIENT’S NAME (Last Name, First Name, Middle Initial) *</label>
                        <Field name="section2[patient_name]" component={Input} className="form-control" wrapperClasses="pl-5" validate={[required]}/>
                      </div>
                      <div className="col-4 border-right border-danger">
                        <div className="row">
                          <div className="col-6 p-3">
                            <label>3. PATIENT’S BIRTH DATE *</label>
                            <div className="d-flex flex-row">
                              <div className="pl-5 text-center">
                                <Field name="section3[patient_birth_month]" component={Input} className="form-control" validate={[required]} />
                                <small>MM</small>
                              </div>

                              <div className="pl-5 text-center">
                                <Field name="section3[patient_birth_day]" component={Input} className="form-control" validate={[required]} />
                                <small>DD</small>
                              </div>

                              <div className="pl-5 text-center">
                                <Field name="section3[patient_birth_year]" component={Input} className="form-control" validate={[required]} />
                                <small>YYYY</small>
                              </div>
                            </div>
                          </div>
                          <div className="col-1 px-0"></div>
                          <div className="col-3 p-3 radio-btn text-center">
                            <label>Sex *</label>
                            <div className="d-flex flex-row align-items-center">
                              <Field name="section3[gender]" value="male" type="radio" component={Radio} label="M" wrapperClasses="radio-sm mr-6 radio-sm" validate={[required]}/>
                              <Field name="section3[gender]" value="female" type="radio" component={Radio} label="F" wrapperClasses="radio-sm mr-6 radio-sm" validate={[required]}/>
                              <Field name="section3[gender]" value="others" type="radio" component={Radio} label="O" wrapperClasses="radio-sm radio-sm" validate={[required]}/>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-4 p-3">
                        <label>4. INSURED'S NAME (Last Name, First Name, Middle Initial) *</label>
                        <Field name="section4[insured_name]" component={Input} className="form-control" wrapperClasses="pl-4" validate={[required]}/>
                      </div>
                    </div>

                    <div className="row border-top border-danger">
                      <div className="col-4 p-3 border-right border-danger">
                        <label>5. PATIENT’S ADDRESS (No., Street) *</label>
                        <Field name="section5[street]" component={Input} className="form-control" wrapperClasses="pl-5" validate={[required]}/>
                      </div>
                      <div className="col-4 p-3 border-right border-danger radio-btn">
                        <label>6. PATIENT RELATIONSHIP TO INSURED</label>
                        <div className="d-flex flex-row pl-5">
                          <Field name="section6[patient_relationship_to_insured]" value="self" type="radio" label="Self" component={Radio} wrapperClasses="radio-sm mr-7"/>
                          <Field name="section6[patient_relationship_to_insured]" value="spouse" type="radio" label="Spouse" component={Radio} wrapperClasses="radio-sm mr-7"/>
                          <Field name="section6[patient_relationship_to_insured]" value="child" type="radio" label="Child" component={Radio} wrapperClasses="radio-sm mr-7"/>
                          <Field name="section6[patient_relationship_to_insured]" value="other" type="radio" label="Other" component={Radio} wrapperClasses="radio-sm"/>
                        </div>
                      </div>
                      <div className="col-4 p-3">
                        <label>7. INSURED’s ADDRESS (No., Street) *</label>
                        <Field name="section7[street]" component={Input} className="form-control" wrapperClasses="pl-4" validate={[required]}/>
                      </div>
                    </div>

                    <div className="row border-top border-danger">
                      <div className="col-4 border-right border-danger">
                        <div className="row">
                          <div className="col-8 p-3 border-right border-danger">
                            <Field name="section5[city]" component={Input} label="CITY *" className="form-control" wrapperClasses="pl-5" validate={[required]}/>
                          </div>
                          <div className="col-4 p-3">
                            <Field name="section5[state]" component={Input} label="STATE *" className="form-control" validate={[required]}/>
                          </div>
                        </div>
                        <div className="row border-top border-danger">
                          <div className="col-4 border-right border-danger p-3">
                            <Field name="section5[zipcode]" component={Input} label="ZIP CODE *" className="form-control" wrapperClasses="pl-5" validate={[required]}/>
                          </div>
                          <div className="col-8 p-3">
                          <label>TELEPHONE (Include Area Code)</label>
                            <div className="d-flex">
                              <Field name="section5[area_code]" component={Input} className="form-control" wrapperClasses="w-25"/>
                              <Field name="section5[telephone]" component={Input} className="form-control" wrapperClasses="w-75 pl-3"/>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-4 p-3 border-right border-danger radio-btn">
                        <label>8. RESERVED FOR NUCC USE</label>
                        {/* <div className="d-flex flex-row pl-5">
                          <Field text="radio" label="Single" component={Radio} wrapperClasses="radio-sm mr-7"/>
                          <Field text="radio" label="Married" component={Radio} wrapperClasses="radio-sm mr-7"/>
                          <Field text="radio" label="Other" component={Radio} wrapperClasses="radio-sm mr-7"/>
                        </div>
                        <div className="d-flex flex-row mt-7 pl-5">
                          <Field text="radio" label="Employed" component={Radio} wrapperClasses="radio-sm mr-5"/>
                          <Field text="radio" label="Full-Time Student" component={Radio} wrapperClasses="radio-sm mr-5"/>
                          <Field text="radio" label="Part-Time Student" component={Radio} wrapperClasses="radio-sm"/>
                        </div> */}
                      </div>
                      <div className="col-4">
                        <div className="row">
                          <div className="col-8 p-3 border-right border-danger">
                            <Field name="section7[city]" component={Input} label="CITY *" className="form-control" wrapperClasses="pl-5" validate={[required]}/>
                          </div>
                          <div className="col-4 p-3">
                            <Field name="section7[state]" component={Input} label="STATE *" className="form-control" validate={[required]}/>
                          </div>
                        </div>
                        <div className="row border-top border-danger">
                          <div className="col-4 border-right border-danger p-3">
                            <Field name="section7[zipcode]" component={Input} label="ZIP CODE *" className="form-control" wrapperClasses="pl-5" validate={[required]}/>
                          </div>
                          <div className="col-8 p-3">
                            <label>TELEPHONE (Include Area Code)</label>
                            <div className="d-flex">
                              <Field name="section7[area_code]" component={Input} className="form-control" wrapperClasses="w-25"/>
                              <Field name="section7[telephone]" component={Input} className="form-control" wrapperClasses="w-75 pl-3"/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row border-top border-danger">
                      <div className="col-4 border-right border-danger">
                        <div className="row">
                          <div className="col-12 p-3">
                            <label className="text-nowrap">9. OTHER INSURED’S NAME (Last Name, First Name, Middle Initial)</label>
                            <Field name="section9[other_insured_name]" component={Input} className="form-control" wrapperClasses="pl-5"/>
                          </div>
                          <div className="col-12 p-3 border-top border-danger">
                            <label className="text-nowrap">a. OTHER INSURED'S POLICY OR GROUP NUMBER</label>
                            <Field name="section9[other_insured_policy]" component={Input} className="form-control" wrapperClasses="pl-5"/>
                          </div>
                          <div className="col-12 border-top border-danger">
                            <div className="row">
                              <div className="col-12 p-3 border-top border-danger">
                                <label>b. RESERVED FOR NUCC USE</label>
                                <Field name="section9[]" component={Input} className="form-control" wrapperClasses="pl-5"/>

                                {/* <div className="d-flex flex-row">
                                  <div className="pl-5 text-center">
                                    <Field component={Input} className="form-control" />
                                    <small>MM</small>
                                  </div>

                                  <div className="pl-5 text-center">
                                    <Field component={Input} className="form-control" />
                                    <small>DD</small>
                                  </div>

                                  <div className="pl-5 text-center">
                                    <Field component={Input} className="form-control" />
                                    <small>YY</small>
                                  </div>
                                </div> */}
                              </div>
                              {/* <div className="col-xl-2 col-1"></div>
                              <div className="col-3 p-3 radio-btn text-center">
                                <label>Sex</label>
                                <div className="d-flex flex-row align-items-center">
                                  <Field component={Radio} label="M" wrapperClasses="radio-sm mr-6 radio-sm"/>
                                  <Field component={Radio} label="F" wrapperClasses="radio-sm radio-sm"/>
                                </div>
                              </div> */}
                            </div>
                          </div>
                          <div className="col-12 p-3 border-top border-danger">
                            <label className="text-nowrap">c. RESERVED FOR NUCC USE</label>
                            <Field name="section9[]" component={Input} className="form-control" wrapperClasses="pl-5"/>
                          </div>
                        </div>
                      </div>
                      <div className="col-4 p-3 border-right border-danger radio-btn">
                        <label>10. IS PATIENT'S CONDITION RELATED TO</label>
                        <div className="row">
                          <div className="col-12 mt-7">
                            <span>a. EMPLOYMENT? (Current or Previous)</span>
                            <div className="d-flex flex-row pl-5">
                              <Field name="section10[patient_condition_related_to][employment]" value="yes" type="radio" label="Yes" component={Radio} wrapperClasses="radio-sm mr-7"/>
                              <Field name="section10[patient_condition_related_to][employment]" value="no" type="radio" label="No" component={Radio} wrapperClasses="radio-sm mr-7"/>
                            </div>
                          </div>
                          <div className="col-5 mt-7">
                            <span>b. AUTO ACCIDENT?</span>
                            <div className="d-flex flex-row pl-5">
                              <Field name="section10[patient_condition_related_to][auto_accident]" value="yes" type="radio" label="Yes" component={Radio} wrapperClasses="radio-sm mr-7"/>
                              <Field name="section10[patient_condition_related_to][auto_accident]" value="no" type="radio" label="No" component={Radio} wrapperClasses="radio-sm mr-7"/>
                            </div>
                          </div>
                          <Field name="section10[place]" label="Place(State)" component={Input} className="form-control" wrapperClasses="col-4 mt-7"/>
                          <div className="col-12 mt-7">
                            <span>c. OTHER ACCIDENT?</span>
                            <div className="d-flex flex-row pl-5">
                              <Field name="section10[patient_condition_related_to][other_accident]" value="yes" type="radio" label="Yes" component={Radio} wrapperClasses="radio-sm mr-7"/>
                              <Field name="section10[patient_condition_related_to][other_accident]" value="no" type="radio" label="No" component={Radio} wrapperClasses="radio-sm mr-7"/>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="row">
                          <div className="col-12 p-3">
                            <label>11. INSURED'S POLICY GROUP OR FECA NUMBER</label>
                            <Field name="section11[insured_policy_group]" component={Input} className="form-control" wrapperClasses="pl-5"/>
                          </div>
                          <div className="col-12 border-top border-danger">
                            <div className="row">
                              <div className="col-6 p-3">
                                <label>a. OTHER INSURED'S DATE OF BIRTH *</label>
                                <div className="d-flex flex-row">
                                  <div className="pl-5 text-center">
                                    <Field name="section11[insured_birth_month]" component={Input} className="form-control" validate={[required]} />
                                    <small>MM</small>
                                  </div>

                                  <div className="pl-5 text-center">
                                    <Field name="section11[insured_birth_day]" component={Input} className="form-control" validate={[required]} />
                                    <small>DD</small>
                                  </div>

                                  <div className="pl-5 text-center">
                                    <Field name="section11[insured_birth_year]" component={Input} className="form-control" validate={[required]} />
                                    <small>YYYY</small>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-2 col-1"></div>
                              <div className="col-4 p-3 radio-btn">
                                <label>Sex</label>
                                <div className="d-flex flex-row align-items-center">
                                  <Field name="section11[insured_gender]" value="male" type="radio" component={Radio} label="M" wrapperClasses="radio-sm mr-3 radio-sm"/>
                                  <Field name="section11[insured_gender]" value="female" type="radio" component={Radio} label="F" wrapperClasses="radio-sm mr-3 radio-sm"/>
                                  <Field name="section11[insured_gender]" value="others" type="radio" component={Radio} label="O" wrapperClasses="radio-sm radio-sm"/>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 p-3 border-top border-danger">
                            <label>b. OTHER CLAIM ID  (Designated by NUCC)</label>
                            <Field name="section11[other_claim_id]" component={Input} className="form-control" wrapperClasses="pl-5"/>
                          </div>
                          <div className="col-12 p-3 border-top border-danger">
                            <label>c. INSURANCE PLAN NAME OR PROGRAM NAME</label>
                            <Field name="section11[insured_plan_name]" component={Input} className="form-control" wrapperClasses="pl-5"/>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row border-top border-danger">
                      <div className="col-4 p-3 border-right border-danger">
                        <label>d. INSURANCE PLAN NAME OR PROGRAM NAME</label>
                        <Field name="section9[insurance_plan_name]" component={Input} className="form-control" wrapperClasses="pl-5"/>
                      </div>
                      <div className="col-4 p-3 border-right border-danger">
                        <label>10d. CLAIM CODES (Designated by NUCC)</label>
                        <Field name="section10[claim_codes]" component={Input} className="form-control" wrapperClasses="pl-7"/>
                      </div>
                      <div className="col-4 p-3 radio-btn">
                        <label>d. IS THERE ANOTHER HEALTH BENEFIT PLAN?</label>
                        <div className="d-flex flex-row align-items-center">
                          <Field name="section11[another_health_benefit_plan]" value="yes" component={Radio} type="radio" label="Yes" wrapperClasses="pl-4 radio-sm mr-5"/>
                          <Field name="section11[another_health_benefit_plan]" value="no" component={Radio} type="radio" label="No" wrapperClasses="pl-4 radio-sm mr-7"/>
                          <small>If yes, return to and complete item 9 a-d.</small>
                        </div>
                      </div>
                    </div>

                    <div className="row border-top border-danger">
                      <div className="col-8 p-3 border-right border-danger">
                        <h6 className="text-center">READ BACK OF FORM BEFORE COMPLETING & SIGNING THIS FORM.</h6>
                        <label className="d-flex flex-row align-items-start">
                          <div className="pr-1">12. </div>
                          <div>
                            PATIENT OR AUTHORIZED PERSON'S SIGNATURE I authorized the release of any medical or other information necessary to process this claim. I also request payment of government benefits either to myself or to the party who accepts assignment below.
                            <div className="d-flex flex-row justify-content-between mt-3">
                              <div className="d-flex flex-row align-items-end">
                                <span>SIGNATURE</span>
                                <Field name="section12[patient_or_authorized_signature]" component={Input} className="form-control" wrapperClasses="pl-3"/>
                              </div>
                              <div className="d-flex flex-row align-items-end">
                                <span>DATE</span>
                                <Field name="section12[signature_date]" component={Input} className="form-control" wrapperClasses="pl-3"/>
                              </div>
                            </div>
                          </div>
                        </label>
                      </div>
                      <div className="col-4 p-3 radio-btn">
                        <label className="d-flex flex-row align-items-start">
                          <div className="pr-1">13. </div>
                          <div>
                            INSURED's OR AUTHORIZED PERSON'S SIGNATURE I authorize payment of medical benefits to the undersigned physician or supplier for services described below
                            <div className="d-flex flex-row align-items-end mt-3">
                              <span>SIGNATURE</span>
                              <Field name="section13[insured_or_authorized_signature]" component={Input} className="form-control" wrapperClasses="pl-4 radio-sm mr-7"/>
                            </div>
                          </div>
                        </label>
                      </div>


                    </div>

                    <div className="row border-top border-danger">
                      <div className="col-4 p-3 border-right border-danger">
                        <label>14. DATE OF CURRENT ILLNESS, INJURY, or PREGNANCY (LMP)</label>
                        <div className="d-flex flex-row">
                          <div className="text-center">
                            <Field name="section14[month_of_current_illness]" component={Input} className="form-control" wrapperClasses="ml-7"/>
                            <small>MM</small>
                          </div>
                          <div className="text-center">
                            <Field name="section14[day_of_current_illness]" component={Input} className="form-control" wrapperClasses="pl-5"/>
                            <small>DD</small>
                          </div>
                          <div className="text-center">
                            <Field name="section14[year_of_current_illness]" component={Input} className="form-control" wrapperClasses="pl-5"/>
                            <small>YY</small>
                          </div>
                          <div className="ml-7">
                            <div className="d-flex flex-row align-items-center">
                              <span>QUAL</span>
                              <Field name="section14[qual]" component={Input} className="form-control" wrapperClasses="pl-3"/>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-4 p-3 border-right border-danger">
                        <label>15. OTHER DATE</label>
                        <div className="d-flex flex-row">
                          <div className="ml-7">
                            <div className="d-flex flex-row align-items-center">
                              <span>QUAL</span>
                              <Field name="section15[qual]" component={Input} className="form-control" wrapperClasses="pl-3"/>
                            </div>
                          </div>
                          <div className="text-center">
                            <Field name="section15[other_date_month]" component={Input} className="form-control" wrapperClasses="pl-7"/>
                            <small>MM</small>
                          </div>
                          <div className="text-center">
                            <Field name="section15[other_date_day]" component={Input} className="form-control" wrapperClasses="pl-5"/>
                            <small>DD</small>
                          </div>
                          <div className="text-center">
                            <Field name="section15[other_date_year]" component={Input} className="form-control" wrapperClasses="pl-5"/>
                            <small>YY</small>
                          </div>
                        </div>
                      </div>
                      <div className="col-4 p-3">
                        <label>16. DATES PATIENT UNABLE TO WORK IN CURRENT
                        OCCUPATION</label>
                        <div className="d-flex flex-row">
                          <div className="pt-3">FROM</div>
                          <div className="text-center">
                            <Field name="section16[patient_unable_to_work_from_month]" component={Input} className="form-control" wrapperClasses="pl-3"/>
                            <small>MM</small>
                          </div>
                          <div className="text-center">
                            <Field name="section16[patient_unable_to_work_from_day]" component={Input} className="form-control" wrapperClasses="pl-3"/>
                            <small>DD</small>
                          </div>
                          <div className="text-center">
                            <Field name="section16[patient_unable_to_work_from_year]" component={Input} className="form-control" wrapperClasses="pl-3"/>
                            <small>YY</small>
                          </div>
                          <div className="pt-3 pl-3">TO</div>
                          <div className="text-center">
                            <Field name="section16[patient_unable_to_work_to_month]" component={Input} className="form-control" wrapperClasses="pl-3"/>
                            <small>MM</small>
                          </div>
                          <div className="text-center">
                            <Field name="section16[patient_unable_to_work_to_day]" component={Input} className="form-control" wrapperClasses="pl-3"/>
                            <small>DD</small>
                          </div>
                          <div className="text-center">
                            <Field name="section16[patient_unable_to_work_to_year]" component={Input} className="form-control" wrapperClasses="pl-3"/>
                            <small>YY</small>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row border-top border-danger">
                      <div className="col-4 p-3 border-right border-danger">
                        <label>17. NAME OF REFERRING PROVIDER OR OTHER SOURCE</label>
                        <div className="row">
                          <Field name="section17[qual]" component={Input} className="form-control" wrapperClasses="pl-8 col-4 pr-0"/>
                          <Field name="section17[name_of_referring_provider_or_other_source]" component={Input} className="form-control" wrapperClasses="col-8"/>
                        </div>
                      </div>
                      <div className="col-4 border-right border-danger">
                        <div className="row">
                          <div className="col-1 py-3 px-2 border-right border-danger">
                            17a.
                          </div>
                          <div className="col-2 border-right border-danger p-3">
                            <Field name="section17[17a1]" component={Input} className="form-control" wrapperClasses=""/>
                          </div>
                          <div className="col-9 p-3">
                            <Field name="section17[17a2]" component={Input} className="form-control" wrapperClasses=""/>
                          </div>
                          <div className="col-1 py-3 px-2 border-right border-danger border-dashed-top">
                            17b.
                          </div>
                          <div className="col-2 border-right border-danger p-3 border-dashed-top">
                            <span>NPI</span>
                          </div>
                          <div className="col-9 p-3 border-dashed-top">
                            <Field name="section17[npi]" component={Input} className="form-control" wrapperClasses=""/>
                          </div>

                        </div>
                      </div>
                      <div className="col-4 p-3">
                        <label>18. HOSPITALIZATION DATES RELATED TO CURRENT
                        SERVICES</label>
                        <div className="d-flex flex-row">
                          <div className="pt-3">FROM</div>
                          <div className="text-center">
                            <Field name="section18[hospitalization_from_day]" component={Input} className="form-control" wrapperClasses="pl-3"/>
                            <small>MM</small>
                          </div>
                          <div className="text-center">
                            <Field name="section18[hospitalization_from_month]" component={Input} className="form-control" wrapperClasses="pl-3"/>
                            <small>DD</small>
                          </div>
                          <div className="text-center">
                            <Field name="section18[hospitalization_from_year]" component={Input} className="form-control" wrapperClasses="pl-3"/>
                            <small>YY</small>
                          </div>
                          <div className="pt-3 pl-3">TO</div>
                          <div className="text-center">
                            <Field name="section18[hospitalization_to_month]" component={Input} className="form-control" wrapperClasses="pl-3"/>
                            <small>MM</small>
                          </div>
                          <div className="text-center">
                            <Field name="section18[hospitalization_to_day]" component={Input} className="form-control" wrapperClasses="pl-3"/>
                            <small>DD</small>
                          </div>
                          <div className="text-center">
                            <Field name="section18[hospitalization_to_year]" component={Input} className="form-control" wrapperClasses="pl-3"/>
                            <small>YY</small>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row border-top border-danger">
                      <div className="col-8 p-3 border-right border-danger">
                        <label>19. ADDITIONAL CLAIM INFORMATION (Designated by NUCC)</label>
                        <Field name="section19[additional_claim_information]" component={Input} className="form-control" wrapperClasses="pl-6"/>
                      </div>
                      <div className="col-4 p-3">
                        <div className="row">
                          <div className="col-4 radio-btn">
                            <label>20. OUTSIDE LAB?</label>
                            <div className="d-flex flex-row">
                              <Field name="section20[outside_lab]" value="yes" type="radio" component={Radio} label="YES" wrapperClasses="radio-sm ml-5"/>
                              <Field name="section20[outside_lab]" value="no" type="radio" component={Radio} label="NO" wrapperClasses="radio-sm ml-5"/>
                            </div>
                          </div>
                          <div className="col-8">
                            <label>$CHARGES</label>
                            <Field name="section20[charges1]" component={Input} className="form-control" wrapperClasses="pl-4"/>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row border-top border-danger">
                      <div className="col-8 p-3 border-right border-danger">
                        <div className="d-flex justiy-content-between mb-3">
                          <label>21. DIAGNOSIS OF NATURE OF ILLNESS OR INJURY Relate A-L to Service Line below(24E)</label>
                          <div className="col-3 d-flex flex-row align-items-center">
                            <span className="pl-xl-7 pl-5 pr-3 text-nowrap border-dashed-right h-100">ICD Ind.</span>
                            <Field name="section21[icd_ind]" component={Input} className="form-control form-control-sm" wrapperClasses="px-xl-5 border-dashed-right"/>
                          </div>
                        </div>
                        <div className="row disabled">
                          <div className="col-3 d-flex flex-row align-items-center">
                            <span className="pl-xl-7 pl-5 pr-3">A*.</span>
                            <Field name="section21[a]" component={Input} className="form-control" wrapperClasses="pr-xl-7" validate={[required]}/>
                          </div>
                          <div className="col-3 d-flex flex-row align-items-center">
                            <span className="pl-xl-7 pl-5 pr-3">B.</span>
                            <Field name="section21[b]" component={Input} className="form-control" wrapperClasses="pr-xl-7"/>
                          </div>
                          <div className="col-3 d-flex flex-row align-items-center">
                            <span className="pl-xl-7 pl-5 pr-3">C.</span>
                            <Field name="section21[c]" component={Input} className="form-control" wrapperClasses="pr-xl-7"/>
                          </div>
                          <div className="col-3 mt-3 d-flex flex-row align-items-center">
                            <span className="pl-xl-7 pl-5 pr-3">D.</span>
                            <Field name="section21[d]" component={Input} className="form-control" wrapperClasses="pr-xl-7"/>
                          </div>
                          <div className="col-3 mt-3 d-flex flex-row align-items-center">
                            <span className="pl-xl-7 pl-5 pr-3">E.</span>
                            <Field name="section21[e]" component={Input} className="form-control" wrapperClasses="pr-xl-7"/>
                          </div>
                          <div className="col-3 mt-3 d-flex flex-row align-items-center">
                            <span className="pl-xl-7 pl-5 pr-3">F.</span>
                            <Field name="section21[f]" component={Input} className="form-control" wrapperClasses="pr-xl-7"/>
                          </div>
                          <div className="col-3 mt-3 d-flex flex-row align-items-center">
                            <span className="pl-xl-7 pl-5 pr-3">G.</span>
                            <Field name="section21[g]" component={Input} className="form-control" wrapperClasses="pr-xl-7"/>
                          </div>
                          <div className="col-3 mt-3 d-flex flex-row align-items-center">
                            <span className="pl-xl-7 pl-5 pr-3">H.</span>
                            <Field name="section21[h]" component={Input} className="form-control" wrapperClasses="pr-xl-7"/>
                          </div>
                          <div className="col-3 mt-3 d-flex flex-row align-items-center">
                            <span className="pl-xl-7 pl-5 pr-3">I.</span>
                            <Field name="section21[i]" component={Input} className="form-control" wrapperClasses="pr-xl-7"/>
                          </div>
                          <div className="col-3 mt-3 d-flex flex-row align-items-center">
                            <span className="pl-xl-7 pl-5 pr-3">J.</span>
                            <Field name="section21[j]" component={Input} className="form-control" wrapperClasses="pr-xl-7"/>
                          </div>
                          <div className="col-3 mt-3 d-flex flex-row align-items-center">
                            <span className="pl-xl-7 pl-5 pr-3">K.</span>
                            <Field name="section21[k]" component={Input} className="form-control" wrapperClasses="pr-xl-7"/>
                          </div>
                          <div className="col-3 mt-3 d-flex flex-row align-items-center">
                            <span className="pl-xl-7 pl-5 pr-3">L.</span>
                            <Field name="section21[l]" component={Input} className="form-control" wrapperClasses="pr-xl-7"/>
                          </div>
                        </div>
                      </div>
                      <div className="col-4 px-0">
                        <div className="row mx-0">
                          <div className="col-6 p-3">
                            <label>22. RESUBMISSION CODE </label>
                            <Field name="section22[resubmission_code]" component={Input} className="form-control" wrapperClasses="pl-6"/>
                          </div>
                          <div className="col-6 p-3">
                            <label>ORIGINAL REF. NO.</label>
                            <Field name="section22[original_ref_no]" component={Input} className="form-control" wrapperClasses=""/>
                          </div>
                        </div>
                        <div className="row mt-3 mx-0">
                          <div className="col-12 border-top border-danger p-3">
                            <label>23. PRIOR AUTHORIZATION NUMBER</label>
                            <Field name="section23[prior_authorization_number]" component={Input} className="form-control" wrapperClasses="pl-6"/>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row border-top border-danger">
                      <div className="col-12 p-0">
                        <table>
                          <col width="281"/>
                          <col width="86"/>
                          <col width="57"/>
                          <col width="291"/>
                          <col width="97"/>
                          <col width="96"/>
                          <col width="91"/>
                          <col width="99"/>
                          <col width="73"/>
                          <col width="327"/>
                          <thead>
                            <th className="font-weight-normal border-right border-danger p-3 align-top">24. A. DATE(S) OF SERVICE
                              <table className="w-100">
                                <tbody>
                                  <tr className="text-center">
                                    <td colSpan="3">FROM</td>
                                    <td colSpan="3">TO</td>
                                  </tr>
                                  <tr>
                                    <td><small>MM</small></td>
                                    <td><small>DD</small></td>
                                    <td><small>YY</small></td>
                                    <td className="text-center"><small>MM</small></td>
                                    <td className="text-center"><small>DD</small></td>
                                    <td className="text-center"><small>YY</small></td>
                                  </tr>
                                </tbody>
                              </table>
                            </th>
                            <th className="text-center font-weight-normal border-right border-danger p-3 align-top">B.<br/>
                              PLACE OF
                              SERVICE
                            </th>
                            <th className="text-center font-weight-normal border-right border-danger p-2 align-top">C.<br/>
                              EMG</th>
                            <th className="text-center font-weight-normal border-right border-danger p-2 align-top">D. PROCEDURES, SERVICES OR SUPPLIES
                              (Explain Unusual Circumstances)
                              <table className="w-100">
                                <col className="100"/>
                                <tbody>
                                  <tr>
                                    <td className="border-right border-danger w-25 px-2">OPT/HCPCS</td>
                                    <td className="text-center">MODIFIER</td>
                                  </tr>
                                </tbody>
                              </table>
                              </th>
                            <th className="text-center font-weight-normal border-right border-danger p-2 align-top">E.<br/>
                              DIAGNOSIS
                              POINTER</th>
                            <th className="text-center font-weight-normal border-right border-danger p-2 align-top">F.<br/>
                              $CHARGES</th>
                            <th className="text-center font-weight-normal border-right border-danger p-2 align-top">G.<br/>
                              DAYS OR
                              UNITS</th>
                            <th className="text-center font-weight-normal border-right border-danger p-2 align-top">H.<br/>
                              EPSDT
                              Family Plan</th>
                            <th className="text-center font-weight-normal border-right border-danger p-2 align-top">I.<br/>
                              ID
                              QUAL</th>
                            <th className="text-center font-weight-normal p-2 align-top"> J.<br/>
                              RENDERING PROVIDER ID. #</th>
                          </thead>
                          <tbody className=" position-relative">
                            <tr className="position-absolute ml-n6 mt-7"><h2>1</h2></tr>
                            <tr className="border-top border-danger">
                              <td className="px-2 pt-3 border-right border-danger" colspan="8"><Field name="section24_1[line_detail_narrative]" component={Input} className="form-control" wrapperClasses="w-100"/></td>
                              <td className="px-2 pt-3 border-right border-danger"><Field name="section24_1[id_qual]" component={Input} className="form-control" wrapperClasses="w-100"/></td>
                              <td className="px-2 pt-3"><Field component={Input} className="form-control" wrapperClasses="w-100"/></td>
                            </tr>
                            <tr>
                              <td className="p-2 border-right border-danger">
                                <table>
                                  <tbody>
                                    <tr>
                                      <td className="border-dashed-right">
                                        <Field name="section24_1[dos_from_month]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                      <td className="border-dashed-right">
                                        <Field name="section24_1[dos_from_day]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                      <td className="border-right border-danger">
                                        <Field name="section24_1[dos_from_year]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                      <td className="border-dashed-right">
                                        <Field name="section24_1[dos_to_month]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                      <td className="border-dashed-right">
                                        <Field name="section24_1[dos_to_day]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                      <td>
                                        <Field name="section24_1[dos_to_year]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td className="p-2 border-right border-danger">
                                <Field name="section24_1[place_of_service]" component={Input} className="form-control" wrapperClasses="w-100"/>
                              </td>
                              <td className="p-2 border-right border-danger">
                                <Field name="section24_1[emg]" component={Input} className="form-control" wrapperClasses="w-100"/>
                              </td>
                              <td className="p-2 border-right border-danger">
                                <table>
                                  <col width="100"/>
                                  <tbody>
                                    <tr>
                                      <td className="border-dashed-right">
                                        <Field name="section24_1[cpt_hcpcs]" component={Input} className="form-control" wrapperClasses="w-100"/>
                                      </td>
                                      <td className="border-dashed-right">
                                        <Field name="section24_1[modifier1]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                      <td className="border-dashed-right">
                                        <Field name="section24_1[modifier2]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                      <td className="border-dashed-right">
                                        <Field name="section24_1[modifier3]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                      <td className="">
                                        <Field name="section24_1[modifier4]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td className="p-2 border-right border-danger">
                                <Field name="section24_1[diagnosis_pointer]" component={Input} className="form-control" wrapperClasses="w-100"/>
                              </td>
                              <td className="p-2 border-right border-danger">
                                <Field name="section24_1[charges]" component={Input} className="form-control" wrapperClasses="w-100"/>
                              </td>
                              <td className="p-2 border-right border-danger">
                                <Field name="section24_1[days_or_units]" component={Input} className="form-control" wrapperClasses="w-100"/>
                              </td>
                              <td className="p-2 border-right border-danger">
                                <Field name="section24_1[epsdt]" component={Input} className="form-control" wrapperClasses="w-100"/>
                              </td>
                              <td className="p-2 border-right border-danger text-center border-dashed-top">
                                <span>NPI</span>
                              </td>
                              <td className="p-2 border-dashed-top">
                                <Field name="section24_1[rendering_provider_id]" component={Input} className="form-control" wrapperClasses="w-100"/>
                              </td>
                            </tr>

                            <tr className="position-absolute ml-n6 mt-7"><h2>2</h2></tr>
                            <tr className="border-top border-danger">
                              <td className="px-2 pt-3 border-right border-danger" colspan="8"><Field name="section24_2[line_detail_narrative]" component={Input} className="form-control" wrapperClasses="w-100"/></td>
                              <td className="px-2 pt-3 border-right border-danger"><Field name="section24_2[id_qual]" component={Input} className="form-control" wrapperClasses="w-100"/></td>
                              <td className="px-2 pt-3"><Field component={Input} className="form-control" wrapperClasses="w-100"/></td>
                            </tr>
                            <tr>
                              <td className="p-2 border-right border-danger">
                                <table>
                                  <tbody>
                                    <tr>
                                      <td className="border-dashed-right">
                                        <Field name="section24_2[dos_from_month]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                      <td className="border-dashed-right">
                                        <Field name="section24_2[dos_from_day]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                      <td className="border-right border-danger">
                                        <Field name="section24_2[dos_from_year]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                      <td className="border-dashed-right">
                                        <Field name="section24_2[dos_to_month]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                      <td className="border-dashed-right">
                                        <Field name="section24_2[dos_to_day]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                      <td>
                                        <Field name="section24_2[dos_to_year]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td className="p-2 border-right border-danger">
                                <Field name="section24_2[place_of_service]" component={Input} className="form-control" wrapperClasses="w-100"/>
                              </td>
                              <td className="p-2 border-right border-danger">
                                <Field name="section24_2[emg]" component={Input} className="form-control" wrapperClasses="w-100"/>
                              </td>
                              <td className="p-2 border-right border-danger">
                                <table>
                                  <col width="100"/>
                                  <tbody>
                                    <tr>
                                      <td className="border-dashed-right">
                                        <Field name="section24_2[cpt_hcpcs]" component={Input} className="form-control" wrapperClasses="w-100"/>
                                      </td>
                                      <td className="border-dashed-right">
                                        <Field name="section24_2[modifier1]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                      <td className="border-dashed-right">
                                        <Field name="section24_2[modifier2]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                      <td className="border-dashed-right">
                                        <Field name="section24_2[modifier3]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                      <td className="">
                                        <Field name="section24_2[modifier4]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td className="p-2 border-right border-danger">
                                <Field name="section24_2[diagnosis_pointer]" component={Input} className="form-control" wrapperClasses="w-100"/>
                              </td>
                              <td className="p-2 border-right border-danger">
                                <Field name="section24_2[charges]" component={Input} className="form-control" wrapperClasses="w-100"/>
                              </td>
                              <td className="p-2 border-right border-danger">
                                <Field name="section24_2[days_or_units]" component={Input} className="form-control" wrapperClasses="w-100"/>
                              </td>
                              <td className="p-2 border-right border-danger">
                                <Field name="section24_2[epsdt]" component={Input} className="form-control" wrapperClasses="w-100"/>
                              </td>
                              <td className="p-2 border-right border-danger text-center border-dashed-top">
                                <span>NPI</span>
                              </td>
                              <td className="p-2 border-dashed-top">
                                <Field name="section24_2[rendering_provider_id]" component={Input} className="form-control" wrapperClasses="w-100"/>
                              </td>
                            </tr>

                            <tr className="position-absolute ml-n6 mt-7"><h2>3</h2></tr>
                            <tr className="border-top border-danger">
                              <td className="px-2 pt-3 border-right border-danger" colspan="8"><Field name="section24_3[line_detail_narrative]" component={Input} className="form-control" wrapperClasses="w-100"/></td>
                              <td className="px-2 pt-3 border-right border-danger"><Field name="section24_3[id_qual]" component={Input} className="form-control" wrapperClasses="w-100"/></td>
                              <td className="px-2 pt-3"><Field component={Input} className="form-control" wrapperClasses="w-100"/></td>
                            </tr>
                            <tr>
                              <td className="p-2 border-right border-danger">
                                <table>
                                  <tbody>
                                    <tr>
                                      <td className="border-dashed-right">
                                        <Field name="section24_3[dos_from_month]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                      <td className="border-dashed-right">
                                        <Field name="section24_3[dos_from_day]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                      <td className="border-right border-danger">
                                        <Field name="section24_3[dos_from_year]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                      <td className="border-dashed-right">
                                        <Field name="section24_3[dos_to_month]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                      <td className="border-dashed-right">
                                        <Field name="section24_3[dos_to_day]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                      <td>
                                        <Field name="section24_3[dos_to_year]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td className="p-2 border-right border-danger">
                                <Field name="section24_3[place_of_service]" component={Input} className="form-control" wrapperClasses="w-100"/>
                              </td>
                              <td className="p-2 border-right border-danger">
                                <Field name="section24_3[emg]" component={Input} className="form-control" wrapperClasses="w-100"/>
                              </td>
                              <td className="p-2 border-right border-danger">
                                <table>
                                  <col width="100"/>
                                  <tbody>
                                    <tr>
                                      <td className="border-dashed-right">
                                        <Field name="section24_3[cpt_hcpcs]" component={Input} className="form-control" wrapperClasses="w-100"/>
                                      </td>
                                      <td className="border-dashed-right">
                                        <Field name="section24_3[modifier1]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                      <td className="border-dashed-right">
                                        <Field name="section24_3[modifier2]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                      <td className="border-dashed-right">
                                        <Field name="section24_3[modifier3]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                      <td className="">
                                        <Field name="section24_3[modifier4]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td className="p-2 border-right border-danger">
                                <Field name="section24_3[diagnosis_pointer]" component={Input} className="form-control" wrapperClasses="w-100"/>
                              </td>
                              <td className="p-2 border-right border-danger">
                                <Field name="section24_3[charges]" component={Input} className="form-control" wrapperClasses="w-100"/>
                              </td>
                              <td className="p-2 border-right border-danger">
                                <Field name="section24_3[days_or_units]" component={Input} className="form-control" wrapperClasses="w-100"/>
                              </td>
                              <td className="p-2 border-right border-danger">
                                <Field name="section24_3[epsdt]" component={Input} className="form-control" wrapperClasses="w-100"/>
                              </td>
                              <td className="p-2 border-right border-danger text-center border-dashed-top">
                                <span>NPI</span>
                              </td>
                              <td className="p-2 border-dashed-top">
                                <Field name="section24_3[rendering_provider_id]" component={Input} className="form-control" wrapperClasses="w-100"/>
                              </td>
                            </tr>

                            <tr className="position-absolute ml-n6 mt-7"><h2>4</h2></tr>
                            <tr className="border-top border-danger">
                              <td className="px-2 pt-3 border-right border-danger" colspan="8"><Field name="section24_4[line_detail_narrative]" component={Input} className="form-control" wrapperClasses="w-100"/></td>
                              <td className="px-2 pt-3 border-right border-danger"><Field name="section24_4[id_qual]" component={Input} className="form-control" wrapperClasses="w-100"/></td>
                              <td className="px-2 pt-3"><Field component={Input} className="form-control" wrapperClasses="w-100"/></td>
                            </tr>
                            <tr>
                              <td className="p-2 border-right border-danger">
                                <table>
                                  <tbody>
                                    <tr>
                                      <td className="border-dashed-right">
                                        <Field name="section24_4[dos_from_month]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                      <td className="border-dashed-right">
                                        <Field name="section24_4[dos_from_day]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                      <td className="border-right border-danger">
                                        <Field name="section24_4[dos_from_year]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                      <td className="border-dashed-right">
                                        <Field name="section24_4[dos_to_month]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                      <td className="border-dashed-right">
                                        <Field name="section24_4[dos_to_day]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                      <td>
                                        <Field name="section24_4[dos_to_year]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td className="p-2 border-right border-danger">
                                <Field name="section24_4[place_of_service]" component={Input} className="form-control" wrapperClasses="w-100"/>
                              </td>
                              <td className="p-2 border-right border-danger">
                                <Field name="section24_4[emg]" component={Input} className="form-control" wrapperClasses="w-100"/>
                              </td>
                              <td className="p-2 border-right border-danger">
                                <table>
                                  <col width="100"/>
                                  <tbody>
                                    <tr>
                                      <td className="border-dashed-right">
                                        <Field name="section24_4[cpt_hcpcs]" component={Input} className="form-control" wrapperClasses="w-100"/>
                                      </td>
                                      <td className="border-dashed-right">
                                        <Field name="section24_4[modifier1]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                      <td className="border-dashed-right">
                                        <Field name="section24_4[modifier2]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                      <td className="border-dashed-right">
                                        <Field name="section24_4[modifier3]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                      <td className="">
                                        <Field name="section24_4[modifier4]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td className="p-2 border-right border-danger">
                                <Field name="section24_4[diagnosis_pointer]" component={Input} className="form-control" wrapperClasses="w-100"/>
                              </td>
                              <td className="p-2 border-right border-danger">
                                <Field name="section24_4[charges]" component={Input} className="form-control" wrapperClasses="w-100"/>
                              </td>
                              <td className="p-2 border-right border-danger">
                                <Field name="section24_4[days_or_units]" component={Input} className="form-control" wrapperClasses="w-100"/>
                              </td>
                              <td className="p-2 border-right border-danger">
                                <Field name="section24_4[epsdt]" component={Input} className="form-control" wrapperClasses="w-100"/>
                              </td>
                              <td className="p-2 border-right border-danger text-center border-dashed-top">
                                <span>NPI</span>
                              </td>
                              <td className="p-2 border-dashed-top">
                                <Field name="section24_4[rendering_provider_id]" component={Input} className="form-control" wrapperClasses="w-100"/>
                              </td>
                            </tr>

                            <tr className="position-absolute ml-n6 mt-7"><h2>5</h2></tr>
                            <tr className="border-top border-danger">
                              <td className="px-2 pt-3 border-right border-danger" colspan="8"><Field name="section24_5[line_detail_narrative]" component={Input} className="form-control" wrapperClasses="w-100"/></td>
                              <td className="px-2 pt-3 border-right border-danger"><Field name="section24_5[id_qual]" component={Input} className="form-control" wrapperClasses="w-100"/></td>
                              <td className="px-2 pt-3"><Field component={Input} className="form-control" wrapperClasses="w-100"/></td>
                            </tr>
                            <tr>
                              <td className="p-2 border-right border-danger">
                                <table>
                                  <tbody>
                                    <tr>
                                      <td className="border-dashed-right">
                                        <Field name="section24_5[dos_from_month]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                      <td className="border-dashed-right">
                                        <Field name="section24_5[dos_from_day]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                      <td className="border-right border-danger">
                                        <Field name="section24_5[dos_from_year]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                      <td className="border-dashed-right">
                                        <Field name="section24_5[dos_to_month]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                      <td className="border-dashed-right">
                                        <Field name="section24_5[dos_to_day]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                      <td>
                                        <Field name="section24_5[dos_to_year]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td className="p-2 border-right border-danger">
                                <Field name="section24_5[place_of_service]" component={Input} className="form-control" wrapperClasses="w-100"/>
                              </td>
                              <td className="p-2 border-right border-danger">
                                <Field name="section24_5[emg]" component={Input} className="form-control" wrapperClasses="w-100"/>
                              </td>
                              <td className="p-2 border-right border-danger">
                                <table>
                                  <col width="100"/>
                                  <tbody>
                                    <tr>
                                      <td className="border-dashed-right">
                                        <Field name="section24_5[cpt_hcpcs]" component={Input} className="form-control" wrapperClasses="w-100"/>
                                      </td>
                                      <td className="border-dashed-right">
                                        <Field name="section24_5[modifier1]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                      <td className="border-dashed-right">
                                        <Field name="section24_5[modifier2]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                      <td className="border-dashed-right">
                                        <Field name="section24_5[modifier3]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                      <td className="">
                                        <Field name="section24_5[modifier4]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td className="p-2 border-right border-danger">
                                <Field name="section24_5[diagnosis_pointer]" component={Input} className="form-control" wrapperClasses="w-100"/>
                              </td>
                              <td className="p-2 border-right border-danger">
                                <Field name="section24_5[charges]" component={Input} className="form-control" wrapperClasses="w-100"/>
                              </td>
                              <td className="p-2 border-right border-danger">
                                <Field name="section24_5[days_or_units]" component={Input} className="form-control" wrapperClasses="w-100"/>
                              </td>
                              <td className="p-2 border-right border-danger">
                                <Field name="section24_5[epsdt]" component={Input} className="form-control" wrapperClasses="w-100"/>
                              </td>
                              <td className="p-2 border-right border-danger text-center border-dashed-top">
                                <span>NPI</span>
                              </td>
                              <td className="p-2 border-dashed-top">
                                <Field name="section24_5[rendering_provider_id]" component={Input} className="form-control" wrapperClasses="w-100"/>
                              </td>
                            </tr>

                            <tr className="position-absolute ml-n6 mt-7"><h2>6</h2></tr>
                            <tr className="border-top border-danger">
                              <td className="px-2 pt-3 border-right border-danger" colspan="8"><Field name="section24_6[line_detail_narrative]" component={Input} className="form-control" wrapperClasses="w-100"/></td>
                              <td className="px-2 pt-3 border-right border-danger"><Field name="section24_6[id_qual]" component={Input} className="form-control" wrapperClasses="w-100"/></td>
                              <td className="px-2 pt-3"><Field component={Input} className="form-control" wrapperClasses="w-100"/></td>
                            </tr>
                            <tr>
                              <td className="p-2 border-right border-danger">
                                <table>
                                  <tbody>
                                    <tr>
                                      <td className="border-dashed-right">
                                        <Field name="section24_6[dos_from_month]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                      <td className="border-dashed-right">
                                        <Field name="section24_6[dos_from_day]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                      <td className="border-right border-danger">
                                        <Field name="section24_6[dos_from_year]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                      <td className="border-dashed-right">
                                        <Field name="section24_6[dos_to_month]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                      <td className="border-dashed-right">
                                        <Field name="section24_6[dos_to_day]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                      <td>
                                        <Field name="section24_6[dos_to_year]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td className="p-2 border-right border-danger">
                                <Field name="section24_6[place_of_service]" component={Input} className="form-control" wrapperClasses="w-100"/>
                              </td>
                              <td className="p-2 border-right border-danger">
                                <Field name="section24_6[emg]" component={Input} className="form-control" wrapperClasses="w-100"/>
                              </td>
                              <td className="p-2 border-right border-danger">
                                <table>
                                  <col width="100"/>
                                  <tbody>
                                    <tr>
                                      <td className="border-dashed-right">
                                        <Field name="section24_6[cpt_hcpcs]" component={Input} className="form-control" wrapperClasses="w-100"/>
                                      </td>
                                      <td className="border-dashed-right">
                                        <Field name="section24_6[modifier1]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                      <td className="border-dashed-right">
                                        <Field name="section24_6[modifier2]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                      <td className="border-dashed-right">
                                        <Field name="section24_6[modifier3]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                      <td className="">
                                        <Field name="section24_6[modifier4]" component={Input} className="form-control px-2" wrapperClasses="w-100"/>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td className="p-2 border-right border-danger">
                                <Field name="section24_6[diagnosis_pointer]" component={Input} className="form-control" wrapperClasses="w-100"/>
                              </td>
                              <td className="p-2 border-right border-danger">
                                <Field name="section24_6[charges]" component={Input} className="form-control" wrapperClasses="w-100"/>
                              </td>
                              <td className="p-2 border-right border-danger">
                                <Field name="section24_6[days_or_units]" component={Input} className="form-control" wrapperClasses="w-100"/>
                              </td>
                              <td className="p-2 border-right border-danger">
                                <Field name="section24_6[epsdt]" component={Input} className="form-control" wrapperClasses="w-100"/>
                              </td>
                              <td className="p-2 border-right border-danger text-center border-dashed-top">
                                <span>NPI</span>
                              </td>
                              <td className="p-2 border-dashed-top">
                                <Field name="section24_6[rendering_provider_id]" component={Input} className="form-control" wrapperClasses="w-100"/>
                              </td>
                            </tr>

                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="row border-top border-danger">
                      <div className="col-5 border-right border-danger">
                        <div className="row radio-btn">
                          <div className="col-5 p-3">
                            <label>25. FEDERAL TAX ID NUMBER</label>
                            <Field name="section25[federal_tax_id_number]" component={Input} className="form-control" wrapperClasses="pl-6"/>
                          </div>
                          <div className="col-2 border-right border-danger p-3">
                            <div className="d-flex flex-row">
                              <div className="d-flex flex-column text-center mr-3">
                                <label>SSN</label>
                                <Field name="section25[ssn]" value="true" type="radio" component={Radio} label="" wrapperClasses="radio-sm"/>
                              </div>
                              <div className="d-flex flex-column text-center">
                                <label>EIN</label>
                                <Field name="section25[ein]" value="true" type="radio" component={Radio} label="" wrapperClasses="radio-sm"/>
                              </div>
                            </div>
                          </div>
                          <div className="col-5 p-3">
                            <label>26. PATIENT’S ACCOUNT NO.</label>
                            <Field name="section26[patient_account_number]" component={Input} className="form-control" wrapperClasses="pl-6"/>
                          </div>
                        </div>

                      </div>
                      <div className="col border-right border-danger">
                        <div className="row">
                          <div className="col-7 p-3 border-right border-danger">
                            <label>27. ACCEPT ASSIGNMENT?</label>
                            <label className="pl-6">(For govt. claims, see back)</label>
                            <div className="d-flex flex-row pl-6">
                              <Field name="section27[accept_assingment]" value="yes" type="radio" component={Radio} label="YES" wrapperClasses="radio-sm"/>
                              <Field name="section27[accept_assingment]" value="no" type="radio" component={Radio} label="NO" wrapperClasses="radio-sm ml-6"/>
                            </div>
                          </div>
                          <div className="col-5 p-3">
                            <label>28. TOTAL CHARGE</label>
                            <div className="row">
                              <div className="col-8">
                                <div className="d-flex align-items-center">
                                  <span>$</span>
                                  <Field name="section28[total_charge_int_part]" component={Input} className="form-control" wrapperClasses="pl-4"/>
                                </div>
                              </div>
                              <div className="col-4 pl-0 pr-7">
                                <Field name="section28[total_charge_decimal_part]" component={Input} className="form-control" wrapperClasses=""/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col-6 p-3 border-right border-danger">
                            <label>29. AMOUNT PAID</label>
                            <div className="row">
                              <div className="col-8">
                                <div className="d-flex align-items-center">
                                  <span>$</span>
                                  <Field name="section29[amount_paid_int_part]" component={Input} className="form-control" wrapperClasses="pl-4"/>
                                </div>
                              </div>
                              <div className="col-4 pl-0 pr-7">
                                <Field name="section29[amount_paid_decimal_part]" component={Input} className="form-control" wrapperClasses=""/>
                              </div>
                            </div>
                          </div>
                          <div className="col-6 p-3">
                            <label>30. Rsvd. for NUCC Use</label>
                            <div className="row">
                              <div className="col-8">
                                <div className="d-flex align-items-center">
                                  <span>$</span>
                                  <Field name="section30[]" component={Input} className="form-control" wrapperClasses="pl-4"/>
                                </div>
                              </div>
                              <div className="col-4 pl-0 pr-7">
                                <Field name="section30[]" component={Input} className="form-control" wrapperClasses=""/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row border-top border-bottom border-danger">
                      <div className="col-5 border-right border-danger p-3">
                        <label className="d-flex flex-row align-items-start">
                          <div className="pr-1">31. </div>
                          <div>
                            SIGNATURE OF PHYSICIAN OR SUPPLIER INCLUDING DEGREES OR CREDENTIALS (I certify that the statements on the reverse apply to this bill and are made a part thereof.)
                            <div className="d-flex flex-row justify-content-between mt-3">
                              <div className="d-flex flex-column">
                                <Field name="section31[signature_of_physician]" component={Input} className="form-control" wrapperClasses=""/>
                                <span>SIGNATURE</span>
                              </div>
                              <div className="d-flex flex-column">
                                <Field name="section31[signature_date]" component={Input} className="form-control" wrapperClasses=""/>
                                <span>DATE</span>
                              </div>
                            </div>
                          </div>
                        </label>
                      </div>
                      <div className="col border-right border-danger">
                        <div className="row">
                          <div className="col-12 p-3">
                            <label>32. SERVICE FACILITY LOCATION INFORMATION</label>
                            <Field name="section32[service_facility_location_info1]" component={Input}  className="form-control border-bottom-0" wrapperClasses="pl-6 "/>
                            <Field name="section32[service_facility_location_info2]" component={Input}  className="form-control border-top-0 border-bottom-0" wrapperClasses="pl-6 "/>
                            <Field name="section32[service_facility_location_info3]" component={Input}  className="form-control border-top-0" wrapperClasses="pl-6 "/>
                          </div>
                        </div>
                        <div className="row border-top border-danger">
                          <div className="col-6 p-3 d-flex flex-row align-items-center border-right border-danger">
                            <label>a</label>
                            <Field name="section32[a]" component={Input} className="form-control" wrapperClasses="pl-3 "/>
                          </div>
                          <div className="col-6 p-3 d-flex flex-row align-items-center">
                            <label className="ml-3">b</label>
                            <Field name="section32[b]" component={Input} className="form-control" wrapperClasses="pl-3 "/>
                          </div>
                        </div>

                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col-12 p-3">
                            <div className="d-flex align-items-center pb-1">
                              <label className="text-nowrap">33. BILLING PROVIDER INFO & PH #</label>
                              <div className="d-flex">
                                <Field name="section33[billing_provider_area_code]" component={Input} className="form-control form-control-sm" wrapperClasses="w-25"/>
                                <Field name="section33[billing_provider_telephone]" component={Input} className="form-control form-control-sm" wrapperClasses="w-75"/>
                              </div>
                            </div>
                            <Field name="section33[billing_provider_info1]" component={Input} className="form-control border-bottom-0" wrapperClasses="pl-6 "/>
                            <Field name="section33[billing_provider_info2]" component={Input} className="form-control border-top-0 border-bottom-0" wrapperClasses="pl-6 "/>
                            <Field name="section33[billing_provider_info3]" component={Input} className="form-control border-top-0" wrapperClasses="pl-6 "/>
                          </div>
                        </div>
                        <div className="row border-top border-danger">
                          <div className="col-6 p-3 d-flex flex-row align-items-center border-right border-danger">
                            <label>a</label>
                            <Field name="section33[a]" component={Input} className="form-control" wrapperClasses="pl-3 "/>
                          </div>
                          <div className="col-6 p-3 d-flex flex-row align-items-center">
                            <label className="ml-3">b</label>
                            <Field name="section33[b]" component={Input} className="form-control" wrapperClasses="pl-3 "/>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div className="col-12 px-0">
                    <div className="row">
                      <div className="col-4 text-left">
                      NUCC Instruction Manual available at: www.nucc.org
                      </div>
                      <div className="col-4 text-center font-weight-bold">
                      <i>PLEASE PRINT OR TYPE</i>
                      </div>
                      <div className="col-4 text-right">
                      APPROVED OMB-0938-1197 FORM CMS-1500 (02-12)
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

ClamForm = reduxForm({
  form: '1500ClamForm',
  enableReinitialize: true,
  onSubmitFail: (errors) => ScrollToTarget()
})(ClamForm)

ClamForm = connect(state => {
  const { claimForm: { insurance_claim_form, trizettoSending }, organisation: { organisation }, auth, order: { order }, rpaClaimForm: { rpaClaimForm } } = state
  const claimFormData = order.rpa_claim_exist ? rpaClaimForm : insurance_claim_form
  return {
    userRole:auth.user.role,
    trizettoSending: trizettoSending,
    ediClearingHouseConfig: organisation.add_ons_config?.type.edi_clearing_house,
    initialValues: {
      ...claimFormData
    }
  }
})(ClamForm)

export default withRouter( ClamForm);
