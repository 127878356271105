import React, { Component } from 'react';
import { Input, Textarea } from '../../../form/'
import { FormTemplateHeader, FormTemplateField, FormTemplateSectionWrapper } from '../../../others/'

export default class BinocularForm extends Component {
  render() {
    const { template, isTemplate, pprops=null } = this.props
    const isMarkCompleted = pprops && pprops.initialValues.binocular && pprops.initialValues.binocular.is_marked_complete
    return (
      <div className="col-lg-8 col-md-12 mt-md-7 mt-lg-0">
        <FormTemplateSectionWrapper isTemplate={isTemplate} fieldValues={template.preliminary_binocular_testing.binocular}>
          <div className="card border-0 shadow-sm px-5">
            <FormTemplateHeader
              isTemplate={isTemplate}
              name="binocular[is_marked_complete]"
              isToggle={isMarkCompleted}
              fieldValues={template.preliminary_binocular_testing.binocular}
              baseName="preliminary_binocular_testing[binocular]"
              overviewName="binocular"
              pprops={pprops}
            />
            
            <div className={`card-body ${isMarkCompleted || isTemplate ? '': 'd-none'}`}>
              <div className="row">
                <div className="col-6">
                  <h6> Cover test </h6>
                  <div className="row">
                    <FormTemplateField
                      isTemplate={isTemplate}
                      name="binocular[cover_test][distance]"
                      baseName="preliminary_binocular_testing[binocular][fields][cover_test][fields][distance]"
                      fieldValues={template.preliminary_binocular_testing.binocular.fields.cover_test.fields.distance}
                      isOpen={isMarkCompleted}
                      component={Input}
                      className="form-control"
                      wrapperClasses="col-6 form-group label-icons"
                    />
                    <FormTemplateField
                      isTemplate={isTemplate}
                      name="binocular[cover_test][near]"
                      baseName="preliminary_binocular_testing[binocular][fields][cover_test][fields][near]"
                      fieldValues={template.preliminary_binocular_testing.binocular.fields.cover_test.fields.near}
                      isOpen={isMarkCompleted}
                      component={Input}
                      className="form-control"
                      wrapperClasses="col-6 form-group label-icons"
                    />
                    <FormTemplateField
                      isTemplate={isTemplate}
                      name="binocular[cover_test][nra]"
                      baseName="preliminary_binocular_testing[binocular][fields][cover_test][fields][nra]"
                      fieldValues={template.preliminary_binocular_testing.binocular.fields.cover_test.fields.nra}
                      isOpen={isMarkCompleted}
                      component={Input}
                      className="form-control"
                      wrapperClasses="col-6 form-group label-icons"
                    />
                    <FormTemplateField
                      isTemplate={isTemplate}
                      name="binocular[cover_test][pra]"
                      baseName="preliminary_binocular_testing[binocular][fields][cover_test][fields][pra]"
                      fieldValues={template.preliminary_binocular_testing.binocular.fields.cover_test.fields.pra}
                      isOpen={isMarkCompleted}
                      component={Input}
                      className="form-control"
                      wrapperClasses="col-6 form-group label-icons"
                    />
                    <FormTemplateField
                      isTemplate={isTemplate}
                      name="binocular[cover_test][npc]"
                      baseName="preliminary_binocular_testing[binocular][fields][cover_test][fields][npc]"
                      fieldValues={template.preliminary_binocular_testing.binocular.fields.cover_test.fields.npc}
                      isOpen={isMarkCompleted}
                      component={Input}
                      className="form-control"
                      wrapperClasses="col-6 form-group label-icons"
                    />
                    <FormTemplateField
                      isTemplate={isTemplate}
                      name="binocular[cover_test][fcc]"
                      baseName="preliminary_binocular_testing[binocular][fields][cover_test][fields][fcc]"
                      fieldValues={template.preliminary_binocular_testing.binocular.fields.cover_test.fields.fcc}
                      isOpen={isMarkCompleted}
                      component={Input}
                      className="form-control"
                      wrapperClasses="col-6 form-group label-icons"
                    />
                    <FormTemplateField
                      isTemplate={isTemplate}
                      name="binocular[cover_test][accomodation_facility]"
                      baseName="preliminary_binocular_testing[binocular][fields][cover_test][fields][accomodation_facility]"
                      fieldValues={template.preliminary_binocular_testing.binocular.fields.cover_test.fields.accomodation_facility}
                      isOpen={isMarkCompleted}
                      component={Input}
                      className="form-control"
                      wrapperClasses="col-6 form-group label-icons"
                    />
                    <FormTemplateField
                      isTemplate={isTemplate}
                      name="binocular[cover_test][dem]"
                      baseName="preliminary_binocular_testing[binocular][fields][cover_test][fields][dem]"
                      fieldValues={template.preliminary_binocular_testing.binocular.fields.cover_test.fields.dem}
                      isOpen={isMarkCompleted}
                      component={Input}
                      className="form-control"
                      wrapperClasses="col-6 form-group label-icons"
                    />
                    <FormTemplateField
                      isTemplate={isTemplate}
                      name="binocular[cover_test][amplitude_of_accomodation]"
                      baseName="preliminary_binocular_testing[binocular][fields][cover_test][fields][amplitude_of_accomodation]"
                      fieldValues={template.preliminary_binocular_testing.binocular.fields.cover_test.fields.amplitude_of_accomodation}
                      isOpen={isMarkCompleted}
                      component={Input}
                      className="form-control"
                      wrapperClasses="col-12 form-group label-icons"
                    />
                  </div>
                </div>
                <div className="col-6">
                  <h6> Phorias </h6>
                  <div className="row">
                    <FormTemplateField
                      isTemplate={isTemplate}
                      name="binocular[phorias][horizontal_distance]"
                      baseName="preliminary_binocular_testing[binocular][fields][phorias][fields][horizontal_distance]"
                      fieldValues={template.preliminary_binocular_testing.binocular.fields.phorias.fields.horizontal_distance}
                      isOpen={isMarkCompleted}
                      component={Input}
                      className="form-control"
                      wrapperClasses="col-6 form-group label-icons"
                    />
                    <FormTemplateField
                      isTemplate={isTemplate}
                      name="binocular[phorias][horizontal_near]"
                      baseName="preliminary_binocular_testing[binocular][fields][phorias][fields][horizontal_near]"
                      fieldValues={template.preliminary_binocular_testing.binocular.fields.phorias.fields.horizontal_near}
                      isOpen={isMarkCompleted}
                      component={Input}
                      className="form-control"
                      wrapperClasses="col-6 form-group label-icons"
                    />
                    <FormTemplateField
                      isTemplate={isTemplate}
                      name="binocular[phorias][vertical_distance]"
                      baseName="preliminary_binocular_testing[binocular][fields][phorias][fields][vertical_distance]"
                      fieldValues={template.preliminary_binocular_testing.binocular.fields.phorias.fields.vertical_distance}
                      isOpen={isMarkCompleted}
                      component={Input}
                      className="form-control"
                      wrapperClasses="col-6 form-group label-icons"
                    />
                    <FormTemplateField
                      isTemplate={isTemplate}
                      name="binocular[phorias][vertical_near]"
                      baseName="preliminary_binocular_testing[binocular][fields][phorias][fields][vertical_near]"
                      fieldValues={template.preliminary_binocular_testing.binocular.fields.phorias.fields.vertical_near}
                      isOpen={isMarkCompleted}
                      component={Input}
                      className="form-control"
                      wrapperClasses="col-6 form-group label-icons"
                    />
                    <FormTemplateField
                      isTemplate={isTemplate}
                      name="binocular[phorias][vergences_distance]"
                      baseName="preliminary_binocular_testing[binocular][fields][phorias][fields][vergences_distance]"
                      fieldValues={template.preliminary_binocular_testing.binocular.fields.phorias.fields.vergences_distance}
                      isOpen={isMarkCompleted}
                      component={Input}
                      className="form-control"
                      wrapperClasses="col-6 form-group label-icons"
                    />
                    <FormTemplateField
                      isTemplate={isTemplate}
                      name="binocular[phorias][vergences_near]"
                      baseName="preliminary_binocular_testing[binocular][fields][phorias][fields][vergences_near]"
                      fieldValues={template.preliminary_binocular_testing.binocular.fields.phorias.fields.vergences_near}
                      isOpen={isMarkCompleted}
                      component={Input}
                      className="form-control"
                      wrapperClasses="col-6 form-group label-icons"
                    />

                  </div>
                </div>
              </div>
              <div className="row">
                <FormTemplateField
                  isTemplate={isTemplate}
                  name="binocular[note]"
                  baseName="preliminary_binocular_testing[binocular][fields][notes]"
                  fieldValues={template.preliminary_binocular_testing.binocular.fields.notes}
                  isOpen={isMarkCompleted}
                  component={Textarea}
                  rows={4}
                  className="form-control"
                  wrapperClasses="col-12 form-group label-icons"
                />
              </div>
            </div>
          </div>
        </FormTemplateSectionWrapper>
      </div>
    );
  }
}