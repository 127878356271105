import React, { Component } from 'react';
import { Input, Textarea } from '../../../form/'
import { FormTemplateHeader, FormTemplateField, FormTemplateSectionWrapper } from '../../../others/'

export default class PreliminaryForm extends Component {
  render() {
    const { template, isTemplate, pprops=null } = this.props
    const isMarkCompleted = pprops && pprops.initialValues.priliminaries && pprops.initialValues.priliminaries.is_marked_complete
    return (
      <div className="col-lg-4 col-md-12">
        <FormTemplateSectionWrapper isTemplate={isTemplate} fieldValues={template.preliminary_binocular_testing.priliminaries}>
          <div className="card border-0 shadow-sm px-5">
            <FormTemplateHeader
              isTemplate={isTemplate}
              name="priliminaries[is_marked_complete]"
              isToggle={isMarkCompleted}
              fieldValues={template.preliminary_binocular_testing.priliminaries}
              baseName="preliminary_binocular_testing[priliminaries]"
              overviewName="preliminary"
              pprops= {pprops}
            />
            
            <div className={`card-body ${isMarkCompleted || isTemplate ? '': 'd-none'}`}>
              <div className="row">
                <FormTemplateField
                  isTemplate={isTemplate}
                  name="priliminaries[eoms]"
                  baseName="preliminary_binocular_testing[priliminaries][fields][eoms]"
                  fieldValues={template.preliminary_binocular_testing.priliminaries.fields.eoms}
                  isOpen={isMarkCompleted}
                  component={Input}
                  className="form-control"
                  wrapperClasses="col-6 form-group label-icons"
                />

                <FormTemplateField
                  isTemplate={isTemplate}
                  name="priliminaries[pupils]"
                  baseName="preliminary_binocular_testing[priliminaries][fields][pupils]"
                  fieldValues={template.preliminary_binocular_testing.priliminaries.fields.pupils}
                  isOpen={isMarkCompleted}
                  component={Input}
                  className="form-control"
                  wrapperClasses="col-6 form-group label-icons"
                />

                <FormTemplateField
                  isTemplate={isTemplate}
                  name="priliminaries[confrontational_field]"
                  baseName="preliminary_binocular_testing[priliminaries][fields][confrontational_field]"
                  fieldValues={template.preliminary_binocular_testing.priliminaries.fields.confrontational_field}
                  isOpen={isMarkCompleted}
                  component={Input}
                  className="form-control"
                  wrapperClasses="col-6 form-group label-icons"
                />

                <FormTemplateField
                  isTemplate={isTemplate}
                  name="priliminaries[color_vision]"
                  baseName="preliminary_binocular_testing[priliminaries][fields][color_vision]"
                  fieldValues={template.preliminary_binocular_testing.priliminaries.fields.color_vision}
                  isOpen={isMarkCompleted}
                  component={Input}
                  className="form-control"
                  wrapperClasses="col-6 form-group label-icons"
                />

                <FormTemplateField
                  isTemplate={isTemplate}
                  name="priliminaries][stereopsis]"
                  baseName="preliminary_binocular_testing[priliminaries][fields][stereopsis]"
                  fieldValues={template.preliminary_binocular_testing.priliminaries.fields.stereopsis}
                  isOpen={isMarkCompleted}
                  component={Input}
                  className="form-control"
                  wrapperClasses="col-6 form-group label-icons"
                />

                <FormTemplateField
                  isTemplate={isTemplate}
                  name="priliminaries[fusion]"
                  baseName="preliminary_binocular_testing[priliminaries][fields][fusion]"
                  fieldValues={template.preliminary_binocular_testing.priliminaries.fields.fusion}
                  isOpen={isMarkCompleted}
                  component={Input}
                  className="form-control"
                  wrapperClasses="col-6 form-group label-icons"
                />
                <FormTemplateField
                  isTemplate={isTemplate}
                  name="priliminaries[muscle_balance]"
                  baseName="preliminary_binocular_testing[priliminaries][fields][muscle_balance]"
                  fieldValues={template.preliminary_binocular_testing.priliminaries.fields.muscle_balance}
                  isOpen={isMarkCompleted}
                  component={Input}
                  className="form-control"
                  wrapperClasses="col-6 form-group label-icons"
                />
                <FormTemplateField
                  isTemplate={isTemplate}
                  name="priliminaries[notes]"
                  baseName="preliminary_binocular_testing[priliminaries][fields][notes]"
                  fieldValues={template.preliminary_binocular_testing.priliminaries.fields.notes}
                  isOpen={isMarkCompleted}
                  component={Textarea}
                  rows={4}
                  className="form-control"
                  wrapperClasses="col-12 form-group label-icons"
                />
              </div>
            </div>
          </div>
        </FormTemplateSectionWrapper>
      </div>
    );
  }
}