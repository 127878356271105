import React from 'react'
import { Link } from 'react-router-dom';
import { dateFormat, humanize, numberToCurrency } from '../../../../../../itrust_common/components/HelperFunctions';
import { layoutChange } from '../../../../../../itrust_common/redux/actions/otherActions';

export default function PatientOverviews(props) {
  const { contact, layout, dispatch } = props

  const getInsuranceType = (insurance) => {
    switch (insurance.insurance_type) {
      case 'PatientMedicalInsurance':
        return <span>Medical: {`${insurance.insurance_name}`}<br /></span>
      case 'PatientVisionInsurance':
        return <span>Vision: {`${insurance.insurance_name}`}<br /></span>
      default: return ''
    }
  }

  const handlePatientRedirect = () => {
    if (layout !== "default") {
      dispatch(layoutChange('default'))
    }
  }

  return (
    <>
      <div className='font-size-14 py-4 mx-3'>
        <div className='d-flex justify-content-between my-4'>
          <span className='text-primary'>
            <i className="las la-suitcase"></i>
            <span className='m-1'>Occupation</span>
          </span>
          <span>{contact.occupation}</span>
        </div>
        <div className='d-flex justify-content-between mb-4 align-items-start'>
          <span className='text-primary'>
            <i className="las la-stethoscope"></i>
            <span className='m-1'>Insurance</span>
          </span>
          <span className='text-right'>
            {contact.patient_insurances_attributes?.length > 0 ?
              <>{contact.patient_insurances_attributes.map(insurance => <span key={insurance.id}>{getInsuranceType(insurance)}</span>)}</> : ''
            }
          </span>
        </div>
        <div className='d-flex justify-content-between mb-4'>
          <span className='text-primary'>
            <i className="las la-calendar"></i>
            <span className='m-1'>Recall Date</span>
          </span>
          <span>{contact.recall_reminder_date ? `${dateFormat(contact.recall_reminder_date)}` : ''}</span>
        </div>
        <div className='d-flex justify-content-between mb-4'>
          <span className='text-primary'>
            <i className="las la-money-bill"></i>
            <span className='m-1'>Balance Due</span>
          </span>
          <span className='text-left'>{numberToCurrency(contact.balance_due)}</span>
        </div>
        <div className='d-flex justify-content-between mb-4'>
          <span className='text-primary'>
            <i className="las la-calendar"></i>
            <span className='m-1'>Appointments</span>
          </span>
          <span>
            <span className='text-secondary font-size-12 mr-2'>{contact.appointment_details?.last_appointment_date && `Last: ${dateFormat(contact.appointment_details?.last_appointment_date)}`}</span>
            <span>{contact.appointment_details.count}</span>
          </span>
        </div>
        <div className='d-flex justify-content-between mb-4'>
          <span className='text-primary'>
            <i className="las la-file-alt"></i>
            <span className='m-1'>Exams</span>
          </span>
          <span>
            <span className='text-secondary font-size-12 mr-2'>{contact.exam_details?.last_exam_date && `Last: ${dateFormat(contact.exam_details?.last_exam_date)}`}</span>
            <span>{contact.exam_details.count}</span>
          </span>
        </div>
        <div className='d-flex justify-content-between mb-4'>
          <span className='text-primary'>
            <i className="las la-cube"></i>
            <span className='m-1'>Orders</span>
          </span>
          <span>
            <span className='text-secondary font-size-12 mr-2'>{contact.order_details?.last_order_date && `Last: ${dateFormat(contact.order_details?.last_order_date)}`}</span>
            <span>{contact.order_details.count}</span>
          </span>
        </div>
        <div className='d-flex justify-content-between mb-4'>
          <span className='text-primary'>
            <i className="las la-users"></i>
            <span className='m-1'>Family Members</span>
          </span>
          <span>

            {contact.family_members_attributes?.length > 0 ?
              <Link exact to={`/patients/${contact.id}/family-member`} className="text-primary" onClick={handlePatientRedirect}>
                <span>{contact.family_members_attributes[0].name}</span>
                {contact.family_members_attributes?.length > 1 &&
                  <span className='text-primary font-size-12 ml-2'>+{contact.family_members_attributes.length - 1}</span>
                }
              </Link> : ''
            }
          </span>
        </div>
        <div className='d-flex justify-content-between mb-4'>
          <span className='text-primary'>
            <i className="las la-truck"></i>
            <span className='m-1'>Order Status</span>
          </span>
          <span>
            <span></span>
            <span className='text-secondary font-size-12 ml-2'>{humanize(contact.order_details?.last_order_status)}</span>
          </span>
        </div>
      </div>
    </>
  )
}