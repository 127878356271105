import React, { Component } from 'react';
import { VISUAL_ACUITY } from '../../../Constants'

class SetDistanceNear extends Component {

  render() {
    const { visual, setVisualAcuity, wrapperClass, isDropLeft } = this.props
    return (
      <div className={`col-6 mb-5 text-right ${wrapperClass}`}>
        <div className={`${isDropLeft && 'btn-group'}`}>
          <div className={`btn-group ${isDropLeft ? 'dropleft' : 'dropright mr-4 mb-4'}`}>
            {!isDropLeft && <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => setVisualAcuity(visual, '20')}>20/20</button>}
            <button type="button" className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split btn-sm dropdownDistanceNear" data-toggle="dropdown" aria-expanded="false">
              <span className="sr-only">Toggle Dropdown</span>
            </button>
            <div className={`dropdown-menu dropdown-menu-distance min-width-8 ${isDropLeft && 'left-dropdown'}`}>
              <div className="d-flex justify-content-center flex-column align-items-center pt-5">
                {
                  VISUAL_ACUITY.map(item => <button type="button" className="btn btn-outline-primary btn-sm mb-4" onClick={() => setVisualAcuity(visual, item)}>{`20/${item}`}</button>)
                }
              </div>
            </div>
          </div>
          {isDropLeft &&
            <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => setVisualAcuity(visual, '20')}>20/20</button>}
        </div>
      </div>
    );
  }
}

export default SetDistanceNear;

