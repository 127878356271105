import React, { Component } from 'react';
import { Field } from 'redux-form';

let valueChange = (value, input, onChangeHook) => {
  if(value === "true" || value === "false"){
    input.onChange(JSON.parse(value))
    if(onChangeHook)
      onChangeHook(JSON.parse(value))
  }
  else{
    input.onChange(value)
    if(onChangeHook)
      onChangeHook(value)
  }

}

let Radio= props => {
  const { input, onChangeHook, radioLabelClasses, containIcons, meta: { touched, error }}= props;
  var fieldValue = (input.value === 'true' || input.value === 'false' || input.value===true || input.value===false ) ?  JSON.parse(input.value) : input.value
  return (
    <label className={`btn btn-outline-primary ${input.checked  && 'active'} ${radioLabelClasses}`}>
      <input {...input} value={fieldValue} type="radio" onChange={(e)=> valueChange(e.target.value, input, onChangeHook)} onBlur={(e)=> valueChange(e.target.value, input)}/>
      {containIcons ?
        <span className="d-flex align-items-center justify-content-center" dangerouslySetInnerHTML={{ __html: props.label }} /> :
        <span className={`${touched && error && 'required-radio-btn'}`}>{props.label}</span>
      }
    </label>
  )
}

export default class RadioGroup extends Component {
  render() {
    const { props, 
      props: { options, disabled, wrapperClasses, label, containIcons, btnWrapperClasses, isButtonsNormal }
        } = this;
    return (
      <div className={`${disabled && 'disabled'} ${wrapperClasses}`}>
        {label !== undefined && <label className="mb-2 d-block">{label}</label>}
        <div className={`btn-group btn-group-toggle ${isButtonsNormal ? 'btn-group' : 'btn-group-sm' } ${btnWrapperClasses}`} >
          {options.map((option)=> 
            <Field {...props} type="radio" component={Radio} containIcons={containIcons} value={option.value} label={option.label}/>
          )}
        </div>
      </div>
    );
  }
}
