import React, { useEffect } from 'react';
import ActionCable from 'actioncable';
import { connect, useDispatch } from 'react-redux';
import { WS_ROOT } from '../../../env';
import { notificationCreate } from '../../../itrust_common/redux/actions/notificationActions';
import { whatsAppNotificationCreate } from '../../../redux/actions/addOns/whatsApp/whatsAppActions';
import { VoipNotificationCreate, callPopUpEdit, callingInformationUpdate } from '../../../redux/actions/addOns/voip/voipActions';
import { twoWayNotificationCreate } from '../../../redux/actions/addOns/twoWayMessaging/twoWayReportAction';

function NotificationCable(props) {
  const dispatch = useDispatch()

  useEffect(() => {
    let accessToken = localStorage.getItem('Access-Token');
    (accessToken && accessToken !== 'null' && accessToken.length > 15) && handleActionCableCreate()
  }, [])

  const handlePopUpClose = () => {
    dispatch(callingInformationUpdate({ callStatus: 'call_ended', isCallActive: false }))
    dispatch(callPopUpEdit(false))
  }

  const handleActionCableCreate = () => {
    setTimeout(function () {
      var cable = ActionCable.createConsumer(getWebSocketURL());
      cable.subscriptions.create(
        {
          channel: 'NotificationsChannel',
        },
        {
          connected: function () {
            console.log('Notification Socket Connected');
          },
          disconnected: function () {
            console.log('Voip Socket Disconnected');
          },
          received: function (data) {
            dispatch(notificationCreate(data));
            data = JSON.parse(data.data)
            if (data.notification.activity_attributes.activity_type === "patient_sms_received") {
              dispatch(twoWayNotificationCreate(data))
            }
            if (['missed_call', 'voicemail_received'].includes(data.notification.activity_attributes)) {
              handlePopUpClose(data)
              dispatch(VoipNotificationCreate(data))
            }
            if (data.notification.activity_attributes.activity_type === "whatsapp") {
              dispatch(whatsAppNotificationCreate(data));
            }
            console.log('notification received');
            console.log('notification received');
          },
          speak: function (message) {
          }
        }
      );
    }, 4000)
  }

  const getWebSocketURL = () => {
    const token = localStorage.getItem('Access-Token')
    return `${WS_ROOT}/cable?token=${token}&org=org&ch=app`
  }

  return (
    <></>
  )
}

export default connect()(NotificationCable)