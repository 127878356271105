import React, { Component } from 'react';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import * as $ from 'jquery';

import { examUpdate, examSecureFax, emailPrescriptionReportSend, examEditCancel, examPdfLink } from '../../../redux/actions/exam/examActions';
import ExamForm from '../exam/ExamForm';
import { openInNewTab } from '../../../itrust_common/components/HelperFunctions';

class EditExamModal extends Component {

  handleExamSubmit(data) {
    this.props.dispatch(examUpdate(data, {include: '*'}))
  }

  handleRemoteSubmit(form) {
    this.props.dispatch(submit(form))
  }

  handleExamSecureFax(data) {
    this.props.dispatch(examSecureFax(this.props.exam.id, data))
  }

  handleEmailPrescriptionReportSend() {
    this.handleSaveBeforePrint()
    this.props.dispatch(emailPrescriptionReportSend(this.props.exam.id))
  }

  handleExamEditCancel(){
    this.props.dispatch(examEditCancel())
  }

  async handleExamPdfPrint(){
    const { exam } = this.props
    this.handleSaveBeforePrint()
    const url = `exams/${exam.id}/overview`
    var printLink = await this.props.dispatch(examPdfLink(exam.patient_id, { exam_print_url: url}))
    openInNewTab(printLink.value.data.filepath)
  }

  handleSaveBeforePrint(){
    const {activeTab } = this.props
    switch(activeTab){
      case 2: this.handleRemoteSubmit('examHpiForm'); break
      case 6: this.handleRemoteSubmit('examPreliminaryBinocularForm'); break
      case 7: this.handleRemoteSubmit('examRefractionContactLensForm'); break
      case 8: this.handleRemoteSubmit('examExternalInternalForm'); break
      case 9: this.handleRemoteSubmit('examAdditionalTestForm'); break
      case 11: this.handleRemoteSubmit('examFinalizePrescriptionForm'); break
      default: 
    }
  }

  componentDidUpdate() {
    if(!this.props.examEditing){
      $('#editExamForm-modal').modal('hide');  
    }
  }

  render() {
    const { exam } = this.props;
    return (
      <div className="exam-setting-modal exam-modal">
        <div className="modal fade" id="editExamForm-modal" tabIndex="-1" role="dialog" aria-labelledby="modalTitle" aria-hidden="true">
          <div className="modal-dialog m-0" role="document">
            <div className="modal-content bg-light">
              {exam.id &&
                <ExamForm 
                  onSubmit={this.handleExamSubmit.bind(this)}
                  onSecurFax={this.handleExamSecureFax.bind(this)}
                  emailPrescriptionReportSend={this.handleEmailPrescriptionReportSend.bind(this)}
                  handleExamEditCancel={this.handleExamEditCancel.bind(this)}
                  handleExamPdfPrint={this.handleExamPdfPrint.bind(this)}
                  />
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { exam, other } = state;
  return {
    exam: exam.exam,
    examEditing: exam.editing,
    activeTab: other.activeTab.exam
  };
}

export default connect(mapStateToProps)(EditExamModal);
