import React, { Component } from 'react';

export default class Textarea extends Component {
  render() {
    const {
      props,
      props: { input, disabled, placeholder, wrapperClasses, label, readOnly },
      props: { meta: { touched, error, warning }, rows, cols }
    } = this;
    return (
      <div className={`${disabled && 'disabled'} ${wrapperClasses}`}>
        {label !== undefined && <label>{props.label}</label>}
        <textarea rows={rows} cols={cols} {...input} className={`${props.className} ${touched && error && 'is-invalid'}`} disabled={props.disabled || false} placeholder={placeholder} readOnly={readOnly} />
        {touched &&
          ((error && <span className="invalid-feedback text-danger d-inline">{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </div>
    );
  }
}
