import {handleAPI, API_VERSION_ONE} from '../../../utils/apiUtils';
const parent = 'SETTING_'

export function patientReportsLoad(filters){
  const url = `/${API_VERSION_ONE}/settings/patient_reports`;
  const method = 'GET'
  return {
    type: `${parent}PATIENT_REPORTS_LOAD`,
    payload: handleAPI(url, filters, method)
  };
}

export function patientReportLoad(id, filters){
  const url = `/${API_VERSION_ONE}/settings/patient_reports/${id}`;
  const method = 'GET'

  return {
    type: `${parent}PATIENT_REPORT_LOAD`,
    payload: handleAPI(url,filters,method)
  };
}

export function patientReportCreate(data, filters){
  const url = `/${API_VERSION_ONE}/settings/patient_reports`;
  const method= 'POST'
  return {
    type: `${parent}PATIENT_REPORT_CREATE`,
    payload: handleAPI(url,filters,method, {patient_report: {...data}})
  }
}

export function patientReportUpdate(data, filters){
  const url = `/${API_VERSION_ONE}/settings/patient_reports/${data.id}`;
  const method = "PUT"
  return {
    type: `${parent}PATIENT_REPORT_UPDATE`,
    payload: handleAPI(url,filters,method, {patient_report: {...data}})
  }
}

export function patientReportDelete(id, filters) {
  const url = `/${API_VERSION_ONE}/settings/patient_reports/${id}`;
  const method = 'DELETE';

  return {
    type: `${parent}PATIENT_REPORT_DELETE`,
    payload: new Promise((resolve, reject) => {
      handleAPI(url, filters, method)
        .then(response => {
          resolve({ id: id, ...response });
        })
        .catch(err => {
          reject(err);
        });
    })
  };
}

export function patientReportEdit(id) {
  return {
    type: `${parent}PATIENT_REPORT_EDIT`,
    id,
  };
}

export function patientReportEditCancel() {
  return {
    type: `${parent}PATIENT_REPORT_EDIT_CANCEL`
  };
}
