export const appointmentSteps = [
  {
    target: '.appointment-step-1',
    content: 'Click on a Time Slot',
    title: 'Create Appointment',
    placement: 'left',
    disableBeacon: true,
    spotlightClicks: true,
    spotlightPadding: 0
  },
  {
    // belongs to open modal step > its being hard to show Tour Tooltip over modal overlay
    target: '.appointment-step-2',
  },
  {
    // belongs to open modal step > its being hard to show Tour Tooltip over modal overlay
    target: '.appointment-step-3',
  },
  {
    target: '.appointment-step-4',
    content: 'Success! The newly created appointment is shown on the dashboard',
    title: 'Create Appointment',
    disableBeacon: true,
    spotlightClicks: false,
    spotlightPadding: 0
  },
]

export const PatientSteps = [
  {
    target: '.patient-step-1',
    content: 'Click Patient',
    title: 'Add Patient',
    placement: 'right',
    disableBeacon: true,
    spotlightClicks: true,
    spotlightPadding: 0
  },
  {
    target: '.patient-step-2',
    content: 'Click Add New',
    title: 'Add Patient',
    placement: 'left',
    disableBeacon: true,
    spotlightClicks: true,
    spotlightPadding: 4
  },
  {
    // belongs to open modal step > its being hard to show Tour Tooltip over modal overlay
    target: '.patient-step-3',
  },
]

export const ExamSteps = [
  {
    target: '.exam-step-1',
    content: 'Under the three dots Actions column click Create Exam',
    title: 'Create Exam',
    placement: 'left',
    disableBeacon: true,
    spotlightClicks: true,
    spotlightPadding: 0
  },
  {
    // belongs to open modal step > its being hard to show Tour Tooltip over modal overlay
    target: '.exam-step-2',
  }
]

export const OrderSteps = [
  {
    target: '.order-step-1',
    content: 'Under the three dots Actions column click Create Order',
    title: 'Create Order',
    placement: 'left',
    disableBeacon: true,
    spotlightClicks: true,
    spotlightPadding: 0
  }
]

export const ActiveAccountSteps = [
  {
    target: '.activate-account-step-1',
    content: 'Click on the Your Billing to go on the activate button.',
    title: 'Activate Account',
    placement: 'right',
    disableBeacon: true,
    spotlightClicks: true,
    spotlightPadding: 0
  },
  {
    target: '.activate-account-step-2',
    content: 'Here you will see the activate button to activate account',
    title: 'Activate Account',
    placement: 'left',
    disableBeacon: true,
    spotlightClicks: true,
    spotlightPadding: 0
  },
]

export const DefaultOnboardingGuide = { login: true, create_appointment: false, create_patient: false, create_exam: false, create_order: false, activate_account: false }
