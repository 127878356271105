import React from 'react';

//Tests import
import Preliminary from './Preliminary'
import Binocular from './Binocular'

let PreliminaryBinocular = props => {
  const { fieldsValues: {priliminaries, binocular}, template} = props;
  return (
    <>
      { template.visible === 'true' &&
        <div className="row">
          { priliminaries && priliminaries.is_marked_complete &&
            [<div className="col-12">
              <div className="border-bottom">
                <h6 className="pb-3 pt-6">{template.priliminaries.custom_label}</h6>
              </div>
            </div>,
            <div className="col-12">
              <Preliminary fieldsValues={priliminaries} template={template.priliminaries}/>
            </div>]
          }
          { binocular && binocular.is_marked_complete &&
            [<div className="col-12">
              <div className="border-bottom">
                <h6 className="pb-3 pt-6">{template.binocular.custom_label}</h6>
              </div>
            </div>,
            <div className="col-12">
              <Binocular fieldsValues={binocular} template={template.binocular}/>
            </div>]
          }
        </div>
      }
    </>
  )
}


export default PreliminaryBinocular;
