import Toastr from "../../../itrust_common/components/Toastr"
const DEFAULT_STATE = {
  trial_orders: [],
  meta: { pagination: {} },
  saving: false
}
let changes = null;
export default function trialOrderReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let trialOrders = [];
  let updatedRecord = {};
  switch(action.type){
    case 'TRIAL_ORDERS_LOAD_PENDING':
      return {...state, saving: true};

    case 'TRIAL_ORDER_UPDATE_PENDING':
      return {...state, saving: true};

    case 'TRIAL_ORDERS_LOAD_REJECTED':
    case 'TRIAL_ORDER_UPDATE_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case 'TRIAL_ORDERS_LOAD_FULFILLED':
      changes = {
        ...response,
        saving: false
      }
      return {...state,...changes};
    
    case 'TRIAL_ORDER_UPDATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      updatedRecord = response.contact_lens_order;
      changes = {
        trial_order: updatedRecord,
        saving: false,
        editing: false
      };
      return { ...state, ...changes };

    case 'TRIAL_ORDER_DELETE_FULFILLED':
      trialOrders = [...state.trial_orders];
      for (const [index, trialOrder] of trialOrders.entries()) {
        if (trialOrder.id === response.trial_order.id) {
          trialOrders.splice(index, 1);
        }
      }
      return {
        ...state,
        trial_orders: trialOrders,
        error: null
      };

    default:
      return state;
  }
}