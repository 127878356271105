import Toastr from "../../../itrust_common/components/Toastr"
const DEFAULT_STATE = {
  family_members: [],
  family_member: {},
  meta: { pagination: {} },
  saving: false
}
let changes = null;
export default function familyMemberReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let familyMembers = [];
  let familyMemberData = [];
  let updatedRecord = {};
  switch(action.type){
    case 'FAMILY_MEMBERS_LOAD_PENDING':
    case 'FAMILY_MEMBER_LOAD_PENDING':
      return {...state, saving: true};

    case 'FAMILY_MEMBER_CREATE_PENDING':
      return {...state, saving: true};

    case 'FAMILY_MEMBERS_LOAD_REJECTED':
    case 'FAMILY_MEMBER_LOAD_REJECTED':
    case 'FAMILY_MEMBER_CREATE_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case 'FAMILY_MEMBERS_LOAD_FULFILLED':
      changes = {
        ...response,
        saving: false
      };
      return {...state, ...changes};
    
    case 'FAMILY_MEMBER_LOAD_FULFILLED':
      changes = {
        family_member: response.patient,
        saving: false
      }
      return {...state,...changes};
    
    case 'FAMILY_MEMBER_CREATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      familyMembers = [...state.family_members]
      let newRecord = response.family_member
      changes = {
        family_members: [...familyMembers, newRecord],
        family_member: newRecord,
        editing: false,
        saving: false
      }
      return {...state, ...changes};

    case 'FAMILY_MEMBER_UPDATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      familyMembers = [...state.family_members];
      updatedRecord = response.family_member;
      for (const [index, familyMember] of familyMembers.entries()) {
        if (familyMember.id === updatedRecord.id) {
          familyMemberData = [
            ...familyMembers.slice(0, index),
            updatedRecord,
            ...familyMembers.slice(index + 1)
          ];
        }
      }
      changes = {
        family_members: familyMemberData,
        family_member: updatedRecord,
        editing: false,
        saving: false
      };
      return { ...state, ...changes };

    case 'FAMILY_MEMBER_DELETE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      familyMembers = [...state.family_members];
      for (const [index, family_member] of familyMembers.entries()) {
        if (family_member.id === response.family_member.id) {
          familyMembers.splice(index, 1);
        }
      }
      return {
        ...state,
        family_members: familyMembers,
        error: null
      };

    case 'FAMILY_MEMBER_EDIT':
      changes = {
        editing: {
          id: action.id,
        },
        family_member: action.familyMemberData || DEFAULT_STATE['family_member']
      };
      return { ...state, ...changes };

    case 'FAMILY_MEMBER_EDIT_CANCEL':
      changes = {
        editing: false
      };
      return { ...state, ...changes };

    default:
      return state;
  }
}