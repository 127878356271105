import React, { Component } from 'react';
import * as $ from 'jquery';
import { orderPaymentEdit } from '../../../../redux/actions/order/orderPaymentActions';
import Alert from '../../../../itrust_common/components/Alert';
class FinalizeButton extends Component {

  handlePaymentEdit() {
    const { onFinalize, orderStaffId, dispatch } = this.props;
    if (orderStaffId) {
      onFinalize()
      dispatch(orderPaymentEdit('New'))
      $("#orderFinalizeForm-modal").modal()
    }
    else {
      Alert.warning("Please select provider to finalize the order.")
    }
  }

  render() {
    return (
      <span className="pointer" onClick={this.handlePaymentEdit.bind(this)} >
        {this.props.children}
      </span>
    );
  }
}

export default FinalizeButton;