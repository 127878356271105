import React, { Component } from 'react'
import { connect } from 'react-redux'
import { submit, isDirty } from 'redux-form'
import {SaveButton} from '../others/'; 

class ModalFormWrapper extends Component {
  render() {
    const { dispatch, children, formName, handleEditCancel, formTitle, saving, modalSize, modalContentClasses, label, allState, button2Submit } = this.props;
    return(
      <div className="modal fade popper overflow-auto" id={`${formName}-modal`} tabIndex="-1" role="dialog" aria-hidden="true"  data-backdrop="static">
        <div className={`modal-dialog ${modalSize ? modalSize: "modal-lg"} `} role="document">
          <div className={`modal-content ${modalContentClasses} `}>
            <div className="modal-header align-items-center border-0">
              <h5 className="text-dark" >{formTitle}</h5>
              <div className="d-flex flex-row">
                <button type="button" className="btn btn-outline-primary mr-7" onClick={() => handleEditCancel(isDirty(formName)(allState))}>Cancel</button>
                {button2Submit && <button type='button' className='btn btn-primary mx-5' onClick={()=> button2Submit()}>Send</button>}
                <SaveButton label={label} saving={saving} handleOnClick={() => dispatch(submit(formName))}/>
              </div>
            </div>
            {children}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    allState: state
  }
}

export default connect(mapStateToProps)(ModalFormWrapper)