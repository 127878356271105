import procedurePricingReducer from './ProcedurePricingReducer';
import messageTrackingReducer from './MessageTrackingReducer';
import storeSettingReducer from './storeSettingReducer';
import storeContactReducer from './StoreContactReducer'

const storeSettingsReducers ={
  procedurePricing: procedurePricingReducer,
  messageTracking: messageTrackingReducer,
  storeSetting: storeSettingReducer,
  storeContactSetting: storeContactReducer  
};

export default storeSettingsReducers;
