import _ from 'lodash'
import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { change } from 'redux-form'

function CopyToDestination(props) {
  const dispatch = useDispatch()
  const { examExternalInternalForm, examAdditionalTestForm, source, showCopy: { destination, title, formName } } = props

  const copyTo = (formName) => {
    formName = formName ? formName : 'examExternalInternalForm'
    const fromData = formName == 'examAdditionalTestForm' ? examAdditionalTestForm : examExternalInternalForm
    const sourceNode = _.get(fromData, source)
    dispatch(change(`${formName}`, `${destination}`, sourceNode))
  }

  return (
    <small className="float-right btn-link" onClick={() => copyTo(formName)}><i className="la la-copy" />{title}</small>
  )
}

const mapStateToProps = state => {
  const { form: { examExternalInternalForm, examAdditionalTestForm } } = state
  return {
    examExternalInternalForm: examExternalInternalForm?.values,
    examAdditionalTestForm: examAdditionalTestForm?.values
  };
};

export default connect(mapStateToProps)(CopyToDestination);