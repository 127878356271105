import React, { Component } from 'react';
import { Field } from 'redux-form';

let Checkbox= props => {
  const { input, className, onClick }= props;
  return (
    <label onClick={()=> onClick && onClick(input.value)} className={`btn btn-outline-primary ${input.value && 'active'} ${className}`}>
      <input {...input} type="checkbox" autocomplete="off" /> 
      {input.value && 'active' && <i className="la la-sm la-check-circle"/>} 
      {props.label}
    </label>
  )
}

export default class CheckboxGroup extends Component {
  render() {
    const { props, 
            props: {options, disabled, wrapperClasses, label, name}
        } = this;
    return (
      <div className={`${disabled && 'disabled'} ${wrapperClasses}`}>
        {label !== undefined && <label className="mb-2 d-block">{label}</label>}
        <div className="btn-group-toggle">
          {options.map((option)=> 
            <Field {...props} name={`${name}['${option.value}']`} component={Checkbox} value={option.value} label={option.label}/>
          )}
        </div>
      </div>
    );
  }
}

