import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as $ from 'jquery';
import { currentStoreLoad, storeStaffsLoad } from '../../../redux/actions/currentStoreActions';
import { procedurePricingsLoad } from '../../../redux/actions/storeSettings/ProcedurePricingActions';

import { examEdit, examLoad } from '../../../redux/actions/exam/examActions';
import { patientMedicalHistoriesLoad } from '../../../redux/actions/patient/patientMedicalHistoryActions';
import { patientReportsLoad } from '../../../itrust_common/redux/actions/settingActions/patientReportActions';
import { handleStartExamRecording } from '../exam/CommonFunctions';

class EditExamButton extends Component {

  loadStoreDependencies(storeId){
    var localStorageStoreId = localStorage.getItem('StoreID')
    if(!localStorageStoreId){
      this.props.dispatch(currentStoreLoad(storeId))
      this.props.dispatch(storeStaffsLoad(storeId,{ rpp: 1000, filter: { doctor: storeId }}))
      this.props.dispatch(procedurePricingsLoad({rpp: 99999, filter: {for_store: storeId}, include:['procedures']}))
    }
  }

  handleExamEdit(storeId, id){
    this.loadStoreDependencies(storeId)

    this.props.dispatch(examEdit(id));
    new Promise((resolve, reject)=>{
      this.props.dispatch(examLoad(id, {include: '*'}))
      .then((response) => {
        var res = response.value.data.exam
        res.is_telemed && handleStartExamRecording()
        //Medical history for overview
        this.props.dispatch(patientMedicalHistoriesLoad(res.patient_id, {rpp: 500, exam_id: res.id}));
        //Prescription report template
        this.props.dispatch(patientReportsLoad({patient_id: res.patient_id, staff_id: res.staff_id, store_id: res.store_id, filter: {category: ["medical_rx", "referral"]}}))
      })
    })
    setTimeout(function(){
      $('#orderForm-modal').modal('hide');
      $('#editExamForm-modal').modal('show');
    }, 1000)
  }

  render() {
    const { examId, storeId } = this.props;
    return (
      <span>
        <span
          className="pointer"
          onClick={this.handleExamEdit.bind(this, storeId, examId)}
        >
          {this.props.children}
        </span>
      </span>
    );
  }
}

export default connect()(EditExamButton);
