import React from 'react';
import DisplayField from '../../../../../itrust_common/components/others/DisplayField'

let GlassView = props => {
  const { fieldsValues, template} = props;
  return (
    <div className="row">
      <div className="col-12">
        <div className="overview-table mb-3">
          <table className="table table-bordered bg-light table-hover shadow-none border border-muted">
            <thead className="text-dark border border-muted">
              <tr className="text-center">
                <th scope="col"></th>
                <th scope="col">
                  <DisplayField
                    isVisible={template.fields.od_sphere.visible}
                    label={template.fields.od_sphere.custom_label}
                  />
                </th>
                <th scope="col">
                  <DisplayField
                    isVisible={template.fields.od_cylinder.visible}
                    label={template.fields.od_cylinder.custom_label}
                  />
                </th>
                <th scope="col">
                  <DisplayField
                    isVisible={template.fields.od_axis.visible}
                    label={template.fields.od_axis.custom_label}
                  />
                </th>
                <th scope="col">
                  <DisplayField
                    isVisible={template.fields.od_add.visible}
                    label={template.fields.od_add.custom_label}
                  />
                </th>
                <th scope="col">
                  <DisplayField
                    isVisible={template.fields.od_prism.visible}
                    label={template.fields.od_prism.custom_label}
                  />
                </th>
                <th scope="col">
                  <DisplayField
                    isVisible={true}
                    label={"Distance"}
                  />
                </th>
                <th scope="col">
                  <DisplayField
                    isVisible={true}
                    label={"Near"}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
                {["od", "os", "ou"].map(type =>
                  <tr className="text-center">
                    <td>
                      <h6>{type.toUpperCase()}</h6>
                    </td>
                    <td>
                      <DisplayField
                        isVisible={true}
                        // label={template.fields.distance_od.custom_label}
                        value={fieldsValues && fieldsValues[`${type}_sphere`]}
                      />
                    </td>
                    <td>
                      <DisplayField
                        isVisible={true}
                        // label={template.fields.near_od.custom_label}
                        value={fieldsValues && fieldsValues[`${type}_cylinder`]}
                      />
                    </td>
                    <td>
                      <DisplayField
                        isVisible={true}
                        // label={template.fields.near_od.custom_label}
                        value={fieldsValues && fieldsValues[`${type}_axis`]}
                      />
                    </td>
                    <td>
                      <DisplayField
                        isVisible={true}
                        // label={template.fields.near_od.custom_label}
                        value={fieldsValues && fieldsValues[`${type}_add`]}
                      />
                    </td>
                    <td>
                      <DisplayField
                        isVisible={true}
                        // label={template.fields.near_od.custom_label}
                        value={fieldsValues && fieldsValues[`${type}_prism`]}
                      />
                    </td>
                    <td>
                      <DisplayField
                        isVisible={true}
                        // label={template.fields.near_od.custom_label}
                        value={fieldsValues && fieldsValues.visual_acuity && fieldsValues.visual_acuity[`${type}_distance`]}
                      />
                    </td>
                    <td>
                      <DisplayField
                        isVisible={true}
                        // label={template.fields.near_od.custom_label}
                        value={fieldsValues && fieldsValues.visual_acuity && fieldsValues.visual_acuity[`${type}_near`]}
                      />
                    </td>
                  </tr>
                  )
              }
            </tbody>
          </table>
        </div>
        { fieldsValues && fieldsValues.notes && <DisplayField
          isVisible={template.fields.notes.visible}
          label={`${template.fields.notes.custom_label}:`}
          value={fieldsValues.notes}
          className=""
        /> }
      </div>
    </div>
  )
}


export default GlassView;