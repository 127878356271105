import React from 'react'
import TwentyTwentySource from '../services/twentyTwenty/TwentyTwentySource'
import EyeMedVerify from '../services/eyemed/EyeMedVerify'

export const RenderRPAService = (props) => {
  const { pprops } = props

  const getServiceName = (serviceName) => {
    const service = pprops.services.find((item) => item.config.service == serviceName)
    return service.config.insurance_payer
  }

  switch (pprops.selectedInsuranceName) {
    case getServiceName('2020source'):
      return <TwentyTwentySource pprops={pprops} />

    case getServiceName('eyemed'):
      return <EyeMedVerify pprops={pprops} />

    default:
      return ''
  }
}