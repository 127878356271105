import React, { useEffect, useState } from 'react'
import * as $ from 'jquery';
import { change, Field, formValueSelector } from 'redux-form'
import { MONTHS } from '../../../../itrust_common/components/Constants'
import { DatePicker, Input, Select } from '../../../../itrust_common/components/form'
import { onlyNumbers, required } from '../../../../itrust_common/components/form/FormValidations'
import { addMonths, dateFormat, getDecimalValues, humanize, numberToCurrency } from '../../../../itrust_common/components/HelperFunctions'
import { connect, useDispatch } from 'react-redux';
import { orderUpdate } from '../../../../redux/actions/order/orderActions'
import Alert from '../../../../itrust_common/components/Alert';

var onlyInstallment, myPayOverTimeInstallments = []; var myInstallmentsTotal;
function PayOverTime(props) {
  const { order, payOverTimeAmount, payOverTimeMonths, scheduledPayOverTimeDetails, payOverTimeScheduleDate } = props
  const [payOverTimeInstallments, setPayOverTimeInstallments] = useState([])
  const [installmentsTotal, setInstallmentsTotal] = useState()
  const [isInvalidInstallments, setIsInvalidInstallments] = useState(false)
  const dispatch = useDispatch();

  useEffect(() => handlePayOverTimeCal(3), [])

  useEffect(() => {
    if (Array.isArray(scheduledPayOverTimeDetails) && scheduledPayOverTimeDetails.length != 0) {
      // if payover installments exist
      setPayOverTimeInstallments(scheduledPayOverTimeDetails)
      handleInstallmentsTotalCal(scheduledPayOverTimeDetails)
      dispatch(change("orderFinalizeForm", 'payover_installments_exist', true))
    }
  }, [order])

  useEffect(() => {
    if (Array.isArray(scheduledPayOverTimeDetails) && scheduledPayOverTimeDetails.length == 0) {
      // if payover installments don't exist
      dispatch(change("orderFinalizeForm", 'payover_time_details.installments_data', payOverTimeInstallments))
    }
  }, [payOverTimeInstallments])

  const handlePayOverTimeCal = (months) => {
    if (months) {
      months = Number(months)
      let divideAmount = (payOverTimeAmount / months);
      if (!Number.isInteger(divideAmount))
        handleDecimalCal(months, divideAmount)
      else
        handleCreateInstallments(months, divideAmount)
    }
    else {
      setPayOverTimeInstallments([])
      setInstallmentsTotal('')
    }
  }

  function handleCreateInstallments(months, divideAmount, firstInstallment) {
    myPayOverTimeInstallments = []; onlyInstallment = []; let installmentDate;
    for (var i = 0; i < months; i++) {
      installmentDate = addMonths(payOverTimeScheduleDate, i)
      installmentDate = dateFormat(installmentDate)
      myPayOverTimeInstallments.push({ amount: divideAmount, schedule_date: installmentDate })
      onlyInstallment.push(divideAmount)
      setPayOverTimeInstallments([...myPayOverTimeInstallments])
      if (firstInstallment) {
        myPayOverTimeInstallments[0] = { amount: firstInstallment, schedule_date: myPayOverTimeInstallments[0].schedule_date }
        onlyInstallment[0] = firstInstallment
      }
      handleInstallmentsTotalCal()
    }
    checkIsInvalidInstallments(onlyInstallment)
  }

  function handleInstallmentsTotalCal(data) {
    if (data) {
      onlyInstallment = [];
      data.filter((el) => onlyInstallment.push(el.amount));
    }
    myInstallmentsTotal = onlyInstallment.reduce((total, num) => total + num)
    setInstallmentsTotal(myInstallmentsTotal)
  }

  function handleMonthsDateCal(date) {
    if (myPayOverTimeInstallments && myPayOverTimeInstallments.length > 0) {
      let installmentDate;
      for (var i = 0; i < payOverTimeMonths; i++) {
        installmentDate = addMonths(date, i)
        installmentDate = dateFormat(installmentDate)
        let currentObj = myPayOverTimeInstallments[i]
        currentObj = { ...myPayOverTimeInstallments[i], schedule_date: installmentDate }
        myPayOverTimeInstallments[i] = currentObj
        setPayOverTimeInstallments([...myPayOverTimeInstallments])
      }
    }
  }

  function handleResetAll() {
    myPayOverTimeInstallments = [];
    setPayOverTimeInstallments([])
    setInstallmentsTotal('')
    dispatch(change("orderFinalizeForm", 'payover_time_details.months', 1))
    dispatch(change("orderFinalizeForm", 'payover_time_details.schedule_date', ''))
  }

  function handleDecimalCal(months, divideAmount) {
    // if number is a decimal number
    let firstInstallment;
    let dividedAmountWithoutDecimals = Number(Math.floor(divideAmount))
    let decimalStr = getDecimalValues(divideAmount)
    let totalOfNonDecimalValues = dividedAmountWithoutDecimals * months
    let totalOfDecimalValues = decimalStr * months
    let valueTotal = totalOfDecimalValues + totalOfNonDecimalValues
    if (payOverTimeAmount != valueTotal) {
      // if total not same
      // payOverTimeAmount = 10
      // valueTotal = 9.999
      // reason is decimal value total contain 0.99 decimals
      let decimalValue = getDecimalValues(valueTotal)
      // round off 0.999
      decimalValue = Number(Number(decimalValue).toFixed())
      valueTotal = valueTotal + decimalValue
      valueTotal = Number(Math.floor(valueTotal))
      if (payOverTimeAmount == valueTotal) {
        firstInstallment = totalOfDecimalValues + Number(Math.floor(divideAmount)) + decimalValue
        firstInstallment = Number(Math.floor(firstInstallment))
        handleCreateInstallments(months, dividedAmountWithoutDecimals, firstInstallment)
      }
    }
    else {
      // decimal value total doesn't contain decimal value and total is equal.
      totalOfDecimalValues = Number(Number(totalOfDecimalValues).toFixed())
      firstInstallment = totalOfDecimalValues + Number(Math.floor(divideAmount))
      // 1.09999 + 9 = 10.09999
      // get firstInstallment without decimal
      firstInstallment = Number(Math.floor(firstInstallment))
      handleCreateInstallments(months, dividedAmountWithoutDecimals, firstInstallment)
    }
  }

  function handleCancelSubscription(order_id) {
    Alert.confirm(
      <div>Are you sure to cancel this EMI Process.</div>,
      (res) => {
        if (res) {
          dispatch(orderUpdate({ id: order_id, is_payovers_destroy: true }, { include: '*' }))
            .then(() => {
              dispatch(change("orderFinalizeForm", 'payover_installments_exist', false))
              $("#orderFinalizeForm-modal").modal('hide')
            })
        }
      }
    )
  }

  function checkIsInvalidInstallments(data) {
    setIsInvalidInstallments(false)
    const isInvalid = data.filter(item => item < 100)
    isInvalid && isInvalid.length > 0 && setIsInvalidInstallments('You are not allowed to create any installment below $100.')
  }

  return (
    <>
      {Array.isArray(scheduledPayOverTimeDetails) && scheduledPayOverTimeDetails.length == 0 &&
        <>
          <div className="col-12">
            <div className="row">
              <Field name="payover_time_details.amount" label="Amount" validate={[onlyNumbers, required]} component={Input} className="form-control" wrapperClasses="col-4 mr-3" onChange={() => handleResetAll()} />
              <Field name="payover_time_details.months" label="Months" component={Select} data={MONTHS} textField="value" valueField="value" wrapperClasses="form-group px-0 col-2 mr-3" onChange={(e) => handlePayOverTimeCal(e.target && e.target.value)} />
              <Field name="payover_time_details.schedule_date" label="Due Date" validate={[required]} component={DatePicker} min={new Date()} max={addMonths(new Date(), 1)} dropUp={true} wrapperClasses="form-group col-4" onChangeHook={(e) => handleMonthsDateCal(e)} />
            </div>
          </div>
        </>
      }
      {isInvalidInstallments && <h6 className='col-12 text-danger'>{isInvalidInstallments}</h6>}
      <div className="col-12 mt-4 mb-6">
        <div className="d-flex justify-content-between ">
          <h6>Payment Details</h6>
          {scheduledPayOverTimeDetails && scheduledPayOverTimeDetails.length > 0 &&
            <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => handleCancelSubscription(order.id)}>Cancel Subscription</button>
          }
        </div>
        <table className="table">
          <thead>
            <th>Months</th>
            <th>Amount</th>
            <th>Due Date</th>
            {scheduledPayOverTimeDetails && scheduledPayOverTimeDetails.length > 0 &&
              <th>Status</th>
            }
          </thead>
          <tbody>
            {payOverTimeInstallments && payOverTimeInstallments.length > 0 && payOverTimeInstallments.map((installment, index) => {
              return <tr key={`installments-${index}`}>
                <td>{index + 1} Month</td>
                <td><strong className="font-size-18">{numberToCurrency(installment.amount)}</strong></td>
                <td>{installment.schedule_date && `${dateFormat(installment.schedule_date)}`}</td>
                {scheduledPayOverTimeDetails && scheduledPayOverTimeDetails.length > 0 &&
                  <td>
                    <span className={`badge badge-pill ${installment.status == 'paid' ? 'badge-success' : 'badge-warning'}`}>
                      {humanize(installment.status)}
                    </span>
                  </td>
                }
              </tr>
            })}
            <tr>
              <td colSpan="12" className="text-right">
                <strong className="font-size-18">Total: {installmentsTotal && numberToCurrency(installmentsTotal)}</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

PayOverTime = connect((state) => {
  const { order: { order }, form: { orderFinalizeForm } } = state
  const selector = formValueSelector('orderFinalizeForm')
  const payOverTimeAmount = selector(state, 'payover_time_details.amount')
  const payOverTimeMonths = selector(state, 'payover_time_details.months')
  const payOverTimeScheduleDate = selector(state, 'payover_time_details.schedule_date')
  return {
    order,
    payOverTimeScheduleDate,
    payOverTimeMonths: Number(payOverTimeMonths),
    payOverTimeAmount: Number(payOverTimeAmount),
    initialValues: {
      id: order.id
    }
  }
})(PayOverTime)

export default PayOverTime
