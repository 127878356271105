import React from "react";
import { Route, Switch } from 'react-router-dom';
import asyncComponent from '../../../itrust_common/components/AsyncComponent';

const DashboardWrapper = asyncComponent(() =>
  import('../../../containers/authorised/addOns/EDIClearingHouse/EDIDashboard/EDIDashboardWrap')
)

const TableDataWrapper = asyncComponent(() =>
  import('../../../containers/authorised/addOns/EDIClearingHouse/EDIDashboard/EDITables/EDITableDataWrap')
)

const LogsWrapper = asyncComponent(() =>
  import('../../../containers/authorised/addOns/EDIClearingHouse/EDILogs/EDILogsWrap')
)

const Settings = asyncComponent(() =>
  import('../../../containers/authorised/addOns/EDIClearingHouse/EDISettings/EDISettings')
)

const EDIClearingHouseRoutes = (
  <Switch>
    <Route exact path='/add-ons/edi-clearing-house/dashboard' component={DashboardWrapper} />
    <Route exact path='/add-ons/edi-clearing-house/tabular-report' component={TableDataWrapper} />
    <Route exact path='/add-ons/edi-clearing-house/logs' component={LogsWrapper} />
    <Route exact path='/add-ons/edi-clearing-house/settings' component={Settings} />
  </Switch>
)

export default EDIClearingHouseRoutes;