import { blobToBase64 } from "../../../../support/utils/base64Convertor";

let mediaRecorder;
let audioBase64;
let recordedChunks = [];
let micStream;
let systemAudioStream;

const getDeviceID = () => {
  navigator.mediaDevices.enumerateDevices().then(devices => {
    devices.forEach(device => {
      if (device.kind === 'audiooutput' && device.deviceId !== 'default') {
        return `${device.label} - ${device.deviceId}`;
      }
    });
  });
}
export async function startRecording() {
  try {
    micStream = await recordMicrophoneAudio();
    systemAudioStream = await recordSystemAudio();
    let combinedStream = new MediaStream([
      ...micStream.getTracks(),
      ...systemAudioStream.getTracks(),
    ]);
    let mimeType = { mimeType: "audio/webm" };
    mediaRecorder = createRecorder(combinedStream, mimeType);
    return combinedStream;
  } catch (err) {
    console.log("Unable to acquire audio capture: " + err);
  }
}

export async function stopRecording() {
  const blob = new Blob(recordedChunks, {
    type: "audio/webm",
  });

  micStream?.getTracks().forEach(track => track.stop());
  systemAudioStream?.getTracks().forEach(track => track.stop());

  var byte_size = blob.size;
  audioBase64 = await blobToBase64(blob);
  recordedChunks = [];
  const name = "Recording" + Date.now();
  return {
    filename: name,
    attachmentBase: audioBase64,
    type: "audio/mp3",
    byte_size: byte_size,
  };
}

async function recordMicrophoneAudio() {
  return await navigator.mediaDevices.getUserMedia({
    audio: true,
    video: false,
  });
}

async function recordSystemAudio() {
  return await navigator.mediaDevices.getUserMedia({
    audio: {
      deviceId: getDeviceID()
    },
    video: false,
  });
}

function createRecorder(stream, mimeType) {
  const mediaRecorder = new MediaRecorder(stream, mimeType);
  mediaRecorder.ondataavailable = function (e) {
    if (e.data.size > 0) {
      recordedChunks.push(e.data);
    }
  };
  mediaRecorder.start(200);
  return mediaRecorder;
}
