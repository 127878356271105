import React, { useState } from 'react'
import WhatsAppMessageListing from './WhatsAppMessageListing'
import WhatsAppChatForm from './WhatsAppChatForm'
import { connect, useDispatch } from 'react-redux';
import WhatsAppChatHeader from './WhatsAppChatHeader';
import { patientWhatsAppLogCreate, patientWhatsAppLogsLoad, whatsAppChatInitiate } from '../../../../../redux/actions/addOns/whatsApp/whatsAppActions';
import { change } from 'redux-form';
import Alert from '../../../../../itrust_common/components/Alert';
import { useHistory } from 'react-router-dom';
import ScrollToTarget from '../../../../../itrust_common/components/others/ScrollToTarget';

function WhatsAppPopup(props) {
  const { patient, currentStore, patientChatLogs, is_chat_initiated, filters, userType, minimizeView, layout } = props
  const dispatch = useDispatch()
  const history= useHistory()

  const getChatStatus = () => {
    if (is_chat_initiated.status === 'initiated' && is_chat_initiated.time) {
      return 'time'
    }
    else if (is_chat_initiated.status === 'chat') {
      return 'chat'
    }
    else {
      return 'initiate'
    }
  }
  const [chatStatus, setChatStatus] = useState(getChatStatus())

  const handleWhatsAppChat = async (data) => {
    const storeId = localStorage.getItem('StoreID')
    const isWhatsAppEnabled = patient.mode_of_notification_type.whatsapp

    if (storeId && isWhatsAppEnabled && patient.cell_phone) {
      if (chatStatus == 'initiate') {
        await dispatch(whatsAppChatInitiate({ log: { ...data, is_initiated: true, type: 'initiate_message' } }))
      }
      else {
        await dispatch(patientWhatsAppLogCreate({ log: { ...data, type: 'chat_message' } }))
      }
      let whatsAppFilter = { sort: 'created_at', rpp: 99999999999999, page: 1, filter: {}, search: '', direction: 'asc', patient_id: patient.id }
      await dispatch(patientWhatsAppLogsLoad(whatsAppFilter))
      dispatch(change('WhatsAppChatForm', 'body', ''))
    }
    else {
      handleWhatsAppSubmissionErrors({ storeId, isWhatsAppEnabled, patient })
    }
  }

  const handleWhatsAppSubmissionErrors = (errors) => {
    const { storeId, isWhatsAppEnabled, patient } = errors
    if (!storeId) {
      Alert.warning("Please select store from top dropdown.")
    }
    else if (!isWhatsAppEnabled) {
      Alert.confirm(<div>Patient haven't enabled WhatsApp Notification, want to enable it now.</div>,
        (res) => {
          if (res) {
            history.push(`/patients/${patient.id}/account_information`)
            setTimeout(function () {
              ScrollToTarget('mode-of-notifications-field')
            }, 500)
          }
        }
      )    }
    else if (!patient.cell_phone ||  patient.cell_phone?.length < 6) {
      Alert.warning("Patient Phone Number must exist.")
    }
  }

  return (
    <div className='whatsapp-popup-wrap position-relative'>
      <WhatsAppChatHeader patient={patient} layout={layout} />
      {!minimizeView && <>
        <WhatsAppMessageListing patientChatLogs={patientChatLogs} />
        {userType !== 'Patient' &&
          <WhatsAppChatForm onSubmit={handleWhatsAppChat} dispatch={dispatch} patient={patient} currentStore={currentStore} is_chat_initiated={is_chat_initiated} setChatStatus={setChatStatus} chatStatus={chatStatus} />
        }
      </>}
    </div>
  )
}

const mapStateToProps = (state) => {
  const { whatsApp, currentStore, filter, auth, other } = state
  return {
    patient: whatsApp.patient,
    currentStore: currentStore.store,
    patientChatLogs: whatsApp.patient_chat_logs,
    is_chat_initiated: whatsApp.is_chat_initiated,
    filters: filter.filters,
    userType: auth.user?.type,
    minimizeView: whatsApp.minimizeView,
    layout: other.layout,
  }
}

export default connect(mapStateToProps)(WhatsAppPopup)