import React from 'react';

//Tests import
import Procedure from './Procedure'
import ImpressionPlan from './ImpressionPlan'

let ProcedureImpression = props => {
  const { fieldsValues: {procedures_attributes, impressions_attributes}, template} = props;
  return (
    <div className="row">
      {(template["procedure"]["visible"] === "true" || template["procedure"]["visible"] === true && procedures_attributes.length > 0) && [<div className="col-12">
        <div className="border-bottom">
          <h6 className="pb-3 pt-6">{template.procedure.custom_label}</h6>
        </div>
      </div>,
      <div className="col-12">
        <Procedure fieldsValues={procedures_attributes} template={template.procedure}/>
      </div>] }

      {(template["impression"]["visible"] === "true" || template["impression"]["visible"] === true && impressions_attributes.length > 0) && [<div className="col-12">
        <div className="border-bottom">
          <h6 className="pb-3 pt-6">{template.impression.custom_label}</h6>
        </div>
      </div>,
      <div className="col-12">
        <ImpressionPlan fieldsValues={impressions_attributes} template={template.impression}/>
      </div>] }
    </div>
  )
}


export default ProcedureImpression;
