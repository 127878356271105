import React, { Component } from 'react';
import PreliminaryForm from './preliminaryBinocularTests/Preliminary';
import BinocularForm from './preliminaryBinocularTests/Binocular';

export default class PreliminaryBinocularForm extends Component {
  render() {
    return (
      <div className="row">
        <PreliminaryForm {...this.props} />
        <BinocularForm {...this.props} />
      </div>
    );
  }
}