import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import HistoryOfPresentIllnessForm from '../../../../itrust_common/components/authorised/examFormPartial/HistoryOfPresentIllnessForm';

class HpiForm extends Component {
  componentWillUnmount(){
    if(this.props.dirty){
      this.props.handleSubmit();
    }
  }
  
  render(){
    const { handleSubmit, template } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <HistoryOfPresentIllnessForm pprops={this.props} template={template} isTemplate={false} />
      </form>
    )
  }
}

HpiForm = reduxForm({
  form: 'examHpiForm',
  enableReinitialize: true,
  // destroyOnUnmount: false
})(HpiForm)

HpiForm = connect(state => {
  const { exam } = state.exam
  return {
    initialValues: {
      id: exam.id,
      hpis_attributes: exam.hpis_attributes,
    }
  }
})(HpiForm)

export default HpiForm;