import React, { Component } from 'react';
import { connect } from 'react-redux';
import { numberToCurrency } from '../../../../../itrust_common/components/HelperFunctions';

class ContactLens extends Component{

  render(){
    const { contactLens } = this.props
    return (
      contactLens.map((lens, index) => [
        <tr className="text-dark bg-light border-bottom border-white border-bottom-2">
          <td><h6>Contact Lens Order #{index+1}</h6></td>
          <td colspan="5"><h6>Boxes</h6></td>
          <td><h6 className="text-danger">{lens.overall_cash_discount ? numberToCurrency(lens.overall_cash_discount) : ''}</h6></td>
          <td><h6>{lens.total ? numberToCurrency(lens.total) : ''}</h6></td>
        </tr>,
        <tr className="text-dark bg-light border-bottom border-white border-bottom-2">
          <td>{lens.od_contact_lens_attributes && lens.od_contact_lens_attributes.brand_name}</td>
          <td>{lens.od_boxes ? lens.od_boxes : ''}</td>
          <td className="pl-8">{lens.od_unit_price ? numberToCurrency(lens.od_unit_price) : ''}</td>
          <td>{lens.od_copay ? numberToCurrency(lens.od_copay) : ''}</td>
          <td>{(lens.od_cash_discount || lens.od_percentage_discount) ? numberToCurrency(lens.od_discount_type === "cash" ? lens.od_cash_discount : lens.od_percentage_discount) : ''}</td>
          <td colSpan="3">{lens.od_subtotal ? numberToCurrency(lens.od_subtotal) : ''}</td>
        </tr>,
        <tr className="text-dark bg-light border-bottom border-white border-bottom-2">
          <td>{lens.os_contact_lens_attributes && lens.os_contact_lens_attributes.brand_name}</td>
          <td>{lens.os_boxes ? lens.os_boxes : ''}</td>
          <td className="pl-8">{lens.os_unit_price ? numberToCurrency(lens.os_unit_price) : ''}</td>
          <td>{lens.os_copay ? numberToCurrency(lens.os_copay) : ''}</td>
          <td>{(lens.os_cash_discount || lens.os_percentage_discount) ? numberToCurrency(lens.os_discount_type === "cash" ? lens.os_cash_discount : lens.os_percentage_discount) : ''}</td>
          <td colSpan="3">{lens.os_subtotal ? numberToCurrency(lens.os_subtotal) : ''}</td>
        </tr>,
        <tr className="text-dark bg-light border-bottom border-white border-bottom-2">
          <td colSpan="3">Material Copay</td>
          <td colSpan="2">{lens.material_copay ? numberToCurrency(lens.material_copay) : ''}</td>
          <td colSpan="3">{lens.material_copay ? numberToCurrency(lens.material_copay) : ''}</td>
        </tr>,
        <tr className="text-dark bg-light border-bottom border-white border-bottom-2">
          <td colSpan="3">Contact Lens Fit</td>
          <td colSpan="2">{lens.shared_allowance_cl_fit ? numberToCurrency(lens.shared_allowance_cl_fit) : ''}</td>
          <td colSpan="3">{lens.shared_allowance_cl_fit ? numberToCurrency(lens.shared_allowance_cl_fit) : ''}</td>
        </tr>,
        <tr className="text-dark bg-light border-bottom border-white border-bottom-2">
          <td colSpan="3">Allowance</td>
          <td colSpan="2">{lens.allowance ? numberToCurrency(lens.allowance) : ''}</td>
          <td colSpan="3">{lens.allowance ? numberToCurrency(lens.allowance) : ''}</td>
        </tr>
      ])
    )
  }
}

export default connect()(ContactLens);