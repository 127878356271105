import React from "react";
import { Route, Switch } from 'react-router-dom';
import asyncComponent from '../../../itrust_common/components/AsyncComponent';

const DashboardWrapper = asyncComponent(() =>
  import('../../../containers/authorised/addOns/rpa/dashboard/DashboardWrapper')
)

const ClaimSubmissionLogs = asyncComponent(() =>
  import('../../../containers/authorised/addOns/rpa/logs/claimSubmission/LogsWrapper')
)

const EligibilityLogs = asyncComponent(() =>
  import('../../../containers/authorised/addOns/rpa/logs/eligibility/LogsWrapper')
)

const SettingsWrapper = asyncComponent(() =>
  import('../../../containers/authorised/addOns/rpa/settings/SettingsWrapper')
)

const RPARoutes = (
  <Switch>
    <Route exact path='/add-ons/rpa/dashboard' component={DashboardWrapper} />
    <Route exact path='/add-ons/rpa/eligibility-logs' component={EligibilityLogs} />
    <Route exact path='/add-ons/rpa/claim-submission-logs' component={ClaimSubmissionLogs} />
    <Route exact path='/add-ons/rpa/settings' component={SettingsWrapper} />
  </Switch>
)

export default RPARoutes;