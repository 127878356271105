import React, {Component} from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Moment from 'moment';

import { Input, Textarea, RadioGroup, AutoCompleteList, Checkbox, AutoCompleteInput } from '../../../itrust_common/components/form';

class MedicalRxForm extends Component {
  render(){
    const { handleSubmit, defaultImpressions } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        {/* <span className="font-weight-bold">Referral Detail</span> */}
        <div className="row">
          <Field name="settings_impression_ids" 
            label="Impression"
            placeholder="Search Impression by Name and Code" 
            component={AutoCompleteList} 
            defaultData={defaultImpressions} 
            klass="Settings::Impression" 
            valueField="id"
            autoCompleteTextField="name"
            view={{h5:'name', span:'code'}}
            className="my-5 col-6"
            wrapperClasses="col-12 form-group" />
          <Field name="medication" component={AutoCompleteInput} klass="Settings::PrescribingMedication" textField="name" valueField= "name" placeholder="Placeholder" wrapperClasses="col-6 form-group" className="form-control" label="Prescription Medications"/>
          {/* <Field name="medication" component={AutoCompleteSelect} klass="Settings::PrescribingMedication" textField="name" valueField= "name" placeholder="Placeholder" wrapperClasses="col-6 form-group" className="form-control" label="Prescription Medications"/> */}
          <Field name="dosage" component={AutoCompleteInput} klass="Settings::Dosage" textField="name" valueField= "name" placeholder="Placeholder" wrapperClasses="col-6 form-group" className="" label="Dosage"/>
          {/* <Field name="dosage" component={AutoCompleteSelect} klass="Settings::Dosage" textField="name" valueField= "name" placeholder="Placeholder" wrapperClasses="col-6 form-group" className="" label="Dosage"/> */}
          <RadioGroup name="is_generic" options={[{label: 'Yes', value: true}, {label: 'No', value: false}]} wrapperClasses="col-6 form-group" label="Generic"/>
          <Field name="refil_value" component={Input} wrapperClasses="col-3 form-group" className="form-control" label="Refill"/>
          <Field name="is_refil_required" component={Checkbox} placeholder="Placeholder" wrapperClasses="col-3 form-group d-flex align-items-center pt-6" className="form-control" label="Do not Refill"/>
          <Field name="comments" component={Textarea} rows={5} wrapperClasses="col-12 form-group" className="form-control" label="Comment"/>
        </div>
      </form>
    );
  }
}

MedicalRxForm = reduxForm({
  form: 'medicalRxForm',
  enableReinitialize: true
})(MedicalRxForm)

MedicalRxForm = connect(state => {
  const { medicalRx: { medical_rx }, exam:{ exam }} = state;
  return {
    defaultImpressions: medical_rx.impressions,
    initialValues: {
      exam_examination_id: exam.id,
      staff_id: exam.staff_id,
      issue_date: Moment(new Date()).format("YYYY/MM/DD"),
      ...medical_rx,
      continue_with_duplicate_impression: false
    }
  };
})(MedicalRxForm);

export default MedicalRxForm;