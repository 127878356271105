import Toastr from "../../../components/Toastr"
const parent = 'SETTING_'

const DEFAULT_STATE = {
  prescribing_medications: [],
  prescribing_medication: {},
  meta: {pagination: {}},
  saving: false
}
let changes = null;
export default function prescribingMedicationReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let prescribingMedications = [];
  let prescribingMedicationData = [];
  let updatedRecord = {};
  switch(action.type){
    case `${parent}PRESCRIBING_MEDICATIONS_LOAD_PENDING`:
    case `${parent}PRESCRIBING_MEDICATION_LOAD_PENDING`:
      return {...state, saving: false};

    case `${parent}PRESCRIBING_MEDICATION_CREATE_PENDING`:
    case `${parent}PRESCRIBING_MEDICATION_UPDATE_PENDING`:
      return {...state, saving: true};

    case `${parent}PRESCRIBING_MEDICATIONS_LOAD_REJECTED`:
    case `${parent}PRESCRIBING_MEDICATION_LOAD_REJECTED`:
    case `${parent}PRESCRIBING_MEDICATION_CREATE_REJECTED`:
    case `${parent}PRESCRIBING_MEDICATION_UPDATE_REJECTED`:
    case `${parent}PRESCRIBING_MEDICATION_DELETE_REJECTED`:
      Toastr.handleShow(response.data.success, response.data.error, response.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case `${parent}PRESCRIBING_MEDICATIONS_LOAD_FULFILLED`:
      changes = {
        ...response,
        saving: false
      };
      return {...state, ...changes};
    
    case `${parent}PRESCRIBING_MEDICATION_LOAD_FULFILLED`:
      changes = {
        prescribing_medication: response.prescribing_medication,
        saving: false
      }
      return {...state,...changes};
    
    case `${parent}PRESCRIBING_MEDICATION_CREATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      prescribingMedications = [...state.prescribing_medications]
      let newRecord = response.prescribing_medication
      changes = {
        prescribing_medications: [...prescribingMedications, newRecord],
        editing: false,
        saving: false
      }
      return {...state, ...changes};

    case `${parent}PRESCRIBING_MEDICATION_UPDATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      prescribingMedications = [...state.prescribing_medications];
      updatedRecord = response.prescribing_medication;
      for (const [index, prescribingMedication] of prescribingMedications.entries()) {
        if (prescribingMedication.id === updatedRecord.id) {
          prescribingMedicationData = [
            ...prescribingMedications.slice(0, index),
            updatedRecord,
            ...prescribingMedications.slice(index + 1)
          ];
        }
      }
      changes = {
        prescribing_medications: prescribingMedicationData,
        editing: false,
        saving: false
      };
      return { ...state, ...changes };

    case `${parent}PRESCRIBING_MEDICATION_DELETE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      prescribingMedications = [...state.prescribing_medications];
      for (const [index, prescribingMedication] of prescribingMedications.entries()) {
        if (prescribingMedication.id === response.prescribing_medication.id) {
          prescribingMedications.splice(index, 1);
        }
      }
      return {
        ...state,
        prescribing_medications: prescribingMedications,
        error: null
      };

    case `${parent}PRESCRIBING_MEDICATION_EDIT`:
      changes = {
        editing: {
          id: action.id,
        },
        prescribing_medication: {}
      };
      return { ...state, ...changes };

    case `${parent}PRESCRIBING_MEDICATION_EDIT_CANCEL`:
      changes = {
        editing: false
      };
      return { ...state, ...changes };

    default:
      return state;
  }
}