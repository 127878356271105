import React, { useEffect, useState } from 'react';

export default function CallTimer(props) {
  const { callStatus } = props;
  const [timer, setTimer] = useState('0:00');
  const [startTime, setStartTime] = useState(null);

  useEffect(() => {
    let intervalId;
    if (callStatus === 'call_started') {
      setStartTime(Date.now());
      intervalId = setInterval(() => {
        const currentTime = Date.now();
        const elapsedTime = currentTime - startTime;
        const minutes = Math.floor(elapsedTime / 60000);
        const seconds = Math.floor((elapsedTime % 60000) / 1000);
        const formattedTime = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        setTimer(formattedTime);
      }, 1000); // Update timer every second
    }
    if (callStatus === 'call_ended') {
      clearInterval(intervalId);
    }
    return () => clearInterval(intervalId);
  }, [callStatus, startTime]);

  return (
    <p className='text-center mb-0 pt-4'>{timer}</p>
  );
}
