import Toastr from "../../itrust_common/components/Toastr"
const DEFAULT_STATE = {
  schedule_job_logs:[],
  schedule_job_log: {},
  meta: { pagination: {} },
  saving: false,
  editing: false
}
let changes = null;
export default function scheduleJobLog(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  switch(action.type){

    case 'SCHEDULE_JOB_LOG_CREATE_PENDING':
    case 'SCHEDULE_JOB_LOGS_LOAD_PENDING':
      return {...state, saving: true};

    case 'SCHEDULE_JOB_LOG_CREATE_REJECTED':
    case 'SCHEDULE_JOB_LOGS_LOAD_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case 'SCHEDULE_JOB_LOG_CREATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201 )
      changes = {
        editing: false,
        saving: false,
      }
      return {...state, ...changes};

    case 'SCHEDULE_JOB_LOGS_LOAD_FULFILLED':
      changes = {
        ...response,
        saving: false
      };
      return {...state, ...changes};

    default:
      return state;
  }
}
