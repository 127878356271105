import {handleAPI, API_VERSION_ONE} from '../../itrust_common/utils/apiUtils';

export function staffStoresLoad(staff_id, filters){
  const url = `/${API_VERSION_ONE}/staffs/${staff_id}/staff_stores`;
  const method = 'GET'
  return {
    type: 'STAFF_STORES_LOAD',
    payload: handleAPI(url, filters, method)
  };
}

export function staffStoreCreate(staff_id, data, filters){
  const url = `/${API_VERSION_ONE}/staffs/${staff_id}/staff_stores`;
  const method= 'POST'
  return {
    type: 'STAFF_STORE_CREATE',
    payload: handleAPI(url,filters,method, {staff_store: {...data}})
  }
}

export function staffStoreUpdate(staff_id, data, filters){
  const url = `/${API_VERSION_ONE}/staffs/${staff_id}/staff_stores/${data.id}`;
  const method = "PUT"
  return {
    type: 'STAFF_STORE_UPDATE',
    payload: handleAPI(url,filters,method, {staff_store: {...data}})
  }
}

export function staffStoreDelete(staff_id, id, filters) {
  const url = `/${API_VERSION_ONE}/staffs/${staff_id}/staff_stores/${id}`;
  const method = 'DELETE';
  return {
    type: 'STAFF_STORE_DELETE',
    payload: new Promise((resolve, reject) => {
      handleAPI(url, filters, method)
        .then(response => {
          resolve({ id: id, ...response });
        })
        .catch(err => {
          reject(err);
        });
    })
  };
}

export function staffStoresDelete(staff_id, filters){
  const url = `/${API_VERSION_ONE}/staffs/${staff_id}/unassign_all_stores`;
  const method = 'POST';
  return {
    type: 'STAFF_STORES_DELETE',
    payload: handleAPI(url, filters, method)
  }
}

export function permissionAllRevoked(staff_id) {
  const url = `/${API_VERSION_ONE}/staffs/${staff_id}/revoke_all_privileges`;
  const method = 'POST';
  return {
    type: 'PERMISSION_ALL_REVOKED',
    payload: handleAPI(url, {}, method)
  };
}

export function staffStoreEdit(id) {
  return {
    type: 'STAFF_STORE_EDIT',
    id,
  };
}

export function staffStoreEditCancel() {
  return {
    type: 'STAFF_STORE_EDIT_CANCEL'
  };
}