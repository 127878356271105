import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as $ from 'jquery';

import { orderPaymentsLoad, orderPaymentEdit } from '../../../../redux/actions/order/orderPaymentActions';

class FinalizeButtonForm extends Component {

  handlePaymentEdit(data) {
    this.props.dispatch(orderPaymentEdit('New'))
    this.props.dispatch(orderPaymentsLoad(this.props.orderId, {filter: {payment_type: ['sale','void']}, include: ['staff']} ))
    $("#orderPaymentsForm-modal").modal()
  }

  render() {
    return (
      <span
        className="pointer"
        onClick={this.handlePaymentEdit.bind(this)}
      >
        {this.props.children}
      </span>
    );
  }
}

FinalizeButtonForm = connect(state=> {
  return {
  }
})(FinalizeButtonForm)

export default FinalizeButtonForm;
