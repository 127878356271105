import React from 'react';
import { isPresentInArray, numberToCurrency } from '../../../../../itrust_common/components/HelperFunctions'

let ContactLenses = props => {
  const { selectedExam, selectedIds, handleIdsSelect } = props;
  return (
    <div className="row">
      <div className="col-12 mt-7">
        { selectedExam && selectedExam.finalized_lenses_attributes && 
          <div className="overview-table px-2">
            <table className="table table-hover shadow-none">
              <thead className="text-dark border-bottom border-bottom-2">
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">EYE</th>
                  <th scope="col">Type</th>
                  <th scope="col">SPH</th>
                  <th scope="col">CYL</th>
                  <th scope="col">AXIS</th>
                  <th scope="col">ADD</th>
                  <th scope="col">BC</th>
                  <th scope="col">DIA</th>
                  <th scope="col">Price</th>
                  <th scope="col">Select</th>
                </tr>
              </thead>
              <tbody>
                { selectedExam.finalized_lenses_attributes.map((finalizedLens, index)=>
                  [<tr key={`finalizedLens-${index}`} className="text-dark border-bottom border-light border-bottom-2">
                    <td>{index+1}</td>
                    <td>OD</td>
                    <td>{finalizedLens.od_contact_lens_attributes && finalizedLens.od_contact_lens_attributes.brand_name}</td>
                    <td>{finalizedLens.od_sphere}</td>
                    <td>{finalizedLens.od_cylinder}</td>
                    <td>{finalizedLens.od_axis}</td>
                    <td>{finalizedLens.od_add}</td>
                    <td>{finalizedLens.od_curve}</td>
                    <td>{finalizedLens.od_diameter}</td>
                    <td>{finalizedLens.od_contact_lens_attributes && numberToCurrency(finalizedLens.od_contact_lens_attributes.unit_price)}</td>
                    <td>
                      <div className="custom-control custom-checkbox">
                        <input 
                          onChange={()=> handleIdsSelect(selectedIds, finalizedLens.id)}
                          type="checkbox"
                          checked={isPresentInArray(selectedIds, finalizedLens.id)}
                          className="custom-control-input" id={`contact-lens-select-${finalizedLens.id}`} />
                        <label className="custom-control-label" htmlFor={`contact-lens-select-${finalizedLens.id}`}></label>
                      </div>
                    </td>
                  </tr>,
                  <tr className="text-dark border-bottom border-bottom-2">
                    <td></td>
                    <td>OS</td>
                    <td>{finalizedLens.os_contact_lens_attributes && finalizedLens.os_contact_lens_attributes.brand_name}</td>
                    <td>{finalizedLens.os_sphere}</td>
                    <td>{finalizedLens.os_cylinder}</td>
                    <td>{finalizedLens.os_axis}</td>
                    <td>{finalizedLens.os_add}</td>
                    <td>{finalizedLens.os_curve}</td>
                    <td>{finalizedLens.os_diameter}</td>
                    <td>{finalizedLens.os_contact_lens_attributes && numberToCurrency(finalizedLens.os_contact_lens_attributes.unit_price)}</td>
                    <td></td>
                  </tr>]
                )}
              </tbody>
            </table>
          </div>
        }
      </div>
    </div>
  )
}

export default ContactLenses;