import React from 'react'
import RpaClaimGenerateButton from './RpaClaimGenerateButton'

function Rpa1500ClaimGenerate(props) {
  // if RPA service has 1500 Claim Form
  const { rpaClaimFormGenerate } = props

  const rpa1500ClaimFormGenerate = async () => {
    rpaClaimFormGenerate()
  }

  return (
    <>
      <RpaClaimGenerateButton rpaClaimFormGenerate={rpa1500ClaimFormGenerate} />
    </>
  )
}

export default Rpa1500ClaimGenerate;