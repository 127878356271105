import React, { Component } from 'react';
import { connect } from "react-redux";
import { Field } from 'redux-form';
import { Input, RadioGroup, Value, Checkbox } from '../form/'
import * as $ from 'jquery'

import { previousExamEdit, previousExamLoad, resetPreviousExam } from '../../redux/actions/exam/previousExamActions';

class FormTemplateHeader extends Component {
  async handlePreviousValue(overviewName) {
    const { exam } = this.props
    await this.handlePreviousExamReset(overviewName)
    await this.props.dispatch(previousExamEdit(overviewName))
    setTimeout(() => {
      (overviewName == 'procedure' || overviewName == 'impression') && this.props.dispatch(previousExamLoad(exam.id, { include: '*' }))
    }, 100);
    $("#" + overviewName).modal()
  }

  async handlePreviousExamReset(overviewName) {
    if (overviewName === "external_exam" || overviewName === "internal_exam") {
      // For Canvas Image an hard re-render required. ResetPreviousExam and then load again
      await this.props.dispatch(previousExamEdit(false))
      await this.props.dispatch(resetPreviousExam())
    }
  }

  render() {
    const { name, overviewName, baseName, isTemplate, fieldValues, pprops = null, isToggle, exams, saving, clearFields, exam } = this.props;
    return (
      isTemplate ?
        <div className="p-6 border-bottom border-light d-flex justify-content-between align-items-center">
          <h6>
            <Field name={baseName + '[custom_label]'} label="" component={Value} />
            <span className="btn p-0 float-right" id={baseName} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i className="la la-pencil text-muted" />
            </span>
            <div className="dropdown-menu dropdown-menu-right border-0 shadow mt-7 ml-3" aria-labelledby={baseName}>
              <div className="row px-7 pb-5">
                <button type="button" className="close" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <Field name={baseName + '[default_label]'} label="Default Label" component={Input} disabled={true} className="form-control" wrapperClasses="col-12 form-group" />
                <Field name={baseName + '[custom_label]'} label="New Label" component={Input} className="form-control" wrapperClasses="col-12 form-group" />
              </div>
            </div>
          </h6>
          <RadioGroup name={baseName + '[visible]'} options={[{ label: 'Show', value: true }, { label: 'Hide', value: false }]} wrapperClasses="" />
        </div>
        :
        <div className="py-6 border-bottom border-light d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <h6 className="text-rap">{fieldValues && fieldValues.custom_label}</h6>
            {exam?.all_exams && exam?.all_exams.length > 1 && overviewName && <span className="badge badge-pill badge-primary ml-3" onClick={() => this.handlePreviousValue(overviewName)}><i className="la la-sm la-info" /></span>}
          </div>
          {name && <Field name={name} component={Checkbox} label={isToggle ? <i className="la la-angle-up" /> : <i className="la la-angle-down" />} className="d-none" wrapperClasses="d-flex align-items-center checkbox-remove w-100 expand-collapse-button" onChange={
            (e) => {
              setTimeout(() => !saving && pprops && pprops.submit(), 500)
            }
          } />}
          {isToggle && clearFields &&
            <span className="ml-4 d-inline-block text-dark" onClick={clearFields}>
              <span className="badge badge-pill badge-danger"><i className="las la-trash-alt"></i></span>
            </span>
            }
        </div>
    )
  }
}

const mapStateToProps = state => {
  const { exam } = state;
  return {
    saving: exam?.saving,
    exam: exam?.exam,
    exams: exam && exam.exams,
  }
}
export default connect(mapStateToProps)(FormTemplateHeader);