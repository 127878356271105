import React from 'react';
import DisplayField from '../../../../../itrust_common/components/others/DisplayField'

let AmslerGridField = props => {
  const { fieldsValues, template } = props;
  return (
    <div className="row">
      <DisplayField
        isVisible={template.od_status.visible}
        label={`${template.od_status.custom_label}:`}
        value={fieldsValues.fields && fieldsValues.fields.od_status}
        className="col-6 py-4"
      />
      <DisplayField
        isVisible={template.os_status.visible}
        label={`${template.os_status.custom_label}:`}
        value={fieldsValues.fields && fieldsValues.fields.os_status}
        className="col-6 py-4"
      />
      <DisplayField
        isVisible={template.od_comments.visible}
        label={`${template.od_comments.custom_label}:`}
        value={fieldsValues.fields && fieldsValues.fields.od_comments}
        className="col-6 py-4"
      />
      <DisplayField
        isVisible={template.os_comments.visible}
        label={`${template.os_comments.custom_label}:`}
        value={fieldsValues.fields && fieldsValues.fields.os_comments}
        className="col-6 py-4"
      />
    </div>
  )
}
export default AmslerGridField;