import React, { useEffect, useState } from 'react'
import PatientCalling from './PatientCalling'
import PatientLogs from './Patientlogs'
import PatientNotes from './PatientNotes'
import PatientOverviews from './PatientOverviews'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { anonymousCallLogsLoad } from '../../../../../../redux/actions/addOns/voip/voipActions'
import { dateFormat, getShortName } from '../../../../../../itrust_common/components/HelperFunctions'
import { patientLoad } from '../../../../../../redux/actions/patient/patientActions'
import { layoutChange } from '../../../../../../itrust_common/redux/actions/otherActions'

function PatientCallWrapper(props) {
  const { layout, contact, familyMembers, makeOutGoingCall, hangUpOutgoingCall, callInfo, callInfo: { isAnonymous, isIncomingCall, anonymous_call_logs }, isCallActive, dispatch, staffId, telecomServiceType } = props

  const [activePatientTab, setActivePatientTab] = useState(isAnonymous ? 1 : 2)

  useEffect(() => {
    isAnonymous && handleLoadAnonymousCallLogs()
  }, [isAnonymous])

  const handleLoadAnonymousCallLogs = () => {
    if (isIncomingCall) {
      dispatch(anonymousCallLogsLoad({ call_log_id: callInfo?.incoming_info?.call_log_id }))
    }
    else {
      dispatch(anonymousCallLogsLoad({ call_log_id: callInfo?.call_log?.id }))
    }
  }

  const handlePatientRedirect = () => {
    if (layout !== "default") {
      dispatch(layoutChange('default'))
    }
    dispatch(patientLoad(contact.id, { include: '*' }))
  }

  return (
    <>
      <div className='bg-white'>
        <div className='d-flex flex-column align-items-center justify-content-center pt-5'>
          <div className='font-weight-500 text-primary'>
            {isAnonymous ?
              <span className='font-size-20 m-1 text-primary'>Anonymous</span> :
              <div className='d-flex flex-column align-items-center justify-content-between'>
                {getShortName(contact, 'rounded-circle mb-4 width-4 height-4', 'mb-4 width-4 height-4 font-size-24 ')}

                <Link className="text-primary" to={`/patients/${contact.id}/overview`} onClick={handlePatientRedirect}>
                  <span className='font-size-20 m-1 text-primary'>{contact.name}</span>
                  <i className="las la-external-link-alt font-size-20"></i>
                </Link>
              </div>
            }
          </div>
          {isAnonymous && <div className='py-2'>
            {isIncomingCall ?
              <h4 className='text-primary'>{callInfo?.call_log?.from}</h4> :
              <h4 className='text-primary'>{callInfo?.call_log?.to}</h4>
            }
          </div>}
          {!isAnonymous &&
            <div className='font-size-14'>
              <span className='pr-3 text-secondary'>DOB</span>
              <span className='pr-6'>
                {contact.date_of_birth ? `${dateFormat(contact.date_of_birth)} | ${contact.age}` : '-'}
              </span>
              <span>
                <i className="las la-map-marker-alt pr-1"></i>
                {contact.home_address_attributes?.city}{contact.home_address_attributes?.state && `, ${contact.home_address_attributes?.state}`}
              </span>
            </div>
          }
          <PatientCalling
            call={callInfo.call}
            callInfo={callInfo}
            isCallActive={isCallActive}
            contact={contact}
            isIncomingCall={isIncomingCall}
            makeOutGoingCall={phoneNumber => makeOutGoingCall(phoneNumber)}
            hangUpOutgoingCall={hangUpOutgoingCall}
            isAnonymous={isAnonymous}
            staffId={staffId}
            telecomServiceType={telecomServiceType}
          />
        </div>
        {activePatientTab === 1 && <PatientLogs callLogs={isAnonymous ? anonymous_call_logs?.call_logs : contact.call_logs_attributes} />}
        {!isAnonymous &&
          <>
            {activePatientTab === 2 && <PatientOverviews contact={contact} familyMembers={familyMembers} layout={layout} dispatch={dispatch} />}
            {activePatientTab === 3 && <PatientNotes contact={contact} />}
          </>
        }
      </div>
      <div className='d-flex justify-content-around font-size-12 py-3'>
        <button className={`d-flex flex-column align-items-center border-0 bg-transparent ${activePatientTab === 3 && 'text-primary'}`} onClick={() => setActivePatientTab(3)}>
          <i className="las la-book-medical font-size-18"></i>
          <span>Notes</span>
        </button>
        {!isAnonymous &&
          <>
            <button className={`d-flex flex-column align-items-center border-0 bg-transparent ${activePatientTab === 2 && 'text-primary'}`} onClick={() => setActivePatientTab(2)}>
              <i className="lar la-user font-size-18"></i>
              <span>Overview</span>
            </button>
            <button className={`d-flex flex-column align-items-center border-0 bg-transparent ${activePatientTab === 1 && 'text-primary'}`} onClick={() => setActivePatientTab(1)}>
              <i className="las la-clock font-size-18"></i>
              <span>Logs</span>
            </button>
          </>}
      </div>
    </>
  )
}

const mapStateToProps = state => {
  const { voip, familyMember, contact, auth, other } = state

  return {
    callInfo: voip?.calling_info,
    contact: contact?.contact_list,
    familyMembers: familyMember.family_members,
    isCallActive: voip?.calling_info.isCallActive,
    staffId: auth.user?.id,
    layout: other.layout,
  }
}

export default connect(mapStateToProps)(PatientCallWrapper);