import React from 'react';
import DisplayField from '../../../../../itrust_common/components/others/DisplayField'

let Preliminary = props => {
  const { fieldsValues, template} = props;
  return (
    <div className="row">
      <DisplayField
        isVisible={template.fields.eoms.visible}
        label={`${template.fields.eoms.custom_label}:`}
        value={fieldsValues.eoms}
        className="col-2 py-4"
      />
      <DisplayField
        isVisible={template.fields.pupils.visible}
        label={`${template.fields.pupils.custom_label}:`}
        value={fieldsValues.pupils}
        className="col-2 py-4"
      />
      <DisplayField
        isVisible={template.fields.confrontational_field.visible}
        label={`${template.fields.confrontational_field.custom_label}:`}
        value={fieldsValues.confrontational_field}
        className="col-3 py-4"
      />
      <DisplayField
        isVisible={template.fields.color_vision.visible}
        label={`${template.fields.color_vision.custom_label}:`}
        value={fieldsValues.color_vision}
        className="col-2 py-4"
      />
      <DisplayField
        isVisible={template.fields.stereopsis.visible}
        label={`${template.fields.stereopsis.custom_label}:`}
        value={fieldsValues.stereopsis}
        className="col-2 py-4"
      />
      <DisplayField
        isVisible={template.fields.fusion.visible}
        label={`${template.fields.fusion.custom_label}:`}
        value={fieldsValues.fusion}
        className="col-2 py-4"
      />
      <DisplayField
        isVisible={template.fields.muscle_balance.visible}
        label={`${template.fields.muscle_balance.custom_label}:`}
        value={fieldsValues.muscle_balance}
        className="col-2 py-4"
      />
      { fieldsValues.notes && <DisplayField
        isVisible={template.fields.notes.visible}
        label={`${template.fields.notes.custom_label}:`}
        value={fieldsValues.notes}
        className="col-2 py-4"
      /> }
    </div>
  )
}


export default Preliminary;