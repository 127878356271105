import React from 'react';
import { Route, Switch, Redirect} from 'react-router-dom';
import asyncComponent from '../../../components/AsyncComponent';

const FormTemplate = asyncComponent(() =>
  import('../../../containers/authorised/setting/patient/formTemplate/FormTemplate')
)

const SocialHistory = asyncComponent(() =>
  import('../../../containers/authorised/setting/patient/socialHistory/SocialHistory')
)

const ReviewOfSystem = asyncComponent(() =>
  import('../../../containers/authorised/setting/patient/reviewOfSystem/ReviewOfSystem')
)

const SurveyRating = asyncComponent(() =>
  import('../../../containers/authorised/setting/patient/surveyRating/SurveyRating')
)

const Prescription = asyncComponent(() =>
  import('../../../containers/authorised/setting/patient/report/reportTypes/Prescription')
)
const MedicalRx = asyncComponent(() =>
  import('../../../containers/authorised/setting/patient/report/reportTypes/MedicalRx')
)
const Referral = asyncComponent(() =>
  import('../../../containers/authorised/setting/patient/report/reportTypes/Referral')
)
const Invoice = asyncComponent(() =>
  import('../../../containers/authorised/setting/patient/report/reportTypes/Invoice')
)

const patientRoutes = (
  <Switch>
    <Route exact path="/settings/patient/form-template" component={FormTemplate} />
    <Route exact path="/settings/patient/social-history" component={SocialHistory} />
    <Route exact path="/settings/patient/review-of-system" component={ReviewOfSystem} />
    <Route exact path="/settings/patient/survey-rating" component={SurveyRating} />
    <Route exact path="/settings/patient/report/prescription" component={Prescription} />
    <Route exact path="/settings/patient/report/medical_rx" component={MedicalRx} />
    <Route exact path="/settings/patient/report/referral" component={Referral} />
    <Route exact path="/settings/patient/report/invoice" component={Invoice} />
    <Redirect exact from="/settings/patient/report" to="/settings/patient/report/prescription" />

    <Redirect exact from="/settings/patient" to="/settings/patient/form-template" />
  </Switch>
);

export default patientRoutes;