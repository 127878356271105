import Toastr from "../../../components/Toastr"
const parent = 'SETTING_'

const DEFAULT_STATE = {
  eyeglass_lens_materials: [],
  eyeglass_lens_material: {},
  meta: {pagination: {}},
  saving: false
}
let changes = null;
export default function eyeglassLensMaterialReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let eyeglassLensMaterials = [];
  let eyeglassLensMaterialData = [];
  let updatedRecord = {};
  switch(action.type){
    case `${parent}EYEGLASS_LENS_MATERIALS_LOAD_PENDING`:
    case `${parent}EYEGLASS_LENS_MATERIAL_LOAD_PENDING`:
      return {...state, saving: false};

    case `${parent}EYEGLASS_LENS_MATERIAL_CREATE_PENDING`:
    case `${parent}EYEGLASS_LENS_MATERIAL_UPDATE_PENDING`:
      return {...state, saving: true};

    case `${parent}EYEGLASS_LENS_MATERIALS_LOAD_REJECTED`:
    case `${parent}EYEGLASS_LENS_MATERIAL_LOAD_REJECTED`:
    case `${parent}EYEGLASS_LENS_MATERIAL_CREATE_REJECTED`:
    case `${parent}EYEGLASS_LENS_MATERIAL_UPDATE_REJECTED`:
    case `${parent}EYEGLASS_LENS_MATERIAL_DELETE_REJECTED`:
      Toastr.handleShow(response.data.success, response.data.error, response.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case `${parent}EYEGLASS_LENS_MATERIALS_LOAD_FULFILLED`:
      changes = {
        ...response,
        saving: false
      };
      return {...state, ...changes};
    
    case `${parent}EYEGLASS_LENS_MATERIAL_LOAD_FULFILLED`:
      changes = {
        eyeglass_lens_material: response.eyeglass_lens_material,
        saving: false
      }
      return {...state,...changes};
    
    case `${parent}EYEGLASS_LENS_MATERIAL_CREATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      eyeglassLensMaterials = [...state.eyeglass_lens_materials]
      let newRecord = response.eyeglass_lens_material
      changes = {
        eyeglass_lens_materials: [...eyeglassLensMaterials, newRecord],
        editing: false,
        saving: false
      }
      return {...state, ...changes};

    case `${parent}EYEGLASS_LENS_MATERIAL_UPDATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      eyeglassLensMaterials = [...state.eyeglass_lens_materials];
      updatedRecord = response.eyeglass_lens_material;
      for (const [index, eyeglassLensMaterial] of eyeglassLensMaterials.entries()) {
        if (eyeglassLensMaterial.id === updatedRecord.id) {
          eyeglassLensMaterialData = [
            ...eyeglassLensMaterials.slice(0, index),
            updatedRecord,
            ...eyeglassLensMaterials.slice(index + 1)
          ];
        }
      }
      changes = {
        eyeglass_lens_materials: eyeglassLensMaterialData,
        editing: false,
        saving: false
      };
      return { ...state, ...changes };

    case `${parent}EYEGLASS_LENS_MATERIAL_DELETE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      eyeglassLensMaterials = [...state.eyeglass_lens_materials];
      for (const [index, eyeglassLensMaterial] of eyeglassLensMaterials.entries()) {
        if (eyeglassLensMaterial.id === response.eyeglass_lens_material.id) {
          eyeglassLensMaterials.splice(index, 1);
        }
      }
      return {
        ...state,
        eyeglass_lens_materials: eyeglassLensMaterials,
        error: null
      };

    case `${parent}EYEGLASS_LENS_MATERIAL_DUPLICATE`:
      changes = {
        editing: {
          id: 'New',
        },
        eyeglass_lens_material: {...action.eyeglassLensMaterial, id: null},
      };
      return { ...state, ...changes };

    case `${parent}EYEGLASS_LENS_MATERIAL_EDIT`:
      changes = {
        editing: {
          id: action.id,
        },
        eyeglass_lens_material: {}
      };
      return { ...state, ...changes };

    case `${parent}EYEGLASS_LENS_MATERIAL_EDIT_CANCEL`:
      changes = {
        editing: false
      };
      return { ...state, ...changes };

    default:
      return state;
  }
}