import React from 'react';

let LoaderComponent = () => {
  return (
    <div className="loading-content bg-light w-100 mx-auto">
      <i className="la la-spinner mx-auto w-100"/>
    </div>
  );
};

export default LoaderComponent;
