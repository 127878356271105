import Toastr from "../../../itrust_common/components/Toastr"
const parent = 'STORE_SETTING_'

const DEFAULT_STATE = {
  procedure_pricings: [],
  procedure_pricing: {},
  meta: {pagination: {}},
  saving: false
}
let changes = null;
export default function procedurePricingReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let procedurePricings = [];
  let procedurePricingData = [];
  let updatedRecord = {};
  let newRecords = [];
  switch(action.type){
    case `${parent}PROCEDURE_PRICINGS_LOAD_PENDING`:
    case `${parent}PROCEDURE_PRICING_LOAD_PENDING`:
      return {...state, saving: false};

    case `${parent}PROCEDURE_PRICING_CREATE_PENDING`:
    case `${parent}PROCEDURE_PRICING_UPDATE_PENDING`:
      return {...state, saving: true};

    case `${parent}PROCEDURE_PRICINGS_LOAD_REJECTED`:
    case `${parent}PROCEDURE_PRICING_LOAD_REJECTED`:
    case `${parent}PROCEDURE_PRICING_CREATE_REJECTED`:
    case `${parent}PROCEDURE_PRICING_UPDATE_REJECTED`:
      Toastr.handleShow(response.data.success, response.data.error, response.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case `${parent}PROCEDURE_PRICINGS_LOAD_FULFILLED`:
      changes = {
        ...response,
        saving: false
      };
      return {...state, ...changes};
    
    case `${parent}PROCEDURE_PRICING_LOAD_FULFILLED`:
      changes = {
        ...response,
        saving: false
      }
      return {...state,...changes};
    
    case `${parent}PROCEDURE_PRICING_CREATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      procedurePricings = [...state.procedure_pricings];
      newRecords = response.procedure_pricings
      let allRecords = [...procedurePricings, ...newRecords]
      changes = {
        procedure_pricings: [...new Map(allRecords.map(item => [item['id'], item])).values()],
        editing: false,
        saving: false
      }
      return {...state, ...changes};

    case `${parent}PROCEDURE_PRICING_UPDATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      procedurePricings = [...state.procedure_pricings];
      updatedRecord = response.procedure_pricing;
      for (const [index, procedurePricing] of procedurePricings.entries()) {
        if (procedurePricing.id === updatedRecord.id) {
          procedurePricingData = [
            ...procedurePricings.slice(0, index),
            updatedRecord,
            ...procedurePricings.slice(index + 1)
          ];
        }
      }
      changes = {
        procedure_pricings: procedurePricingData,
        editing: false,
        saving: false
      };
      return { ...state, ...changes };

    case `${parent}PROCEDURE_PRICING_DELETE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      procedurePricings = [...state.procedure_pricings];
      for (const [index, procedurePricing] of procedurePricings.entries()) {
        if (procedurePricing.id === response.procedure_pricing.id) {
          procedurePricings.splice(index, 1);
        }
      }
      return {
        ...state,
        procedure_pricings: procedurePricings,
        error: null
      };

    case `${parent}PROCEDURE_PRICING_EDIT`:
      changes = {
        editing: {
          id: action.id,
        },
        procedure_pricing: {}
      };
      return { ...state, ...changes };

    case `${parent}PROCEDURE_PRICING_EDIT_CANCEL`:
      changes = {
        editing: false
      };
      return { ...state, ...changes };

    default:
      return state;
  }
}