import React, { useEffect } from 'react'
import ThemePicker from './ThemePicker';
import AddOnsForm from './AddOnsForm';
import Accessibility from './Accessibility';
import { connect, useDispatch } from 'react-redux';
import { FormSection, reduxForm } from 'redux-form';
import { getCurrentZoomLevel } from '../../../itrust_common/components/HelperFunctions';
import { SaveButton } from '../../../itrust_common/components/others';
import * as $ from 'jquery';

function PreferencesForm(props) {
  const { handleSubmit, setIsReloadRequire, saving, isReloadRequire, setCurrentTheme, currentTheme, setCurrentZoomLevel } = props

  useEffect(() => {
    $('#theme-tab').tab('show')
  }, [])

  return (
    <form onSubmit={handleSubmit} className="tab-content ml-9 w-75 d-flex flex-column justify-content-between" id="v-pills-tabContent">
      <div className="tab-pane fade show active" id="theme" role="tabpanel" aria-labelledby="theme-tab">
        <ThemePicker setIsReloadRequire={setIsReloadRequire} setCurrentTheme={setCurrentTheme} currentTheme={currentTheme} />
      </div>
      <div className="tab-pane fade" id="accessibility" role="tabpanel" aria-labelledby="accessibility-tab">
        <Accessibility setIsReloadRequire={setIsReloadRequire} setCurrentZoomLevel={setCurrentZoomLevel} />
      </div>
      <div className="tab-pane fade " id="addOns" role="tabpanel" aria-labelledby="addOns-tab">
        <FormSection name="preferences.add_ons">
          <AddOnsForm />
        </FormSection>
      </div>
      <div className="d-flex align-items-center justify-content-end">
        {isReloadRequire && <span className='mr-4'>Reload Required!</span>}
        <SaveButton saving={saving} label={`${isReloadRequire ? 'Save & Reload' : 'Save'}`} />
      </div>
    </form>
  )
}

PreferencesForm = reduxForm({
  form: 'PreferencesForm',
  enableReinitialize: true,
})(PreferencesForm)

const mapStateToProps = (state) => {
  const { auth, auth: { user: { preferences }, user } } = state
  const currentZoomLevel = getCurrentZoomLevel() ? getCurrentZoomLevel() : '100%'

  return {
    saving: auth.saving,
    initialValues: {
      preferences: { ...preferences },
      zoom_level: currentZoomLevel,
      background_image_attributes: user.background_image_attributes
    }
  }
}

export default connect(mapStateToProps)(PreferencesForm);