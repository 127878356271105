import React from "react";

const Attachments = ({ article }) => {
  return (
    <>
      <>
        <div>
          <div className="w-100 px-4">
            {article.attachments.map((attachment) => (
              <div className="my-2">
                {attachment.attachment.includes("data:video") === true ? (
                  <video
                    src={attachment.attachment}
                    className="w-75 h-100"
                    controls
                  />
                ) : (
                  <img src={attachment.attachment} className="h-100 w-75" />
                )}
              </div>
            ))}
          </div>
        </div>
      </>
    </>
  );
};

export default Attachments;
