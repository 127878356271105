import Toastr from "../../../itrust_common/components/Toastr"
const DEFAULT_STATE = {
  insurance_claim_form: {},
  meta: { pagination: {} },
  saving: false,
  trizettoSending: false
}
let changes = null;
export default function claimFormReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let updatedRecord = {};
  switch(action.type){
    case 'CLAIM_FORM_LOAD_PENDING':
      return {...state, saving: true};
    case 'SEND_TO_TRIZETTO_PENDING':
      return {...state, trizettoSending: true};

    case 'CLAIM_FORM_UPDATE_PENDING':
      return {...state, saving: true};

    case 'CLAIM_FORM_LOAD_REJECTED':
    case 'CLAIM_FORM_CREATE_REJECTED':
    case 'CLAIM_FORM_UPDATE_REJECTED':
    case 'CLAIM_FORM_SECURE_FAX_REJECTED':
    case 'CLAIM_FORM_SYNC_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};
    case 'SEND_TO_TRIZETTO_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = {
        trizettoSending: false
      }
      return {...state,...changes};

    case 'CLAIM_FORM_LOAD_FULFILLED':
      changes = {
        ...response,
        saving: false
      }
      return {...state,...changes};

    case 'CLAIM_FORM_CREATE_FULFILLED':
      changes = {
        ...response,
        saving: false
      }
      return {...state,...changes};
    
    case 'CLAIM_FORM_UPDATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      updatedRecord = response.insurance_claim_form;
      changes = {
        insurance_claim_form: updatedRecord,
        saving: false
      };
      return { ...state, ...changes };

    case 'CLAIM_FORM_SYNC_FULFILLED':
      changes = {
        ...response,
        saving: false
      }
      return {...state,...changes};

    case 'CLAIM_FORM_SECURE_FAX_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      return state;
    
    case 'SEND_TO_TRIZETTO_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      return {...state, trizettoSending: false};

    case 'CLAIM_FORM_RESET':
      changes = {
        insurance_claim_form: {},
        saving: false
      }
      return {...state,...changes};
      
    default:
      return state;
  }
}