import React, { Fragment, useEffect, useState } from 'react'
import Log from './Log';
import { connect, useDispatch } from 'react-redux';
import { voipCallLogsClear, voipCallLogsLoad } from '../../../../../../../redux/actions/addOns/voip/voipReportActions';
import { filterReset } from '../../../../../../../itrust_common/redux/actions/filterActions';

function CallLogs(props) {
  const { callLogs, saving, logsCount, nextPage, filters } = props
  const [activeLogTab, setActiveLogTab] = useState(1);
  const callLogPopupFilters = {
    "sort": "created_at",
    "direction": "desc",
    "search": "",
    "filter": {},
    "page": 1,
    "rpp": 60
  }
  const dispatch = useDispatch()

  useEffect(() => {
    handleCallLogsLoad(callLogPopupFilters)
    return () => {
      dispatch(voipCallLogsClear())
    }
  }, [])

  const handleCallLogsLoad = (filter) => {
    dispatch(voipCallLogsLoad({ ...filter, include: '*' }))
  }

  const handleLogTabChange = async (tab, callLogFilter) => {
    setActiveLogTab(tab)
    await dispatch(voipCallLogsClear())
    handleCallLogsLoad({ ...callLogPopupFilters, filter: { ...callLogFilter }})
  }

  return (
    <div className='p-4'>
      <div className='d-flex justify-content-between align-items-center px-7 mb-5 font-size-14'>
        <span className={`bg-transparent pointer px-4 ${activeLogTab === 1 ? 'text-primary border-bottom border-2 border-primary' : 'text-secondary border-0'}`} onClick={() => handleLogTabChange(1, {})}>All</span>
        <span className={`bg-transparent pointer px-4 ${activeLogTab === 2 ? 'text-primary border-bottom border-2 border-primary' : 'text-secondary border-0'}`} onClick={() => handleLogTabChange(2, { missed_calls: true })}>Missed</span>
        <span className={`bg-transparent pointer px-4 ${activeLogTab === 3 ? 'text-primary border-bottom border-2 border-primary' : 'text-secondary border-0'}`} onClick={() => handleLogTabChange(3, { recording_type: 'voicemail' })}>Voicemail</span>
      </div>
      <div className='calling-inner-height overflow-auto my-5'>
        {saving && <div className='d-flex align-items-center text-primary justify-content-center my-5'>
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <strong className='ml-3 font-size-18'>Loading...</strong>
        </div>}
        {callLogs?.length > 0 ?
          <>
            {callLogs.map((callLog) => <Fragment key={callLog.id}>
              <Log callLog={callLog} filters={filters} dispatch={dispatch} />
            </Fragment>)}
            {!saving && logsCount > callLogs.length &&
              <div className='text-center my-5'>
                <button className='btn btn-primary btn-sm' onClick={() => handleCallLogsLoad({ ...callLogPopupFilters, page: nextPage })}>Load More 60</button>
              </div>
            }
          </> :
          <>{!saving && <p className='text-center mt-7'>No Record Found</p>}</>
        }
      </div>
    </div>
  )
}
const mapStateToProps = state => {
  const { voip, filter, voipReport } = state;

  return {
    filters: filter.filters,
    saving: voipReport.saving,
    callLogs: voipReport.call_logs,
    logsCount: voipReport.meta?.pagination?.count,
    nextPage: voipReport.meta?.pagination?.next
  }
}

export default connect(mapStateToProps)(CallLogs);