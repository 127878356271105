import {handleAPI, API_VERSION_ONE} from '../../../itrust_common/utils/apiUtils';

export function ordersLoad(filters){
  const url = `/${API_VERSION_ONE}/orders/`;
  const method = 'GET'

  return {
    type: 'ORDERS_LOAD',
    payload: handleAPI(url, filters, method)
  };
}

export function orderLoad(id, filters){
  const url = `/${API_VERSION_ONE}/orders/${id}`;
  const method = 'GET'

  return {
    type: 'ORDER_LOAD',
    payload: handleAPI(url,filters,method)
  };
}

export function orderCreate(data, filters){
  const url = `/${API_VERSION_ONE}/orders/`;
  const method= 'POST'
  return {
    type: 'ORDER_CREATE',
    payload: handleAPI(url,filters,method, {order: {...data}})
  }
}

export function duplicateOrderCreate(data,filters) {
  const url = `/${API_VERSION_ONE}/orders/${data.id}/duplicate_order`;
  const method = 'POST'
  return {
    type: 'DUPLICATE_ORDER_CREATE',
    payload: handleAPI(url, filters, method, data)
  }
}

export function orderUpdate(data, filters){
  const url = `/${API_VERSION_ONE}/orders/${data.id}`;
  const method = "PUT"
  return {
    type: 'ORDER_UPDATE',
    payload: handleAPI(url,filters,method, {order: {...data}})
  }
}

export function orderDelete(id, filters) {
  const url = `/${API_VERSION_ONE}/orders/${id}/`;
  const method = 'DELETE';

  return {
    type: 'ORDER_DELETE',
    payload: new Promise((resolve, reject) => {
      handleAPI(url, filters, method)
        .then(response => {
          resolve({ id: id, ...response });
        })
        .catch(err => {
          reject(err);
        });
    })
  };
}

export function orderProcedureImpressionReorder(data) {
  return {
    type: 'ORDER_PROCEDURE_IMPRESSION_REORDER',
    data
  };
}

export function orderEdit(id, data) {
  return {
    type: 'ORDER_EDIT',
    id,
    data
  };
}

export function orderEditCancel() {
  return {
    type: 'ORDER_EDIT_CANCEL'
  };
}

export function orderReset() {
  return {
    type: 'ORDER_RESET'
  };
}

export function orderCloseModal(val) {
  return {
    type: 'ORDER_CLOSE_MODAL',
    payload: val
  };
}