import {handleAPI, API_VERSION_ONE} from '../../utils/apiUtils';

export function notificationsLoad(filters){
  const url = `/${API_VERSION_ONE}/notifications/`
  const method = "GET"
  return {
    type: 'NOTIFICATIONS_LOAD',
    payload: handleAPI(url, filters, method)
  };
}

export function notificationCreate(data) {
  return {
    type: 'NOTIFICATION_CREATE_SUCCESS',
    payload: data
  };
}

export function notificationFilterChange(filters) {
  return {
    type: 'NOTIFICATION_FILTER_CHANGE',
    filters,
  };
}

export function notificationUpdate(data) {
  const url = `/${API_VERSION_ONE}/notifications/${data.id}`
  const method = "PATCH"
  return {
    type: 'NOTIFICATION_READ',
    payload: handleAPI(url, data, method)
  };
}