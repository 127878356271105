import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import ProcedureImpressionPlanForm from '../../../../itrust_common/components/authorised/examFormPartial/ProcedurePlanForm';
import { dndReorder } from '../../../../itrust_common/components/HelperFunctions';
import { positionsUpdate } from '../../../../itrust_common/redux/actions/positionableActions';
import { examProcedureImpressionReorder } from '../../../../redux/actions/exam/examActions';

class ExamProcedureImpressionPlanForm extends Component {
  componentWillUnmount(){
    if(this.props.dirty){
      this.props.handleSubmit();
    }
  } 

  onDragEnd(dnd, klass, type){
    var response = dndReorder(dnd, [...this.props[type+"_attributes"]])
    this.props.dispatch(examProcedureImpressionReorder({[type+"_attributes"]: response["records"]}))
    this.props.dispatch(positionsUpdate({klass: klass, data: response["positionableObj"]}))
  }
  
  render(){
    const { template, exam, submit } = this.props;
    return (
      <form>
        <ProcedureImpressionPlanForm pprops={this.props} submit={submit} onDragEnd={this.onDragEnd.bind(this)} exam={exam} template={template} isTemplate={false}/>
      </form>
    )
  }
}

ExamProcedureImpressionPlanForm = reduxForm({
  form: 'procedureImpressionPlanForm',
  enableReinitialize: true
})(ExamProcedureImpressionPlanForm)

ExamProcedureImpressionPlanForm = connect(state => {
  const {exam: { exam } } = state
  return {
    examId: exam.id,
    impressions_attributes: exam.impressions_attributes,
    procedures_attributes: exam.procedures_attributes,
    initialValues: {
      id: exam.id,
      impressions_attributes: exam.impressions_attributes,
      procedures_attributes: exam.procedures_attributes
    }
  }
})(ExamProcedureImpressionPlanForm)

export default ExamProcedureImpressionPlanForm;