import {handleAPI, API_VERSION_ONE} from '../../../utils/apiUtils';

export function previousExamLoad(id, filters){
  const url = `/${API_VERSION_ONE}/examinations/${id}`;
  const method = 'GET'

  return {
    type: 'PREVIOUS_EXAM_LOAD',
    payload: handleAPI(url,filters,method)
  };
}

export function previousExamEdit(id, examData) {
  return {
    type: 'PREVIOUS_EXAM_EDIT',
    id,
    examData
  };
}

export function previousExamCancel() {
  return {
    type: 'EXAM_EDIT_CANCEL'
  };
}


export function examImportDeviceData(data, filters) {
  const url = `/${API_VERSION_ONE}/examinations/${data.id}/import_device_data`;
  const method = 'PUT'
  return {
    type: 'EXAM_UPDATE',
    payload: handleAPI(url, filters, method, { exam: { ...data } })

  };
}

export function resetPreviousExam() {
  return {
    type: 'RESET_PREVIOUS_EXAM',
  };
}
