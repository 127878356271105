import Toastr from "../../itrust_common/components/Toastr"

const DEFAULT_STATE = {
  staffs: [],
  store: {},
  saving: false,
}
let changes = null;
export default function currentStoreReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  switch(action.type){
    case 'STORE_STAFFS_LOAD_PENDING':
      return {...state, saving: true};

    case 'STORE_STAFFS_LOAD_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case 'STORE_STAFFS_LOAD_FULFILLED':
      changes = {
        ...response,
        saving: false
      };
      return {...state, ...changes};

    case 'CURRENT_STORE_LOAD_FULFILLED':
      changes = {
        store: response.store
      };
      localStorage.setItem('StoreID', response.store.id);
      localStorage.setItem('StoreTimezone', response.store.timezone);
      return { ...state, ...changes };

    case 'CURRENT_STORE_ALL':
      changes = {
        store: {}
      };
      localStorage.removeItem('StoreID');
      localStorage.removeItem('StoreTimezone');
      return { ...state, ...changes };

    default:
      return state;
  }
}
