import { combineReducers } from 'redux';

import { reducer as formReducer } from 'redux-form';
import itrustCommonReducer from '../itrust_common/redux/reducers';
import organisationPaymentReducer from './reducers/organisationPayment';
import patientReducer from './reducers/patient';
import publicReducer from './reducers/public';
import examReducer from './reducers/exam';
import orderReducer from './reducers/order';
import paymentReportReducer from './reducers/paymentReport'
import storeSettingsReducer from './reducers/storeSettings'
import appointmentReducer from './reducers/appointmentReducer';
import allDayNoteReducer from './reducers/allDayNoteReducer';

import organisationReducer from './reducers/organisationReducer';
import staffReducer from './reducers/staffReducer';
import storeReducer from './reducers/storeReducer';
import staffStoreReducer from './reducers/staffStoreReducer';
import permissionReducer from './reducers/permissionReducer';
import recurringScheduleReducer from './reducers/recurringScheduleReducer';
import anonymousRecurringScheduleReducer from './reducers/anonymousRecurringScheduleReducer';
import perDayScheduleReducer from './reducers/perDayScheduleReducer';
import anonymousPerDayScheduleReducer from './reducers/anonymousPerDayScheduleReducer';
import currentStoreReducer from './reducers/currentStoreReducer';
import referralReducer from './reducers/referralReducer';
import medicalRxReducer from './reducers/medicalRxReducer';
import patientSurveyReducer from './reducers/campaignReducer';
import logsReducer from './reducers/logsReducer';
import scheduleJobLogReducer from './reducers/scheduleJobLogReducer';
import addOnsReducers from './reducers/addOns';
import onBoardingReducer from './reducers/onBoardingReducer';

const rootReducer = combineReducers({
  organisation: organisationReducer,
  staff: staffReducer,
  store: storeReducer,
  staffStore: staffStoreReducer,
  permission: permissionReducer,
  perDaySchedule: perDayScheduleReducer,
  recurringSchedule: recurringScheduleReducer,
  anonymousRecurringSchedule: anonymousRecurringScheduleReducer,
  anonymousPerDaySchedule: anonymousPerDayScheduleReducer,
  appointment: appointmentReducer,
  allDayNote: allDayNoteReducer,
  currentStore: currentStoreReducer,
  referral: referralReducer,
  medicalRx: medicalRxReducer,
  patientSurvey: patientSurveyReducer,
  logs: logsReducer,
  scheduleJobLog: scheduleJobLogReducer,
  onBoarding: onBoardingReducer,
  ...itrustCommonReducer,
  ...organisationPaymentReducer,
  ...patientReducer,
  ...publicReducer,
  ...examReducer,
  ...orderReducer,
  ...paymentReportReducer,
  ...storeSettingsReducer,
  ...addOnsReducers,
  form: formReducer,
});

export default rootReducer;