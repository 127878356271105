import {handleAPI, API_VERSION_ONE} from '../../../itrust_common/utils/apiUtils';

export function patientMessagesLoad(filters){
  const url = `/${API_VERSION_ONE}/add_ons/two_way_message/messaging`;
  const method = 'GET'

  return {
    type: 'PATIENT_MESSAGES_LOAD',
    payload: handleAPI(url, filters, method)
  };
}

export function patientMessageCreate(data, filters){
  const url = `/${API_VERSION_ONE}/add_ons/two_way_message/messaging`;

  const method= 'POST'
  return {
    type: 'PATIENT_MESSAGE_CREATE',
    payload: handleAPI(url,filters,method, data)
  }
}