import Toastr from "../../itrust_common/components/Toastr"
const DEFAULT_STATE = {
  email_logs: [],
  sms_logs: [],
  fax_logs: [],
  meta: { pagination: {} },
  saving: false,
  editing: false
}
let changes = null;
export default function logsReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  switch(action.type){
    case 'EMAIL_LOGS_LOAD_PENDING':
    case 'SMS_LOGS_LOAD_PENDING':
    case 'FAX_LOGS_LOAD_PENDING':
      return {...state, saving: true};

    case 'EMAIL_LOGS_LOAD_REJECTED':
    case 'SMS_LOGS_LOAD_REJECTED':
    case 'FAX_LOGS_LOAD_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case 'EMAIL_LOGS_LOAD_FULFILLED':
    case 'SMS_LOGS_LOAD_FULFILLED':
    case 'FAX_LOGS_LOAD_FULFILLED':
      changes = {
        ...response,
        saving: false
      };
      return {...state, ...changes};

    default:
      return state;
  }
}
