import Toastr from "../../../../itrust_common/components/Toastr";

const DEFAULT_STATE = {
  saving: false,
  payment_logs: [],
  yesterday_payment_logs: [],
  editing: {}
}

let changes = null;

export const itrustPayPaymentLogsReducer = (state = DEFAULT_STATE, action) => {
  const response = action.payload && (action.payload.data || action.payload.response)
  let paymentLogs = []
  let paymentData = {}
  let updatedRecord = {}
  switch (action.type) {

    case 'ITRUST_PAY_PAYMENT_LOGS_LOAD_PENDING':
    case 'ITRUST_PAY_PAYMENT_LOG_UPDATE_PENDING':
    case 'ITRUST_PAY_PAYMENT_SETTLE_PENDING':
      return { ...state, saving: true }

    case 'ITRUST_PAY_PAYMENT_LOGS_LOAD_REJECTED':
    case 'ITRUST_PAY_PAYMENT_LOG_UPDATE_REJECTED':
    case 'ITRUST_PAY_PAYMENT_SETTLE_REJECTED':
      Toastr.handleShow(false, response.data.error, response.data.status)
      return { ...state, saving: false }

    case 'ITRUST_PAY_PAYMENT_LOG_UPDATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      changes = { ...response, meta: { ...state.meta }, saving: false }
      return { ...state, ...changes }

    case 'ITRUST_PAY_PAYMENT_SETTLE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      paymentLogs = [...state.payment_logs];
      updatedRecord = response["add_ons/itrust_pay/log"];
      const isNewCreatedLog = paymentLogs.find(log => log.id == updatedRecord.id);
      if (isNewCreatedLog) {
        for (const [index, deviceLog] of paymentLogs.entries()) {
          if (deviceLog.id === updatedRecord.id) {
            paymentData = [
              ...paymentLogs.slice(0, index),
              updatedRecord,
              ...paymentLogs.slice(index + 1)
            ];
          }
        }
      }
      else {
        paymentData = [...paymentLogs, updatedRecord]
      }
      changes = { payment_logs: paymentData, saving: false, };
      return { ...state, ...changes };

    case 'ITRUST_PAY_PAYMENT_LOGS_LOAD_FULFILLED':
      let responseDataField = response.meta.message === "last_one_day_logs" ? 'yesterday_payment_logs' : 'payment_logs';
      changes = { [responseDataField]: [...response['add_ons/itrust_pay/logs']], meta: { ...response.meta }, saving: false }
      return { ...state, ...changes }

    case 'ITRUST_PAY_PAYMENT_LOG_EDIT':
      changes = { editing: { ...action.data } }
      return { ...state, ...changes }

    case 'ITRUST_PAY_PAYMENT_LOG_EDIT_CANCEL':
      changes = { editing: false }
      return { ...state, ...changes }

    default:
      return state;
  }
}
