import React, { useState } from 'react'
import { currentUserUpdate } from '../../../itrust_common/redux/actions/authActions';
import { unsplashPhotosLoad } from '../../../itrust_common/redux/actions/UnsplashActions';
import { connect } from 'react-redux';
import * as $ from 'jquery'
import { useDispatch } from 'react-redux';
import { Field, change, submit } from 'redux-form';
import { FileUpload } from '../../../itrust_common/components/form';

const blue = "#297ebb", green = "#68c031", yellow = "#efa03e", purple = "#a458ef", pink = "#ef39e5", light_blue = "#7acdef", light_yellow = "#efc348", black = "#000000", red = "#ff0000"

const colorPlates = [{ value: 'theme1', color: blue, name: 'Blue' }, { value: 'theme2', color: green, name: 'Green' }, { value: 'theme3', color: yellow, name: 'Orange' }, { value: 'theme4', color: purple, name: 'Purple' }, { value: 'theme5', color: pink, name: 'Pink' }, { value: 'theme6', color: light_blue, name: 'Light Blue' }, { value: 'theme7', color: light_yellow, name: 'Light Yellow' }, { value: 'theme8', color: black, name: 'Black' }, { value: 'theme9', color: red, name: 'Red' }];

function ThemePicker(props) {
  const { setIsReloadRequire, currentTheme, setCurrentTheme, preferences } = props
  const dispatch = useDispatch()

  const handleThemeChange = (themeName) => {
    setCurrentTheme(themeName)
    setIsReloadRequire(true)
  }

  const handleCurrentUserBackgroundUpdate = (data) => {
    dispatch(currentUserUpdate({ preferences: { ...preferences, background: { ...preferences.background, ...data } } }))
  }

  const handleBackgroundImgUpload = () => {
    dispatch(change('PreferencesForm', 'preferences[background][background_mode]', 'upload'))
    setTimeout(() => dispatch(submit('PreferencesForm')), 700);
  }

  const handleBackground = (categoryName) => {
    dispatch(unsplashPhotosLoad({ query: 'sea', page: 1 }, categoryName))
    handleCurrentUserBackgroundUpdate({ background_mode: 'custom' })
    $('.customise-background').modal('show')
    $('#preferences-modal').modal('hide')
  }

  return (
    <div className='row w-100'>
      <h6 className='col-12 mb-4'>Theme Appearance</h6>
      {colorPlates.map(item =>
        <button
          type='button'
          onClick={() => handleThemeChange(item.value)}
          className={`btn rounded col-3 border text-left ml-4 mb-5 font-size-18 ${currentTheme === item.value && 'border-2 border-primary bg-primary-light '}`}
          key={item.color} >
          <i className="la la-adjust" style={{ color: `${item.color}` }} /> {item.name && item.name}
        </button>
      )}
      <h6 className='col-10 mt-4 border-top pt-6 pb-2 '>Customize Background</h6>
      <button
        type='button'
        className={`pointer ${preferences?.background?.background_mode === "time_of_day" && 'border-2 border-primary bg-primary-light'} btn border rounded col-3 m-3 `}
        onClick={() => handleCurrentUserBackgroundUpdate({ background_mode: 'time_of_day' })} >
        <i className="la la-sun-o mx-2" />Set to Time of Day
      </button>
      <button type='button' className={`pointer ${preferences?.background?.background_mode === "season" && 'border-2 border-primary bg-primary-light'} btn border rounded col-3 m-3`} onClick={() => handleCurrentUserBackgroundUpdate({ background_mode: 'season' })}>
        <i className="la la-umbrella mx-2" />Set to Season
      </button>
      <button type='button' className={`pointer ${preferences?.background?.background_mode === "custom" && 'border-2 border-primary bg-primary-light'} btn border rounded col-3 m-3`} onClick={() => handleBackground("user")}>
        <i className="la la-search mx-2" />Search Wallpaper
      </button>
      <div className='col-3 px-0 m-3'>
        <button type='button' className='btn border rounded w-100' disabled={preferences?.background?.background_mode === "default"} onClick={() => handleCurrentUserBackgroundUpdate({ background_mode: 'default' })}>
          <i className="la la-history mx-2" />Restore Default
        </button>
      </div>
      <Field name="background_image_attributes" wrapperClasses='m-3 col-4 p-0' imageClasses='preferences-uploaded-img' component={FileUpload} uploadClasses="preferences-upload-btn d-flex justify-content-between align-items-center" onChangeHook={handleBackgroundImgUpload} />
    </div>
  )
}

const mapStateToProps = state => {
  const { auth: { user: { preferences } } } = state
  return {
    preferences,
  }
}
export default connect(mapStateToProps)(ThemePicker);
