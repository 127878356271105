import React, { Component } from 'react';
import ReactPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { connect } from 'react-redux';
import { CountriesStates } from '../CountriesStates';
import _ from 'lodash';

class PhoneInput extends Component {

  render() {
    const {
      props,
      props: { input, disabled, wrapperClasses, label, orgCountry, anonymousOrgCountry },
      props: { meta: { touched, error, warning } }
    } = this;
    const orgCountryCode = _.find(CountriesStates, { countryName: orgCountry ?? anonymousOrgCountry })
    return (
      <div className={`${disabled && 'disabled'} ${wrapperClasses}`}>
        {label !== undefined && <label>{props.label}</label>}
        <ReactPhoneInput
          {...input}
          enableSearchField={true}
          autoFormat={false}
          country={orgCountryCode?.countryShortCode.toLowerCase() ?? 'us'}
          preferredCountries={['us', 'ca', 'in']}
          disabled={props.disabled || false}
          countryCodeEditable={false}
          disableCountryGuess={true}
        />
        {touched &&
          ((error && <span className="error-msg text-danger">{error}</span>) ||
            (warning && <span>{warning}</span>))}
        {touched && (error && <i className="la la-exclamation-circle text-danger error-icon-label"></i>)}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { organisation: { organisation }, publicAnonymousSetting } = state
  return {
    orgCountry: organisation.address_attributes?.country,
    anonymousOrgCountry: publicAnonymousSetting?.anonymous_calendar_setting?.organisation_country
  }
}

export default connect(mapStateToProps)(PhoneInput)