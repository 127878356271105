import React, {Component} from 'react';
import VisualAcuity from './refractionContactLens/visualAcuity'
import Refraction from './refractionContactLens/refraction'
import ContactLens from './refractionContactLens/contactLens'

class RefractionContactLensForm extends Component{
  render(){
    return (
      <div className="refraction-contact-lens-form">
        <VisualAcuity {...this.props}/>
        <Refraction {...this.props}/>
        <ContactLens {...this.props}/>
      </div>
    );
  }
}

export default RefractionContactLensForm;