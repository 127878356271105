import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { whatsAppEdit } from '../../../../../redux/actions/addOns/whatsApp/whatsAppActions'
import { callPopUpEdit, voipInfoUpdate } from '../../../../../redux/actions/addOns/voip/voipActions'

export default function PatientAddOnsHeader(props) {
  const { minimizeView, type, callPopUpStatus, addOnsConfig, hideMinimizeBtn } = props
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      handleMinimizeAddOn(false)
    }
  }, [])

  const handleMinimizeAddOn = (val) => {
    switch (type) {
      case 'whatsapp':
        dispatch(whatsAppEdit({ minimizeView: val }))
        return;
      default:
        dispatch(voipInfoUpdate({ minimizeView: val }))
        return;
    }
  }

  const handleCloseAddOn = () => {
    switch (type) {
      case 'whatsapp':
        dispatch(whatsAppEdit({ patientProfile: false, minimizeView: false }))
        return;
      default:
        dispatch(callPopUpEdit(false))
        dispatch(voipInfoUpdate({ minimizeView: false }))
        return;
    }
  }

  return (
    <>
      {(!hideMinimizeBtn) && <>
        {minimizeView ?
          <span onClick={() => handleMinimizeAddOn(false)}>
            <i className="las la-plus-circle font-size-20 text-primary px-2 pointer"></i>
          </span> :
          <span onClick={() => handleMinimizeAddOn(true)}>
            <i className="las la-minus-circle font-size-20 text-primary px-2 pointer"></i>
          </span>
        }
      </>}
      <span onClick={handleCloseAddOn}>
        <i className="las la-times-circle font-size-20 text-danger px-3 pointer"></i>
      </span>
    </>
  )
}