import Toastr from "../../itrust_common/components/Toastr"
const DEFAULT_STATE = {
  anonymous_calendar_per_day_schedules: [],
  anonymous_calendar_per_day_schedule: {},
  meta: { pagination: {} },
  saving: false
}
let changes = null;
export default function perDayScheduleReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let perDaySchedules = [];
  let perDayScheduleData = [];
  let updatedRecord = {};
  switch(action.type){
    case 'ANONYMOUS_CALENDAR_PER_DAY_SCHEDULES_LOAD_PENDING':
    case 'ANONYMOUS_CALENDAR_PER_DAY_SCHEDULE_LOAD_PENDING':
      return {...state, saving: true};

    case 'ANONYMOUS_CALENDAR_PER_DAY_SCHEDULE_CREATE_PENDING':
    case 'ANONYMOUS_CALENDAR_PER_DAY_SCHEDULE_UPDATE_PENDING':
      return {...state, saving: true};

    case 'ANONYMOUS_CALENDAR_PER_DAY_SCHEDULES_LOAD_REJECTED':
    case 'ANONYMOUS_CALENDAR_PER_DAY_SCHEDULE_LOAD_REJECTED':
    case 'ANONYMOUS_CALENDAR_PER_DAY_SCHEDULE_CREATE_REJECTED':
    case 'ANONYMOUS_CALENDAR_PER_DAY_SCHEDULE_UPDATE_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case 'ANONYMOUS_CALENDAR_PER_DAY_SCHEDULES_LOAD_FULFILLED':
      changes = {
        ...response,
        saving: false,
        editing: false
      };
      return {...state, ...changes};
    
    case 'ANONYMOUS_CALENDAR_PER_DAY_SCHEDULE_LOAD_FULFILLED':
      changes = {
        anonymous_calendar_per_day_schedule: response.anonymous_calendar_per_day_schedule,
        saving: false
      }
      return {...state,...changes};
    
    case 'ANONYMOUS_CALENDAR_PER_DAY_SCHEDULE_CREATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      perDaySchedules = [...state.anonymous_calendar_per_day_schedules]
      let newRecord = response.anonymous_calendar_per_day_schedule
      changes = {
        anonymous_calendar_per_day_schedules: [...perDaySchedules, newRecord],
        editing: false,
        saving: false
      }
      return {...state, ...changes};

    case 'ANONYMOUS_CALENDAR_PER_DAY_SCHEDULE_UPDATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      perDaySchedules = [...state.anonymous_calendar_per_day_schedules];
      updatedRecord = response.anonymous_calendar_per_day_schedule;
      for (const [index, perDaySchedule] of perDaySchedules.entries()) {
        if (perDaySchedule.id === updatedRecord.id) {
          perDayScheduleData = [
            ...perDaySchedules.slice(0, index),
            updatedRecord,
            ...perDaySchedules.slice(index + 1)
          ];
        }
      }
      changes = {
        anonymous_calendar_per_day_schedules: perDayScheduleData,
        saving: false
      };
      return { ...state, ...changes };

    case 'ANONYMOUS_CALENDAR_PER_DAY_SCHEDULE_DELETE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      perDaySchedules = [...state.anonymous_calendar_per_day_schedules];
      for (const [index, perDaySchedule] of perDaySchedules.entries()) {
        if (perDaySchedule.id === response.anonymous_calendar_per_day_schedule.id) {
          perDaySchedules.splice(index, 1);
        }
      }
      return {
        ...state,
        anonymous_calendar_per_day_schedules: perDaySchedules,
        error: null,
        editing: false
      };

    case 'ANONYMOUS_CALENDAR_PER_DAY_SCHEDULE_EDIT':
      changes = {
        editing: {
          id: action.id,
        },
        anonymous_calendar_per_day_schedule: { address_attributes: {country: "United States"} }
      };
      return { ...state, ...changes };

    case 'ANONYMOUS_CALENDAR_PER_DAY_SCHEDULE_EDIT_CANCEL':
      changes = {
        editing: false
      };
      return { ...state, ...changes };


    default:
      return state;
  }
}