import React from 'react'
import VoipNotificationList from '../addOns/voip/VoipNotificationList'
import WhatsAppNotificationList from '../addOns/whatsApp/WhatsAppNotificationList'
import TwoWayNotificationList from '../addOns/twoWayMessage/TwoWayNotificationList'
import { connect } from 'react-redux'
import NotificationCable from './NotificationCable'

function Notification(props) {
  const { voipConfig, whatsAppConfig, twoWayMessageConfig } = props

  return (
    <div className='d-flex mx-5'>
      <NotificationCable />
      {voipConfig?.visible && <VoipNotificationList />}
      {twoWayMessageConfig?.visible && <TwoWayNotificationList />}
      {whatsAppConfig?.visible && <WhatsAppNotificationList />}
    </div>
  )
}

const mapStateToProps = (state) => {
  const { organisation: { organisation } } = state

  return {
    voipConfig: organisation.add_ons_config?.type?.voip,
    whatsAppConfig: organisation.add_ons_config?.type?.whatsapp,
    twoWayMessageConfig: organisation?.add_ons_config?.type?.two_way_message,
  }
}

export default connect(mapStateToProps)(Notification)
