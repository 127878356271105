import {handleAPI, API_VERSION_ONE} from '../../itrust_common/utils/apiUtils';

export function medicalRxsLoad(patient_id, filters){
  const url = `/${API_VERSION_ONE}/patients/${patient_id}/medical_rxes`;
  const method = 'GET'
  return {
    type: 'MEDICALRXS_LOAD',
    payload: handleAPI(url, filters, method)
  };
}

export function medicalRxLoad(patient_id, id, filters){
  const url = `/${API_VERSION_ONE}/patients/${patient_id}/medical_rxes/${id}`;
  const method = 'GET'

  return {
    type: 'MEDICALRX_LOAD',
    payload: handleAPI(url,filters,method)
  };
}

export function medicalRxCreate(patient_id, data, filters){
  const url = `/${API_VERSION_ONE}/patients/${patient_id}/medical_rxes`;
  const method= 'POST'
  return {
    type: 'MEDICALRX_CREATE',
    payload: handleAPI(url,filters,method, {medical_rx: {...data}})
  }
}

export function medicalRxUpdate(patient_id, data, filters){
  const url = `/${API_VERSION_ONE}/patients/${patient_id}/medical_rxes/${data.id}`;
  const method = "PUT"
  return {
    type: 'MEDICALRX_UPDATE',
    payload: handleAPI(url,filters,method, {medical_rx: {...data}})
  }
}

export function medicalRxDelete(patient_id, id, filters) {
  const url = `/${API_VERSION_ONE}/patients/${patient_id}/medical_rxes/${id}`;
  const method = 'DELETE';
  return {
    type: 'MEDICALRX_DELETE',
    payload: new Promise((resolve, reject) => {
      handleAPI(url, filters, method)
        .then(response => {
          resolve({ id: id, ...response });
        })
        .catch(err => {
          reject(err);
        });
    })
  };
}

export function medicalRxEdit(id) {
  return {
    type: 'MEDICALRX_EDIT',
    id,
  };
}

export function medicalRxEditCancel() {
  return {
    type: 'MEDICALRX_EDIT_CANCEL'
  };
}

export function medicalRxSecureFax(patient_id, data){
  const url = `/${API_VERSION_ONE}/patients/${patient_id}/medical_rxes/${data.id}/fax_pdf`;
  const method = 'GET'
  return {
    type: 'MEDICALRX_SECURE_FAX',
    payload: handleAPI(url, data, method)
  };
}