import Toastr from "../../../components/Toastr"
const parent = 'SETTING_'
const DEFAULT_STATE = {
  diseases: [],
  disease: {},
  meta: {pagination: {}},
  saving: false
}
let changes = null;
export default function diseaseReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let diseases = [];
  let diseaseData = [];
  let updatedRecord = {};
  switch(action.type){
    case `${parent}DISEASES_LOAD_PENDING`:
    case `${parent}DISEASE_LOAD_PENDING`:
      return {...state, saving: false};

    case `${parent}DISEASE_CREATE_PENDING`:
    case `${parent}DISEASE_UPDATE_PENDING`:
      return {...state, saving: true};

    case `${parent}DISEASES_LOAD_REJECTED`:
    case `${parent}DISEASE_LOAD_REJECTED`:
    case `${parent}DISEASE_CREATE_REJECTED`:
    case `${parent}DISEASE_UPDATE_REJECTED`:
    case `${parent}DISEASE_DELETE_REJECTED`:
      Toastr.handleShow(response.data.success, response.data.error, response.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case `${parent}DISEASES_LOAD_FULFILLED`:
      changes = {
        ...response,
        saving: false
      };
      return {...state, ...changes};
    
    case `${parent}DISEASE_LOAD_FULFILLED`:
      changes = {
        disease: response.disease,
        saving: false
      }
      return {...state,...changes};
    
    case `${parent}DISEASE_CREATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      diseases = [...state.diseases]
      let newRecord = response.disease
      changes = {
        diseases: [...diseases, newRecord],
        editing: false,
        saving: false
      }
      return {...state, ...changes};

    case `${parent}DISEASE_UPDATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      diseases = [...state.diseases];
      updatedRecord = response.disease;
      for (const [index, disease] of diseases.entries()) {
        if (disease.id === updatedRecord.id) {
          diseaseData = [
            ...diseases.slice(0, index),
            updatedRecord,
            ...diseases.slice(index + 1)
          ];
        }
      }
      changes = {
        diseases: diseaseData,
        editing: false,
        saving: false
      };
      return { ...state, ...changes };

    case `${parent}DISEASE_DELETE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      diseases = [...state.diseases];
      for (const [index, disease] of diseases.entries()) {
        if (disease.id === response.disease.id) {
          diseases.splice(index, 1);
        }
      }
      return {
        ...state,
        diseases: diseases,
        error: null
      };

    case `${parent}DISEASE_EDIT`:
      changes = {
        editing: {
          id: action.id,
          diseaseType: action.diseaseType
        },
        disease: {  }
      };
      return { ...state, ...changes };

    case `${parent}DISEASE_EDIT_CANCEL`:
      changes = {
        editing: false
      };
      return { ...state, ...changes };

    default:
      return state;
  }
}