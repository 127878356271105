import React, { Component, useEffect } from 'react';
import { reduxForm, Field, FieldArray, change } from 'redux-form';
import { connect } from 'react-redux';
import { Input, FileUpload } from '../../../../itrust_common/components/form';
import Alert from '../../../../itrust_common/components/Alert';
let RenderDoc = props => {
  const { fields, submit, dispatch } = props

  const handleRemoveOrderDoc = (member) => {
    Alert.deleteConfirm("Are you sure? You want to delete this record.",
      (res) => {
        if (res) {
          dispatch(change("orderAttachedDocsForm", `${member}[_destroy]`, true)); 
          setTimeout(() => submit(), 500)
        }
      })
  }

  return (
    <div className="row bg-white">
      {fields.map((member, index, members) => {
        return (
          <div key={index} className="col-xl-3 col-md-6 mt-7" style={{ maxHeight: "20rem" }}>
            {index === 0 ?
              <Field name={`${member}[document_attributes]`} onChangeHook={() => { setTimeout(() => submit(), 1000) }} component={FileUpload} imageClasses="img-fluid" labelClasses="d-block" wrapperClasses="col-12 form-group" /> :
              <>
                {members.get(index).document_attributes &&
                  <div className="w-100">
                    <embed src={members.get(index).document_attributes?.file.url} className="pt-5 img-fluid" style={{ height: '12rem', objectFit: "fit" }}></embed>
                    <div className="col-12 my-5 px-0">
                      <div className="d-flex">
                        <Field name={`${member}[description]`} placeholder="Enter File description  ..." component={Input} onBlur={() => setTimeout(() => submit(), 500)} className="form-control" wrapperClasses="form-group mb-0 w-100" />
                        <div className="dropright d-inline-block float-right badge badge-pill badge-primary px-1 pt-3 ml-5">
                          <button type="button" className="btn p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="la la-ellipsis-v" />
                          </button>
                          <div className="dropdown-menu border-0 shadow-sm">
                            <ul className="list-unstyled px-5 mb-0">
                              <li className="py-3 pointer"><a className="text-dark d-block" target="_blank" href={members.get(index).document_attributes?.file.url}>View</a></li>
                              <li className="py-3 pointer" onClick={() => handleRemoveOrderDoc(member)}>Remove</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </>
            }
          </div>
        )
      })}
    </div>
  );
}

function AttachedDocsForm(props) {
  const { handleSubmit, submit, dispatch, order } = props;

  useEffect(() => {
    return () => {
      if (props.dirty) {
        !props.saving && this.props.handleSubmit();
      }
    }
  }, [])

  return (
    <form onSubmit={handleSubmit} className={`px-7 py-5 ${order.status !== "finalized" && " mt-6"}`}>
      <FieldArray name="patient_documents_attributes"
        props={{
          submit: submit,
          dispatch: dispatch
        }}
        component={RenderDoc}
      />
    </form>
  )
}

AttachedDocsForm = reduxForm({
  form: 'orderAttachedDocsForm',
  enableReinitialize: true,
})(AttachedDocsForm)


AttachedDocsForm = connect(state => {
  const { order } = state
  return {
    order: order.order,
    saving: order.saving,
    initialValues: {
      id: order.order.id,
      patient_documents_attributes: [{ patient_id: order.order.patient_id }, ...order.order.patient_documents_attributes],
    }
  }
})(AttachedDocsForm)

export default AttachedDocsForm;