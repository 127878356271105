import Toastr from "../../../components/Toastr"
const parent = 'SETTING_'

const DEFAULT_STATE = {
  patient_template: {},
  meta: {pagination: {}},
  saving: false
}

let changes = null;
export default function patientTemplateReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let updatedRecord = {};
  switch(action.type){
    case `${parent}PATIENT_TEMPLATE_LOAD_PENDING`:
      return {...state, saving: true};

    case `${parent}PATIENT_TEMPLATE_CREATE_PENDING`:
    case `${parent}PATIENT_TEMPLATE_UPDATE_PENDING`:
      return {...state, saving: true};

    case `${parent}PATIENT_TEMPLATE_LOAD_REJECTED`:
    case `${parent}PATIENT_TEMPLATE_CREATE_REJECTED`:
    case `${parent}PATIENT_TEMPLATE_UPDATE_REJECTED`:
      Toastr.handleShow(response.data.success, response.data.error, response.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    
    case `${parent}PATIENT_TEMPLATE_LOAD_FULFILLED`:
      changes = {
        patient_template: response.patient_template,
        saving: false
      }
      return {...state,...changes};

    case `${parent}PATIENT_TEMPLATE_UPDATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      updatedRecord = response.patient_template;
      changes = {
        patient_template: {...updatedRecord, patient_template_id: updatedRecord.id},
        editing: false,
        saving: false
      };
      return { ...state, ...changes };

    default:
      return state;
  }
}