import React, {Component} from 'react';
import {connect} from 'react-redux';
import { dataLoad } from '../../redux/actions/autoCompleteActions';
import { diseaseCreate } from '../../redux/actions/settingActions/diseaseActions';
import { medicationCreate } from '../../redux/actions/settingActions/medicationActions';


//import {filterChange, filterReset} from '../../redux/actions/filterActions';
var delayTimer;

class AutoComplete extends Component {
  constructor(props){
    super(props);
    this.state={data: [], search: ""};
  }

  handleSearch(value){
    const { klass, otherFilters } = this.props
    this.setState({search: value})
    var filters = {search: value, klass: klass, ...otherFilters}
if(klass){
  clearTimeout(delayTimer);
  delayTimer = setTimeout(function () {
    new Promise((resolve, reject) => {
      this.props.dispatch(dataLoad(filters))
        .then(response => {
          this.setState({ data: response.value.data.data });
        })
    }
    )
  }.bind(this), 500)
}
  }

  async handleAddNewSetting(){
    const { valueField, addNewSetting } = this.props
    var recordType = addNewSetting.split('family_').pop()
    if( recordType === "medical_history" || recordType ==="ocular_history" || recordType === "allergy" || recordType === "surgery" ){
      var res = await this.props.dispatch(diseaseCreate({disease_type: recordType, name: this.state.search.trim()}))
      this.handleSelect(res.value.data.disease[valueField])
    }else if(recordType === "medication"){
      var res = await this.props.dispatch(medicationCreate({ name: this.state.search.trim()}))
      this.handleSelect(res.value.data.medication[valueField])
    }
  }

  handleSelect(val){
    this.props.onSelect(val)
    this.setState({search: ""})
  }

  render(){
    const { placeholder, wrapperClasses, dropdownClasses, textField, valueField, addNewSetting } = this.props
    const {data} = this.state
    return(
      <div className={`exam-template ${wrapperClasses}`}>
          <div className="input-group">
            <input value={this.state.search} onChange={(search) => this.handleSearch(search.target.value)} type="text" className="form-control" placeholder={placeholder}/>
            {
              addNewSetting && data && data.filter(entry => entry[textField].trim().toLowerCase() === this.state.search.trim().toLowerCase()) == 0 && this.state.search != "" ?
              <div className="input-group-append" onClick={this.handleAddNewSetting.bind(this)}>
                <span className="input-group-text pointer position-static bg-primary text-white">Add</span>
              </div>
              :
              <div className="input-group-append">
                <span className="input-group-text search-button position-static bg-light"><i className="la la-search text-muted" /></span>
              </div>
            }
          </div>
          <ul className={`dropdown-menu list-unstyled px-5 bg-white w-100 ${dropdownClasses} ${data && data.length > 0 && this.state.search !=="" ? 'show' : ''}`}>
            {data?.map(obj => (
              <li key={`auto-complete ${valueField ? obj[valueField] : obj.id}`} className="pointer" onClick={this.handleSelect.bind(this, valueField ? obj[valueField] : obj)}>
                {obj[textField]}
              </li>
            ))}
          </ul>
      </div>
    );
  }
}

export default connect()(AutoComplete);
