import React, { useState, Fragment } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import * as $ from 'jquery'

export const FileCrop = (props) => {
  const { id, allFiles, setAllFiles, image, handleSubmit, label } = props
  const [cropData, setCropData] = useState("");
  const [cropper, setCropper] = useState();
  const [useCrop, setCrop] = useState(false);

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      setCropData(cropper.getCroppedCanvas().toDataURL());
    }
  };

  const handleRotate = () => {
    if (typeof cropper !== "undefined") {
      cropper.rotate(90)
    }
  };

  const handleCropReset = () => {
    setAllFiles([])
    setCropData("")
    setCrop(false)
    $(`#${id}`).modal('hide')
  }


  const handleCropDone = () => {
    if(cropData !== ""){
      allFiles[0].file = cropData
    }
    handleSubmit(allFiles[0]);
    handleCropReset()
  }


  return (
    <div className="modal fade" id={`${id}`} style={{zIndex: 999999}} data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" style={{minWidth: "auto"}} role="document">
        <div className="modal-content" style={{minHeight: "auto"}}>
          <div className="modal-header py-5">
            <h5 className="modal-title" id="exampleModalCenterTitle">{label}</h5>
            <div className="d-flex">
            { cropData === "" ?
                useCrop ?
                  <Fragment>
                    <button type="button" onClick={()=> setCrop(false)} className="btn btn-outline-primary mx-6">Cancel</button>
                    <button type="button" onClick={handleRotate} className="btn btn-primary">Rotate</button>
                    <button type="button" onClick={getCropData} className="btn btn-primary mx-6">Done</button>
                  </Fragment>
                  :
                  <Fragment>
                    <button type="button" onClick={()=> setCrop(true)} className="btn btn-outline-primary">Crop/Rotate</button>
                    <button type="button" className="btn btn-primary mx-6" onClick={handleCropDone}>Done</button>
                  </Fragment>
                :
                <Fragment>
                  <button type="button" onClick={()=> setCropData("")} className="btn btn-outline-primary mx-6">Re-crop/Rotate</button>
                  <button type="button" className="btn btn-primary" onClick={handleCropDone}>Done</button>
                </Fragment>
              }
              <button type="button" className="close py-5" onClick={() => handleCropReset()}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

          </div>
          <div className="modal-body">
          { cropData === "" ?
            useCrop ?
              <Cropper
                style={{ height: 400, width: "100%" }}
                initialAspectRatio={2}
                preview=""
                src={image}
                viewMode={0}
                guides={true}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                rotatable={true}
                autoCropArea={1}
                checkOrientation={true} // https://github.com/fengyuanchen/cropperjs/issues/671
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
              />
              :
              <img style={{ width: "100%", maxHeight: '400px', objectFit: 'contain' }} src={image} alt="original" />
            :
            <img style={{ width: "100%", maxHeight: '400px', objectFit: 'contain' }} src={cropData} alt="cropped" />
          }
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileCrop;
