import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import TourModules from './TourModules';
import TourProgressBar from './TourProgressBar';
import { onBoardingTourProgressInfoUpdate, onBoardingTourReset } from '../../../../redux/actions/onBoardingActions';
import { createDemoPatientForOnboarding } from '../onBoardingHelpers';
import { useOnboardingTourData } from '../OnBoardingCustomHooks';
import { useLocation } from 'react-router-dom';

function ProgressPopUp(props) {
  const { organisationStatus } = props
  const onBoardingTourData = useOnboardingTourData()
  const { onBoarding, onBoarding: { progressPopUpInfo: { moduleProgressHide } }, dispatch, onboardingGuide, userRole, userId } = onBoardingTourData
  const location = useLocation();

  useEffect(() => {
    // on Collapse popUp auto align fixes
    window.dispatchEvent(new Event('scroll'));
  }, [moduleProgressHide])

  useEffect(() => {
    // on Long scroll > scrolling height fixes
    window.scrollTo({ top: 0, left:0, behavior: 'smooth' });
  }, [location]);

  const handleTourEnd = () => {
    dispatch(onBoardingTourReset())
  }

  const handleProgressBarCollapse = () => {
    if (organisationStatus === 'trial' && !onBoarding.isTourActive) {
      createDemoPatientForOnboarding(dispatch, userId)
    }
    dispatch(onBoardingTourProgressInfoUpdate({ moduleProgressHide: !moduleProgressHide }))
  }

  return (
    <div className={`d-flex flex-column justify-content-end progress-bar-wrap ${moduleProgressHide && 'progress-bar-wrap-collapse'}`}>
      <div className={`bg-white border-radius-6 ${moduleProgressHide ? 'p-5' : 'p-6'}`}>
        <div className='d-flex justify-content-between align-items-center'>
          <h5 className='text-uppercase font-size-18'>Onboarding Checklist</h5>
          <span className='pointer' onClick={handleProgressBarCollapse}>
            {moduleProgressHide ?
              <i className="las la-expand font-size-20"></i> :
              <i className="las la-compress font-size-20"></i>
            }
          </span>
        </div>
        <TourProgressBar onboardingGuide={onboardingGuide} userRole={userRole} organisationStatus={organisationStatus} moduleProgressHide={moduleProgressHide} />
        {!moduleProgressHide && <>
          <TourModules userRole={userRole} onboardingGuide={onboardingGuide} dispatch={dispatch} onBoarding={onBoarding} organisationStatus={organisationStatus} />
          {organisationStatus !== 'trial' && <div className='d-flex justify-content-end mt-4'>
            <button className='btn btn-outline-primary btn-sm' onClick={handleTourEnd}>End Tour</button>
          </div>}
        </>}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  const { organisation: { organisation } } = state
  return {
    organisationStatus: organisation.status,
  }
}

export default connect(mapStateToProps)(ProgressPopUp)