import React from 'react'
import { claimFormSync } from '../../../../redux/actions/order/claimFormActions'
import { connect, useDispatch } from 'react-redux'
import { rpaClaimFormCreate, rpaClaimFormLoad } from '../../../../redux/actions/addOns/rpa/rpaClaimFormAction'
import { getInsuranceFromRPAServices } from '../../../../containers/authorised/addOns/other/AddOnsHelperFunctions'

function ClaimFormSync(props) {
  const { order, services, label, handleAfterSyncSuccessfully } = props
  const dispatch = useDispatch()

  const handleClaimFormSync = async () => {
    if (order.rpa_claim_exist) {
      const insurance = getInsuranceFromRPAServices(services, order.patient_insurance_attributes?.insurance_name)
      let rpaClaimGenerateData = { sync_claim_form: true, add_ons_id: insurance.id, orders_order_id: order.id, patient_id: order.patient_id, insurance_type: order.patient_insurance_attributes?.insurance_type, store_id: order.store_id, insurance_payer: order.patient_insurance_attributes?.insurance_name, service: insurance.config.service, other_details: { resource: "generate_claim" } }
      await dispatch(rpaClaimFormCreate(rpaClaimGenerateData))
      await dispatch(rpaClaimFormLoad(order.id))
      handleAfterSyncSuccessfully && handleAfterSyncSuccessfully()
    }
    else {
      dispatch(claimFormSync(order.id))
    }
  }

  return (
    <>
      <button type="button" className="btn btn-outline-primary ml-lg-7 ml-md-5 mx-md-4 my-md-2 mx-lg-0 my-lg-0" onClick={handleClaimFormSync}>{label ?? 'Sync Data'}</button>
    </>
  )
}

const mapStateToProps = (state) => {
  const { order: { order }, rpa: { services } } = state
  return {
    order,
    services
  }
}

export default connect(mapStateToProps)(ClaimFormSync)