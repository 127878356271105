import React, { useState } from "react";
import { Field, reduxForm, reset } from "redux-form";
import { createTicket } from "../../utils/api/tickets";
import recordingClipIcon from "../../images/recordingClipIcon.svg";
import captureClipIcon from "../../images/captureClipIcon.svg";
import { required } from "../../../itrust_common/components/form/FormValidations";
import { Input, Textarea } from "../../../itrust_common/components/form";
import { useDispatch } from "react-redux";

let CreateTicket = (props) => {
  const [loading, setLoading] = useState(false);
  const { attachmentData, setAttachmentData, setView, handleSubmit } = props;
  const dispatch = useDispatch();
  let attachment;
  if (attachmentData) {
    const attachedDataBase = attachmentData.attachmentBase.split(",");
    let name;
    if (attachmentData.type === "video") {
      name = attachmentData.filename + ".webm";
    } else {
      name = attachmentData.filename + ".png";
    }
    attachment = [
      {
        filename: name,
        data: attachedDataBase[1],
        "mime-type":
          attachmentData.type === "video" ? "video/webm" : "image/png",
      },
    ];
  }
  let handleCreateTicket = async (ticket) => {
    setLoading(true);
    ticket.article.type = "note";
    ticket.article.content_type = "text/html";
    ticket.article.internal = false;
    ticket.article.time_unit = "25";
    ticket.article.subject = ticket.title;
    ticket.group = "Users";
    ticket.group_id = 1;
    if (attachmentData) {
      ticket.article.attachments = attachment;
    }
    const response = await createTicket(ticket);
    if (response.ok) {
      setLoading(false);
      dispatch(reset("createTicket"));
      setView("Tickets");
      setAttachmentData();
    }
  };
  return (
    <form onSubmit={handleSubmit(handleCreateTicket)}>
      <div className="bg-white w-26 box-border ticket-form position-absolute">
        <div className=" overflow-auto h-82 hide-scroller">
          <>
            <div className="mb-2 px-4 py-2">
              <label className="d-block text-left text-gray-700 text-sm">
                Title
              </label>
              <Field
                component={Input}
                name="title"
                validate={required}
                className="form-control border border-1 border-gray py-0"
                wrapperClasses="col-12 form-group px-0"
              />
            </div>
            {attachmentData && (
              <div className="mb-2 px-4 py-1">
                <p className="d-block text-left text-gray-700 text-sm mb-2">
                  Attachment
                </p>
                <p className="text-xs text-left text-slate-400 d-flex">
                  {" "}
                  <img
                    src={
                      attachmentData.type === "image"
                        ? captureClipIcon
                        : recordingClipIcon
                    }
                    alt="clip-icon"
                    className="mr-1"
                  />
                  {attachmentData.filename}
                  {attachmentData.type === "image" ? ".png" : ".webm"}
                </p>
              </div>
            )}
            <div className=" px-4 py-2">
              <label className="d-block  text-left text-gray-700 text-sm">
                Your Message
              </label>
              <Field
                component={Textarea}
                rows={attachment ? "6" : "8"}
                className="form-control border border-1 border-gray py-0"
                wrapperClasses="col-12 form-group px-0"
                name="article.body"
                validate={required}
              />
            </div>
            <div className="d-flex justify-content-end mr-4 mb-4">
              <button className="bg-primary text-white px-5 py-1 rounded-md btn-border">
                {loading && (
                  <div
                    className="spinner-border text-light spinner-border-sm"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
                Submit
              </button>
            </div>
          </>
        </div>
      </div>
    </form>
  );
};

CreateTicket = reduxForm({
  form: "createTicket",
  enableReinitialize: false,
})(CreateTicket);

export default CreateTicket;
