import Toastr from "../../../itrust_common/components/Toastr"
const DEFAULT_STATE = {
  patients:[],
  reports: {},
  meta: { pagination: {} },
  saving: false,
  editing: false
}
let changes = null;
export default function patientReportReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  switch(action.type){
    case 'PATIENT_REPORTS_LOAD_PENDING':
    case 'PATIENT_REPORTS_EMAIL_PENDING':
      return {...state, saving: true};

    case 'PATIENT_REPORTS_LOAD_REJECTED':
    case 'PATIENT_REPORTS_EMAIL_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case 'PATIENT_REPORTS_LOAD_FULFILLED':
      changes = {
        reports: response.reports,
        meta: response.meta,
        saving: false
      };
      return {...state, ...changes};
    
    case 'PATIENT_REPORT_EMAIL_FULFILLED':
      Toastr.handleShow(true, response.message, 201)
      return {...state, ...changes};
    
    case 'PATIENT_LOAD_FULFILLED':
      changes = {
        patient: response.patient,
        saving: false
      }
      return {...state,...changes};
    
    case 'PATIENT_REPORT_EDIT':
      changes = {
        editing: {
          id: action.id,
        },
        patient_survey: {}
      };
      return { ...state, ...changes };

    case 'PATIENT_REPORT_EDIT_CANCEL':
      changes = {
        editing: false,
      };
      return { ...state, ...changes };
      
    default:
      return state;
  }
}