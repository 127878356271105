export function filterChange(filters) {
  return {
    type: 'FILTER_CHANGE',
    filters,
  };
}

export function filterReset() {
  return {
    type: 'FILTER_RESET',
  };
}

export function patientPaymentRedirect(data) {
  return {
    type: 'PATIENT_PAYMENT_REDIRECT',
    payload: data
  };
}

export function dateRangePickerDropdown(data) {
  return {
    type: 'DATE_RANGE_PCIKER',
    payload: data
  };
}
