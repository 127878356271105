import React from 'react';
import {FormTemplateField} from '../../../others/'
import {Input, Textarea, Dropdown} from '../../../form'

let AmnioticMembrane = props => {
  const { baseName, baseFieldValues, isTemplate, isOpen } = props;
  return (
    <div className="card-body">
      <div className="row">
        
        <FormTemplateField
          isTemplate={isTemplate}
          name={`${baseName}[fields][eye]`}
          baseName={`${baseName}[fields][eye]`}
          fieldValues={baseFieldValues['eye']}
          component={Dropdown} 
          isOpen={isOpen}
          data={[{key: 'od', value: 'OD'}, {key: 'os', value: 'OS'}, {key: 'ou', value: 'OU'}]} 
          textField="value" 
          valueField="key" 
          wrapperClasses="col-6 form-group label-icons"
         />
        
        <FormTemplateField
          isTemplate={isTemplate}
          name={`${baseName}[fields][lid_taped]`}
          baseName={`${baseName}[fields][lid_taped]`}
          fieldValues={baseFieldValues['lid_taped']}
          component={Dropdown} 
          isOpen={isOpen}
          data={[{key: 'yes', value: 'Yes'}, {key: 'no', value: 'No'}]} 
          textField="value" 
          valueField="key" 
          wrapperClasses="col-6 form-group label-icons"
         />

        <FormTemplateField
          isTemplate={isTemplate}
          name={`${baseName}[fields][insertion_method]`}
          baseName={`${baseName}[fields][insertion_method]`}
          fieldValues={baseFieldValues['insertion_method']}
          component={Input}
          isOpen={isOpen}
          className="form-control"
          wrapperClasses="col-6 form-group label-icons"
         />
        
        <FormTemplateField
          isTemplate={isTemplate}
          name={`${baseName}[fields][am_type]`}
          baseName={`${baseName}[fields][am_type]`}
          fieldValues={baseFieldValues['am_type']}
          component={Input}
          isOpen={isOpen}
          className="form-control"
          wrapperClasses="col-6 form-group label-icons"
         />
        
        <FormTemplateField
          isTemplate={isTemplate}
          name={`${baseName}[fields][post_op_medication]`}
          baseName={`${baseName}[fields][post_op_medication]`}
          fieldValues={baseFieldValues['post_op_medication']}
          component={Input}
          isOpen={isOpen}
          placeholder="Type to search"
          className="form-control"
          wrapperClasses="col-6 form-group label-icons"
         />
        
        <FormTemplateField
          isTemplate={isTemplate}
          name={`${baseName}[fields][comments]`}
          baseName={`${baseName}[fields][comments]`}
          fieldValues={baseFieldValues['comments']}
          component={Textarea}
          isOpen={isOpen}
          rows={3}
          className="form-control"
          wrapperClasses="col-12 form-group label-icons"
         />
      </div>
    </div>
  )
}
export default AmnioticMembrane;