import {handleAPI, API_VERSION_ONE} from '../../../itrust_common/utils/apiUtils';

export function patientsLoad(filters){
  const url = `/${API_VERSION_ONE}/patients/`;
  const method = 'GET'

  return {
    type: 'PATIENTS_LOAD',
    payload: handleAPI(url, filters, method)
  };
}

export function patientLoad(id, filters){
  const url = `/${API_VERSION_ONE}/patients/${id}`;
  const method = 'GET'

  return {
    type: 'PATIENT_LOAD',
    payload: handleAPI(url,filters,method)
  };
}

export function patientCreate(data, filters){
  const url = `/${API_VERSION_ONE}/patients/`;
  const method= 'POST'
  return {
    type: 'PATIENT_CREATE',
    payload: handleAPI(url,filters,method, {patient: {...data}})
  }
}

export function patientUpdate(data, filters){
  const url = `/${API_VERSION_ONE}/patients/${data.id}`;
  const method = "PUT"
  return {
    type: 'PATIENT_UPDATE',
    payload: handleAPI(url,filters,method, {patient: {...data}})
  }
}

export function patientDelete(id, filters) {
  const url = `/${API_VERSION_ONE}/patients/${id}/`;
  const method = 'DELETE';

  return {
    type: 'PATIENT_DELETE',
    payload: new Promise((resolve, reject) => {
      handleAPI(url, filters, method)
        .then(response => {
          resolve({ id: id, ...response });
        })
        .catch(err => {
          reject(err);
        });
    })
  };
}

export function patientMerge(data){
  const url = `/${API_VERSION_ONE}/patients/${data.id}/merge`;
  const method= 'PUT'
  return {
    type: 'PATIENT_MERGE',
    payload: handleAPI(url,{},method, data)
  }
}

export function patientDeletePaymentMethod(data, filters) {
  const url = `/${API_VERSION_ONE}/patients/${data.patient_id}/delete_payment_method/${data.payment_method_id}`;
  const method = 'DELETE'
  return {
    type: 'PATIENT_PAYMENT_METHOD_DELETE',
    payload: handleAPI(url, filters, method)
  }
}

export function sendPatientSignUpLink(id, filters) {
  const url = `/${API_VERSION_ONE}/patients/${id}/send_signup_mail`;
  const method = 'GET'

  return {
    type: 'SEND_PATIENT_SIGN_UP_LINK',
    payload: handleAPI(url, filters, method)
  };
}

export function patientEdit(id, patientData) {
  return {
    type: 'PATIENT_EDIT',
    id,
    patientData
  };
}

export function patientEditCancel() {
  return {
    type: 'PATIENT_EDIT_CANCEL'
  };
}

export function patientReset() {
  return {
    type: 'PATIENT_RESET'
  };
}
