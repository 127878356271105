import Toastr from "../../../itrust_common/components/Toastr";

const parent = 'PUBLIC_'

const DEFAULT_STATE = {
  stores: [],
  store: {},
  staffs: [],
  slots: [],
  isFirstAvailable: false
}
let changes = null;
export default function storeReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  switch(action.type){
    case `${parent}STORES_LOAD_PENDING`:
    case `${parent}STORE_STAFFS_LOAD_PENDING`:
    case `${parent}STORE_FIRST_AVAILABLE_SLOTS_LOAD_PENDING`:
      return {...state};

    case `${parent}STORES_LOAD_REJECTED`:
    case `${parent}STORE_STAFFS_LOAD_REJECTED`:
    case `${parent}STORE_FIRST_AVAILABLE_SLOTS_LOAD_REJECTED`:
      Toastr.handleShow(false, response.data.error, response.data.status)
      return {...state};

    case `${parent}STORES_LOAD_FULFILLED`:
    case `${parent}STORE_LOAD_FULFILLED`:
    case `${parent}STORE_STAFFS_LOAD_FULFILLED`:
    case `${parent}STORE_FIRST_AVAILABLE_SLOTS_LOAD_FULFILLED`:
      changes = {
        ...response,
      };
      return {...state, ...changes};
    
    case `${parent}STORE_ID`:
      changes = {
        storeId: action.id
      };
      return { ...state, ...changes };
    
    case `${parent}SET_FIRST_AVAILABLE`:
      changes = {
        isFirstAvailable: action.isChecked
      };
      return { ...state, ...changes };

    case `${parent}SET_SELECTED_DATE_ID`:
      changes = {
        selectedDate: action.date
      };
      return { ...state, ...changes };
    default:
      return state;
  }
}