import {handleAPI, API_VERSION_ONE} from '../../itrust_common/utils/apiUtils';

export function currentStoreLoad(id, filters) {
  const url = `/${API_VERSION_ONE}/current_store`;
  const method = 'GET'

  return {
    type: 'CURRENT_STORE_LOAD',
    payload: handleAPI(url, filters, method)
  };
}

export function storeStaffsLoad(store_id, filters) {
  const url = `/${API_VERSION_ONE}/stores/${store_id}/staff_members`;
  const method = 'GET'

  return {
    type: 'STORE_STAFFS_LOAD',
    payload: handleAPI(url, filters, method)
  };
}

export function currentStoreAll() {
  return {
    type: 'CURRENT_STORE_ALL',
  };
}
