import React from 'react'
import OpticalItrustImg from '../../../../assets/static/images/optical-itrust.svg';
import LeadingTeamsImg from '../../../../assets/static/images/leading_teams.svg';

export default function GuideStep2() {
  return (
    <div className='row mx-0'>
      <div className='col-6'>
        <h5>Great! Now get to know us little better...</h5>
        <ul className='pl-5 mt-4'>
          <li>Pioneer in cloud-based software since 2010</li>
          <li>Trusted by over 150,000 daily users globally</li>
          <li>Highly scalable to accommodate practices of all sizes</li>
          <li>Lightning-fast charting capabilities for streamlined workflow</li>
          <li>Designed for efficiency with a low learning curve and intuitive interface</li>
          <li>Headquartered in Miami, Florida, serving healthcare practices worldwide</li>
          <li>Comprehensive software integrating Optical and Cloud EHR with Automated Practice Management</li>
        </ul>
      </div>
      <div className='col-6'>
        <img className='img-fluid' src={OpticalItrustImg} />
      </div>
      <div className='col-12 my-6 text-center'>
        <h6 className='mb-6'>Leading teams grow with iTRUST </h6>
        <img className='img-fluid' src={LeadingTeamsImg} />
      </div>
    </div>
  )
}
