import React, { useState } from "react";
import Cookies from "js-cookie";
import * as $ from "jquery";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { numberToCurrencyWithoutDecimal } from "../itrust_common/components/HelperFunctions";

export default function NewFeaturePopup(props) {

  const { currentUser, organisation } = props;
  const [isSupport, setSupport] = useState(Cookies.get("iTrustSupport"));
  const [iTrustPay, setItrustPay] = useState(Cookies.get("iTrustPay"));

  const remindMeLater = (name, value, expire_days, hidePopUp) => {
    Cookies.set(`${name}`, `${value}`, { expires: expire_days });
    $(`${hidePopUp}`).hide();

    if (name == 'iTrustSupport')
      setSupport(Cookies.get("iTrustSupport"));
    else
      setItrustPay(Cookies.get("iTrustPay"));
  };

  return (
    <>
      {isSupport !== "NewSupportTicketUpdate" && (
        <div className="support-pop-up-align pop-up-align bg-white shadow-lg m-4 border-radius-10">
          <div className="bg-primary py-3 top-primary-bar text-right">
            <button className="btn p-0" onClick={() => remindMeLater('iTrustSupport', 'NewSupportTicketUpdate', 90, '.support-pop-up-align')}>
              <i className="las la-times text-white font-size-12 mr-3"></i>
            </button>
          </div>
          <div className="p-5 d-flex align-items-start">
            <button className="btn p-0">
              <i className="las la-info-circle"></i>
            </button>
            <div className="ml-3 mt-2">
              <h6 className="mb-2">
                {currentUser && currentUser.first_name} ! We just launched our new support system.
              </h6>
              <span className="text-muted">
                Click the button to create any support ticket.
              </span>
              <div className="d-flex justify-content-end mt-5 mb-2">
                <i className="las la-angle-down text-primary"></i>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* {isSupport == "NewSupportTicketUpdate" &&
        iTrustPay != "EnableItrustPay" &&
        organisation.subscription_plan !== "enterprise" &&
        currentUser && ["super_admin", "itrust_admin"].includes(currentUser.role) &&
        organisation.connect_account_details && organisation.connect_account_details.itrust_pay_enable && !organisation.connect_account_details.account_payouts_enabled && (
          <div className="payouts-pop-up pop-up-align itrust-pay-pop-up bg-white shadow-lg m-4 border-radius-10" id="payout-pop">
            <div className="bg-primary py-3 top-primary-bar text-right d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <i className="las la-info-circle text-white ml-2"></i>
                <p className="mb-0 ml-3 text-white font-size-14">
                  iTRUST Pay exclusive offer
                  {organisation.itrust_pay_offer_days_left ?
                    <strong className="ml-2">ONLY {organisation.itrust_pay_offer_days_left} DAYS LEFT</strong> : ''
                  }
                </p>
              </div>
              <button className="btn p-0" onClick={() => remindMeLater('iTrustPay', 'EnableItrustPay', 1, '#payout-pop')}>
                <i className="las la-times text-white font-size-12 mr-3"></i>
              </button>
            </div>
            <div className="px-3 py-5 d-flex align-items-start">
              <div className="ml-3 mt-2">
                <h6 className="mb-2 font-size-18">Hi {currentUser.first_name},</h6>
                <h6 className="my-4">
                  {organisation.connect_account_details.itrust_pay_fee_credits && (organisation.itrust_pay_offer_days_left && organisation.itrust_pay_offer_days_left !== 0) ?
                    <span>
                      🎉 You are eligible for fee free processing for {`${numberToCurrencyWithoutDecimal(organisation.connect_account_details.itrust_pay_fee_credits)}`} of payments processed!
                    </span> :
                    <span>🎉 We just launched online payments!</span>
                  }
                </h6>
              </div>
            </div>
            <div className="d-flex align-content-center justify-content-end m-4">
              <Link
                to="/payment/itrust-pay"
                className="font-weight-bold btn btn-danger"
              >
                ACTIVATE NOW
              </Link>
              <a href='https://www.itrust.io/itrustpay' target='_blank' className='ml-4 d-flex justify-content-center align-items-center'>
                Learn More <i className="las la-external-link-alt font-size-24 ml-2"></i>
              </a>
            </div>
          </div>
        )} */}
    </>
  );
}

NewFeaturePopup = connect((state) => {
  const { auth, organisation } = state;
  return {
    currentUser: auth.user,
    organisation: organisation.organisation,
  };
})(NewFeaturePopup);