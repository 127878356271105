import React from 'react'
import RpaClaimGenerateButton from '../../../claimForm/generateClaim/RpaClaimGenerateButton'
import { rpaClaimFormTemplateLoad } from '../../../../../../../redux/actions/addOns/rpa/rpaClaimFormAction'

function EyeMedClaimGenerate(props) {
  const { pprops: { rpaClaimFormGenerate, dispatch } } = props

  const rpaEyeMedClaimGenerate = async () => {
    await rpaClaimFormGenerate()
    await dispatch(rpaClaimFormTemplateLoad())
  }

  return (
    <>
      <RpaClaimGenerateButton rpaClaimFormGenerate={rpaEyeMedClaimGenerate} />
    </>
  )
}

export default EyeMedClaimGenerate;