import Toastr from "../../../itrust_common/components/Toastr"
const DEFAULT_STATE = {
  patients:[],
  patient: {},
  meta: { pagination: {} },
  saving: false
}
let changes = null;
export default function patientReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let patients = [];
  let patientData = [];
  let updatedRecord = {};
  switch(action.type){
    case 'PATIENTS_LOAD_PENDING':
    case 'PATIENT_LOAD_PENDING':
      return {...state, saving: true};

    case 'PATIENT_CREATE_PENDING':
    case 'PATIENT_UPDATE_PENDING':
      return {...state, saving: true};

    case 'PATIENTS_LOAD_REJECTED':
    case 'PATIENT_LOAD_REJECTED':
    case 'PATIENT_CREATE_REJECTED':
    case 'PATIENT_UPDATE_REJECTED':
    case 'PATIENT_DELETE_REJECTED':
    case 'PATIENT_MERGE_REJECTED':
    case 'PATIENT_PAYMENT_METHOD_DELETE_REJECTED':
    case 'SEND_PATIENT_SIGN_UP_LINK_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case 'PATIENTS_LOAD_FULFILLED':
      changes = {
        patients: response.patients,
        meta: {...state.meta, ...response.meta},
        saving: false
      };
      return {...state, ...changes};

    case 'PATIENT_LOAD_FULFILLED':
      changes = {
        patient: response.patient,
        meta: {...state.meta, ...response.meta},
        saving: false
      }
      return {...state,...changes};

    case 'PATIENT_CREATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      patients = [...state.patients]
      let newRecord = response.patient
      changes = {
        patients: [newRecord, ...patients],
        patient: newRecord,
        editing: false,
        saving: false
      }
      return {...state, ...changes};

    case 'PATIENT_UPDATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      patients = [...state.patients];
      updatedRecord = response.patient;
      for (const [index, patient] of patients.entries()) {
        if (patient.id === updatedRecord.id) {
          patientData = [
            ...patients.slice(0, index),
            updatedRecord,
            ...patients.slice(index + 1)
          ];
        }
      }
      changes = {
        patients: patientData,
        patient: updatedRecord,
        editing: false,
        saving: false
      };
      return { ...state, ...changes };

    case 'PATIENT_DELETE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      patients = [...state.patients];
      for (const [index, patient] of patients.entries()) {
        if (patient.id === response.patient.id) {
          patients.splice(index, 1);
        }
      }
      return {
        ...state,
        patients: patients,
        error: null
      };

    case 'PATIENT_MERGE_FULFILLED':
    case 'PATIENT_PAYMENT_METHOD_DELETE_FULFILLED':
    case 'SEND_PATIENT_SIGN_UP_LINK_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      return {
        ...state,
        error: null
      };


    case 'PATIENT_EDIT':
      changes = {
        editing: {
          id: action.id,
        },
        patient: action.patientData || DEFAULT_STATE['patient']
      };
      return { ...state, ...changes };

    case 'PATIENT_EDIT_CANCEL':
      changes = {
        editing: false
      };
      return { ...state, ...changes };

    case 'PATIENT_RESET':
      changes = {
        ...DEFAULT_STATE
      };
      return { ...state, ...changes };

    default:
      return state;
  }
}
