import React, {Component} from 'react';
import * as $ from 'jquery'
import Toastr from './Toastr'

var _this;
class Alert extends Component{
  constructor(props){
    super(props)
    this.state = { type: '', message: '', input: '', heading:'' }
    _this = this
    this.hook = React.createRef();
  }

  static confirm = ( message, code) => {
    _this.handleRequest('confirm', message, code)
  }

  static warning = ( message, code) => {
    _this.handleRequest('warning', message, code)
  }

  static deleteConfirm = ( message, code, heading) => {
    _this.handleRequest('deleteConfirm', message, code, heading)
  }

  handleRequest(type, message, code, heading){
    this.setState({ type: type, message: message, heading: heading})
    this.hook= code
    $('#alert-modal').modal()
  }

  handleCancel(){
    $('#alert-modal').modal('hide')
    this.setState({ type: '', message: '', input: '', heading: '' })
    this.hook(false)
  }

  handleDeleteConfirm(){
    var val = this.state.input
    if(val === 'delete'){
      $('#alert-modal').modal('hide');
      this.setState({ type: '', message: '', input: '', heading: '' })
      this.hook(true)
    } else{
      Toastr.handleShow(false, 'Wrong input, please try again')
    }
  }
  handleWarningConfirm(){
    $('#alert-modal').modal('hide');
    this.setState({ type: '', message: '', input: '' })
    this.hook && this.hook(true)
  }

  render(){
    return(
      <div className="modal fade" style={{zIndex: 999999999}} id="alert-modal" data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog mx-auto" role="document">
          {this.renderModalContent()}
        </div>
      </div>
    );
  }

  renderModalContent(){
    const { type, message, heading } = this.state
    switch(type){
      case 'deleteConfirm':
        return(
          <div className="modal-content shadow-lg">
            <div className="modal-header flex-column bg-light text-center align-items-center">
              <span className="d-block badge bg-danger-light"><i className="la la-trash text-danger"></i></span>
              {
                heading !== undefined ?
                  <h5 className="modal-title text-danger pt-5" id="exampleModalLabel">{heading}</h5>:
                  <h5 className="modal-title text-danger pt-5" id="exampleModalLabel">Delete Record Permanently?</h5>
              }
            </div>
            <div className="modal-body p-6">
              {/* <h6>Warning: This cannot be undone.</h6> */}
              <span>{ message }</span>
              <div className="form-group mt-6">
                <label htmlFor="exampleInputEmail1">Enter <span className="font-weight-bold">delete</span> to continue.</label>
                <input value={this.state.input} onChange={(e)=> this.setState({input: e.target.value})} className="form-control" id="exampleInputEmail1" />
              </div>
              <div className="text-right pt-5">
                <button type="button" className="btn bg-light mr-5" onClick={this.handleCancel.bind(this)}>
                  Cancel, Keep Record
                </button>
                <button type="button" className="btn btn-danger ml-5" onClick={this.handleDeleteConfirm.bind(this)}>
                  Yes, Delete
                </button>
              </div>
            </div>
          </div>
        )

      case 'confirm':
        return(
          <div className="modal-content shadow-lg">
            <div className="modal-header bg-light py-5">
              <h5 className="modal-title mb-0" id="exampleModalLabel">Confirm</h5>
            </div>
            <div className="modal-body p-6">
              <span>{message}</span>
              <div className="text-right pt-5">
                <button type="button" className="btn bg-light mr-5" onClick={this.handleCancel.bind(this)}>
                  Cancel
                </button>
                <button type="button" className="btn btn-primary ml-5" onClick={this.handleWarningConfirm.bind(this)}>
                  Continue
                </button>
              </div>
            </div>
          </div>
        )
      case 'warning':
        return(
          <div className="modal-content shadow-lg">
            <div className="modal-header bg-light py-5">
              <h5 className="modal-title mb-0" id="exampleModalLabel">Alert</h5>
            </div>
            <div className="modal-body p-6">
              <span>{message}</span>
              <div className="text-right pt-5">
                <button type="button" className="btn btn-primary ml-5" onClick={this.handleWarningConfirm.bind(this)}>
                  OK
                </button>
              </div>
            </div>
          </div>
        )
        
      default:
    }
  }
}

export default Alert;