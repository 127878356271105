import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import AdditionalTestsForm from '../../../../itrust_common/components/authorised/examFormPartial/AdditionalTestsForm';

class AdditionalTestForm extends Component {
  componentWillUnmount(){
    if(this.props.dirty){
      this.props.handleSubmit();
    }
  }

  render(){
    const { handleSubmit, template } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <AdditionalTestsForm pprops={this.props} template={template} isTemplate={false} />
      </form>
    )
  }
}

AdditionalTestForm = reduxForm({
  form: 'examAdditionalTestForm',
  enableReinitialize: true,
})(AdditionalTestForm)

AdditionalTestForm = connect(state => {
  const {exam: { exam } } = state
  return {
    initialValues: {
      id: exam.id,
      additional_tests_attributes: exam.additional_tests_attributes
    }
  }
})(AdditionalTestForm)

export default AdditionalTestForm;