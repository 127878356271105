import React, { Component } from 'react';
import { connect } from 'react-redux';
import { numberToCurrency } from '../../../../../itrust_common/components/HelperFunctions';

class Procedure extends Component{
  getProcedureTotal(procedures){
    let total = 0
    procedures.forEach(procedure => {
      return total = total + procedure.subtotal
    })
    return total
  }

  render(){
    const { procedures, order } = this.props
    return [
      <tr className="text-dark bg-light border-bottom border-white border-bottom-2">
        <td colspan="6"><h6>Exam Procedure</h6></td>
        <td><h6 className="text-danger">{order.cash_procedures_discount ? numberToCurrency(order.cash_procedures_discount) : ''}</h6></td>
        <td><h6>{this.getProcedureTotal(procedures) ? numberToCurrency(this.getProcedureTotal(procedures)) : ''}</h6></td>
      </tr>,
      procedures.map((procedure, index) => (
        <tr key={`procedure-${index}`} className="text-dark bg-light border-bottom border-white border-bottom-2">
          <td colSpan="2">{index + 1}. {procedure.description}</td>
          <td className="pl-8">{procedure.unit_price ? numberToCurrency(procedure.unit_price) : ''}</td>
          <td>{procedure.copay ? numberToCurrency(procedure.copay) : ''}</td>
          <td>{(procedure.cash_discount || procedure.percentage_discount) ? numberToCurrency(procedure.discount_type === "cash" ? procedure.cash_discount : procedure.percentage_discount) : ''}</td>
          <td colSpan="3">{procedure.subtotal ? numberToCurrency(procedure.subtotal) : ''}</td>
        </tr>
      ))
    ]
  }
}

export default connect()(Procedure);