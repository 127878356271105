import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { onlyNumbers, required } from '../../../../../itrust_common/components/form/FormValidations'
import { SaveButton } from '../../../../../itrust_common/components/others'
import { AutoCompleteSelect, Input } from '../../../../../itrust_common/components/form'
import OnlineCardsImg from "../../../../../assets/static/images/online-cards.svg";
import { CountriesStates } from '../../../../../itrust_common/components/CountriesStates'
import _ from 'lodash';
import { connect } from 'react-redux'

function AddPaymentMethodForm(props) {
  const { handleSubmit, saving, setshowAddCardForm, paymentMethodsCount, regions, patientProfileView, paymentMethodEditing, selectedState } = props

  return (
    <form >
      <div className='row my-2'>
        <div className={`position-relative ${patientProfileView ? 'col-12' : 'col-9'} card-img ${paymentMethodEditing && 'disabled'}`}>
          <Field component={Input} name="card_number" label='Card Number' validate={paymentMethodEditing ? [] : [required, onlyNumbers]} className="form-control" placeholder="XXXX XXXX XXXX XXXX" />
          <img src={OnlineCardsImg} alt='card' className='position-absolute' />
        </div>
        <span className='col-12'></span>
        <Field component={Input} name="exp_month" label='Expire' validate={[required, onlyNumbers]} wrapperClasses={`${patientProfileView ? 'col-3' : 'col-2'} mt-4`} className="form-control " placeholder="MM" />
        <Field component={Input} name="exp_year" label="" validate={[required, onlyNumbers]} wrapperClasses={`${patientProfileView ? 'col-3' : 'col-2'} mt-5 pl-0`} className="form-control " placeholder="YYYY" />
        {!paymentMethodEditing ? <Field component={Input} name="cvv" label='CVV' validate={[required, onlyNumbers]} wrapperClasses='col-2 mt-4 pl-0' className="form-control " placeholder="XXX" /> : ''}
        <Field component={Input} name="zip" label="Zip Code" validate={paymentMethodEditing ? [] : [required]} wrapperClasses={`${patientProfileView ? 'col-3' : 'col-2'} mt-4 pl-0 ${paymentMethodEditing && 'disabled'}`} className="form-control" placeholder="Zip Code" />
        <Field component={Input} name={`${paymentMethodEditing ? 'name' : 'card_holder_name'}`} label="Card Holder Name" validate={paymentMethodEditing ? [] : [required]} wrapperClasses={`${patientProfileView ? 'col-12' : 'col-9'} mt-5 ${paymentMethodEditing && 'disabled'}`} className="form-control" placeholder="Card Holder Name" />
        <Field component={Input} name="address_line1" label="Address" validate={[required]} wrapperClasses={`${patientProfileView ? 'col-12' : 'col-9'} mt-5`} className="form-control" placeholder="Address" />
        <Field name='state' component={AutoCompleteSelect} data={regions} textField="name" valueField="shortCode" key={selectedState} placeholder="State" wrapperClasses={`${patientProfileView ? 'col-7' : 'col-6'} form-group error autocomplete mt-5`} label="State" validate={[required]} />
        <Field component={Input} name="city" label="City" validate={[required]} wrapperClasses={`${patientProfileView ? 'col-5' : 'col-3'} pl-0 mt-4`} className="form-control" placeholder="City" />
        {paymentMethodsCount ? <button className='btn btn-outline-primary col-2 offset-7' onClick={() => setshowAddCardForm(false)}>Back</button> : null}
        {!patientProfileView && <SaveButton label="Save Card" buttonType='button' handleOnClick={handleSubmit} wrapperClasses={`col-3 ${!paymentMethodsCount && 'offset-9'}`} saving={saving} />}
      </div>
    </form>
  )
}

AddPaymentMethodForm = reduxForm({
  form: 'AddPaymentMethodForm',
  enableReinitialize: true,
})(AddPaymentMethodForm)

const mapStateToProps = (state) => {
  const { } = state
  const countryData = _.find(CountriesStates, { countryName: 'United States' })
  return {
    regions: countryData ? countryData.regions : [],
  }
}

export default connect(mapStateToProps)(AddPaymentMethodForm)