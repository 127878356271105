import {handleAPI, API_VERSION_ONE} from '../../../itrust_common/utils/apiUtils';

export function eyeglassLabOrderLoad(eyeglassId, labOrderId, filters){
  const url = `/${API_VERSION_ONE}/eyeglasses/${eyeglassId}/eyeglass_lab_orders/${labOrderId}`;
  const method = 'GET'

  return {
    type: 'EYEGLASS_LAB_ORDER_LOAD',
    payload: handleAPI(url,filters,method)
  };
}

export function eyeglassLabOrderUpdate(eyeglassId, data, filters){

  const url = `/${API_VERSION_ONE}/eyeglasses/${eyeglassId}/eyeglass_lab_orders/${data.id}`;
  const method = "PUT"
  return {
    type: 'EYEGLASS_LAB_ORDER_UPDATE',
    payload: handleAPI(url,filters,method, {eyeglass_lab_order: {...data}})
  }
}


export function eyeglassLabOrderCreate(eyeglassId, data, filters){
  const url = `/${API_VERSION_ONE}/eyeglasses/${eyeglassId}/eyeglass_lab_orders`;
  const method = "POST"
  return {
    type: 'EYEGLASS_LAB_ORDER_CREATE',
    payload: handleAPI(url,filters,method, {eyeglass_lab_order: {...data}})
  }
}

export function eyeglassLabOrderEdit(id) {
  return {
    type: 'EYEGLASS_LAB_ORDER_EDIT',
    id
  };
}


export function eyeglassLabOrderEditCancel() {
  return {
    type: 'EYEGLASS_LAB_ORDER_EDIT_CANCEL'
  };
}
