import settingReducer from './settingReducer'
import authReducer from './authReducer';
import filterReducer from './filterReducer';
import notificationReducer from './notificationReducer';
import activityReducer from './activityReducer';
import unsplashReducer from './unsplashReducer';
import wizardReducer from './wizardReducer';
import passwordReducer from './passwordReducer';
import otherReducer from './otherReducer';
import examReducer from './exam';
import importReducer from './importReducer';

const itrustCommonReducer ={
  setting: settingReducer,
  auth: authReducer,
  filter: filterReducer,
  notification: notificationReducer,
  activity: activityReducer,
  unsplash: unsplashReducer,
  wizard: wizardReducer,
  password: passwordReducer,
  other: otherReducer,
  import: importReducer,
  ...examReducer,
};

export default itrustCommonReducer;