import React from 'react';
import { FieldArray, change } from 'redux-form';
import { checkIsVisible } from '../../../HelperFunctions';
import GlassForm from './GlassForm'

class RenderGlass extends React.Component {

  render() {
    const { template, isTemplate, fields, pprops } = this.props
    return (
      <span>
        {fields.map((member, index) => (
          <div className="card-body">
            <div className="row my-5">
              <div className="col">
                <span className="badge badge-dark">Eyeglass Rx {index + 1}</span>
              </div>
            </div>
            <GlassForm
              {...this.props}
              name={`finalized_glasses_attributes[${index}]`}
              baseName={`finalize_prescription[eye_glass][eye_glasses][0]`}
              fieldValues={template['finalize_prescription']['eye_glass']['eye_glasses'][0]}
            />
            {!isTemplate &&
              <div className='row justify-content-between'>
                <div className='col-4'>
                  {(fields.length === index + 1) && pprops.exam?.pupillary_distance?.is_marked_complete && template.refraction_contact_lens_exam.refraction.fields?.pupillary_distance?.show_in_finalize_prescription && checkIsVisible(template.refraction_contact_lens_exam.refraction.fields.pupillary_distance.show_in_finalize_prescription) &&
                    <>
                      <strong>Pupillary Distance:</strong>
                      <table className='table table-bordered'>
                        <thead>
                          <th>Distance</th>
                          <th>Intermediate</th>
                          <th>Near</th>
                        </thead>
                        <tbody>
                          <tr>
                            <td>OD: {pprops.exam.pupillary_distance.od_distance}</td>
                            <td>OD: {pprops.exam.pupillary_distance.od_intermediate}</td>
                            <td>OD: {pprops.exam.pupillary_distance.od_near}</td>
                          </tr>
                          <tr>
                            <td>OS: {pprops.exam.pupillary_distance.os_distance}</td>
                            <td>OS: {pprops.exam.pupillary_distance.os_intermediate}</td>
                            <td>OS: {pprops.exam.pupillary_distance.os_near}</td>
                          </tr>
                          <tr>
                            <td>OU: {pprops.exam.pupillary_distance.ou_distance}</td>
                            <td>OU: {pprops.exam.pupillary_distance.ou_intermediate}</td>
                            <td>OU: {pprops.exam.pupillary_distance.ou_near}</td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  }
                </div>
                <div className='col-8'>
                  {!isTemplate && (fields.length === index + 1) &&
                    <button type="button" className="btn btn-primary float-right" onClick={() => { fields.push({ ...pprops.initialValues.finalized_glasses_attributes[fields.length - 1], id: null }); setTimeout(() => pprops.submit(), 500) }}>
                      Add New
                    </button>
                  }
                  {!isTemplate &&
                    <button type="button" className="btn btn-outline-primary mr-7 float-right" aria-label="Close" onClick={() => { pprops.dispatch(change('examFinalizePrescriptionForm', `${member}[_destroy]`, true)); setTimeout(() => pprops.submit(), 500) }}>
                      Remove Prescription
                    </button>
                  }
                </div>
              </div>
            }
          </div>
        )
        )}
      </span>
    );
  }
}

const EyeGlassPrescription = props => {
  const { template, isTemplate, pprops } = props
  const name = isTemplate ? "finalize_prescription[eye_glass][eye_glasses]" : "finalized_glasses_attributes"
  return (
    <FieldArray name={name}
      props={{
        template: template,
        isTemplate: isTemplate,
        pprops: pprops,
      }}
      component={RenderGlass} />
  );
}

export default EyeGlassPrescription;





