import React from 'react';
import { isPresentInArray, numberToCurrency } from '../../../../../itrust_common/components/HelperFunctions'

let Procedures = props => {
  const { selectedExam, selectedIds, handleIdsSelect } = props;
  return (
    <div className="row">
      <div className="col-12 mt-7">
        { selectedExam && selectedExam.procedures_attributes &&
          <div className="overview-table px-2">
            <table className="table table-hover shadow-none">
              <thead className="text-dark border-bottom bg-light border-white border-bottom-2">
                <tr>
                  <th scope="col">Procedure</th>
                  <th scope="col">Code</th>
                  <th scope="col">Unit Price</th>
                  <th scope="col">Select</th>
                </tr>
              </thead>
              <tbody>
                { selectedExam.procedures_attributes.map((procedure, index)=>
                  <tr key={`procedure-${index}`} className="text-dark bg-light border-bottom border-white border-bottom-2">
                    <td>{procedure.name}</td>
                    <td>{procedure.exam_code}</td>
                    <td>{numberToCurrency(procedure.unit_price)}</td>
                    <td>
                      <div className="custom-control custom-checkbox">
                        <input
                          onChange={()=> handleIdsSelect(selectedIds, procedure.id)}
                          type="checkbox"
                          checked={isPresentInArray(selectedIds, procedure.id)}
                          className="custom-control-input" id={`procedure-select-${procedure.id}`} />
                        <label className="custom-control-label" htmlFor={`procedure-select-${procedure.id}`}></label>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        }
      </div>
    </div>
  )
}

export default Procedures;
