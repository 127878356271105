import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from "react-redux";

class Permission extends Component {

  canView(){
    const { moduleName, action, onlyAdmin, currentUser, currentStore } = this.props;

    if(currentUser.role==='super_admin' || currentUser.role==='itrust_admin'){
      return true
    } else if(currentUser.role==='admin'){
      if(onlyAdmin)
        return onlyAdmin === 'admin';
      return true
    } else if (onlyAdmin == undefined && currentStore.store_access_level=="full"){
      return true
    } else if (currentStore.store_access_level == "restricted") {
      return false
    }else if(moduleName !== undefined){
      var record = _.find(currentStore.permissions, { app_module_name:  moduleName})
      return record && (record.access_level==="full" || record.permissions[action])
    }else if(moduleName === undefined && onlyAdmin === undefined){
      return true
    }
  }

  render() {
    return (
      <React.Fragment>
        {
          this.canView() &&
            this.props.children
        }
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  const { currentStore, auth } = state;
  return {
    currentStore: currentStore.store,
    currentUser: auth.user
  }
}
export default connect(mapStateToProps)(Permission);


