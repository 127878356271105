import React from 'react'
import LoadingImg from "./../assets/static/images/loading.gif";

export default function DisabledLoader(props) {
  const { saving, wrapperClasses } = props
  return (
    <>
      {saving &&
        <div className={`disabled-loader ${wrapperClasses}`}>
          <div className={`spinner-border loader-display`} role="status">
            <div className='d-flex align-items-baseline'>
              <span className='text-white h1'>Loading</span>
              <img src={LoadingImg} />
            </div>
          </div>
          <div className={`modal-backdrop fade show opacity-3 modal-loader`}></div>
        </div>
      }
    </>
  )
}
