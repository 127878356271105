import React from 'react'
import { connect } from 'react-redux';
import CallingWrapper from './voipPopUp/CallingWrapper';

const Calling = (props) => {
  const { call_pop_up_status } = props
  return (
    <>
      {call_pop_up_status && <CallingWrapper />}
    </>
  )
}

const mapStateToProps = state => {
  const { voip } = state;
  return {
    call_pop_up_status: voip?.call_pop_up_status,
  }
}

export default connect(mapStateToProps)(Calling);