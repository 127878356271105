import {handleAPI} from '../../../itrust_common/utils/apiUtils';
const parent = 'PUBLIC_'

export function organisationLoad(filters){
  const url = `/`;
  const method = 'GET'

  return {
    type: `${parent}ORGANISATION_LOAD`,
    payload: handleAPI(url, filters, method)
  };
}

export function organisationValidate(filters) {
  const url = `/validate_tenant`;
  const method = 'GET'

  return {
    type: `${parent}ORGANISATION_VALIDATE`,
    payload: handleAPI(url, filters, method)
  };
}

export function isOrgNotExist(res) {
  return (res.success === false && res.error === "Organisation does not exist") ? true : false
}

export function isOrgInactive(res) {
  return (res.success === false && res.error === "Organisation is inactive") ? true : false
}