import { Device } from '@twilio/voice-sdk'
import Alert from "../../../../../itrust_common/components/Alert";
import { contactLoad } from "../../../../../redux/actions/addOns/contactListsAction";
import { callPopUpEdit, callingInformationUpdate, makeOutgoingCall } from "../../../../../redux/actions/addOns/voip/voipActions";

let device;
export const twilioClientSetup = async (data, dispatch) => {
  let res = await dispatch(makeOutgoingCall(data))
  res = res.action.payload.data["add_ons/voip/call_log"]
  res.patient_id && dispatch(contactLoad(res.patient_id, { include: '*' }))
  device = await twilioDeviceInitialization(res.token,dispatch)
  return { device, res }
}

export const twilioDeviceInitialization = async (token,dispatch) => {
  if (token) {
    const deviceOptions = {
      codecPreferences: ["opus", "pcmu"],
    }
    device = new Device(token, deviceOptions);
    handleTwilioDeviceListeners(device, token, dispatch)
    // Device must be registered in order to receive incoming calls
    await device.register()
    return device;
  }
  else {
    Alert.warning('Token is not present.')
  }
}

export const handleTwilioDeviceListeners = (device, token, dispatch) => {

  device.on("registered", () => {
    console.log("Twilio.Device Ready to make and receive calls!");
  });

  device.on("unregistered", () => {
    console.log('The device has been unregistered.');
  });

  device.on("error", (error) => {
    if (error.code !== 20104) {
      alert("Twilio.Device Error:\n" + error.message);
    }
  });

  device.on("incoming", (call) => twilioIncomingCallHandle(call, device, token,dispatch));
}

// Outgoing Calling
export const TwilioOutGoingCallConnect = async (res, phoneNumber, dispatch) => {
  const call = await res.device.connect({ params: { To: `${phoneNumber}`, call_log_id: res.res.id, call_from_staff: true } });
  call.on("accept", () => twilioOutgoingCallAccept(call, res.device, dispatch));
  call.on("disconnect", () => twilioOutgoingCallEnded(call, res.device, res.token, dispatch));
  twilioMiscCallingEvents(call)
  return call
}

export const twilioOutgoingCallAccept = (call, device, dispatch) => {
  dispatch(callingInformationUpdate({ callStatus: 'ringing' }))
  return console.log('Ringing...')
}

export const twilioOutgoingCallEnded = async (call, device, token, dispatch) => {
  await twilioDeviceUnregister(device)
  dispatch(callingInformationUpdate({ callStatus: 'call_ended', isCallActive: false }))
  console.log("OutGoing Call ended.")
}

// Incoming Calling
export const twilioIncomingCallHandle = (call, device, token,dispatch) => {
  console.log('Incoming Call')
  dispatch(callPopUpEdit('patient'))
  dispatch(callingInformationUpdate({ call: call, callStatus: 'ringing' }))

  // add event listener to call object
  call.on("disconnect", () => twilioIncomingCallEnded(call, device, token, dispatch));
  call.on("reject", () => twilioIncomingCallEnded(call, device, token, dispatch));
  twilioMiscCallingEvents(call)
  return call
}

export const twilioIncomingCallEnded = async (call, device, token, dispatch) => {
  await twilioDeviceUnregister(device)
  dispatch(callingInformationUpdate({ callStatus: 'call_ended', isCallActive: false }))
  console.log("Incoming Call ended.")
}

// Other
const twilioDeviceUnregister = (device) => {
  return device.unregister()
    .then(() => console.log('The device has been successfully unregistered.'))
    .catch((error) => console.log('An error occurred while unRegistering the device:', error));
}

export const twilioMiscCallingEvents = (call) => {
  call.on('reconnecting', (twilioError) => {
    alert('reconnecting')
    console.log('Connectivity error: ', twilioError);
  });

  call.on('warning', function (warningName, warningData) {
    if (warningName === 'low-mos') {
      // Show the Poor connection Message
      alert('We have detected poor call quality conditions. You may experience degraded call quality.');
    }
  });

  // call.on('warning-cleared', function (warningName) {
  //   if (warningName === 'low-mos') {
  //     // Hide the Poor connection Message
  //     alert('Connection OK');
  //   }
  // });

  // call.on('ringing', hasEarlyMedia => {
  //   showRingingIndicator();
  //   if (!hasEarlyMedia) {
  //     playOutgoingRinging();
  //   }
  // });
}