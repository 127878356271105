import React, { useEffect } from 'react';
import { reduxForm } from 'redux-form';
import * as $ from 'jquery';
import { Field, change } from 'redux-form';
import { connect } from 'react-redux';
import { MultiSelect, Select, DateAndTimePicker, Textarea, RadioGroup } from '../../../itrust_common/components/form';
import CustomToolTip from '../../../containers/authorised/onboarding/CustomToolTip';
import { onBoardingTourInfoUpdate } from '../../../redux/actions/onBoardingActions';

let NewExamForm = props => {
  const { handleSubmit, providers, procedures, defaultProcedureIds, activeTourType } = props;
  let setProcedure = (values) => {
    var data = []
    values && values.map(value => data = [...data, { settings_procedure_id: value }])
    props.dispatch(change('newExamForm', 'procedures_attributes', data))
  }

  useEffect(() => {
    setProcedure(defaultProcedureIds);
  }, []);

  const handleExamTourBackStep = () => {
    $('#newExamForm-modal').modal('hide');
    props.dispatch(onBoardingTourInfoUpdate({ stepIndex: 0, isTourActive: true, activeTourType: 'create_exam' }))
  }

  return (
    <form onSubmit={handleSubmit} className="px-7 py-5 exam-step-2">
      <div className="row">
        <Field name='exam_date' label="Exam Date" component={DateAndTimePicker} wrapperClasses='form-group disabled col-6' />
        <Field name="staff_id" label="Exam Provider" component={Select} data={providers} placeholder="Select Provider" textField="name" valueField="id" wrapperClasses="form-group col-6" />
        <MultiSelect
          name="procedure_ids"
          placeholder="Reason of Visit"
          data={procedures}
          defaultValues={defaultProcedureIds}
          textField="friendly_name"
          valueField="settings_procedure_id"
          isAllSelectVisible={false}
          isSearchVisible={true}
          label="Reason of visit"
          onSelectHook={(value) => { setProcedure(value) }}
          wrapperClasses="form-group col-6"
        />
        <RadioGroup name="is_telemed" options={[{ label: 'Yes', value: true }, { label: 'No', value: false }]} wrapperClasses="col-6 form-group" label="Telemed" />
        {/*<Field name="settings_insurance_id" label="Insurance Payer" component={Select} data={insurances} textField="name" valueField="id" wrapperClasses="form-group col-6" />*/}
        <Field name="hpis_attributes[0][chief_complaint]" label="Comments" component={Textarea} rows={4} className="form-control" wrapperClasses="form-group col-12" />
      </div>
      {/* belongs to open modal step > its being hard to show Tour Tooltip over modal overlay */}
      {activeTourType === 'create_exam' &&
        <CustomToolTip
          description="Enter exam details and click Create Exam"
          wrapperClasses="align-tour-tooltip exam-tour-tooltip"
          customData={{ index: '2', size: '2' }}
          isRenderCustom={true}
          isLastStep={true}
          title="Create Exam"
          handleTourBackStep={handleExamTourBackStep}
        />
      }
    </form>
  )
}

NewExamForm = reduxForm({
  form: 'newExamForm',
})(NewExamForm)

NewExamForm = connect(state => {
  const { exam, currentStore, procedurePricing, onBoarding: { activeTourType } } = state
  return {
    procedures: procedurePricing.procedure_pricings,
    providers: currentStore.staffs,
    defaultProcedureIds: exam.editing.data.defaultProcedureIds,
    activeTourType,
    initialValues: {
      staff_id: exam.editing.data.staffId ? exam.editing.data.staffId : currentStore.store.default_provider_id,
      patient_id: exam.editing.data.patientId,
      appointment_id: exam.editing.data.appointmentId,
      procedure_ids: exam.editing.data.defaultProcedureIds,
      exam_date: (new Date()).toString(),
      is_telemed: false
    }
  }
})(NewExamForm)

export default NewExamForm;