import React from 'react';
import { Field } from 'redux-form';
import { Input, Textarea, PhoneInput, RadioGroup, AutoCompleteList, DatePicker } from '../../../itrust_common/components/form';
import { lowercase } from '../../../itrust_common/components/form/FormNormalize';

let ReferralDeatilsForm = props => {
  const { defaultContacts } = props
  return (
    <div>
      <span className="font-weight-bold">Referral Detail</span>
      <div className="row mt-5">
        <Field name="name" component={Input} placeholder="Placeholder" wrapperClasses="col-6 form-group" className="form-control disabled bg-light" label="Patient Name"/>
        <Field name="referral_date" component={DatePicker} placeholder="Placeholder" wrapperClasses="col-6 form-group" className="disabled bg-light" label="Referral Date"/>
        <Field name="date_of_birth" component={Input} placeholder="Placeholder" wrapperClasses="col-6 form-group" className="form-control disabled bg-light" label="Date Of Birth"/>
        <Field name="age" component={Input} placeholder="Placeholder" className="form-control disabled bg-light" wrapperClasses="col-6 form-group" label="Age"/>
        <div className="col-6">
          <label>Cell Phone</label>
          <Field name="cell_phone" component={PhoneInput} placeholder="Placeholder" wrapperClasses="form-group" className="form-control" disabled={true}/>
        </div>
        <Field name="email" normalize={lowercase} component={Input} placeholder="Placeholder" wrapperClasses="col-6 form-group" className="form-control disabled bg-light" label="Email"/>
        <Field name="request_to_see" component={Input} placeholder="Placeholder" wrapperClasses="col-6 form-group" className="form-control" label="Request to See"/>
        <Field name="referral_reason" component={Input} placeholder="Placeholder" wrapperClasses="col-6 form-group" className="form-control" label="Referral Reason"/>
        <Field name="body_message" component={Textarea} rows={5} placeholder="Placeholder" wrapperClasses="col-12 form-group" className="form-control" label="Body Message"/>
        <Field name="contact_ids" 
          label="Secure Fax" 
          placeholder="Search and add contact" 
          component={AutoCompleteList} 
          defaultData={defaultContacts} 
          klass="Contact"
          valueField="id"
          autoCompleteTextField="company_name"
          view={{image:'logo', h5:'company_name', span:'fax'}}
          className="my-5 col-6"
          wrapperClasses="col-8 form-group" />
        <RadioGroup name="send_notification_to_patient" options={[{label: 'Yes', value: true}, {label: 'No', value: false}]} wrapperClasses="col-4 form-group" label="Send Notification to Patient"/>
      </div>
    </div>
  );
}

export default ReferralDeatilsForm;