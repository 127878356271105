import React, {Component} from 'react';

export default class Switch extends Component {
  render() {
    const { props, props: { input, disabled, wrapperClasses } } = this;
    const id = `${input.name}-${input.value}`
    return (
      <div className={`switch ${wrapperClasses} ${disabled && 'disabled'}`}>
        <div className="custom-control custom-switch">
          <input type="checkbox" className="custom-control-input" id={id} {...input} checked={input.value} disabled={disabled}/>
          <label className="custom-control-label" htmlFor={id}>{props.label}</label>
        </div>
      </div>
    );
  }
}

