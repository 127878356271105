import Toastr from "../../../itrust_common/components/Toastr"
const DEFAULT_STATE = {
  payments: [],
  payment: {},
  itrust_pay_log: {},
  meta: { pagination: {} },
  saving: false,
  devicePayment: {
    active: false,
    status: '',
  },
  paymentByLinkStatus: 'initiated'
}
let changes = null;
export default function orderPaymentReducer(state = DEFAULT_STATE, action) {
  const response = action.payload && (action.payload.data || action.payload.response)
  let payments = [];
  let paymentData = [];
  let updatedRecord = {};

  switch (action.type) {
    case 'ORDER_PAYMENTS_LOAD_PENDING':
    case 'ORDER_PAYMENT_LOAD_PENDING':
    case 'ORDER_PAYMENT_CREATE_PENDING':
    case 'ORDER_PAYMENT_UPDATE_PENDING':
      return { ...state, saving: true };

    case 'ORDER_PAYMENTS_LOAD_REJECTED':
    case 'ORDER_PAYMENT_LOAD_REJECTED':
    case 'ORDER_PAYMENT_CREATE_REJECTED':
    case 'ORDER_PAYMENT_UPDATE_REJECTED':
    case 'ORDER_PAYMENT_DELETE_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = {
        saving: false
      }
      return { ...state, ...changes };

    case 'ORDER_PAYMENTS_LOAD_FULFILLED':
      changes = {
        ...response,
        saving: false
      };
      return { ...state, ...changes };

    case 'ORDER_PAYMENT_LOAD_FULFILLED':
      changes = {
        payment: response.payment,
        saving: false
      }
      return { ...state, ...changes };

    case 'ORDER_PAYMENT_CREATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      payments = [...state.payments]
      let newRecord = response.payment
      changes = {
        payments: [...payments, newRecord],
        editing: false,
        saving: false
      }
      return { ...state, ...changes };

    case 'ORDER_PAYMENT_UPDATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      payments = [...state.payments];
      updatedRecord = response.payment;
      for (const [index, payment] of payments.entries()) {
        if (payment.id === updatedRecord.id) {
          paymentData = [
            ...payments.slice(0, index),
            updatedRecord,
            ...payments.slice(index + 1)
          ];
        }
      }
      changes = {
        payments: paymentData,
        editing: false,
        saving: false
      };
      return { ...state, ...changes };

    case 'ORDER_PAYMENT_DELETE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      payments = [...state.payments];
      for (const [index, payment] of payments.entries()) {
        if (payment.id === response.id) {
          payments.splice(index, 1);
        }
      }
      return {
        ...state,
        payments: payments,
        error: null
      };

    case 'ORDER_PAYMENT_INTENT_CONFIRM_FULFILLED':
      return { ...state, ...changes };

    case 'ORDER_PAYMENT_EDIT':
      changes = {
        editing: {
          id: action.id,
        },
        payment: {}
      };
      return { ...state, ...changes };

    case 'ORDER_PAYMENT_EDIT_CANCEL':
      changes = {
        editing: false
      };
      return { ...state, ...changes };

    // Pay via Link
    case 'SUBMIT_PAYMENT_BY_LINK_PENDING':
      return { ...state, saving: true, paymentByLinkStatus: 'in_progress' };

    case 'SUBMIT_PAYMENT_BY_LINK_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      return { ...state, saving: false, paymentByLinkStatus: 'failed' };

    case 'SEND_PAYMENT_URL_REJECTED':
      Toastr.handleShow(response.data.success, response.data.message, response.data.status_code)
      changes = {
        saving: false
      }
      return { ...state, ...changes };

    case 'SEND_PAYMENT_URL_FULFILLED':
      Toastr.handleShow(true, response.message, 201)
      changes = { saving: false }
      return { ...state, ...changes };

    case 'SUBMIT_PAYMENT_BY_LINK_FULFILLED':
      Toastr.handleShow(true, response.message, 201)
      localStorage.removeItem('Payment-Token')
      changes = { saving: false, paymentByLinkStatus: 'successful' }
      return { ...state, ...changes };

    // Device Payment
    case 'DEVICE_PAYMENT_LOG_DELETE_PENDING':
      return { ...state, saving: true }

    case 'INITIATE_DEVICE_PAYMENT_PENDING':
      return { ...state, saving: true, devicePayment: { active: true, status: 'in_progress' } }

    case 'INITIATE_DEVICE_PAYMENT_REJECTED':
      Toastr.handleShow(false, response.data.error, response.data.status)
      return { ...state, saving: false, devicePayment: { active: false } }

    case 'VALIDATE_PAYMENT_LINK_REJECTED':
      Toastr.handleShow(false, response.data.error, response.data.status)
    return { ...state, saving: false, devicePayment: { active: false }, isLinkExpired: true }

    case 'DEVICE_PAYMENT_LOG_DELETE_REJECTED':
      Toastr.handleShow(false, response.data.error, response.data.status_code)
      response.data.status_code === 404 && localStorage.removeItem('deviceLogId')
      return { ...state, saving: false }

    case 'DEVICE_PAYMENT_LOG_LOAD_REJECTED':
      Toastr.handleShow(false, response.data.error, response.data.status_code)
      return { ...state, saving: false }

    case 'DEVICE_PAYMENT_STATUS_UPDATE':
      return { ...state, saving: false, devicePayment: { ...action.payload } }

    case 'DEVICE_PAYMENT_LOG_DELETE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      return { ...state, saving: false, device_log: {}, devicePayment: { active: false, status: '' } }

    case 'INITIATE_DEVICE_PAYMENT_FULFILLED':
      localStorage.setItem('deviceLogId', response['add_ons/itrust_pay/log'].id)
      changes = {
        itrust_pay_log: response['add_ons/itrust_pay/log'],
        saving: false
      };
      return { ...state, ...changes };

    default:
      return state;
  }
}