import React from "react";
import CreateTicket from "./createTicket/CreateTicket";
import AllTickets from "./tickets/AllTickets";
import { capture } from "../utils/capture";
import { startRecording } from "../utils/recorder";
import ImageEditor from "./ImageEditor";
import { useEffect } from "react";
import { seenAllNotifications } from "../utils/api/tickets";

const MainPopup = (props) => {
  const { view, setView, setAttachmentData, attachmentData, setNotifications, handleOnboardingTourReplay } = props;

  const handleStartRecording = async () => {
    if (attachmentData === undefined) {
      const response = await startRecording();
      if (response) setView("Recording");
    } else {
      setView("Text");
    }
  };

  const handleCapture = async () => {
    setView("Capture");
    const response = await capture();
    if (response.error) {
      setView("Menu");
    }
    if (response.attachmentBase) {
      setAttachmentData(response);
      setView("Editor");
    }
  };

  useEffect(() => {
    handleSeenAllNotification();
  }, []);

  const handleSeenAllNotification = async () => {
    const response = await seenAllNotifications();
    setNotifications(false)
  };

  return (
    <>
      {view === "Editor" && (
        <ImageEditor
          img={attachmentData.attachmentBase}
          attachmentData={attachmentData}
          setAttachmentData={setAttachmentData}
          setView={setView}
        />
      )}
      {view === "Tickets" && <AllTickets view={view} setNotifications={setNotifications}/>}
      {(view === "Text" ||
        view === "RecordingForm" ||
        view === "CaptureForm") && (
        <CreateTicket
          view={view}
          setView={setView}
          setAttachmentData={setAttachmentData}
          attachmentData={attachmentData}
        />
      )}
      <div className="">
        <ul className="position-absolute bg-white main-popup  text-center text-gray2 text-sm rounded-lg shadow-lg z-50 list-unstyled bg-clip-padding  mt-1 border border-1 border-gray m-0">
          <li
            className={`border-bottom border-gray2 cursor-pointer ${
              view === "Tickets" && "active-record"
            }`}
            onClick={() =>
              view === "Tickets" ? setView("") : setView("Tickets")
            }
          >
            <p className="dropdown-item py-5 px-3 mb-0 font-normal text-gray-700 hover-effect">
              All <br /> Tickets
            </p>
          </li>
          <li>
            <p
              className="py-5 px-3 mb-0 text-primary font-weight-bold hover:bg-gray-100"
              href="#"
            >
              Create <br /> Ticket <br />
              <i className="las la-angle-double-down text-primary"></i>
            </p>
          </li>
          <li>
            <span className="dropdown-item text-sm py-5 px-3 text-gray-700 hover-effect pointer" onClick={handleOnboardingTourReplay}>
              <i className="las la-hand-pointer text-primary"></i><br />
              App Tour
            </span>
          </li>
          <li
            onClick={() => handleStartRecording()}
            className={`${
              view === "RecordingForm" && "active-record"
            } cursor-pointer`}
          >
            <p
              className="dropdown-item py-5 px-3 mb-0 font-normal text-gray-700 hover-effect"
              href="#"
            >
              <i className="las la-video text-primary"></i>
              <br />
              Record
            </p>
          </li>
          <li
            onClick={() => handleCapture()}
            className={`${view === "CaptureForm" && "active-record"} cursor-pointer`}
          >
            <p
              className="dropdown-item py-5 px-3 mb-0 font-normal text-gray-700 hover-effect"
              href="#"
            >
              <i className="las la-image text-primary"></i>
              <br />
              Capture
            </p>
          </li>
          <li
            onClick={() => (view === "Text" ? setView("") : setView("Text"))}
            className={`${view === "Text" && "active-record"} cursor-pointer`}
          >
            <p
              className="dropdown-item text-sm py-5 px-3 mb-0 font-normal text-gray-700 hover-effect"
              href="#"
            >
              <i className="las la-file-alt text-primary"></i>
              <br />
              Text
            </p>
          </li>
        </ul>
      </div>
    </>
  );
};

export default MainPopup;
