import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import asyncComponent from '../../itrust_common/components/AsyncComponent';

const AllPayments = asyncComponent(() =>
  import('../../containers/authorised/paymentReport/allPayments/AllPayments')
);

const ClaimDetails = asyncComponent(() =>
  import('../../containers/authorised/paymentReport/ClaimDetails')
);

const AgedClaim = asyncComponent(() =>
  import('../../containers/authorised/paymentReport/AgedClaim')
);

const NetSaleByPayer = asyncComponent(() =>
  import('../../containers/authorised/paymentReport/NetSaleByPayer')
);

const OrderDetail = asyncComponent(() =>
  import('../../containers/authorised/paymentReport/OrderDetail')
);

const DailyReport = asyncComponent(() =>
  import('../../containers/authorised/paymentReport/DailyReport')
);

const SellingReport = asyncComponent(() =>
  import('../../containers/authorised/paymentReport/SellingReport')
);

const InsuranceBalanceDue = asyncComponent(() =>
  import('../../containers/authorised/paymentReport/InsuranceBalanceDue')
);

const CashBalanceDue = asyncComponent(() =>
  import('../../containers/authorised/paymentReport/CashBalanceDue')
);

const PaymentReportRoutes = (
  <Switch>
    <Route exact path="/payment-report/all-payments" component={AllPayments} />
    <Route exact path="/payment-report/insurance-balance-due" component={InsuranceBalanceDue} />
    <Route exact path="/payment-report/cash-payment-balance-due" component={CashBalanceDue} />
    <Route exact path="/payment-report/claim-detail" component={ClaimDetails} />
    <Route exact path="/payment-report/aged-claim" component={AgedClaim} />
    <Route exact path="/payment-report/net-sale-by-payer" component={NetSaleByPayer} />
    <Route exact path="/payment-report/order-detail" component={OrderDetail} />
    <Route exact path="/payment-report/daily-report" component={DailyReport} />
    <Route exact path="/payment-report/selling-report" component={SellingReport} />
    <Redirect exact from="/payment-report" to="/payment-report/all-payments" />
  </Switch>
);

export default PaymentReportRoutes;