import Toastr from "../../../components/Toastr"
const parent = 'SETTING_'

const DEFAULT_STATE = {
  misc_order_items: [],
  misc_order_item: {},
  meta: {pagination: {}},
  saving: false
}
let changes = null;
export default function miscItemReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let miscOrderItems = [];
  let miscOrderItemData = [];
  let updatedRecord = {};
  switch(action.type){
    case `${parent}MISC_ITEMS_LOAD_PENDING`:
    case `${parent}MISC_ITEM_LOAD_PENDING`:
      return {...state, saving: false};

    case `${parent}MISC_ITEM_CREATE_PENDING`:
    case `${parent}MISC_ITEM_UPDATE_PENDING`:
      return {...state, saving: true};

    case `${parent}MISC_ITEMS_LOAD_REJECTED`:
    case `${parent}MISC_ITEM_LOAD_REJECTED`:
    case `${parent}MISC_ITEM_CREATE_REJECTED`:
    case `${parent}MISC_ITEM_UPDATE_REJECTED`:
      Toastr.handleShow(response.data.success, response.data.error, response.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case `${parent}MISC_ITEMS_LOAD_FULFILLED`:
      changes = {
        ...response,
        saving: false
      };
      return {...state, ...changes};
    
    case `${parent}MISC_ITEM_LOAD_FULFILLED`:
      changes = {
        misc_order_item: response.misc_order_item,
        saving: false
      }
      return {...state,...changes};
    
    case `${parent}MISC_ITEM_CREATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      miscOrderItems = [...state.misc_order_items]
      let newRecord = response.misc_order_item
      changes = {
        misc_order_items: [...miscOrderItems, newRecord],
        editing: false,
        saving: false
      }
      return {...state, ...changes};

    case `${parent}MISC_ITEM_UPDATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      miscOrderItems = [...state.misc_order_items];
      updatedRecord = response.misc_order_item;
      for (const [index, miscOrderItem] of miscOrderItems.entries()) {
        if (miscOrderItem.id === updatedRecord.id) {
          miscOrderItemData = [
            ...miscOrderItems.slice(0, index),
            updatedRecord,
            ...miscOrderItems.slice(index + 1)
          ];
        }
      }
      changes = {
        misc_order_items: miscOrderItemData,
        editing: false,
        saving: false
      };
      return { ...state, ...changes };

    case `${parent}MISC_ITEM_DELETE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      miscOrderItems = [...state.misc_order_items];
      for (const [index, miscOrderItem] of miscOrderItems.entries()) {
        if (miscOrderItem.id === response.misc_order_item.id) {
          miscOrderItems.splice(index, 1);
        }
      }
      return {
        ...state,
        misc_order_items: miscOrderItems,
        error: null
      };

    case `${parent}MISC_ITEM_DUPLICATE`:
      changes = {
        editing: {
          id: 'New',
        },
        misc_order_item: {...action.miscItem, id: null},
      };
      return { ...state, ...changes };
  
    case `${parent}MISC_ITEM_EDIT`:
      changes = {
        editing: {
          id: action.id,
        },
        misc_order_item: {  }
      };
      return { ...state, ...changes };

    case `${parent}MISC_ITEM_EDIT_CANCEL`:
      changes = {
        editing: false
      };
      return { ...state, ...changes };

    default:
      return state;
  }
}