import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as $ from 'jquery';

import { examEditCancel, examCreate, examEdit, examLoad } from '../../../redux/actions/exam/examActions';
import { appointmentLoad } from '../../../redux/actions/appointmentActions';
import { patientMedicalHistoriesLoad } from '../../../redux/actions/patient/patientMedicalHistoryActions';
import { patientReportsLoad } from '../../../itrust_common/redux/actions/settingActions/patientReportActions';

import NewExamForm from '../exam/NewExamForm';
import { ModalFormWrapper } from '../../../itrust_common/components/others'
import { handleStartExamRecording } from '../exam/CommonFunctions';
import { onBoardingTourInfoReset } from '../../../redux/actions/onBoardingActions';

class CreateExamModal extends Component {

  handleExamEditCancel() {
    const { activeTourType } = this.props
    if (activeTourType === 'create_exam') {
      this.props.dispatch(onBoardingTourInfoReset())
    }
    this.props.dispatch(examEditCancel());
    $('#newExamForm-modal').modal('hide');
  }

  handleExamSubmit(data) {
    const { examEditing, activeTourType } = this.props
    if (activeTourType === 'create_exam') {
      this.handleExamOnboardingTour()
    }
    new Promise((resolve, reject) => {
      this.props.dispatch(examCreate(data, { include: '*' }))
        .then((response) => {
          //Load appointment if exam created from appointment
          if (examEditing && examEditing.data && examEditing.data.appointmentId)
            this.props.dispatch(appointmentLoad(examEditing.data.appointmentId, { include: ['patient', 'staff', 'procedures', 'examination', 'order'] }))

          // Open exam Edit model just after creation
          var id = response.value.data.exam.id
          this.props.dispatch(examEdit(id));
          new Promise((resolve, reject) => {
            this.props.dispatch(examLoad(id, { include: '*' }))
              .then((response) => {
                var res = response.value.data.exam
                //Medical history for overview
                res.is_telemed && handleStartExamRecording()
                this.props.dispatch(patientMedicalHistoriesLoad(res.patient_id, { rpp: 500 }));
                //Prescription report template
                this.props.dispatch(patientReportsLoad({ patient_id: res.patient_id, staff_id: res.staff_id, store_id: res.store_id, filter: { category: ["medical_rx", "referral"] } }))
              })
          })
          $('#newExamForm-modal').modal('hide');
          $('#editExamForm-modal').modal('show');
        })
    })
  }

  handleExamOnboardingTour() {
    const { activeTourType } = this.props
    $('#newExamForm-modal').modal('hide');
    this.props.dispatch(onBoardingTourInfoReset(activeTourType))
  }

  componentDidUpdate() {
    if (!this.props.examEditing) {
      $('#newExamForm-modal').modal('hide');
    }
  }

  render() {
    const { examEditing, saving } = this.props;
    return (
      <ModalFormWrapper
        formName={`newExamForm`}
        saving={saving}
        formTitle="New Exam"
        label='Create Exam'
        handleEditCancel={this.handleExamEditCancel.bind(this)}
      >
        {examEditing.id == 'New' &&
          <NewExamForm
            onSubmit={this.handleExamSubmit.bind(this)}
          />
        }
      </ModalFormWrapper>
    );
  }
}

function mapStateToProps(state) {
  const { exam, patient, currentStore, onBoarding } = state;
  return {
    examEditing: exam.editing,
    saving: exam.saving,
    patient: patient.patient,
    store: currentStore.store,
    activeTourType: onBoarding.activeTourType,
  };
}

export default connect(mapStateToProps)(CreateExamModal);
