import React from 'react';
import { Field, change } from 'redux-form';

import { Input, InputWithIcon, AutoCompleteSelect } from '../../../../../../itrust_common/components/form';

import DiscountField from '../../DiscountField';

let Addon = props => {
  const {fields, pprops} = props;
  return (
    <div className="row mx-0 mb-7 bg-light border-top border-top-2">
      <div className="col-12 p-5 pt-6">
        <div className="d-flex align-items-center justify-content-between">
          <h6>Add-on Details</h6>
          <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => { fields.push({}); setTimeout(() => pprops.submit(), 500) }}>
            Add New
          </button>
        </div>
      </div>
      <div className="col-12 p-5">
        <div className="overview-table border rounded border-2">
          <table className="table table-hover shadow-none">
            <thead className="text-dark border-bottom bg-light border-bottom-2">
              <tr>
                <th scope="col" className="w-25">Add-on</th>
                <th scope="col">Unit Price</th>
                <th scope="col">Copay</th>
                <th scope="col">Discount</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {fields.map((member, index, members) => (
                [<tr className="text-dark bg-light ">
                  <td>
                    <Field 
                      name={`${member}[settings_eyeglass_addon_id]`} 
                      displayName={`${member}[addon_name]`} 
                      component={AutoCompleteSelect} 
                      onChangeHook={(data) => {pprops.dispatch(change('orderEyeglassForm', `${member}[settings_eyeglass_addon_id]`, data.id)); setTimeout(() => pprops.submit(), 1000) } }
                      klass="Settings::EyeglassAddon" 
                      textField="name" 
                      valueField="id" 
                      placeholder="Placeholder" 
                      className="form-control"
                      wrapperClasses="addon-col-width"
                    />
                  </td>
                  <td>
                    <Field name={`${member}[unit_price]`} component={InputWithIcon} title="$" className="form-control disabled bg-light" />
                  </td>
                  <td>
                    <Field name={`${member}[copay]`} component={InputWithIcon} title="$" className="form-control" />
                  </td>
                  <td>
                    <DiscountField
                      cashName={`${member}[cash_discount]`}
                      percentageName={`${member}[percentage_discount]`}
                      discountTypeName={`${member}[discount_type]`}
                      defaultType={members.get(index).discount_type}
                    />
                  </td>
                  <td>
                    <span className="badge badge-pill badge-danger"
                      onClick={() => { pprops.dispatch(change('orderEyeglassForm', `${member}[_destroy]`, true)); setTimeout(() => pprops.submit(), 1000) }}>
                      <i className="la la-trash"/>
                    </span>
                  </td>
                </tr>,
                <tr className="text-dark bg-light border-bottom">
                  <td colSpan="5">
                    <Field name={`${member}[details]`} component={Input} className="form-control" />
                  </td>
                  <td></td>
                </tr>]
              ))}
            </tbody>
          </table>
       </div>
      </div>
    </div>
  )
}
export default Addon;