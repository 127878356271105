import Toastr from "../../../../itrust_common/components/Toastr";

const DEFAULT_STATE = {
  account: {},
  wallet_details: {},
  saving: false,
  payout_logs: [],
}

let changes = null;

export const itrustPayReducer = (state = DEFAULT_STATE, action) => {
  const response = action.payload && (action.payload.data || action.payload.response)
  switch (action.type) {

    case 'ITRUST_PAY_ONBOARDING_PENDING':
    case 'ITRUST_PAY_ONBOARDING_UPDATE_PENDING':
      return { ...state, saving: true }

    case 'ITRUST_PAY_ONBOARDING_REJECTED':
    case 'ITRUST_PAY_ONBOARDING_UPDATE_REJECTED':
    case 'ITRUST_PAY_PAYOUTS_LOAD_REJECTED':
    case 'ITRUST_PAY_WALLET_LOAD_REJECTED':
      Toastr.handleShow(false, response.data.error, response.data.status)
      return { ...state, saving: false }

    case 'ITRUST_PAY_ONBOARDING_UPDATE_FULFILLED':
    case 'ITRUST_PAY_ONBOARDING_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      changes = { ...response, saving: false }
      return { ...state, ...changes }

    case 'ITRUST_PAY_PAYOUTS_LOAD_FULFILLED':
      changes = { payout_logs: response["add_ons/itrust_pay/payout_logs"], saving: false }
      return { ...state, ...changes }

    case 'ITRUST_PAY_WALLET_LOAD_FULFILLED':
      changes = { ...response, saving: false }
      return { ...state, ...changes }

    default:
      return state;
  }
}