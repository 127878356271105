import React, { Component } from 'react';
import { FieldArray } from 'redux-form';

import Glass from './partials/Glass'
import Lens from './partials/Lens'
import Addon from './partials/Addon'
import Frame from './partials/Frame'
import PupillaryDistance from './partials/PupillaryDistance'
import Benefits from './partials/Benefits'

class LensForm extends Component {

  render() {
    const { member, pprops, currentEyeglass } = this.props
    return (
      <div>
        <Glass member={member} pprops={pprops}/>
        <div className="col-12">
          <div className="row">
            <div className="col-5 py-6 border-top border-top-2 border-light">
              <Lens member={member} currentEyeglass={currentEyeglass} pprops={pprops}/>
              <FieldArray name={`${member}[eyeglass_addons_attributes]`}
                props = {{
                  pprops: pprops
                }}
                component={Addon} />

            </div>
            <div className="col-7 py-6 border-top border-top-2 border-light">
              <Frame member={member} currentEyeglass={currentEyeglass} pprops={pprops}/>
              <PupillaryDistance member={member}/>
            </div>
          </div>
          <Benefits member={member} currentEyeglass={currentEyeglass}/>
        </div>
      </div>
    );
  }

}

export default LensForm;
