import React from 'react';
import DisplayField from '../../../../../itrust_common/components/others/DisplayField'

let VisualAcuity = props => {
  const { fieldsValues, template} = props;
  return (
    <div className="row">
      <div className="col-12">
        <div className="overview-table mb-3">
          <table className="table table-bordered bg-light table-hover shadow-none border border-muted">
            <thead className="text-dark border border-muted">
              <tr className="text-center">
                <th scope="col"></th>
                <th scope="col">
                  <DisplayField
                    isVisible={true}
                    label={'Distance'}
                  />
                </th>
                <th scope="col">
                  <DisplayField
                    isVisible={true}
                    label={'Near'}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {["od", "os", "ou"].map(type =>
                <tr className="text-center">
                  <td>
                    <h6>{type.toUpperCase()}</h6>
                  </td>
                  <td>
                    <DisplayField
                      isVisible={template.fields[`distance_${type}`]['visible']}
                      // label={template.fields.distance_od.custom_label}
                      value={fieldsValues[`${type}_distance`]}
                    />
                  </td>
                  <td>
                    <DisplayField
                      isVisible={template.fields[`near_${type}`]['visible']}
                      // label={template.fields.near_od.custom_label}
                      value={fieldsValues[`${type}_near`]}
                    />
                  </td>
                </tr>
                )}
            </tbody>
          </table>
        </div>
        { fieldsValues.notes && <DisplayField
          isVisible={template.fields.notes.visible}
          label={`${template.fields.notes.custom_label}:`}
          value={fieldsValues.notes}
          className=""
        /> }
      </div>
    </div>
  )
}


export default VisualAcuity;