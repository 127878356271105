import React from 'react';
import { Route, Switch } from 'react-router-dom';
import asyncComponent from '../../../components/AsyncComponent';

const Contact = asyncComponent(() =>
  import('../../../containers/authorised/setting/contact/contact')
)

const contactRoutes = (
  <Switch>
    <Route exact path="/settings/contact" component={Contact} />
  </Switch>
);

export default contactRoutes;