import React from 'react';
import { Route, Switch } from 'react-router-dom';
import asyncComponent from '../../components/AsyncComponent';


const Activity = asyncComponent(() =>
  import('../../containers/authorised/activity/Activity')
);

const ActivityRoutes = (
  <Switch>
    <Route exact path="/activities" component={Activity} />
  </Switch>
);
export default ActivityRoutes;
