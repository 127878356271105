import React from 'react'
import IncomingButtons from '../callingButtons/IncomingButtons'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getShortName } from '../../../../../../itrust_common/components/HelperFunctions'
import { bandwidthIncomingCallReject, callPopUpEdit, callingInformationUpdate } from '../../../../../../redux/actions/addOns/voip/voipActions'
import { contactLoad } from '../../../../../../redux/actions/addOns/contactListsAction'
import { patientMessagesLoad } from '../../../../../../redux/actions/patient/patientMessageActions'

function PatientIncoming(props) {
  const { handleMakeOutGoingCall, dispatch, callInfo, staffId, contact, filters, telecomServiceType } = props

  const handleIncomingMessage = async (contactId) => {
    const callLogId = callInfo.incoming_info?.call_log_id
    telecomServiceType === 'bandwidth' ?
    dispatch(bandwidthIncomingCallReject({ call_log_id: callLogId }))
    : await callInfo?.call.reject()
    dispatch(callingInformationUpdate({ call: callInfo?.call, callStatus: 'call_ended', isCallActive: false }))
    let filter = { ...filters, sort: "send_at", direction: 'asc', rpp: 999999999, include: ['patient', 'store', 'staff','message_template'] }
    await dispatch(contactLoad(contactId, { include: '*' }))
    await dispatch(patientMessagesLoad({ ...filter, patient_id: contactId }))
    dispatch(callPopUpEdit('patient_message'))
  }

  return (
    <div className='p-4'>
      <div className='d-flex justify-content-between align-items-center'>
        <div className='d-flex align-items-center'>
          {getShortName(contact, 'mr-4 width-4 height-4', 'mr-4 width-4 height-4 font-size-24 ')}
          <div className='d-flex flex-column'>
            <small className='text-danger'>Incoming Call</small>
            {callInfo?.isAnonymous ? <strong>Anonymous</strong> : <strong>{contact?.name}</strong>}
            <span>{callInfo?.incoming_info?.from} </span>
          </div>
        </div>
        <div className='d-flex'>
          {!callInfo?.isAnonymous && <button className='btn p-0 rounded-circle mr-5 font-size-20' onClick={() => handleIncomingMessage(contact?.id)}>
            <i className="las la-comment-dots border border-secondary rounded-circle text-secondary p-2 pointer"></i>
          </button>}
          <IncomingButtons call={callInfo?.call} callInfo={callInfo} dispatch={dispatch} handleMakeOutGoingCall={() => handleMakeOutGoingCall()} isCallActive={callInfo?.isCallActive} staffId={staffId} isAnonymous={callInfo?.isAnonymous} telecomServiceType={telecomServiceType} />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  const { voip, auth, filter, contact } = state
  return {
    callInfo: voip?.calling_info,
    staffId: auth.user?.id,
    filters: filter.filters,
    contact: contact.contact_list,
  }
}

export default connect(mapStateToProps)(PatientIncoming)