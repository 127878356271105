import Toastr from "../../../components/Toastr"
const parent = 'SETTING_'

const DEFAULT_STATE = {
  contact_lens_cleaning_solutions: [],
  contact_lens_cleaning_solution: {},
  meta: {pagination: {}},
  saving: false
}
let changes = null;
export default function contactLensCleaningSolutionReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let contactLensCleaningSolutions = [];
  let contactLensSleaningSolutionData = [];
  let updatedRecord = {};
  switch(action.type){
    case `${parent}CONTACT_LENS_CLEANING_SOLUTIONS_LOAD_PENDING`:
    case `${parent}CONTACT_LENS_CLEANING_SOLUTION_LOAD_PENDING`:
      return {...state, saving: false};

    case `${parent}CONTACT_LENS_CLEANING_SOLUTION_CREATE_PENDING`:
    case `${parent}CONTACT_LENS_CLEANING_SOLUTION_UPDATE_PENDING`:
      return {...state, saving: true};

    case `${parent}CONTACT_LENS_CLEANING_SOLUTIONS_LOAD_REJECTED`:
    case `${parent}CONTACT_LENS_CLEANING_SOLUTION_LOAD_REJECTED`:
    case `${parent}CONTACT_LENS_CLEANING_SOLUTION_CREATE_REJECTED`:
    case `${parent}CONTACT_LENS_CLEANING_SOLUTION_UPDATE_REJECTED`:
    case `${parent}CONTACT_LENS_CLEANING_SOLUTION_DELETE_REJECTED`:
      Toastr.handleShow(response.data.success, response.data.error, response.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case `${parent}CONTACT_LENS_CLEANING_SOLUTIONS_LOAD_FULFILLED`:
      changes = {
        ...response,
        saving: false
      };
      return {...state, ...changes};
    
    case `${parent}CONTACT_LENS_CLEANING_SOLUTION_LOAD_FULFILLED`:
      changes = {
        contact_lens_cleaning_solution: response.contact_lens_cleaning_solution,
        saving: false
      }
      return {...state,...changes};
    
    case `${parent}CONTACT_LENS_CLEANING_SOLUTION_CREATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      contactLensCleaningSolutions = [...state.contact_lens_cleaning_solutions]
      let newRecord = response.contact_lens_cleaning_solution
      changes = {
        contact_lens_cleaning_solutions: [...contactLensCleaningSolutions, newRecord],
        editing: false,
        saving: false
      }
      return {...state, ...changes};

    case `${parent}CONTACT_LENS_CLEANING_SOLUTION_UPDATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      contactLensCleaningSolutions = [...state.contact_lens_cleaning_solutions];
      updatedRecord = response.contact_lens_cleaning_solution;
      for (const [index, contactLensCleaningSolution] of contactLensCleaningSolutions.entries()) {
        if (contactLensCleaningSolution.id === updatedRecord.id) {
          contactLensSleaningSolutionData = [
            ...contactLensCleaningSolutions.slice(0, index),
            updatedRecord,
            ...contactLensCleaningSolutions.slice(index + 1)
          ];
        }
      }
      changes = {
        contact_lens_cleaning_solutions: contactLensSleaningSolutionData,
        editing: false,
        saving: false
      };
      return { ...state, ...changes };

    case `${parent}CONTACT_LENS_CLEANING_SOLUTION_DELETE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      contactLensCleaningSolutions = [...state.contact_lens_cleaning_solutions];
      for (const [index, contactLensCleaningSolution] of contactLensCleaningSolutions.entries()) {
        if (contactLensCleaningSolution.id === response.contact_lens_cleaning_solution.id) {
          contactLensCleaningSolutions.splice(index, 1);
        }
      }
      return {
        ...state,
        contact_lens_cleaning_solutions: contactLensCleaningSolutions,
        error: null
      };

    case `${parent}CONTACT_LENS_CLEANING_SOLUTION_EDIT`:
      changes = {
        editing: {
          id: action.id,
        },
        contact_lens_cleaning_solution: {}
      };
      return { ...state, ...changes };

    case `${parent}CONTACT_LENS_CLEANING_SOLUTION_EDIT_CANCEL`:
      changes = {
        editing: false
      };
      return { ...state, ...changes };

    default:
      return state;
  }
}