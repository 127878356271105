import React from 'react'
import _ from 'lodash';

export default function TourProgressBar(props) {
  const { onboardingGuide, userRole, organisationStatus, moduleProgressHide } = props

  const calTourProgressBarStatus = () => {
    // Count the number of true values
    const trueCount = getTrueCount();
    // Total number of steps
    const totalSteps = getTotalSteps()
    // Calculate the progress percentage
    const progress = Math.round((trueCount / totalSteps) * 100);
    return progress;
  }

  const getTotalSteps = () => {
    if (["super_admin", "itrust_admin"].includes(userRole) && organisationStatus === 'trial') {
      return 6
    }
    else {
      return 5
    }
  }

  const getTrueCount = () => {
    return Object.values(onboardingGuide)?.filter(val => val === true).length
  }

  return (
    <div className={`${moduleProgressHide ? 'my-4' : 'my-6'}`}>
      <div className="progress">
        <div className="progress-bar font-size-18" role="progressbar" style={{ width: `${calTourProgressBarStatus()}%` }} aria-valuenow={`${calTourProgressBarStatus()}`} aria-valuemin="0" aria-valuemax="100">{`${calTourProgressBarStatus()}%`}</div>
      </div>
    </div>
  )
}
