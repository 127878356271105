import React, { Component} from 'react';
import Header from '../../../itrust_common/components/authorised/layouts/Topbar.js';
// import Sidebar from '../../itrust_common/components/authorised/layouts/Sidebar';
import PatientRoutes from '../patientRoutes';
import {connect} from 'react-redux';
import StoreSelection from '../../../components/authorised/layouts/StoreSelection'
import PatientSignUp from '../../../containers/authorised/patient/PatientSignUp';

const headerMenu = [];

class Authorised extends Component{
  render(){
    const { layout, userType, currentUser, meta, organisation, patient } =this.props
    return(
      <div className={`${layout !=='default' && 'd-none'}`}>
        <Header 
          userType={userType} 
          headerMenu={headerMenu} 
          handleLogout={this.props.handleLogout}
          storeSelectionComponent= {<StoreSelection currentUser={currentUser}/>}
        />
        <PatientSignUp currentUser={currentUser} meta={meta} organisation={organisation} patient={patient} />
        <div className="main-div">
          <div className="main-content-patient-profile position-relative px-5 bg-light">
            {PatientRoutes}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { other, auth, organisation: { organisation }, patient: { patient } } = state;
  return {
    layout: other.layout,
    meta: auth.meta,
    organisation,
    patient,
  }
}

export default connect(mapStateToProps)(Authorised);