import Toastr from "../../../components/Toastr"
const parent = 'SETTING_'

const DEFAULT_STATE = {
  eyeglass_addons: [],
  eyeglass_addon: {},
  meta: {pagination: {}},
  saving: false
}
let changes = null;
export default function eyeglass_addonReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let eyeglassAddons = [];
  let eyeglassAddonData = [];
  let updatedRecord = {};
  switch(action.type){
    case `${parent}EYEGLASS_ADDONS_LOAD_PENDING`:
    case `${parent}EYEGLASS_ADDON_LOAD_PENDING`:
      return {...state, saving: false};
    
    case `${parent}EYEGLASS_ADDON_CREATE_PENDING`:
    case `${parent}EYEGLASS_ADDON_UPDATE_PENDING`:
      return {...state, saving: true};

    case `${parent}EYEGLASS_ADDONS_LOAD_REJECTED`:
    case `${parent}EYEGLASS_ADDON_LOAD_REJECTED`:
    case `${parent}EYEGLASS_ADDON_CREATE_REJECTED`:
    case `${parent}EYEGLASS_ADDON_UPDATE_REJECTED`:
    case `${parent}EYEGLASS_ADDON_DELETE_REJECTED`:
      Toastr.handleShow(response.data.success, response.data.error, response.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case `${parent}EYEGLASS_ADDONS_LOAD_FULFILLED`:
      changes = {
        ...response,
        saving: false
      };
      return {...state, ...changes};
    
    case `${parent}EYEGLASS_ADDON_LOAD_FULFILLED`:
      changes = {
        eyeglass_addon: response.eyeglass_addon,
        saving: false
      }
      return {...state,...changes};
    
    case `${parent}EYEGLASS_ADDON_CREATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      eyeglassAddons = [...state.eyeglass_addons]
      let newRecord = response.eyeglass_addon
      changes = {
        eyeglass_addons: [...eyeglassAddons, newRecord],
        editing: false,
        saving: false
      }
      return {...state, ...changes};

    case `${parent}EYEGLASS_ADDON_UPDATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      eyeglassAddons = [...state.eyeglass_addons];
      updatedRecord = response.eyeglass_addon;
      for (const [index, eyeglassAddon] of eyeglassAddons.entries()) {
        if (eyeglassAddon.id === updatedRecord.id) {
          eyeglassAddonData = [
            ...eyeglassAddons.slice(0, index),
            updatedRecord,
            ...eyeglassAddons.slice(index + 1)
          ];
        }
      }
      changes = {
        eyeglass_addons: eyeglassAddonData,
        editing: false,
        saving: false
      };
      return { ...state, ...changes };

    case `${parent}EYEGLASS_ADDON_DELETE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      eyeglassAddons = [...state.eyeglass_addons];
      for (const [index, eyeglassAddon] of eyeglassAddons.entries()) {
        if (eyeglassAddon.id === response.eyeglass_addon.id) {
          eyeglassAddons.splice(index, 1);
        }
      }
      return {
        ...state,
        eyeglass_addons: eyeglassAddons,
        error: null
      };

    case `${parent}EYEGLASS_ADDON_DUPLICATE`:
      changes = {
        editing: {
          id: 'New',
        },
        eyeglass_addon: {...action.eyeglassAddOn, id: null},
      };
      return { ...state, ...changes };

    case `${parent}EYEGLASS_ADDON_EDIT`:
      changes = {
        editing: {
          id: action.id,
        },
        eyeglass_addon: {  }
      };
      return { ...state, ...changes };

    case `${parent}EYEGLASS_ADDON_EDIT_CANCEL`:
      changes = {
        editing: false
      };
      return { ...state, ...changes };

    default:
      return state;
  }
}