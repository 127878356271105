import React, { Component } from 'react';
import { getModeOfNotification } from '../HelperFunctions';
export default class Input extends Component {
  render() {
    const {
      props,
      props: { input, disabled, placeholder, autoComplete, wrapperClasses, label, isModeOfNotificationDefaultVal, readOnly },
      props: { meta: { touched, error, warning } }
    } = this;
    return (
      <div className={`${disabled && 'disabled'} ${wrapperClasses}`}>
        {label !== undefined && <label>{props.label}</label>}
        <input
          {...input}
          value={isModeOfNotificationDefaultVal ? getModeOfNotification(input.value) : input.value}
          autoComplete={autoComplete}
          placeholder={placeholder}
          type={props.type}
          readOnly={readOnly}
          disabled={props.disabled || false}
          className={`${props.className} ${touched && error && 'is-invalid'}`}
        />
        {touched &&
          ((error && <span className="invalid-feedback text-danger d-inline la-sm">{error}</span>) ||
            (warning && <span>{warning}</span>))}
        {/* { touched && (error && <i className={`la la-warning text-danger ${label === undefined ? 'error-icon' : 'error-icon-label'}`}></i>)} */}
      </div>
    );
  }
}
