import React from 'react';
import { Route, Switch, Redirect} from 'react-router-dom';
import asyncComponent from '../../../components/AsyncComponent';

const Patient = asyncComponent(() =>
  import('../../../containers/authorised/setting/messageTemplate/Patient.js')
)

const Appointment = asyncComponent(() =>
  import('../../../containers/authorised/setting/messageTemplate/Appointment.js')
)

const Exam = asyncComponent(() =>
  import('../../../containers/authorised/setting/messageTemplate/Exam.js')
)

const Order = asyncComponent(() =>
  import('../../../containers/authorised/setting/messageTemplate/Order.js')
)

const Other = asyncComponent(() =>
  import('../../../containers/authorised/setting/messageTemplate/Other.js')
)

const Signature = asyncComponent(() =>
  import('../../../containers/authorised/setting/messageTemplate/Signature.js')
)

const MessageTemplateRoutes = (
  <Switch>
    <Route exact path="/settings/message-templates/patient" component={Patient} />
    <Route exact path="/settings/message-templates/appointment" component={Appointment} />
    <Route exact path="/settings/message-templates/exam" component={Exam} />
    <Route exact path="/settings/message-templates/order" component={Order} />
    <Route exact path="/settings/message-templates/other" component={Other} />
    <Route exact path="/settings/message-templates/signature" component={Signature} />
    <Redirect exact from="/settings/message-templates" to="/settings/message-templates/appointment" />
  </Switch>
);

export default MessageTemplateRoutes;