import {handleAPI, API_VERSION_ONE} from '../../../utils/apiUtils';

export function reviewOfSystemsLoad(filters){
  const url = `/${API_VERSION_ONE}/settings/review_of_systems`;
  const method = 'GET'
  return {
    type: 'REVIEW_OF_SYSTEMS_LOAD',
    payload: handleAPI(url, filters, method)
  };
}

export function reviewOfSystemLoad(id, filters){
  const url = `/${API_VERSION_ONE}/settings/review_of_systems/${id}`;
  const method = 'GET'

  return {
    type: 'REVIEW_OF_SYSTEM_LOAD',
    payload: handleAPI(url,filters,method)
  };
}

export function reviewOfSystemCreate(data, filters){
  const url = `/${API_VERSION_ONE}/settings/review_of_systems`;
  const method= 'POST'
  return {
    type: 'REVIEW_OF_SYSTEM_CREATE',
    payload: handleAPI(url,filters,method, {review_of_system: {...data}})
  }
}

export function reviewOfSystemUpdate(data, filters){
  const url = `/${API_VERSION_ONE}/settings/review_of_systems/${data.id}`;
  const method = "PUT"
  return {
    type: 'REVIEW_OF_SYSTEM_UPDATE',
    payload: handleAPI(url,filters,method, {review_of_system: {...data}})
  }
}

export function reviewOfSystemDelete(id, filters) {
  const url = `/${API_VERSION_ONE}/settings/review_of_systems/${id}`;
  const method = 'DELETE';

  return {
    type: 'REVIEW_OF_SYSTEM_DELETE',
    payload: new Promise((resolve, reject) => {
      handleAPI(url, filters, method)
        .then(response => {
          resolve({ id: id, ...response });
        })
        .catch(err => {
          reject(err);
        });
    })
  };
}

export function reviewOfSystemEdit(id) {
  return {
    type: 'REVIEW_OF_SYSTEM_EDIT',
    id,
  };
}

export function reviewOfSystemEditCancel() {
  return {
    type: 'REVIEW_OF_SYSTEM_EDIT_CANCEL'
  };
}

export function reviewOfSystemUpdateAllToHide(data, filters) {
  const url = `/${API_VERSION_ONE}/settings/review_of_systems/update_all_to_hide`;
  const method = 'PUT';

  return {
    type: 'REVIEW_OF_SYSTEM_UPDATE_ALL_TO_HIDE',
    payload: handleAPI(url, filters, method, { review_of_system: { ...data } })
  };
}
