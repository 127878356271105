import React from 'react';
import DisplayField from '../../../../itrust_common/components/others/DisplayField'

let Hpi = props => {
  const { fieldsValues: {hpis_attributes}, template} = props;
  return (
    hpis_attributes.map((hpi, index) => (
      <div className="row">
        <div className="col-12">
          <div className="border-bottom">
            <h6 className="pb-3 pt-6">Chief Complaint {index+1}/ HPI</h6>
          </div>
        </div>
        <DisplayField
          isVisible={template.hpis[0].fields.chief_complaint.visible}
          label={`${template.hpis[0].fields.chief_complaint.custom_label}:`}
          value={hpi.chief_complaint}
          className="col-6 py-4"
        />
        <DisplayField
          isVisible={template.hpis[0].fields.location.visible}
          label={`${template.hpis[0].fields.location.custom_label}:`}
          value={hpi.location}
          className="col-2 py-4"
        />
        <DisplayField
          isVisible={template.hpis[0].fields.quality.visible}
          label={`${template.hpis[0].fields.quality.custom_label}:`}
          value={hpi.quality}
          className="col-2 py-4"
        />
        <DisplayField
          isVisible={template.hpis[0].fields.severity.visible}
          label={`${template.hpis[0].fields.severity.custom_label}:`}
          value={hpi.severity}
          className="col-2 py-4"
        />
        <DisplayField
          isVisible={template.hpis[0].fields.duration.visible}
          label={`${template.hpis[0].fields.duration.custom_label}:`}
          value={hpi.duration}
          className="col-2 py-4"
        />
        <DisplayField
          isVisible={template.hpis[0].fields.timing.visible}
          label={`${template.hpis[0].fields.timing.custom_label}:`}
          value={hpi.timing}
          className="col-2 py-4"
        />
        <DisplayField
          isVisible={template.hpis[0].fields.context.visible}
          label={`${template.hpis[0].fields.context.custom_label}:`}
          value={hpi.context}
          className="col-2 py-4"
        />
        <DisplayField
          isVisible={template.hpis[0].fields.modifying_factors.visible}
          label={`${template.hpis[0].fields.modifying_factors.custom_label}:`}
          value={hpi.modifying_factors}
          className="col-2 py-4"
        />
        <DisplayField
          isVisible={template.hpis[0].fields.associated_signs_and_symptoms.visible}
          label={`${template.hpis[0].fields.associated_signs_and_symptoms.custom_label}:`}
          value={hpi.associated_signs_and_symptoms}
          className="col-2 py-4"
        />

    </div>
  ) ))
}


export default Hpi;