import Toastr from "../../../itrust_common/components/Toastr"
const parent = 'ORGANISATION_'
const DEFAULT_STATE = {
  licenses: [],
  license: {},
  licensesCount: {},
  meta: {pagination: {count: 0}},
  saving: false
}
let changes = null;
export default function licenseReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  var responseId = action.payload && action.payload.id
  let licenses = [];
  let licenseData = [];
  let updatedRecord = {};
  switch(action.type){
    case `${parent}LICENSES_LOAD_PENDING`:
    case `${parent}LICENSE_LOAD_PENDING`:
      return {...state, saving: true};

    case `${parent}LICENSE_CREATE_PENDING`:
      return {...state, saving: true};

    case `${parent}LICENSES_LOAD_REJECTED`:
    case `${parent}LICENSE_LOAD_REJECTED`:
    case `${parent}LICENSE_CREATE_REJECTED`:
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case `${parent}LICENSES_LOAD_FULFILLED`:
      changes = {
        ...response,
        saving: false
      };
      return {...state, ...changes};
    
    case `${parent}LICENSE_LOAD_FULFILLED`:
      changes = {
        license: response.license,
        saving: false
      }
      return {...state,...changes};
    
    case `${parent}LICENSE_CREATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      licenses = [...state.licenses]
      let newRecord = response.licenses
      changes = {
        licenses: [...licenses, ...newRecord],
        editing: false,
        saving: false
      }
      return {...state, ...changes};

    case `${parent}LICENSE_UPDATE_FULFILLED`:
    case `${parent}LICENSE_ASSIGN_FULFILLED`:
    case `${parent}LICENSE_UNASSIGN_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      licenses = [...state.licenses];
      updatedRecord = response.license;
      for (const [index, license] of licenses.entries()) {
        if (license.id === updatedRecord.id) {
          licenseData = [
            ...licenses.slice(0, index),
            updatedRecord,
            ...licenses.slice(index + 1)
          ];
        }
      }
      changes = {
        licenses: licenseData,
        editing: false,
        saving: false
      };
      return { ...state, ...changes };

    case `${parent}LICENSE_DELETE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      licenses = [...state.licenses];
      for (const [index, license] of licenses.entries()) {
        if (license.id === responseId) {
          licenses.splice(index, 1);
        }
      }
      return {
        ...state,
        licenses: licenses,
        error: null
      };

    case `${parent}LICENSE_EDIT`:
      changes = {
        editing: {
          id: action.id,
        },
        license: { address_attributes: {country: "United States"} }
      };
      return { ...state, ...changes };

    case `${parent}LICENSE_EDIT_CANCEL`:
      changes = {
        editing: false
      };
      return { ...state, ...changes };

    case `${parent}LICENSES_COUNT_LOAD_FULFILLED`:
      changes = {
        licensesCount: {...response},
      };
      return {...state, ...changes};
    
    default:
      return state;
  }
}