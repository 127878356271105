import React from 'react';
import DisplayField from '../../../../../itrust_common/components/others/DisplayField'

let Nct = props => {
  const { fieldsValues, template} = props;
  return (
    <div className="row">
      {fieldsValues && fieldsValues.map(item => 
        item.is_marked_complete && <div className="col-12">
          <div className="overview-table mb-3">
            <table className="table table-bordered bg-light table-hover shadow-none border border-muted">
              <tbody>
                {["od", "os", "time"].map(type =>
                  <tr className="text-center">
                    <td>
                      <h6>{type.toUpperCase()}</h6>
                    </td>
                      <td>
                        <DisplayField
                          isVisible={template.ncts[0].fields[type].visible}
                          value={type !== "time" ? item[type] : item[`nct_${type}`]}
                        />
                      </td>
                  </tr>
                  )
                }
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  )
}
export default Nct;