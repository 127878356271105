import React from 'react';
import { Field, reduxForm } from 'redux-form';

import {  AutoCompleteSelect  } from '../../../itrust_common/components/form';

let SecureFaxForm = props => {
  const { handleSubmit } = props;
  return (
    <div className="d-flex w-100">
      <Field label="Secure Fax"
        name="contact_id"
        displayName="company_name"
        component={AutoCompleteSelect} 
        klass="Contact"
        textField="company_name" 
        valueField="id" 
        placeholder=""
        className="form-control mx-4" 
        wrapperClasses="d-flex flex-row align-items-center w-100"
        labelClasses="text-nowrap mr-5" />
      <button className="btn btn-primary ml-5" onClick={props.submit}>Send</button>
    </div>
  );
}

SecureFaxForm = reduxForm({
  form: 'secureFaxForm',
  enableReinitialize: true,
})(SecureFaxForm)

export default SecureFaxForm;
