import React from 'react'
import { callPopUpEdit, callingInformationUpdate, receiveIncomingCall, bandwidthIncomingCallReject } from '../../../../../../redux/actions/addOns/voip/voipActions';
import { bandwidthIncomingCallInitialize, bandwidthRingtoneRinging } from '../BandwidthCallingHelpers';

export default function IncomingButtons(props) {
  const { call, handleMakeOutGoingCall, dispatch, callInfo, isCallActive, staffId, isAnonymous, telecomServiceType } = props

  const handleAcceptIncomingCall = async () => {
    const callLogId = callInfo.incoming_info?.call_log_id
    const data = await dispatch(receiveIncomingCall({ staff_id: staffId, call_log_id: callLogId }))
    telecomServiceType === 'bandwidth' ?
      bandwidthIncomingCallInitialize(data, dispatch) :
      await call.accept()
    dispatch(callingInformationUpdate({ call: call, callStatus: 'call_started' }))
    dispatch(callPopUpEdit('patient'))
    console.log("Incoming Call Accepted and Started.")
  }

  const handleRejectIncomingCall = async () => {
    if (telecomServiceType === 'bandwidth') {
      const callLogId = callInfo.incoming_info?.call_log_id
      dispatch(bandwidthIncomingCallReject({ call_log_id: callLogId }))
      bandwidthRingtoneRinging(false)
    }
    else {
      await call.reject();
    }
    dispatch(callingInformationUpdate({ call: call, callStatus: 'call_ended', isCallActive: false }))
    dispatch(callPopUpEdit(false))
  }

  const hangupIncomingCall = async () => {
    telecomServiceType === 'bandwidth' ? await call.terminate() : await call.disconnect()
    dispatch(callingInformationUpdate({ call: call, callStatus: 'call_ended', isCallActive: false }))
  }

  return (
    <>
      {/* {(call?.status() === 'pending' || call?.status() === 'ringing') && */}
      {(callInfo?.callStatus === 'ringing') &&
        <>
          <button className='btn p-0 rounded-circle mr-5 font-size-20' onClick={() => handleAcceptIncomingCall()}>
            <i className="las la-phone border border-primary rounded-circle text-primary p-2 pointer"></i>
          </button>
          <button className='btn p-0 rounded-circle mr-5 font-size-20' onClick={handleRejectIncomingCall}>
            <i className="las la-phone-alt border border-danger rounded-circle rotate-45 text-danger p-2 pointer"></i>
          </button>
        </>
      }
      {/* {(call?.status() === 'open' || call?.status() === 'reconnecting') && */}
      {(callInfo?.callStatus === 'call_started') &&
        <button className='btn p-0 rounded-circle mr-5 font-size-20' onClick={hangupIncomingCall}>
          <i className="las la-phone-alt border border-danger rounded-circle rotate-45 text-danger p-2 pointer"></i>
        </button>
      }
      {/* {(call?.status() === 'open' || call?.status() === 'reconnecting') && */}
      {(callInfo?.callStatus === 'call_ended') &&
        <button className={`btn p-0 rounded-circle mr-5 font-size-20 ${isCallActive && 'disabled'}`} onClick={() => handleMakeOutGoingCall()}>
          <i className="las la-phone border border-primary rounded-circle text-primary p-2 pointer"></i>
        </button>
      }
    </>
  )
}
