import Toastr from "../../../../itrust_common/components/Toastr"

const DEFAULT_STATE = {
  meta: { pagination: {} },
  two_way_report: {},
  sms_logs: [],
  notifications: [],
  saving: false,
}
let changes = null
let allNotifications=[]
let updatedRecord={}
let notificationsData=[]

export const twoWayReportReducer = (state = DEFAULT_STATE, action) => {
  const response = action.payload && (action.payload.data || action.payload.response)
  switch (action.type) {

    case 'TWO_WAY_REPORT_LOAD_PENDING':
    case 'TWO_WAY_SMS_LOGS_LOAD_PENDING':
    case 'TWO_WAY_NOTIFICATION_LOAD_PENDING':
      return { ...state, saving: true };

    case 'TWO_WAY_REPORT_LOAD_REJECTED':
    case 'TWO_WAY_SMS_LOGS_LOAD_REJECTED':
    case 'TWO_WAY_NOTIFICATION_LOAD_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = { saving: false }
      return { ...state, ...changes };

    case 'TWO_WAY_REPORT_LOAD_FULFILLED':
      changes = { two_way_report: { ...response }, saving: false }
      return { ...state, ...changes };

    case 'TWO_WAY_SMS_LOGS_LOAD_FULFILLED':
      changes = { ...response, meta: { ...state.meta, ...response.meta }, saving: false };
      return { ...state, ...changes };

    case 'TWO_WAY_NOTIFICATION_LOAD_FULFILLED':
      allNotifications = response.meta.messages_read === "All Messages are read" ? [...response.notifications] : [...state.notifications, ...response.notifications]
      changes = { ...response, notifications: [...allNotifications], saving: false };
      return { ...state, ...changes };

    case 'TWO_WAY_NOTIFICATION_UPDATE':
      allNotifications = [...state.notifications];
      updatedRecord = { ...action.data }
      let unreadCount = updatedRecord.read_at ? state.meta.unread_count - 1 : state.meta.unread_count + 1
      for (const [index, notification] of allNotifications.entries()) {
        if (notification.id === updatedRecord.id) {
          notificationsData = [
            ...allNotifications.slice(0, index),
            updatedRecord,
            ...allNotifications.slice(index + 1)
          ];
        }
      }
      changes = {
        notifications: notificationsData,
        meta: { ...state.meta, unread_count: unreadCount },
        saving: false
      };
      return { ...state, ...changes };

    case 'TWO_WAY_NOTIFICATION_CREATE':
      changes = {
        notifications: [action.data.notification, ...state.notifications],
        meta: { ...state.meta, unread_count: state.meta.unread_count + 1 },
        saving: false
      };
      return { ...state, ...changes }


    default:
      return state
  }
}