import React, {Component} from 'react';
import {reduxForm} from 'redux-form';
import { RadioGroup } from '../../../../itrust_common/components/form';
import { connect } from 'react-redux';
var timeout=null

class NeurologicalForm extends Component{
  render(){
    return (
      <div className="col-lg-6 col-md-12 mt-7">
        <div className="card border-0 shadow-sm">
          <div className="p-5 border-bottom border-light">
            <h6>Neurological Psychological Status</h6>
          </div>
          <div className="card-body px-7">
            <div className="row">
              <div className="col-3">
                <RadioGroup label="Agitated" name="neurological_psychological_status[agitated]" options={[{ label: 'Yes', value: true }, { label: 'No', value: false }]} wrapperClasses="" />
              </div>
              <div className="col-3">
                <RadioGroup label="Anxious" name="neurological_psychological_status[anxious]" options={[{ label: 'Yes', value: true }, { label: 'No', value: false }]} wrapperClasses="" />
              </div>
              <div className="col-3">
                <RadioGroup label="Depressed" name="neurological_psychological_status[depressed]" options={[{ label: 'Yes', value: true }, { label: 'No', value: false }]} wrapperClasses="" />
              </div>
              <div className="col-3">
                <RadioGroup label="Oriented x3" name="neurological_psychological_status[oriented_x3]" options={[{ label: 'Yes', value: true }, { label: 'No', value: false }]} wrapperClasses="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

NeurologicalForm = reduxForm({
  form: "neurologicalForm",
  enableReinitialize: true,
  onChange: (values, dispatch, props) => {
    if(props.dirty){
      clearTimeout(timeout);
      timeout = setTimeout(() => props.submit(), 1500);
    }
  },
})(NeurologicalForm)

NeurologicalForm = connect((state, ownProps) => {
  const { patient: {patient}, exam: {exam} } = state
  const obj = ownProps.examId ? exam : patient
  return {
    initialValues: {
      id: obj.id,
      neurological_psychological_status: obj.neurological_psychological_status
    }
  }
})(NeurologicalForm)

export default NeurologicalForm;
