import Toastr from "../../../components/Toastr"
const parent = 'SETTING_'

const DEFAULT_STATE = {
  message_templates: [],
  message_template: {},
  meta: {pagination: {}},
  keywords: {organisation: {}, patient: {}, store: {}, staff: {}, appointment: {}},
  saving: false
}
let changes = null;
export default function messageTemplateReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let messageTemplates = [];
  let messageTemplateData = [];
  let updatedRecord = {};
  switch(action.type){
    case `${parent}MESSAGE_TEMPLATES_LOAD_PENDING`:
    case `${parent}MESSAGE_TEMPLATE_LOAD_PENDING`:
      return {...state, saving: false};

    case `${parent}MESSAGE_TEMPLATE_CREATE_PENDING`:
    case `${parent}MESSAGE_TEMPLATE_UPDATE_PENDING`:
      return {...state, saving: true};

    case `${parent}MESSAGE_TEMPLATES_LOAD_REJECTED`:
    case `${parent}MESSAGE_TEMPLATE_LOAD_REJECTED`:
    case `${parent}MESSAGE_TEMPLATE_CREATE_REJECTED`:
    case `${parent}MESSAGE_TEMPLATE_UPDATE_REJECTED`:
    case `${parent}WHATSAPP_MESSAGE_TEMPLATE_UPDATE_REJECTED`:
    case `${parent}MESSAGE_TEMPLATE_DELETE_REJECTED`:
      Toastr.handleShow(response.data.success, response.data.error, response.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case `${parent}MESSAGE_TEMPLATES_LOAD_FULFILLED`:
      changes = {
        ...response,
        saving: false
      };
      return {...state, ...changes};

    case `${parent}MESSAGE_TEMPLATE_LOAD_FULFILLED`:
      changes = {
        message_template: { ...response.message_template, whatsapp_template: response.meta.whatsapp_template },
        saving: false
      }
      return { ...state, ...changes };

      case `${parent}MESSAGE_TEMPLATE_CREATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      messageTemplates = [...state.message_templates]
      let newRecord = response.message_template
      changes = {
        message_templates: [...messageTemplates, newRecord],
        editing: false,
        saving: false,
      }
      return {...state, ...changes};

    case `${parent}MESSAGE_TEMPLATE_UPDATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      messageTemplates = [...state.message_templates];
      updatedRecord = response.message_template;
      for (const [index, messageTemplate] of messageTemplates.entries()) {
        if (messageTemplate.id === updatedRecord.id) {
          messageTemplateData = [
            ...messageTemplates.slice(0, index),
            updatedRecord,
            ...messageTemplates.slice(index + 1)
          ];
        }
      }
      changes = {
        message_templates: messageTemplateData,
        editing: false,
        saving: false,
      };
      return { ...state, ...changes };

    case `${parent}MESSAGE_TEMPLATE_DELETE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      messageTemplates = [...state.message_templates];
      for (const [index, messageTemplate] of messageTemplates.entries()) {
        if (messageTemplate.id === response.message_template.id) {
          messageTemplates.splice(index, 1);
        }
      }
      return {
        ...state,
        message_templates: messageTemplates,
        error: null
      };

    case `${parent}MESSAGE_TEMPLATE_KEYWORDS_LOAD_FULFILLED`:
      changes = {
        keywords: response.keywords,
        saving: false
      }
      return {...state,...changes};
    

    case `${parent}MESSAGE_TEMPLATE_EDIT`:
      changes = {
        editing: {
          id: action.id,
        },
        message_template: {mode: "both", category: action.category, template_type: 'reminder'}
      };
      return { ...state, ...changes };

    case `${parent}MESSAGE_TEMPLATE_EDIT_CANCEL`:
      changes = {
        editing: false
      };
      return { ...state, ...changes };

    default:
      return state;
  }
}