import React, { Component } from 'react';

export default class ColorPicker extends Component {
  render() {
    const { props, props: { input, disabled, wrapperClasses, className, label } } = this;
    // const forId = `${input.name}-${input.value}-${id}`
    return (
      <div className={`${disabled && 'disabled'} ${wrapperClasses}`}>
        { label && <label>{props.label}</label> }
        <div>
          <input {...input} className={className} type="color" {...input}/>
          {/* <span className="ml-3">{input.value}</span> */}
        </div>
      </div>
    );
  }
}