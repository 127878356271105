import React from 'react';
import { FieldArray, change } from 'redux-form';
import LensForm from './LensForm'
class RenderLens extends React.Component {
  render() {
    const { template, isTemplate, fields, pprops } = this.props
    return (
      <span>
        {fields.map((member, index) => (
          <div className="card-body">
            <div className="row my-5">
              <div className="col">
                <span className="badge badge-dark">Contact Lens Rx {index + 1}</span>
              </div>
            </div>
            <LensForm
              {...this.props}
              currentContactLens={pprops.lensesAttributes[index] ? pprops.lensesAttributes[index] : { ...pprops.lensesAttributes[pprops.lensesAttributes.length - 1], id: null }}
              name={`finalized_lenses_attributes[${index}]`}
              baseName={`finalize_prescription[contact_lens][contact_lenses][0]`}
              fieldValues={template.finalize_prescription.contact_lens.contact_lenses[0]}
            />
            <div>
              <div>
                {!isTemplate && (fields.length === index + 1) &&
                  <button type="button" className="btn btn-primary float-right" onClick={() => { fields.push({ ...pprops.initialValues.finalized_lenses_attributes[fields.length - 1], id: null }); setTimeout(() => pprops.submit(), 500) }}>
                    Add New
                  </button>
                }
                {!isTemplate &&
                  <button type="button" className="btn btn-outline-primary mr-7 float-right" aria-label="Close" onClick={() => { pprops.dispatch(change('examFinalizePrescriptionForm', `${member}[_destroy]`, true)); setTimeout(() => pprops.submit(), 500) }}>
                    Remove Prescription
                  </button>
                }
              </div>
            </div>
          </div>
        ))}
      </span>
    );
  }
}

const ContactLensPrescription = props => {
  const { template, isTemplate, pprops } = props
  const name = isTemplate ? "finalize_prescription[contact_lens][contact_lenses]" : "finalized_lenses_attributes"
  return (
    <FieldArray name={name}
      props={{
        template: template,
        isTemplate: isTemplate,
        pprops: pprops,
      }}
      component={RenderLens} />
  );
}

export default ContactLensPrescription;