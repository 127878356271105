import React from 'react';
import { Field } from 'redux-form';

import { Textarea, InputWithIcon } from '../../../../../../itrust_common/components/form';

import DiscountField from '../../DiscountField';
import * as $ from 'jquery';

let Benefits = props => {
  const { member, currentEyeglass } = props;
  return (
    <div className="row">
      <div className="col-12 mb-6 border-top border-top-2 border-light pt-6">
        <h6>Benefits</h6>
      </div>
      <div className="col-6">
        <div className="row">
          <Field
            label="Material Copay"
            name={`${member}[material_copay]`}
            component={InputWithIcon}
            title="$"
            labelClasses="w-100"
            className="form-control"
            wrapperClasses="col-4 mb-5"
          />

          <Field
            label="Lens Allowance"
            name={`${member}[lens_allowance]`}
            component={InputWithIcon}
            title="$"
            labelClasses="w-100"
            className="form-control"
            wrapperClasses="col-4 mb-5"
          />

          <Field
            label="Frame Allowance"
            name={`${member}[frame_allowance]`}
            component={InputWithIcon}
            title="$"
            labelClasses="w-100"
            className="form-control"
            wrapperClasses="col-4 mb-5"
          />

          <Field
            label="Frame Only Discount"
            name={`${member}[frame_only_percentage_discount]`}
            component={InputWithIcon}
            titleAppend="%"
            labelClasses="w-100"
            className="form-control"
            wrapperClasses="col-4 mb-5"
          />

          <div className="col-4 mb-5">
            <label>Overall Discount</label>
            <DiscountField
              cashName={`${member}[overall_cash_discount]`}
              percentageName={`${member}[overall_percentage_discount]`}
              discountTypeName={`${member}[overall_discount_type]`}
              defaultType={currentEyeglass.overall_discount_type}
              prependClasses="py-2"
            />
          </div>
        </div>
      </div>

      <Field
        label="Comment"
        name={`${member}[notes]`}
        component={Textarea}
        rows="5"
        className="form-control"
        wrapperClasses="col-4 form-group px-7"
      />

      <Field
        label={<span>Total<small className="float-right btn-link pt-2"><i className="la la-sm la-info-circle" type="button" id={`eg-total-${currentEyeglass.id}`} data-toggle="tooltip" onMouseEnter={()=> $(`#eg-total-${currentEyeglass.id}`).tooltip('show')} onMouseLeave={()=> $(`#eg-total-${currentEyeglass.id}`)} data-placement="top" title="Total = lens_type_subtotal + lens_material_subtotal + frame_subtotal + material_copay + addons_subtotal - overall_cash_discount" /></small></span>}
        name={`${member}[total]`}
        component={InputWithIcon}
        title="$"
        labelClasses="w-100"
        className="form-control"
        wrapperClasses="col-2 mb-5"
      />
    </div>
  )
}
export default Benefits;