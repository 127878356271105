import React, { Component } from 'react'
import { connect } from 'react-redux'

class Pagination extends Component {
  onFilterChange(filter) {
    this.props.filterChangeHook({ ...this.props.filters, ...filter })
  }

  render() {
    const { filters, totalRecords, currentLoadedCount, wrapperClasses } = this.props;
    const startIndex = (filters.rpp * (filters.page - 1)) + 1
    const endIndex = startIndex + currentLoadedCount - 1
    const totalPage = Math.ceil(totalRecords / filters.rpp)
    const rppOptions = this.props.rppOptions ?? [5, 10, 20, 30, 40, 50]

    return (
      <div className={`${wrapperClasses}`}>
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div className="d-flex flex-row align-items-center">
            <span>Show</span>
            <select className="d-inline-block custom-select bg-white mx-3" value={filters.rpp} onChange={(a) => this.onFilterChange({ page: 1, rpp: a.target.value })}>
              {rppOptions.map((option) => option === 'All' ? <option value={999999999999999}>{option}</option> : <option value={option}>{option}</option>)}
            </select>
            <span className="text-nowrap">entries of {totalRecords} ({startIndex} to {endIndex})</span>
          </div>
          <ul className="pagination pagination-sm">
            {filters.page > 1 ?
              [<li className="page-item" onClick={this.onFilterChange.bind(this, { page: 1 })}><button className="page-link" href="#">First</button></li>,
              <li className="page-item" onClick={this.onFilterChange.bind(this, { page: filters.page - 1 })}>
                <button className="page-link" href="#">Prev</button>
              </li>]
              :
              [<li className="page-item disabled"><button className="page-link" href="#">First</button></li>,
              <li className="page-item disabled"><button className="page-link" href="#">Prev</button></li>]
            }

            {filters.page < totalPage ?
              [<li className="page-item" onClick={this.onFilterChange.bind(this, { page: filters.page + 1 })}>
                <button className="page-link" href="#">Next</button>
              </li>,
              <li className="page-item" onClick={this.onFilterChange.bind(this, { page: totalPage })}><button className="page-link" href="#">Last</button></li>]
              :
              [<li className="page-item disabled"><button className="page-link" href="#">Next</button></li>,
              <li className="page-item disabled" ><button className="page-link" href="#">Last</button></li>]
            }
          </ul>
        </div>
      </div>
    )
  }
}

export default connect()(Pagination)