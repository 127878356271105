import React, { useRef, useState } from 'react';
import Moment from 'moment';
import { connect, useDispatch } from 'react-redux';
import { reduxForm, Field, formValueSelector, change } from 'redux-form';
import { InputWithIcon, Input, Textarea, RadioGroup, Dropdown, Switch } from '../../../../itrust_common/components/form';
import { REFUND_PAYMENT_MODES, CARD_TYPES } from '../../../../itrust_common/components/Constants';
import { SaveButton } from '../../../../itrust_common/components/others';
import { onlyPositiveNumbers } from '../../../../itrust_common/components/form/FormValidations';
import OnlinePaymentImg from '../../../../assets/static/images/online-payment.svg';
import SwipeCardMachineImg from '../../../../assets/static/images/card-swipe-machine.svg';
import PayOverTime from './PayOverTime';
import OnlinePaymentWrapper from '../edit/orderOnlinePayments/OnlinePaymentWrapper';
import DevicePayment from './devicePayment/DevicePayment';
import ItrustPayByLink from '../edit/orderOnlinePayments/itrustPayByLink/ItrustPayByLink';

let PaymentForm = props => {
  const [paymentMethod, setPaymentMethod] = useState(null)
  const [setupIntentId, setSetupIntentId] = useState(null)
  const childRef = useRef(null);
  const dispatch = useDispatch()

  const { selectedModeOfPayment, handleSubmit, paymentType, saving, orderSubmit, orderId, orderBalanceDue, orderAmountPaid, order, organisation, currentUser, connect_account_details, isPayOverTime, scheduledPayOverTimeDetails, scheduledPayOverTimePaymentMethodId, payment_methods, storeDeviceId, amount, storeId, phoneNumberForLink } = props


  const PAYMENT_MODE =
    organisation.subscription_plan !== "enterprise" && organisation.add_ons_config.type.itrust_pay?.status !== 'inactive' && organisation.add_ons_config.type.itrust_pay?.visible === true ?
      [
        { value: 'online', label: `<img src=${OnlinePaymentImg} /> Online` },
        { value: 'device', label: `<img src=${SwipeCardMachineImg} /> Terminal` },
        { value: 'link', label: '<i className="las la-external-link-alt mt-1 mr-1"></i>Pay by Text' },
        { value: 'card', label: '<i className="las la-hand-holding-usd mr-1"></i> Offline' },
        { value: 'cash', label: '<i className="las la-money-bill-wave mt-2 mr-1"></i> Cash' },
        { value: 'check', label: '<i className="las la-money-check-alt mt-1 mr-1"></i> Check' },
      ] :
      [
        { value: 'card', label: '<i className="las la-hand-holding-usd mr-1"></i> Offline' },
        { value: 'cash', label: '<i className="las la-money-bill-wave mt-2 mr-1"></i> Cash' },
        { value: 'check', label: '<i className="las la-money-check-alt mt-1 mr-1"></i> Check' },
      ]
  function handlePriorAuthorizationSubmit(val) {
    orderSubmit({ id: orderId, prior_authorization_number: val })
    childRef.current && childRef.current.handleStripeLoadAndSetupIntent && childRef.current.handleStripeLoadAndSetupIntent()
  }

  function handleModeOfPaymentChange(mode_of_payment) {
    if (mode_of_payment === 'device') {
      dispatch(change('orderFinalizeForm', 'amount', orderBalanceDue))
    }
    else {
      amount && dispatch(change('orderFinalizeForm', 'amount', ''))
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        {paymentType !== 'refund' &&
          <Field name="prior_authorization_number" label="1500 Prior Authorization Number" onBlur={(val) => handlePriorAuthorizationSubmit(val.target.value)} component={Input} className="form-control" wrapperClasses="col-12 form-group" />
        }

        <div className={`${paymentType == 'refund' ? 'col-6' : 'col-8'}`}>
          {organisation.subscription_plan != "enterprise" && paymentType !== 'refund' &&
            selectedModeOfPayment === 'online' && connect_account_details &&
            <Field name="is_payover_time" component={Switch} checkedValue={order.is_payover_time} label="Pay Over Time"
              wrapperClasses={`mt-3 mb-5 col-12`} disabled={order.balance_due < 300 || !payment_methods || (payment_methods && payment_methods.length == 0) || (Array.isArray(scheduledPayOverTimeDetails) && scheduledPayOverTimeDetails.length > 0)} />
          }
          {paymentType == 'refund' ?
            <RadioGroup name="mode_of_payment" options={REFUND_PAYMENT_MODES} wrapperClasses={`${paymentType !== 'refund' && orderBalanceDue === 0 && 'disabled'} col-12 form-group`} label={'Refund Mode'} /> :
            <div className='col-12 payment-method-btn'>
              <RadioGroup name="mode_of_payment" containIcons={true} radioLabelClasses="mr-4 mb-4 rounded-pill" options={PAYMENT_MODE} wrapperClasses={`${paymentType !== 'refund' && orderBalanceDue === 0 && 'disabled'} form-group`} label={'Payment Method'} onChangeHook={e => handleModeOfPaymentChange(e)} />
            </div>
          }
        </div>

        {(selectedModeOfPayment === 'online' && isPayOverTime) ?
          <PayOverTime isPayOverTime={isPayOverTime} scheduledPayOverTimeDetails={scheduledPayOverTimeDetails} /> :
          <div className="col-4 pl-0">
            <Field name="amount" label="Amount" component={InputWithIcon} title="$" className="form-control" wrapperClasses={`${paymentType !== 'refund' && orderBalanceDue === 0 && 'disabled'} ${paymentType == 'refund' && orderAmountPaid === 0 && 'disabled'} form-group`} validate={[onlyPositiveNumbers]} />
          </div>
        }

        {selectedModeOfPayment === 'check' &&
          <Field name="check_details[check_number]" label="Check#" component={Input} className="form-control" wrapperClasses={`${paymentType !== 'refund' && orderBalanceDue === 0 && 'disabled'} col-5 form-group`} />
        }
        {selectedModeOfPayment === 'card' &&
          <Field name="card_details[card_type]" label="Card type" component={Dropdown} data={CARD_TYPES} valueField="value" textField="value" wrapperClasses={`${paymentType !== 'refund' && orderBalanceDue === 0 && 'disabled'}  col-5`} />
        }
        {organisation.subscription_plan !== "enterprise" && paymentType !== 'refund' && selectedModeOfPayment === 'online' &&
          <>
            <OnlinePaymentWrapper />
            {/* {organisation.connect_account_details.account_payouts_enabled ?
              <div className='col-12'>
                <CardPayment ref={childRef} scheduledPayOverTimeDetails={scheduledPayOverTimeDetails} scheduledPayOverTimePaymentMethodId={scheduledPayOverTimePaymentMethodId} orderId={orderId} order={order} setPaymentMethod={setPaymentMethod} setSetupIntentId={setSetupIntentId} setupIntentId={setupIntentId} />
              </div> :
              <div className="col-8">
                {["super_admin", "itrust_admin"].includes(currentUser.role) ?
                  <>
                    <span className="text-danger">
                      Your online card payments are not live,
                    <Link to="/add-ons/itrust-pay/dashboard" className="text-danger font-weight-bold" onClick={() => { $('#orderForm-modal').modal('hide'); $('.modal-backdrop').remove() }}>
                        <u className="mx-2">activate it now.</u>
                      </Link>
                    </span>
                    <img src={OnlinePaymentDisabledImg} className="w-75 cursor-not-allowed mt-2" />
                  </> :
                  <>
                    <p className="text-danger">
                      Your online card payments are not live, contact your administrator to activate it.
                    </p>
                    <img src={OnlinePaymentDisabledImg} className="w-75 cursor-not-allowed" />
                  </>}
              </div>
            } */}
          </>
        }
        {organisation.subscription_plan !== "enterprise" && paymentType !== 'refund' && selectedModeOfPayment === 'device' &&
          <DevicePayment orderId={orderId} orderStaffId={order.staff_attributes.id} storeDeviceId={storeDeviceId} amount={amount} orderBalanceDue={orderBalanceDue} />
        }
        {organisation.subscription_plan !== "enterprise" && paymentType !== 'refund' && selectedModeOfPayment === 'link' && <ItrustPayByLink orderId={orderId} storeId={storeId} staffId={currentUser.id} phoneNumberForLink={phoneNumberForLink} amount={amount} orderBalanceDue={orderBalanceDue} />}
        <Field name="notes" label="Comments" component={Textarea} rows={4} className="form-control" wrapperClasses="col-12 form-group mt-5" />
      </div>
      {
        paymentType == 'refund' &&
        <div className="text-right">
          <SaveButton saving={saving} />
        </div>
      }
    </form>
  )
}

PaymentForm = reduxForm({
  enableReinitialize: true
})(PaymentForm)


PaymentForm = connect((state, ownProps) => {
  const selector = formValueSelector(ownProps.form)
  const { order, auth: { user }, organisation: { organisation }, patientPaymentMethod: { payment_methods }, orderPayment: { payment }, currentStore } = state
  const selectedModeOfPayment = selector(state, 'mode_of_payment')
  const amount = selector(state, 'amount')
  const phoneNumberForLink = selector(state, 'phone_number')
  const isPayOverTime = selector(state, 'is_payover_time')
  const isiTrustPayActive = organisation.subscription_plan !== "enterprise" && organisation.add_ons_config.type?.itrust_pay?.status !== 'inactive' && organisation.add_ons_config.type.itrust_pay?.visible
  return {
    organisation,
    phoneNumberForLink,
    order: order.order,
    storeId: currentStore.store.id,
    isPayOverTime,
    selectedModeOfPayment: selectedModeOfPayment,
    currentUser: user,
    saving: order.saving,
    orderId: order.order.id,
    amount,
    storeDeviceId: currentStore.store?.itrust_pay_device_config?.payarc_device,
    orderBalanceDue: order.order.balance_due,
    orderAmountPaid: order.order.amount_paid,
    scheduledPayOverTimePaymentMethodId: order.order.payover_time_details && order.order.payover_time_details.payment_method_id,
    scheduledPayOverTimeDetails: order.order.payovers_attributes,
    connect_account_details: organisation.connect_account_details,
    payment_methods,
    initialValues: {
      is_payover_time: order.order.is_payover_time,
      orders_order_id: order.order.id,
      mode_of_payment: order.order.balance_due == 0 ? '' :
        ownProps.paymentType === 'sale' ? selectedModeOfPayment ? selectedModeOfPayment : (isiTrustPayActive) ? 'device' : 'cash'
          : ownProps.paymentType === 'refund' && 'cash',
      payment_date: Moment(new Date()).format("YYYY/MM/DD"),
      payment_type: ownProps.paymentType,
      staff_id: user.id,
      prior_authorization_number: order.order.prior_authorization_number,
      amount: (ownProps.paymentType === 'sale' && isiTrustPayActive) ? order.order.balance_due : '',
      payover_time_details: {
        amount: order.order.balance_due,
        months: 3,
        schedule_date: Moment(new Date()).format("MM/DD/YYYY").toString(),
      },
      payment_method_id: payment_methods[0]?.card_id,
      phone_number: order?.order?.patient_attributes?.cell_phone,
      customer_id: payment_methods[0]?.customer_id
    }
  }
})(PaymentForm)

export default PaymentForm
