import patientReducer from './patientReducer';
import patientSocialHistoryReducer from './patientSocialHistoryReducer';
import patientMedicalHistoryReducer from './patientMedicalHistoryReducer';
import patientReviewOfSystemReducer from './patientReviewOfSystemReducer';
import patientDocumentReducer from './patientDocumentReducer';
import tagReducer from './tagReducer';
import intraofficeNoteReducer from './patientIntraofficeNoteReducer';
import familyMemberReducer from './patientFamilyMemberReducer';
import patientMessageReducer from './patientMessageReducer';
import patientReportReducer from './patientReportsReducer';
import patientPaymentMethodReducer from './patientPaymentMethodReducer';

const patientReducers ={
  patient: patientReducer,
  patientSocialHistory: patientSocialHistoryReducer,
  patientMedicalHistory: patientMedicalHistoryReducer,
  patientReviewOfSystem: patientReviewOfSystemReducer,
  patientDocument: patientDocumentReducer,
  intraofficeNote: intraofficeNoteReducer,
  familyMember: familyMemberReducer,
  patientMessage: patientMessageReducer,
  tag: tagReducer,
  patientReport: patientReportReducer,
  patientPaymentMethod: patientPaymentMethodReducer
};

export default patientReducers;